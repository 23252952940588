import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /** The day, does not include a time. */
  Date: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: string;
  FullText: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { [key: string]: any };
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: string;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};



/** All input for the `addImageItem` mutation. */
export type AddImageItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  image: Scalars['String'];
};

/** The output of our `addImageItem` mutation. */
export type AddImageItemPayload = {
  __typename?: 'AddImageItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  item?: Maybe<Item>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Item`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `Item`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Payment` that is related to this `Item`. */
  paymentByPaymentId?: Maybe<Payment>;
  /** Reads a single `Bidder` that is related to this `Item`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Item`. */
  userByDeletedFromInvoiceBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userBySetAsideBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userByIsDeletedBy?: Maybe<User>;
  /** An edge for our `Item`. May be used by Relay 1. */
  itemEdge?: Maybe<ItemsEdge>;
};


/** The output of our `addImageItem` mutation. */
export type AddImageItemPayloadItemEdgeArgs = {
  orderBy?: Maybe<Array<ItemsOrderBy>>;
};

/** All input for the `addItemToBin` mutation. */
export type AddItemToBinInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  bin: Scalars['String'];
};

/** The output of our `addItemToBin` mutation. */
export type AddItemToBinPayload = {
  __typename?: 'AddItemToBinPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  addItemToBinResult?: Maybe<AddItemToBinResult>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type AddItemToBinResult = {
  __typename?: 'AddItemToBinResult';
  picklistQueue?: Maybe<PicklistQueue>;
  itemRackLocation?: Maybe<ItemRackLocation>;
};

/** All input for the `addItemToCart` mutation. */
export type AddItemToCartInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
};

/** The output of our `addItemToCart` mutation. */
export type AddItemToCartPayload = {
  __typename?: 'AddItemToCartPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  picklistQueue?: Maybe<PicklistQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `PicklistQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `PicklistQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `PicklistQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByStepUpdatedBy?: Maybe<User>;
  /** An edge for our `PicklistQueue`. May be used by Relay 1. */
  picklistQueueEdge?: Maybe<PicklistQueuesEdge>;
};


/** The output of our `addItemToCart` mutation. */
export type AddItemToCartPayloadPicklistQueueEdgeArgs = {
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
};

/** All input for the `addItemToRack` mutation. */
export type AddItemToRackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  rack: Scalars['String'];
};

/** The output of our `addItemToRack` mutation. */
export type AddItemToRackPayload = {
  __typename?: 'AddItemToRackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  picklistQueue?: Maybe<PicklistQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `PicklistQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `PicklistQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `PicklistQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByStepUpdatedBy?: Maybe<User>;
  /** An edge for our `PicklistQueue`. May be used by Relay 1. */
  picklistQueueEdge?: Maybe<PicklistQueuesEdge>;
};


/** The output of our `addItemToRack` mutation. */
export type AddItemToRackPayloadPicklistQueueEdgeArgs = {
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
};

/** All input for the `adjustBidderCredits` mutation. */
export type AdjustBidderCreditsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  bidderId: Scalars['Int'];
  type: AdjustBidderCreditsType;
  amount: Scalars['Int'];
  note: Scalars['String'];
};

/** The output of our `adjustBidderCredits` mutation. */
export type AdjustBidderCreditsPayload = {
  __typename?: 'AdjustBidderCreditsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  bidderCreditHistory?: Maybe<BidderCreditHistory>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Bidder` that is related to this `BidderCreditHistory`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `Invoice` that is related to this `BidderCreditHistory`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Auction` that is related to this `BidderCreditHistory`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Item` that is related to this `BidderCreditHistory`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `BidderCreditHistory`. */
  userByCreatedBy?: Maybe<User>;
  /** An edge for our `BidderCreditHistory`. May be used by Relay 1. */
  bidderCreditHistoryEdge?: Maybe<BidderCreditHistoriesEdge>;
};


/** The output of our `adjustBidderCredits` mutation. */
export type AdjustBidderCreditsPayloadBidderCreditHistoryEdgeArgs = {
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
};

export enum AdjustBidderCreditsType {
  Add = 'ADD',
  Remove = 'REMOVE'
}

/** All input for the `applyCreditToInvoice` mutation. */
export type ApplyCreditToInvoiceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
  creditAmount: Scalars['Int'];
};

/** The output of our `applyCreditToInvoice` mutation. */
export type ApplyCreditToInvoicePayload = {
  __typename?: 'ApplyCreditToInvoicePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Invoice`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Bidder` that is related to this `Invoice`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Invoice`. */
  userByProcessPaymentChangedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Invoice`. */
  userByAlternatePaymentCreatedBy?: Maybe<User>;
  /** Reads a single `Location` that is related to this `Invoice`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `Invoice`. May be used by Relay 1. */
  invoiceEdge?: Maybe<InvoicesEdge>;
};


/** The output of our `applyCreditToInvoice` mutation. */
export type ApplyCreditToInvoicePayloadInvoiceEdgeArgs = {
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
};

/** All input for the `assignBinToRack` mutation. */
export type AssignBinToRackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  bin: Scalars['String'];
  rack: Scalars['String'];
};

/** The output of our `assignBinToRack` mutation. */
export type AssignBinToRackPayload = {
  __typename?: 'AssignBinToRackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<AssignBinToRackResult>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type AssignBinToRackResult = {
  __typename?: 'AssignBinToRackResult';
  queueIds?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  locations?: Maybe<Array<Maybe<ItemRackLocation>>>;
};

export type Auction = Node & {
  __typename?: 'Auction';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  invId?: Maybe<Scalars['Int']>;
  bwId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  locationName?: Maybe<Scalars['String']>;
  dateCreated: Scalars['Datetime'];
  dateUpdated?: Maybe<Scalars['Datetime']>;
  dateArchived?: Maybe<Scalars['Datetime']>;
  dateDeleted?: Maybe<Scalars['Datetime']>;
  name: Scalars['String'];
  descriptionShort: Scalars['String'];
  descriptionFull: Scalars['String'];
  tagline: Scalars['String'];
  locationId?: Maybe<Scalars['Int']>;
  /** Put FROM locations.premium if found location by auction.location_name = locations.name else put FROM setting -> auction_defaults.premium */
  premium: Scalars['BigFloat'];
  /** Put FROM locations.tax if found location by auction.location_name = locations.name else put FROM setting -> auction_defaults.tax */
  tax: Scalars['BigFloat'];
  timezone?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Datetime']>;
  endAt?: Maybe<Scalars['Datetime']>;
  pickupStartAt?: Maybe<Scalars['Datetime']>;
  pickupEndAt?: Maybe<Scalars['Datetime']>;
  isCompleted: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isPulling: Scalars['Boolean'];
  isPulled: Scalars['Boolean'];
  lastPullAt?: Maybe<Scalars['Datetime']>;
  lastPullError?: Maybe<Scalars['String']>;
  isPushed: Scalars['Boolean'];
  isPushing: Scalars['Boolean'];
  lastPushAt?: Maybe<Scalars['Datetime']>;
  lastPushError?: Maybe<Scalars['String']>;
  isProcessingInvoicePayments: Scalars['Boolean'];
  processInvoicePaymentsExecCount: Scalars['Int'];
  isEmailingInvoices: Scalars['Boolean'];
  emailInvoicesExecCount: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  lpudNumDays: Scalars['Int'];
  extType: ExtType;
  merId?: Maybe<Scalars['String']>;
  /** Reads a single `Location` that is related to this `Auction`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAuctionId: InvoicesConnection;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByAuctionId: InvoicesSearchesConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByAuctionId: ItemsConnection;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByAuctionId: BidderCreditHistoriesConnection;
  /** Reads and enables pagination through a set of `AuctionPush`. */
  auctionPushesByAuctionId: AuctionPushesConnection;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByAuctionId: AuctionInvoicesViewsConnection;
  countCompletedInvoices?: Maybe<Scalars['Int']>;
  countPaidInvoices?: Maybe<Scalars['Int']>;
  /** Number of processing items in push queue. */
  countProcessingItemsForPush?: Maybe<Scalars['Int']>;
  /** Number of queued items in push queue. */
  countQueuedItemsForPush?: Maybe<Scalars['Int']>;
  /** Number of sold items in auction. */
  countSoldItems?: Maybe<Scalars['Int']>;
  countTotalInvoices?: Maybe<Scalars['Int']>;
  /** Number of items in auction. */
  countTotalItems?: Maybe<Scalars['Int']>;
  countUncompletedInvoices?: Maybe<Scalars['Int']>;
  countUnpaidInvoices?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `ItemImage`. */
  images: ItemImagesConnection;
  percentCompletedInvoices?: Maybe<Scalars['BigFloat']>;
  percentPaidInvoices?: Maybe<Scalars['BigFloat']>;
  /** Percent of sold items in auction. */
  percentSoldItems?: Maybe<Scalars['BigFloat']>;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByInvoiceAuctionIdAndBidderId: AuctionBiddersByInvoiceAuctionIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceAuctionIdAndProcessPaymentChangedBy: AuctionUsersByInvoiceAuctionIdAndProcessPaymentChangedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceAuctionIdAndAlternatePaymentCreatedBy: AuctionUsersByInvoiceAuctionIdAndAlternatePaymentCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByInvoiceAuctionIdAndLocationId: AuctionLocationsByInvoiceAuctionIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByInvoicesSearchAuctionIdAndInvoiceId: AuctionInvoicesByInvoicesSearchAuctionIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByInvoicesSearchAuctionIdAndBidderId: AuctionBiddersByInvoicesSearchAuctionIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByItemAuctionIdAndInvoiceId: AuctionInvoicesByItemAuctionIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByItemAuctionIdAndPaymentId: AuctionPaymentsByItemAuctionIdAndPaymentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByItemAuctionIdAndBidderId: AuctionBiddersByItemAuctionIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemAuctionIdAndDeletedFromInvoiceBy: AuctionUsersByItemAuctionIdAndDeletedFromInvoiceByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemAuctionIdAndSetAsideBy: AuctionUsersByItemAuctionIdAndSetAsideByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemAuctionIdAndIsDeletedBy: AuctionUsersByItemAuctionIdAndIsDeletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByBidderCreditHistoryAuctionIdAndBidderId: AuctionBiddersByBidderCreditHistoryAuctionIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderCreditHistoryAuctionIdAndInvoiceId: AuctionInvoicesByBidderCreditHistoryAuctionIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderCreditHistoryAuctionIdAndItemId: AuctionItemsByBidderCreditHistoryAuctionIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByBidderCreditHistoryAuctionIdAndCreatedBy: AuctionUsersByBidderCreditHistoryAuctionIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByAuctionInvoicesViewAuctionIdAndCustomerId: AuctionBiddersByAuctionInvoicesViewAuctionIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAuctionInvoicesViewAuctionIdAndInvoiceId: AuctionInvoicesByAuctionInvoicesViewAuctionIdAndInvoiceIdManyToManyConnection;
};


export type AuctionInvoicesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type AuctionInvoicesSearchesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};


export type AuctionItemsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type AuctionBidderCreditHistoriesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};


export type AuctionAuctionPushesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionPushesOrderBy>>;
  condition?: Maybe<AuctionPushCondition>;
  filter?: Maybe<AuctionPushFilter>;
};


export type AuctionAuctionInvoicesViewsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};


export type AuctionImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<ItemImageFilter>;
};


export type AuctionBiddersByInvoiceAuctionIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type AuctionUsersByInvoiceAuctionIdAndProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type AuctionUsersByInvoiceAuctionIdAndAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type AuctionLocationsByInvoiceAuctionIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type AuctionInvoicesByInvoicesSearchAuctionIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type AuctionBiddersByInvoicesSearchAuctionIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type AuctionInvoicesByItemAuctionIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type AuctionPaymentsByItemAuctionIdAndPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy>>;
  condition?: Maybe<PaymentCondition>;
  filter?: Maybe<PaymentFilter>;
};


export type AuctionBiddersByItemAuctionIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type AuctionUsersByItemAuctionIdAndDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type AuctionUsersByItemAuctionIdAndSetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type AuctionUsersByItemAuctionIdAndIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type AuctionBiddersByBidderCreditHistoryAuctionIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type AuctionInvoicesByBidderCreditHistoryAuctionIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type AuctionItemsByBidderCreditHistoryAuctionIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type AuctionUsersByBidderCreditHistoryAuctionIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type AuctionBiddersByAuctionInvoicesViewAuctionIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type AuctionInvoicesByAuctionInvoicesViewAuctionIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Bidder` values, with data from `AuctionInvoicesView`. */
export type AuctionBiddersByAuctionInvoicesViewAuctionIdAndCustomerIdManyToManyConnection = {
  __typename?: 'AuctionBiddersByAuctionInvoicesViewAuctionIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `AuctionInvoicesView`, and the cursor to aid in pagination. */
  edges: Array<AuctionBiddersByAuctionInvoicesViewAuctionIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `AuctionInvoicesView`. */
export type AuctionBiddersByAuctionInvoicesViewAuctionIdAndCustomerIdManyToManyEdge = {
  __typename?: 'AuctionBiddersByAuctionInvoicesViewAuctionIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByCustomerId: AuctionInvoicesViewsConnection;
};


/** A `Bidder` edge in the connection, with data from `AuctionInvoicesView`. */
export type AuctionBiddersByAuctionInvoicesViewAuctionIdAndCustomerIdManyToManyEdgeAuctionInvoicesViewsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};

/** A connection to a list of `Bidder` values, with data from `BidderCreditHistory`. */
export type AuctionBiddersByBidderCreditHistoryAuctionIdAndBidderIdManyToManyConnection = {
  __typename?: 'AuctionBiddersByBidderCreditHistoryAuctionIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<AuctionBiddersByBidderCreditHistoryAuctionIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `BidderCreditHistory`. */
export type AuctionBiddersByBidderCreditHistoryAuctionIdAndBidderIdManyToManyEdge = {
  __typename?: 'AuctionBiddersByBidderCreditHistoryAuctionIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByBidderId: BidderCreditHistoriesConnection;
};


/** A `Bidder` edge in the connection, with data from `BidderCreditHistory`. */
export type AuctionBiddersByBidderCreditHistoryAuctionIdAndBidderIdManyToManyEdgeBidderCreditHistoriesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Invoice`. */
export type AuctionBiddersByInvoiceAuctionIdAndBidderIdManyToManyConnection = {
  __typename?: 'AuctionBiddersByInvoiceAuctionIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<AuctionBiddersByInvoiceAuctionIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Invoice`. */
export type AuctionBiddersByInvoiceAuctionIdAndBidderIdManyToManyEdge = {
  __typename?: 'AuctionBiddersByInvoiceAuctionIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderId: InvoicesConnection;
};


/** A `Bidder` edge in the connection, with data from `Invoice`. */
export type AuctionBiddersByInvoiceAuctionIdAndBidderIdManyToManyEdgeInvoicesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Bidder` values, with data from `InvoicesSearch`. */
export type AuctionBiddersByInvoicesSearchAuctionIdAndBidderIdManyToManyConnection = {
  __typename?: 'AuctionBiddersByInvoicesSearchAuctionIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `InvoicesSearch`, and the cursor to aid in pagination. */
  edges: Array<AuctionBiddersByInvoicesSearchAuctionIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `InvoicesSearch`. */
export type AuctionBiddersByInvoicesSearchAuctionIdAndBidderIdManyToManyEdge = {
  __typename?: 'AuctionBiddersByInvoicesSearchAuctionIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByBidderId: InvoicesSearchesConnection;
};


/** A `Bidder` edge in the connection, with data from `InvoicesSearch`. */
export type AuctionBiddersByInvoicesSearchAuctionIdAndBidderIdManyToManyEdgeInvoicesSearchesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Item`. */
export type AuctionBiddersByItemAuctionIdAndBidderIdManyToManyConnection = {
  __typename?: 'AuctionBiddersByItemAuctionIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<AuctionBiddersByItemAuctionIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Item`. */
export type AuctionBiddersByItemAuctionIdAndBidderIdManyToManyEdge = {
  __typename?: 'AuctionBiddersByItemAuctionIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderId: ItemsConnection;
};


/** A `Bidder` edge in the connection, with data from `Item`. */
export type AuctionBiddersByItemAuctionIdAndBidderIdManyToManyEdgeItemsByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

export type AuctionChangesView = {
  __typename?: 'AuctionChangesView';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['Datetime']>;
  countQueuedItemsForPush?: Maybe<Scalars['Int']>;
  countProcessingItemsForPush?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `AuctionChangesView` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AuctionChangesViewCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `endAt` field. */
  endAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `countQueuedItemsForPush` field. */
  countQueuedItemsForPush?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `countProcessingItemsForPush` field. */
  countProcessingItemsForPush?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `AuctionChangesView` object types. All fields are combined with a logical ‘and.’ */
export type AuctionChangesViewFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `endAt` field. */
  endAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `countQueuedItemsForPush` field. */
  countQueuedItemsForPush?: Maybe<IntFilter>;
  /** Filter by the object’s `countProcessingItemsForPush` field. */
  countProcessingItemsForPush?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AuctionChangesViewFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AuctionChangesViewFilter>>;
  /** Negates the expression. */
  not?: Maybe<AuctionChangesViewFilter>;
};

/** A connection to a list of `AuctionChangesView` values. */
export type AuctionChangesViewsConnection = {
  __typename?: 'AuctionChangesViewsConnection';
  /** A list of `AuctionChangesView` objects. */
  nodes: Array<AuctionChangesView>;
  /** A list of edges which contains the `AuctionChangesView` and cursor to aid in pagination. */
  edges: Array<AuctionChangesViewsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuctionChangesView` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AuctionChangesView` edge in the connection. */
export type AuctionChangesViewsEdge = {
  __typename?: 'AuctionChangesViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AuctionChangesView` at the end of the edge. */
  node: AuctionChangesView;
};

/** Methods to use when ordering `AuctionChangesView`. */
export enum AuctionChangesViewsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  EndAtAsc = 'END_AT_ASC',
  EndAtDesc = 'END_AT_DESC',
  CountQueuedItemsForPushAsc = 'COUNT_QUEUED_ITEMS_FOR_PUSH_ASC',
  CountQueuedItemsForPushDesc = 'COUNT_QUEUED_ITEMS_FOR_PUSH_DESC',
  CountProcessingItemsForPushAsc = 'COUNT_PROCESSING_ITEMS_FOR_PUSH_ASC',
  CountProcessingItemsForPushDesc = 'COUNT_PROCESSING_ITEMS_FOR_PUSH_DESC'
}

/** A condition to be used against `Auction` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type AuctionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invId` field. */
  invId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bwId` field. */
  bwId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `number` field. */
  number?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationName` field. */
  locationName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateCreated` field. */
  dateCreated?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dateUpdated` field. */
  dateUpdated?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dateArchived` field. */
  dateArchived?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `dateDeleted` field. */
  dateDeleted?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `descriptionShort` field. */
  descriptionShort?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `descriptionFull` field. */
  descriptionFull?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `tagline` field. */
  tagline?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `premium` field. */
  premium?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `tax` field. */
  tax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `startAt` field. */
  startAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endAt` field. */
  endAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `pickupStartAt` field. */
  pickupStartAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `pickupEndAt` field. */
  pickupEndAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPulling` field. */
  isPulling?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPulled` field. */
  isPulled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lastPullAt` field. */
  lastPullAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lastPullError` field. */
  lastPullError?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isPushed` field. */
  isPushed?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPushing` field. */
  isPushing?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lastPushAt` field. */
  lastPushAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lastPushError` field. */
  lastPushError?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isProcessingInvoicePayments` field. */
  isProcessingInvoicePayments?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `processInvoicePaymentsExecCount` field. */
  processInvoicePaymentsExecCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isEmailingInvoices` field. */
  isEmailingInvoices?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `emailInvoicesExecCount` field. */
  emailInvoicesExecCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lpudNumDays` field. */
  lpudNumDays?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `extType` field. */
  extType?: Maybe<ExtType>;
  /** Checks for equality with the object’s `merId` field. */
  merId?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Auction` object types. All fields are combined with a logical ‘and.’ */
export type AuctionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `invId` field. */
  invId?: Maybe<IntFilter>;
  /** Filter by the object’s `bwId` field. */
  bwId?: Maybe<IntFilter>;
  /** Filter by the object’s `number` field. */
  number?: Maybe<IntFilter>;
  /** Filter by the object’s `locationName` field. */
  locationName?: Maybe<StringFilter>;
  /** Filter by the object’s `dateCreated` field. */
  dateCreated?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `dateUpdated` field. */
  dateUpdated?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `dateArchived` field. */
  dateArchived?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `dateDeleted` field. */
  dateDeleted?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `descriptionShort` field. */
  descriptionShort?: Maybe<StringFilter>;
  /** Filter by the object’s `descriptionFull` field. */
  descriptionFull?: Maybe<StringFilter>;
  /** Filter by the object’s `tagline` field. */
  tagline?: Maybe<StringFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `premium` field. */
  premium?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `tax` field. */
  tax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: Maybe<StringFilter>;
  /** Filter by the object’s `startAt` field. */
  startAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `endAt` field. */
  endAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `pickupStartAt` field. */
  pickupStartAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `pickupEndAt` field. */
  pickupEndAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPulling` field. */
  isPulling?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPulled` field. */
  isPulled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `lastPullAt` field. */
  lastPullAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lastPullError` field. */
  lastPullError?: Maybe<StringFilter>;
  /** Filter by the object’s `isPushed` field. */
  isPushed?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPushing` field. */
  isPushing?: Maybe<BooleanFilter>;
  /** Filter by the object’s `lastPushAt` field. */
  lastPushAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lastPushError` field. */
  lastPushError?: Maybe<StringFilter>;
  /** Filter by the object’s `isProcessingInvoicePayments` field. */
  isProcessingInvoicePayments?: Maybe<BooleanFilter>;
  /** Filter by the object’s `processInvoicePaymentsExecCount` field. */
  processInvoicePaymentsExecCount?: Maybe<IntFilter>;
  /** Filter by the object’s `isEmailingInvoices` field. */
  isEmailingInvoices?: Maybe<BooleanFilter>;
  /** Filter by the object’s `emailInvoicesExecCount` field. */
  emailInvoicesExecCount?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lpudNumDays` field. */
  lpudNumDays?: Maybe<IntFilter>;
  /** Filter by the object’s `extType` field. */
  extType?: Maybe<ExtTypeFilter>;
  /** Filter by the object’s `merId` field. */
  merId?: Maybe<StringFilter>;
  /** Filter by the object’s `countCompletedInvoices` field. */
  countCompletedInvoices?: Maybe<IntFilter>;
  /** Filter by the object’s `countPaidInvoices` field. */
  countPaidInvoices?: Maybe<IntFilter>;
  /** Filter by the object’s `countProcessingItemsForPush` field. */
  countProcessingItemsForPush?: Maybe<IntFilter>;
  /** Filter by the object’s `countQueuedItemsForPush` field. */
  countQueuedItemsForPush?: Maybe<IntFilter>;
  /** Filter by the object’s `countSoldItems` field. */
  countSoldItems?: Maybe<IntFilter>;
  /** Filter by the object’s `countTotalInvoices` field. */
  countTotalInvoices?: Maybe<IntFilter>;
  /** Filter by the object’s `countTotalItems` field. */
  countTotalItems?: Maybe<IntFilter>;
  /** Filter by the object’s `countUncompletedInvoices` field. */
  countUncompletedInvoices?: Maybe<IntFilter>;
  /** Filter by the object’s `countUnpaidInvoices` field. */
  countUnpaidInvoices?: Maybe<IntFilter>;
  /** Filter by the object’s `percentCompletedInvoices` field. */
  percentCompletedInvoices?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `percentPaidInvoices` field. */
  percentPaidInvoices?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `percentSoldItems` field. */
  percentSoldItems?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `invoicesByAuctionId` relation. */
  invoicesByAuctionId?: Maybe<AuctionToManyInvoiceFilter>;
  /** Some related `invoicesByAuctionId` exist. */
  invoicesByAuctionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoicesSearchesByAuctionId` relation. */
  invoicesSearchesByAuctionId?: Maybe<AuctionToManyInvoicesSearchFilter>;
  /** Some related `invoicesSearchesByAuctionId` exist. */
  invoicesSearchesByAuctionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemsByAuctionId` relation. */
  itemsByAuctionId?: Maybe<AuctionToManyItemFilter>;
  /** Some related `itemsByAuctionId` exist. */
  itemsByAuctionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderCreditHistoriesByAuctionId` relation. */
  bidderCreditHistoriesByAuctionId?: Maybe<AuctionToManyBidderCreditHistoryFilter>;
  /** Some related `bidderCreditHistoriesByAuctionId` exist. */
  bidderCreditHistoriesByAuctionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auctionPushesByAuctionId` relation. */
  auctionPushesByAuctionId?: Maybe<AuctionToManyAuctionPushFilter>;
  /** Some related `auctionPushesByAuctionId` exist. */
  auctionPushesByAuctionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auctionInvoicesViewsByAuctionId` relation. */
  auctionInvoicesViewsByAuctionId?: Maybe<AuctionToManyAuctionInvoicesViewFilter>;
  /** Some related `auctionInvoicesViewsByAuctionId` exist. */
  auctionInvoicesViewsByAuctionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** A related `locationByLocationId` exists. */
  locationByLocationIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AuctionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AuctionFilter>>;
  /** Negates the expression. */
  not?: Maybe<AuctionFilter>;
};

/** A connection to a list of `Invoice` values, with data from `AuctionInvoicesView`. */
export type AuctionInvoicesByAuctionInvoicesViewAuctionIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'AuctionInvoicesByAuctionInvoicesViewAuctionIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `AuctionInvoicesView`, and the cursor to aid in pagination. */
  edges: Array<AuctionInvoicesByAuctionInvoicesViewAuctionIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `AuctionInvoicesView`. */
export type AuctionInvoicesByAuctionInvoicesViewAuctionIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'AuctionInvoicesByAuctionInvoicesViewAuctionIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByInvoiceId: AuctionInvoicesViewsConnection;
};


/** A `Invoice` edge in the connection, with data from `AuctionInvoicesView`. */
export type AuctionInvoicesByAuctionInvoicesViewAuctionIdAndInvoiceIdManyToManyEdgeAuctionInvoicesViewsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};

/** A connection to a list of `Invoice` values, with data from `BidderCreditHistory`. */
export type AuctionInvoicesByBidderCreditHistoryAuctionIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'AuctionInvoicesByBidderCreditHistoryAuctionIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<AuctionInvoicesByBidderCreditHistoryAuctionIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `BidderCreditHistory`. */
export type AuctionInvoicesByBidderCreditHistoryAuctionIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'AuctionInvoicesByBidderCreditHistoryAuctionIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByInvoiceId: BidderCreditHistoriesConnection;
};


/** A `Invoice` edge in the connection, with data from `BidderCreditHistory`. */
export type AuctionInvoicesByBidderCreditHistoryAuctionIdAndInvoiceIdManyToManyEdgeBidderCreditHistoriesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Invoice` values, with data from `InvoicesSearch`. */
export type AuctionInvoicesByInvoicesSearchAuctionIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'AuctionInvoicesByInvoicesSearchAuctionIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `InvoicesSearch`, and the cursor to aid in pagination. */
  edges: Array<AuctionInvoicesByInvoicesSearchAuctionIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `InvoicesSearch`. */
export type AuctionInvoicesByInvoicesSearchAuctionIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'AuctionInvoicesByInvoicesSearchAuctionIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByInvoiceId: InvoicesSearchesConnection;
};


/** A `Invoice` edge in the connection, with data from `InvoicesSearch`. */
export type AuctionInvoicesByInvoicesSearchAuctionIdAndInvoiceIdManyToManyEdgeInvoicesSearchesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};

/** A connection to a list of `Invoice` values, with data from `Item`. */
export type AuctionInvoicesByItemAuctionIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'AuctionInvoicesByItemAuctionIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<AuctionInvoicesByItemAuctionIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `Item`. */
export type AuctionInvoicesByItemAuctionIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'AuctionInvoicesByItemAuctionIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceId: ItemsConnection;
};


/** A `Invoice` edge in the connection, with data from `Item`. */
export type AuctionInvoicesByItemAuctionIdAndInvoiceIdManyToManyEdgeItemsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

export type AuctionInvoicesView = {
  __typename?: 'AuctionInvoicesView';
  invoiceId?: Maybe<Scalars['Int']>;
  invoiceUid?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['BigInt']>;
  amountDue?: Maybe<Scalars['BigInt']>;
  itemCount?: Maybe<Scalars['Int']>;
  auctionName?: Maybe<Scalars['String']>;
  auctionId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  customerName?: Maybe<Scalars['String']>;
  customerActiveActionNotesCount?: Maybe<Scalars['Int']>;
  customerActiveAlertNotesCount?: Maybe<Scalars['Int']>;
  /** Reads a single `Bidder` that is related to this `AuctionInvoicesView`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Auction` that is related to this `AuctionInvoicesView`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `AuctionInvoicesView`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
};

/**
 * A condition to be used against `AuctionInvoicesView` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type AuctionInvoicesViewCondition = {
  /** Checks for equality with the object’s `invoiceId` field. */
  invoiceId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invoiceUid` field. */
  invoiceUid?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `total` field. */
  total?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `amountDue` field. */
  amountDue?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `itemCount` field. */
  itemCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `auctionName` field. */
  auctionName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `auctionId` field. */
  auctionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerName` field. */
  customerName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `customerActiveActionNotesCount` field. */
  customerActiveActionNotesCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerActiveAlertNotesCount` field. */
  customerActiveAlertNotesCount?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `AuctionInvoicesView` object types. All fields are combined with a logical ‘and.’ */
export type AuctionInvoicesViewFilter = {
  /** Filter by the object’s `invoiceId` field. */
  invoiceId?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceUid` field. */
  invoiceUid?: Maybe<StringFilter>;
  /** Filter by the object’s `total` field. */
  total?: Maybe<BigIntFilter>;
  /** Filter by the object’s `amountDue` field. */
  amountDue?: Maybe<BigIntFilter>;
  /** Filter by the object’s `itemCount` field. */
  itemCount?: Maybe<IntFilter>;
  /** Filter by the object’s `auctionName` field. */
  auctionName?: Maybe<StringFilter>;
  /** Filter by the object’s `auctionId` field. */
  auctionId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerActiveActionNotesCount` field. */
  customerActiveActionNotesCount?: Maybe<IntFilter>;
  /** Filter by the object’s `customerActiveAlertNotesCount` field. */
  customerActiveAlertNotesCount?: Maybe<IntFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** A related `bidderByCustomerId` exists. */
  bidderByCustomerIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auctionByAuctionId` relation. */
  auctionByAuctionId?: Maybe<AuctionFilter>;
  /** A related `auctionByAuctionId` exists. */
  auctionByAuctionIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceByInvoiceId` relation. */
  invoiceByInvoiceId?: Maybe<InvoiceFilter>;
  /** A related `invoiceByInvoiceId` exists. */
  invoiceByInvoiceIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AuctionInvoicesViewFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AuctionInvoicesViewFilter>>;
  /** Negates the expression. */
  not?: Maybe<AuctionInvoicesViewFilter>;
};

/** A connection to a list of `AuctionInvoicesView` values. */
export type AuctionInvoicesViewsConnection = {
  __typename?: 'AuctionInvoicesViewsConnection';
  /** A list of `AuctionInvoicesView` objects. */
  nodes: Array<AuctionInvoicesView>;
  /** A list of edges which contains the `AuctionInvoicesView` and cursor to aid in pagination. */
  edges: Array<AuctionInvoicesViewsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuctionInvoicesView` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AuctionInvoicesView` edge in the connection. */
export type AuctionInvoicesViewsEdge = {
  __typename?: 'AuctionInvoicesViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AuctionInvoicesView` at the end of the edge. */
  node: AuctionInvoicesView;
};

/** Methods to use when ordering `AuctionInvoicesView`. */
export enum AuctionInvoicesViewsOrderBy {
  Natural = 'NATURAL',
  InvoiceIdAsc = 'INVOICE_ID_ASC',
  InvoiceIdDesc = 'INVOICE_ID_DESC',
  InvoiceUidAsc = 'INVOICE_UID_ASC',
  InvoiceUidDesc = 'INVOICE_UID_DESC',
  TotalAsc = 'TOTAL_ASC',
  TotalDesc = 'TOTAL_DESC',
  AmountDueAsc = 'AMOUNT_DUE_ASC',
  AmountDueDesc = 'AMOUNT_DUE_DESC',
  ItemCountAsc = 'ITEM_COUNT_ASC',
  ItemCountDesc = 'ITEM_COUNT_DESC',
  AuctionNameAsc = 'AUCTION_NAME_ASC',
  AuctionNameDesc = 'AUCTION_NAME_DESC',
  AuctionIdAsc = 'AUCTION_ID_ASC',
  AuctionIdDesc = 'AUCTION_ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CustomerNameAsc = 'CUSTOMER_NAME_ASC',
  CustomerNameDesc = 'CUSTOMER_NAME_DESC',
  CustomerActiveActionNotesCountAsc = 'CUSTOMER_ACTIVE_ACTION_NOTES_COUNT_ASC',
  CustomerActiveActionNotesCountDesc = 'CUSTOMER_ACTIVE_ACTION_NOTES_COUNT_DESC',
  CustomerActiveAlertNotesCountAsc = 'CUSTOMER_ACTIVE_ALERT_NOTES_COUNT_ASC',
  CustomerActiveAlertNotesCountDesc = 'CUSTOMER_ACTIVE_ALERT_NOTES_COUNT_DESC'
}

/** A connection to a list of `Item` values, with data from `BidderCreditHistory`. */
export type AuctionItemsByBidderCreditHistoryAuctionIdAndItemIdManyToManyConnection = {
  __typename?: 'AuctionItemsByBidderCreditHistoryAuctionIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<AuctionItemsByBidderCreditHistoryAuctionIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `BidderCreditHistory`. */
export type AuctionItemsByBidderCreditHistoryAuctionIdAndItemIdManyToManyEdge = {
  __typename?: 'AuctionItemsByBidderCreditHistoryAuctionIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByItemId: BidderCreditHistoriesConnection;
};


/** A `Item` edge in the connection, with data from `BidderCreditHistory`. */
export type AuctionItemsByBidderCreditHistoryAuctionIdAndItemIdManyToManyEdgeBidderCreditHistoriesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Location` values, with data from `Invoice`. */
export type AuctionLocationsByInvoiceAuctionIdAndLocationIdManyToManyConnection = {
  __typename?: 'AuctionLocationsByInvoiceAuctionIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<AuctionLocationsByInvoiceAuctionIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `Invoice`. */
export type AuctionLocationsByInvoiceAuctionIdAndLocationIdManyToManyEdge = {
  __typename?: 'AuctionLocationsByInvoiceAuctionIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByLocationId: InvoicesConnection;
};


/** A `Location` edge in the connection, with data from `Invoice`. */
export type AuctionLocationsByInvoiceAuctionIdAndLocationIdManyToManyEdgeInvoicesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** Represents an update to a `Auction`. Fields that are set will be updated. */
export type AuctionPatch = {
  invId?: Maybe<Scalars['Int']>;
  bwId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  locationName?: Maybe<Scalars['String']>;
  dateCreated?: Maybe<Scalars['Datetime']>;
  dateUpdated?: Maybe<Scalars['Datetime']>;
  dateArchived?: Maybe<Scalars['Datetime']>;
  dateDeleted?: Maybe<Scalars['Datetime']>;
  name?: Maybe<Scalars['String']>;
  descriptionShort?: Maybe<Scalars['String']>;
  descriptionFull?: Maybe<Scalars['String']>;
  tagline?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  /** Put FROM locations.premium if found location by auction.location_name = locations.name else put FROM setting -> auction_defaults.premium */
  premium?: Maybe<Scalars['BigFloat']>;
  /** Put FROM locations.tax if found location by auction.location_name = locations.name else put FROM setting -> auction_defaults.tax */
  tax?: Maybe<Scalars['BigFloat']>;
  timezone?: Maybe<Scalars['String']>;
  startAt?: Maybe<Scalars['Datetime']>;
  endAt?: Maybe<Scalars['Datetime']>;
  pickupStartAt?: Maybe<Scalars['Datetime']>;
  pickupEndAt?: Maybe<Scalars['Datetime']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isPulling?: Maybe<Scalars['Boolean']>;
  isPulled?: Maybe<Scalars['Boolean']>;
  lastPullAt?: Maybe<Scalars['Datetime']>;
  lastPullError?: Maybe<Scalars['String']>;
  isPushed?: Maybe<Scalars['Boolean']>;
  isPushing?: Maybe<Scalars['Boolean']>;
  lastPushAt?: Maybe<Scalars['Datetime']>;
  lastPushError?: Maybe<Scalars['String']>;
  isProcessingInvoicePayments?: Maybe<Scalars['Boolean']>;
  lpudNumDays?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `Payment` values, with data from `Item`. */
export type AuctionPaymentsByItemAuctionIdAndPaymentIdManyToManyConnection = {
  __typename?: 'AuctionPaymentsByItemAuctionIdAndPaymentIdManyToManyConnection';
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** A list of edges which contains the `Payment`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<AuctionPaymentsByItemAuctionIdAndPaymentIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection, with data from `Item`. */
export type AuctionPaymentsByItemAuctionIdAndPaymentIdManyToManyEdge = {
  __typename?: 'AuctionPaymentsByItemAuctionIdAndPaymentIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPaymentId: ItemsConnection;
};


/** A `Payment` edge in the connection, with data from `Item`. */
export type AuctionPaymentsByItemAuctionIdAndPaymentIdManyToManyEdgeItemsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

export type AuctionPush = Node & {
  __typename?: 'AuctionPush';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['BigInt'];
  type: AuctionPushType;
  auctionId: Scalars['Int'];
  data: Scalars['JSON'];
  result: Scalars['JSON'];
  state: QueuedProcessingType;
  isSuccessful?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  stateLastUpdatedAt: Scalars['Datetime'];
  /** Reads a single `Auction` that is related to this `AuctionPush`. */
  auctionByAuctionId?: Maybe<Auction>;
};

/**
 * A condition to be used against `AuctionPush` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AuctionPushCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<AuctionPushType>;
  /** Checks for equality with the object’s `auctionId` field. */
  auctionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `data` field. */
  data?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `result` field. */
  result?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `state` field. */
  state?: Maybe<QueuedProcessingType>;
  /** Checks for equality with the object’s `isSuccessful` field. */
  isSuccessful?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `stateLastUpdatedAt` field. */
  stateLastUpdatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `AuctionPush` object types. All fields are combined with a logical ‘and.’ */
export type AuctionPushFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<BigIntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<AuctionPushTypeFilter>;
  /** Filter by the object’s `auctionId` field. */
  auctionId?: Maybe<IntFilter>;
  /** Filter by the object’s `data` field. */
  data?: Maybe<JsonFilter>;
  /** Filter by the object’s `result` field. */
  result?: Maybe<JsonFilter>;
  /** Filter by the object’s `state` field. */
  state?: Maybe<QueuedProcessingTypeFilter>;
  /** Filter by the object’s `isSuccessful` field. */
  isSuccessful?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `stateLastUpdatedAt` field. */
  stateLastUpdatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `auctionByAuctionId` relation. */
  auctionByAuctionId?: Maybe<AuctionFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<AuctionPushFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<AuctionPushFilter>>;
  /** Negates the expression. */
  not?: Maybe<AuctionPushFilter>;
};

export enum AuctionPushType {
  Auction = 'AUCTION',
  AuctionImage = 'AUCTION_IMAGE',
  Item = 'ITEM',
  ItemImage = 'ITEM_IMAGE'
}

/** A filter to be used against AuctionPushType fields. All fields are combined with a logical ‘and.’ */
export type AuctionPushTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<AuctionPushType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<AuctionPushType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<AuctionPushType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<AuctionPushType>;
  /** Included in the specified list. */
  in?: Maybe<Array<AuctionPushType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<AuctionPushType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<AuctionPushType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<AuctionPushType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<AuctionPushType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<AuctionPushType>;
};

/** A connection to a list of `AuctionPush` values. */
export type AuctionPushesConnection = {
  __typename?: 'AuctionPushesConnection';
  /** A list of `AuctionPush` objects. */
  nodes: Array<AuctionPush>;
  /** A list of edges which contains the `AuctionPush` and cursor to aid in pagination. */
  edges: Array<AuctionPushesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuctionPush` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `AuctionPush` edge in the connection. */
export type AuctionPushesEdge = {
  __typename?: 'AuctionPushesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `AuctionPush` at the end of the edge. */
  node: AuctionPush;
};

/** Methods to use when ordering `AuctionPush`. */
export enum AuctionPushesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  AuctionIdAsc = 'AUCTION_ID_ASC',
  AuctionIdDesc = 'AUCTION_ID_DESC',
  DataAsc = 'DATA_ASC',
  DataDesc = 'DATA_DESC',
  ResultAsc = 'RESULT_ASC',
  ResultDesc = 'RESULT_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  IsSuccessfulAsc = 'IS_SUCCESSFUL_ASC',
  IsSuccessfulDesc = 'IS_SUCCESSFUL_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  StateLastUpdatedAtAsc = 'STATE_LAST_UPDATED_AT_ASC',
  StateLastUpdatedAtDesc = 'STATE_LAST_UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `AuctionInvoicesView` object types. All fields are combined with a logical ‘and.’ */
export type AuctionToManyAuctionInvoicesViewFilter = {
  /** Every related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AuctionInvoicesViewFilter>;
  /** Some related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AuctionInvoicesViewFilter>;
  /** No related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AuctionInvoicesViewFilter>;
};

/** A filter to be used against many `AuctionPush` object types. All fields are combined with a logical ‘and.’ */
export type AuctionToManyAuctionPushFilter = {
  /** Every related `AuctionPush` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AuctionPushFilter>;
  /** Some related `AuctionPush` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AuctionPushFilter>;
  /** No related `AuctionPush` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AuctionPushFilter>;
};

/** A filter to be used against many `BidderCreditHistory` object types. All fields are combined with a logical ‘and.’ */
export type AuctionToManyBidderCreditHistoryFilter = {
  /** Every related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<BidderCreditHistoryFilter>;
  /** Some related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<BidderCreditHistoryFilter>;
  /** No related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<BidderCreditHistoryFilter>;
};

/** A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type AuctionToManyInvoiceFilter = {
  /** Every related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceFilter>;
  /** Some related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceFilter>;
  /** No related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceFilter>;
};

/** A filter to be used against many `InvoicesSearch` object types. All fields are combined with a logical ‘and.’ */
export type AuctionToManyInvoicesSearchFilter = {
  /** Every related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoicesSearchFilter>;
  /** Some related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoicesSearchFilter>;
  /** No related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoicesSearchFilter>;
};

/** A filter to be used against many `Item` object types. All fields are combined with a logical ‘and.’ */
export type AuctionToManyItemFilter = {
  /** Every related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemFilter>;
  /** Some related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemFilter>;
  /** No related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `BidderCreditHistory`. */
export type AuctionUsersByBidderCreditHistoryAuctionIdAndCreatedByManyToManyConnection = {
  __typename?: 'AuctionUsersByBidderCreditHistoryAuctionIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<AuctionUsersByBidderCreditHistoryAuctionIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `BidderCreditHistory`. */
export type AuctionUsersByBidderCreditHistoryAuctionIdAndCreatedByManyToManyEdge = {
  __typename?: 'AuctionUsersByBidderCreditHistoryAuctionIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByCreatedBy: BidderCreditHistoriesConnection;
};


/** A `User` edge in the connection, with data from `BidderCreditHistory`. */
export type AuctionUsersByBidderCreditHistoryAuctionIdAndCreatedByManyToManyEdgeBidderCreditHistoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `User` values, with data from `Invoice`. */
export type AuctionUsersByInvoiceAuctionIdAndAlternatePaymentCreatedByManyToManyConnection = {
  __typename?: 'AuctionUsersByInvoiceAuctionIdAndAlternatePaymentCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<AuctionUsersByInvoiceAuctionIdAndAlternatePaymentCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invoice`. */
export type AuctionUsersByInvoiceAuctionIdAndAlternatePaymentCreatedByManyToManyEdge = {
  __typename?: 'AuctionUsersByInvoiceAuctionIdAndAlternatePaymentCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAlternatePaymentCreatedBy: InvoicesConnection;
};


/** A `User` edge in the connection, with data from `Invoice`. */
export type AuctionUsersByInvoiceAuctionIdAndAlternatePaymentCreatedByManyToManyEdgeInvoicesByAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `User` values, with data from `Invoice`. */
export type AuctionUsersByInvoiceAuctionIdAndProcessPaymentChangedByManyToManyConnection = {
  __typename?: 'AuctionUsersByInvoiceAuctionIdAndProcessPaymentChangedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<AuctionUsersByInvoiceAuctionIdAndProcessPaymentChangedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invoice`. */
export type AuctionUsersByInvoiceAuctionIdAndProcessPaymentChangedByManyToManyEdge = {
  __typename?: 'AuctionUsersByInvoiceAuctionIdAndProcessPaymentChangedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProcessPaymentChangedBy: InvoicesConnection;
};


/** A `User` edge in the connection, with data from `Invoice`. */
export type AuctionUsersByInvoiceAuctionIdAndProcessPaymentChangedByManyToManyEdgeInvoicesByProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndDeletedFromInvoiceByManyToManyConnection = {
  __typename?: 'AuctionUsersByItemAuctionIdAndDeletedFromInvoiceByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<AuctionUsersByItemAuctionIdAndDeletedFromInvoiceByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndDeletedFromInvoiceByManyToManyEdge = {
  __typename?: 'AuctionUsersByItemAuctionIdAndDeletedFromInvoiceByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByDeletedFromInvoiceBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndDeletedFromInvoiceByManyToManyEdgeItemsByDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndIsDeletedByManyToManyConnection = {
  __typename?: 'AuctionUsersByItemAuctionIdAndIsDeletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<AuctionUsersByItemAuctionIdAndIsDeletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndIsDeletedByManyToManyEdge = {
  __typename?: 'AuctionUsersByItemAuctionIdAndIsDeletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByIsDeletedBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndIsDeletedByManyToManyEdgeItemsByIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndSetAsideByManyToManyConnection = {
  __typename?: 'AuctionUsersByItemAuctionIdAndSetAsideByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<AuctionUsersByItemAuctionIdAndSetAsideByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndSetAsideByManyToManyEdge = {
  __typename?: 'AuctionUsersByItemAuctionIdAndSetAsideByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySetAsideBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type AuctionUsersByItemAuctionIdAndSetAsideByManyToManyEdgeItemsBySetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Auction` values. */
export type AuctionsConnection = {
  __typename?: 'AuctionsConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction` and cursor to aid in pagination. */
  edges: Array<AuctionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection. */
export type AuctionsEdge = {
  __typename?: 'AuctionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
};

/** Methods to use when ordering `Auction`. */
export enum AuctionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvIdAsc = 'INV_ID_ASC',
  InvIdDesc = 'INV_ID_DESC',
  BwIdAsc = 'BW_ID_ASC',
  BwIdDesc = 'BW_ID_DESC',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  LocationNameAsc = 'LOCATION_NAME_ASC',
  LocationNameDesc = 'LOCATION_NAME_DESC',
  DateCreatedAsc = 'DATE_CREATED_ASC',
  DateCreatedDesc = 'DATE_CREATED_DESC',
  DateUpdatedAsc = 'DATE_UPDATED_ASC',
  DateUpdatedDesc = 'DATE_UPDATED_DESC',
  DateArchivedAsc = 'DATE_ARCHIVED_ASC',
  DateArchivedDesc = 'DATE_ARCHIVED_DESC',
  DateDeletedAsc = 'DATE_DELETED_ASC',
  DateDeletedDesc = 'DATE_DELETED_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionShortAsc = 'DESCRIPTION_SHORT_ASC',
  DescriptionShortDesc = 'DESCRIPTION_SHORT_DESC',
  DescriptionFullAsc = 'DESCRIPTION_FULL_ASC',
  DescriptionFullDesc = 'DESCRIPTION_FULL_DESC',
  TaglineAsc = 'TAGLINE_ASC',
  TaglineDesc = 'TAGLINE_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  PremiumAsc = 'PREMIUM_ASC',
  PremiumDesc = 'PREMIUM_DESC',
  TaxAsc = 'TAX_ASC',
  TaxDesc = 'TAX_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  StartAtAsc = 'START_AT_ASC',
  StartAtDesc = 'START_AT_DESC',
  EndAtAsc = 'END_AT_ASC',
  EndAtDesc = 'END_AT_DESC',
  PickupStartAtAsc = 'PICKUP_START_AT_ASC',
  PickupStartAtDesc = 'PICKUP_START_AT_DESC',
  PickupEndAtAsc = 'PICKUP_END_AT_ASC',
  PickupEndAtDesc = 'PICKUP_END_AT_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsPullingAsc = 'IS_PULLING_ASC',
  IsPullingDesc = 'IS_PULLING_DESC',
  IsPulledAsc = 'IS_PULLED_ASC',
  IsPulledDesc = 'IS_PULLED_DESC',
  LastPullAtAsc = 'LAST_PULL_AT_ASC',
  LastPullAtDesc = 'LAST_PULL_AT_DESC',
  LastPullErrorAsc = 'LAST_PULL_ERROR_ASC',
  LastPullErrorDesc = 'LAST_PULL_ERROR_DESC',
  IsPushedAsc = 'IS_PUSHED_ASC',
  IsPushedDesc = 'IS_PUSHED_DESC',
  IsPushingAsc = 'IS_PUSHING_ASC',
  IsPushingDesc = 'IS_PUSHING_DESC',
  LastPushAtAsc = 'LAST_PUSH_AT_ASC',
  LastPushAtDesc = 'LAST_PUSH_AT_DESC',
  LastPushErrorAsc = 'LAST_PUSH_ERROR_ASC',
  LastPushErrorDesc = 'LAST_PUSH_ERROR_DESC',
  IsProcessingInvoicePaymentsAsc = 'IS_PROCESSING_INVOICE_PAYMENTS_ASC',
  IsProcessingInvoicePaymentsDesc = 'IS_PROCESSING_INVOICE_PAYMENTS_DESC',
  ProcessInvoicePaymentsExecCountAsc = 'PROCESS_INVOICE_PAYMENTS_EXEC_COUNT_ASC',
  ProcessInvoicePaymentsExecCountDesc = 'PROCESS_INVOICE_PAYMENTS_EXEC_COUNT_DESC',
  IsEmailingInvoicesAsc = 'IS_EMAILING_INVOICES_ASC',
  IsEmailingInvoicesDesc = 'IS_EMAILING_INVOICES_DESC',
  EmailInvoicesExecCountAsc = 'EMAIL_INVOICES_EXEC_COUNT_ASC',
  EmailInvoicesExecCountDesc = 'EMAIL_INVOICES_EXEC_COUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  LpudNumDaysAsc = 'LPUD_NUM_DAYS_ASC',
  LpudNumDaysDesc = 'LPUD_NUM_DAYS_DESC',
  ExtTypeAsc = 'EXT_TYPE_ASC',
  ExtTypeDesc = 'EXT_TYPE_DESC',
  MerIdAsc = 'MER_ID_ASC',
  MerIdDesc = 'MER_ID_DESC',
  CountCompletedInvoicesAsc = 'COUNT_COMPLETED_INVOICES_ASC',
  CountCompletedInvoicesDesc = 'COUNT_COMPLETED_INVOICES_DESC',
  CountPaidInvoicesAsc = 'COUNT_PAID_INVOICES_ASC',
  CountPaidInvoicesDesc = 'COUNT_PAID_INVOICES_DESC',
  CountTotalInvoicesAsc = 'COUNT_TOTAL_INVOICES_ASC',
  CountTotalInvoicesDesc = 'COUNT_TOTAL_INVOICES_DESC',
  CountUncompletedInvoicesAsc = 'COUNT_UNCOMPLETED_INVOICES_ASC',
  CountUncompletedInvoicesDesc = 'COUNT_UNCOMPLETED_INVOICES_DESC',
  CountUnpaidInvoicesAsc = 'COUNT_UNPAID_INVOICES_ASC',
  CountUnpaidInvoicesDesc = 'COUNT_UNPAID_INVOICES_DESC',
  PercentCompletedInvoicesAsc = 'PERCENT_COMPLETED_INVOICES_ASC',
  PercentCompletedInvoicesDesc = 'PERCENT_COMPLETED_INVOICES_DESC',
  PercentPaidInvoicesAsc = 'PERCENT_PAID_INVOICES_ASC',
  PercentPaidInvoicesDesc = 'PERCENT_PAID_INVOICES_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Auth = {
  __typename?: 'Auth';
  accessToken?: Maybe<Scalars['String']>;
  isInitialized: Scalars['Boolean'];
  query?: Maybe<Query>;
  refreshToken?: Maybe<Scalars['String']>;
};

/** All input for the `automaticCheckIn` mutation. */
export type AutomaticCheckInInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  uid: Scalars['String'];
};

/** The output of our `automaticCheckIn` mutation. */
export type AutomaticCheckInPayload = {
  __typename?: 'AutomaticCheckInPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<AutomaticCheckInRecord>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The return type of our `automaticCheckIn` mutation. */
export type AutomaticCheckInRecord = {
  __typename?: 'AutomaticCheckInRecord';
  invoiceCount?: Maybe<Scalars['Int']>;
  itemCount?: Maybe<Scalars['Int']>;
  customerName?: Maybe<Scalars['String']>;
};

/** All input for the `batchEmailInvoices` mutation. */
export type BatchEmailInvoicesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `batchEmailInvoices` mutation. */
export type BatchEmailInvoicesPayload = {
  __typename?: 'BatchEmailInvoicesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `batchProcessInvoicePayments` mutation. */
export type BatchProcessInvoicePaymentsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `batchProcessInvoicePayments` mutation. */
export type BatchProcessInvoicePaymentsPayload = {
  __typename?: 'BatchProcessInvoicePaymentsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `batchSendToPicklistQueue` mutation. */
export type BatchSendToPicklistQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationId: Scalars['Int'];
  invoiceIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `batchSendToPicklistQueue` mutation. */
export type BatchSendToPicklistQueuePayload = {
  __typename?: 'BatchSendToPicklistQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Bidder = Node & {
  __typename?: 'Bidder';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  bwId?: Maybe<Scalars['Int']>;
  paymentId?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSON']>;
  isTaxExempt: Scalars['Boolean'];
  processInvoicePayment: Scalars['Boolean'];
  processInvoicePaymentReason?: Maybe<Scalars['String']>;
  processInvoicePaymentChangedBy?: Maybe<Scalars['Int']>;
  processInvoicePaymentChangedAt?: Maybe<Scalars['Datetime']>;
  lastPullAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  extType: ExtType;
  merId?: Maybe<Scalars['String']>;
  /** Reads a single `User` that is related to this `Bidder`. */
  userByProcessInvoicePaymentChangedBy?: Maybe<User>;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderId: InvoicesConnection;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByBidderId: InvoicesSearchesConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderId: ItemsConnection;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByBidderId: BidderCreditHistoriesConnection;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCustomerId: PickupQueuesConnection;
  /** Reads and enables pagination through a set of `CustomerNote`. */
  customerNotesByCustomerId: CustomerNotesConnection;
  /** Reads and enables pagination through a set of `PickupQueuePriority`. */
  pickupQueuePrioritiesByCustomerId: PickupQueuePrioritiesConnection;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCustomerId: PicklistQueuesConnection;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByCustomerId: ShippingQueuesConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByCustomerId: PickupSessionsConnection;
  /** Reads and enables pagination through a set of `PickupCustomer`. */
  pickupCustomersByCustomerId: PickupCustomersConnection;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByCustomerId: AuctionInvoicesViewsConnection;
  /** Reads and enables pagination through a set of `CustomerActionsView`. */
  customerActionsViewsByCustomerId: CustomerActionsViewsConnection;
  /** Reads and enables pagination through a set of `CustomerAlertsView`. */
  customerAlertsViewsByCustomerId: CustomerAlertsViewsConnection;
  /** Reads and enables pagination through a set of `LivePickupQueue`. */
  livePickupQueuesByCustomerId: LivePickupQueuesConnection;
  /** Number of customer active return notes. */
  activeActionNotesCount?: Maybe<Scalars['Int']>;
  activeAlertNotesCount?: Maybe<Scalars['Int']>;
  activeAllNotesCount?: Maybe<Scalars['Int']>;
  creditBalance?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByInvoiceBidderIdAndAuctionId: BidderAuctionsByInvoiceBidderIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceBidderIdAndProcessPaymentChangedBy: BidderUsersByInvoiceBidderIdAndProcessPaymentChangedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceBidderIdAndAlternatePaymentCreatedBy: BidderUsersByInvoiceBidderIdAndAlternatePaymentCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByInvoiceBidderIdAndLocationId: BidderLocationsByInvoiceBidderIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByInvoicesSearchBidderIdAndInvoiceId: BidderInvoicesByInvoicesSearchBidderIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByInvoicesSearchBidderIdAndAuctionId: BidderAuctionsByInvoicesSearchBidderIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByItemBidderIdAndAuctionId: BidderAuctionsByItemBidderIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByItemBidderIdAndInvoiceId: BidderInvoicesByItemBidderIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByItemBidderIdAndPaymentId: BidderPaymentsByItemBidderIdAndPaymentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemBidderIdAndDeletedFromInvoiceBy: BidderUsersByItemBidderIdAndDeletedFromInvoiceByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemBidderIdAndSetAsideBy: BidderUsersByItemBidderIdAndSetAsideByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemBidderIdAndIsDeletedBy: BidderUsersByItemBidderIdAndIsDeletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderCreditHistoryBidderIdAndInvoiceId: BidderInvoicesByBidderCreditHistoryBidderIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByBidderCreditHistoryBidderIdAndAuctionId: BidderAuctionsByBidderCreditHistoryBidderIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderCreditHistoryBidderIdAndItemId: BidderItemsByBidderCreditHistoryBidderIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByBidderCreditHistoryBidderIdAndCreatedBy: BidderUsersByBidderCreditHistoryBidderIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPickupQueueCustomerIdAndItemId: BidderItemsByPickupQueueCustomerIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupQueueCustomerIdAndLocationId: BidderLocationsByPickupQueueCustomerIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueCustomerIdAndAssignedUserId: BidderUsersByPickupQueueCustomerIdAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueCustomerIdAndCompletedBy: BidderUsersByPickupQueueCustomerIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueCustomerIdAndCreatedBy: BidderUsersByPickupQueueCustomerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByPickupQueueCustomerIdAndSessionId: BidderPickupSessionsByPickupQueueCustomerIdAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCustomerNoteCustomerIdAndCreatedBy: BidderUsersByCustomerNoteCustomerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupQueuePriorityCustomerIdAndLocationId: BidderLocationsByPickupQueuePriorityCustomerIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPicklistQueueCustomerIdAndLocationId: BidderLocationsByPicklistQueueCustomerIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCustomerIdAndAssignedUserId: BidderUsersByPicklistQueueCustomerIdAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCustomerIdAndCreatedBy: BidderUsersByPicklistQueueCustomerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCustomerIdAndCompletedBy: BidderUsersByPicklistQueueCustomerIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCustomerIdAndStepUpdatedBy: BidderUsersByPicklistQueueCustomerIdAndStepUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByShippingQueueCustomerIdAndLocationId: BidderLocationsByShippingQueueCustomerIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByShippingQueueCustomerIdAndAssignedUserId: BidderUsersByShippingQueueCustomerIdAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByShippingQueueCustomerIdAndCompletedBy: BidderUsersByShippingQueueCustomerIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupSessionCustomerIdAndLocationId: BidderLocationsByPickupSessionCustomerIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupSessionCustomerIdAndUserId: BidderUsersByPickupSessionCustomerIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupCustomerCustomerIdAndLocationId: BidderLocationsByPickupCustomerCustomerIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupCustomerCustomerIdAndUserId: BidderUsersByPickupCustomerCustomerIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByAuctionInvoicesViewCustomerIdAndAuctionId: BidderAuctionsByAuctionInvoicesViewCustomerIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAuctionInvoicesViewCustomerIdAndInvoiceId: BidderInvoicesByAuctionInvoicesViewCustomerIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCustomerActionsViewCustomerIdAndCreatedBy: BidderUsersByCustomerActionsViewCustomerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByCustomerAlertsViewCustomerIdAndCreatedBy: BidderUsersByCustomerAlertsViewCustomerIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByLivePickupQueueCustomerIdAndAssignedUserId: BidderUsersByLivePickupQueueCustomerIdAndAssignedUserIdManyToManyConnection;
};


export type BidderInvoicesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type BidderInvoicesSearchesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};


export type BidderItemsByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type BidderBidderCreditHistoriesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};


export type BidderPickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};


export type BidderCustomerNotesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerNotesOrderBy>>;
  condition?: Maybe<CustomerNoteCondition>;
  filter?: Maybe<CustomerNoteFilter>;
};


export type BidderPickupQueuePrioritiesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuePrioritiesOrderBy>>;
  condition?: Maybe<PickupQueuePriorityCondition>;
  filter?: Maybe<PickupQueuePriorityFilter>;
};


export type BidderPicklistQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};


export type BidderShippingQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};


export type BidderPickupSessionsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type BidderPickupCustomersByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
  condition?: Maybe<PickupCustomerCondition>;
  filter?: Maybe<PickupCustomerFilter>;
};


export type BidderAuctionInvoicesViewsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};


export type BidderCustomerActionsViewsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerActionsViewsOrderBy>>;
  condition?: Maybe<CustomerActionsViewCondition>;
  filter?: Maybe<CustomerActionsViewFilter>;
};


export type BidderCustomerAlertsViewsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerAlertsViewsOrderBy>>;
  condition?: Maybe<CustomerAlertsViewCondition>;
  filter?: Maybe<CustomerAlertsViewFilter>;
};


export type BidderLivePickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LivePickupQueuesOrderBy>>;
  condition?: Maybe<LivePickupQueueCondition>;
  filter?: Maybe<LivePickupQueueFilter>;
};


export type BidderAuctionsByInvoiceBidderIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type BidderUsersByInvoiceBidderIdAndProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByInvoiceBidderIdAndAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderLocationsByInvoiceBidderIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type BidderInvoicesByInvoicesSearchBidderIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type BidderAuctionsByInvoicesSearchBidderIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type BidderAuctionsByItemBidderIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type BidderInvoicesByItemBidderIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type BidderPaymentsByItemBidderIdAndPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy>>;
  condition?: Maybe<PaymentCondition>;
  filter?: Maybe<PaymentFilter>;
};


export type BidderUsersByItemBidderIdAndDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByItemBidderIdAndSetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByItemBidderIdAndIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderInvoicesByBidderCreditHistoryBidderIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type BidderAuctionsByBidderCreditHistoryBidderIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type BidderItemsByBidderCreditHistoryBidderIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type BidderUsersByBidderCreditHistoryBidderIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderItemsByPickupQueueCustomerIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type BidderLocationsByPickupQueueCustomerIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type BidderUsersByPickupQueueCustomerIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByPickupQueueCustomerIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByPickupQueueCustomerIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderPickupSessionsByPickupQueueCustomerIdAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type BidderUsersByCustomerNoteCustomerIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderLocationsByPickupQueuePriorityCustomerIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type BidderLocationsByPicklistQueueCustomerIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type BidderUsersByPicklistQueueCustomerIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByPicklistQueueCustomerIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByPicklistQueueCustomerIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByPicklistQueueCustomerIdAndStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderLocationsByShippingQueueCustomerIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type BidderUsersByShippingQueueCustomerIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByShippingQueueCustomerIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderLocationsByPickupSessionCustomerIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type BidderUsersByPickupSessionCustomerIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderLocationsByPickupCustomerCustomerIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type BidderUsersByPickupCustomerCustomerIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderAuctionsByAuctionInvoicesViewCustomerIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type BidderInvoicesByAuctionInvoicesViewCustomerIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type BidderUsersByCustomerActionsViewCustomerIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByCustomerAlertsViewCustomerIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type BidderUsersByLivePickupQueueCustomerIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/** A connection to a list of `Auction` values, with data from `AuctionInvoicesView`. */
export type BidderAuctionsByAuctionInvoicesViewCustomerIdAndAuctionIdManyToManyConnection = {
  __typename?: 'BidderAuctionsByAuctionInvoicesViewCustomerIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `AuctionInvoicesView`, and the cursor to aid in pagination. */
  edges: Array<BidderAuctionsByAuctionInvoicesViewCustomerIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `AuctionInvoicesView`. */
export type BidderAuctionsByAuctionInvoicesViewCustomerIdAndAuctionIdManyToManyEdge = {
  __typename?: 'BidderAuctionsByAuctionInvoicesViewCustomerIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByAuctionId: AuctionInvoicesViewsConnection;
};


/** A `Auction` edge in the connection, with data from `AuctionInvoicesView`. */
export type BidderAuctionsByAuctionInvoicesViewCustomerIdAndAuctionIdManyToManyEdgeAuctionInvoicesViewsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};

/** A connection to a list of `Auction` values, with data from `BidderCreditHistory`. */
export type BidderAuctionsByBidderCreditHistoryBidderIdAndAuctionIdManyToManyConnection = {
  __typename?: 'BidderAuctionsByBidderCreditHistoryBidderIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<BidderAuctionsByBidderCreditHistoryBidderIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `BidderCreditHistory`. */
export type BidderAuctionsByBidderCreditHistoryBidderIdAndAuctionIdManyToManyEdge = {
  __typename?: 'BidderAuctionsByBidderCreditHistoryBidderIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByAuctionId: BidderCreditHistoriesConnection;
};


/** A `Auction` edge in the connection, with data from `BidderCreditHistory`. */
export type BidderAuctionsByBidderCreditHistoryBidderIdAndAuctionIdManyToManyEdgeBidderCreditHistoriesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Auction` values, with data from `Invoice`. */
export type BidderAuctionsByInvoiceBidderIdAndAuctionIdManyToManyConnection = {
  __typename?: 'BidderAuctionsByInvoiceBidderIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<BidderAuctionsByInvoiceBidderIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Invoice`. */
export type BidderAuctionsByInvoiceBidderIdAndAuctionIdManyToManyEdge = {
  __typename?: 'BidderAuctionsByInvoiceBidderIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAuctionId: InvoicesConnection;
};


/** A `Auction` edge in the connection, with data from `Invoice`. */
export type BidderAuctionsByInvoiceBidderIdAndAuctionIdManyToManyEdgeInvoicesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Auction` values, with data from `InvoicesSearch`. */
export type BidderAuctionsByInvoicesSearchBidderIdAndAuctionIdManyToManyConnection = {
  __typename?: 'BidderAuctionsByInvoicesSearchBidderIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `InvoicesSearch`, and the cursor to aid in pagination. */
  edges: Array<BidderAuctionsByInvoicesSearchBidderIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `InvoicesSearch`. */
export type BidderAuctionsByInvoicesSearchBidderIdAndAuctionIdManyToManyEdge = {
  __typename?: 'BidderAuctionsByInvoicesSearchBidderIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByAuctionId: InvoicesSearchesConnection;
};


/** A `Auction` edge in the connection, with data from `InvoicesSearch`. */
export type BidderAuctionsByInvoicesSearchBidderIdAndAuctionIdManyToManyEdgeInvoicesSearchesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};

/** A connection to a list of `Auction` values, with data from `Item`. */
export type BidderAuctionsByItemBidderIdAndAuctionIdManyToManyConnection = {
  __typename?: 'BidderAuctionsByItemBidderIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<BidderAuctionsByItemBidderIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Item`. */
export type BidderAuctionsByItemBidderIdAndAuctionIdManyToManyEdge = {
  __typename?: 'BidderAuctionsByItemBidderIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByAuctionId: ItemsConnection;
};


/** A `Auction` edge in the connection, with data from `Item`. */
export type BidderAuctionsByItemBidderIdAndAuctionIdManyToManyEdgeItemsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A condition to be used against `Bidder` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type BidderCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bwId` field. */
  bwId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `email` field. */
  email?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `mobileNumber` field. */
  mobileNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `location` field. */
  location?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `isTaxExempt` field. */
  isTaxExempt?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `processInvoicePayment` field. */
  processInvoicePayment?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `processInvoicePaymentReason` field. */
  processInvoicePaymentReason?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `processInvoicePaymentChangedBy` field. */
  processInvoicePaymentChangedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `processInvoicePaymentChangedAt` field. */
  processInvoicePaymentChangedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lastPullAt` field. */
  lastPullAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `extType` field. */
  extType?: Maybe<ExtType>;
  /** Checks for equality with the object’s `merId` field. */
  merId?: Maybe<Scalars['String']>;
};

/** A connection to a list of `BidderCreditHistory` values. */
export type BidderCreditHistoriesConnection = {
  __typename?: 'BidderCreditHistoriesConnection';
  /** A list of `BidderCreditHistory` objects. */
  nodes: Array<BidderCreditHistory>;
  /** A list of edges which contains the `BidderCreditHistory` and cursor to aid in pagination. */
  edges: Array<BidderCreditHistoriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `BidderCreditHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `BidderCreditHistory` edge in the connection. */
export type BidderCreditHistoriesEdge = {
  __typename?: 'BidderCreditHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `BidderCreditHistory` at the end of the edge. */
  node: BidderCreditHistory;
};

/** Methods to use when ordering `BidderCreditHistory`. */
export enum BidderCreditHistoriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TxTypeAsc = 'TX_TYPE_ASC',
  TxTypeDesc = 'TX_TYPE_DESC',
  BidderIdAsc = 'BIDDER_ID_ASC',
  BidderIdDesc = 'BIDDER_ID_DESC',
  InvoiceIdAsc = 'INVOICE_ID_ASC',
  InvoiceIdDesc = 'INVOICE_ID_DESC',
  AuctionIdAsc = 'AUCTION_ID_ASC',
  AuctionIdDesc = 'AUCTION_ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  CreditAsc = 'CREDIT_ASC',
  CreditDesc = 'CREDIT_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type BidderCreditHistory = Node & {
  __typename?: 'BidderCreditHistory';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  txType: BidderCreditHistoryTxType;
  bidderId: Scalars['Int'];
  invoiceId?: Maybe<Scalars['Int']>;
  auctionId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  credit: Scalars['Int'];
  note: Scalars['String'];
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['Int']>;
  /** Reads a single `Bidder` that is related to this `BidderCreditHistory`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `Invoice` that is related to this `BidderCreditHistory`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Auction` that is related to this `BidderCreditHistory`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Item` that is related to this `BidderCreditHistory`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `BidderCreditHistory`. */
  userByCreatedBy?: Maybe<User>;
};

/**
 * A condition to be used against `BidderCreditHistory` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type BidderCreditHistoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `txType` field. */
  txType?: Maybe<BidderCreditHistoryTxType>;
  /** Checks for equality with the object’s `bidderId` field. */
  bidderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invoiceId` field. */
  invoiceId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `auctionId` field. */
  auctionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `credit` field. */
  credit?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `note` field. */
  note?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `BidderCreditHistory` object types. All fields are combined with a logical ‘and.’ */
export type BidderCreditHistoryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `txType` field. */
  txType?: Maybe<BidderCreditHistoryTxTypeFilter>;
  /** Filter by the object’s `bidderId` field. */
  bidderId?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceId` field. */
  invoiceId?: Maybe<IntFilter>;
  /** Filter by the object’s `auctionId` field. */
  auctionId?: Maybe<IntFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `credit` field. */
  credit?: Maybe<IntFilter>;
  /** Filter by the object’s `note` field. */
  note?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `bidderByBidderId` relation. */
  bidderByBidderId?: Maybe<BidderFilter>;
  /** Filter by the object’s `invoiceByInvoiceId` relation. */
  invoiceByInvoiceId?: Maybe<InvoiceFilter>;
  /** A related `invoiceByInvoiceId` exists. */
  invoiceByInvoiceIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auctionByAuctionId` relation. */
  auctionByAuctionId?: Maybe<AuctionFilter>;
  /** A related `auctionByAuctionId` exists. */
  auctionByAuctionIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** A related `itemByItemId` exists. */
  itemByItemIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<BidderCreditHistoryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<BidderCreditHistoryFilter>>;
  /** Negates the expression. */
  not?: Maybe<BidderCreditHistoryFilter>;
};

export enum BidderCreditHistoryTxType {
  Add = 'ADD',
  Use = 'USE',
  Credit = 'CREDIT',
  Correction = 'CORRECTION'
}

/** A filter to be used against BidderCreditHistoryTxType fields. All fields are combined with a logical ‘and.’ */
export type BidderCreditHistoryTxTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<BidderCreditHistoryTxType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<BidderCreditHistoryTxType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<BidderCreditHistoryTxType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<BidderCreditHistoryTxType>;
  /** Included in the specified list. */
  in?: Maybe<Array<BidderCreditHistoryTxType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<BidderCreditHistoryTxType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<BidderCreditHistoryTxType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<BidderCreditHistoryTxType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<BidderCreditHistoryTxType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<BidderCreditHistoryTxType>;
};

/** A filter to be used against `Bidder` object types. All fields are combined with a logical ‘and.’ */
export type BidderFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `bwId` field. */
  bwId?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentId` field. */
  paymentId?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: Maybe<StringFilter>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `mobileNumber` field. */
  mobileNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `location` field. */
  location?: Maybe<JsonFilter>;
  /** Filter by the object’s `isTaxExempt` field. */
  isTaxExempt?: Maybe<BooleanFilter>;
  /** Filter by the object’s `processInvoicePayment` field. */
  processInvoicePayment?: Maybe<BooleanFilter>;
  /** Filter by the object’s `processInvoicePaymentReason` field. */
  processInvoicePaymentReason?: Maybe<StringFilter>;
  /** Filter by the object’s `processInvoicePaymentChangedBy` field. */
  processInvoicePaymentChangedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `processInvoicePaymentChangedAt` field. */
  processInvoicePaymentChangedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lastPullAt` field. */
  lastPullAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `extType` field. */
  extType?: Maybe<ExtTypeFilter>;
  /** Filter by the object’s `merId` field. */
  merId?: Maybe<StringFilter>;
  /** Filter by the object’s `activeActionNotesCount` field. */
  activeActionNotesCount?: Maybe<IntFilter>;
  /** Filter by the object’s `activeAlertNotesCount` field. */
  activeAlertNotesCount?: Maybe<IntFilter>;
  /** Filter by the object’s `activeAllNotesCount` field. */
  activeAllNotesCount?: Maybe<IntFilter>;
  /** Filter by the object’s `creditBalance` field. */
  creditBalance?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `invoicesByBidderId` relation. */
  invoicesByBidderId?: Maybe<BidderToManyInvoiceFilter>;
  /** Some related `invoicesByBidderId` exist. */
  invoicesByBidderIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoicesSearchesByBidderId` relation. */
  invoicesSearchesByBidderId?: Maybe<BidderToManyInvoicesSearchFilter>;
  /** Some related `invoicesSearchesByBidderId` exist. */
  invoicesSearchesByBidderIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemsByBidderId` relation. */
  itemsByBidderId?: Maybe<BidderToManyItemFilter>;
  /** Some related `itemsByBidderId` exist. */
  itemsByBidderIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderCreditHistoriesByBidderId` relation. */
  bidderCreditHistoriesByBidderId?: Maybe<BidderToManyBidderCreditHistoryFilter>;
  /** Some related `bidderCreditHistoriesByBidderId` exist. */
  bidderCreditHistoriesByBidderIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupQueuesByCustomerId` relation. */
  pickupQueuesByCustomerId?: Maybe<BidderToManyPickupQueueFilter>;
  /** Some related `pickupQueuesByCustomerId` exist. */
  pickupQueuesByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerNotesByCustomerId` relation. */
  customerNotesByCustomerId?: Maybe<BidderToManyCustomerNoteFilter>;
  /** Some related `customerNotesByCustomerId` exist. */
  customerNotesByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupQueuePrioritiesByCustomerId` relation. */
  pickupQueuePrioritiesByCustomerId?: Maybe<BidderToManyPickupQueuePriorityFilter>;
  /** Some related `pickupQueuePrioritiesByCustomerId` exist. */
  pickupQueuePrioritiesByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `picklistQueuesByCustomerId` relation. */
  picklistQueuesByCustomerId?: Maybe<BidderToManyPicklistQueueFilter>;
  /** Some related `picklistQueuesByCustomerId` exist. */
  picklistQueuesByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `shippingQueuesByCustomerId` relation. */
  shippingQueuesByCustomerId?: Maybe<BidderToManyShippingQueueFilter>;
  /** Some related `shippingQueuesByCustomerId` exist. */
  shippingQueuesByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupSessionsByCustomerId` relation. */
  pickupSessionsByCustomerId?: Maybe<BidderToManyPickupSessionFilter>;
  /** Some related `pickupSessionsByCustomerId` exist. */
  pickupSessionsByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupCustomersByCustomerId` relation. */
  pickupCustomersByCustomerId?: Maybe<BidderToManyPickupCustomerFilter>;
  /** Some related `pickupCustomersByCustomerId` exist. */
  pickupCustomersByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auctionInvoicesViewsByCustomerId` relation. */
  auctionInvoicesViewsByCustomerId?: Maybe<BidderToManyAuctionInvoicesViewFilter>;
  /** Some related `auctionInvoicesViewsByCustomerId` exist. */
  auctionInvoicesViewsByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerActionsViewsByCustomerId` relation. */
  customerActionsViewsByCustomerId?: Maybe<BidderToManyCustomerActionsViewFilter>;
  /** Some related `customerActionsViewsByCustomerId` exist. */
  customerActionsViewsByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerAlertsViewsByCustomerId` relation. */
  customerAlertsViewsByCustomerId?: Maybe<BidderToManyCustomerAlertsViewFilter>;
  /** Some related `customerAlertsViewsByCustomerId` exist. */
  customerAlertsViewsByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `livePickupQueuesByCustomerId` relation. */
  livePickupQueuesByCustomerId?: Maybe<BidderToManyLivePickupQueueFilter>;
  /** Some related `livePickupQueuesByCustomerId` exist. */
  livePickupQueuesByCustomerIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByProcessInvoicePaymentChangedBy` relation. */
  userByProcessInvoicePaymentChangedBy?: Maybe<UserFilter>;
  /** A related `userByProcessInvoicePaymentChangedBy` exists. */
  userByProcessInvoicePaymentChangedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<BidderFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<BidderFilter>>;
  /** Negates the expression. */
  not?: Maybe<BidderFilter>;
};

/** A connection to a list of `Invoice` values, with data from `AuctionInvoicesView`. */
export type BidderInvoicesByAuctionInvoicesViewCustomerIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'BidderInvoicesByAuctionInvoicesViewCustomerIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `AuctionInvoicesView`, and the cursor to aid in pagination. */
  edges: Array<BidderInvoicesByAuctionInvoicesViewCustomerIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `AuctionInvoicesView`. */
export type BidderInvoicesByAuctionInvoicesViewCustomerIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'BidderInvoicesByAuctionInvoicesViewCustomerIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByInvoiceId: AuctionInvoicesViewsConnection;
};


/** A `Invoice` edge in the connection, with data from `AuctionInvoicesView`. */
export type BidderInvoicesByAuctionInvoicesViewCustomerIdAndInvoiceIdManyToManyEdgeAuctionInvoicesViewsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};

/** A connection to a list of `Invoice` values, with data from `BidderCreditHistory`. */
export type BidderInvoicesByBidderCreditHistoryBidderIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'BidderInvoicesByBidderCreditHistoryBidderIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<BidderInvoicesByBidderCreditHistoryBidderIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `BidderCreditHistory`. */
export type BidderInvoicesByBidderCreditHistoryBidderIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'BidderInvoicesByBidderCreditHistoryBidderIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByInvoiceId: BidderCreditHistoriesConnection;
};


/** A `Invoice` edge in the connection, with data from `BidderCreditHistory`. */
export type BidderInvoicesByBidderCreditHistoryBidderIdAndInvoiceIdManyToManyEdgeBidderCreditHistoriesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Invoice` values, with data from `InvoicesSearch`. */
export type BidderInvoicesByInvoicesSearchBidderIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'BidderInvoicesByInvoicesSearchBidderIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `InvoicesSearch`, and the cursor to aid in pagination. */
  edges: Array<BidderInvoicesByInvoicesSearchBidderIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `InvoicesSearch`. */
export type BidderInvoicesByInvoicesSearchBidderIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'BidderInvoicesByInvoicesSearchBidderIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByInvoiceId: InvoicesSearchesConnection;
};


/** A `Invoice` edge in the connection, with data from `InvoicesSearch`. */
export type BidderInvoicesByInvoicesSearchBidderIdAndInvoiceIdManyToManyEdgeInvoicesSearchesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};

/** A connection to a list of `Invoice` values, with data from `Item`. */
export type BidderInvoicesByItemBidderIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'BidderInvoicesByItemBidderIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<BidderInvoicesByItemBidderIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `Item`. */
export type BidderInvoicesByItemBidderIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'BidderInvoicesByItemBidderIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceId: ItemsConnection;
};


/** A `Invoice` edge in the connection, with data from `Item`. */
export type BidderInvoicesByItemBidderIdAndInvoiceIdManyToManyEdgeItemsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Item` values, with data from `BidderCreditHistory`. */
export type BidderItemsByBidderCreditHistoryBidderIdAndItemIdManyToManyConnection = {
  __typename?: 'BidderItemsByBidderCreditHistoryBidderIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<BidderItemsByBidderCreditHistoryBidderIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `BidderCreditHistory`. */
export type BidderItemsByBidderCreditHistoryBidderIdAndItemIdManyToManyEdge = {
  __typename?: 'BidderItemsByBidderCreditHistoryBidderIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByItemId: BidderCreditHistoriesConnection;
};


/** A `Item` edge in the connection, with data from `BidderCreditHistory`. */
export type BidderItemsByBidderCreditHistoryBidderIdAndItemIdManyToManyEdgeBidderCreditHistoriesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Item` values, with data from `PickupQueue`. */
export type BidderItemsByPickupQueueCustomerIdAndItemIdManyToManyConnection = {
  __typename?: 'BidderItemsByPickupQueueCustomerIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderItemsByPickupQueueCustomerIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type BidderItemsByPickupQueueCustomerIdAndItemIdManyToManyEdge = {
  __typename?: 'BidderItemsByPickupQueueCustomerIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByItemId: PickupQueuesConnection;
};


/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type BidderItemsByPickupQueueCustomerIdAndItemIdManyToManyEdgePickupQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `Invoice`. */
export type BidderLocationsByInvoiceBidderIdAndLocationIdManyToManyConnection = {
  __typename?: 'BidderLocationsByInvoiceBidderIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<BidderLocationsByInvoiceBidderIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `Invoice`. */
export type BidderLocationsByInvoiceBidderIdAndLocationIdManyToManyEdge = {
  __typename?: 'BidderLocationsByInvoiceBidderIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByLocationId: InvoicesConnection;
};


/** A `Location` edge in the connection, with data from `Invoice`. */
export type BidderLocationsByInvoiceBidderIdAndLocationIdManyToManyEdgeInvoicesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Location` values, with data from `PicklistQueue`. */
export type BidderLocationsByPicklistQueueCustomerIdAndLocationIdManyToManyConnection = {
  __typename?: 'BidderLocationsByPicklistQueueCustomerIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderLocationsByPicklistQueueCustomerIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type BidderLocationsByPicklistQueueCustomerIdAndLocationIdManyToManyEdge = {
  __typename?: 'BidderLocationsByPicklistQueueCustomerIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByLocationId: PicklistQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type BidderLocationsByPicklistQueueCustomerIdAndLocationIdManyToManyEdgePicklistQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupCustomer`. */
export type BidderLocationsByPickupCustomerCustomerIdAndLocationIdManyToManyConnection = {
  __typename?: 'BidderLocationsByPickupCustomerCustomerIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupCustomer`, and the cursor to aid in pagination. */
  edges: Array<BidderLocationsByPickupCustomerCustomerIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupCustomer`. */
export type BidderLocationsByPickupCustomerCustomerIdAndLocationIdManyToManyEdge = {
  __typename?: 'BidderLocationsByPickupCustomerCustomerIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  id: Scalars['UUID'];
  userId?: Maybe<Scalars['Int']>;
  number: Scalars['Int'];
  customerNotAvailableAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  source: Scalars['String'];
};

/** A connection to a list of `Location` values, with data from `PickupQueue`. */
export type BidderLocationsByPickupQueueCustomerIdAndLocationIdManyToManyConnection = {
  __typename?: 'BidderLocationsByPickupQueueCustomerIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderLocationsByPickupQueueCustomerIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type BidderLocationsByPickupQueueCustomerIdAndLocationIdManyToManyEdge = {
  __typename?: 'BidderLocationsByPickupQueueCustomerIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByLocationId: PickupQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type BidderLocationsByPickupQueueCustomerIdAndLocationIdManyToManyEdgePickupQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupQueuePriority`. */
export type BidderLocationsByPickupQueuePriorityCustomerIdAndLocationIdManyToManyConnection = {
  __typename?: 'BidderLocationsByPickupQueuePriorityCustomerIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupQueuePriority`, and the cursor to aid in pagination. */
  edges: Array<BidderLocationsByPickupQueuePriorityCustomerIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupQueuePriority`. */
export type BidderLocationsByPickupQueuePriorityCustomerIdAndLocationIdManyToManyEdge = {
  __typename?: 'BidderLocationsByPickupQueuePriorityCustomerIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  priorityAt: Scalars['Datetime'];
};

/** A connection to a list of `Location` values, with data from `PickupSession`. */
export type BidderLocationsByPickupSessionCustomerIdAndLocationIdManyToManyConnection = {
  __typename?: 'BidderLocationsByPickupSessionCustomerIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupSession`, and the cursor to aid in pagination. */
  edges: Array<BidderLocationsByPickupSessionCustomerIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupSession`. */
export type BidderLocationsByPickupSessionCustomerIdAndLocationIdManyToManyEdge = {
  __typename?: 'BidderLocationsByPickupSessionCustomerIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByLocationId: PickupSessionsConnection;
};


/** A `Location` edge in the connection, with data from `PickupSession`. */
export type BidderLocationsByPickupSessionCustomerIdAndLocationIdManyToManyEdgePickupSessionsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};

/** A connection to a list of `Location` values, with data from `ShippingQueue`. */
export type BidderLocationsByShippingQueueCustomerIdAndLocationIdManyToManyConnection = {
  __typename?: 'BidderLocationsByShippingQueueCustomerIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderLocationsByShippingQueueCustomerIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `ShippingQueue`. */
export type BidderLocationsByShippingQueueCustomerIdAndLocationIdManyToManyEdge = {
  __typename?: 'BidderLocationsByShippingQueueCustomerIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByLocationId: ShippingQueuesConnection;
};


/** A `Location` edge in the connection, with data from `ShippingQueue`. */
export type BidderLocationsByShippingQueueCustomerIdAndLocationIdManyToManyEdgeShippingQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** Represents an update to a `Bidder`. Fields that are set will be updated. */
export type BidderPatch = {
  bwId?: Maybe<Scalars['Int']>;
  paymentId?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['JSON']>;
  isTaxExempt?: Maybe<Scalars['Boolean']>;
  processInvoicePayment?: Maybe<Scalars['Boolean']>;
  processInvoicePaymentReason?: Maybe<Scalars['String']>;
  processInvoicePaymentChangedBy?: Maybe<Scalars['Int']>;
  processInvoicePaymentChangedAt?: Maybe<Scalars['Datetime']>;
  lastPullAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Payment` values, with data from `Item`. */
export type BidderPaymentsByItemBidderIdAndPaymentIdManyToManyConnection = {
  __typename?: 'BidderPaymentsByItemBidderIdAndPaymentIdManyToManyConnection';
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** A list of edges which contains the `Payment`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<BidderPaymentsByItemBidderIdAndPaymentIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection, with data from `Item`. */
export type BidderPaymentsByItemBidderIdAndPaymentIdManyToManyEdge = {
  __typename?: 'BidderPaymentsByItemBidderIdAndPaymentIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPaymentId: ItemsConnection;
};


/** A `Payment` edge in the connection, with data from `Item`. */
export type BidderPaymentsByItemBidderIdAndPaymentIdManyToManyEdgeItemsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `PickupSession` values, with data from `PickupQueue`. */
export type BidderPickupSessionsByPickupQueueCustomerIdAndSessionIdManyToManyConnection = {
  __typename?: 'BidderPickupSessionsByPickupQueueCustomerIdAndSessionIdManyToManyConnection';
  /** A list of `PickupSession` objects. */
  nodes: Array<PickupSession>;
  /** A list of edges which contains the `PickupSession`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderPickupSessionsByPickupQueueCustomerIdAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type BidderPickupSessionsByPickupQueueCustomerIdAndSessionIdManyToManyEdge = {
  __typename?: 'BidderPickupSessionsByPickupQueueCustomerIdAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupSession` at the end of the edge. */
  node: PickupSession;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesBySessionId: PickupQueuesConnection;
};


/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type BidderPickupSessionsByPickupQueueCustomerIdAndSessionIdManyToManyEdgePickupQueuesBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A filter to be used against many `AuctionInvoicesView` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyAuctionInvoicesViewFilter = {
  /** Every related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AuctionInvoicesViewFilter>;
  /** Some related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AuctionInvoicesViewFilter>;
  /** No related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AuctionInvoicesViewFilter>;
};

/** A filter to be used against many `BidderCreditHistory` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyBidderCreditHistoryFilter = {
  /** Every related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<BidderCreditHistoryFilter>;
  /** Some related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<BidderCreditHistoryFilter>;
  /** No related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<BidderCreditHistoryFilter>;
};

/** A filter to be used against many `CustomerActionsView` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyCustomerActionsViewFilter = {
  /** Every related `CustomerActionsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CustomerActionsViewFilter>;
  /** Some related `CustomerActionsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CustomerActionsViewFilter>;
  /** No related `CustomerActionsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CustomerActionsViewFilter>;
};

/** A filter to be used against many `CustomerAlertsView` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyCustomerAlertsViewFilter = {
  /** Every related `CustomerAlertsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CustomerAlertsViewFilter>;
  /** Some related `CustomerAlertsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CustomerAlertsViewFilter>;
  /** No related `CustomerAlertsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CustomerAlertsViewFilter>;
};

/** A filter to be used against many `CustomerNote` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyCustomerNoteFilter = {
  /** Every related `CustomerNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CustomerNoteFilter>;
  /** Some related `CustomerNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CustomerNoteFilter>;
  /** No related `CustomerNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CustomerNoteFilter>;
};

/** A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyInvoiceFilter = {
  /** Every related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceFilter>;
  /** Some related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceFilter>;
  /** No related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceFilter>;
};

/** A filter to be used against many `InvoicesSearch` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyInvoicesSearchFilter = {
  /** Every related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoicesSearchFilter>;
  /** Some related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoicesSearchFilter>;
  /** No related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoicesSearchFilter>;
};

/** A filter to be used against many `Item` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyItemFilter = {
  /** Every related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemFilter>;
  /** Some related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemFilter>;
  /** No related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemFilter>;
};

/** A filter to be used against many `LivePickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyLivePickupQueueFilter = {
  /** Every related `LivePickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LivePickupQueueFilter>;
  /** Some related `LivePickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LivePickupQueueFilter>;
  /** No related `LivePickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LivePickupQueueFilter>;
};

/** A filter to be used against many `PicklistQueue` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyPicklistQueueFilter = {
  /** Every related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PicklistQueueFilter>;
  /** Some related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PicklistQueueFilter>;
  /** No related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PicklistQueueFilter>;
};

/** A filter to be used against many `PickupCustomer` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyPickupCustomerFilter = {
  /** Every related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupCustomerFilter>;
  /** Some related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupCustomerFilter>;
  /** No related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupCustomerFilter>;
};

/** A filter to be used against many `PickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyPickupQueueFilter = {
  /** Every related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupQueueFilter>;
  /** Some related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupQueueFilter>;
  /** No related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupQueueFilter>;
};

/** A filter to be used against many `PickupQueuePriority` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyPickupQueuePriorityFilter = {
  /** Every related `PickupQueuePriority` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupQueuePriorityFilter>;
  /** Some related `PickupQueuePriority` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupQueuePriorityFilter>;
  /** No related `PickupQueuePriority` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupQueuePriorityFilter>;
};

/** A filter to be used against many `PickupSession` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyPickupSessionFilter = {
  /** Every related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupSessionFilter>;
  /** Some related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupSessionFilter>;
  /** No related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupSessionFilter>;
};

/** A filter to be used against many `ShippingQueue` object types. All fields are combined with a logical ‘and.’ */
export type BidderToManyShippingQueueFilter = {
  /** Every related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ShippingQueueFilter>;
  /** Some related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ShippingQueueFilter>;
  /** No related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `User` values, with data from `BidderCreditHistory`. */
export type BidderUsersByBidderCreditHistoryBidderIdAndCreatedByManyToManyConnection = {
  __typename?: 'BidderUsersByBidderCreditHistoryBidderIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByBidderCreditHistoryBidderIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `BidderCreditHistory`. */
export type BidderUsersByBidderCreditHistoryBidderIdAndCreatedByManyToManyEdge = {
  __typename?: 'BidderUsersByBidderCreditHistoryBidderIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByCreatedBy: BidderCreditHistoriesConnection;
};


/** A `User` edge in the connection, with data from `BidderCreditHistory`. */
export type BidderUsersByBidderCreditHistoryBidderIdAndCreatedByManyToManyEdgeBidderCreditHistoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `User` values, with data from `CustomerActionsView`. */
export type BidderUsersByCustomerActionsViewCustomerIdAndCreatedByManyToManyConnection = {
  __typename?: 'BidderUsersByCustomerActionsViewCustomerIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `CustomerActionsView`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByCustomerActionsViewCustomerIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `CustomerActionsView`. */
export type BidderUsersByCustomerActionsViewCustomerIdAndCreatedByManyToManyEdge = {
  __typename?: 'BidderUsersByCustomerActionsViewCustomerIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `CustomerActionsView`. */
  customerActionsViewsByCreatedBy: CustomerActionsViewsConnection;
};


/** A `User` edge in the connection, with data from `CustomerActionsView`. */
export type BidderUsersByCustomerActionsViewCustomerIdAndCreatedByManyToManyEdgeCustomerActionsViewsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerActionsViewsOrderBy>>;
  condition?: Maybe<CustomerActionsViewCondition>;
  filter?: Maybe<CustomerActionsViewFilter>;
};

/** A connection to a list of `User` values, with data from `CustomerAlertsView`. */
export type BidderUsersByCustomerAlertsViewCustomerIdAndCreatedByManyToManyConnection = {
  __typename?: 'BidderUsersByCustomerAlertsViewCustomerIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `CustomerAlertsView`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByCustomerAlertsViewCustomerIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `CustomerAlertsView`. */
export type BidderUsersByCustomerAlertsViewCustomerIdAndCreatedByManyToManyEdge = {
  __typename?: 'BidderUsersByCustomerAlertsViewCustomerIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `CustomerAlertsView`. */
  customerAlertsViewsByCreatedBy: CustomerAlertsViewsConnection;
};


/** A `User` edge in the connection, with data from `CustomerAlertsView`. */
export type BidderUsersByCustomerAlertsViewCustomerIdAndCreatedByManyToManyEdgeCustomerAlertsViewsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerAlertsViewsOrderBy>>;
  condition?: Maybe<CustomerAlertsViewCondition>;
  filter?: Maybe<CustomerAlertsViewFilter>;
};

/** A connection to a list of `User` values, with data from `CustomerNote`. */
export type BidderUsersByCustomerNoteCustomerIdAndCreatedByManyToManyConnection = {
  __typename?: 'BidderUsersByCustomerNoteCustomerIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `CustomerNote`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByCustomerNoteCustomerIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `CustomerNote`. */
export type BidderUsersByCustomerNoteCustomerIdAndCreatedByManyToManyEdge = {
  __typename?: 'BidderUsersByCustomerNoteCustomerIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `CustomerNote`. */
  customerNotesByCreatedBy: CustomerNotesConnection;
};


/** A `User` edge in the connection, with data from `CustomerNote`. */
export type BidderUsersByCustomerNoteCustomerIdAndCreatedByManyToManyEdgeCustomerNotesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerNotesOrderBy>>;
  condition?: Maybe<CustomerNoteCondition>;
  filter?: Maybe<CustomerNoteFilter>;
};

/** A connection to a list of `User` values, with data from `Invoice`. */
export type BidderUsersByInvoiceBidderIdAndAlternatePaymentCreatedByManyToManyConnection = {
  __typename?: 'BidderUsersByInvoiceBidderIdAndAlternatePaymentCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByInvoiceBidderIdAndAlternatePaymentCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invoice`. */
export type BidderUsersByInvoiceBidderIdAndAlternatePaymentCreatedByManyToManyEdge = {
  __typename?: 'BidderUsersByInvoiceBidderIdAndAlternatePaymentCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAlternatePaymentCreatedBy: InvoicesConnection;
};


/** A `User` edge in the connection, with data from `Invoice`. */
export type BidderUsersByInvoiceBidderIdAndAlternatePaymentCreatedByManyToManyEdgeInvoicesByAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `User` values, with data from `Invoice`. */
export type BidderUsersByInvoiceBidderIdAndProcessPaymentChangedByManyToManyConnection = {
  __typename?: 'BidderUsersByInvoiceBidderIdAndProcessPaymentChangedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByInvoiceBidderIdAndProcessPaymentChangedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invoice`. */
export type BidderUsersByInvoiceBidderIdAndProcessPaymentChangedByManyToManyEdge = {
  __typename?: 'BidderUsersByInvoiceBidderIdAndProcessPaymentChangedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProcessPaymentChangedBy: InvoicesConnection;
};


/** A `User` edge in the connection, with data from `Invoice`. */
export type BidderUsersByInvoiceBidderIdAndProcessPaymentChangedByManyToManyEdgeInvoicesByProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type BidderUsersByItemBidderIdAndDeletedFromInvoiceByManyToManyConnection = {
  __typename?: 'BidderUsersByItemBidderIdAndDeletedFromInvoiceByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByItemBidderIdAndDeletedFromInvoiceByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type BidderUsersByItemBidderIdAndDeletedFromInvoiceByManyToManyEdge = {
  __typename?: 'BidderUsersByItemBidderIdAndDeletedFromInvoiceByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByDeletedFromInvoiceBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type BidderUsersByItemBidderIdAndDeletedFromInvoiceByManyToManyEdgeItemsByDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type BidderUsersByItemBidderIdAndIsDeletedByManyToManyConnection = {
  __typename?: 'BidderUsersByItemBidderIdAndIsDeletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByItemBidderIdAndIsDeletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type BidderUsersByItemBidderIdAndIsDeletedByManyToManyEdge = {
  __typename?: 'BidderUsersByItemBidderIdAndIsDeletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByIsDeletedBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type BidderUsersByItemBidderIdAndIsDeletedByManyToManyEdgeItemsByIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type BidderUsersByItemBidderIdAndSetAsideByManyToManyConnection = {
  __typename?: 'BidderUsersByItemBidderIdAndSetAsideByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByItemBidderIdAndSetAsideByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type BidderUsersByItemBidderIdAndSetAsideByManyToManyEdge = {
  __typename?: 'BidderUsersByItemBidderIdAndSetAsideByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySetAsideBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type BidderUsersByItemBidderIdAndSetAsideByManyToManyEdgeItemsBySetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `LivePickupQueue`. */
export type BidderUsersByLivePickupQueueCustomerIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'BidderUsersByLivePickupQueueCustomerIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `LivePickupQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByLivePickupQueueCustomerIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `LivePickupQueue`. */
export type BidderUsersByLivePickupQueueCustomerIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'BidderUsersByLivePickupQueueCustomerIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `LivePickupQueue`. */
  livePickupQueuesByAssignedUserId: LivePickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `LivePickupQueue`. */
export type BidderUsersByLivePickupQueueCustomerIdAndAssignedUserIdManyToManyEdgeLivePickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LivePickupQueuesOrderBy>>;
  condition?: Maybe<LivePickupQueueCondition>;
  filter?: Maybe<LivePickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'BidderUsersByPicklistQueueCustomerIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPicklistQueueCustomerIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'BidderUsersByPicklistQueueCustomerIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByAssignedUserId: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndAssignedUserIdManyToManyEdgePicklistQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndCompletedByManyToManyConnection = {
  __typename?: 'BidderUsersByPicklistQueueCustomerIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPicklistQueueCustomerIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndCompletedByManyToManyEdge = {
  __typename?: 'BidderUsersByPicklistQueueCustomerIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCompletedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndCompletedByManyToManyEdgePicklistQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndCreatedByManyToManyConnection = {
  __typename?: 'BidderUsersByPicklistQueueCustomerIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPicklistQueueCustomerIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndCreatedByManyToManyEdge = {
  __typename?: 'BidderUsersByPicklistQueueCustomerIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCreatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndCreatedByManyToManyEdgePicklistQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndStepUpdatedByManyToManyConnection = {
  __typename?: 'BidderUsersByPicklistQueueCustomerIdAndStepUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPicklistQueueCustomerIdAndStepUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndStepUpdatedByManyToManyEdge = {
  __typename?: 'BidderUsersByPicklistQueueCustomerIdAndStepUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByStepUpdatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type BidderUsersByPicklistQueueCustomerIdAndStepUpdatedByManyToManyEdgePicklistQueuesByStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupCustomer`. */
export type BidderUsersByPickupCustomerCustomerIdAndUserIdManyToManyConnection = {
  __typename?: 'BidderUsersByPickupCustomerCustomerIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupCustomer`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPickupCustomerCustomerIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupCustomer`. */
export type BidderUsersByPickupCustomerCustomerIdAndUserIdManyToManyEdge = {
  __typename?: 'BidderUsersByPickupCustomerCustomerIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupCustomer`. */
  pickupCustomersByUserId: PickupCustomersConnection;
};


/** A `User` edge in the connection, with data from `PickupCustomer`. */
export type BidderUsersByPickupCustomerCustomerIdAndUserIdManyToManyEdgePickupCustomersByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
  condition?: Maybe<PickupCustomerCondition>;
  filter?: Maybe<PickupCustomerFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'BidderUsersByPickupQueueCustomerIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPickupQueueCustomerIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'BidderUsersByPickupQueueCustomerIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByAssignedUserId: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndAssignedUserIdManyToManyEdgePickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndCompletedByManyToManyConnection = {
  __typename?: 'BidderUsersByPickupQueueCustomerIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPickupQueueCustomerIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndCompletedByManyToManyEdge = {
  __typename?: 'BidderUsersByPickupQueueCustomerIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCompletedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndCompletedByManyToManyEdgePickupQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndCreatedByManyToManyConnection = {
  __typename?: 'BidderUsersByPickupQueueCustomerIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPickupQueueCustomerIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndCreatedByManyToManyEdge = {
  __typename?: 'BidderUsersByPickupQueueCustomerIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCreatedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type BidderUsersByPickupQueueCustomerIdAndCreatedByManyToManyEdgePickupQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupSession`. */
export type BidderUsersByPickupSessionCustomerIdAndUserIdManyToManyConnection = {
  __typename?: 'BidderUsersByPickupSessionCustomerIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupSession`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByPickupSessionCustomerIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupSession`. */
export type BidderUsersByPickupSessionCustomerIdAndUserIdManyToManyEdge = {
  __typename?: 'BidderUsersByPickupSessionCustomerIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByUserId: PickupSessionsConnection;
};


/** A `User` edge in the connection, with data from `PickupSession`. */
export type BidderUsersByPickupSessionCustomerIdAndUserIdManyToManyEdgePickupSessionsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};

/** A connection to a list of `User` values, with data from `ShippingQueue`. */
export type BidderUsersByShippingQueueCustomerIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'BidderUsersByShippingQueueCustomerIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByShippingQueueCustomerIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type BidderUsersByShippingQueueCustomerIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'BidderUsersByShippingQueueCustomerIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByAssignedUserId: ShippingQueuesConnection;
};


/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type BidderUsersByShippingQueueCustomerIdAndAssignedUserIdManyToManyEdgeShippingQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ShippingQueue`. */
export type BidderUsersByShippingQueueCustomerIdAndCompletedByManyToManyConnection = {
  __typename?: 'BidderUsersByShippingQueueCustomerIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<BidderUsersByShippingQueueCustomerIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type BidderUsersByShippingQueueCustomerIdAndCompletedByManyToManyEdge = {
  __typename?: 'BidderUsersByShippingQueueCustomerIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByCompletedBy: ShippingQueuesConnection;
};


/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type BidderUsersByShippingQueueCustomerIdAndCompletedByManyToManyEdgeShippingQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `Bidder` values. */
export type BiddersConnection = {
  __typename?: 'BiddersConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder` and cursor to aid in pagination. */
  edges: Array<BiddersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection. */
export type BiddersEdge = {
  __typename?: 'BiddersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
};

/** Methods to use when ordering `Bidder`. */
export enum BiddersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  BwIdAsc = 'BW_ID_ASC',
  BwIdDesc = 'BW_ID_DESC',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  MobileNumberAsc = 'MOBILE_NUMBER_ASC',
  MobileNumberDesc = 'MOBILE_NUMBER_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  IsTaxExemptAsc = 'IS_TAX_EXEMPT_ASC',
  IsTaxExemptDesc = 'IS_TAX_EXEMPT_DESC',
  ProcessInvoicePaymentAsc = 'PROCESS_INVOICE_PAYMENT_ASC',
  ProcessInvoicePaymentDesc = 'PROCESS_INVOICE_PAYMENT_DESC',
  ProcessInvoicePaymentReasonAsc = 'PROCESS_INVOICE_PAYMENT_REASON_ASC',
  ProcessInvoicePaymentReasonDesc = 'PROCESS_INVOICE_PAYMENT_REASON_DESC',
  ProcessInvoicePaymentChangedByAsc = 'PROCESS_INVOICE_PAYMENT_CHANGED_BY_ASC',
  ProcessInvoicePaymentChangedByDesc = 'PROCESS_INVOICE_PAYMENT_CHANGED_BY_DESC',
  ProcessInvoicePaymentChangedAtAsc = 'PROCESS_INVOICE_PAYMENT_CHANGED_AT_ASC',
  ProcessInvoicePaymentChangedAtDesc = 'PROCESS_INVOICE_PAYMENT_CHANGED_AT_DESC',
  LastPullAtAsc = 'LAST_PULL_AT_ASC',
  LastPullAtDesc = 'LAST_PULL_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  ExtTypeAsc = 'EXT_TYPE_ASC',
  ExtTypeDesc = 'EXT_TYPE_DESC',
  MerIdAsc = 'MER_ID_ASC',
  MerIdDesc = 'MER_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigFloat']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigFloat']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigFloat']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigFloat']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigFloat']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigFloat']>;
};


/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['BigInt']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['BigInt']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['BigInt']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['BigInt']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['BigInt']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['BigInt']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['BigInt']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['BigInt']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['BigInt']>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Boolean']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Boolean']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Boolean']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Boolean']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Boolean']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Boolean']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Boolean']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Boolean']>;
};

/** All input for the `changeUserPassword` mutation. */
export type ChangeUserPasswordInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};

/** The output of our `changeUserPassword` mutation. */
export type ChangeUserPasswordPayload = {
  __typename?: 'ChangeUserPasswordPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `chargeInvoicePayment` mutation. */
export type ChargeInvoicePaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
  jobQueueId?: Maybe<Scalars['Int']>;
};

/** The output of our `chargeInvoicePayment` mutation. */
export type ChargeInvoicePaymentPayload = {
  __typename?: 'ChargeInvoicePaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CheckAppNewUpdateInput = {
  currentVersion?: Maybe<Scalars['String']>;
};

export type CheckAppNewUpdatePayload = {
  __typename?: 'CheckAppNewUpdatePayload';
  shouldUpdate: Scalars['Boolean'];
};

/** The return type of our `checkItemForRelist` query. */
export type CheckItemForRelistRecord = {
  __typename?: 'CheckItemForRelistRecord';
  canRelist?: Maybe<Scalars['Boolean']>;
  isSetAside?: Maybe<Scalars['Boolean']>;
  relistDate?: Maybe<Scalars['String']>;
  itemId?: Maybe<Scalars['Int']>;
};

/** All input for the `clearAllUserNotifications` mutation. */
export type ClearAllUserNotificationsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `clearAllUserNotifications` mutation. */
export type ClearAllUserNotificationsPayload = {
  __typename?: 'ClearAllUserNotificationsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `clearPickupQueueItem` mutation. */
export type ClearPickupQueueItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  pickupQueueId: Scalars['Int'];
};

/** The output of our `clearPickupQueueItem` mutation. */
export type ClearPickupQueueItemPayload = {
  __typename?: 'ClearPickupQueueItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  pickupQueue?: Maybe<PickupQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `PickupQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Location` that is related to this `PickupQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `Bidder` that is related to this `PickupQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `PickupSession` that is related to this `PickupQueue`. */
  pickupSessionBySessionId?: Maybe<PickupSession>;
  /** An edge for our `PickupQueue`. May be used by Relay 1. */
  pickupQueueEdge?: Maybe<PickupQueuesEdge>;
};


/** The output of our `clearPickupQueueItem` mutation. */
export type ClearPickupQueueItemPayloadPickupQueueEdgeArgs = {
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
};

/** All input for the `clearUserNotification` mutation. */
export type ClearUserNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  notificationId: Scalars['Int'];
};

/** The output of our `clearUserNotification` mutation. */
export type ClearUserNotificationPayload = {
  __typename?: 'ClearUserNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `commitAuction` mutation. */
export type CommitAuctionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  auctionId: Scalars['Int'];
  publish: Scalars['Boolean'];
};

/** The output of our `commitAuction` mutation. */
export type CommitAuctionPayload = {
  __typename?: 'CommitAuctionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  willCommit?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CopyItemsImageInput = {
  toItemId: Scalars['Int'];
  itemImageId: Scalars['Int'];
};

export type CopyItemsImagePayload = {
  __typename?: 'CopyItemsImagePayload';
  itemImage?: Maybe<ItemImage>;
  query?: Maybe<Query>;
};

/** All input for the `createAdministrator` mutation. */
export type CreateAdministratorInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  pinCode?: Maybe<Scalars['String']>;
};

/** The output of our `createAdministrator` mutation. */
export type CreateAdministratorPayload = {
  __typename?: 'CreateAdministratorPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `createAdministrator` mutation. */
export type CreateAdministratorPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `createBatchEmailInvoicesJob` mutation. */
export type CreateBatchEmailInvoicesJobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `createBatchEmailInvoicesJob` mutation. */
export type CreateBatchEmailInvoicesJobPayload = {
  __typename?: 'CreateBatchEmailInvoicesJobPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `createBatchProcessInvoicePaymentsJob` mutation. */
export type CreateBatchProcessInvoicePaymentsJobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `createBatchProcessInvoicePaymentsJob` mutation. */
export type CreateBatchProcessInvoicePaymentsJobPayload = {
  __typename?: 'CreateBatchProcessInvoicePaymentsJobPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the create `CustomerNote` mutation. */
export type CreateCustomerNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CustomerNote` to be created by this mutation. */
  customerNote: CustomerNoteInput;
};

/** The output of our create `CustomerNote` mutation. */
export type CreateCustomerNotePayload = {
  __typename?: 'CreateCustomerNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CustomerNote` that was created by this mutation. */
  customerNote?: Maybe<CustomerNote>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Bidder` that is related to this `CustomerNote`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `CustomerNote`. */
  userByCreatedBy?: Maybe<User>;
  /** An edge for our `CustomerNote`. May be used by Relay 1. */
  customerNoteEdge?: Maybe<CustomerNotesEdge>;
};


/** The output of our create `CustomerNote` mutation. */
export type CreateCustomerNotePayloadCustomerNoteEdgeArgs = {
  orderBy?: Maybe<Array<CustomerNotesOrderBy>>;
};

/** All input for the `createEmployee` mutation. */
export type CreateEmployeeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  pinCode: Scalars['String'];
};

/** The output of our `createEmployee` mutation. */
export type CreateEmployeePayload = {
  __typename?: 'CreateEmployeePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `createEmployee` mutation. */
export type CreateEmployeePayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the create `InvoiceAdjustment` mutation. */
export type CreateInvoiceAdjustmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvoiceAdjustment` to be created by this mutation. */
  invoiceAdjustment: InvoiceAdjustmentInput;
};

/** The output of our create `InvoiceAdjustment` mutation. */
export type CreateInvoiceAdjustmentPayload = {
  __typename?: 'CreateInvoiceAdjustmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvoiceAdjustment` that was created by this mutation. */
  invoiceAdjustment?: Maybe<InvoiceAdjustment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Invoice` that is related to this `InvoiceAdjustment`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** An edge for our `InvoiceAdjustment`. May be used by Relay 1. */
  invoiceAdjustmentEdge?: Maybe<InvoiceAdjustmentsEdge>;
};


/** The output of our create `InvoiceAdjustment` mutation. */
export type CreateInvoiceAdjustmentPayloadInvoiceAdjustmentEdgeArgs = {
  orderBy?: Maybe<Array<InvoiceAdjustmentsOrderBy>>;
};

/** All input for the create `InvoiceItemReturn` mutation. */
export type CreateInvoiceItemReturnInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvoiceItemReturn` to be created by this mutation. */
  invoiceItemReturn: InvoiceItemReturnInput;
};

/** The output of our create `InvoiceItemReturn` mutation. */
export type CreateInvoiceItemReturnPayload = {
  __typename?: 'CreateInvoiceItemReturnPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvoiceItemReturn` that was created by this mutation. */
  invoiceItemReturn?: Maybe<InvoiceItemReturn>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `InvoiceItemReturn`. */
  itemByInvoiceItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `InvoiceItemReturn`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `InvoiceItemReturn`. */
  userByUpdatedBy?: Maybe<User>;
  /** Reads a single `ReturnOption` that is related to this `InvoiceItemReturn`. */
  returnOptionByOptionId?: Maybe<ReturnOption>;
  /** Reads a single `ReturnQueue` that is related to this `InvoiceItemReturn`. */
  returnQueueByQueueId?: Maybe<ReturnQueue>;
  /** An edge for our `InvoiceItemReturn`. May be used by Relay 1. */
  invoiceItemReturnEdge?: Maybe<InvoiceItemReturnsEdge>;
};


/** The output of our create `InvoiceItemReturn` mutation. */
export type CreateInvoiceItemReturnPayloadInvoiceItemReturnEdgeArgs = {
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
};

/** All input for the create `InvoiceNote` mutation. */
export type CreateInvoiceNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvoiceNote` to be created by this mutation. */
  invoiceNote: InvoiceNoteInput;
};

/** The output of our create `InvoiceNote` mutation. */
export type CreateInvoiceNotePayload = {
  __typename?: 'CreateInvoiceNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvoiceNote` that was created by this mutation. */
  invoiceNote?: Maybe<InvoiceNote>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Invoice` that is related to this `InvoiceNote`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `User` that is related to this `InvoiceNote`. */
  userByCreatedBy?: Maybe<User>;
  /** An edge for our `InvoiceNote`. May be used by Relay 1. */
  invoiceNoteEdge?: Maybe<InvoiceNotesEdge>;
};


/** The output of our create `InvoiceNote` mutation. */
export type CreateInvoiceNotePayloadInvoiceNoteEdgeArgs = {
  orderBy?: Maybe<Array<InvoiceNotesOrderBy>>;
};

/** All input for the create `Location` mutation. */
export type CreateLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Location` to be created by this mutation. */
  location: LocationInput;
};

/** The output of our create `Location` mutation. */
export type CreateLocationPayload = {
  __typename?: 'CreateLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Location` that was created by this mutation. */
  location?: Maybe<Location>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Location`. May be used by Relay 1. */
  locationEdge?: Maybe<LocationsEdge>;
};


/** The output of our create `Location` mutation. */
export type CreateLocationPayloadLocationEdgeArgs = {
  orderBy?: Maybe<Array<LocationsOrderBy>>;
};

/** All input for the create `PickupSession` mutation. */
export type CreatePickupSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupSession` to be created by this mutation. */
  pickupSession: PickupSessionInput;
};

/** The output of our create `PickupSession` mutation. */
export type CreatePickupSessionPayload = {
  __typename?: 'CreatePickupSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupSession` that was created by this mutation. */
  pickupSession?: Maybe<PickupSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `PickupSession`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupSession`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupSession`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `PickupSession`. May be used by Relay 1. */
  pickupSessionEdge?: Maybe<PickupSessionsEdge>;
};


/** The output of our create `PickupSession` mutation. */
export type CreatePickupSessionPayloadPickupSessionEdgeArgs = {
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
};

/** All input for the create `Printer` mutation. */
export type CreatePrinterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Printer` to be created by this mutation. */
  printer: PrinterInput;
};

/** The output of our create `Printer` mutation. */
export type CreatePrinterPayload = {
  __typename?: 'CreatePrinterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Printer` that was created by this mutation. */
  printer?: Maybe<Printer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `Printer`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `Printer`. May be used by Relay 1. */
  printerEdge?: Maybe<PrintersEdge>;
};


/** The output of our create `Printer` mutation. */
export type CreatePrinterPayloadPrinterEdgeArgs = {
  orderBy?: Maybe<Array<PrintersOrderBy>>;
};

/** All input for the create `ReturnOption` mutation. */
export type CreateReturnOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ReturnOption` to be created by this mutation. */
  returnOption: ReturnOptionInput;
};

/** The output of our create `ReturnOption` mutation. */
export type CreateReturnOptionPayload = {
  __typename?: 'CreateReturnOptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ReturnOption` that was created by this mutation. */
  returnOption?: Maybe<ReturnOption>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `ReturnOption`. May be used by Relay 1. */
  returnOptionEdge?: Maybe<ReturnOptionsEdge>;
};


/** The output of our create `ReturnOption` mutation. */
export type CreateReturnOptionPayloadReturnOptionEdgeArgs = {
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
};

/** All input for the `createSecretary` mutation. */
export type CreateSecretaryInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  pinCode?: Maybe<Scalars['String']>;
};

/** The output of our `createSecretary` mutation. */
export type CreateSecretaryPayload = {
  __typename?: 'CreateSecretaryPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `createSecretary` mutation. */
export type CreateSecretaryPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the create `UserLocation` mutation. */
export type CreateUserLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserLocation` to be created by this mutation. */
  userLocation: UserLocationInput;
};

/** The output of our create `UserLocation` mutation. */
export type CreateUserLocationPayload = {
  __typename?: 'CreateUserLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserLocation` that was created by this mutation. */
  userLocation?: Maybe<UserLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserLocation`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Location` that is related to this `UserLocation`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `UserLocation`. May be used by Relay 1. */
  userLocationEdge?: Maybe<UserLocationsEdge>;
};


/** The output of our create `UserLocation` mutation. */
export type CreateUserLocationPayloadUserLocationEdgeArgs = {
  orderBy?: Maybe<Array<UserLocationsOrderBy>>;
};

export type Credentials = {
  __typename?: 'Credentials';
  accessToken: Scalars['String'];
  refreshToken: Scalars['String'];
};


export type CustomerActionsView = {
  __typename?: 'CustomerActionsView';
  id?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<CustomerNoteType>;
  expiresAt?: Maybe<Scalars['Date']>;
  /** Reads a single `Bidder` that is related to this `CustomerActionsView`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `CustomerActionsView`. */
  userByCreatedBy?: Maybe<User>;
};

/**
 * A condition to be used against `CustomerActionsView` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type CustomerActionsViewCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `note` field. */
  note?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<CustomerNoteType>;
  /** Checks for equality with the object’s `expiresAt` field. */
  expiresAt?: Maybe<Scalars['Date']>;
};

/** A filter to be used against `CustomerActionsView` object types. All fields are combined with a logical ‘and.’ */
export type CustomerActionsViewFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `note` field. */
  note?: Maybe<StringFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<CustomerNoteTypeFilter>;
  /** Filter by the object’s `expiresAt` field. */
  expiresAt?: Maybe<DateFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** A related `bidderByCustomerId` exists. */
  bidderByCustomerIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CustomerActionsViewFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CustomerActionsViewFilter>>;
  /** Negates the expression. */
  not?: Maybe<CustomerActionsViewFilter>;
};

/** A connection to a list of `CustomerActionsView` values. */
export type CustomerActionsViewsConnection = {
  __typename?: 'CustomerActionsViewsConnection';
  /** A list of `CustomerActionsView` objects. */
  nodes: Array<CustomerActionsView>;
  /** A list of edges which contains the `CustomerActionsView` and cursor to aid in pagination. */
  edges: Array<CustomerActionsViewsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CustomerActionsView` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CustomerActionsView` edge in the connection. */
export type CustomerActionsViewsEdge = {
  __typename?: 'CustomerActionsViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CustomerActionsView` at the end of the edge. */
  node: CustomerActionsView;
};

/** Methods to use when ordering `CustomerActionsView`. */
export enum CustomerActionsViewsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ExpiresAtAsc = 'EXPIRES_AT_ASC',
  ExpiresAtDesc = 'EXPIRES_AT_DESC'
}

export type CustomerAlertsView = {
  __typename?: 'CustomerAlertsView';
  id?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  type?: Maybe<CustomerNoteType>;
  expiresAt?: Maybe<Scalars['Date']>;
  /** Reads a single `Bidder` that is related to this `CustomerAlertsView`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `CustomerAlertsView`. */
  userByCreatedBy?: Maybe<User>;
};

/**
 * A condition to be used against `CustomerAlertsView` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CustomerAlertsViewCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `note` field. */
  note?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<CustomerNoteType>;
  /** Checks for equality with the object’s `expiresAt` field. */
  expiresAt?: Maybe<Scalars['Date']>;
};

/** A filter to be used against `CustomerAlertsView` object types. All fields are combined with a logical ‘and.’ */
export type CustomerAlertsViewFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `note` field. */
  note?: Maybe<StringFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<CustomerNoteTypeFilter>;
  /** Filter by the object’s `expiresAt` field. */
  expiresAt?: Maybe<DateFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** A related `bidderByCustomerId` exists. */
  bidderByCustomerIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CustomerAlertsViewFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CustomerAlertsViewFilter>>;
  /** Negates the expression. */
  not?: Maybe<CustomerAlertsViewFilter>;
};

/** A connection to a list of `CustomerAlertsView` values. */
export type CustomerAlertsViewsConnection = {
  __typename?: 'CustomerAlertsViewsConnection';
  /** A list of `CustomerAlertsView` objects. */
  nodes: Array<CustomerAlertsView>;
  /** A list of edges which contains the `CustomerAlertsView` and cursor to aid in pagination. */
  edges: Array<CustomerAlertsViewsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CustomerAlertsView` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CustomerAlertsView` edge in the connection. */
export type CustomerAlertsViewsEdge = {
  __typename?: 'CustomerAlertsViewsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CustomerAlertsView` at the end of the edge. */
  node: CustomerAlertsView;
};

/** Methods to use when ordering `CustomerAlertsView`. */
export enum CustomerAlertsViewsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ExpiresAtAsc = 'EXPIRES_AT_ASC',
  ExpiresAtDesc = 'EXPIRES_AT_DESC'
}

/** All input for the `customerInvoicePortal` mutation. */
export type CustomerInvoicePortalInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  customerBwId: Scalars['Int'];
  pageNumber?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
};

/** The output of our `customerInvoicePortal` mutation. */
export type CustomerInvoicePortalPayload = {
  __typename?: 'CustomerInvoicePortalPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  customerInvoicePortalReturnType?: Maybe<CustomerInvoicePortalReturnType>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type CustomerInvoicePortalReturnType = {
  __typename?: 'CustomerInvoicePortalReturnType';
  total?: Maybe<Scalars['Int']>;
  invoices?: Maybe<Scalars['JSON']>;
};

export type CustomerNote = Node & {
  __typename?: 'CustomerNote';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  customerId: Scalars['Int'];
  note: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  type?: Maybe<CustomerNoteType>;
  expiresAt?: Maybe<Scalars['Date']>;
  /** Reads a single `Bidder` that is related to this `CustomerNote`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `CustomerNote`. */
  userByCreatedBy?: Maybe<User>;
};

/**
 * A condition to be used against `CustomerNote` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type CustomerNoteCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `note` field. */
  note?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<CustomerNoteType>;
  /** Checks for equality with the object’s `expiresAt` field. */
  expiresAt?: Maybe<Scalars['Date']>;
};

/** A filter to be used against `CustomerNote` object types. All fields are combined with a logical ‘and.’ */
export type CustomerNoteFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `note` field. */
  note?: Maybe<StringFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<CustomerNoteTypeFilter>;
  /** Filter by the object’s `expiresAt` field. */
  expiresAt?: Maybe<DateFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<CustomerNoteFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<CustomerNoteFilter>>;
  /** Negates the expression. */
  not?: Maybe<CustomerNoteFilter>;
};

/** An input for mutations affecting `CustomerNote` */
export type CustomerNoteInput = {
  customerId: Scalars['Int'];
  note: Scalars['String'];
  type?: Maybe<CustomerNoteType>;
  expiresAt?: Maybe<Scalars['Date']>;
};

/** Represents an update to a `CustomerNote`. Fields that are set will be updated. */
export type CustomerNotePatch = {
  type?: Maybe<CustomerNoteType>;
  expiresAt?: Maybe<Scalars['Date']>;
};

export enum CustomerNoteType {
  ActionBringToNr = 'ACTION_BRING_TO_NR',
  ActionShipping = 'ACTION_SHIPPING',
  ActionNoFoodTax = 'ACTION_NO_FOOD_TAX',
  AlertNoRelistExceptions = 'ALERT_NO_RELIST_EXCEPTIONS',
  AlertNoReturnExceptions = 'ALERT_NO_RETURN_EXCEPTIONS',
  AlertNoMisbidExceptions = 'ALERT_NO_MISBID_EXCEPTIONS',
  AlertNoUnpaidInvoices = 'ALERT_NO_UNPAID_INVOICES',
  AlertNoMissingItemsAfterPickUp = 'ALERT_NO_MISSING_ITEMS_AFTER_PICK_UP',
  AlertAlwaysRefund = 'ALERT_ALWAYS_REFUND',
  AlertOther = 'ALERT_OTHER'
}

/** A filter to be used against CustomerNoteType fields. All fields are combined with a logical ‘and.’ */
export type CustomerNoteTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<CustomerNoteType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<CustomerNoteType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<CustomerNoteType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<CustomerNoteType>;
  /** Included in the specified list. */
  in?: Maybe<Array<CustomerNoteType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<CustomerNoteType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<CustomerNoteType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<CustomerNoteType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<CustomerNoteType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<CustomerNoteType>;
};

/** A connection to a list of `CustomerNote` values. */
export type CustomerNotesConnection = {
  __typename?: 'CustomerNotesConnection';
  /** A list of `CustomerNote` objects. */
  nodes: Array<CustomerNote>;
  /** A list of edges which contains the `CustomerNote` and cursor to aid in pagination. */
  edges: Array<CustomerNotesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `CustomerNote` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `CustomerNote` edge in the connection. */
export type CustomerNotesEdge = {
  __typename?: 'CustomerNotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `CustomerNote` at the end of the edge. */
  node: CustomerNote;
};

/** Methods to use when ordering `CustomerNote`. */
export enum CustomerNotesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  ExpiresAtAsc = 'EXPIRES_AT_ASC',
  ExpiresAtDesc = 'EXPIRES_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type DashboardAnalyticsType = {
  __typename?: 'DashboardAnalyticsType';
  numItemsSold?: Maybe<Scalars['Int']>;
  sumValueSold?: Maybe<Scalars['Int']>;
  numItemsReturned?: Maybe<Scalars['Int']>;
};


/** A filter to be used against Date fields. All fields are combined with a logical ‘and.’ */
export type DateFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Date']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Date']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Date']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Date']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Date']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Date']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Date']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Date']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Date']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Date']>;
};


/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Datetime']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Datetime']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Datetime']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Datetime']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Datetime']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Datetime']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Datetime']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Datetime']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Datetime']>;
};

/** All input for the `deleteAuctionPushByAuctionId` mutation. */
export type DeleteAuctionPushByAuctionIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  auctionId: Scalars['Int'];
};

/** The output of our `deleteAuctionPushByAuctionId` mutation. */
export type DeleteAuctionPushByAuctionIdPayload = {
  __typename?: 'DeleteAuctionPushByAuctionIdPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `deleteCreditFromReturn` mutation. */
export type DeleteCreditFromReturnInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  returnId: Scalars['Int'];
  reason: Scalars['String'];
};

/** The output of our `deleteCreditFromReturn` mutation. */
export type DeleteCreditFromReturnPayload = {
  __typename?: 'DeleteCreditFromReturnPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceItemReturn?: Maybe<InvoiceItemReturn>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `InvoiceItemReturn`. */
  itemByInvoiceItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `InvoiceItemReturn`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `InvoiceItemReturn`. */
  userByUpdatedBy?: Maybe<User>;
  /** Reads a single `ReturnOption` that is related to this `InvoiceItemReturn`. */
  returnOptionByOptionId?: Maybe<ReturnOption>;
  /** Reads a single `ReturnQueue` that is related to this `InvoiceItemReturn`. */
  returnQueueByQueueId?: Maybe<ReturnQueue>;
  /** An edge for our `InvoiceItemReturn`. May be used by Relay 1. */
  invoiceItemReturnEdge?: Maybe<InvoiceItemReturnsEdge>;
};


/** The output of our `deleteCreditFromReturn` mutation. */
export type DeleteCreditFromReturnPayloadInvoiceItemReturnEdgeArgs = {
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
};

/** All input for the `deleteCustomerNoteById` mutation. */
export type DeleteCustomerNoteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteCustomerNote` mutation. */
export type DeleteCustomerNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CustomerNote` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `CustomerNote` mutation. */
export type DeleteCustomerNotePayload = {
  __typename?: 'DeleteCustomerNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CustomerNote` that was deleted by this mutation. */
  customerNote?: Maybe<CustomerNote>;
  deletedCustomerNoteId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Bidder` that is related to this `CustomerNote`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `CustomerNote`. */
  userByCreatedBy?: Maybe<User>;
  /** An edge for our `CustomerNote`. May be used by Relay 1. */
  customerNoteEdge?: Maybe<CustomerNotesEdge>;
};


/** The output of our delete `CustomerNote` mutation. */
export type DeleteCustomerNotePayloadCustomerNoteEdgeArgs = {
  orderBy?: Maybe<Array<CustomerNotesOrderBy>>;
};

/** All input for the `deleteInvoiceNoteById` mutation. */
export type DeleteInvoiceNoteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deleteInvoiceNote` mutation. */
export type DeleteInvoiceNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InvoiceNote` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `InvoiceNote` mutation. */
export type DeleteInvoiceNotePayload = {
  __typename?: 'DeleteInvoiceNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvoiceNote` that was deleted by this mutation. */
  invoiceNote?: Maybe<InvoiceNote>;
  deletedInvoiceNoteId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Invoice` that is related to this `InvoiceNote`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `User` that is related to this `InvoiceNote`. */
  userByCreatedBy?: Maybe<User>;
  /** An edge for our `InvoiceNote`. May be used by Relay 1. */
  invoiceNoteEdge?: Maybe<InvoiceNotesEdge>;
};


/** The output of our delete `InvoiceNote` mutation. */
export type DeleteInvoiceNotePayloadInvoiceNoteEdgeArgs = {
  orderBy?: Maybe<Array<InvoiceNotesOrderBy>>;
};

/** All input for the `deleteItemFromInvoice` mutation. */
export type DeleteItemFromInvoiceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  reason: Scalars['String'];
};

/** The output of our `deleteItemFromInvoice` mutation. */
export type DeleteItemFromInvoicePayload = {
  __typename?: 'DeleteItemFromInvoicePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  item?: Maybe<Item>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Item`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `Item`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Payment` that is related to this `Item`. */
  paymentByPaymentId?: Maybe<Payment>;
  /** Reads a single `Bidder` that is related to this `Item`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Item`. */
  userByDeletedFromInvoiceBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userBySetAsideBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userByIsDeletedBy?: Maybe<User>;
  /** An edge for our `Item`. May be used by Relay 1. */
  itemEdge?: Maybe<ItemsEdge>;
};


/** The output of our `deleteItemFromInvoice` mutation. */
export type DeleteItemFromInvoicePayloadItemEdgeArgs = {
  orderBy?: Maybe<Array<ItemsOrderBy>>;
};

/** All input for the `deletePicklistQueueById` mutation. */
export type DeletePicklistQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deletePicklistQueueByItemId` mutation. */
export type DeletePicklistQueueByItemIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
};

/** All input for the `deletePicklistQueue` mutation. */
export type DeletePicklistQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PicklistQueue` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `PicklistQueue` mutation. */
export type DeletePicklistQueuePayload = {
  __typename?: 'DeletePicklistQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PicklistQueue` that was deleted by this mutation. */
  picklistQueue?: Maybe<PicklistQueue>;
  deletedPicklistQueueId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `PicklistQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `PicklistQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `PicklistQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByStepUpdatedBy?: Maybe<User>;
  /** An edge for our `PicklistQueue`. May be used by Relay 1. */
  picklistQueueEdge?: Maybe<PicklistQueuesEdge>;
};


/** The output of our delete `PicklistQueue` mutation. */
export type DeletePicklistQueuePayloadPicklistQueueEdgeArgs = {
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
};

/** All input for the `deletePickupCustomerById` mutation. */
export type DeletePickupCustomerByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deletePickupCustomerByLocationIdAndCustomerId` mutation. */
export type DeletePickupCustomerByLocationIdAndCustomerIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
};

/** All input for the `deletePickupCustomerByLocationIdAndNumber` mutation. */
export type DeletePickupCustomerByLocationIdAndNumberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationId: Scalars['Int'];
  number: Scalars['Int'];
};

/** All input for the `deletePickupCustomer` mutation. */
export type DeletePickupCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PickupCustomer` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `PickupCustomer` mutation. */
export type DeletePickupCustomerPayload = {
  __typename?: 'DeletePickupCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupCustomer` that was deleted by this mutation. */
  pickupCustomer?: Maybe<PickupCustomer>;
  deletedPickupCustomerId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `PickupCustomer`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupCustomer`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupCustomer`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `PickupCustomer`. May be used by Relay 1. */
  pickupCustomerEdge?: Maybe<PickupCustomersEdge>;
};


/** The output of our delete `PickupCustomer` mutation. */
export type DeletePickupCustomerPayloadPickupCustomerEdgeArgs = {
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
};

/** All input for the `deletePickupQueueById` mutation. */
export type DeletePickupQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** All input for the `deletePickupQueue` mutation. */
export type DeletePickupQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PickupQueue` to be deleted. */
  nodeId: Scalars['ID'];
};

/** All input for the `deletePickupQueueItem` mutation. */
export type DeletePickupQueueItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  pickupQueueId: Scalars['Int'];
};

/** The output of our `deletePickupQueueItem` mutation. */
export type DeletePickupQueueItemPayload = {
  __typename?: 'DeletePickupQueueItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `PickupQueue` mutation. */
export type DeletePickupQueuePayload = {
  __typename?: 'DeletePickupQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupQueue` that was deleted by this mutation. */
  pickupQueue?: Maybe<PickupQueue>;
  deletedPickupQueueId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `PickupQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Location` that is related to this `PickupQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `Bidder` that is related to this `PickupQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `PickupSession` that is related to this `PickupQueue`. */
  pickupSessionBySessionId?: Maybe<PickupSession>;
  /** An edge for our `PickupQueue`. May be used by Relay 1. */
  pickupQueueEdge?: Maybe<PickupQueuesEdge>;
};


/** The output of our delete `PickupQueue` mutation. */
export type DeletePickupQueuePayloadPickupQueueEdgeArgs = {
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
};

/** All input for the `deletePickupSessionById` mutation. */
export type DeletePickupSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deletePickupSession` mutation. */
export type DeletePickupSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PickupSession` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `PickupSession` mutation. */
export type DeletePickupSessionPayload = {
  __typename?: 'DeletePickupSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupSession` that was deleted by this mutation. */
  pickupSession?: Maybe<PickupSession>;
  deletedPickupSessionId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `PickupSession`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupSession`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupSession`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `PickupSession`. May be used by Relay 1. */
  pickupSessionEdge?: Maybe<PickupSessionsEdge>;
};


/** The output of our delete `PickupSession` mutation. */
export type DeletePickupSessionPayloadPickupSessionEdgeArgs = {
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
};

/** All input for the `deleteShippingQueueById` mutation. */
export type DeleteShippingQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['UUID'];
};

/** All input for the `deleteShippingQueueByItemId` mutation. */
export type DeleteShippingQueueByItemIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
};

/** All input for the `deleteShippingQueue` mutation. */
export type DeleteShippingQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ShippingQueue` to be deleted. */
  nodeId: Scalars['ID'];
};

/** The output of our delete `ShippingQueue` mutation. */
export type DeleteShippingQueuePayload = {
  __typename?: 'DeleteShippingQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ShippingQueue` that was deleted by this mutation. */
  shippingQueue?: Maybe<ShippingQueue>;
  deletedShippingQueueId?: Maybe<Scalars['ID']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `ShippingQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `ShippingQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `ShippingQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** An edge for our `ShippingQueue`. May be used by Relay 1. */
  shippingQueueEdge?: Maybe<ShippingQueuesEdge>;
};


/** The output of our delete `ShippingQueue` mutation. */
export type DeleteShippingQueuePayloadShippingQueueEdgeArgs = {
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
};

export type DownloadCustomerInvoiceInput = {
  invoiceUid: Scalars['String'];
  publicToken: Scalars['String'];
};

export type DownloadCustomerInvoicePayload = {
  __typename?: 'DownloadCustomerInvoicePayload';
  downloadUrl: Scalars['String'];
};

export type DownloadPaperlessAppInput = {
  password: Scalars['String'];
};

export type DownloadPaperlessAppPayload = {
  __typename?: 'DownloadPaperlessAppPayload';
  downloadUrl?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

/** All input for the `downloadReturnReport` mutation. */
export type DownloadReturnReportInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
};

/** The output of our `downloadReturnReport` mutation. */
export type DownloadReturnReportPayload = {
  __typename?: 'DownloadReturnReportPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `emailAuctionInvoices` mutation. */
export type EmailAuctionInvoicesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  auctionId: Scalars['Int'];
};

/** The output of our `emailAuctionInvoices` mutation. */
export type EmailAuctionInvoicesPayload = {
  __typename?: 'EmailAuctionInvoicesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `emailInvoice` mutation. */
export type EmailInvoiceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
};

/** The output of our `emailInvoice` mutation. */
export type EmailInvoicePayload = {
  __typename?: 'EmailInvoicePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type EmployeeStat = {
  __typename?: 'EmployeeStat';
  employeeId?: Maybe<Scalars['Int']>;
  employeeName?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  locationName?: Maybe<Scalars['String']>;
  dailyTotalItems?: Maybe<Scalars['Int']>;
  dailyPicklistItems?: Maybe<Scalars['Int']>;
  dailyPickupItems?: Maybe<Scalars['Int']>;
  last60TotalItems?: Maybe<Scalars['Int']>;
  last60PicklistItems?: Maybe<Scalars['Int']>;
  last60PickupItems?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `EmployeeStat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type EmployeeStatCondition = {
  /** Checks for equality with the object’s `employeeId` field. */
  employeeId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `employeeName` field. */
  employeeName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationName` field. */
  locationName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dailyTotalItems` field. */
  dailyTotalItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `dailyPicklistItems` field. */
  dailyPicklistItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `dailyPickupItems` field. */
  dailyPickupItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `last60TotalItems` field. */
  last60TotalItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `last60PicklistItems` field. */
  last60PicklistItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `last60PickupItems` field. */
  last60PickupItems?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `EmployeeStat` object types. All fields are combined with a logical ‘and.’ */
export type EmployeeStatFilter = {
  /** Filter by the object’s `employeeId` field. */
  employeeId?: Maybe<IntFilter>;
  /** Filter by the object’s `employeeName` field. */
  employeeName?: Maybe<StringFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `locationName` field. */
  locationName?: Maybe<StringFilter>;
  /** Filter by the object’s `dailyTotalItems` field. */
  dailyTotalItems?: Maybe<IntFilter>;
  /** Filter by the object’s `dailyPicklistItems` field. */
  dailyPicklistItems?: Maybe<IntFilter>;
  /** Filter by the object’s `dailyPickupItems` field. */
  dailyPickupItems?: Maybe<IntFilter>;
  /** Filter by the object’s `last60TotalItems` field. */
  last60TotalItems?: Maybe<IntFilter>;
  /** Filter by the object’s `last60PicklistItems` field. */
  last60PicklistItems?: Maybe<IntFilter>;
  /** Filter by the object’s `last60PickupItems` field. */
  last60PickupItems?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<EmployeeStatFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<EmployeeStatFilter>>;
  /** Negates the expression. */
  not?: Maybe<EmployeeStatFilter>;
};

/** A connection to a list of `EmployeeStat` values. */
export type EmployeeStatsConnection = {
  __typename?: 'EmployeeStatsConnection';
  /** A list of `EmployeeStat` objects. */
  nodes: Array<EmployeeStat>;
  /** A list of edges which contains the `EmployeeStat` and cursor to aid in pagination. */
  edges: Array<EmployeeStatsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `EmployeeStat` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `EmployeeStat` edge in the connection. */
export type EmployeeStatsEdge = {
  __typename?: 'EmployeeStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `EmployeeStat` at the end of the edge. */
  node: EmployeeStat;
};

/** Methods to use when ordering `EmployeeStat`. */
export enum EmployeeStatsOrderBy {
  Natural = 'NATURAL',
  EmployeeIdAsc = 'EMPLOYEE_ID_ASC',
  EmployeeIdDesc = 'EMPLOYEE_ID_DESC',
  EmployeeNameAsc = 'EMPLOYEE_NAME_ASC',
  EmployeeNameDesc = 'EMPLOYEE_NAME_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  LocationNameAsc = 'LOCATION_NAME_ASC',
  LocationNameDesc = 'LOCATION_NAME_DESC',
  DailyTotalItemsAsc = 'DAILY_TOTAL_ITEMS_ASC',
  DailyTotalItemsDesc = 'DAILY_TOTAL_ITEMS_DESC',
  DailyPicklistItemsAsc = 'DAILY_PICKLIST_ITEMS_ASC',
  DailyPicklistItemsDesc = 'DAILY_PICKLIST_ITEMS_DESC',
  DailyPickupItemsAsc = 'DAILY_PICKUP_ITEMS_ASC',
  DailyPickupItemsDesc = 'DAILY_PICKUP_ITEMS_DESC',
  Last_60TotalItemsAsc = 'LAST_60_TOTAL_ITEMS_ASC',
  Last_60TotalItemsDesc = 'LAST_60_TOTAL_ITEMS_DESC',
  Last_60PicklistItemsAsc = 'LAST_60_PICKLIST_ITEMS_ASC',
  Last_60PicklistItemsDesc = 'LAST_60_PICKLIST_ITEMS_DESC',
  Last_60PickupItemsAsc = 'LAST_60_PICKUP_ITEMS_ASC',
  Last_60PickupItemsDesc = 'LAST_60_PICKUP_ITEMS_DESC'
}

export enum EventType {
  Paperless = 'PAPERLESS',
  Mercury = 'MERCURY',
  MercuryInitialCreation = 'MERCURY_INITIAL_CREATION',
  PaperlessPlaceItemOnHold = 'PAPERLESS_PLACE_ITEM_ON_HOLD'
}

/** A filter to be used against EventType fields. All fields are combined with a logical ‘and.’ */
export type EventTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<EventType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<EventType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<EventType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<EventType>;
  /** Included in the specified list. */
  in?: Maybe<Array<EventType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<EventType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<EventType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<EventType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<EventType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<EventType>;
};

export enum ExtType {
  BidWrangler = 'BID_WRANGLER',
  Mercury = 'MERCURY'
}

/** A filter to be used against ExtType fields. All fields are combined with a logical ‘and.’ */
export type ExtTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ExtType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ExtType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ExtType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ExtType>;
  /** Included in the specified list. */
  in?: Maybe<Array<ExtType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ExtType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ExtType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ExtType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ExtType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ExtType>;
};


/** A filter to be used against FullText fields. All fields are combined with a logical ‘and.’ */
export type FullTextFilter = {
  /** Performs a full text search on the field. */
  matches?: Maybe<Scalars['String']>;
};

/** All input for the `generateDates1MonthFromNow` mutation. */
export type GenerateDates1MonthFromNowInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `generateDates1MonthFromNow` mutation. */
export type GenerateDates1MonthFromNowPayload = {
  __typename?: 'GenerateDates1MonthFromNowPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  dates?: Maybe<Array<Maybe<Scalars['Date']>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `generateDatesInThisMonth` mutation. */
export type GenerateDatesInThisMonthInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  theDate?: Maybe<Scalars['Datetime']>;
};

/** The output of our `generateDatesInThisMonth` mutation. */
export type GenerateDatesInThisMonthPayload = {
  __typename?: 'GenerateDatesInThisMonthPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  dates?: Maybe<Array<Maybe<Scalars['Date']>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A `Permission` edge in the connection. */
export type GetAllowedUserPermissionEdge = {
  __typename?: 'GetAllowedUserPermissionEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Permission` at the end of the edge. */
  node?: Maybe<Permission>;
};

/** A connection to a list of `Permission` values. */
export type GetAllowedUserPermissionsConnection = {
  __typename?: 'GetAllowedUserPermissionsConnection';
  /** A list of `Permission` objects. */
  nodes: Array<Maybe<Permission>>;
  /** A list of edges which contains the `Permission` and cursor to aid in pagination. */
  edges: Array<GetAllowedUserPermissionEdge>;
  /** The count of *all* `Permission` you could get from the connection. */
  totalCount: Scalars['Int'];
};

export type GetCustomerInvoiceResult = {
  __typename?: 'GetCustomerInvoiceResult';
  invoice?: Maybe<GetCustomerInvoiceResultInvoiceType>;
  auction?: Maybe<GetCustomerInvoiceResultAuctionType>;
  customer?: Maybe<GetCustomerInvoiceResultCustomerType>;
  items?: Maybe<Array<Maybe<GetCustomerInvoiceResultItemsType>>>;
  adjustments?: Maybe<Array<Maybe<GetCustomerInvoiceResultAdjustmentsType>>>;
  payment?: Maybe<GetCustomerInvoiceResultPaymentType>;
  signatureUrl?: Maybe<Scalars['String']>;
};

export type GetCustomerInvoiceResultAdjustmentsType = {
  __typename?: 'GetCustomerInvoiceResultAdjustmentsType';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
};

export type GetCustomerInvoiceResultAuctionType = {
  __typename?: 'GetCustomerInvoiceResultAuctionType';
  name?: Maybe<Scalars['String']>;
  endAt?: Maybe<Scalars['Datetime']>;
  pickupStartAt?: Maybe<Scalars['Datetime']>;
  pickupEndAt?: Maybe<Scalars['Datetime']>;
  timezone?: Maybe<Scalars['String']>;
  pickupAddress?: Maybe<Scalars['String']>;
  pickupCity?: Maybe<Scalars['String']>;
  pickupState?: Maybe<Scalars['String']>;
  pickupZipcode?: Maybe<Scalars['String']>;
};

export type GetCustomerInvoiceResultCustomerType = {
  __typename?: 'GetCustomerInvoiceResultCustomerType';
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type GetCustomerInvoiceResultInvoiceType = {
  __typename?: 'GetCustomerInvoiceResultInvoiceType';
  id?: Maybe<Scalars['Int']>;
  uid?: Maybe<Scalars['String']>;
  amountDue?: Maybe<Scalars['Int']>;
  feeSubtotal?: Maybe<Scalars['Int']>;
  itemSubtotal?: Maybe<Scalars['Int']>;
  itemPremiumTotal?: Maybe<Scalars['Int']>;
  itemTaxTotal?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['Int']>;
  appliedCredit?: Maybe<Scalars['Int']>;
  appliedPayment?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  itemBidTotal?: Maybe<Scalars['Int']>;
};

export type GetCustomerInvoiceResultItemReturnsType = {
  __typename?: 'GetCustomerInvoiceResultItemReturnsType';
  id?: Maybe<Scalars['Int']>;
  creditAmount?: Maybe<Scalars['Int']>;
  paymentAmount?: Maybe<Scalars['Int']>;
  alternatePaymentAmount?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

export type GetCustomerInvoiceResultItemsType = {
  __typename?: 'GetCustomerInvoiceResultItemsType';
  number?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  bidAmount?: Maybe<Scalars['Int']>;
  premiumAmount?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Int']>;
  returns?: Maybe<Array<Maybe<GetCustomerInvoiceResultItemReturnsType>>>;
  retailPrice?: Maybe<Scalars['Int']>;
  condition?: Maybe<Scalars['String']>;
};

export type GetCustomerInvoiceResultPaymentType = {
  __typename?: 'GetCustomerInvoiceResultPaymentType';
  cardType?: Maybe<Scalars['String']>;
  cardNumber?: Maybe<Scalars['String']>;
};

/** The return type of our `getDataByInvoiceUid` query. */
export type GetDataByInvoiceUidRecord = {
  __typename?: 'GetDataByInvoiceUidRecord';
  invoiceId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  itemOverallPickupStatus?: Maybe<ItemOverallPickupStatus>;
  auctionName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
};

/** The return type of our `getDataBySku` query. */
export type GetDataBySkuRecord = {
  __typename?: 'GetDataBySkuRecord';
  invoiceId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  itemOverallPickupStatus?: Maybe<ItemOverallPickupStatus>;
  auctionName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
};

/** The return type of our `getInvoiceByAuctionNameAndItemNumber` query. */
export type GetInvoiceByAuctionNameAndItemNumberRecord = {
  __typename?: 'GetInvoiceByAuctionNameAndItemNumberRecord';
  invoiceId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  itemOverallPickupStatus?: Maybe<ItemOverallPickupStatus>;
};

/** The return type of our `getInvoiceFromMyPickupByAuctionNameAndItemNumber` query. */
export type GetInvoiceFromMyPickupByAuctionNameAndItemNumberRecord = {
  __typename?: 'GetInvoiceFromMyPickupByAuctionNameAndItemNumberRecord';
  invoiceId?: Maybe<Scalars['Int']>;
  itemId?: Maybe<Scalars['Int']>;
  itemOverallPickupStatus?: Maybe<ItemOverallPickupStatus>;
};

/** A connection to a list of `GetItemHistoryRecord` values. */
export type GetItemHistoryConnection = {
  __typename?: 'GetItemHistoryConnection';
  /** A list of `GetItemHistoryRecord` objects. */
  nodes: Array<GetItemHistoryRecord>;
  /** A list of edges which contains the `GetItemHistoryRecord` and cursor to aid in pagination. */
  edges: Array<GetItemHistoryEdge>;
  /** The count of *all* `GetItemHistoryRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `GetItemHistoryRecord` edge in the connection. */
export type GetItemHistoryEdge = {
  __typename?: 'GetItemHistoryEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `GetItemHistoryRecord` at the end of the edge. */
  node: GetItemHistoryRecord;
};

/** The return type of our `getItemHistory` query. */
export type GetItemHistoryRecord = {
  __typename?: 'GetItemHistoryRecord';
  id?: Maybe<Scalars['Int']>;
  type?: Maybe<ItemHistoryType>;
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  createdByUser?: Maybe<Scalars['String']>;
};

/** A filter to be used against `GetItemHistoryRecord` object types. All fields are combined with a logical ‘and.’ */
export type GetItemHistoryRecordFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<ItemHistoryTypeFilter>;
  /** Filter by the object’s `oldValue` field. */
  oldValue?: Maybe<StringFilter>;
  /** Filter by the object’s `newValue` field. */
  newValue?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<StringFilter>;
  /** Filter by the object’s `createdByUser` field. */
  createdByUser?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<GetItemHistoryRecordFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<GetItemHistoryRecordFilter>>;
  /** Negates the expression. */
  not?: Maybe<GetItemHistoryRecordFilter>;
};

/** The return type of our `getItemReturnDetails` query. */
export type GetItemReturnDetailsRecord = {
  __typename?: 'GetItemReturnDetailsRecord';
  item?: Maybe<Item>;
  returnQueue?: Maybe<ReturnQueue>;
};

/** The return type of our `getUserLocation` query. */
export type GetUserLocationRecord = {
  __typename?: 'GetUserLocationRecord';
  locationId?: Maybe<Scalars['Int']>;
  locationName?: Maybe<Scalars['String']>;
};

/** All input for the `handleAssignNextCustomerInPickupQueue` mutation. */
export type HandleAssignNextCustomerInPickupQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `handleAssignNextCustomerInPickupQueue` mutation. */
export type HandleAssignNextCustomerInPickupQueuePayload = {
  __typename?: 'HandleAssignNextCustomerInPickupQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `handleCancelPickupAction` mutation. */
export type HandleCancelPickupActionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `handleCancelPickupAction` mutation. */
export type HandleCancelPickupActionPayload = {
  __typename?: 'HandleCancelPickupActionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `handleDeliverAction` mutation. */
export type HandleDeliverActionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `handleDeliverAction` mutation. */
export type HandleDeliverActionPayload = {
  __typename?: 'HandleDeliverActionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `handlePickupStepCustVerify` mutation. */
export type HandlePickupStepCustVerifyInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `handlePickupStepCustVerify` mutation. */
export type HandlePickupStepCustVerifyPayload = {
  __typename?: 'HandlePickupStepCustVerifyPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  pickupSession?: Maybe<PickupSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `PickupSession`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupSession`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupSession`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `PickupSession`. May be used by Relay 1. */
  pickupSessionEdge?: Maybe<PickupSessionsEdge>;
};


/** The output of our `handlePickupStepCustVerify` mutation. */
export type HandlePickupStepCustVerifyPayloadPickupSessionEdgeArgs = {
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
};

/** All input for the `handlePickupStepStart` mutation. */
export type HandlePickupStepStartInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `handlePickupStepStart` mutation. */
export type HandlePickupStepStartPayload = {
  __typename?: 'HandlePickupStepStartPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  pickupSession?: Maybe<PickupSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `PickupSession`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupSession`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupSession`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `PickupSession`. May be used by Relay 1. */
  pickupSessionEdge?: Maybe<PickupSessionsEdge>;
};


/** The output of our `handlePickupStepStart` mutation. */
export type HandlePickupStepStartPayloadPickupSessionEdgeArgs = {
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
};

/** All input for the `handlePickupVerifyCustomer` mutation. */
export type HandlePickupVerifyCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceUid: Scalars['String'];
};

/** The output of our `handlePickupVerifyCustomer` mutation. */
export type HandlePickupVerifyCustomerPayload = {
  __typename?: 'HandlePickupVerifyCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<HandlePickupVerifyCustomerRecord>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The return type of our `handlePickupVerifyCustomer` mutation. */
export type HandlePickupVerifyCustomerRecord = {
  __typename?: 'HandlePickupVerifyCustomerRecord';
  verified?: Maybe<Scalars['Boolean']>;
  pickupSession?: Maybe<PickupSession>;
};

/** All input for the `handleReturnAction` mutation. */
export type HandleReturnActionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  returnOptionId: Scalars['UUID'];
  type: ReturnQueueType;
  amount: Scalars['Int'];
  comment?: Maybe<Scalars['String']>;
  printerId?: Maybe<Scalars['Int']>;
};

/** The output of our `handleReturnAction` mutation. */
export type HandleReturnActionPayload = {
  __typename?: 'HandleReturnActionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  isShippingItem?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `handleSendInvoiceToPickupQueueAction` mutation. */
export type HandleSendInvoiceToPickupQueueActionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
};

/** The output of our `handleSendInvoiceToPickupQueueAction` mutation. */
export type HandleSendInvoiceToPickupQueueActionPayload = {
  __typename?: 'HandleSendInvoiceToPickupQueueActionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemCount?: Maybe<Scalars['Int']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `insertItemImages` mutation. */
export type InsertItemImagesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  items: Scalars['JSON'];
};

/** The output of our `insertItemImages` mutation. */
export type InsertItemImagesPayload = {
  __typename?: 'InsertItemImagesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['Int']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['Int']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['Int']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['Int']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['Int']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['Int']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['Int']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['Int']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['Int']>;
};

export type Invoice = Node & {
  __typename?: 'Invoice';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  uid?: Maybe<Scalars['String']>;
  publicToken: Scalars['String'];
  auctionId?: Maybe<Scalars['Int']>;
  bidderId?: Maybe<Scalars['Int']>;
  bidderSignatureImageName?: Maybe<Scalars['String']>;
  bidderSignatureSignedUrl?: Maybe<Scalars['String']>;
  bidderSignatureExpiredAt?: Maybe<Scalars['Datetime']>;
  bidderCreditsUsed: Scalars['Int'];
  paymentCreditsUsed: Scalars['Int'];
  alternateCreditsUsed: Scalars['Int'];
  canProceedPayment: Scalars['Boolean'];
  isProcessingPayment: Scalars['Boolean'];
  isInitialPaymentProcessed: Scalars['Boolean'];
  processPayment: Scalars['Boolean'];
  processPaymentReason?: Maybe<Scalars['String']>;
  processPaymentChangedBy?: Maybe<Scalars['Int']>;
  processPaymentChangedAt?: Maybe<Scalars['Datetime']>;
  alternatePaymentNote?: Maybe<Scalars['String']>;
  alternatePaymentCreatedBy?: Maybe<Scalars['Int']>;
  alternatePaymentCreatedAt?: Maybe<Scalars['Datetime']>;
  lastPickupDate?: Maybe<Scalars['Date']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  lpudChangedType: LpudChangedType;
  canDisplayToCustomer: Scalars['Boolean'];
  elevatedToken: Scalars['String'];
  extType: ExtType;
  merId?: Maybe<Scalars['String']>;
  locationId: Scalars['Int'];
  lastPutOnHoldEventAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Auction` that is related to this `Invoice`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Bidder` that is related to this `Invoice`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Invoice`. */
  userByProcessPaymentChangedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Invoice`. */
  userByAlternatePaymentCreatedBy?: Maybe<User>;
  /** Reads a single `Location` that is related to this `Invoice`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByInvoiceId: InvoicesSearchesConnection;
  /** Reads and enables pagination through a set of `InvoiceAdjustment`. */
  invoiceAdjustmentsByInvoiceId: InvoiceAdjustmentsConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByInvoiceId: PaymentsConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceId: ItemsConnection;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByInvoiceId: BidderCreditHistoriesConnection;
  /** Reads and enables pagination through a set of `InvoiceNote`. */
  invoiceNotesByInvoiceId: InvoiceNotesConnection;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByInvoiceId: AuctionInvoicesViewsConnection;
  /** Number of total alternate payment credits (in whole cents) available for refund. */
  alternateCreditsAvailableForRefund?: Maybe<Scalars['Int']>;
  /** Number of total alternate payment credits (in whole cents) that were refunded. */
  alternateCreditsRefunded?: Maybe<Scalars['Int']>;
  amountDue?: Maybe<Scalars['BigInt']>;
  /** Number of total bidder credits (in whole cents) that were refunded. */
  bidderCreditsRefunded?: Maybe<Scalars['Int']>;
  countTotalReturns?: Maybe<Scalars['Int']>;
  feesSubtotal?: Maybe<Scalars['BigInt']>;
  hasRelists?: Maybe<Scalars['Boolean']>;
  hasReturns?: Maybe<Scalars['Boolean']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  isInPickupQueue?: Maybe<Scalars['Boolean']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  isStarted?: Maybe<Scalars['Boolean']>;
  itemsBidTotal?: Maybe<Scalars['BigInt']>;
  itemsCountActive?: Maybe<Scalars['Int']>;
  itemsCountAll?: Maybe<Scalars['Int']>;
  itemsPremiumTotal?: Maybe<Scalars['BigInt']>;
  itemsSubtotal?: Maybe<Scalars['BigInt']>;
  itemsTaxTotal?: Maybe<Scalars['BigInt']>;
  /** Number of total payment credits (in whole cents) available for refund. */
  paymentCreditsAvailableForRefund?: Maybe<Scalars['Int']>;
  /** Number of total payment credits (in whole cents) that were refunded. */
  paymentCreditsRefunded?: Maybe<Scalars['Int']>;
  total?: Maybe<Scalars['BigInt']>;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByInvoicesSearchInvoiceIdAndBidderId: InvoiceBiddersByInvoicesSearchInvoiceIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByInvoicesSearchInvoiceIdAndAuctionId: InvoiceAuctionsByInvoicesSearchInvoiceIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByItemInvoiceIdAndAuctionId: InvoiceAuctionsByItemInvoiceIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByItemInvoiceIdAndPaymentId: InvoicePaymentsByItemInvoiceIdAndPaymentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByItemInvoiceIdAndBidderId: InvoiceBiddersByItemInvoiceIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemInvoiceIdAndDeletedFromInvoiceBy: InvoiceUsersByItemInvoiceIdAndDeletedFromInvoiceByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemInvoiceIdAndSetAsideBy: InvoiceUsersByItemInvoiceIdAndSetAsideByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemInvoiceIdAndIsDeletedBy: InvoiceUsersByItemInvoiceIdAndIsDeletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByBidderCreditHistoryInvoiceIdAndBidderId: InvoiceBiddersByBidderCreditHistoryInvoiceIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByBidderCreditHistoryInvoiceIdAndAuctionId: InvoiceAuctionsByBidderCreditHistoryInvoiceIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderCreditHistoryInvoiceIdAndItemId: InvoiceItemsByBidderCreditHistoryInvoiceIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByBidderCreditHistoryInvoiceIdAndCreatedBy: InvoiceUsersByBidderCreditHistoryInvoiceIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceNoteInvoiceIdAndCreatedBy: InvoiceUsersByInvoiceNoteInvoiceIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByAuctionInvoicesViewInvoiceIdAndCustomerId: InvoiceBiddersByAuctionInvoicesViewInvoiceIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByAuctionInvoicesViewInvoiceIdAndAuctionId: InvoiceAuctionsByAuctionInvoicesViewInvoiceIdAndAuctionIdManyToManyConnection;
};


export type InvoiceInvoicesSearchesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};


export type InvoiceInvoiceAdjustmentsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceAdjustmentsOrderBy>>;
  condition?: Maybe<InvoiceAdjustmentCondition>;
  filter?: Maybe<InvoiceAdjustmentFilter>;
};


export type InvoicePaymentsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy>>;
  condition?: Maybe<PaymentCondition>;
  filter?: Maybe<PaymentFilter>;
};


export type InvoiceItemsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type InvoiceBidderCreditHistoriesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};


export type InvoiceInvoiceNotesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceNotesOrderBy>>;
  condition?: Maybe<InvoiceNoteCondition>;
  filter?: Maybe<InvoiceNoteFilter>;
};


export type InvoiceAuctionInvoicesViewsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};


export type InvoiceBiddersByInvoicesSearchInvoiceIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type InvoiceAuctionsByInvoicesSearchInvoiceIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type InvoiceAuctionsByItemInvoiceIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type InvoicePaymentsByItemInvoiceIdAndPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy>>;
  condition?: Maybe<PaymentCondition>;
  filter?: Maybe<PaymentFilter>;
};


export type InvoiceBiddersByItemInvoiceIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type InvoiceUsersByItemInvoiceIdAndDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type InvoiceUsersByItemInvoiceIdAndSetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type InvoiceUsersByItemInvoiceIdAndIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type InvoiceBiddersByBidderCreditHistoryInvoiceIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type InvoiceAuctionsByBidderCreditHistoryInvoiceIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type InvoiceItemsByBidderCreditHistoryInvoiceIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type InvoiceUsersByBidderCreditHistoryInvoiceIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type InvoiceUsersByInvoiceNoteInvoiceIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type InvoiceBiddersByAuctionInvoicesViewInvoiceIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type InvoiceAuctionsByAuctionInvoicesViewInvoiceIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};

export type InvoiceAdjustment = Node & {
  __typename?: 'InvoiceAdjustment';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  invoiceId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  isDeleted: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Invoice` that is related to this `InvoiceAdjustment`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
};

/**
 * A condition to be used against `InvoiceAdjustment` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InvoiceAdjustmentCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invoiceId` field. */
  invoiceId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `InvoiceAdjustment` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceAdjustmentFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceId` field. */
  invoiceId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `amount` field. */
  amount?: Maybe<IntFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `invoiceByInvoiceId` relation. */
  invoiceByInvoiceId?: Maybe<InvoiceFilter>;
  /** A related `invoiceByInvoiceId` exists. */
  invoiceByInvoiceIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<InvoiceAdjustmentFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<InvoiceAdjustmentFilter>>;
  /** Negates the expression. */
  not?: Maybe<InvoiceAdjustmentFilter>;
};

/** An input for mutations affecting `InvoiceAdjustment` */
export type InvoiceAdjustmentInput = {
  invoiceId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/** Represents an update to a `InvoiceAdjustment`. Fields that are set will be updated. */
export type InvoiceAdjustmentPatch = {
  invoiceId?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `InvoiceAdjustment` values. */
export type InvoiceAdjustmentsConnection = {
  __typename?: 'InvoiceAdjustmentsConnection';
  /** A list of `InvoiceAdjustment` objects. */
  nodes: Array<InvoiceAdjustment>;
  /** A list of edges which contains the `InvoiceAdjustment` and cursor to aid in pagination. */
  edges: Array<InvoiceAdjustmentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvoiceAdjustment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InvoiceAdjustment` edge in the connection. */
export type InvoiceAdjustmentsEdge = {
  __typename?: 'InvoiceAdjustmentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InvoiceAdjustment` at the end of the edge. */
  node: InvoiceAdjustment;
};

/** Methods to use when ordering `InvoiceAdjustment`. */
export enum InvoiceAdjustmentsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvoiceIdAsc = 'INVOICE_ID_ASC',
  InvoiceIdDesc = 'INVOICE_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Auction` values, with data from `AuctionInvoicesView`. */
export type InvoiceAuctionsByAuctionInvoicesViewInvoiceIdAndAuctionIdManyToManyConnection = {
  __typename?: 'InvoiceAuctionsByAuctionInvoicesViewInvoiceIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `AuctionInvoicesView`, and the cursor to aid in pagination. */
  edges: Array<InvoiceAuctionsByAuctionInvoicesViewInvoiceIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `AuctionInvoicesView`. */
export type InvoiceAuctionsByAuctionInvoicesViewInvoiceIdAndAuctionIdManyToManyEdge = {
  __typename?: 'InvoiceAuctionsByAuctionInvoicesViewInvoiceIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByAuctionId: AuctionInvoicesViewsConnection;
};


/** A `Auction` edge in the connection, with data from `AuctionInvoicesView`. */
export type InvoiceAuctionsByAuctionInvoicesViewInvoiceIdAndAuctionIdManyToManyEdgeAuctionInvoicesViewsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};

/** A connection to a list of `Auction` values, with data from `BidderCreditHistory`. */
export type InvoiceAuctionsByBidderCreditHistoryInvoiceIdAndAuctionIdManyToManyConnection = {
  __typename?: 'InvoiceAuctionsByBidderCreditHistoryInvoiceIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<InvoiceAuctionsByBidderCreditHistoryInvoiceIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `BidderCreditHistory`. */
export type InvoiceAuctionsByBidderCreditHistoryInvoiceIdAndAuctionIdManyToManyEdge = {
  __typename?: 'InvoiceAuctionsByBidderCreditHistoryInvoiceIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByAuctionId: BidderCreditHistoriesConnection;
};


/** A `Auction` edge in the connection, with data from `BidderCreditHistory`. */
export type InvoiceAuctionsByBidderCreditHistoryInvoiceIdAndAuctionIdManyToManyEdgeBidderCreditHistoriesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Auction` values, with data from `InvoicesSearch`. */
export type InvoiceAuctionsByInvoicesSearchInvoiceIdAndAuctionIdManyToManyConnection = {
  __typename?: 'InvoiceAuctionsByInvoicesSearchInvoiceIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `InvoicesSearch`, and the cursor to aid in pagination. */
  edges: Array<InvoiceAuctionsByInvoicesSearchInvoiceIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `InvoicesSearch`. */
export type InvoiceAuctionsByInvoicesSearchInvoiceIdAndAuctionIdManyToManyEdge = {
  __typename?: 'InvoiceAuctionsByInvoicesSearchInvoiceIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByAuctionId: InvoicesSearchesConnection;
};


/** A `Auction` edge in the connection, with data from `InvoicesSearch`. */
export type InvoiceAuctionsByInvoicesSearchInvoiceIdAndAuctionIdManyToManyEdgeInvoicesSearchesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};

/** A connection to a list of `Auction` values, with data from `Item`. */
export type InvoiceAuctionsByItemInvoiceIdAndAuctionIdManyToManyConnection = {
  __typename?: 'InvoiceAuctionsByItemInvoiceIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<InvoiceAuctionsByItemInvoiceIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Item`. */
export type InvoiceAuctionsByItemInvoiceIdAndAuctionIdManyToManyEdge = {
  __typename?: 'InvoiceAuctionsByItemInvoiceIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByAuctionId: ItemsConnection;
};


/** A `Auction` edge in the connection, with data from `Item`. */
export type InvoiceAuctionsByItemInvoiceIdAndAuctionIdManyToManyEdgeItemsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Bidder` values, with data from `AuctionInvoicesView`. */
export type InvoiceBiddersByAuctionInvoicesViewInvoiceIdAndCustomerIdManyToManyConnection = {
  __typename?: 'InvoiceBiddersByAuctionInvoicesViewInvoiceIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `AuctionInvoicesView`, and the cursor to aid in pagination. */
  edges: Array<InvoiceBiddersByAuctionInvoicesViewInvoiceIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `AuctionInvoicesView`. */
export type InvoiceBiddersByAuctionInvoicesViewInvoiceIdAndCustomerIdManyToManyEdge = {
  __typename?: 'InvoiceBiddersByAuctionInvoicesViewInvoiceIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  auctionInvoicesViewsByCustomerId: AuctionInvoicesViewsConnection;
};


/** A `Bidder` edge in the connection, with data from `AuctionInvoicesView`. */
export type InvoiceBiddersByAuctionInvoicesViewInvoiceIdAndCustomerIdManyToManyEdgeAuctionInvoicesViewsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};

/** A connection to a list of `Bidder` values, with data from `BidderCreditHistory`. */
export type InvoiceBiddersByBidderCreditHistoryInvoiceIdAndBidderIdManyToManyConnection = {
  __typename?: 'InvoiceBiddersByBidderCreditHistoryInvoiceIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<InvoiceBiddersByBidderCreditHistoryInvoiceIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `BidderCreditHistory`. */
export type InvoiceBiddersByBidderCreditHistoryInvoiceIdAndBidderIdManyToManyEdge = {
  __typename?: 'InvoiceBiddersByBidderCreditHistoryInvoiceIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByBidderId: BidderCreditHistoriesConnection;
};


/** A `Bidder` edge in the connection, with data from `BidderCreditHistory`. */
export type InvoiceBiddersByBidderCreditHistoryInvoiceIdAndBidderIdManyToManyEdgeBidderCreditHistoriesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Bidder` values, with data from `InvoicesSearch`. */
export type InvoiceBiddersByInvoicesSearchInvoiceIdAndBidderIdManyToManyConnection = {
  __typename?: 'InvoiceBiddersByInvoicesSearchInvoiceIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `InvoicesSearch`, and the cursor to aid in pagination. */
  edges: Array<InvoiceBiddersByInvoicesSearchInvoiceIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `InvoicesSearch`. */
export type InvoiceBiddersByInvoicesSearchInvoiceIdAndBidderIdManyToManyEdge = {
  __typename?: 'InvoiceBiddersByInvoicesSearchInvoiceIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  invoicesSearchesByBidderId: InvoicesSearchesConnection;
};


/** A `Bidder` edge in the connection, with data from `InvoicesSearch`. */
export type InvoiceBiddersByInvoicesSearchInvoiceIdAndBidderIdManyToManyEdgeInvoicesSearchesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Item`. */
export type InvoiceBiddersByItemInvoiceIdAndBidderIdManyToManyConnection = {
  __typename?: 'InvoiceBiddersByItemInvoiceIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<InvoiceBiddersByItemInvoiceIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Item`. */
export type InvoiceBiddersByItemInvoiceIdAndBidderIdManyToManyEdge = {
  __typename?: 'InvoiceBiddersByItemInvoiceIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderId: ItemsConnection;
};


/** A `Bidder` edge in the connection, with data from `Item`. */
export type InvoiceBiddersByItemInvoiceIdAndBidderIdManyToManyEdgeItemsByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A condition to be used against `Invoice` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type InvoiceCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `uid` field. */
  uid?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `publicToken` field. */
  publicToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `auctionId` field. */
  auctionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bidderId` field. */
  bidderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bidderSignatureImageName` field. */
  bidderSignatureImageName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bidderSignatureSignedUrl` field. */
  bidderSignatureSignedUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bidderSignatureExpiredAt` field. */
  bidderSignatureExpiredAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `bidderCreditsUsed` field. */
  bidderCreditsUsed?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentCreditsUsed` field. */
  paymentCreditsUsed?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `alternateCreditsUsed` field. */
  alternateCreditsUsed?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `canProceedPayment` field. */
  canProceedPayment?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isProcessingPayment` field. */
  isProcessingPayment?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isInitialPaymentProcessed` field. */
  isInitialPaymentProcessed?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `processPayment` field. */
  processPayment?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `processPaymentReason` field. */
  processPaymentReason?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `processPaymentChangedBy` field. */
  processPaymentChangedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `processPaymentChangedAt` field. */
  processPaymentChangedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `alternatePaymentNote` field. */
  alternatePaymentNote?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `alternatePaymentCreatedBy` field. */
  alternatePaymentCreatedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `alternatePaymentCreatedAt` field. */
  alternatePaymentCreatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lastPickupDate` field. */
  lastPickupDate?: Maybe<Scalars['Date']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lpudChangedType` field. */
  lpudChangedType?: Maybe<LpudChangedType>;
  /** Checks for equality with the object’s `canDisplayToCustomer` field. */
  canDisplayToCustomer?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `elevatedToken` field. */
  elevatedToken?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `extType` field. */
  extType?: Maybe<ExtType>;
  /** Checks for equality with the object’s `merId` field. */
  merId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lastPutOnHoldEventAt` field. */
  lastPutOnHoldEventAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `uid` field. */
  uid?: Maybe<StringFilter>;
  /** Filter by the object’s `publicToken` field. */
  publicToken?: Maybe<StringFilter>;
  /** Filter by the object’s `auctionId` field. */
  auctionId?: Maybe<IntFilter>;
  /** Filter by the object’s `bidderId` field. */
  bidderId?: Maybe<IntFilter>;
  /** Filter by the object’s `bidderSignatureImageName` field. */
  bidderSignatureImageName?: Maybe<StringFilter>;
  /** Filter by the object’s `bidderSignatureSignedUrl` field. */
  bidderSignatureSignedUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `bidderSignatureExpiredAt` field. */
  bidderSignatureExpiredAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `bidderCreditsUsed` field. */
  bidderCreditsUsed?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentCreditsUsed` field. */
  paymentCreditsUsed?: Maybe<IntFilter>;
  /** Filter by the object’s `alternateCreditsUsed` field. */
  alternateCreditsUsed?: Maybe<IntFilter>;
  /** Filter by the object’s `canProceedPayment` field. */
  canProceedPayment?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isProcessingPayment` field. */
  isProcessingPayment?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isInitialPaymentProcessed` field. */
  isInitialPaymentProcessed?: Maybe<BooleanFilter>;
  /** Filter by the object’s `processPayment` field. */
  processPayment?: Maybe<BooleanFilter>;
  /** Filter by the object’s `processPaymentReason` field. */
  processPaymentReason?: Maybe<StringFilter>;
  /** Filter by the object’s `processPaymentChangedBy` field. */
  processPaymentChangedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `processPaymentChangedAt` field. */
  processPaymentChangedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `alternatePaymentNote` field. */
  alternatePaymentNote?: Maybe<StringFilter>;
  /** Filter by the object’s `alternatePaymentCreatedBy` field. */
  alternatePaymentCreatedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `alternatePaymentCreatedAt` field. */
  alternatePaymentCreatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lastPickupDate` field. */
  lastPickupDate?: Maybe<DateFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lpudChangedType` field. */
  lpudChangedType?: Maybe<LpudChangedTypeFilter>;
  /** Filter by the object’s `canDisplayToCustomer` field. */
  canDisplayToCustomer?: Maybe<BooleanFilter>;
  /** Filter by the object’s `elevatedToken` field. */
  elevatedToken?: Maybe<StringFilter>;
  /** Filter by the object’s `extType` field. */
  extType?: Maybe<ExtTypeFilter>;
  /** Filter by the object’s `merId` field. */
  merId?: Maybe<StringFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `lastPutOnHoldEventAt` field. */
  lastPutOnHoldEventAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `alternateCreditsAvailableForRefund` field. */
  alternateCreditsAvailableForRefund?: Maybe<IntFilter>;
  /** Filter by the object’s `alternateCreditsRefunded` field. */
  alternateCreditsRefunded?: Maybe<IntFilter>;
  /** Filter by the object’s `amountDue` field. */
  amountDue?: Maybe<BigIntFilter>;
  /** Filter by the object’s `bidderCreditsRefunded` field. */
  bidderCreditsRefunded?: Maybe<IntFilter>;
  /** Filter by the object’s `countTotalReturns` field. */
  countTotalReturns?: Maybe<IntFilter>;
  /** Filter by the object’s `feesSubtotal` field. */
  feesSubtotal?: Maybe<BigIntFilter>;
  /** Filter by the object’s `hasRelists` field. */
  hasRelists?: Maybe<BooleanFilter>;
  /** Filter by the object’s `hasReturns` field. */
  hasReturns?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isInPickupQueue` field. */
  isInPickupQueue?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPaid` field. */
  isPaid?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isStarted` field. */
  isStarted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `itemsBidTotal` field. */
  itemsBidTotal?: Maybe<BigIntFilter>;
  /** Filter by the object’s `itemsCountActive` field. */
  itemsCountActive?: Maybe<IntFilter>;
  /** Filter by the object’s `itemsCountAll` field. */
  itemsCountAll?: Maybe<IntFilter>;
  /** Filter by the object’s `itemsPremiumTotal` field. */
  itemsPremiumTotal?: Maybe<BigIntFilter>;
  /** Filter by the object’s `itemsSubtotal` field. */
  itemsSubtotal?: Maybe<BigIntFilter>;
  /** Filter by the object’s `itemsTaxTotal` field. */
  itemsTaxTotal?: Maybe<BigIntFilter>;
  /** Filter by the object’s `paymentCreditsAvailableForRefund` field. */
  paymentCreditsAvailableForRefund?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentCreditsRefunded` field. */
  paymentCreditsRefunded?: Maybe<IntFilter>;
  /** Filter by the object’s `total` field. */
  total?: Maybe<BigIntFilter>;
  /** Filter by the object’s `invoicesSearchesByInvoiceId` relation. */
  invoicesSearchesByInvoiceId?: Maybe<InvoiceToManyInvoicesSearchFilter>;
  /** Some related `invoicesSearchesByInvoiceId` exist. */
  invoicesSearchesByInvoiceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceAdjustmentsByInvoiceId` relation. */
  invoiceAdjustmentsByInvoiceId?: Maybe<InvoiceToManyInvoiceAdjustmentFilter>;
  /** Some related `invoiceAdjustmentsByInvoiceId` exist. */
  invoiceAdjustmentsByInvoiceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `paymentsByInvoiceId` relation. */
  paymentsByInvoiceId?: Maybe<InvoiceToManyPaymentFilter>;
  /** Some related `paymentsByInvoiceId` exist. */
  paymentsByInvoiceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemsByInvoiceId` relation. */
  itemsByInvoiceId?: Maybe<InvoiceToManyItemFilter>;
  /** Some related `itemsByInvoiceId` exist. */
  itemsByInvoiceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderCreditHistoriesByInvoiceId` relation. */
  bidderCreditHistoriesByInvoiceId?: Maybe<InvoiceToManyBidderCreditHistoryFilter>;
  /** Some related `bidderCreditHistoriesByInvoiceId` exist. */
  bidderCreditHistoriesByInvoiceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceNotesByInvoiceId` relation. */
  invoiceNotesByInvoiceId?: Maybe<InvoiceToManyInvoiceNoteFilter>;
  /** Some related `invoiceNotesByInvoiceId` exist. */
  invoiceNotesByInvoiceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auctionInvoicesViewsByInvoiceId` relation. */
  auctionInvoicesViewsByInvoiceId?: Maybe<InvoiceToManyAuctionInvoicesViewFilter>;
  /** Some related `auctionInvoicesViewsByInvoiceId` exist. */
  auctionInvoicesViewsByInvoiceIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auctionByAuctionId` relation. */
  auctionByAuctionId?: Maybe<AuctionFilter>;
  /** A related `auctionByAuctionId` exists. */
  auctionByAuctionIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderByBidderId` relation. */
  bidderByBidderId?: Maybe<BidderFilter>;
  /** A related `bidderByBidderId` exists. */
  bidderByBidderIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByProcessPaymentChangedBy` relation. */
  userByProcessPaymentChangedBy?: Maybe<UserFilter>;
  /** A related `userByProcessPaymentChangedBy` exists. */
  userByProcessPaymentChangedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByAlternatePaymentCreatedBy` relation. */
  userByAlternatePaymentCreatedBy?: Maybe<UserFilter>;
  /** A related `userByAlternatePaymentCreatedBy` exists. */
  userByAlternatePaymentCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<InvoiceFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<InvoiceFilter>>;
  /** Negates the expression. */
  not?: Maybe<InvoiceFilter>;
};

export type InvoiceItemEventInput = {
  invoiceId: Scalars['Int'];
};

export type InvoiceItemEventSubscriptionPayload = {
  __typename?: 'InvoiceItemEventSubscriptionPayload';
  item?: Maybe<Item>;
  event: InvoiceItemEventType;
};

export enum InvoiceItemEventType {
  Updated = 'updated'
}

export type InvoiceItemReturn = Node & {
  __typename?: 'InvoiceItemReturn';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  invoiceItemId: Scalars['Int'];
  creditAmount: Scalars['Int'];
  paymentAmount: Scalars['Int'];
  alternatePaymentAmount: Scalars['Int'];
  status: InvoiceItemReturnStatusType;
  wasSuccessful: Scalars['Boolean'];
  errorMessage?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['Datetime'];
  updatedBy?: Maybe<Scalars['Int']>;
  optionId: Scalars['UUID'];
  note?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['UUID']>;
  /** Reads a single `Item` that is related to this `InvoiceItemReturn`. */
  itemByInvoiceItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `InvoiceItemReturn`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `InvoiceItemReturn`. */
  userByUpdatedBy?: Maybe<User>;
  /** Reads a single `ReturnOption` that is related to this `InvoiceItemReturn`. */
  returnOptionByOptionId?: Maybe<ReturnOption>;
  /** Reads a single `ReturnQueue` that is related to this `InvoiceItemReturn`. */
  returnQueueByQueueId?: Maybe<ReturnQueue>;
  /** Reads and enables pagination through a set of `InvoiceItemReturnHistory`. */
  invoiceItemReturnHistoriesByReturnId: InvoiceItemReturnHistoriesConnection;
  canDeleteCredit?: Maybe<Scalars['Boolean']>;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnHistoryReturnIdAndBy: InvoiceItemReturnUsersByInvoiceItemReturnHistoryReturnIdAndByManyToManyConnection;
};


export type InvoiceItemReturnInvoiceItemReturnHistoriesByReturnIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnHistoriesOrderBy>>;
  condition?: Maybe<InvoiceItemReturnHistoryCondition>;
  filter?: Maybe<InvoiceItemReturnHistoryFilter>;
};


export type InvoiceItemReturnUsersByInvoiceItemReturnHistoryReturnIdAndByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/**
 * A condition to be used against `InvoiceItemReturn` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InvoiceItemReturnCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invoiceItemId` field. */
  invoiceItemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `creditAmount` field. */
  creditAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentAmount` field. */
  paymentAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `alternatePaymentAmount` field. */
  alternatePaymentAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<InvoiceItemReturnStatusType>;
  /** Checks for equality with the object’s `wasSuccessful` field. */
  wasSuccessful?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `errorMessage` field. */
  errorMessage?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `optionId` field. */
  optionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `note` field. */
  note?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `queueId` field. */
  queueId?: Maybe<Scalars['UUID']>;
};

/** A filter to be used against `InvoiceItemReturn` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceItemReturnFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceItemId` field. */
  invoiceItemId?: Maybe<IntFilter>;
  /** Filter by the object’s `creditAmount` field. */
  creditAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentAmount` field. */
  paymentAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `alternatePaymentAmount` field. */
  alternatePaymentAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<InvoiceItemReturnStatusTypeFilter>;
  /** Filter by the object’s `wasSuccessful` field. */
  wasSuccessful?: Maybe<BooleanFilter>;
  /** Filter by the object’s `errorMessage` field. */
  errorMessage?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `optionId` field. */
  optionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `note` field. */
  note?: Maybe<StringFilter>;
  /** Filter by the object’s `queueId` field. */
  queueId?: Maybe<UuidFilter>;
  /** Filter by the object’s `canDeleteCredit` field. */
  canDeleteCredit?: Maybe<BooleanFilter>;
  /** Filter by the object’s `invoiceItemReturnHistoriesByReturnId` relation. */
  invoiceItemReturnHistoriesByReturnId?: Maybe<InvoiceItemReturnToManyInvoiceItemReturnHistoryFilter>;
  /** Some related `invoiceItemReturnHistoriesByReturnId` exist. */
  invoiceItemReturnHistoriesByReturnIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemByInvoiceItemId` relation. */
  itemByInvoiceItemId?: Maybe<ItemFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUpdatedBy` relation. */
  userByUpdatedBy?: Maybe<UserFilter>;
  /** A related `userByUpdatedBy` exists. */
  userByUpdatedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `returnOptionByOptionId` relation. */
  returnOptionByOptionId?: Maybe<ReturnOptionFilter>;
  /** Filter by the object’s `returnQueueByQueueId` relation. */
  returnQueueByQueueId?: Maybe<ReturnQueueFilter>;
  /** A related `returnQueueByQueueId` exists. */
  returnQueueByQueueIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<InvoiceItemReturnFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<InvoiceItemReturnFilter>>;
  /** Negates the expression. */
  not?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `InvoiceItemReturnHistory` values. */
export type InvoiceItemReturnHistoriesConnection = {
  __typename?: 'InvoiceItemReturnHistoriesConnection';
  /** A list of `InvoiceItemReturnHistory` objects. */
  nodes: Array<InvoiceItemReturnHistory>;
  /** A list of edges which contains the `InvoiceItemReturnHistory` and cursor to aid in pagination. */
  edges: Array<InvoiceItemReturnHistoriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvoiceItemReturnHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InvoiceItemReturnHistory` edge in the connection. */
export type InvoiceItemReturnHistoriesEdge = {
  __typename?: 'InvoiceItemReturnHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InvoiceItemReturnHistory` at the end of the edge. */
  node: InvoiceItemReturnHistory;
};

/** Methods to use when ordering `InvoiceItemReturnHistory`. */
export enum InvoiceItemReturnHistoriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ReturnIdAsc = 'RETURN_ID_ASC',
  ReturnIdDesc = 'RETURN_ID_DESC',
  ActionAsc = 'ACTION_ASC',
  ActionDesc = 'ACTION_DESC',
  ReasonAsc = 'REASON_ASC',
  ReasonDesc = 'REASON_DESC',
  DataAsc = 'DATA_ASC',
  DataDesc = 'DATA_DESC',
  TsAsc = 'TS_ASC',
  TsDesc = 'TS_DESC',
  ByAsc = 'BY_ASC',
  ByDesc = 'BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type InvoiceItemReturnHistory = Node & {
  __typename?: 'InvoiceItemReturnHistory';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  returnId: Scalars['Int'];
  action: InvoiceItemReturnHistoryAction;
  reason: Scalars['String'];
  data: Scalars['JSON'];
  ts: Scalars['Datetime'];
  by: Scalars['Int'];
  /** Reads a single `InvoiceItemReturn` that is related to this `InvoiceItemReturnHistory`. */
  invoiceItemReturnByReturnId?: Maybe<InvoiceItemReturn>;
  /** Reads a single `User` that is related to this `InvoiceItemReturnHistory`. */
  userByBy?: Maybe<User>;
};

export enum InvoiceItemReturnHistoryAction {
  DeleteCredit = 'DELETE_CREDIT'
}

/** A filter to be used against InvoiceItemReturnHistoryAction fields. All fields are combined with a logical ‘and.’ */
export type InvoiceItemReturnHistoryActionFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<InvoiceItemReturnHistoryAction>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<InvoiceItemReturnHistoryAction>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<InvoiceItemReturnHistoryAction>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<InvoiceItemReturnHistoryAction>;
  /** Included in the specified list. */
  in?: Maybe<Array<InvoiceItemReturnHistoryAction>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<InvoiceItemReturnHistoryAction>>;
  /** Less than the specified value. */
  lessThan?: Maybe<InvoiceItemReturnHistoryAction>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<InvoiceItemReturnHistoryAction>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<InvoiceItemReturnHistoryAction>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<InvoiceItemReturnHistoryAction>;
};

/**
 * A condition to be used against `InvoiceItemReturnHistory` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type InvoiceItemReturnHistoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `returnId` field. */
  returnId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `action` field. */
  action?: Maybe<InvoiceItemReturnHistoryAction>;
  /** Checks for equality with the object’s `reason` field. */
  reason?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `data` field. */
  data?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `ts` field. */
  ts?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `by` field. */
  by?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `InvoiceItemReturnHistory` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceItemReturnHistoryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `returnId` field. */
  returnId?: Maybe<IntFilter>;
  /** Filter by the object’s `action` field. */
  action?: Maybe<InvoiceItemReturnHistoryActionFilter>;
  /** Filter by the object’s `reason` field. */
  reason?: Maybe<StringFilter>;
  /** Filter by the object’s `data` field. */
  data?: Maybe<JsonFilter>;
  /** Filter by the object’s `ts` field. */
  ts?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `by` field. */
  by?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceItemReturnByReturnId` relation. */
  invoiceItemReturnByReturnId?: Maybe<InvoiceItemReturnFilter>;
  /** Filter by the object’s `userByBy` relation. */
  userByBy?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<InvoiceItemReturnHistoryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<InvoiceItemReturnHistoryFilter>>;
  /** Negates the expression. */
  not?: Maybe<InvoiceItemReturnHistoryFilter>;
};

/** An input for mutations affecting `InvoiceItemReturn` */
export type InvoiceItemReturnInput = {
  invoiceItemId: Scalars['Int'];
  creditAmount?: Maybe<Scalars['Int']>;
  paymentAmount?: Maybe<Scalars['Int']>;
  alternatePaymentAmount?: Maybe<Scalars['Int']>;
  status?: Maybe<InvoiceItemReturnStatusType>;
  optionId: Scalars['UUID'];
  note?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['UUID']>;
};

export enum InvoiceItemReturnStatusType {
  Created = 'CREATED',
  Approved = 'APPROVED',
  Processing = 'PROCESSING',
  Completed = 'COMPLETED'
}

/** A filter to be used against InvoiceItemReturnStatusType fields. All fields are combined with a logical ‘and.’ */
export type InvoiceItemReturnStatusTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<InvoiceItemReturnStatusType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<InvoiceItemReturnStatusType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<InvoiceItemReturnStatusType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<InvoiceItemReturnStatusType>;
  /** Included in the specified list. */
  in?: Maybe<Array<InvoiceItemReturnStatusType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<InvoiceItemReturnStatusType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<InvoiceItemReturnStatusType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<InvoiceItemReturnStatusType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<InvoiceItemReturnStatusType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<InvoiceItemReturnStatusType>;
};

/** A filter to be used against many `InvoiceItemReturnHistory` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceItemReturnToManyInvoiceItemReturnHistoryFilter = {
  /** Every related `InvoiceItemReturnHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceItemReturnHistoryFilter>;
  /** Some related `InvoiceItemReturnHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceItemReturnHistoryFilter>;
  /** No related `InvoiceItemReturnHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceItemReturnHistoryFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturnHistory`. */
export type InvoiceItemReturnUsersByInvoiceItemReturnHistoryReturnIdAndByManyToManyConnection = {
  __typename?: 'InvoiceItemReturnUsersByInvoiceItemReturnHistoryReturnIdAndByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturnHistory`, and the cursor to aid in pagination. */
  edges: Array<InvoiceItemReturnUsersByInvoiceItemReturnHistoryReturnIdAndByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturnHistory`. */
export type InvoiceItemReturnUsersByInvoiceItemReturnHistoryReturnIdAndByManyToManyEdge = {
  __typename?: 'InvoiceItemReturnUsersByInvoiceItemReturnHistoryReturnIdAndByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturnHistory`. */
  invoiceItemReturnHistoriesByBy: InvoiceItemReturnHistoriesConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturnHistory`. */
export type InvoiceItemReturnUsersByInvoiceItemReturnHistoryReturnIdAndByManyToManyEdgeInvoiceItemReturnHistoriesByByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnHistoriesOrderBy>>;
  condition?: Maybe<InvoiceItemReturnHistoryCondition>;
  filter?: Maybe<InvoiceItemReturnHistoryFilter>;
};

/** A connection to a list of `InvoiceItemReturn` values. */
export type InvoiceItemReturnsConnection = {
  __typename?: 'InvoiceItemReturnsConnection';
  /** A list of `InvoiceItemReturn` objects. */
  nodes: Array<InvoiceItemReturn>;
  /** A list of edges which contains the `InvoiceItemReturn` and cursor to aid in pagination. */
  edges: Array<InvoiceItemReturnsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvoiceItemReturn` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InvoiceItemReturn` edge in the connection. */
export type InvoiceItemReturnsEdge = {
  __typename?: 'InvoiceItemReturnsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InvoiceItemReturn` at the end of the edge. */
  node: InvoiceItemReturn;
};

/** Methods to use when ordering `InvoiceItemReturn`. */
export enum InvoiceItemReturnsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvoiceItemIdAsc = 'INVOICE_ITEM_ID_ASC',
  InvoiceItemIdDesc = 'INVOICE_ITEM_ID_DESC',
  CreditAmountAsc = 'CREDIT_AMOUNT_ASC',
  CreditAmountDesc = 'CREDIT_AMOUNT_DESC',
  PaymentAmountAsc = 'PAYMENT_AMOUNT_ASC',
  PaymentAmountDesc = 'PAYMENT_AMOUNT_DESC',
  AlternatePaymentAmountAsc = 'ALTERNATE_PAYMENT_AMOUNT_ASC',
  AlternatePaymentAmountDesc = 'ALTERNATE_PAYMENT_AMOUNT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  WasSuccessfulAsc = 'WAS_SUCCESSFUL_ASC',
  WasSuccessfulDesc = 'WAS_SUCCESSFUL_DESC',
  ErrorMessageAsc = 'ERROR_MESSAGE_ASC',
  ErrorMessageDesc = 'ERROR_MESSAGE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  OptionIdAsc = 'OPTION_ID_ASC',
  OptionIdDesc = 'OPTION_ID_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  QueueIdAsc = 'QUEUE_ID_ASC',
  QueueIdDesc = 'QUEUE_ID_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Item` values, with data from `BidderCreditHistory`. */
export type InvoiceItemsByBidderCreditHistoryInvoiceIdAndItemIdManyToManyConnection = {
  __typename?: 'InvoiceItemsByBidderCreditHistoryInvoiceIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<InvoiceItemsByBidderCreditHistoryInvoiceIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `BidderCreditHistory`. */
export type InvoiceItemsByBidderCreditHistoryInvoiceIdAndItemIdManyToManyEdge = {
  __typename?: 'InvoiceItemsByBidderCreditHistoryInvoiceIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByItemId: BidderCreditHistoriesConnection;
};


/** A `Item` edge in the connection, with data from `BidderCreditHistory`. */
export type InvoiceItemsByBidderCreditHistoryInvoiceIdAndItemIdManyToManyEdgeBidderCreditHistoriesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

export type InvoiceNote = Node & {
  __typename?: 'InvoiceNote';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  invoiceId: Scalars['Int'];
  note: Scalars['String'];
  createdBy: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `Invoice` that is related to this `InvoiceNote`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `User` that is related to this `InvoiceNote`. */
  userByCreatedBy?: Maybe<User>;
};

/**
 * A condition to be used against `InvoiceNote` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type InvoiceNoteCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invoiceId` field. */
  invoiceId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `note` field. */
  note?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `InvoiceNote` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceNoteFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceId` field. */
  invoiceId?: Maybe<IntFilter>;
  /** Filter by the object’s `note` field. */
  note?: Maybe<StringFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `invoiceByInvoiceId` relation. */
  invoiceByInvoiceId?: Maybe<InvoiceFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<InvoiceNoteFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<InvoiceNoteFilter>>;
  /** Negates the expression. */
  not?: Maybe<InvoiceNoteFilter>;
};

/** An input for mutations affecting `InvoiceNote` */
export type InvoiceNoteInput = {
  invoiceId: Scalars['Int'];
  note: Scalars['String'];
};

/** A connection to a list of `InvoiceNote` values. */
export type InvoiceNotesConnection = {
  __typename?: 'InvoiceNotesConnection';
  /** A list of `InvoiceNote` objects. */
  nodes: Array<InvoiceNote>;
  /** A list of edges which contains the `InvoiceNote` and cursor to aid in pagination. */
  edges: Array<InvoiceNotesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvoiceNote` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InvoiceNote` edge in the connection. */
export type InvoiceNotesEdge = {
  __typename?: 'InvoiceNotesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InvoiceNote` at the end of the edge. */
  node: InvoiceNote;
};

/** Methods to use when ordering `InvoiceNote`. */
export enum InvoiceNotesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvoiceIdAsc = 'INVOICE_ID_ASC',
  InvoiceIdDesc = 'INVOICE_ID_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Payment` values, with data from `Item`. */
export type InvoicePaymentsByItemInvoiceIdAndPaymentIdManyToManyConnection = {
  __typename?: 'InvoicePaymentsByItemInvoiceIdAndPaymentIdManyToManyConnection';
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** A list of edges which contains the `Payment`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<InvoicePaymentsByItemInvoiceIdAndPaymentIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection, with data from `Item`. */
export type InvoicePaymentsByItemInvoiceIdAndPaymentIdManyToManyEdge = {
  __typename?: 'InvoicePaymentsByItemInvoiceIdAndPaymentIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPaymentId: ItemsConnection;
};


/** A `Payment` edge in the connection, with data from `Item`. */
export type InvoicePaymentsByItemInvoiceIdAndPaymentIdManyToManyEdgeItemsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A filter to be used against many `AuctionInvoicesView` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceToManyAuctionInvoicesViewFilter = {
  /** Every related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AuctionInvoicesViewFilter>;
  /** Some related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AuctionInvoicesViewFilter>;
  /** No related `AuctionInvoicesView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AuctionInvoicesViewFilter>;
};

/** A filter to be used against many `BidderCreditHistory` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceToManyBidderCreditHistoryFilter = {
  /** Every related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<BidderCreditHistoryFilter>;
  /** Some related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<BidderCreditHistoryFilter>;
  /** No related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<BidderCreditHistoryFilter>;
};

/** A filter to be used against many `InvoiceAdjustment` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceToManyInvoiceAdjustmentFilter = {
  /** Every related `InvoiceAdjustment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceAdjustmentFilter>;
  /** Some related `InvoiceAdjustment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceAdjustmentFilter>;
  /** No related `InvoiceAdjustment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceAdjustmentFilter>;
};

/** A filter to be used against many `InvoiceNote` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceToManyInvoiceNoteFilter = {
  /** Every related `InvoiceNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceNoteFilter>;
  /** Some related `InvoiceNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceNoteFilter>;
  /** No related `InvoiceNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceNoteFilter>;
};

/** A filter to be used against many `InvoicesSearch` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceToManyInvoicesSearchFilter = {
  /** Every related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoicesSearchFilter>;
  /** Some related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoicesSearchFilter>;
  /** No related `InvoicesSearch` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoicesSearchFilter>;
};

/** A filter to be used against many `Item` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceToManyItemFilter = {
  /** Every related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemFilter>;
  /** Some related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemFilter>;
  /** No related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemFilter>;
};

/** A filter to be used against many `Payment` object types. All fields are combined with a logical ‘and.’ */
export type InvoiceToManyPaymentFilter = {
  /** Every related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PaymentFilter>;
  /** Some related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PaymentFilter>;
  /** No related `Payment` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PaymentFilter>;
};

/** A connection to a list of `User` values, with data from `BidderCreditHistory`. */
export type InvoiceUsersByBidderCreditHistoryInvoiceIdAndCreatedByManyToManyConnection = {
  __typename?: 'InvoiceUsersByBidderCreditHistoryInvoiceIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<InvoiceUsersByBidderCreditHistoryInvoiceIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `BidderCreditHistory`. */
export type InvoiceUsersByBidderCreditHistoryInvoiceIdAndCreatedByManyToManyEdge = {
  __typename?: 'InvoiceUsersByBidderCreditHistoryInvoiceIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByCreatedBy: BidderCreditHistoriesConnection;
};


/** A `User` edge in the connection, with data from `BidderCreditHistory`. */
export type InvoiceUsersByBidderCreditHistoryInvoiceIdAndCreatedByManyToManyEdgeBidderCreditHistoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceNote`. */
export type InvoiceUsersByInvoiceNoteInvoiceIdAndCreatedByManyToManyConnection = {
  __typename?: 'InvoiceUsersByInvoiceNoteInvoiceIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceNote`, and the cursor to aid in pagination. */
  edges: Array<InvoiceUsersByInvoiceNoteInvoiceIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceNote`. */
export type InvoiceUsersByInvoiceNoteInvoiceIdAndCreatedByManyToManyEdge = {
  __typename?: 'InvoiceUsersByInvoiceNoteInvoiceIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceNote`. */
  invoiceNotesByCreatedBy: InvoiceNotesConnection;
};


/** A `User` edge in the connection, with data from `InvoiceNote`. */
export type InvoiceUsersByInvoiceNoteInvoiceIdAndCreatedByManyToManyEdgeInvoiceNotesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceNotesOrderBy>>;
  condition?: Maybe<InvoiceNoteCondition>;
  filter?: Maybe<InvoiceNoteFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndDeletedFromInvoiceByManyToManyConnection = {
  __typename?: 'InvoiceUsersByItemInvoiceIdAndDeletedFromInvoiceByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<InvoiceUsersByItemInvoiceIdAndDeletedFromInvoiceByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndDeletedFromInvoiceByManyToManyEdge = {
  __typename?: 'InvoiceUsersByItemInvoiceIdAndDeletedFromInvoiceByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByDeletedFromInvoiceBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndDeletedFromInvoiceByManyToManyEdgeItemsByDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndIsDeletedByManyToManyConnection = {
  __typename?: 'InvoiceUsersByItemInvoiceIdAndIsDeletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<InvoiceUsersByItemInvoiceIdAndIsDeletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndIsDeletedByManyToManyEdge = {
  __typename?: 'InvoiceUsersByItemInvoiceIdAndIsDeletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByIsDeletedBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndIsDeletedByManyToManyEdgeItemsByIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndSetAsideByManyToManyConnection = {
  __typename?: 'InvoiceUsersByItemInvoiceIdAndSetAsideByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<InvoiceUsersByItemInvoiceIdAndSetAsideByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndSetAsideByManyToManyEdge = {
  __typename?: 'InvoiceUsersByItemInvoiceIdAndSetAsideByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySetAsideBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type InvoiceUsersByItemInvoiceIdAndSetAsideByManyToManyEdgeItemsBySetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Invoice` values. */
export type InvoicesConnection = {
  __typename?: 'InvoicesConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice` and cursor to aid in pagination. */
  edges: Array<InvoicesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection. */
export type InvoicesEdge = {
  __typename?: 'InvoicesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
};

/** Methods to use when ordering `Invoice`. */
export enum InvoicesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UidAsc = 'UID_ASC',
  UidDesc = 'UID_DESC',
  PublicTokenAsc = 'PUBLIC_TOKEN_ASC',
  PublicTokenDesc = 'PUBLIC_TOKEN_DESC',
  AuctionIdAsc = 'AUCTION_ID_ASC',
  AuctionIdDesc = 'AUCTION_ID_DESC',
  BidderIdAsc = 'BIDDER_ID_ASC',
  BidderIdDesc = 'BIDDER_ID_DESC',
  BidderSignatureImageNameAsc = 'BIDDER_SIGNATURE_IMAGE_NAME_ASC',
  BidderSignatureImageNameDesc = 'BIDDER_SIGNATURE_IMAGE_NAME_DESC',
  BidderSignatureSignedUrlAsc = 'BIDDER_SIGNATURE_SIGNED_URL_ASC',
  BidderSignatureSignedUrlDesc = 'BIDDER_SIGNATURE_SIGNED_URL_DESC',
  BidderSignatureExpiredAtAsc = 'BIDDER_SIGNATURE_EXPIRED_AT_ASC',
  BidderSignatureExpiredAtDesc = 'BIDDER_SIGNATURE_EXPIRED_AT_DESC',
  BidderCreditsUsedAsc = 'BIDDER_CREDITS_USED_ASC',
  BidderCreditsUsedDesc = 'BIDDER_CREDITS_USED_DESC',
  PaymentCreditsUsedAsc = 'PAYMENT_CREDITS_USED_ASC',
  PaymentCreditsUsedDesc = 'PAYMENT_CREDITS_USED_DESC',
  AlternateCreditsUsedAsc = 'ALTERNATE_CREDITS_USED_ASC',
  AlternateCreditsUsedDesc = 'ALTERNATE_CREDITS_USED_DESC',
  CanProceedPaymentAsc = 'CAN_PROCEED_PAYMENT_ASC',
  CanProceedPaymentDesc = 'CAN_PROCEED_PAYMENT_DESC',
  IsProcessingPaymentAsc = 'IS_PROCESSING_PAYMENT_ASC',
  IsProcessingPaymentDesc = 'IS_PROCESSING_PAYMENT_DESC',
  IsInitialPaymentProcessedAsc = 'IS_INITIAL_PAYMENT_PROCESSED_ASC',
  IsInitialPaymentProcessedDesc = 'IS_INITIAL_PAYMENT_PROCESSED_DESC',
  ProcessPaymentAsc = 'PROCESS_PAYMENT_ASC',
  ProcessPaymentDesc = 'PROCESS_PAYMENT_DESC',
  ProcessPaymentReasonAsc = 'PROCESS_PAYMENT_REASON_ASC',
  ProcessPaymentReasonDesc = 'PROCESS_PAYMENT_REASON_DESC',
  ProcessPaymentChangedByAsc = 'PROCESS_PAYMENT_CHANGED_BY_ASC',
  ProcessPaymentChangedByDesc = 'PROCESS_PAYMENT_CHANGED_BY_DESC',
  ProcessPaymentChangedAtAsc = 'PROCESS_PAYMENT_CHANGED_AT_ASC',
  ProcessPaymentChangedAtDesc = 'PROCESS_PAYMENT_CHANGED_AT_DESC',
  AlternatePaymentNoteAsc = 'ALTERNATE_PAYMENT_NOTE_ASC',
  AlternatePaymentNoteDesc = 'ALTERNATE_PAYMENT_NOTE_DESC',
  AlternatePaymentCreatedByAsc = 'ALTERNATE_PAYMENT_CREATED_BY_ASC',
  AlternatePaymentCreatedByDesc = 'ALTERNATE_PAYMENT_CREATED_BY_DESC',
  AlternatePaymentCreatedAtAsc = 'ALTERNATE_PAYMENT_CREATED_AT_ASC',
  AlternatePaymentCreatedAtDesc = 'ALTERNATE_PAYMENT_CREATED_AT_DESC',
  LastPickupDateAsc = 'LAST_PICKUP_DATE_ASC',
  LastPickupDateDesc = 'LAST_PICKUP_DATE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  LpudChangedTypeAsc = 'LPUD_CHANGED_TYPE_ASC',
  LpudChangedTypeDesc = 'LPUD_CHANGED_TYPE_DESC',
  CanDisplayToCustomerAsc = 'CAN_DISPLAY_TO_CUSTOMER_ASC',
  CanDisplayToCustomerDesc = 'CAN_DISPLAY_TO_CUSTOMER_DESC',
  ElevatedTokenAsc = 'ELEVATED_TOKEN_ASC',
  ElevatedTokenDesc = 'ELEVATED_TOKEN_DESC',
  ExtTypeAsc = 'EXT_TYPE_ASC',
  ExtTypeDesc = 'EXT_TYPE_DESC',
  MerIdAsc = 'MER_ID_ASC',
  MerIdDesc = 'MER_ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  LastPutOnHoldEventAtAsc = 'LAST_PUT_ON_HOLD_EVENT_AT_ASC',
  LastPutOnHoldEventAtDesc = 'LAST_PUT_ON_HOLD_EVENT_AT_DESC',
  AmountDueAsc = 'AMOUNT_DUE_ASC',
  AmountDueDesc = 'AMOUNT_DUE_DESC',
  CountTotalReturnsAsc = 'COUNT_TOTAL_RETURNS_ASC',
  CountTotalReturnsDesc = 'COUNT_TOTAL_RETURNS_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type InvoicesSearch = Node & {
  __typename?: 'InvoicesSearch';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  invoiceId: Scalars['Int'];
  bidderId: Scalars['Int'];
  auctionId: Scalars['Int'];
  fullText?: Maybe<Scalars['FullText']>;
  /** Reads a single `Invoice` that is related to this `InvoicesSearch`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Bidder` that is related to this `InvoicesSearch`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `Auction` that is related to this `InvoicesSearch`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Full-text search ranking when filtered by `fullText`. */
  fullTextRank?: Maybe<Scalars['Float']>;
};

/**
 * A condition to be used against `InvoicesSearch` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type InvoicesSearchCondition = {
  /** Checks for equality with the object’s `invoiceId` field. */
  invoiceId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bidderId` field. */
  bidderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `auctionId` field. */
  auctionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `fullText` field. */
  fullText?: Maybe<Scalars['FullText']>;
};

/** A filter to be used against `InvoicesSearch` object types. All fields are combined with a logical ‘and.’ */
export type InvoicesSearchFilter = {
  /** Filter by the object’s `invoiceId` field. */
  invoiceId?: Maybe<IntFilter>;
  /** Filter by the object’s `bidderId` field. */
  bidderId?: Maybe<IntFilter>;
  /** Filter by the object’s `auctionId` field. */
  auctionId?: Maybe<IntFilter>;
  /** Filter by the object’s `fullText` field. */
  fullText?: Maybe<FullTextFilter>;
  /** Filter by the object’s `invoiceByInvoiceId` relation. */
  invoiceByInvoiceId?: Maybe<InvoiceFilter>;
  /** Filter by the object’s `bidderByBidderId` relation. */
  bidderByBidderId?: Maybe<BidderFilter>;
  /** Filter by the object’s `auctionByAuctionId` relation. */
  auctionByAuctionId?: Maybe<AuctionFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<InvoicesSearchFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<InvoicesSearchFilter>>;
  /** Negates the expression. */
  not?: Maybe<InvoicesSearchFilter>;
};

/** A connection to a list of `InvoicesSearch` values. */
export type InvoicesSearchesConnection = {
  __typename?: 'InvoicesSearchesConnection';
  /** A list of `InvoicesSearch` objects. */
  nodes: Array<InvoicesSearch>;
  /** A list of edges which contains the `InvoicesSearch` and cursor to aid in pagination. */
  edges: Array<InvoicesSearchesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvoicesSearch` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InvoicesSearch` edge in the connection. */
export type InvoicesSearchesEdge = {
  __typename?: 'InvoicesSearchesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InvoicesSearch` at the end of the edge. */
  node: InvoicesSearch;
};

/** Methods to use when ordering `InvoicesSearch`. */
export enum InvoicesSearchesOrderBy {
  Natural = 'NATURAL',
  InvoiceIdAsc = 'INVOICE_ID_ASC',
  InvoiceIdDesc = 'INVOICE_ID_DESC',
  BidderIdAsc = 'BIDDER_ID_ASC',
  BidderIdDesc = 'BIDDER_ID_DESC',
  AuctionIdAsc = 'AUCTION_ID_ASC',
  AuctionIdDesc = 'AUCTION_ID_DESC',
  FullTextAsc = 'FULL_TEXT_ASC',
  FullTextDesc = 'FULL_TEXT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC',
  FullTextRankAsc = 'FULL_TEXT_RANK_ASC',
  FullTextRankDesc = 'FULL_TEXT_RANK_DESC'
}

/** All input for the `issueReturnForItem` mutation. */
export type IssueReturnForItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  creditAmount: Scalars['Int'];
  paymentAmount: Scalars['Int'];
  alternatePaymentAmount: Scalars['Int'];
  optionId: Scalars['UUID'];
  sendEmail: Scalars['Boolean'];
  note?: Maybe<Scalars['String']>;
  queueId?: Maybe<Scalars['UUID']>;
};

/** The output of our `issueReturnForItem` mutation. */
export type IssueReturnForItemPayload = {
  __typename?: 'IssueReturnForItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceItemReturn?: Maybe<InvoiceItemReturn>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `InvoiceItemReturn`. */
  itemByInvoiceItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `InvoiceItemReturn`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `InvoiceItemReturn`. */
  userByUpdatedBy?: Maybe<User>;
  /** Reads a single `ReturnOption` that is related to this `InvoiceItemReturn`. */
  returnOptionByOptionId?: Maybe<ReturnOption>;
  /** Reads a single `ReturnQueue` that is related to this `InvoiceItemReturn`. */
  returnQueueByQueueId?: Maybe<ReturnQueue>;
  /** An edge for our `InvoiceItemReturn`. May be used by Relay 1. */
  invoiceItemReturnEdge?: Maybe<InvoiceItemReturnsEdge>;
};


/** The output of our `issueReturnForItem` mutation. */
export type IssueReturnForItemPayloadInvoiceItemReturnEdgeArgs = {
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
};

export type Item = Node & {
  __typename?: 'Item';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  invId?: Maybe<Scalars['Int']>;
  bwId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  auctionId: Scalars['Int'];
  invoiceId?: Maybe<Scalars['Int']>;
  paymentId?: Maybe<Scalars['Int']>;
  bidderId?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['String']>;
  invDateCreated?: Maybe<Scalars['Datetime']>;
  invDateScanned?: Maybe<Scalars['Datetime']>;
  invCreatedByUserId?: Maybe<Scalars['Int']>;
  invCreatedByUserName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  retailPrice: Scalars['Int'];
  category?: Maybe<Scalars['String']>;
  olasku?: Maybe<Scalars['String']>;
  bwSoldState?: Maybe<Scalars['String']>;
  bwWonBidderId?: Maybe<Scalars['Int']>;
  bwWonAmount?: Maybe<Scalars['Int']>;
  bwWonAt?: Maybe<Scalars['Datetime']>;
  totalBids?: Maybe<Scalars['Int']>;
  images: Array<Maybe<Scalars['String']>>;
  rawImages: Array<Maybe<Scalars['String']>>;
  primaryImages: Array<Maybe<Scalars['String']>>;
  bidAmount?: Maybe<Scalars['Int']>;
  premiumAmount?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['Int']>;
  taxExemptAmount?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Int']>;
  paidStatus?: Maybe<ItemPaidStatus>;
  relistStatus?: Maybe<ItemRelistStatus>;
  pickupStatus?: Maybe<ItemPickupStatus>;
  returnStatus?: Maybe<ItemReturnStatus>;
  overallPickupStatus?: Maybe<ItemOverallPickupStatus>;
  isTaxExempt: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  isDeletedFromInvoice: Scalars['Boolean'];
  isPushed: Scalars['Boolean'];
  isPushing: Scalars['Boolean'];
  lastPushAt?: Maybe<Scalars['Datetime']>;
  isPulling: Scalars['Boolean'];
  isPulled: Scalars['Boolean'];
  lastPullAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  deletedFromInvoiceReason?: Maybe<Scalars['String']>;
  deletedFromInvoiceBy?: Maybe<Scalars['Int']>;
  deletedFromInvoiceAt?: Maybe<Scalars['Datetime']>;
  setAsideNote?: Maybe<Scalars['String']>;
  setAsideBy?: Maybe<Scalars['Int']>;
  setAsideAt?: Maybe<Scalars['Datetime']>;
  condition?: Maybe<Scalars['String']>;
  externalHyperlink?: Maybe<Scalars['String']>;
  isDeletedAt?: Maybe<Scalars['Datetime']>;
  isDeletedBy?: Maybe<Scalars['Int']>;
  extType: ExtType;
  merId?: Maybe<Scalars['String']>;
  shippingStatus?: Maybe<ItemShippingStatus>;
  isActive: Scalars['Boolean'];
  status: ItemStatus;
  feeAmount: Scalars['Int'];
  shippingAmount: Scalars['Int'];
  /** Reads a single `Auction` that is related to this `Item`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `Item`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Payment` that is related to this `Item`. */
  paymentByPaymentId?: Maybe<Payment>;
  /** Reads a single `Bidder` that is related to this `Item`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Item`. */
  userByDeletedFromInvoiceBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userBySetAsideBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userByIsDeletedBy?: Maybe<User>;
  /** Reads and enables pagination through a set of `ItemImage`. */
  itemImagesByItemId: ItemImagesConnection;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByItemId: BidderCreditHistoriesConnection;
  /** Reads a single `ItemRackLocation` that is related to this `Item`. */
  itemRackLocationByItemId?: Maybe<ItemRackLocation>;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByInvoiceItemId: InvoiceItemReturnsConnection;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByItemId: PickupQueuesConnection;
  /** Reads and enables pagination through a set of `ItemActionHistory`. */
  itemActionHistoriesByItemId: ItemActionHistoriesConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByItemId: ReturnQueuesConnection;
  /** Reads a single `PicklistQueue` that is related to this `Item`. */
  picklistQueueByItemId?: Maybe<PicklistQueue>;
  /** Reads a single `PickupSchedule` that is related to this `Item`. */
  pickupScheduleByItemId?: Maybe<PickupSchedule>;
  /** Reads a single `ShippingQueue` that is related to this `Item`. */
  shippingQueueByItemId?: Maybe<ShippingQueue>;
  /** Number of alternate payment credits (in whole cents) available for refund. */
  alternatePaymentCreditsAvailableForRefund?: Maybe<Scalars['Int']>;
  /** Number of alternate payment credits (in whole cents) that were refunded. */
  alternatePaymentCreditsRefunded?: Maybe<Scalars['Int']>;
  hasPaymentTransactionSettled?: Maybe<Scalars['Boolean']>;
  isPriorityPickup?: Maybe<Scalars['Boolean']>;
  isProcessingPushChange?: Maybe<Scalars['Boolean']>;
  isQueuedPushChange?: Maybe<Scalars['Boolean']>;
  /** Number of payment credits (in whole cents) available for refund. */
  paymentCreditsAvailableForRefund?: Maybe<Scalars['Int']>;
  /** Number of payment credits (in whole cents) that were refunded. */
  paymentCreditsRefunded?: Maybe<Scalars['Int']>;
  previewImage?: Maybe<ItemImage>;
  primaryImagesCount?: Maybe<Scalars['BigFloat']>;
  /** Total amount (in whole cents) that is available for return. */
  returnTotalAmountAvailable?: Maybe<Scalars['Int']>;
  returnTotalPendingStatus?: Maybe<ItemTotalReturnStatus>;
  /** Total status of all returns on this item. */
  returnTotalStatus?: Maybe<ItemTotalReturnStatus>;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByBidderCreditHistoryItemIdAndBidderId: ItemBiddersByBidderCreditHistoryItemIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderCreditHistoryItemIdAndInvoiceId: ItemInvoicesByBidderCreditHistoryItemIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByBidderCreditHistoryItemIdAndAuctionId: ItemAuctionsByBidderCreditHistoryItemIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByBidderCreditHistoryItemIdAndCreatedBy: ItemUsersByBidderCreditHistoryItemIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnInvoiceItemIdAndCreatedBy: ItemUsersByInvoiceItemReturnInvoiceItemIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnInvoiceItemIdAndUpdatedBy: ItemUsersByInvoiceItemReturnInvoiceItemIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  returnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionId: ItemReturnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueId: ItemReturnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupQueueItemIdAndLocationId: ItemLocationsByPickupQueueItemIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueItemIdAndAssignedUserId: ItemUsersByPickupQueueItemIdAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupQueueItemIdAndCustomerId: ItemBiddersByPickupQueueItemIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueItemIdAndCompletedBy: ItemUsersByPickupQueueItemIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueItemIdAndCreatedBy: ItemUsersByPickupQueueItemIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByPickupQueueItemIdAndSessionId: ItemPickupSessionsByPickupQueueItemIdAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemActionHistoryItemIdAndCreatedBy: ItemUsersByItemActionHistoryItemIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  returnOptionsByReturnQueueItemIdAndOptionId: ItemReturnOptionsByReturnQueueItemIdAndOptionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueItemIdAndCreatedBy: ItemUsersByReturnQueueItemIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueItemIdAndCompletedBy: ItemUsersByReturnQueueItemIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueItemIdAndUpdatedBy: ItemUsersByReturnQueueItemIdAndUpdatedByManyToManyConnection;
};


export type ItemItemImagesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemImagesOrderBy>>;
  condition?: Maybe<ItemImageCondition>;
  filter?: Maybe<ItemImageFilter>;
};


export type ItemBidderCreditHistoriesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};


export type ItemInvoiceItemReturnsByInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};


export type ItemPickupQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};


export type ItemItemActionHistoriesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemActionHistoriesOrderBy>>;
  condition?: Maybe<ItemActionHistoryCondition>;
  filter?: Maybe<ItemActionHistoryFilter>;
};


export type ItemReturnQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type ItemBiddersByBidderCreditHistoryItemIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type ItemInvoicesByBidderCreditHistoryItemIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type ItemAuctionsByBidderCreditHistoryItemIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type ItemUsersByBidderCreditHistoryItemIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemUsersByInvoiceItemReturnInvoiceItemIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemUsersByInvoiceItemReturnInvoiceItemIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemReturnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};


export type ItemReturnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type ItemLocationsByPickupQueueItemIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type ItemUsersByPickupQueueItemIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemBiddersByPickupQueueItemIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type ItemUsersByPickupQueueItemIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemUsersByPickupQueueItemIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemPickupSessionsByPickupQueueItemIdAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type ItemUsersByItemActionHistoryItemIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemReturnOptionsByReturnQueueItemIdAndOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};


export type ItemUsersByReturnQueueItemIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemUsersByReturnQueueItemIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ItemUsersByReturnQueueItemIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/** A connection to a list of `ItemActionHistory` values. */
export type ItemActionHistoriesConnection = {
  __typename?: 'ItemActionHistoriesConnection';
  /** A list of `ItemActionHistory` objects. */
  nodes: Array<ItemActionHistory>;
  /** A list of edges which contains the `ItemActionHistory` and cursor to aid in pagination. */
  edges: Array<ItemActionHistoriesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ItemActionHistory` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ItemActionHistory` edge in the connection. */
export type ItemActionHistoriesEdge = {
  __typename?: 'ItemActionHistoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ItemActionHistory` at the end of the edge. */
  node: ItemActionHistory;
};

/** Methods to use when ordering `ItemActionHistory`. */
export enum ItemActionHistoriesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  OldValueAsc = 'OLD_VALUE_ASC',
  OldValueDesc = 'OLD_VALUE_DESC',
  NewValueAsc = 'NEW_VALUE_ASC',
  NewValueDesc = 'NEW_VALUE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ItemActionHistory = Node & {
  __typename?: 'ItemActionHistory';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  itemId?: Maybe<Scalars['Int']>;
  oldValue?: Maybe<Scalars['String']>;
  newValue?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['Int']>;
  /** Reads a single `Item` that is related to this `ItemActionHistory`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `ItemActionHistory`. */
  userByCreatedBy?: Maybe<User>;
};

/**
 * A condition to be used against `ItemActionHistory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ItemActionHistoryCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `oldValue` field. */
  oldValue?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `newValue` field. */
  newValue?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `ItemActionHistory` object types. All fields are combined with a logical ‘and.’ */
export type ItemActionHistoryFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `oldValue` field. */
  oldValue?: Maybe<StringFilter>;
  /** Filter by the object’s `newValue` field. */
  newValue?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** A related `itemByItemId` exists. */
  itemByItemIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ItemActionHistoryFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ItemActionHistoryFilter>>;
  /** Negates the expression. */
  not?: Maybe<ItemActionHistoryFilter>;
};

/** A connection to a list of `Auction` values, with data from `BidderCreditHistory`. */
export type ItemAuctionsByBidderCreditHistoryItemIdAndAuctionIdManyToManyConnection = {
  __typename?: 'ItemAuctionsByBidderCreditHistoryItemIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<ItemAuctionsByBidderCreditHistoryItemIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `BidderCreditHistory`. */
export type ItemAuctionsByBidderCreditHistoryItemIdAndAuctionIdManyToManyEdge = {
  __typename?: 'ItemAuctionsByBidderCreditHistoryItemIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByAuctionId: BidderCreditHistoriesConnection;
};


/** A `Auction` edge in the connection, with data from `BidderCreditHistory`. */
export type ItemAuctionsByBidderCreditHistoryItemIdAndAuctionIdManyToManyEdgeBidderCreditHistoriesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Bidder` values, with data from `BidderCreditHistory`. */
export type ItemBiddersByBidderCreditHistoryItemIdAndBidderIdManyToManyConnection = {
  __typename?: 'ItemBiddersByBidderCreditHistoryItemIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<ItemBiddersByBidderCreditHistoryItemIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `BidderCreditHistory`. */
export type ItemBiddersByBidderCreditHistoryItemIdAndBidderIdManyToManyEdge = {
  __typename?: 'ItemBiddersByBidderCreditHistoryItemIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByBidderId: BidderCreditHistoriesConnection;
};


/** A `Bidder` edge in the connection, with data from `BidderCreditHistory`. */
export type ItemBiddersByBidderCreditHistoryItemIdAndBidderIdManyToManyEdgeBidderCreditHistoriesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupQueue`. */
export type ItemBiddersByPickupQueueItemIdAndCustomerIdManyToManyConnection = {
  __typename?: 'ItemBiddersByPickupQueueItemIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemBiddersByPickupQueueItemIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type ItemBiddersByPickupQueueItemIdAndCustomerIdManyToManyEdge = {
  __typename?: 'ItemBiddersByPickupQueueItemIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCustomerId: PickupQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type ItemBiddersByPickupQueueItemIdAndCustomerIdManyToManyEdgePickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A condition to be used against `Item` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invId` field. */
  invId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bwId` field. */
  bwId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `number` field. */
  number?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `auctionId` field. */
  auctionId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invoiceId` field. */
  invoiceId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bidderId` field. */
  bidderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `truckId` field. */
  truckId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `invDateCreated` field. */
  invDateCreated?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `invDateScanned` field. */
  invDateScanned?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `invCreatedByUserId` field. */
  invCreatedByUserId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invCreatedByUserName` field. */
  invCreatedByUserName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `retailPrice` field. */
  retailPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `category` field. */
  category?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `olasku` field. */
  olasku?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bwSoldState` field. */
  bwSoldState?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bwWonBidderId` field. */
  bwWonBidderId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bwWonAmount` field. */
  bwWonAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bwWonAt` field. */
  bwWonAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `totalBids` field. */
  totalBids?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `images` field. */
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `rawImages` field. */
  rawImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `primaryImages` field. */
  primaryImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Checks for equality with the object’s `bidAmount` field. */
  bidAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `premiumAmount` field. */
  premiumAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `taxAmount` field. */
  taxAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `taxExemptAmount` field. */
  taxExemptAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `totalAmount` field. */
  totalAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paidStatus` field. */
  paidStatus?: Maybe<ItemPaidStatus>;
  /** Checks for equality with the object’s `relistStatus` field. */
  relistStatus?: Maybe<ItemRelistStatus>;
  /** Checks for equality with the object’s `pickupStatus` field. */
  pickupStatus?: Maybe<ItemPickupStatus>;
  /** Checks for equality with the object’s `returnStatus` field. */
  returnStatus?: Maybe<ItemReturnStatus>;
  /** Checks for equality with the object’s `overallPickupStatus` field. */
  overallPickupStatus?: Maybe<ItemOverallPickupStatus>;
  /** Checks for equality with the object’s `isTaxExempt` field. */
  isTaxExempt?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isDeletedFromInvoice` field. */
  isDeletedFromInvoice?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPushed` field. */
  isPushed?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPushing` field. */
  isPushing?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lastPushAt` field. */
  lastPushAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `isPulling` field. */
  isPulling?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isPulled` field. */
  isPulled?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `lastPullAt` field. */
  lastPullAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `deletedFromInvoiceReason` field. */
  deletedFromInvoiceReason?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `deletedFromInvoiceBy` field. */
  deletedFromInvoiceBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `deletedFromInvoiceAt` field. */
  deletedFromInvoiceAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `setAsideNote` field. */
  setAsideNote?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `setAsideBy` field. */
  setAsideBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `setAsideAt` field. */
  setAsideAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `condition` field. */
  condition?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `externalHyperlink` field. */
  externalHyperlink?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isDeletedAt` field. */
  isDeletedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `isDeletedBy` field. */
  isDeletedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `extType` field. */
  extType?: Maybe<ExtType>;
  /** Checks for equality with the object’s `merId` field. */
  merId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shippingStatus` field. */
  shippingStatus?: Maybe<ItemShippingStatus>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<ItemStatus>;
  /** Checks for equality with the object’s `feeAmount` field. */
  feeAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `shippingAmount` field. */
  shippingAmount?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `Item` object types. All fields are combined with a logical ‘and.’ */
export type ItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `invId` field. */
  invId?: Maybe<IntFilter>;
  /** Filter by the object’s `bwId` field. */
  bwId?: Maybe<IntFilter>;
  /** Filter by the object’s `number` field. */
  number?: Maybe<IntFilter>;
  /** Filter by the object’s `auctionId` field. */
  auctionId?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceId` field. */
  invoiceId?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentId` field. */
  paymentId?: Maybe<IntFilter>;
  /** Filter by the object’s `bidderId` field. */
  bidderId?: Maybe<IntFilter>;
  /** Filter by the object’s `truckId` field. */
  truckId?: Maybe<StringFilter>;
  /** Filter by the object’s `invDateCreated` field. */
  invDateCreated?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `invDateScanned` field. */
  invDateScanned?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `invCreatedByUserId` field. */
  invCreatedByUserId?: Maybe<IntFilter>;
  /** Filter by the object’s `invCreatedByUserName` field. */
  invCreatedByUserName?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `retailPrice` field. */
  retailPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `category` field. */
  category?: Maybe<StringFilter>;
  /** Filter by the object’s `olasku` field. */
  olasku?: Maybe<StringFilter>;
  /** Filter by the object’s `bwSoldState` field. */
  bwSoldState?: Maybe<StringFilter>;
  /** Filter by the object’s `bwWonBidderId` field. */
  bwWonBidderId?: Maybe<IntFilter>;
  /** Filter by the object’s `bwWonAmount` field. */
  bwWonAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `bwWonAt` field. */
  bwWonAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `totalBids` field. */
  totalBids?: Maybe<IntFilter>;
  /** Filter by the object’s `images` field. */
  images?: Maybe<StringListFilter>;
  /** Filter by the object’s `rawImages` field. */
  rawImages?: Maybe<StringListFilter>;
  /** Filter by the object’s `primaryImages` field. */
  primaryImages?: Maybe<StringListFilter>;
  /** Filter by the object’s `bidAmount` field. */
  bidAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `premiumAmount` field. */
  premiumAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `taxAmount` field. */
  taxAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `taxExemptAmount` field. */
  taxExemptAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `totalAmount` field. */
  totalAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `paidStatus` field. */
  paidStatus?: Maybe<ItemPaidStatusFilter>;
  /** Filter by the object’s `relistStatus` field. */
  relistStatus?: Maybe<ItemRelistStatusFilter>;
  /** Filter by the object’s `pickupStatus` field. */
  pickupStatus?: Maybe<ItemPickupStatusFilter>;
  /** Filter by the object’s `returnStatus` field. */
  returnStatus?: Maybe<ItemReturnStatusFilter>;
  /** Filter by the object’s `overallPickupStatus` field. */
  overallPickupStatus?: Maybe<ItemOverallPickupStatusFilter>;
  /** Filter by the object’s `isTaxExempt` field. */
  isTaxExempt?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isDeletedFromInvoice` field. */
  isDeletedFromInvoice?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPushed` field. */
  isPushed?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPushing` field. */
  isPushing?: Maybe<BooleanFilter>;
  /** Filter by the object’s `lastPushAt` field. */
  lastPushAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `isPulling` field. */
  isPulling?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPulled` field. */
  isPulled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `lastPullAt` field. */
  lastPullAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `deletedFromInvoiceReason` field. */
  deletedFromInvoiceReason?: Maybe<StringFilter>;
  /** Filter by the object’s `deletedFromInvoiceBy` field. */
  deletedFromInvoiceBy?: Maybe<IntFilter>;
  /** Filter by the object’s `deletedFromInvoiceAt` field. */
  deletedFromInvoiceAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `setAsideNote` field. */
  setAsideNote?: Maybe<StringFilter>;
  /** Filter by the object’s `setAsideBy` field. */
  setAsideBy?: Maybe<IntFilter>;
  /** Filter by the object’s `setAsideAt` field. */
  setAsideAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `condition` field. */
  condition?: Maybe<StringFilter>;
  /** Filter by the object’s `externalHyperlink` field. */
  externalHyperlink?: Maybe<StringFilter>;
  /** Filter by the object’s `isDeletedAt` field. */
  isDeletedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `isDeletedBy` field. */
  isDeletedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `extType` field. */
  extType?: Maybe<ExtTypeFilter>;
  /** Filter by the object’s `merId` field. */
  merId?: Maybe<StringFilter>;
  /** Filter by the object’s `shippingStatus` field. */
  shippingStatus?: Maybe<ItemShippingStatusFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<ItemStatusFilter>;
  /** Filter by the object’s `feeAmount` field. */
  feeAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `shippingAmount` field. */
  shippingAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `alternatePaymentCreditsAvailableForRefund` field. */
  alternatePaymentCreditsAvailableForRefund?: Maybe<IntFilter>;
  /** Filter by the object’s `alternatePaymentCreditsRefunded` field. */
  alternatePaymentCreditsRefunded?: Maybe<IntFilter>;
  /** Filter by the object’s `hasPaymentTransactionSettled` field. */
  hasPaymentTransactionSettled?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isPriorityPickup` field. */
  isPriorityPickup?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isProcessingPushChange` field. */
  isProcessingPushChange?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isQueuedPushChange` field. */
  isQueuedPushChange?: Maybe<BooleanFilter>;
  /** Filter by the object’s `paymentCreditsAvailableForRefund` field. */
  paymentCreditsAvailableForRefund?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentCreditsRefunded` field. */
  paymentCreditsRefunded?: Maybe<IntFilter>;
  /** Filter by the object’s `primaryImagesCount` field. */
  primaryImagesCount?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `returnTotalAmountAvailable` field. */
  returnTotalAmountAvailable?: Maybe<IntFilter>;
  /** Filter by the object’s `returnTotalPendingStatus` field. */
  returnTotalPendingStatus?: Maybe<ItemTotalReturnStatusFilter>;
  /** Filter by the object’s `returnTotalStatus` field. */
  returnTotalStatus?: Maybe<ItemTotalReturnStatusFilter>;
  /** Filter by the object’s `itemImagesByItemId` relation. */
  itemImagesByItemId?: Maybe<ItemToManyItemImageFilter>;
  /** Some related `itemImagesByItemId` exist. */
  itemImagesByItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderCreditHistoriesByItemId` relation. */
  bidderCreditHistoriesByItemId?: Maybe<ItemToManyBidderCreditHistoryFilter>;
  /** Some related `bidderCreditHistoriesByItemId` exist. */
  bidderCreditHistoriesByItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemRackLocationByItemId` relation. */
  itemRackLocationByItemId?: Maybe<ItemRackLocationFilter>;
  /** A related `itemRackLocationByItemId` exists. */
  itemRackLocationByItemIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceItemReturnsByInvoiceItemId` relation. */
  invoiceItemReturnsByInvoiceItemId?: Maybe<ItemToManyInvoiceItemReturnFilter>;
  /** Some related `invoiceItemReturnsByInvoiceItemId` exist. */
  invoiceItemReturnsByInvoiceItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupQueuesByItemId` relation. */
  pickupQueuesByItemId?: Maybe<ItemToManyPickupQueueFilter>;
  /** Some related `pickupQueuesByItemId` exist. */
  pickupQueuesByItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemActionHistoriesByItemId` relation. */
  itemActionHistoriesByItemId?: Maybe<ItemToManyItemActionHistoryFilter>;
  /** Some related `itemActionHistoriesByItemId` exist. */
  itemActionHistoriesByItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `returnQueuesByItemId` relation. */
  returnQueuesByItemId?: Maybe<ItemToManyReturnQueueFilter>;
  /** Some related `returnQueuesByItemId` exist. */
  returnQueuesByItemIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `picklistQueueByItemId` relation. */
  picklistQueueByItemId?: Maybe<PicklistQueueFilter>;
  /** A related `picklistQueueByItemId` exists. */
  picklistQueueByItemIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupScheduleByItemId` relation. */
  pickupScheduleByItemId?: Maybe<PickupScheduleFilter>;
  /** A related `pickupScheduleByItemId` exists. */
  pickupScheduleByItemIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `shippingQueueByItemId` relation. */
  shippingQueueByItemId?: Maybe<ShippingQueueFilter>;
  /** A related `shippingQueueByItemId` exists. */
  shippingQueueByItemIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `auctionByAuctionId` relation. */
  auctionByAuctionId?: Maybe<AuctionFilter>;
  /** Filter by the object’s `invoiceByInvoiceId` relation. */
  invoiceByInvoiceId?: Maybe<InvoiceFilter>;
  /** A related `invoiceByInvoiceId` exists. */
  invoiceByInvoiceIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `paymentByPaymentId` relation. */
  paymentByPaymentId?: Maybe<PaymentFilter>;
  /** A related `paymentByPaymentId` exists. */
  paymentByPaymentIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderByBidderId` relation. */
  bidderByBidderId?: Maybe<BidderFilter>;
  /** A related `bidderByBidderId` exists. */
  bidderByBidderIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByDeletedFromInvoiceBy` relation. */
  userByDeletedFromInvoiceBy?: Maybe<UserFilter>;
  /** A related `userByDeletedFromInvoiceBy` exists. */
  userByDeletedFromInvoiceByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userBySetAsideBy` relation. */
  userBySetAsideBy?: Maybe<UserFilter>;
  /** A related `userBySetAsideBy` exists. */
  userBySetAsideByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByIsDeletedBy` relation. */
  userByIsDeletedBy?: Maybe<UserFilter>;
  /** A related `userByIsDeletedBy` exists. */
  userByIsDeletedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<ItemFilter>;
};

export enum ItemHistoryType {
  General = 'GENERAL',
  RackLocation = 'RACK_LOCATION',
  Status = 'STATUS',
  Bin = 'BIN',
  NotFound = 'NOT_FOUND',
  MercurySentEvent = 'MERCURY_SENT_EVENT',
  MercuryReceivedEvent = 'MERCURY_RECEIVED_EVENT'
}

/** A filter to be used against ItemHistoryType fields. All fields are combined with a logical ‘and.’ */
export type ItemHistoryTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemHistoryType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemHistoryType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemHistoryType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemHistoryType>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemHistoryType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemHistoryType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemHistoryType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemHistoryType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemHistoryType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemHistoryType>;
};

export type ItemImage = Node & {
  __typename?: 'ItemImage';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  itemId: Scalars['Int'];
  imageName: Scalars['String'];
  imageSignedUrl?: Maybe<Scalars['String']>;
  imageExpiredAt?: Maybe<Scalars['Datetime']>;
  sort: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
  isDeleted: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  publicUrl?: Maybe<Scalars['String']>;
  extType: ExtType;
  /** Reads a single `Item` that is related to this `ItemImage`. */
  itemByItemId?: Maybe<Item>;
  imageUrl?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `ItemImage` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ItemImageCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `imageName` field. */
  imageName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `imageSignedUrl` field. */
  imageSignedUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `imageExpiredAt` field. */
  imageExpiredAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `sort` field. */
  sort?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPrimary` field. */
  isPrimary?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `publicUrl` field. */
  publicUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `extType` field. */
  extType?: Maybe<ExtType>;
};

/** A filter to be used against `ItemImage` object types. All fields are combined with a logical ‘and.’ */
export type ItemImageFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `imageName` field. */
  imageName?: Maybe<StringFilter>;
  /** Filter by the object’s `imageSignedUrl` field. */
  imageSignedUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `imageExpiredAt` field. */
  imageExpiredAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `sort` field. */
  sort?: Maybe<IntFilter>;
  /** Filter by the object’s `isPrimary` field. */
  isPrimary?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `publicUrl` field. */
  publicUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `extType` field. */
  extType?: Maybe<ExtTypeFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ItemImageFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ItemImageFilter>>;
  /** Negates the expression. */
  not?: Maybe<ItemImageFilter>;
};

/** Represents an update to a `ItemImage`. Fields that are set will be updated. */
export type ItemImagePatch = {
  isPrimary?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `ItemImage` values. */
export type ItemImagesConnection = {
  __typename?: 'ItemImagesConnection';
  /** A list of `ItemImage` objects. */
  nodes: Array<ItemImage>;
  /** A list of edges which contains the `ItemImage` and cursor to aid in pagination. */
  edges: Array<ItemImagesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ItemImage` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ItemImage` edge in the connection. */
export type ItemImagesEdge = {
  __typename?: 'ItemImagesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ItemImage` at the end of the edge. */
  node: ItemImage;
};

/** Methods to use when ordering `ItemImage`. */
export enum ItemImagesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  ImageNameAsc = 'IMAGE_NAME_ASC',
  ImageNameDesc = 'IMAGE_NAME_DESC',
  ImageSignedUrlAsc = 'IMAGE_SIGNED_URL_ASC',
  ImageSignedUrlDesc = 'IMAGE_SIGNED_URL_DESC',
  ImageExpiredAtAsc = 'IMAGE_EXPIRED_AT_ASC',
  ImageExpiredAtDesc = 'IMAGE_EXPIRED_AT_DESC',
  SortAsc = 'SORT_ASC',
  SortDesc = 'SORT_DESC',
  IsPrimaryAsc = 'IS_PRIMARY_ASC',
  IsPrimaryDesc = 'IS_PRIMARY_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PublicUrlAsc = 'PUBLIC_URL_ASC',
  PublicUrlDesc = 'PUBLIC_URL_DESC',
  ExtTypeAsc = 'EXT_TYPE_ASC',
  ExtTypeDesc = 'EXT_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Invoice` values, with data from `BidderCreditHistory`. */
export type ItemInvoicesByBidderCreditHistoryItemIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'ItemInvoicesByBidderCreditHistoryItemIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<ItemInvoicesByBidderCreditHistoryItemIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `BidderCreditHistory`. */
export type ItemInvoicesByBidderCreditHistoryItemIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'ItemInvoicesByBidderCreditHistoryItemIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByInvoiceId: BidderCreditHistoriesConnection;
};


/** A `Invoice` edge in the connection, with data from `BidderCreditHistory`. */
export type ItemInvoicesByBidderCreditHistoryItemIdAndInvoiceIdManyToManyEdgeBidderCreditHistoriesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupQueue`. */
export type ItemLocationsByPickupQueueItemIdAndLocationIdManyToManyConnection = {
  __typename?: 'ItemLocationsByPickupQueueItemIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemLocationsByPickupQueueItemIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type ItemLocationsByPickupQueueItemIdAndLocationIdManyToManyEdge = {
  __typename?: 'ItemLocationsByPickupQueueItemIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByLocationId: PickupQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type ItemLocationsByPickupQueueItemIdAndLocationIdManyToManyEdgePickupQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

export enum ItemOverallPickupStatus {
  Unpaid = 'UNPAID',
  Paid = 'PAID',
  PickupPending = 'PICKUP_PENDING',
  PickupAssigned = 'PICKUP_ASSIGNED',
  PickupCompleted = 'PICKUP_COMPLETED',
  WaitingForDesk = 'WAITING_FOR_DESK',
  ReturnPending = 'RETURN_PENDING',
  ReturnCompleted = 'RETURN_COMPLETED',
  RelistCompleted = 'RELIST_COMPLETED',
  SetAside = 'SET_ASIDE',
  ShippingQueued = 'SHIPPING_QUEUED',
  ShippingCompleted = 'SHIPPING_COMPLETED'
}

/** A filter to be used against ItemOverallPickupStatus fields. All fields are combined with a logical ‘and.’ */
export type ItemOverallPickupStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemOverallPickupStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemOverallPickupStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemOverallPickupStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemOverallPickupStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemOverallPickupStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemOverallPickupStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemOverallPickupStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemOverallPickupStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemOverallPickupStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemOverallPickupStatus>;
};

export enum ItemPaidStatus {
  Paid = 'PAID',
  Unpaid = 'UNPAID'
}

/** A filter to be used against ItemPaidStatus fields. All fields are combined with a logical ‘and.’ */
export type ItemPaidStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemPaidStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemPaidStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemPaidStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemPaidStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemPaidStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemPaidStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemPaidStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemPaidStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemPaidStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemPaidStatus>;
};

/** Represents an update to a `Item`. Fields that are set will be updated. */
export type ItemPatch = {
  invId?: Maybe<Scalars['Int']>;
  bwId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  auctionId?: Maybe<Scalars['Int']>;
  invoiceId?: Maybe<Scalars['Int']>;
  paymentId?: Maybe<Scalars['Int']>;
  bidderId?: Maybe<Scalars['Int']>;
  truckId?: Maybe<Scalars['String']>;
  invDateCreated?: Maybe<Scalars['Datetime']>;
  invDateScanned?: Maybe<Scalars['Datetime']>;
  invCreatedByUserId?: Maybe<Scalars['Int']>;
  invCreatedByUserName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  retailPrice?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  olasku?: Maybe<Scalars['String']>;
  bwSoldState?: Maybe<Scalars['String']>;
  bwWonBidderId?: Maybe<Scalars['Int']>;
  bwWonAmount?: Maybe<Scalars['Int']>;
  bwWonAt?: Maybe<Scalars['Datetime']>;
  totalBids?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<Scalars['String']>>>;
  rawImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  primaryImages?: Maybe<Array<Maybe<Scalars['String']>>>;
  bidAmount?: Maybe<Scalars['Int']>;
  premiumAmount?: Maybe<Scalars['Int']>;
  taxAmount?: Maybe<Scalars['Int']>;
  taxExemptAmount?: Maybe<Scalars['Int']>;
  totalAmount?: Maybe<Scalars['Int']>;
  paidStatus?: Maybe<ItemPaidStatus>;
  relistStatus?: Maybe<ItemRelistStatus>;
  pickupStatus?: Maybe<ItemPickupStatus>;
  returnStatus?: Maybe<ItemReturnStatus>;
  overallPickupStatus?: Maybe<ItemOverallPickupStatus>;
  isTaxExempt?: Maybe<Scalars['Boolean']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  isDeletedFromInvoice?: Maybe<Scalars['Boolean']>;
  isPushed?: Maybe<Scalars['Boolean']>;
  isPushing?: Maybe<Scalars['Boolean']>;
  lastPushAt?: Maybe<Scalars['Datetime']>;
  isPulling?: Maybe<Scalars['Boolean']>;
  isPulled?: Maybe<Scalars['Boolean']>;
  lastPullAt?: Maybe<Scalars['Datetime']>;
  deletedFromInvoiceReason?: Maybe<Scalars['String']>;
  deletedFromInvoiceBy?: Maybe<Scalars['Int']>;
  deletedFromInvoiceAt?: Maybe<Scalars['Datetime']>;
  setAsideNote?: Maybe<Scalars['String']>;
  setAsideBy?: Maybe<Scalars['Int']>;
  setAsideAt?: Maybe<Scalars['Datetime']>;
  condition?: Maybe<Scalars['String']>;
  externalHyperlink?: Maybe<Scalars['String']>;
};

/** A connection to a list of `PickupSession` values, with data from `PickupQueue`. */
export type ItemPickupSessionsByPickupQueueItemIdAndSessionIdManyToManyConnection = {
  __typename?: 'ItemPickupSessionsByPickupQueueItemIdAndSessionIdManyToManyConnection';
  /** A list of `PickupSession` objects. */
  nodes: Array<PickupSession>;
  /** A list of edges which contains the `PickupSession`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemPickupSessionsByPickupQueueItemIdAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type ItemPickupSessionsByPickupQueueItemIdAndSessionIdManyToManyEdge = {
  __typename?: 'ItemPickupSessionsByPickupQueueItemIdAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupSession` at the end of the edge. */
  node: PickupSession;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesBySessionId: PickupQueuesConnection;
};


/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type ItemPickupSessionsByPickupQueueItemIdAndSessionIdManyToManyEdgePickupQueuesBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

export enum ItemPickupStatus {
  Pending = 'PENDING',
  Assigned = 'ASSIGNED',
  Desk = 'DESK',
  Completed = 'COMPLETED',
  SetAside = 'SET_ASIDE'
}

/** A filter to be used against ItemPickupStatus fields. All fields are combined with a logical ‘and.’ */
export type ItemPickupStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemPickupStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemPickupStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemPickupStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemPickupStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemPickupStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemPickupStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemPickupStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemPickupStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemPickupStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemPickupStatus>;
};

export type ItemRackLocation = Node & {
  __typename?: 'ItemRackLocation';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  itemId: Scalars['Int'];
  rackLocation: Scalars['String'];
  updatedAt: Scalars['Datetime'];
  updatedBy?: Maybe<Scalars['Int']>;
  bin?: Maybe<Scalars['String']>;
  evTg: EventType;
  locationPrefix?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  rowPrefix?: Maybe<Scalars['String']>;
  row?: Maybe<Scalars['Int']>;
  bay?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  levelSuffix?: Maybe<Scalars['String']>;
  isAdvSortable: Scalars['Boolean'];
  /** Reads a single `Item` that is related to this `ItemRackLocation`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `ItemRackLocation`. */
  userByUpdatedBy?: Maybe<User>;
};

/**
 * A condition to be used against `ItemRackLocation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ItemRackLocationCondition = {
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `rackLocation` field. */
  rackLocation?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bin` field. */
  bin?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `evTg` field. */
  evTg?: Maybe<EventType>;
  /** Checks for equality with the object’s `locationPrefix` field. */
  locationPrefix?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `location` field. */
  location?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `rowPrefix` field. */
  rowPrefix?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `row` field. */
  row?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `bay` field. */
  bay?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `level` field. */
  level?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `levelSuffix` field. */
  levelSuffix?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isAdvSortable` field. */
  isAdvSortable?: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against `ItemRackLocation` object types. All fields are combined with a logical ‘and.’ */
export type ItemRackLocationFilter = {
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `rackLocation` field. */
  rackLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `bin` field. */
  bin?: Maybe<StringFilter>;
  /** Filter by the object’s `evTg` field. */
  evTg?: Maybe<EventTypeFilter>;
  /** Filter by the object’s `locationPrefix` field. */
  locationPrefix?: Maybe<StringFilter>;
  /** Filter by the object’s `location` field. */
  location?: Maybe<StringFilter>;
  /** Filter by the object’s `rowPrefix` field. */
  rowPrefix?: Maybe<StringFilter>;
  /** Filter by the object’s `row` field. */
  row?: Maybe<IntFilter>;
  /** Filter by the object’s `bay` field. */
  bay?: Maybe<IntFilter>;
  /** Filter by the object’s `level` field. */
  level?: Maybe<IntFilter>;
  /** Filter by the object’s `levelSuffix` field. */
  levelSuffix?: Maybe<StringFilter>;
  /** Filter by the object’s `isAdvSortable` field. */
  isAdvSortable?: Maybe<BooleanFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Filter by the object’s `userByUpdatedBy` relation. */
  userByUpdatedBy?: Maybe<UserFilter>;
  /** A related `userByUpdatedBy` exists. */
  userByUpdatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ItemRackLocationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ItemRackLocationFilter>>;
  /** Negates the expression. */
  not?: Maybe<ItemRackLocationFilter>;
};

/** A connection to a list of `ItemRackLocation` values. */
export type ItemRackLocationsConnection = {
  __typename?: 'ItemRackLocationsConnection';
  /** A list of `ItemRackLocation` objects. */
  nodes: Array<ItemRackLocation>;
  /** A list of edges which contains the `ItemRackLocation` and cursor to aid in pagination. */
  edges: Array<ItemRackLocationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ItemRackLocation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ItemRackLocation` edge in the connection. */
export type ItemRackLocationsEdge = {
  __typename?: 'ItemRackLocationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ItemRackLocation` at the end of the edge. */
  node: ItemRackLocation;
};

/** Methods to use when ordering `ItemRackLocation`. */
export enum ItemRackLocationsOrderBy {
  Natural = 'NATURAL',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  RackLocationAsc = 'RACK_LOCATION_ASC',
  RackLocationDesc = 'RACK_LOCATION_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  BinAsc = 'BIN_ASC',
  BinDesc = 'BIN_DESC',
  EvTgAsc = 'EV_TG_ASC',
  EvTgDesc = 'EV_TG_DESC',
  LocationPrefixAsc = 'LOCATION_PREFIX_ASC',
  LocationPrefixDesc = 'LOCATION_PREFIX_DESC',
  LocationAsc = 'LOCATION_ASC',
  LocationDesc = 'LOCATION_DESC',
  RowPrefixAsc = 'ROW_PREFIX_ASC',
  RowPrefixDesc = 'ROW_PREFIX_DESC',
  RowAsc = 'ROW_ASC',
  RowDesc = 'ROW_DESC',
  BayAsc = 'BAY_ASC',
  BayDesc = 'BAY_DESC',
  LevelAsc = 'LEVEL_ASC',
  LevelDesc = 'LEVEL_DESC',
  LevelSuffixAsc = 'LEVEL_SUFFIX_ASC',
  LevelSuffixDesc = 'LEVEL_SUFFIX_DESC',
  IsAdvSortableAsc = 'IS_ADV_SORTABLE_ASC',
  IsAdvSortableDesc = 'IS_ADV_SORTABLE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum ItemRelistStatus {
  Completed = 'COMPLETED'
}

/** A filter to be used against ItemRelistStatus fields. All fields are combined with a logical ‘and.’ */
export type ItemRelistStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemRelistStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemRelistStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemRelistStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemRelistStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemRelistStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemRelistStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemRelistStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemRelistStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemRelistStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemRelistStatus>;
};

/** A connection to a list of `ReturnOption` values, with data from `InvoiceItemReturn`. */
export type ItemReturnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionIdManyToManyConnection = {
  __typename?: 'ItemReturnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionIdManyToManyConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ItemReturnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection, with data from `InvoiceItemReturn`. */
export type ItemReturnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionIdManyToManyEdge = {
  __typename?: 'ItemReturnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByOptionId: InvoiceItemReturnsConnection;
};


/** A `ReturnOption` edge in the connection, with data from `InvoiceItemReturn`. */
export type ItemReturnOptionsByInvoiceItemReturnInvoiceItemIdAndOptionIdManyToManyEdgeInvoiceItemReturnsByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `ReturnOption` values, with data from `ReturnQueue`. */
export type ItemReturnOptionsByReturnQueueItemIdAndOptionIdManyToManyConnection = {
  __typename?: 'ItemReturnOptionsByReturnQueueItemIdAndOptionIdManyToManyConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemReturnOptionsByReturnQueueItemIdAndOptionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection, with data from `ReturnQueue`. */
export type ItemReturnOptionsByReturnQueueItemIdAndOptionIdManyToManyEdge = {
  __typename?: 'ItemReturnOptionsByReturnQueueItemIdAndOptionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByOptionId: ReturnQueuesConnection;
};


/** A `ReturnOption` edge in the connection, with data from `ReturnQueue`. */
export type ItemReturnOptionsByReturnQueueItemIdAndOptionIdManyToManyEdgeReturnQueuesByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `ReturnQueue` values, with data from `InvoiceItemReturn`. */
export type ItemReturnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueIdManyToManyConnection = {
  __typename?: 'ItemReturnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueIdManyToManyConnection';
  /** A list of `ReturnQueue` objects. */
  nodes: Array<ReturnQueue>;
  /** A list of edges which contains the `ReturnQueue`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ItemReturnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnQueue` edge in the connection, with data from `InvoiceItemReturn`. */
export type ItemReturnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueIdManyToManyEdge = {
  __typename?: 'ItemReturnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnQueue` at the end of the edge. */
  node: ReturnQueue;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByQueueId: InvoiceItemReturnsConnection;
};


/** A `ReturnQueue` edge in the connection, with data from `InvoiceItemReturn`. */
export type ItemReturnQueuesByInvoiceItemReturnInvoiceItemIdAndQueueIdManyToManyEdgeInvoiceItemReturnsByQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

export enum ItemReturnStatus {
  Pending = 'PENDING',
  Completed = 'COMPLETED'
}

/** A filter to be used against ItemReturnStatus fields. All fields are combined with a logical ‘and.’ */
export type ItemReturnStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemReturnStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemReturnStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemReturnStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemReturnStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemReturnStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemReturnStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemReturnStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemReturnStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemReturnStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemReturnStatus>;
};

export enum ItemShippingStatus {
  Queued = 'QUEUED',
  Completed = 'COMPLETED'
}

/** A filter to be used against ItemShippingStatus fields. All fields are combined with a logical ‘and.’ */
export type ItemShippingStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemShippingStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemShippingStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemShippingStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemShippingStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemShippingStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemShippingStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemShippingStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemShippingStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemShippingStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemShippingStatus>;
};

export enum ItemStatus {
  PaymentUnpaid = 'PAYMENT_UNPAID',
  PaymentPaid = 'PAYMENT_PAID',
  PickupQueued = 'PICKUP_QUEUED',
  PickupAssigned = 'PICKUP_ASSIGNED',
  PickupCompleted = 'PICKUP_COMPLETED',
  PickupDesk = 'PICKUP_DESK',
  PickupSetAside = 'PICKUP_SET_ASIDE',
  PickupExpired = 'PICKUP_EXPIRED',
  ReturnPending = 'RETURN_PENDING',
  ReturnCompletedPartialCredit = 'RETURN_COMPLETED_PARTIAL_CREDIT',
  ReturnCompletedPartialRefund = 'RETURN_COMPLETED_PARTIAL_REFUND',
  ReturnCompletedPartialBoth = 'RETURN_COMPLETED_PARTIAL_BOTH',
  ReturnCompletedFullCredit = 'RETURN_COMPLETED_FULL_CREDIT',
  ReturnCompletedFullRefund = 'RETURN_COMPLETED_FULL_REFUND',
  ReturnCompletedFullBoth = 'RETURN_COMPLETED_FULL_BOTH',
  RelistCompleted = 'RELIST_COMPLETED',
  ShippingQueued = 'SHIPPING_QUEUED',
  ShippingPending = 'SHIPPING_PENDING',
  ShippingCompleted = 'SHIPPING_COMPLETED'
}

/** A filter to be used against ItemStatus fields. All fields are combined with a logical ‘and.’ */
export type ItemStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemStatus>;
};

/** A filter to be used against many `BidderCreditHistory` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManyBidderCreditHistoryFilter = {
  /** Every related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<BidderCreditHistoryFilter>;
  /** Some related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<BidderCreditHistoryFilter>;
  /** No related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<BidderCreditHistoryFilter>;
};

/** A filter to be used against many `InvoiceItemReturn` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManyInvoiceItemReturnFilter = {
  /** Every related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceItemReturnFilter>;
  /** Some related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceItemReturnFilter>;
  /** No related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceItemReturnFilter>;
};

/** A filter to be used against many `ItemActionHistory` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManyItemActionHistoryFilter = {
  /** Every related `ItemActionHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemActionHistoryFilter>;
  /** Some related `ItemActionHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemActionHistoryFilter>;
  /** No related `ItemActionHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemActionHistoryFilter>;
};

/** A filter to be used against many `ItemImage` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManyItemImageFilter = {
  /** Every related `ItemImage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemImageFilter>;
  /** Some related `ItemImage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemImageFilter>;
  /** No related `ItemImage` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemImageFilter>;
};

/** A filter to be used against many `PickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManyPickupQueueFilter = {
  /** Every related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupQueueFilter>;
  /** Some related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupQueueFilter>;
  /** No related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupQueueFilter>;
};

/** A filter to be used against many `ReturnQueue` object types. All fields are combined with a logical ‘and.’ */
export type ItemToManyReturnQueueFilter = {
  /** Every related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ReturnQueueFilter>;
  /** Some related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ReturnQueueFilter>;
  /** No related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ReturnQueueFilter>;
};

export enum ItemTotalReturnStatus {
  None = 'NONE',
  Partial = 'PARTIAL',
  Full = 'FULL'
}

/** A filter to be used against ItemTotalReturnStatus fields. All fields are combined with a logical ‘and.’ */
export type ItemTotalReturnStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ItemTotalReturnStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ItemTotalReturnStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ItemTotalReturnStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ItemTotalReturnStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ItemTotalReturnStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ItemTotalReturnStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ItemTotalReturnStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ItemTotalReturnStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ItemTotalReturnStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ItemTotalReturnStatus>;
};

/** A connection to a list of `User` values, with data from `BidderCreditHistory`. */
export type ItemUsersByBidderCreditHistoryItemIdAndCreatedByManyToManyConnection = {
  __typename?: 'ItemUsersByBidderCreditHistoryItemIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByBidderCreditHistoryItemIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `BidderCreditHistory`. */
export type ItemUsersByBidderCreditHistoryItemIdAndCreatedByManyToManyEdge = {
  __typename?: 'ItemUsersByBidderCreditHistoryItemIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByCreatedBy: BidderCreditHistoriesConnection;
};


/** A `User` edge in the connection, with data from `BidderCreditHistory`. */
export type ItemUsersByBidderCreditHistoryItemIdAndCreatedByManyToManyEdgeBidderCreditHistoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturn`. */
export type ItemUsersByInvoiceItemReturnInvoiceItemIdAndCreatedByManyToManyConnection = {
  __typename?: 'ItemUsersByInvoiceItemReturnInvoiceItemIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByInvoiceItemReturnInvoiceItemIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ItemUsersByInvoiceItemReturnInvoiceItemIdAndCreatedByManyToManyEdge = {
  __typename?: 'ItemUsersByInvoiceItemReturnInvoiceItemIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByCreatedBy: InvoiceItemReturnsConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ItemUsersByInvoiceItemReturnInvoiceItemIdAndCreatedByManyToManyEdgeInvoiceItemReturnsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturn`. */
export type ItemUsersByInvoiceItemReturnInvoiceItemIdAndUpdatedByManyToManyConnection = {
  __typename?: 'ItemUsersByInvoiceItemReturnInvoiceItemIdAndUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByInvoiceItemReturnInvoiceItemIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ItemUsersByInvoiceItemReturnInvoiceItemIdAndUpdatedByManyToManyEdge = {
  __typename?: 'ItemUsersByInvoiceItemReturnInvoiceItemIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByUpdatedBy: InvoiceItemReturnsConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ItemUsersByInvoiceItemReturnInvoiceItemIdAndUpdatedByManyToManyEdgeInvoiceItemReturnsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `User` values, with data from `ItemActionHistory`. */
export type ItemUsersByItemActionHistoryItemIdAndCreatedByManyToManyConnection = {
  __typename?: 'ItemUsersByItemActionHistoryItemIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ItemActionHistory`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByItemActionHistoryItemIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ItemActionHistory`. */
export type ItemUsersByItemActionHistoryItemIdAndCreatedByManyToManyEdge = {
  __typename?: 'ItemUsersByItemActionHistoryItemIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ItemActionHistory`. */
  itemActionHistoriesByCreatedBy: ItemActionHistoriesConnection;
};


/** A `User` edge in the connection, with data from `ItemActionHistory`. */
export type ItemUsersByItemActionHistoryItemIdAndCreatedByManyToManyEdgeItemActionHistoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemActionHistoriesOrderBy>>;
  condition?: Maybe<ItemActionHistoryCondition>;
  filter?: Maybe<ItemActionHistoryFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'ItemUsersByPickupQueueItemIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByPickupQueueItemIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'ItemUsersByPickupQueueItemIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByAssignedUserId: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndAssignedUserIdManyToManyEdgePickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndCompletedByManyToManyConnection = {
  __typename?: 'ItemUsersByPickupQueueItemIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByPickupQueueItemIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndCompletedByManyToManyEdge = {
  __typename?: 'ItemUsersByPickupQueueItemIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCompletedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndCompletedByManyToManyEdgePickupQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndCreatedByManyToManyConnection = {
  __typename?: 'ItemUsersByPickupQueueItemIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByPickupQueueItemIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndCreatedByManyToManyEdge = {
  __typename?: 'ItemUsersByPickupQueueItemIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCreatedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type ItemUsersByPickupQueueItemIdAndCreatedByManyToManyEdgePickupQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndCompletedByManyToManyConnection = {
  __typename?: 'ItemUsersByReturnQueueItemIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByReturnQueueItemIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndCompletedByManyToManyEdge = {
  __typename?: 'ItemUsersByReturnQueueItemIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCompletedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndCompletedByManyToManyEdgeReturnQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndCreatedByManyToManyConnection = {
  __typename?: 'ItemUsersByReturnQueueItemIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByReturnQueueItemIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndCreatedByManyToManyEdge = {
  __typename?: 'ItemUsersByReturnQueueItemIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCreatedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndCreatedByManyToManyEdgeReturnQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndUpdatedByManyToManyConnection = {
  __typename?: 'ItemUsersByReturnQueueItemIdAndUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<ItemUsersByReturnQueueItemIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndUpdatedByManyToManyEdge = {
  __typename?: 'ItemUsersByReturnQueueItemIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByUpdatedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ItemUsersByReturnQueueItemIdAndUpdatedByManyToManyEdgeReturnQueuesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `Item` values. */
export type ItemsConnection = {
  __typename?: 'ItemsConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item` and cursor to aid in pagination. */
  edges: Array<ItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection. */
export type ItemsEdge = {
  __typename?: 'ItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
};

/** Methods to use when ordering `Item`. */
export enum ItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvIdAsc = 'INV_ID_ASC',
  InvIdDesc = 'INV_ID_DESC',
  BwIdAsc = 'BW_ID_ASC',
  BwIdDesc = 'BW_ID_DESC',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  AuctionIdAsc = 'AUCTION_ID_ASC',
  AuctionIdDesc = 'AUCTION_ID_DESC',
  InvoiceIdAsc = 'INVOICE_ID_ASC',
  InvoiceIdDesc = 'INVOICE_ID_DESC',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  BidderIdAsc = 'BIDDER_ID_ASC',
  BidderIdDesc = 'BIDDER_ID_DESC',
  TruckIdAsc = 'TRUCK_ID_ASC',
  TruckIdDesc = 'TRUCK_ID_DESC',
  InvDateCreatedAsc = 'INV_DATE_CREATED_ASC',
  InvDateCreatedDesc = 'INV_DATE_CREATED_DESC',
  InvDateScannedAsc = 'INV_DATE_SCANNED_ASC',
  InvDateScannedDesc = 'INV_DATE_SCANNED_DESC',
  InvCreatedByUserIdAsc = 'INV_CREATED_BY_USER_ID_ASC',
  InvCreatedByUserIdDesc = 'INV_CREATED_BY_USER_ID_DESC',
  InvCreatedByUserNameAsc = 'INV_CREATED_BY_USER_NAME_ASC',
  InvCreatedByUserNameDesc = 'INV_CREATED_BY_USER_NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  RetailPriceAsc = 'RETAIL_PRICE_ASC',
  RetailPriceDesc = 'RETAIL_PRICE_DESC',
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  OlaskuAsc = 'OLASKU_ASC',
  OlaskuDesc = 'OLASKU_DESC',
  BwSoldStateAsc = 'BW_SOLD_STATE_ASC',
  BwSoldStateDesc = 'BW_SOLD_STATE_DESC',
  BwWonBidderIdAsc = 'BW_WON_BIDDER_ID_ASC',
  BwWonBidderIdDesc = 'BW_WON_BIDDER_ID_DESC',
  BwWonAmountAsc = 'BW_WON_AMOUNT_ASC',
  BwWonAmountDesc = 'BW_WON_AMOUNT_DESC',
  BwWonAtAsc = 'BW_WON_AT_ASC',
  BwWonAtDesc = 'BW_WON_AT_DESC',
  TotalBidsAsc = 'TOTAL_BIDS_ASC',
  TotalBidsDesc = 'TOTAL_BIDS_DESC',
  ImagesAsc = 'IMAGES_ASC',
  ImagesDesc = 'IMAGES_DESC',
  RawImagesAsc = 'RAW_IMAGES_ASC',
  RawImagesDesc = 'RAW_IMAGES_DESC',
  PrimaryImagesAsc = 'PRIMARY_IMAGES_ASC',
  PrimaryImagesDesc = 'PRIMARY_IMAGES_DESC',
  BidAmountAsc = 'BID_AMOUNT_ASC',
  BidAmountDesc = 'BID_AMOUNT_DESC',
  PremiumAmountAsc = 'PREMIUM_AMOUNT_ASC',
  PremiumAmountDesc = 'PREMIUM_AMOUNT_DESC',
  TaxAmountAsc = 'TAX_AMOUNT_ASC',
  TaxAmountDesc = 'TAX_AMOUNT_DESC',
  TaxExemptAmountAsc = 'TAX_EXEMPT_AMOUNT_ASC',
  TaxExemptAmountDesc = 'TAX_EXEMPT_AMOUNT_DESC',
  TotalAmountAsc = 'TOTAL_AMOUNT_ASC',
  TotalAmountDesc = 'TOTAL_AMOUNT_DESC',
  PaidStatusAsc = 'PAID_STATUS_ASC',
  PaidStatusDesc = 'PAID_STATUS_DESC',
  RelistStatusAsc = 'RELIST_STATUS_ASC',
  RelistStatusDesc = 'RELIST_STATUS_DESC',
  PickupStatusAsc = 'PICKUP_STATUS_ASC',
  PickupStatusDesc = 'PICKUP_STATUS_DESC',
  ReturnStatusAsc = 'RETURN_STATUS_ASC',
  ReturnStatusDesc = 'RETURN_STATUS_DESC',
  OverallPickupStatusAsc = 'OVERALL_PICKUP_STATUS_ASC',
  OverallPickupStatusDesc = 'OVERALL_PICKUP_STATUS_DESC',
  IsTaxExemptAsc = 'IS_TAX_EXEMPT_ASC',
  IsTaxExemptDesc = 'IS_TAX_EXEMPT_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsDeletedFromInvoiceAsc = 'IS_DELETED_FROM_INVOICE_ASC',
  IsDeletedFromInvoiceDesc = 'IS_DELETED_FROM_INVOICE_DESC',
  IsPushedAsc = 'IS_PUSHED_ASC',
  IsPushedDesc = 'IS_PUSHED_DESC',
  IsPushingAsc = 'IS_PUSHING_ASC',
  IsPushingDesc = 'IS_PUSHING_DESC',
  LastPushAtAsc = 'LAST_PUSH_AT_ASC',
  LastPushAtDesc = 'LAST_PUSH_AT_DESC',
  IsPullingAsc = 'IS_PULLING_ASC',
  IsPullingDesc = 'IS_PULLING_DESC',
  IsPulledAsc = 'IS_PULLED_ASC',
  IsPulledDesc = 'IS_PULLED_DESC',
  LastPullAtAsc = 'LAST_PULL_AT_ASC',
  LastPullAtDesc = 'LAST_PULL_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DeletedFromInvoiceReasonAsc = 'DELETED_FROM_INVOICE_REASON_ASC',
  DeletedFromInvoiceReasonDesc = 'DELETED_FROM_INVOICE_REASON_DESC',
  DeletedFromInvoiceByAsc = 'DELETED_FROM_INVOICE_BY_ASC',
  DeletedFromInvoiceByDesc = 'DELETED_FROM_INVOICE_BY_DESC',
  DeletedFromInvoiceAtAsc = 'DELETED_FROM_INVOICE_AT_ASC',
  DeletedFromInvoiceAtDesc = 'DELETED_FROM_INVOICE_AT_DESC',
  SetAsideNoteAsc = 'SET_ASIDE_NOTE_ASC',
  SetAsideNoteDesc = 'SET_ASIDE_NOTE_DESC',
  SetAsideByAsc = 'SET_ASIDE_BY_ASC',
  SetAsideByDesc = 'SET_ASIDE_BY_DESC',
  SetAsideAtAsc = 'SET_ASIDE_AT_ASC',
  SetAsideAtDesc = 'SET_ASIDE_AT_DESC',
  ConditionAsc = 'CONDITION_ASC',
  ConditionDesc = 'CONDITION_DESC',
  ExternalHyperlinkAsc = 'EXTERNAL_HYPERLINK_ASC',
  ExternalHyperlinkDesc = 'EXTERNAL_HYPERLINK_DESC',
  IsDeletedAtAsc = 'IS_DELETED_AT_ASC',
  IsDeletedAtDesc = 'IS_DELETED_AT_DESC',
  IsDeletedByAsc = 'IS_DELETED_BY_ASC',
  IsDeletedByDesc = 'IS_DELETED_BY_DESC',
  ExtTypeAsc = 'EXT_TYPE_ASC',
  ExtTypeDesc = 'EXT_TYPE_DESC',
  MerIdAsc = 'MER_ID_ASC',
  MerIdDesc = 'MER_ID_DESC',
  ShippingStatusAsc = 'SHIPPING_STATUS_ASC',
  ShippingStatusDesc = 'SHIPPING_STATUS_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  FeeAmountAsc = 'FEE_AMOUNT_ASC',
  FeeAmountDesc = 'FEE_AMOUNT_DESC',
  ShippingAmountAsc = 'SHIPPING_AMOUNT_ASC',
  ShippingAmountDesc = 'SHIPPING_AMOUNT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['JSON']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['JSON']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['JSON']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['JSON']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['JSON']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['JSON']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['JSON']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['JSON']>;
  /** Contains the specified JSON. */
  contains?: Maybe<Scalars['JSON']>;
  /** Contains the specified key. */
  containsKey?: Maybe<Scalars['String']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: Maybe<Array<Scalars['String']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: Maybe<Array<Scalars['String']>>;
  /** Contained by the specified JSON. */
  containedBy?: Maybe<Scalars['JSON']>;
};

export type JobQueue = Node & {
  __typename?: 'JobQueue';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  job: Scalars['String'];
  queueName?: Maybe<Scalars['String']>;
  payload: Scalars['JSON'];
  jobCount?: Maybe<Scalars['Int']>;
  successCount: Scalars['Int'];
  failCount: Scalars['Int'];
  isCompleted: Scalars['Boolean'];
  onCompletedTaskExecution?: Maybe<Scalars['String']>;
  userNotificationId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `UserNotification` that is related to this `JobQueue`. */
  userNotificationByUserNotificationId?: Maybe<UserNotification>;
  /** Reads a single `User` that is related to this `JobQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads and enables pagination through a set of `JobQueueItem`. */
  jobQueueItemsByJobQueueId: JobQueueItemsConnection;
};


export type JobQueueJobQueueItemsByJobQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<JobQueueItemsOrderBy>>;
  condition?: Maybe<JobQueueItemCondition>;
  filter?: Maybe<JobQueueItemFilter>;
};

/**
 * A condition to be used against `JobQueue` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type JobQueueCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `job` field. */
  job?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `queueName` field. */
  queueName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `jobCount` field. */
  jobCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `successCount` field. */
  successCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `failCount` field. */
  failCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `onCompletedTaskExecution` field. */
  onCompletedTaskExecution?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `userNotificationId` field. */
  userNotificationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `JobQueue` object types. All fields are combined with a logical ‘and.’ */
export type JobQueueFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `job` field. */
  job?: Maybe<StringFilter>;
  /** Filter by the object’s `queueName` field. */
  queueName?: Maybe<StringFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: Maybe<JsonFilter>;
  /** Filter by the object’s `jobCount` field. */
  jobCount?: Maybe<IntFilter>;
  /** Filter by the object’s `successCount` field. */
  successCount?: Maybe<IntFilter>;
  /** Filter by the object’s `failCount` field. */
  failCount?: Maybe<IntFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `onCompletedTaskExecution` field. */
  onCompletedTaskExecution?: Maybe<StringFilter>;
  /** Filter by the object’s `userNotificationId` field. */
  userNotificationId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `jobQueueItemsByJobQueueId` relation. */
  jobQueueItemsByJobQueueId?: Maybe<JobQueueToManyJobQueueItemFilter>;
  /** Some related `jobQueueItemsByJobQueueId` exist. */
  jobQueueItemsByJobQueueIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userNotificationByUserNotificationId` relation. */
  userNotificationByUserNotificationId?: Maybe<UserNotificationFilter>;
  /** A related `userNotificationByUserNotificationId` exists. */
  userNotificationByUserNotificationIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<JobQueueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<JobQueueFilter>>;
  /** Negates the expression. */
  not?: Maybe<JobQueueFilter>;
};

export type JobQueueItem = Node & {
  __typename?: 'JobQueueItem';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['BigInt'];
  jobQueueId: Scalars['Int'];
  identifier: Scalars['JSON'];
  payload: Scalars['JSON'];
  isSuccessful?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Datetime'];
  completedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `JobQueue` that is related to this `JobQueueItem`. */
  jobQueueByJobQueueId?: Maybe<JobQueue>;
};

/**
 * A condition to be used against `JobQueueItem` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type JobQueueItemCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['BigInt']>;
  /** Checks for equality with the object’s `jobQueueId` field. */
  jobQueueId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `identifier` field. */
  identifier?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `payload` field. */
  payload?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `isSuccessful` field. */
  isSuccessful?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `JobQueueItem` object types. All fields are combined with a logical ‘and.’ */
export type JobQueueItemFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<BigIntFilter>;
  /** Filter by the object’s `jobQueueId` field. */
  jobQueueId?: Maybe<IntFilter>;
  /** Filter by the object’s `identifier` field. */
  identifier?: Maybe<JsonFilter>;
  /** Filter by the object’s `payload` field. */
  payload?: Maybe<JsonFilter>;
  /** Filter by the object’s `isSuccessful` field. */
  isSuccessful?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `jobQueueByJobQueueId` relation. */
  jobQueueByJobQueueId?: Maybe<JobQueueFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<JobQueueItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<JobQueueItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<JobQueueItemFilter>;
};

/** A connection to a list of `JobQueueItem` values. */
export type JobQueueItemsConnection = {
  __typename?: 'JobQueueItemsConnection';
  /** A list of `JobQueueItem` objects. */
  nodes: Array<JobQueueItem>;
  /** A list of edges which contains the `JobQueueItem` and cursor to aid in pagination. */
  edges: Array<JobQueueItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `JobQueueItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `JobQueueItem` edge in the connection. */
export type JobQueueItemsEdge = {
  __typename?: 'JobQueueItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `JobQueueItem` at the end of the edge. */
  node: JobQueueItem;
};

/** Methods to use when ordering `JobQueueItem`. */
export enum JobQueueItemsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  JobQueueIdAsc = 'JOB_QUEUE_ID_ASC',
  JobQueueIdDesc = 'JOB_QUEUE_ID_DESC',
  IdentifierAsc = 'IDENTIFIER_ASC',
  IdentifierDesc = 'IDENTIFIER_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  IsSuccessfulAsc = 'IS_SUCCESSFUL_ASC',
  IsSuccessfulDesc = 'IS_SUCCESSFUL_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A filter to be used against many `JobQueueItem` object types. All fields are combined with a logical ‘and.’ */
export type JobQueueToManyJobQueueItemFilter = {
  /** Every related `JobQueueItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<JobQueueItemFilter>;
  /** Some related `JobQueueItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<JobQueueItemFilter>;
  /** No related `JobQueueItem` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<JobQueueItemFilter>;
};

/** A connection to a list of `JobQueue` values. */
export type JobQueuesConnection = {
  __typename?: 'JobQueuesConnection';
  /** A list of `JobQueue` objects. */
  nodes: Array<JobQueue>;
  /** A list of edges which contains the `JobQueue` and cursor to aid in pagination. */
  edges: Array<JobQueuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `JobQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `JobQueue` edge in the connection. */
export type JobQueuesEdge = {
  __typename?: 'JobQueuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `JobQueue` at the end of the edge. */
  node: JobQueue;
};

/** Methods to use when ordering `JobQueue`. */
export enum JobQueuesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  JobAsc = 'JOB_ASC',
  JobDesc = 'JOB_DESC',
  QueueNameAsc = 'QUEUE_NAME_ASC',
  QueueNameDesc = 'QUEUE_NAME_DESC',
  PayloadAsc = 'PAYLOAD_ASC',
  PayloadDesc = 'PAYLOAD_DESC',
  JobCountAsc = 'JOB_COUNT_ASC',
  JobCountDesc = 'JOB_COUNT_DESC',
  SuccessCountAsc = 'SUCCESS_COUNT_ASC',
  SuccessCountDesc = 'SUCCESS_COUNT_DESC',
  FailCountAsc = 'FAIL_COUNT_ASC',
  FailCountDesc = 'FAIL_COUNT_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  OnCompletedTaskExecutionAsc = 'ON_COMPLETED_TASK_EXECUTION_ASC',
  OnCompletedTaskExecutionDesc = 'ON_COMPLETED_TASK_EXECUTION_DESC',
  UserNotificationIdAsc = 'USER_NOTIFICATION_ID_ASC',
  UserNotificationIdDesc = 'USER_NOTIFICATION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ListenPayload = {
  __typename?: 'ListenPayload';
  /** Our root query field type. Allows us to run any query from our subscription payload. */
  query?: Maybe<Query>;
  relatedNode?: Maybe<Node>;
  relatedNodeId?: Maybe<Scalars['ID']>;
};

export type LivePickupQueue = {
  __typename?: 'LivePickupQueue';
  locationId?: Maybe<Scalars['Int']>;
  assignedUserId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  isPriority?: Maybe<Scalars['Boolean']>;
  /** Reads a single `User` that is related to this `LivePickupQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `Bidder` that is related to this `LivePickupQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
};

/**
 * A condition to be used against `LivePickupQueue` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type LivePickupQueueCondition = {
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isPriority` field. */
  isPriority?: Maybe<Scalars['Boolean']>;
};

/** A filter to be used against `LivePickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type LivePickupQueueFilter = {
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `isPriority` field. */
  isPriority?: Maybe<BooleanFilter>;
  /** Filter by the object’s `userByAssignedUserId` relation. */
  userByAssignedUserId?: Maybe<UserFilter>;
  /** A related `userByAssignedUserId` exists. */
  userByAssignedUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** A related `bidderByCustomerId` exists. */
  bidderByCustomerIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LivePickupQueueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LivePickupQueueFilter>>;
  /** Negates the expression. */
  not?: Maybe<LivePickupQueueFilter>;
};

/** A connection to a list of `LivePickupQueue` values. */
export type LivePickupQueuesConnection = {
  __typename?: 'LivePickupQueuesConnection';
  /** A list of `LivePickupQueue` objects. */
  nodes: Array<LivePickupQueue>;
  /** A list of edges which contains the `LivePickupQueue` and cursor to aid in pagination. */
  edges: Array<LivePickupQueuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `LivePickupQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `LivePickupQueue` edge in the connection. */
export type LivePickupQueuesEdge = {
  __typename?: 'LivePickupQueuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `LivePickupQueue` at the end of the edge. */
  node: LivePickupQueue;
};

/** Methods to use when ordering `LivePickupQueue`. */
export enum LivePickupQueuesOrderBy {
  Natural = 'NATURAL',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  AssignedUserIdAsc = 'ASSIGNED_USER_ID_ASC',
  AssignedUserIdDesc = 'ASSIGNED_USER_ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  IsPriorityAsc = 'IS_PRIORITY_ASC',
  IsPriorityDesc = 'IS_PRIORITY_DESC'
}

export type Location = Node & {
  __typename?: 'Location';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  invoicePrefix?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  addressMain: Scalars['String'];
  addressSecondary: Scalars['String'];
  lat: Scalars['String'];
  long: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  zipCode: Scalars['String'];
  isDeleted: Scalars['Boolean'];
  tax: Scalars['BigFloat'];
  premium: Scalars['BigFloat'];
  timezone: Scalars['String'];
  shortDescription: Scalars['String'];
  fullDescription: Scalars['String'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  lpudMon: Scalars['Int'];
  lpudTue: Scalars['Int'];
  lpudWed: Scalars['Int'];
  lpudThu: Scalars['Int'];
  lpudFri: Scalars['Int'];
  lpudSat: Scalars['Int'];
  lpudSun: Scalars['Int'];
  merMapWarehouse: Scalars['String'];
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByLocationId: AuctionsConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByLocationId: InvoicesConnection;
  /** Reads and enables pagination through a set of `Printer`. */
  printersByLocationId: PrintersConnection;
  /** Reads and enables pagination through a set of `UserLocation`. */
  userLocationsByLocationId: UserLocationsConnection;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByLocationId: PickupQueuesConnection;
  /** Reads and enables pagination through a set of `Truckload`. */
  truckloadsByReceivingLocation: TruckloadsConnection;
  /** Reads and enables pagination through a set of `PickupQueuePriority`. */
  pickupQueuePrioritiesByLocationId: PickupQueuePrioritiesConnection;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByLocationId: PicklistQueuesConnection;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByLocationId: ShippingQueuesConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByLocationId: PickupSessionsConnection;
  /** Reads and enables pagination through a set of `PickupCustomer`. */
  pickupCustomersByLocationId: PickupCustomersConnection;
  fullAddress?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByInvoiceLocationIdAndAuctionId: LocationAuctionsByInvoiceLocationIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByInvoiceLocationIdAndBidderId: LocationBiddersByInvoiceLocationIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceLocationIdAndProcessPaymentChangedBy: LocationUsersByInvoiceLocationIdAndProcessPaymentChangedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceLocationIdAndAlternatePaymentCreatedBy: LocationUsersByInvoiceLocationIdAndAlternatePaymentCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPickupQueueLocationIdAndItemId: LocationItemsByPickupQueueLocationIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueLocationIdAndAssignedUserId: LocationUsersByPickupQueueLocationIdAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupQueueLocationIdAndCustomerId: LocationBiddersByPickupQueueLocationIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueLocationIdAndCompletedBy: LocationUsersByPickupQueueLocationIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueLocationIdAndCreatedBy: LocationUsersByPickupQueueLocationIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByPickupQueueLocationIdAndSessionId: LocationPickupSessionsByPickupQueueLocationIdAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByTruckloadReceivingLocationAndCreatedBy: LocationUsersByTruckloadReceivingLocationAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupQueuePriorityLocationIdAndCustomerId: LocationBiddersByPickupQueuePriorityLocationIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPicklistQueueLocationIdAndCustomerId: LocationBiddersByPicklistQueueLocationIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueLocationIdAndAssignedUserId: LocationUsersByPicklistQueueLocationIdAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueLocationIdAndCreatedBy: LocationUsersByPicklistQueueLocationIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueLocationIdAndCompletedBy: LocationUsersByPicklistQueueLocationIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueLocationIdAndStepUpdatedBy: LocationUsersByPicklistQueueLocationIdAndStepUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByShippingQueueLocationIdAndCustomerId: LocationBiddersByShippingQueueLocationIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByShippingQueueLocationIdAndAssignedUserId: LocationUsersByShippingQueueLocationIdAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByShippingQueueLocationIdAndCompletedBy: LocationUsersByShippingQueueLocationIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupSessionLocationIdAndCustomerId: LocationBiddersByPickupSessionLocationIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupSessionLocationIdAndUserId: LocationUsersByPickupSessionLocationIdAndUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupCustomerLocationIdAndCustomerId: LocationBiddersByPickupCustomerLocationIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupCustomerLocationIdAndUserId: LocationUsersByPickupCustomerLocationIdAndUserIdManyToManyConnection;
};


export type LocationAuctionsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type LocationInvoicesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type LocationPrintersByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PrintersOrderBy>>;
  condition?: Maybe<PrinterCondition>;
  filter?: Maybe<PrinterFilter>;
};


export type LocationUserLocationsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserLocationsOrderBy>>;
  condition?: Maybe<UserLocationCondition>;
  filter?: Maybe<UserLocationFilter>;
};


export type LocationPickupQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};


export type LocationTruckloadsByReceivingLocationArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TruckloadsOrderBy>>;
  condition?: Maybe<TruckloadCondition>;
  filter?: Maybe<TruckloadFilter>;
};


export type LocationPickupQueuePrioritiesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuePrioritiesOrderBy>>;
  condition?: Maybe<PickupQueuePriorityCondition>;
  filter?: Maybe<PickupQueuePriorityFilter>;
};


export type LocationPicklistQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};


export type LocationShippingQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};


export type LocationPickupSessionsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type LocationPickupCustomersByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
  condition?: Maybe<PickupCustomerCondition>;
  filter?: Maybe<PickupCustomerFilter>;
};


export type LocationAuctionsByInvoiceLocationIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type LocationBiddersByInvoiceLocationIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type LocationUsersByInvoiceLocationIdAndProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationUsersByInvoiceLocationIdAndAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationItemsByPickupQueueLocationIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type LocationUsersByPickupQueueLocationIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationBiddersByPickupQueueLocationIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type LocationUsersByPickupQueueLocationIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationUsersByPickupQueueLocationIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationPickupSessionsByPickupQueueLocationIdAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type LocationUsersByTruckloadReceivingLocationAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationBiddersByPickupQueuePriorityLocationIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type LocationBiddersByPicklistQueueLocationIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type LocationUsersByPicklistQueueLocationIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationUsersByPicklistQueueLocationIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationUsersByPicklistQueueLocationIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationUsersByPicklistQueueLocationIdAndStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationBiddersByShippingQueueLocationIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type LocationUsersByShippingQueueLocationIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationUsersByShippingQueueLocationIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationBiddersByPickupSessionLocationIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type LocationUsersByPickupSessionLocationIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type LocationBiddersByPickupCustomerLocationIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type LocationUsersByPickupCustomerLocationIdAndUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/** A connection to a list of `Auction` values, with data from `Invoice`. */
export type LocationAuctionsByInvoiceLocationIdAndAuctionIdManyToManyConnection = {
  __typename?: 'LocationAuctionsByInvoiceLocationIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<LocationAuctionsByInvoiceLocationIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Invoice`. */
export type LocationAuctionsByInvoiceLocationIdAndAuctionIdManyToManyEdge = {
  __typename?: 'LocationAuctionsByInvoiceLocationIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAuctionId: InvoicesConnection;
};


/** A `Auction` edge in the connection, with data from `Invoice`. */
export type LocationAuctionsByInvoiceLocationIdAndAuctionIdManyToManyEdgeInvoicesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Invoice`. */
export type LocationBiddersByInvoiceLocationIdAndBidderIdManyToManyConnection = {
  __typename?: 'LocationBiddersByInvoiceLocationIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<LocationBiddersByInvoiceLocationIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Invoice`. */
export type LocationBiddersByInvoiceLocationIdAndBidderIdManyToManyEdge = {
  __typename?: 'LocationBiddersByInvoiceLocationIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderId: InvoicesConnection;
};


/** A `Bidder` edge in the connection, with data from `Invoice`. */
export type LocationBiddersByInvoiceLocationIdAndBidderIdManyToManyEdgeInvoicesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PicklistQueue`. */
export type LocationBiddersByPicklistQueueLocationIdAndCustomerIdManyToManyConnection = {
  __typename?: 'LocationBiddersByPicklistQueueLocationIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationBiddersByPicklistQueueLocationIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type LocationBiddersByPicklistQueueLocationIdAndCustomerIdManyToManyEdge = {
  __typename?: 'LocationBiddersByPicklistQueueLocationIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCustomerId: PicklistQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type LocationBiddersByPicklistQueueLocationIdAndCustomerIdManyToManyEdgePicklistQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupCustomer`. */
export type LocationBiddersByPickupCustomerLocationIdAndCustomerIdManyToManyConnection = {
  __typename?: 'LocationBiddersByPickupCustomerLocationIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupCustomer`, and the cursor to aid in pagination. */
  edges: Array<LocationBiddersByPickupCustomerLocationIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupCustomer`. */
export type LocationBiddersByPickupCustomerLocationIdAndCustomerIdManyToManyEdge = {
  __typename?: 'LocationBiddersByPickupCustomerLocationIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  id: Scalars['UUID'];
  userId?: Maybe<Scalars['Int']>;
  number: Scalars['Int'];
  customerNotAvailableAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  source: Scalars['String'];
};

/** A connection to a list of `Bidder` values, with data from `PickupQueue`. */
export type LocationBiddersByPickupQueueLocationIdAndCustomerIdManyToManyConnection = {
  __typename?: 'LocationBiddersByPickupQueueLocationIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationBiddersByPickupQueueLocationIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type LocationBiddersByPickupQueueLocationIdAndCustomerIdManyToManyEdge = {
  __typename?: 'LocationBiddersByPickupQueueLocationIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCustomerId: PickupQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type LocationBiddersByPickupQueueLocationIdAndCustomerIdManyToManyEdgePickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupQueuePriority`. */
export type LocationBiddersByPickupQueuePriorityLocationIdAndCustomerIdManyToManyConnection = {
  __typename?: 'LocationBiddersByPickupQueuePriorityLocationIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupQueuePriority`, and the cursor to aid in pagination. */
  edges: Array<LocationBiddersByPickupQueuePriorityLocationIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupQueuePriority`. */
export type LocationBiddersByPickupQueuePriorityLocationIdAndCustomerIdManyToManyEdge = {
  __typename?: 'LocationBiddersByPickupQueuePriorityLocationIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  priorityAt: Scalars['Datetime'];
};

/** A connection to a list of `Bidder` values, with data from `PickupSession`. */
export type LocationBiddersByPickupSessionLocationIdAndCustomerIdManyToManyConnection = {
  __typename?: 'LocationBiddersByPickupSessionLocationIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupSession`, and the cursor to aid in pagination. */
  edges: Array<LocationBiddersByPickupSessionLocationIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupSession`. */
export type LocationBiddersByPickupSessionLocationIdAndCustomerIdManyToManyEdge = {
  __typename?: 'LocationBiddersByPickupSessionLocationIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByCustomerId: PickupSessionsConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupSession`. */
export type LocationBiddersByPickupSessionLocationIdAndCustomerIdManyToManyEdgePickupSessionsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};

/** A connection to a list of `Bidder` values, with data from `ShippingQueue`. */
export type LocationBiddersByShippingQueueLocationIdAndCustomerIdManyToManyConnection = {
  __typename?: 'LocationBiddersByShippingQueueLocationIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationBiddersByShippingQueueLocationIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `ShippingQueue`. */
export type LocationBiddersByShippingQueueLocationIdAndCustomerIdManyToManyEdge = {
  __typename?: 'LocationBiddersByShippingQueueLocationIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByCustomerId: ShippingQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `ShippingQueue`. */
export type LocationBiddersByShippingQueueLocationIdAndCustomerIdManyToManyEdgeShippingQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/**
 * A condition to be used against `Location` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type LocationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invoicePrefix` field. */
  invoicePrefix?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `addressMain` field. */
  addressMain?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `addressSecondary` field. */
  addressSecondary?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lat` field. */
  lat?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `long` field. */
  long?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `city` field. */
  city?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `state` field. */
  state?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `zipCode` field. */
  zipCode?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `tax` field. */
  tax?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `premium` field. */
  premium?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `shortDescription` field. */
  shortDescription?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `fullDescription` field. */
  fullDescription?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `lpudMon` field. */
  lpudMon?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lpudTue` field. */
  lpudTue?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lpudWed` field. */
  lpudWed?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lpudThu` field. */
  lpudThu?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lpudFri` field. */
  lpudFri?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lpudSat` field. */
  lpudSat?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lpudSun` field. */
  lpudSun?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `merMapWarehouse` field. */
  merMapWarehouse?: Maybe<Scalars['String']>;
};

/** A filter to be used against `Location` object types. All fields are combined with a logical ‘and.’ */
export type LocationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `invoicePrefix` field. */
  invoicePrefix?: Maybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `addressMain` field. */
  addressMain?: Maybe<StringFilter>;
  /** Filter by the object’s `addressSecondary` field. */
  addressSecondary?: Maybe<StringFilter>;
  /** Filter by the object’s `lat` field. */
  lat?: Maybe<StringFilter>;
  /** Filter by the object’s `long` field. */
  long?: Maybe<StringFilter>;
  /** Filter by the object’s `city` field. */
  city?: Maybe<StringFilter>;
  /** Filter by the object’s `state` field. */
  state?: Maybe<StringFilter>;
  /** Filter by the object’s `zipCode` field. */
  zipCode?: Maybe<StringFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `tax` field. */
  tax?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `premium` field. */
  premium?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: Maybe<StringFilter>;
  /** Filter by the object’s `shortDescription` field. */
  shortDescription?: Maybe<StringFilter>;
  /** Filter by the object’s `fullDescription` field. */
  fullDescription?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `lpudMon` field. */
  lpudMon?: Maybe<IntFilter>;
  /** Filter by the object’s `lpudTue` field. */
  lpudTue?: Maybe<IntFilter>;
  /** Filter by the object’s `lpudWed` field. */
  lpudWed?: Maybe<IntFilter>;
  /** Filter by the object’s `lpudThu` field. */
  lpudThu?: Maybe<IntFilter>;
  /** Filter by the object’s `lpudFri` field. */
  lpudFri?: Maybe<IntFilter>;
  /** Filter by the object’s `lpudSat` field. */
  lpudSat?: Maybe<IntFilter>;
  /** Filter by the object’s `lpudSun` field. */
  lpudSun?: Maybe<IntFilter>;
  /** Filter by the object’s `merMapWarehouse` field. */
  merMapWarehouse?: Maybe<StringFilter>;
  /** Filter by the object’s `fullAddress` field. */
  fullAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `auctionsByLocationId` relation. */
  auctionsByLocationId?: Maybe<LocationToManyAuctionFilter>;
  /** Some related `auctionsByLocationId` exist. */
  auctionsByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoicesByLocationId` relation. */
  invoicesByLocationId?: Maybe<LocationToManyInvoiceFilter>;
  /** Some related `invoicesByLocationId` exist. */
  invoicesByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `printersByLocationId` relation. */
  printersByLocationId?: Maybe<LocationToManyPrinterFilter>;
  /** Some related `printersByLocationId` exist. */
  printersByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userLocationsByLocationId` relation. */
  userLocationsByLocationId?: Maybe<LocationToManyUserLocationFilter>;
  /** Some related `userLocationsByLocationId` exist. */
  userLocationsByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupQueuesByLocationId` relation. */
  pickupQueuesByLocationId?: Maybe<LocationToManyPickupQueueFilter>;
  /** Some related `pickupQueuesByLocationId` exist. */
  pickupQueuesByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `truckloadsByReceivingLocation` relation. */
  truckloadsByReceivingLocation?: Maybe<LocationToManyTruckloadFilter>;
  /** Some related `truckloadsByReceivingLocation` exist. */
  truckloadsByReceivingLocationExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupQueuePrioritiesByLocationId` relation. */
  pickupQueuePrioritiesByLocationId?: Maybe<LocationToManyPickupQueuePriorityFilter>;
  /** Some related `pickupQueuePrioritiesByLocationId` exist. */
  pickupQueuePrioritiesByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `picklistQueuesByLocationId` relation. */
  picklistQueuesByLocationId?: Maybe<LocationToManyPicklistQueueFilter>;
  /** Some related `picklistQueuesByLocationId` exist. */
  picklistQueuesByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `shippingQueuesByLocationId` relation. */
  shippingQueuesByLocationId?: Maybe<LocationToManyShippingQueueFilter>;
  /** Some related `shippingQueuesByLocationId` exist. */
  shippingQueuesByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupSessionsByLocationId` relation. */
  pickupSessionsByLocationId?: Maybe<LocationToManyPickupSessionFilter>;
  /** Some related `pickupSessionsByLocationId` exist. */
  pickupSessionsByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupCustomersByLocationId` relation. */
  pickupCustomersByLocationId?: Maybe<LocationToManyPickupCustomerFilter>;
  /** Some related `pickupCustomersByLocationId` exist. */
  pickupCustomersByLocationIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<LocationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<LocationFilter>>;
  /** Negates the expression. */
  not?: Maybe<LocationFilter>;
};

/** An input for mutations affecting `Location` */
export type LocationInput = {
  invoicePrefix?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  addressMain?: Maybe<Scalars['String']>;
  addressSecondary?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['BigFloat']>;
  premium?: Maybe<Scalars['BigFloat']>;
  timezone?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  lpudMon?: Maybe<Scalars['Int']>;
  lpudTue?: Maybe<Scalars['Int']>;
  lpudWed?: Maybe<Scalars['Int']>;
  lpudThu?: Maybe<Scalars['Int']>;
  lpudFri?: Maybe<Scalars['Int']>;
  lpudSat?: Maybe<Scalars['Int']>;
  lpudSun?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `Item` values, with data from `PickupQueue`. */
export type LocationItemsByPickupQueueLocationIdAndItemIdManyToManyConnection = {
  __typename?: 'LocationItemsByPickupQueueLocationIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationItemsByPickupQueueLocationIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type LocationItemsByPickupQueueLocationIdAndItemIdManyToManyEdge = {
  __typename?: 'LocationItemsByPickupQueueLocationIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByItemId: PickupQueuesConnection;
};


/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type LocationItemsByPickupQueueLocationIdAndItemIdManyToManyEdgePickupQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** Represents an update to a `Location`. Fields that are set will be updated. */
export type LocationPatch = {
  invoicePrefix?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  addressMain?: Maybe<Scalars['String']>;
  addressSecondary?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['String']>;
  long?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  isDeleted?: Maybe<Scalars['Boolean']>;
  tax?: Maybe<Scalars['BigFloat']>;
  premium?: Maybe<Scalars['BigFloat']>;
  timezone?: Maybe<Scalars['String']>;
  shortDescription?: Maybe<Scalars['String']>;
  fullDescription?: Maybe<Scalars['String']>;
  lpudMon?: Maybe<Scalars['Int']>;
  lpudTue?: Maybe<Scalars['Int']>;
  lpudWed?: Maybe<Scalars['Int']>;
  lpudThu?: Maybe<Scalars['Int']>;
  lpudFri?: Maybe<Scalars['Int']>;
  lpudSat?: Maybe<Scalars['Int']>;
  lpudSun?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `PickupSession` values, with data from `PickupQueue`. */
export type LocationPickupSessionsByPickupQueueLocationIdAndSessionIdManyToManyConnection = {
  __typename?: 'LocationPickupSessionsByPickupQueueLocationIdAndSessionIdManyToManyConnection';
  /** A list of `PickupSession` objects. */
  nodes: Array<PickupSession>;
  /** A list of edges which contains the `PickupSession`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationPickupSessionsByPickupQueueLocationIdAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type LocationPickupSessionsByPickupQueueLocationIdAndSessionIdManyToManyEdge = {
  __typename?: 'LocationPickupSessionsByPickupQueueLocationIdAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupSession` at the end of the edge. */
  node: PickupSession;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesBySessionId: PickupQueuesConnection;
};


/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type LocationPickupSessionsByPickupQueueLocationIdAndSessionIdManyToManyEdgePickupQueuesBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A filter to be used against many `Auction` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyAuctionFilter = {
  /** Every related `Auction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<AuctionFilter>;
  /** Some related `Auction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<AuctionFilter>;
  /** No related `Auction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<AuctionFilter>;
};

/** A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyInvoiceFilter = {
  /** Every related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceFilter>;
  /** Some related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceFilter>;
  /** No related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceFilter>;
};

/** A filter to be used against many `PicklistQueue` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyPicklistQueueFilter = {
  /** Every related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PicklistQueueFilter>;
  /** Some related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PicklistQueueFilter>;
  /** No related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PicklistQueueFilter>;
};

/** A filter to be used against many `PickupCustomer` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyPickupCustomerFilter = {
  /** Every related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupCustomerFilter>;
  /** Some related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupCustomerFilter>;
  /** No related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupCustomerFilter>;
};

/** A filter to be used against many `PickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyPickupQueueFilter = {
  /** Every related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupQueueFilter>;
  /** Some related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupQueueFilter>;
  /** No related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupQueueFilter>;
};

/** A filter to be used against many `PickupQueuePriority` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyPickupQueuePriorityFilter = {
  /** Every related `PickupQueuePriority` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupQueuePriorityFilter>;
  /** Some related `PickupQueuePriority` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupQueuePriorityFilter>;
  /** No related `PickupQueuePriority` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupQueuePriorityFilter>;
};

/** A filter to be used against many `PickupSession` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyPickupSessionFilter = {
  /** Every related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupSessionFilter>;
  /** Some related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupSessionFilter>;
  /** No related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupSessionFilter>;
};

/** A filter to be used against many `Printer` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyPrinterFilter = {
  /** Every related `Printer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PrinterFilter>;
  /** Some related `Printer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PrinterFilter>;
  /** No related `Printer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PrinterFilter>;
};

/** A filter to be used against many `ShippingQueue` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyShippingQueueFilter = {
  /** Every related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ShippingQueueFilter>;
  /** Some related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ShippingQueueFilter>;
  /** No related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ShippingQueueFilter>;
};

/** A filter to be used against many `Truckload` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyTruckloadFilter = {
  /** Every related `Truckload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TruckloadFilter>;
  /** Some related `Truckload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TruckloadFilter>;
  /** No related `Truckload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TruckloadFilter>;
};

/** A filter to be used against many `UserLocation` object types. All fields are combined with a logical ‘and.’ */
export type LocationToManyUserLocationFilter = {
  /** Every related `UserLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<UserLocationFilter>;
  /** Some related `UserLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<UserLocationFilter>;
  /** No related `UserLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<UserLocationFilter>;
};

/** A connection to a list of `User` values, with data from `Invoice`. */
export type LocationUsersByInvoiceLocationIdAndAlternatePaymentCreatedByManyToManyConnection = {
  __typename?: 'LocationUsersByInvoiceLocationIdAndAlternatePaymentCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByInvoiceLocationIdAndAlternatePaymentCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invoice`. */
export type LocationUsersByInvoiceLocationIdAndAlternatePaymentCreatedByManyToManyEdge = {
  __typename?: 'LocationUsersByInvoiceLocationIdAndAlternatePaymentCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAlternatePaymentCreatedBy: InvoicesConnection;
};


/** A `User` edge in the connection, with data from `Invoice`. */
export type LocationUsersByInvoiceLocationIdAndAlternatePaymentCreatedByManyToManyEdgeInvoicesByAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `User` values, with data from `Invoice`. */
export type LocationUsersByInvoiceLocationIdAndProcessPaymentChangedByManyToManyConnection = {
  __typename?: 'LocationUsersByInvoiceLocationIdAndProcessPaymentChangedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByInvoiceLocationIdAndProcessPaymentChangedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invoice`. */
export type LocationUsersByInvoiceLocationIdAndProcessPaymentChangedByManyToManyEdge = {
  __typename?: 'LocationUsersByInvoiceLocationIdAndProcessPaymentChangedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProcessPaymentChangedBy: InvoicesConnection;
};


/** A `User` edge in the connection, with data from `Invoice`. */
export type LocationUsersByInvoiceLocationIdAndProcessPaymentChangedByManyToManyEdgeInvoicesByProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'LocationUsersByPicklistQueueLocationIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPicklistQueueLocationIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'LocationUsersByPicklistQueueLocationIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByAssignedUserId: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndAssignedUserIdManyToManyEdgePicklistQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndCompletedByManyToManyConnection = {
  __typename?: 'LocationUsersByPicklistQueueLocationIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPicklistQueueLocationIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndCompletedByManyToManyEdge = {
  __typename?: 'LocationUsersByPicklistQueueLocationIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCompletedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndCompletedByManyToManyEdgePicklistQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndCreatedByManyToManyConnection = {
  __typename?: 'LocationUsersByPicklistQueueLocationIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPicklistQueueLocationIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndCreatedByManyToManyEdge = {
  __typename?: 'LocationUsersByPicklistQueueLocationIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCreatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndCreatedByManyToManyEdgePicklistQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndStepUpdatedByManyToManyConnection = {
  __typename?: 'LocationUsersByPicklistQueueLocationIdAndStepUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPicklistQueueLocationIdAndStepUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndStepUpdatedByManyToManyEdge = {
  __typename?: 'LocationUsersByPicklistQueueLocationIdAndStepUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByStepUpdatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type LocationUsersByPicklistQueueLocationIdAndStepUpdatedByManyToManyEdgePicklistQueuesByStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupCustomer`. */
export type LocationUsersByPickupCustomerLocationIdAndUserIdManyToManyConnection = {
  __typename?: 'LocationUsersByPickupCustomerLocationIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupCustomer`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPickupCustomerLocationIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupCustomer`. */
export type LocationUsersByPickupCustomerLocationIdAndUserIdManyToManyEdge = {
  __typename?: 'LocationUsersByPickupCustomerLocationIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupCustomer`. */
  pickupCustomersByUserId: PickupCustomersConnection;
};


/** A `User` edge in the connection, with data from `PickupCustomer`. */
export type LocationUsersByPickupCustomerLocationIdAndUserIdManyToManyEdgePickupCustomersByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
  condition?: Maybe<PickupCustomerCondition>;
  filter?: Maybe<PickupCustomerFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'LocationUsersByPickupQueueLocationIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPickupQueueLocationIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'LocationUsersByPickupQueueLocationIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByAssignedUserId: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndAssignedUserIdManyToManyEdgePickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndCompletedByManyToManyConnection = {
  __typename?: 'LocationUsersByPickupQueueLocationIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPickupQueueLocationIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndCompletedByManyToManyEdge = {
  __typename?: 'LocationUsersByPickupQueueLocationIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCompletedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndCompletedByManyToManyEdgePickupQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndCreatedByManyToManyConnection = {
  __typename?: 'LocationUsersByPickupQueueLocationIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPickupQueueLocationIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndCreatedByManyToManyEdge = {
  __typename?: 'LocationUsersByPickupQueueLocationIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCreatedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type LocationUsersByPickupQueueLocationIdAndCreatedByManyToManyEdgePickupQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupSession`. */
export type LocationUsersByPickupSessionLocationIdAndUserIdManyToManyConnection = {
  __typename?: 'LocationUsersByPickupSessionLocationIdAndUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupSession`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByPickupSessionLocationIdAndUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupSession`. */
export type LocationUsersByPickupSessionLocationIdAndUserIdManyToManyEdge = {
  __typename?: 'LocationUsersByPickupSessionLocationIdAndUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByUserId: PickupSessionsConnection;
};


/** A `User` edge in the connection, with data from `PickupSession`. */
export type LocationUsersByPickupSessionLocationIdAndUserIdManyToManyEdgePickupSessionsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};

/** A connection to a list of `User` values, with data from `ShippingQueue`. */
export type LocationUsersByShippingQueueLocationIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'LocationUsersByShippingQueueLocationIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByShippingQueueLocationIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type LocationUsersByShippingQueueLocationIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'LocationUsersByShippingQueueLocationIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByAssignedUserId: ShippingQueuesConnection;
};


/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type LocationUsersByShippingQueueLocationIdAndAssignedUserIdManyToManyEdgeShippingQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ShippingQueue`. */
export type LocationUsersByShippingQueueLocationIdAndCompletedByManyToManyConnection = {
  __typename?: 'LocationUsersByShippingQueueLocationIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByShippingQueueLocationIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type LocationUsersByShippingQueueLocationIdAndCompletedByManyToManyEdge = {
  __typename?: 'LocationUsersByShippingQueueLocationIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByCompletedBy: ShippingQueuesConnection;
};


/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type LocationUsersByShippingQueueLocationIdAndCompletedByManyToManyEdgeShippingQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `User` values, with data from `Truckload`. */
export type LocationUsersByTruckloadReceivingLocationAndCreatedByManyToManyConnection = {
  __typename?: 'LocationUsersByTruckloadReceivingLocationAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Truckload`, and the cursor to aid in pagination. */
  edges: Array<LocationUsersByTruckloadReceivingLocationAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Truckload`. */
export type LocationUsersByTruckloadReceivingLocationAndCreatedByManyToManyEdge = {
  __typename?: 'LocationUsersByTruckloadReceivingLocationAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Truckload`. */
  truckloadsByCreatedBy: TruckloadsConnection;
};


/** A `User` edge in the connection, with data from `Truckload`. */
export type LocationUsersByTruckloadReceivingLocationAndCreatedByManyToManyEdgeTruckloadsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TruckloadsOrderBy>>;
  condition?: Maybe<TruckloadCondition>;
  filter?: Maybe<TruckloadFilter>;
};

/** A connection to a list of `Location` values. */
export type LocationsConnection = {
  __typename?: 'LocationsConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location` and cursor to aid in pagination. */
  edges: Array<LocationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection. */
export type LocationsEdge = {
  __typename?: 'LocationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
};

/** Methods to use when ordering `Location`. */
export enum LocationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvoicePrefixAsc = 'INVOICE_PREFIX_ASC',
  InvoicePrefixDesc = 'INVOICE_PREFIX_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  AddressMainAsc = 'ADDRESS_MAIN_ASC',
  AddressMainDesc = 'ADDRESS_MAIN_DESC',
  AddressSecondaryAsc = 'ADDRESS_SECONDARY_ASC',
  AddressSecondaryDesc = 'ADDRESS_SECONDARY_DESC',
  LatAsc = 'LAT_ASC',
  LatDesc = 'LAT_DESC',
  LongAsc = 'LONG_ASC',
  LongDesc = 'LONG_DESC',
  CityAsc = 'CITY_ASC',
  CityDesc = 'CITY_DESC',
  StateAsc = 'STATE_ASC',
  StateDesc = 'STATE_DESC',
  ZipCodeAsc = 'ZIP_CODE_ASC',
  ZipCodeDesc = 'ZIP_CODE_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  TaxAsc = 'TAX_ASC',
  TaxDesc = 'TAX_DESC',
  PremiumAsc = 'PREMIUM_ASC',
  PremiumDesc = 'PREMIUM_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  ShortDescriptionAsc = 'SHORT_DESCRIPTION_ASC',
  ShortDescriptionDesc = 'SHORT_DESCRIPTION_DESC',
  FullDescriptionAsc = 'FULL_DESCRIPTION_ASC',
  FullDescriptionDesc = 'FULL_DESCRIPTION_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  LpudMonAsc = 'LPUD_MON_ASC',
  LpudMonDesc = 'LPUD_MON_DESC',
  LpudTueAsc = 'LPUD_TUE_ASC',
  LpudTueDesc = 'LPUD_TUE_DESC',
  LpudWedAsc = 'LPUD_WED_ASC',
  LpudWedDesc = 'LPUD_WED_DESC',
  LpudThuAsc = 'LPUD_THU_ASC',
  LpudThuDesc = 'LPUD_THU_DESC',
  LpudFriAsc = 'LPUD_FRI_ASC',
  LpudFriDesc = 'LPUD_FRI_DESC',
  LpudSatAsc = 'LPUD_SAT_ASC',
  LpudSatDesc = 'LPUD_SAT_DESC',
  LpudSunAsc = 'LPUD_SUN_ASC',
  LpudSunDesc = 'LPUD_SUN_DESC',
  MerMapWarehouseAsc = 'MER_MAP_WAREHOUSE_ASC',
  MerMapWarehouseDesc = 'MER_MAP_WAREHOUSE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type LoginByPinCodeInput = {
  pinCode: Scalars['String'];
};

export type LoginByPinCodePayload = {
  __typename?: 'LoginByPinCodePayload';
  credentials: Credentials;
};

export type LoginByRefreshTokenInput = {
  refreshToken: Scalars['String'];
};

export type LoginByRefreshTokenPayload = {
  __typename?: 'LoginByRefreshTokenPayload';
  credentials: Credentials;
};

export type LoginByUsernameAndPasswordInput = {
  username: Scalars['String'];
  password: Scalars['String'];
};

export type LoginByUsernameAndPasswordPayload = {
  __typename?: 'LoginByUsernameAndPasswordPayload';
  credentials: Credentials;
};

export type LogoutPayload = {
  __typename?: 'LogoutPayload';
  success?: Maybe<Scalars['Boolean']>;
};

export enum LpudChangedType {
  Manual = 'MANUAL',
  Auto = 'AUTO'
}

/** A filter to be used against LpudChangedType fields. All fields are combined with a logical ‘and.’ */
export type LpudChangedTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<LpudChangedType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<LpudChangedType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<LpudChangedType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<LpudChangedType>;
  /** Included in the specified list. */
  in?: Maybe<Array<LpudChangedType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<LpudChangedType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<LpudChangedType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<LpudChangedType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<LpudChangedType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<LpudChangedType>;
};

/** All input for the `markInvoiceAsPaid` mutation. */
export type MarkInvoiceAsPaidInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
  note: Scalars['String'];
};

/** The output of our `markInvoiceAsPaid` mutation. */
export type MarkInvoiceAsPaidPayload = {
  __typename?: 'MarkInvoiceAsPaidPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Invoice`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Bidder` that is related to this `Invoice`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Invoice`. */
  userByProcessPaymentChangedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Invoice`. */
  userByAlternatePaymentCreatedBy?: Maybe<User>;
  /** Reads a single `Location` that is related to this `Invoice`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `Invoice`. May be used by Relay 1. */
  invoiceEdge?: Maybe<InvoicesEdge>;
};


/** The output of our `markInvoiceAsPaid` mutation. */
export type MarkInvoiceAsPaidPayloadInvoiceEdgeArgs = {
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
};

export type MissingRackLocationListResult = {
  __typename?: 'MissingRackLocationListResult';
  itemId?: Maybe<Scalars['Int']>;
  itemNumber?: Maybe<Scalars['Int']>;
  auction?: Maybe<Scalars['String']>;
  customer?: Maybe<Scalars['String']>;
  invoiceId?: Maybe<Scalars['Int']>;
  invoiceUid?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
};

/** A filter to be used against `MissingRackLocationListResult` object types. All fields are combined with a logical ‘and.’ */
export type MissingRackLocationListResultFilter = {
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `itemNumber` field. */
  itemNumber?: Maybe<IntFilter>;
  /** Filter by the object’s `auction` field. */
  auction?: Maybe<StringFilter>;
  /** Filter by the object’s `customer` field. */
  customer?: Maybe<StringFilter>;
  /** Filter by the object’s `invoiceId` field. */
  invoiceId?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceUid` field. */
  invoiceUid?: Maybe<StringFilter>;
  /** Filter by the object’s `location` field. */
  location?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MissingRackLocationListResultFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MissingRackLocationListResultFilter>>;
  /** Negates the expression. */
  not?: Maybe<MissingRackLocationListResultFilter>;
};

/** A connection to a list of `MissingRackLocationListResult` values. */
export type MissingRackLocationListResultsConnection = {
  __typename?: 'MissingRackLocationListResultsConnection';
  /** A list of `MissingRackLocationListResult` objects. */
  nodes: Array<MissingRackLocationListResult>;
  /** A list of edges which contains the `MissingRackLocationListResult` and cursor to aid in pagination. */
  edges: Array<MissingRackLocationListResultsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MissingRackLocationListResult` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MissingRackLocationListResult` edge in the connection. */
export type MissingRackLocationListResultsEdge = {
  __typename?: 'MissingRackLocationListResultsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MissingRackLocationListResult` at the end of the edge. */
  node: MissingRackLocationListResult;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `CustomerNote`. */
  createCustomerNote?: Maybe<CreateCustomerNotePayload>;
  /** Creates a single `InvoiceAdjustment`. */
  createInvoiceAdjustment?: Maybe<CreateInvoiceAdjustmentPayload>;
  /** Creates a single `InvoiceItemReturn`. */
  createInvoiceItemReturn?: Maybe<CreateInvoiceItemReturnPayload>;
  /** Creates a single `InvoiceNote`. */
  createInvoiceNote?: Maybe<CreateInvoiceNotePayload>;
  /** Creates a single `Location`. */
  createLocation?: Maybe<CreateLocationPayload>;
  /** Creates a single `PickupSession`. */
  createPickupSession?: Maybe<CreatePickupSessionPayload>;
  /** Creates a single `Printer`. */
  createPrinter?: Maybe<CreatePrinterPayload>;
  /** Creates a single `ReturnOption`. */
  createReturnOption?: Maybe<CreateReturnOptionPayload>;
  /** Creates a single `UserLocation`. */
  createUserLocation?: Maybe<CreateUserLocationPayload>;
  /** Updates a single `Auction` using its globally unique id and a patch. */
  updateAuction?: Maybe<UpdateAuctionPayload>;
  /** Updates a single `Auction` using a unique key and a patch. */
  updateAuctionById?: Maybe<UpdateAuctionPayload>;
  /** Updates a single `Auction` using a unique key and a patch. */
  updateAuctionByInvId?: Maybe<UpdateAuctionPayload>;
  /** Updates a single `Auction` using a unique key and a patch. */
  updateAuctionByBwId?: Maybe<UpdateAuctionPayload>;
  /** Updates a single `Bidder` using its globally unique id and a patch. */
  updateBidder?: Maybe<UpdateBidderPayload>;
  /** Updates a single `Bidder` using a unique key and a patch. */
  updateBidderById?: Maybe<UpdateBidderPayload>;
  /** Updates a single `Bidder` using a unique key and a patch. */
  updateBidderByBwId?: Maybe<UpdateBidderPayload>;
  /** Updates a single `CustomerNote` using its globally unique id and a patch. */
  updateCustomerNote?: Maybe<UpdateCustomerNotePayload>;
  /** Updates a single `CustomerNote` using a unique key and a patch. */
  updateCustomerNoteById?: Maybe<UpdateCustomerNotePayload>;
  /** Updates a single `InvoiceAdjustment` using its globally unique id and a patch. */
  updateInvoiceAdjustment?: Maybe<UpdateInvoiceAdjustmentPayload>;
  /** Updates a single `InvoiceAdjustment` using a unique key and a patch. */
  updateInvoiceAdjustmentById?: Maybe<UpdateInvoiceAdjustmentPayload>;
  /** Updates a single `ItemImage` using its globally unique id and a patch. */
  updateItemImage?: Maybe<UpdateItemImagePayload>;
  /** Updates a single `ItemImage` using a unique key and a patch. */
  updateItemImageById?: Maybe<UpdateItemImagePayload>;
  /** Updates a single `Item` using its globally unique id and a patch. */
  updateItem?: Maybe<UpdateItemPayload>;
  /** Updates a single `Item` using a unique key and a patch. */
  updateItemById?: Maybe<UpdateItemPayload>;
  /** Updates a single `Item` using a unique key and a patch. */
  updateItemByBwId?: Maybe<UpdateItemPayload>;
  /** Updates a single `Location` using its globally unique id and a patch. */
  updateLocation?: Maybe<UpdateLocationPayload>;
  /** Updates a single `Location` using a unique key and a patch. */
  updateLocationById?: Maybe<UpdateLocationPayload>;
  /** Updates a single `Location` using a unique key and a patch. */
  updateLocationByInvoicePrefix?: Maybe<UpdateLocationPayload>;
  /** Updates a single `Location` using a unique key and a patch. */
  updateLocationByName?: Maybe<UpdateLocationPayload>;
  /** Updates a single `PicklistQueue` using its globally unique id and a patch. */
  updatePicklistQueue?: Maybe<UpdatePicklistQueuePayload>;
  /** Updates a single `PicklistQueue` using a unique key and a patch. */
  updatePicklistQueueById?: Maybe<UpdatePicklistQueuePayload>;
  /** Updates a single `PicklistQueue` using a unique key and a patch. */
  updatePicklistQueueByItemId?: Maybe<UpdatePicklistQueuePayload>;
  /** Updates a single `PickupCustomer` using its globally unique id and a patch. */
  updatePickupCustomer?: Maybe<UpdatePickupCustomerPayload>;
  /** Updates a single `PickupCustomer` using a unique key and a patch. */
  updatePickupCustomerById?: Maybe<UpdatePickupCustomerPayload>;
  /** Updates a single `PickupCustomer` using a unique key and a patch. */
  updatePickupCustomerByLocationIdAndCustomerId?: Maybe<UpdatePickupCustomerPayload>;
  /** Updates a single `PickupCustomer` using a unique key and a patch. */
  updatePickupCustomerByLocationIdAndNumber?: Maybe<UpdatePickupCustomerPayload>;
  /** Updates a single `PickupQueue` using its globally unique id and a patch. */
  updatePickupQueue?: Maybe<UpdatePickupQueuePayload>;
  /** Updates a single `PickupQueue` using a unique key and a patch. */
  updatePickupQueueById?: Maybe<UpdatePickupQueuePayload>;
  /** Updates a single `PickupSession` using its globally unique id and a patch. */
  updatePickupSession?: Maybe<UpdatePickupSessionPayload>;
  /** Updates a single `PickupSession` using a unique key and a patch. */
  updatePickupSessionById?: Maybe<UpdatePickupSessionPayload>;
  /** Updates a single `Printer` using its globally unique id and a patch. */
  updatePrinter?: Maybe<UpdatePrinterPayload>;
  /** Updates a single `Printer` using a unique key and a patch. */
  updatePrinterById?: Maybe<UpdatePrinterPayload>;
  /** Updates a single `ReturnOption` using its globally unique id and a patch. */
  updateReturnOption?: Maybe<UpdateReturnOptionPayload>;
  /** Updates a single `ReturnOption` using a unique key and a patch. */
  updateReturnOptionById?: Maybe<UpdateReturnOptionPayload>;
  /** Updates a single `ReturnQueue` using its globally unique id and a patch. */
  updateReturnQueue?: Maybe<UpdateReturnQueuePayload>;
  /** Updates a single `ReturnQueue` using a unique key and a patch. */
  updateReturnQueueById?: Maybe<UpdateReturnQueuePayload>;
  /** Updates a single `ShippingQueue` using its globally unique id and a patch. */
  updateShippingQueue?: Maybe<UpdateShippingQueuePayload>;
  /** Updates a single `ShippingQueue` using a unique key and a patch. */
  updateShippingQueueById?: Maybe<UpdateShippingQueuePayload>;
  /** Updates a single `ShippingQueue` using a unique key and a patch. */
  updateShippingQueueByItemId?: Maybe<UpdateShippingQueuePayload>;
  /** Updates a single `Truckload` using its globally unique id and a patch. */
  updateTruckload?: Maybe<UpdateTruckloadPayload>;
  /** Updates a single `Truckload` using a unique key and a patch. */
  updateTruckloadById?: Maybe<UpdateTruckloadPayload>;
  /** Updates a single `UserLocation` using its globally unique id and a patch. */
  updateUserLocation?: Maybe<UpdateUserLocationPayload>;
  /** Updates a single `UserLocation` using a unique key and a patch. */
  updateUserLocationByUserId?: Maybe<UpdateUserLocationPayload>;
  /** Updates a single `UserNotification` using its globally unique id and a patch. */
  updateUserNotification?: Maybe<UpdateUserNotificationPayload>;
  /** Updates a single `UserNotification` using a unique key and a patch. */
  updateUserNotificationById?: Maybe<UpdateUserNotificationPayload>;
  /** Deletes a single `CustomerNote` using its globally unique id. */
  deleteCustomerNote?: Maybe<DeleteCustomerNotePayload>;
  /** Deletes a single `CustomerNote` using a unique key. */
  deleteCustomerNoteById?: Maybe<DeleteCustomerNotePayload>;
  /** Deletes a single `InvoiceNote` using its globally unique id. */
  deleteInvoiceNote?: Maybe<DeleteInvoiceNotePayload>;
  /** Deletes a single `InvoiceNote` using a unique key. */
  deleteInvoiceNoteById?: Maybe<DeleteInvoiceNotePayload>;
  /** Deletes a single `PicklistQueue` using its globally unique id. */
  deletePicklistQueue?: Maybe<DeletePicklistQueuePayload>;
  /** Deletes a single `PicklistQueue` using a unique key. */
  deletePicklistQueueById?: Maybe<DeletePicklistQueuePayload>;
  /** Deletes a single `PicklistQueue` using a unique key. */
  deletePicklistQueueByItemId?: Maybe<DeletePicklistQueuePayload>;
  /** Deletes a single `PickupCustomer` using its globally unique id. */
  deletePickupCustomer?: Maybe<DeletePickupCustomerPayload>;
  /** Deletes a single `PickupCustomer` using a unique key. */
  deletePickupCustomerById?: Maybe<DeletePickupCustomerPayload>;
  /** Deletes a single `PickupCustomer` using a unique key. */
  deletePickupCustomerByLocationIdAndCustomerId?: Maybe<DeletePickupCustomerPayload>;
  /** Deletes a single `PickupCustomer` using a unique key. */
  deletePickupCustomerByLocationIdAndNumber?: Maybe<DeletePickupCustomerPayload>;
  /** Deletes a single `PickupQueue` using its globally unique id. */
  deletePickupQueue?: Maybe<DeletePickupQueuePayload>;
  /** Deletes a single `PickupQueue` using a unique key. */
  deletePickupQueueById?: Maybe<DeletePickupQueuePayload>;
  /** Deletes a single `PickupSession` using its globally unique id. */
  deletePickupSession?: Maybe<DeletePickupSessionPayload>;
  /** Deletes a single `PickupSession` using a unique key. */
  deletePickupSessionById?: Maybe<DeletePickupSessionPayload>;
  /** Deletes a single `ShippingQueue` using its globally unique id. */
  deleteShippingQueue?: Maybe<DeleteShippingQueuePayload>;
  /** Deletes a single `ShippingQueue` using a unique key. */
  deleteShippingQueueById?: Maybe<DeleteShippingQueuePayload>;
  /** Deletes a single `ShippingQueue` using a unique key. */
  deleteShippingQueueByItemId?: Maybe<DeleteShippingQueuePayload>;
  addImageItem?: Maybe<AddImageItemPayload>;
  addItemToBin?: Maybe<AddItemToBinPayload>;
  addItemToCart?: Maybe<AddItemToCartPayload>;
  addItemToRack?: Maybe<AddItemToRackPayload>;
  adjustBidderCredits?: Maybe<AdjustBidderCreditsPayload>;
  applyCreditToInvoice?: Maybe<ApplyCreditToInvoicePayload>;
  assignBinToRack?: Maybe<AssignBinToRackPayload>;
  automaticCheckIn?: Maybe<AutomaticCheckInPayload>;
  batchEmailInvoices?: Maybe<BatchEmailInvoicesPayload>;
  batchProcessInvoicePayments?: Maybe<BatchProcessInvoicePaymentsPayload>;
  batchSendToPicklistQueue?: Maybe<BatchSendToPicklistQueuePayload>;
  changeUserPassword?: Maybe<ChangeUserPasswordPayload>;
  chargeInvoicePayment?: Maybe<ChargeInvoicePaymentPayload>;
  clearAllUserNotifications?: Maybe<ClearAllUserNotificationsPayload>;
  clearPickupQueueItem?: Maybe<ClearPickupQueueItemPayload>;
  clearUserNotification?: Maybe<ClearUserNotificationPayload>;
  commitAuction?: Maybe<CommitAuctionPayload>;
  /** Creates a user with administrator role */
  createAdministrator?: Maybe<CreateAdministratorPayload>;
  createBatchEmailInvoicesJob?: Maybe<CreateBatchEmailInvoicesJobPayload>;
  createBatchProcessInvoicePaymentsJob?: Maybe<CreateBatchProcessInvoicePaymentsJobPayload>;
  /** Creates a user with employee role */
  createEmployee?: Maybe<CreateEmployeePayload>;
  /** Creates a user with secretary role */
  createSecretary?: Maybe<CreateSecretaryPayload>;
  customerInvoicePortal?: Maybe<CustomerInvoicePortalPayload>;
  deleteAuctionPushByAuctionId?: Maybe<DeleteAuctionPushByAuctionIdPayload>;
  deleteCreditFromReturn?: Maybe<DeleteCreditFromReturnPayload>;
  deleteItemFromInvoice?: Maybe<DeleteItemFromInvoicePayload>;
  deletePickupQueueItem?: Maybe<DeletePickupQueueItemPayload>;
  downloadReturnReport?: Maybe<DownloadReturnReportPayload>;
  emailAuctionInvoices?: Maybe<EmailAuctionInvoicesPayload>;
  emailInvoice?: Maybe<EmailInvoicePayload>;
  generateDates1MonthFromNow?: Maybe<GenerateDates1MonthFromNowPayload>;
  generateDatesInThisMonth?: Maybe<GenerateDatesInThisMonthPayload>;
  handleAssignNextCustomerInPickupQueue?: Maybe<HandleAssignNextCustomerInPickupQueuePayload>;
  handleCancelPickupAction?: Maybe<HandleCancelPickupActionPayload>;
  handleDeliverAction?: Maybe<HandleDeliverActionPayload>;
  handlePickupStepCustVerify?: Maybe<HandlePickupStepCustVerifyPayload>;
  handlePickupStepStart?: Maybe<HandlePickupStepStartPayload>;
  handlePickupVerifyCustomer?: Maybe<HandlePickupVerifyCustomerPayload>;
  handleReturnAction?: Maybe<HandleReturnActionPayload>;
  handleSendInvoiceToPickupQueueAction?: Maybe<HandleSendInvoiceToPickupQueueActionPayload>;
  insertItemImages?: Maybe<InsertItemImagesPayload>;
  issueReturnForItem?: Maybe<IssueReturnForItemPayload>;
  markInvoiceAsPaid?: Maybe<MarkInvoiceAsPaidPayload>;
  picklistAssignNextCustomer?: Maybe<PicklistAssignNextCustomerPayload>;
  picklistItemNotFound?: Maybe<PicklistItemNotFoundPayload>;
  pickupActionAssignBinToItems?: Maybe<PickupActionAssignBinToItemsPayload>;
  pickupActionAssignBinToRack?: Maybe<PickupActionAssignBinToRackPayload>;
  pickupActionAssignRackToItems?: Maybe<PickupActionAssignRackToItemsPayload>;
  placeItemOnHold?: Maybe<PlaceItemOnHoldPayload>;
  printInboundTruckloadLabels?: Maybe<PrintInboundTruckloadLabelsPayload>;
  printInvoiceLabel?: Maybe<PrintInvoiceLabelPayload>;
  printNotPickedUpLabels?: Maybe<PrintNotPickedUpLabelsPayload>;
  processAuctionInvoicePayments?: Maybe<ProcessAuctionInvoicePaymentsPayload>;
  relistItem?: Maybe<RelistItemPayload>;
  removeItemTax?: Maybe<RemoveItemTaxPayload>;
  removePrimaryImageItem?: Maybe<RemovePrimaryImageItemPayload>;
  reprintTruckload?: Maybe<ReprintTruckloadPayload>;
  resortItemImages?: Maybe<ResortItemImagesPayload>;
  resyncCustomerFromBidwrangler?: Maybe<ResyncCustomerFromBidwranglerPayload>;
  setAsideItem?: Maybe<SetAsideItemPayload>;
  setInvoiceLastPickupDate?: Maybe<SetInvoiceLastPickupDatePayload>;
  setInvoiceSignaturesBasedOnItems?: Maybe<SetInvoiceSignaturesBasedOnItemsPayload>;
  setInvoicesLastPickupDate?: Maybe<SetInvoicesLastPickupDatePayload>;
  setItemBinByItemIds?: Maybe<SetItemBinByItemIdsPayload>;
  setItemRackLocationByItemIds?: Maybe<SetItemRackLocationByItemIdsPayload>;
  setItemsRackLocation?: Maybe<SetItemsRackLocationPayload>;
  setProcessInvoicePayment?: Maybe<SetProcessInvoicePaymentPayload>;
  setProcessPayment?: Maybe<SetProcessPaymentPayload>;
  setUserLocation?: Maybe<SetUserLocationPayload>;
  shippingAddItemToCart?: Maybe<ShippingAddItemToCartPayload>;
  shippingAssignNextCustomer?: Maybe<ShippingAssignNextCustomerPayload>;
  shippingAssignRackToItem?: Maybe<ShippingAssignRackToItemPayload>;
  shippingItemNotFound?: Maybe<ShippingItemNotFoundPayload>;
  startInvoicePayment?: Maybe<StartInvoicePaymentPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserActiveState?: Maybe<UpdateUserActiveStatePayload>;
  voidPayment?: Maybe<VoidPaymentPayload>;
  payCustomerInvoice?: Maybe<PayCustomerInvoicePayload>;
  downloadCustomerInvoice?: Maybe<DownloadCustomerInvoicePayload>;
  /** Use this mutation to log in to account by username and password */
  loginByUsernameAndPassword?: Maybe<LoginByUsernameAndPasswordPayload>;
  /** Use this mutation to log in to account by username and password */
  loginByPinCode?: Maybe<LoginByPinCodePayload>;
  /** Use this mutation to auto log in by refreshToken */
  loginByRefreshToken?: Maybe<LoginByRefreshTokenPayload>;
  /** Use this mutation to logout from your account */
  logout?: Maybe<LogoutPayload>;
  uploadItemsImage?: Maybe<UploadItemsImagePayload>;
  reuploadItemsImage?: Maybe<ReuploadItemsImagePayload>;
  copyItemsImage?: Maybe<CopyItemsImagePayload>;
  /** Use this mutation to upload signature to invoice */
  uploadSignature?: Maybe<UploadSignaturePayload>;
  /** Use this mutation to upload truckload */
  uploadTruckload?: Maybe<UploadTruckloadPayload>;
  /** Use this mutation to upload truckload bill of lading */
  uploadTruckloadBillOfLading?: Maybe<Truckload>;
  downloadPaperlessApp?: Maybe<DownloadPaperlessAppPayload>;
  /** Upserts a single `PickupSession`. */
  upsertPickupSession?: Maybe<UpsertPickupSessionPayload>;
  /** Upserts a single `ReturnOption`. */
  upsertReturnOption?: Maybe<UpsertReturnOptionPayload>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateCustomerNoteArgs = {
  input: CreateCustomerNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInvoiceAdjustmentArgs = {
  input: CreateInvoiceAdjustmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInvoiceItemReturnArgs = {
  input: CreateInvoiceItemReturnInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateInvoiceNoteArgs = {
  input: CreateInvoiceNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePickupSessionArgs = {
  input: CreatePickupSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePrinterArgs = {
  input: CreatePrinterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateReturnOptionArgs = {
  input: CreateReturnOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserLocationArgs = {
  input: CreateUserLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuctionArgs = {
  input: UpdateAuctionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuctionByIdArgs = {
  input: UpdateAuctionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuctionByInvIdArgs = {
  input: UpdateAuctionByInvIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuctionByBwIdArgs = {
  input: UpdateAuctionByBwIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBidderArgs = {
  input: UpdateBidderInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBidderByIdArgs = {
  input: UpdateBidderByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateBidderByBwIdArgs = {
  input: UpdateBidderByBwIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerNoteArgs = {
  input: UpdateCustomerNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateCustomerNoteByIdArgs = {
  input: UpdateCustomerNoteByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInvoiceAdjustmentArgs = {
  input: UpdateInvoiceAdjustmentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateInvoiceAdjustmentByIdArgs = {
  input: UpdateInvoiceAdjustmentByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateItemImageArgs = {
  input: UpdateItemImageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateItemImageByIdArgs = {
  input: UpdateItemImageByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateItemArgs = {
  input: UpdateItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateItemByIdArgs = {
  input: UpdateItemByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateItemByBwIdArgs = {
  input: UpdateItemByBwIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocationArgs = {
  input: UpdateLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocationByIdArgs = {
  input: UpdateLocationByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocationByInvoicePrefixArgs = {
  input: UpdateLocationByInvoicePrefixInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocationByNameArgs = {
  input: UpdateLocationByNameInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePicklistQueueArgs = {
  input: UpdatePicklistQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePicklistQueueByIdArgs = {
  input: UpdatePicklistQueueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePicklistQueueByItemIdArgs = {
  input: UpdatePicklistQueueByItemIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePickupCustomerArgs = {
  input: UpdatePickupCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePickupCustomerByIdArgs = {
  input: UpdatePickupCustomerByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePickupCustomerByLocationIdAndCustomerIdArgs = {
  input: UpdatePickupCustomerByLocationIdAndCustomerIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePickupCustomerByLocationIdAndNumberArgs = {
  input: UpdatePickupCustomerByLocationIdAndNumberInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePickupQueueArgs = {
  input: UpdatePickupQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePickupQueueByIdArgs = {
  input: UpdatePickupQueueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePickupSessionArgs = {
  input: UpdatePickupSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePickupSessionByIdArgs = {
  input: UpdatePickupSessionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePrinterArgs = {
  input: UpdatePrinterInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePrinterByIdArgs = {
  input: UpdatePrinterByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReturnOptionArgs = {
  input: UpdateReturnOptionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReturnOptionByIdArgs = {
  input: UpdateReturnOptionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReturnQueueArgs = {
  input: UpdateReturnQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateReturnQueueByIdArgs = {
  input: UpdateReturnQueueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShippingQueueArgs = {
  input: UpdateShippingQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShippingQueueByIdArgs = {
  input: UpdateShippingQueueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateShippingQueueByItemIdArgs = {
  input: UpdateShippingQueueByItemIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTruckloadArgs = {
  input: UpdateTruckloadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateTruckloadByIdArgs = {
  input: UpdateTruckloadByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserLocationArgs = {
  input: UpdateUserLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserLocationByUserIdArgs = {
  input: UpdateUserLocationByUserIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserNotificationArgs = {
  input: UpdateUserNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserNotificationByIdArgs = {
  input: UpdateUserNotificationByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerNoteArgs = {
  input: DeleteCustomerNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCustomerNoteByIdArgs = {
  input: DeleteCustomerNoteByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInvoiceNoteArgs = {
  input: DeleteInvoiceNoteInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteInvoiceNoteByIdArgs = {
  input: DeleteInvoiceNoteByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePicklistQueueArgs = {
  input: DeletePicklistQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePicklistQueueByIdArgs = {
  input: DeletePicklistQueueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePicklistQueueByItemIdArgs = {
  input: DeletePicklistQueueByItemIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupCustomerArgs = {
  input: DeletePickupCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupCustomerByIdArgs = {
  input: DeletePickupCustomerByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupCustomerByLocationIdAndCustomerIdArgs = {
  input: DeletePickupCustomerByLocationIdAndCustomerIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupCustomerByLocationIdAndNumberArgs = {
  input: DeletePickupCustomerByLocationIdAndNumberInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupQueueArgs = {
  input: DeletePickupQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupQueueByIdArgs = {
  input: DeletePickupQueueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupSessionArgs = {
  input: DeletePickupSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupSessionByIdArgs = {
  input: DeletePickupSessionByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteShippingQueueArgs = {
  input: DeleteShippingQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteShippingQueueByIdArgs = {
  input: DeleteShippingQueueByIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteShippingQueueByItemIdArgs = {
  input: DeleteShippingQueueByItemIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddImageItemArgs = {
  input: AddImageItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddItemToBinArgs = {
  input: AddItemToBinInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddItemToCartArgs = {
  input: AddItemToCartInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAddItemToRackArgs = {
  input: AddItemToRackInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAdjustBidderCreditsArgs = {
  input: AdjustBidderCreditsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationApplyCreditToInvoiceArgs = {
  input: ApplyCreditToInvoiceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAssignBinToRackArgs = {
  input: AssignBinToRackInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationAutomaticCheckInArgs = {
  input: AutomaticCheckInInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationBatchEmailInvoicesArgs = {
  input: BatchEmailInvoicesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationBatchProcessInvoicePaymentsArgs = {
  input: BatchProcessInvoicePaymentsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationBatchSendToPicklistQueueArgs = {
  input: BatchSendToPicklistQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangeUserPasswordArgs = {
  input: ChangeUserPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationChargeInvoicePaymentArgs = {
  input: ChargeInvoicePaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearAllUserNotificationsArgs = {
  input: ClearAllUserNotificationsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearPickupQueueItemArgs = {
  input: ClearPickupQueueItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationClearUserNotificationArgs = {
  input: ClearUserNotificationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCommitAuctionArgs = {
  input: CommitAuctionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAdministratorArgs = {
  input: CreateAdministratorInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBatchEmailInvoicesJobArgs = {
  input: CreateBatchEmailInvoicesJobInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateBatchProcessInvoicePaymentsJobArgs = {
  input: CreateBatchProcessInvoicePaymentsJobInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateEmployeeArgs = {
  input: CreateEmployeeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSecretaryArgs = {
  input: CreateSecretaryInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCustomerInvoicePortalArgs = {
  input: CustomerInvoicePortalInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuctionPushByAuctionIdArgs = {
  input: DeleteAuctionPushByAuctionIdInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteCreditFromReturnArgs = {
  input: DeleteCreditFromReturnInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteItemFromInvoiceArgs = {
  input: DeleteItemFromInvoiceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePickupQueueItemArgs = {
  input: DeletePickupQueueItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDownloadReturnReportArgs = {
  input: DownloadReturnReportInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationEmailAuctionInvoicesArgs = {
  input: EmailAuctionInvoicesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationEmailInvoiceArgs = {
  input: EmailInvoiceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateDates1MonthFromNowArgs = {
  input: GenerateDates1MonthFromNowInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationGenerateDatesInThisMonthArgs = {
  input: GenerateDatesInThisMonthInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationHandleAssignNextCustomerInPickupQueueArgs = {
  input: HandleAssignNextCustomerInPickupQueueInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationHandleCancelPickupActionArgs = {
  input: HandleCancelPickupActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationHandleDeliverActionArgs = {
  input: HandleDeliverActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationHandlePickupStepCustVerifyArgs = {
  input: HandlePickupStepCustVerifyInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationHandlePickupStepStartArgs = {
  input: HandlePickupStepStartInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationHandlePickupVerifyCustomerArgs = {
  input: HandlePickupVerifyCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationHandleReturnActionArgs = {
  input: HandleReturnActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationHandleSendInvoiceToPickupQueueActionArgs = {
  input: HandleSendInvoiceToPickupQueueActionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationInsertItemImagesArgs = {
  input: InsertItemImagesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationIssueReturnForItemArgs = {
  input: IssueReturnForItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationMarkInvoiceAsPaidArgs = {
  input: MarkInvoiceAsPaidInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPicklistAssignNextCustomerArgs = {
  input: PicklistAssignNextCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPicklistItemNotFoundArgs = {
  input: PicklistItemNotFoundInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPickupActionAssignBinToItemsArgs = {
  input: PickupActionAssignBinToItemsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPickupActionAssignBinToRackArgs = {
  input: PickupActionAssignBinToRackInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPickupActionAssignRackToItemsArgs = {
  input: PickupActionAssignRackToItemsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPlaceItemOnHoldArgs = {
  input: PlaceItemOnHoldInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPrintInboundTruckloadLabelsArgs = {
  input: PrintInboundTruckloadLabelsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPrintInvoiceLabelArgs = {
  input: PrintInvoiceLabelInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPrintNotPickedUpLabelsArgs = {
  input: PrintNotPickedUpLabelsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationProcessAuctionInvoicePaymentsArgs = {
  input: ProcessAuctionInvoicePaymentsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRelistItemArgs = {
  input: RelistItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemoveItemTaxArgs = {
  input: RemoveItemTaxInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationRemovePrimaryImageItemArgs = {
  input: RemovePrimaryImageItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationReprintTruckloadArgs = {
  input: ReprintTruckloadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResortItemImagesArgs = {
  input: ResortItemImagesInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationResyncCustomerFromBidwranglerArgs = {
  input: ResyncCustomerFromBidwranglerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetAsideItemArgs = {
  input: SetAsideItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetInvoiceLastPickupDateArgs = {
  input: SetInvoiceLastPickupDateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetInvoiceSignaturesBasedOnItemsArgs = {
  input: SetInvoiceSignaturesBasedOnItemsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetInvoicesLastPickupDateArgs = {
  input: SetInvoicesLastPickupDateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetItemBinByItemIdsArgs = {
  input: SetItemBinByItemIdsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetItemRackLocationByItemIdsArgs = {
  input: SetItemRackLocationByItemIdsInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetItemsRackLocationArgs = {
  input: SetItemsRackLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetProcessInvoicePaymentArgs = {
  input: SetProcessInvoicePaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetProcessPaymentArgs = {
  input: SetProcessPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationSetUserLocationArgs = {
  input: SetUserLocationInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationShippingAddItemToCartArgs = {
  input: ShippingAddItemToCartInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationShippingAssignNextCustomerArgs = {
  input: ShippingAssignNextCustomerInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationShippingAssignRackToItemArgs = {
  input: ShippingAssignRackToItemInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationShippingItemNotFoundArgs = {
  input: ShippingItemNotFoundInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationStartInvoicePaymentArgs = {
  input: StartInvoicePaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserActiveStateArgs = {
  input: UpdateUserActiveStateInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationVoidPaymentArgs = {
  input: VoidPaymentInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationPayCustomerInvoiceArgs = {
  input: PayCustomerInvoiceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDownloadCustomerInvoiceArgs = {
  input: DownloadCustomerInvoiceInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginByUsernameAndPasswordArgs = {
  input: LoginByUsernameAndPasswordInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginByPinCodeArgs = {
  input: LoginByPinCodeInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationLoginByRefreshTokenArgs = {
  input?: Maybe<LoginByRefreshTokenInput>;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadItemsImageArgs = {
  input: UploadItemsImageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationReuploadItemsImageArgs = {
  input: ReuploadItemsImageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationCopyItemsImageArgs = {
  input: CopyItemsImageInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadSignatureArgs = {
  input: UploadSignatureInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadTruckloadArgs = {
  input: UploadTruckloadInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUploadTruckloadBillOfLadingArgs = {
  input: UploadTruckloadBillOfLadingInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationDownloadPaperlessAppArgs = {
  input: DownloadPaperlessAppInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertPickupSessionArgs = {
  where?: Maybe<UpsertPickupSessionWhere>;
  input: UpsertPickupSessionInput;
};


/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertReturnOptionArgs = {
  where?: Maybe<UpsertReturnOptionWhere>;
  input: UpsertReturnOptionInput;
};

export type MyEmployeeStat = {
  __typename?: 'MyEmployeeStat';
  employeeId?: Maybe<Scalars['Int']>;
  employeeName?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  locationName?: Maybe<Scalars['String']>;
  dailyTotalItems?: Maybe<Scalars['Int']>;
  dailyPicklistItems?: Maybe<Scalars['Int']>;
  dailyPickupItems?: Maybe<Scalars['Int']>;
  last60TotalItems?: Maybe<Scalars['Int']>;
  last60PicklistItems?: Maybe<Scalars['Int']>;
  last60PickupItems?: Maybe<Scalars['Int']>;
};

/**
 * A condition to be used against `MyEmployeeStat` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type MyEmployeeStatCondition = {
  /** Checks for equality with the object’s `employeeId` field. */
  employeeId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `employeeName` field. */
  employeeName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationName` field. */
  locationName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dailyTotalItems` field. */
  dailyTotalItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `dailyPicklistItems` field. */
  dailyPicklistItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `dailyPickupItems` field. */
  dailyPickupItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `last60TotalItems` field. */
  last60TotalItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `last60PicklistItems` field. */
  last60PicklistItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `last60PickupItems` field. */
  last60PickupItems?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `MyEmployeeStat` object types. All fields are combined with a logical ‘and.’ */
export type MyEmployeeStatFilter = {
  /** Filter by the object’s `employeeId` field. */
  employeeId?: Maybe<IntFilter>;
  /** Filter by the object’s `employeeName` field. */
  employeeName?: Maybe<StringFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `locationName` field. */
  locationName?: Maybe<StringFilter>;
  /** Filter by the object’s `dailyTotalItems` field. */
  dailyTotalItems?: Maybe<IntFilter>;
  /** Filter by the object’s `dailyPicklistItems` field. */
  dailyPicklistItems?: Maybe<IntFilter>;
  /** Filter by the object’s `dailyPickupItems` field. */
  dailyPickupItems?: Maybe<IntFilter>;
  /** Filter by the object’s `last60TotalItems` field. */
  last60TotalItems?: Maybe<IntFilter>;
  /** Filter by the object’s `last60PicklistItems` field. */
  last60PicklistItems?: Maybe<IntFilter>;
  /** Filter by the object’s `last60PickupItems` field. */
  last60PickupItems?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<MyEmployeeStatFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<MyEmployeeStatFilter>>;
  /** Negates the expression. */
  not?: Maybe<MyEmployeeStatFilter>;
};

/** A connection to a list of `MyEmployeeStat` values. */
export type MyEmployeeStatsConnection = {
  __typename?: 'MyEmployeeStatsConnection';
  /** A list of `MyEmployeeStat` objects. */
  nodes: Array<MyEmployeeStat>;
  /** A list of edges which contains the `MyEmployeeStat` and cursor to aid in pagination. */
  edges: Array<MyEmployeeStatsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `MyEmployeeStat` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `MyEmployeeStat` edge in the connection. */
export type MyEmployeeStatsEdge = {
  __typename?: 'MyEmployeeStatsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `MyEmployeeStat` at the end of the edge. */
  node: MyEmployeeStat;
};

/** Methods to use when ordering `MyEmployeeStat`. */
export enum MyEmployeeStatsOrderBy {
  Natural = 'NATURAL',
  EmployeeIdAsc = 'EMPLOYEE_ID_ASC',
  EmployeeIdDesc = 'EMPLOYEE_ID_DESC',
  EmployeeNameAsc = 'EMPLOYEE_NAME_ASC',
  EmployeeNameDesc = 'EMPLOYEE_NAME_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  LocationNameAsc = 'LOCATION_NAME_ASC',
  LocationNameDesc = 'LOCATION_NAME_DESC',
  DailyTotalItemsAsc = 'DAILY_TOTAL_ITEMS_ASC',
  DailyTotalItemsDesc = 'DAILY_TOTAL_ITEMS_DESC',
  DailyPicklistItemsAsc = 'DAILY_PICKLIST_ITEMS_ASC',
  DailyPicklistItemsDesc = 'DAILY_PICKLIST_ITEMS_DESC',
  DailyPickupItemsAsc = 'DAILY_PICKUP_ITEMS_ASC',
  DailyPickupItemsDesc = 'DAILY_PICKUP_ITEMS_DESC',
  Last_60TotalItemsAsc = 'LAST_60_TOTAL_ITEMS_ASC',
  Last_60TotalItemsDesc = 'LAST_60_TOTAL_ITEMS_DESC',
  Last_60PicklistItemsAsc = 'LAST_60_PICKLIST_ITEMS_ASC',
  Last_60PicklistItemsDesc = 'LAST_60_PICKLIST_ITEMS_DESC',
  Last_60PickupItemsAsc = 'LAST_60_PICKUP_ITEMS_ASC',
  Last_60PickupItemsDesc = 'LAST_60_PICKUP_ITEMS_DESC'
}

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']>;
};

export type PayCustomerInvoiceInput = {
  invoiceId: Scalars['ID'];
  dataDescriptor: Scalars['String'];
  dataValue: Scalars['String'];
  saveForFutureUse?: Maybe<Scalars['Boolean']>;
};

export type PayCustomerInvoicePayload = {
  __typename?: 'PayCustomerInvoicePayload';
  invoice?: Maybe<Invoice>;
  query?: Maybe<Query>;
};

export type Payment = Node & {
  __typename?: 'Payment';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  invoiceId: Scalars['Int'];
  jobQueueId?: Maybe<Scalars['Int']>;
  paymentProcessorChargeTransactionId?: Maybe<Scalars['String']>;
  processingVoidStatus?: Maybe<QueuedProcessingType>;
  processingRefundStatus?: Maybe<QueuedProcessingType>;
  isVoided: Scalars['Boolean'];
  isCharged: Scalars['Boolean'];
  status?: Maybe<QueuedProcessingType>;
  chargeStatus?: Maybe<StatusType>;
  chargeStatusErrorMessage?: Maybe<Scalars['String']>;
  amount: Scalars['Int'];
  paymentAmount: Scalars['Int'];
  creditAmount: Scalars['Int'];
  refundedPaymentAmount: Scalars['Int'];
  refundedCreditAmount: Scalars['Int'];
  cardNumber: Scalars['String'];
  cardType: Scalars['String'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Invoice` that is related to this `Payment`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads and enables pagination through a set of `Transaction`. */
  transactionsByPaymentId: TransactionsConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPaymentId: ItemsConnection;
  /** Reads and enables pagination through a set of `PaymentsLog`. */
  paymentsLogsByPaymentId: PaymentsLogsConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByItemPaymentIdAndAuctionId: PaymentAuctionsByItemPaymentIdAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByItemPaymentIdAndInvoiceId: PaymentInvoicesByItemPaymentIdAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByItemPaymentIdAndBidderId: PaymentBiddersByItemPaymentIdAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemPaymentIdAndDeletedFromInvoiceBy: PaymentUsersByItemPaymentIdAndDeletedFromInvoiceByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemPaymentIdAndSetAsideBy: PaymentUsersByItemPaymentIdAndSetAsideByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemPaymentIdAndIsDeletedBy: PaymentUsersByItemPaymentIdAndIsDeletedByManyToManyConnection;
};


export type PaymentTransactionsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TransactionsOrderBy>>;
  condition?: Maybe<TransactionCondition>;
  filter?: Maybe<TransactionFilter>;
};


export type PaymentItemsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type PaymentPaymentsLogsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsLogsOrderBy>>;
  condition?: Maybe<PaymentsLogCondition>;
  filter?: Maybe<PaymentsLogFilter>;
};


export type PaymentAuctionsByItemPaymentIdAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type PaymentInvoicesByItemPaymentIdAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type PaymentBiddersByItemPaymentIdAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type PaymentUsersByItemPaymentIdAndDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type PaymentUsersByItemPaymentIdAndSetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type PaymentUsersByItemPaymentIdAndIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/** A connection to a list of `Auction` values, with data from `Item`. */
export type PaymentAuctionsByItemPaymentIdAndAuctionIdManyToManyConnection = {
  __typename?: 'PaymentAuctionsByItemPaymentIdAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<PaymentAuctionsByItemPaymentIdAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Item`. */
export type PaymentAuctionsByItemPaymentIdAndAuctionIdManyToManyEdge = {
  __typename?: 'PaymentAuctionsByItemPaymentIdAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByAuctionId: ItemsConnection;
};


/** A `Auction` edge in the connection, with data from `Item`. */
export type PaymentAuctionsByItemPaymentIdAndAuctionIdManyToManyEdgeItemsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Item`. */
export type PaymentBiddersByItemPaymentIdAndBidderIdManyToManyConnection = {
  __typename?: 'PaymentBiddersByItemPaymentIdAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<PaymentBiddersByItemPaymentIdAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Item`. */
export type PaymentBiddersByItemPaymentIdAndBidderIdManyToManyEdge = {
  __typename?: 'PaymentBiddersByItemPaymentIdAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderId: ItemsConnection;
};


/** A `Bidder` edge in the connection, with data from `Item`. */
export type PaymentBiddersByItemPaymentIdAndBidderIdManyToManyEdgeItemsByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A condition to be used against `Payment` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PaymentCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `invoiceId` field. */
  invoiceId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `jobQueueId` field. */
  jobQueueId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentProcessorChargeTransactionId` field. */
  paymentProcessorChargeTransactionId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `processingVoidStatus` field. */
  processingVoidStatus?: Maybe<QueuedProcessingType>;
  /** Checks for equality with the object’s `processingRefundStatus` field. */
  processingRefundStatus?: Maybe<QueuedProcessingType>;
  /** Checks for equality with the object’s `isVoided` field. */
  isVoided?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isCharged` field. */
  isCharged?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<QueuedProcessingType>;
  /** Checks for equality with the object’s `chargeStatus` field. */
  chargeStatus?: Maybe<StatusType>;
  /** Checks for equality with the object’s `chargeStatusErrorMessage` field. */
  chargeStatusErrorMessage?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentAmount` field. */
  paymentAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `creditAmount` field. */
  creditAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `refundedPaymentAmount` field. */
  refundedPaymentAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `refundedCreditAmount` field. */
  refundedCreditAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `cardNumber` field. */
  cardNumber?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `cardType` field. */
  cardType?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `Payment` object types. All fields are combined with a logical ‘and.’ */
export type PaymentFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `invoiceId` field. */
  invoiceId?: Maybe<IntFilter>;
  /** Filter by the object’s `jobQueueId` field. */
  jobQueueId?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentProcessorChargeTransactionId` field. */
  paymentProcessorChargeTransactionId?: Maybe<StringFilter>;
  /** Filter by the object’s `processingVoidStatus` field. */
  processingVoidStatus?: Maybe<QueuedProcessingTypeFilter>;
  /** Filter by the object’s `processingRefundStatus` field. */
  processingRefundStatus?: Maybe<QueuedProcessingTypeFilter>;
  /** Filter by the object’s `isVoided` field. */
  isVoided?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isCharged` field. */
  isCharged?: Maybe<BooleanFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<QueuedProcessingTypeFilter>;
  /** Filter by the object’s `chargeStatus` field. */
  chargeStatus?: Maybe<StatusTypeFilter>;
  /** Filter by the object’s `chargeStatusErrorMessage` field. */
  chargeStatusErrorMessage?: Maybe<StringFilter>;
  /** Filter by the object’s `amount` field. */
  amount?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentAmount` field. */
  paymentAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `creditAmount` field. */
  creditAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `refundedPaymentAmount` field. */
  refundedPaymentAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `refundedCreditAmount` field. */
  refundedCreditAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `cardNumber` field. */
  cardNumber?: Maybe<StringFilter>;
  /** Filter by the object’s `cardType` field. */
  cardType?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `transactionsByPaymentId` relation. */
  transactionsByPaymentId?: Maybe<PaymentToManyTransactionFilter>;
  /** Some related `transactionsByPaymentId` exist. */
  transactionsByPaymentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemsByPaymentId` relation. */
  itemsByPaymentId?: Maybe<PaymentToManyItemFilter>;
  /** Some related `itemsByPaymentId` exist. */
  itemsByPaymentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `paymentsLogsByPaymentId` relation. */
  paymentsLogsByPaymentId?: Maybe<PaymentToManyPaymentsLogFilter>;
  /** Some related `paymentsLogsByPaymentId` exist. */
  paymentsLogsByPaymentIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceByInvoiceId` relation. */
  invoiceByInvoiceId?: Maybe<InvoiceFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PaymentFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PaymentFilter>>;
  /** Negates the expression. */
  not?: Maybe<PaymentFilter>;
};

/** A connection to a list of `Invoice` values, with data from `Item`. */
export type PaymentInvoicesByItemPaymentIdAndInvoiceIdManyToManyConnection = {
  __typename?: 'PaymentInvoicesByItemPaymentIdAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<PaymentInvoicesByItemPaymentIdAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `Item`. */
export type PaymentInvoicesByItemPaymentIdAndInvoiceIdManyToManyEdge = {
  __typename?: 'PaymentInvoicesByItemPaymentIdAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceId: ItemsConnection;
};


/** A `Invoice` edge in the connection, with data from `Item`. */
export type PaymentInvoicesByItemPaymentIdAndInvoiceIdManyToManyEdgeItemsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A filter to be used against many `Item` object types. All fields are combined with a logical ‘and.’ */
export type PaymentToManyItemFilter = {
  /** Every related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemFilter>;
  /** Some related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemFilter>;
  /** No related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemFilter>;
};

/** A filter to be used against many `PaymentsLog` object types. All fields are combined with a logical ‘and.’ */
export type PaymentToManyPaymentsLogFilter = {
  /** Every related `PaymentsLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PaymentsLogFilter>;
  /** Some related `PaymentsLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PaymentsLogFilter>;
  /** No related `PaymentsLog` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PaymentsLogFilter>;
};

/** A filter to be used against many `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type PaymentToManyTransactionFilter = {
  /** Every related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TransactionFilter>;
  /** Some related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TransactionFilter>;
  /** No related `Transaction` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TransactionFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndDeletedFromInvoiceByManyToManyConnection = {
  __typename?: 'PaymentUsersByItemPaymentIdAndDeletedFromInvoiceByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<PaymentUsersByItemPaymentIdAndDeletedFromInvoiceByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndDeletedFromInvoiceByManyToManyEdge = {
  __typename?: 'PaymentUsersByItemPaymentIdAndDeletedFromInvoiceByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByDeletedFromInvoiceBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndDeletedFromInvoiceByManyToManyEdgeItemsByDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndIsDeletedByManyToManyConnection = {
  __typename?: 'PaymentUsersByItemPaymentIdAndIsDeletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<PaymentUsersByItemPaymentIdAndIsDeletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndIsDeletedByManyToManyEdge = {
  __typename?: 'PaymentUsersByItemPaymentIdAndIsDeletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByIsDeletedBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndIsDeletedByManyToManyEdgeItemsByIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndSetAsideByManyToManyConnection = {
  __typename?: 'PaymentUsersByItemPaymentIdAndSetAsideByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<PaymentUsersByItemPaymentIdAndSetAsideByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndSetAsideByManyToManyEdge = {
  __typename?: 'PaymentUsersByItemPaymentIdAndSetAsideByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySetAsideBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type PaymentUsersByItemPaymentIdAndSetAsideByManyToManyEdgeItemsBySetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Payment` values. */
export type PaymentsConnection = {
  __typename?: 'PaymentsConnection';
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** A list of edges which contains the `Payment` and cursor to aid in pagination. */
  edges: Array<PaymentsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection. */
export type PaymentsEdge = {
  __typename?: 'PaymentsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
};

export type PaymentsLog = Node & {
  __typename?: 'PaymentsLog';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  paymentId?: Maybe<Scalars['Int']>;
  type: Scalars['String'];
  text: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['String']>;
  /** Reads a single `Payment` that is related to this `PaymentsLog`. */
  paymentByPaymentId?: Maybe<Payment>;
};

/**
 * A condition to be used against `PaymentsLog` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PaymentsLogCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `text` field. */
  text?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['String']>;
};

/** A filter to be used against `PaymentsLog` object types. All fields are combined with a logical ‘and.’ */
export type PaymentsLogFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentId` field. */
  paymentId?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<StringFilter>;
  /** Filter by the object’s `text` field. */
  text?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<StringFilter>;
  /** Filter by the object’s `paymentByPaymentId` relation. */
  paymentByPaymentId?: Maybe<PaymentFilter>;
  /** A related `paymentByPaymentId` exists. */
  paymentByPaymentIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PaymentsLogFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PaymentsLogFilter>>;
  /** Negates the expression. */
  not?: Maybe<PaymentsLogFilter>;
};

/** A connection to a list of `PaymentsLog` values. */
export type PaymentsLogsConnection = {
  __typename?: 'PaymentsLogsConnection';
  /** A list of `PaymentsLog` objects. */
  nodes: Array<PaymentsLog>;
  /** A list of edges which contains the `PaymentsLog` and cursor to aid in pagination. */
  edges: Array<PaymentsLogsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PaymentsLog` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PaymentsLog` edge in the connection. */
export type PaymentsLogsEdge = {
  __typename?: 'PaymentsLogsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PaymentsLog` at the end of the edge. */
  node: PaymentsLog;
};

/** Methods to use when ordering `PaymentsLog`. */
export enum PaymentsLogsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  TextAsc = 'TEXT_ASC',
  TextDesc = 'TEXT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** Methods to use when ordering `Payment`. */
export enum PaymentsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InvoiceIdAsc = 'INVOICE_ID_ASC',
  InvoiceIdDesc = 'INVOICE_ID_DESC',
  JobQueueIdAsc = 'JOB_QUEUE_ID_ASC',
  JobQueueIdDesc = 'JOB_QUEUE_ID_DESC',
  PaymentProcessorChargeTransactionIdAsc = 'PAYMENT_PROCESSOR_CHARGE_TRANSACTION_ID_ASC',
  PaymentProcessorChargeTransactionIdDesc = 'PAYMENT_PROCESSOR_CHARGE_TRANSACTION_ID_DESC',
  ProcessingVoidStatusAsc = 'PROCESSING_VOID_STATUS_ASC',
  ProcessingVoidStatusDesc = 'PROCESSING_VOID_STATUS_DESC',
  ProcessingRefundStatusAsc = 'PROCESSING_REFUND_STATUS_ASC',
  ProcessingRefundStatusDesc = 'PROCESSING_REFUND_STATUS_DESC',
  IsVoidedAsc = 'IS_VOIDED_ASC',
  IsVoidedDesc = 'IS_VOIDED_DESC',
  IsChargedAsc = 'IS_CHARGED_ASC',
  IsChargedDesc = 'IS_CHARGED_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  ChargeStatusAsc = 'CHARGE_STATUS_ASC',
  ChargeStatusDesc = 'CHARGE_STATUS_DESC',
  ChargeStatusErrorMessageAsc = 'CHARGE_STATUS_ERROR_MESSAGE_ASC',
  ChargeStatusErrorMessageDesc = 'CHARGE_STATUS_ERROR_MESSAGE_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  PaymentAmountAsc = 'PAYMENT_AMOUNT_ASC',
  PaymentAmountDesc = 'PAYMENT_AMOUNT_DESC',
  CreditAmountAsc = 'CREDIT_AMOUNT_ASC',
  CreditAmountDesc = 'CREDIT_AMOUNT_DESC',
  RefundedPaymentAmountAsc = 'REFUNDED_PAYMENT_AMOUNT_ASC',
  RefundedPaymentAmountDesc = 'REFUNDED_PAYMENT_AMOUNT_DESC',
  RefundedCreditAmountAsc = 'REFUNDED_CREDIT_AMOUNT_ASC',
  RefundedCreditAmountDesc = 'REFUNDED_CREDIT_AMOUNT_DESC',
  CardNumberAsc = 'CARD_NUMBER_ASC',
  CardNumberDesc = 'CARD_NUMBER_DESC',
  CardTypeAsc = 'CARD_TYPE_ASC',
  CardTypeDesc = 'CARD_TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export enum Permission {
  AutomaticCheckIn = 'AUTOMATIC_CHECK_IN',
  PickupDeliver = 'PICKUP_DELIVER'
}

/** All input for the `picklistAssignNextCustomer` mutation. */
export type PicklistAssignNextCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `picklistAssignNextCustomer` mutation. */
export type PicklistAssignNextCustomerPayload = {
  __typename?: 'PicklistAssignNextCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type PicklistAvailableQueueType = {
  __typename?: 'PicklistAvailableQueueType';
  auctionName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `PicklistAvailableQueueType` object types. All fields are combined with a logical ‘and.’ */
export type PicklistAvailableQueueTypeFilter = {
  /** Filter by the object’s `auctionName` field. */
  auctionName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PicklistAvailableQueueTypeFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PicklistAvailableQueueTypeFilter>>;
  /** Negates the expression. */
  not?: Maybe<PicklistAvailableQueueTypeFilter>;
};

/** A connection to a list of `PicklistAvailableQueueType` values. */
export type PicklistAvailableQueueTypesConnection = {
  __typename?: 'PicklistAvailableQueueTypesConnection';
  /** A list of `PicklistAvailableQueueType` objects. */
  nodes: Array<PicklistAvailableQueueType>;
  /** A list of edges which contains the `PicklistAvailableQueueType` and cursor to aid in pagination. */
  edges: Array<PicklistAvailableQueueTypesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PicklistAvailableQueueType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PicklistAvailableQueueType` edge in the connection. */
export type PicklistAvailableQueueTypesEdge = {
  __typename?: 'PicklistAvailableQueueTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PicklistAvailableQueueType` at the end of the edge. */
  node: PicklistAvailableQueueType;
};

/** All input for the `picklistItemNotFound` mutation. */
export type PicklistItemNotFoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
};

/** The output of our `picklistItemNotFound` mutation. */
export type PicklistItemNotFoundPayload = {
  __typename?: 'PicklistItemNotFoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  picklistQueue?: Maybe<PicklistQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `PicklistQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `PicklistQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `PicklistQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByStepUpdatedBy?: Maybe<User>;
  /** An edge for our `PicklistQueue`. May be used by Relay 1. */
  picklistQueueEdge?: Maybe<PicklistQueuesEdge>;
};


/** The output of our `picklistItemNotFound` mutation. */
export type PicklistItemNotFoundPayloadPicklistQueueEdgeArgs = {
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
};

export type PicklistMyQueueType = {
  __typename?: 'PicklistMyQueueType';
  item?: Maybe<Item>;
  queue?: Maybe<PicklistQueue>;
};

/** A connection to a list of `PicklistMyQueueType` values. */
export type PicklistMyQueueTypesConnection = {
  __typename?: 'PicklistMyQueueTypesConnection';
  /** A list of `PicklistMyQueueType` objects. */
  nodes: Array<PicklistMyQueueType>;
  /** A list of edges which contains the `PicklistMyQueueType` and cursor to aid in pagination. */
  edges: Array<PicklistMyQueueTypesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PicklistMyQueueType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PicklistMyQueueType` edge in the connection. */
export type PicklistMyQueueTypesEdge = {
  __typename?: 'PicklistMyQueueTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PicklistMyQueueType` at the end of the edge. */
  node: PicklistMyQueueType;
};

export type PicklistQueue = Node & {
  __typename?: 'PicklistQueue';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  itemId: Scalars['Int'];
  itemNum: Scalars['Int'];
  customerId: Scalars['Int'];
  locationId: Scalars['Int'];
  assignedUserId?: Maybe<Scalars['Int']>;
  step?: Maybe<PicklistQueueStep>;
  stepValue?: Maybe<Scalars['String']>;
  status: PicklistQueueStatus;
  createdBy?: Maybe<Scalars['Int']>;
  createdAt: Scalars['Datetime'];
  completedBy?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['Datetime']>;
  scheduleTime?: Maybe<Scalars['Datetime']>;
  stepUpdatedAt?: Maybe<Scalars['Datetime']>;
  stepUpdatedBy?: Maybe<Scalars['Int']>;
  /** Reads a single `Item` that is related to this `PicklistQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `PicklistQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `PicklistQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByStepUpdatedBy?: Maybe<User>;
};

/**
 * A condition to be used against `PicklistQueue` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PicklistQueueCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `itemNum` field. */
  itemNum?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `step` field. */
  step?: Maybe<PicklistQueueStep>;
  /** Checks for equality with the object’s `stepValue` field. */
  stepValue?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<PicklistQueueStatus>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `completedBy` field. */
  completedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `scheduleTime` field. */
  scheduleTime?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `stepUpdatedAt` field. */
  stepUpdatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `stepUpdatedBy` field. */
  stepUpdatedBy?: Maybe<Scalars['Int']>;
};

/** The return type of our `picklistQueueCounts` query. */
export type PicklistQueueCountsRecord = {
  __typename?: 'PicklistQueueCountsRecord';
  totalCount?: Maybe<Scalars['Int']>;
  assignedCount?: Maybe<Scalars['Int']>;
  unassignedCount?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `PicklistQueue` object types. All fields are combined with a logical ‘and.’ */
export type PicklistQueueFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `itemNum` field. */
  itemNum?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<IntFilter>;
  /** Filter by the object’s `step` field. */
  step?: Maybe<PicklistQueueStepFilter>;
  /** Filter by the object’s `stepValue` field. */
  stepValue?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<PicklistQueueStatusFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `completedBy` field. */
  completedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `scheduleTime` field. */
  scheduleTime?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `stepUpdatedAt` field. */
  stepUpdatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `stepUpdatedBy` field. */
  stepUpdatedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `userByAssignedUserId` relation. */
  userByAssignedUserId?: Maybe<UserFilter>;
  /** A related `userByAssignedUserId` exists. */
  userByAssignedUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCompletedBy` relation. */
  userByCompletedBy?: Maybe<UserFilter>;
  /** A related `userByCompletedBy` exists. */
  userByCompletedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByStepUpdatedBy` relation. */
  userByStepUpdatedBy?: Maybe<UserFilter>;
  /** A related `userByStepUpdatedBy` exists. */
  userByStepUpdatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PicklistQueueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PicklistQueueFilter>>;
  /** Negates the expression. */
  not?: Maybe<PicklistQueueFilter>;
};

export type PicklistQueueItem = {
  __typename?: 'PicklistQueueItem';
  customerId?: Maybe<Scalars['Int']>;
  customerName?: Maybe<Scalars['String']>;
  assignedUserId?: Maybe<Scalars['Int']>;
  assignedName?: Maybe<Scalars['String']>;
  inQueueAt?: Maybe<Scalars['Datetime']>;
  scheduleTime?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `PicklistQueueItem` object types. All fields are combined with a logical ‘and.’ */
export type PicklistQueueItemFilter = {
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<IntFilter>;
  /** Filter by the object’s `assignedName` field. */
  assignedName?: Maybe<StringFilter>;
  /** Filter by the object’s `inQueueAt` field. */
  inQueueAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `scheduleTime` field. */
  scheduleTime?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PicklistQueueItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PicklistQueueItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<PicklistQueueItemFilter>;
};

/** A connection to a list of `PicklistQueueItem` values. */
export type PicklistQueueItemsConnection = {
  __typename?: 'PicklistQueueItemsConnection';
  /** A list of `PicklistQueueItem` objects. */
  nodes: Array<PicklistQueueItem>;
  /** A list of edges which contains the `PicklistQueueItem` and cursor to aid in pagination. */
  edges: Array<PicklistQueueItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PicklistQueueItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PicklistQueueItem` edge in the connection. */
export type PicklistQueueItemsEdge = {
  __typename?: 'PicklistQueueItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PicklistQueueItem` at the end of the edge. */
  node: PicklistQueueItem;
};

/** Represents an update to a `PicklistQueue`. Fields that are set will be updated. */
export type PicklistQueuePatch = {
  itemId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  locationId?: Maybe<Scalars['Int']>;
  assignedUserId?: Maybe<Scalars['Int']>;
  step?: Maybe<PicklistQueueStep>;
  stepValue?: Maybe<Scalars['String']>;
  status?: Maybe<PicklistQueueStatus>;
};

export enum PicklistQueueStatus {
  Waiting = 'WAITING',
  WorkingOn = 'WORKING_ON',
  Completed = 'COMPLETED'
}

/** A filter to be used against PicklistQueueStatus fields. All fields are combined with a logical ‘and.’ */
export type PicklistQueueStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<PicklistQueueStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<PicklistQueueStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<PicklistQueueStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<PicklistQueueStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<PicklistQueueStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<PicklistQueueStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<PicklistQueueStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<PicklistQueueStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<PicklistQueueStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<PicklistQueueStatus>;
};

export enum PicklistQueueStep {
  Rack = 'RACK',
  Cart = 'CART',
  Bin = 'BIN',
  BinToRack = 'BIN_TO_RACK',
  NotFound = 'NOT_FOUND'
}

/** A filter to be used against PicklistQueueStep fields. All fields are combined with a logical ‘and.’ */
export type PicklistQueueStepFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<PicklistQueueStep>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<PicklistQueueStep>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<PicklistQueueStep>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<PicklistQueueStep>;
  /** Included in the specified list. */
  in?: Maybe<Array<PicklistQueueStep>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<PicklistQueueStep>>;
  /** Less than the specified value. */
  lessThan?: Maybe<PicklistQueueStep>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<PicklistQueueStep>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<PicklistQueueStep>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<PicklistQueueStep>;
};

/** A connection to a list of `PicklistQueue` values. */
export type PicklistQueuesConnection = {
  __typename?: 'PicklistQueuesConnection';
  /** A list of `PicklistQueue` objects. */
  nodes: Array<PicklistQueue>;
  /** A list of edges which contains the `PicklistQueue` and cursor to aid in pagination. */
  edges: Array<PicklistQueuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PicklistQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PicklistQueue` edge in the connection. */
export type PicklistQueuesEdge = {
  __typename?: 'PicklistQueuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PicklistQueue` at the end of the edge. */
  node: PicklistQueue;
};

/** Methods to use when ordering `PicklistQueue`. */
export enum PicklistQueuesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  ItemNumAsc = 'ITEM_NUM_ASC',
  ItemNumDesc = 'ITEM_NUM_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  AssignedUserIdAsc = 'ASSIGNED_USER_ID_ASC',
  AssignedUserIdDesc = 'ASSIGNED_USER_ID_DESC',
  StepAsc = 'STEP_ASC',
  StepDesc = 'STEP_DESC',
  StepValueAsc = 'STEP_VALUE_ASC',
  StepValueDesc = 'STEP_VALUE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CompletedByAsc = 'COMPLETED_BY_ASC',
  CompletedByDesc = 'COMPLETED_BY_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  ScheduleTimeAsc = 'SCHEDULE_TIME_ASC',
  ScheduleTimeDesc = 'SCHEDULE_TIME_DESC',
  StepUpdatedAtAsc = 'STEP_UPDATED_AT_ASC',
  StepUpdatedAtDesc = 'STEP_UPDATED_AT_DESC',
  StepUpdatedByAsc = 'STEP_UPDATED_BY_ASC',
  StepUpdatedByDesc = 'STEP_UPDATED_BY_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `pickupActionAssignBinToItems` mutation. */
export type PickupActionAssignBinToItemsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  bin: Scalars['String'];
  itemIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `pickupActionAssignBinToItems` mutation. */
export type PickupActionAssignBinToItemsPayload = {
  __typename?: 'PickupActionAssignBinToItemsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `pickupActionAssignBinToRack` mutation. */
export type PickupActionAssignBinToRackInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  bin: Scalars['String'];
  rack: Scalars['String'];
};

/** The output of our `pickupActionAssignBinToRack` mutation. */
export type PickupActionAssignBinToRackPayload = {
  __typename?: 'PickupActionAssignBinToRackPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  integers?: Maybe<Array<Maybe<Scalars['Int']>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `pickupActionAssignRackToItems` mutation. */
export type PickupActionAssignRackToItemsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  rack: Scalars['String'];
  itemIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `pickupActionAssignRackToItems` mutation. */
export type PickupActionAssignRackToItemsPayload = {
  __typename?: 'PickupActionAssignRackToItemsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type PickupCustomer = Node & {
  __typename?: 'PickupCustomer';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  number: Scalars['Int'];
  customerNotAvailableAt?: Maybe<Scalars['Datetime']>;
  createdAt: Scalars['Datetime'];
  source: Scalars['String'];
  /** Reads a single `Location` that is related to this `PickupCustomer`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupCustomer`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupCustomer`. */
  userByUserId?: Maybe<User>;
};

/**
 * A condition to be used against `PickupCustomer` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PickupCustomerCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `number` field. */
  number?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerNotAvailableAt` field. */
  customerNotAvailableAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `source` field. */
  source?: Maybe<Scalars['String']>;
};

/** A filter to be used against `PickupCustomer` object types. All fields are combined with a logical ‘and.’ */
export type PickupCustomerFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<IntFilter>;
  /** Filter by the object’s `number` field. */
  number?: Maybe<IntFilter>;
  /** Filter by the object’s `customerNotAvailableAt` field. */
  customerNotAvailableAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `source` field. */
  source?: Maybe<StringFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** A related `userByUserId` exists. */
  userByUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PickupCustomerFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PickupCustomerFilter>>;
  /** Negates the expression. */
  not?: Maybe<PickupCustomerFilter>;
};

/** Represents an update to a `PickupCustomer`. Fields that are set will be updated. */
export type PickupCustomerPatch = {
  id?: Maybe<Scalars['UUID']>;
  locationId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  number?: Maybe<Scalars['Int']>;
  customerNotAvailableAt?: Maybe<Scalars['Datetime']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  source?: Maybe<Scalars['String']>;
};

/** A connection to a list of `PickupCustomer` values. */
export type PickupCustomersConnection = {
  __typename?: 'PickupCustomersConnection';
  /** A list of `PickupCustomer` objects. */
  nodes: Array<PickupCustomer>;
  /** A list of edges which contains the `PickupCustomer` and cursor to aid in pagination. */
  edges: Array<PickupCustomersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupCustomer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupCustomer` edge in the connection. */
export type PickupCustomersEdge = {
  __typename?: 'PickupCustomersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupCustomer` at the end of the edge. */
  node: PickupCustomer;
};

/** Methods to use when ordering `PickupCustomer`. */
export enum PickupCustomersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  NumberAsc = 'NUMBER_ASC',
  NumberDesc = 'NUMBER_DESC',
  CustomerNotAvailableAtAsc = 'CUSTOMER_NOT_AVAILABLE_AT_ASC',
  CustomerNotAvailableAtDesc = 'CUSTOMER_NOT_AVAILABLE_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  SourceAsc = 'SOURCE_ASC',
  SourceDesc = 'SOURCE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type PickupMyQueueResult = {
  __typename?: 'PickupMyQueueResult';
  info?: Maybe<PickupMyQueueResultInfo>;
  session?: Maybe<PickupMyQueueResultSession>;
  items?: Maybe<Array<Maybe<PickupMyQueueResultItem>>>;
};

export type PickupMyQueueResultInfo = {
  __typename?: 'PickupMyQueueResultInfo';
  customerId?: Maybe<Scalars['Int']>;
  customerName?: Maybe<Scalars['String']>;
  customerNumber?: Maybe<Scalars['String']>;
};

export type PickupMyQueueResultItem = {
  __typename?: 'PickupMyQueueResultItem';
  id?: Maybe<Scalars['Int']>;
  num?: Maybe<Scalars['Int']>;
  rack?: Maybe<Scalars['String']>;
  bin?: Maybe<Scalars['String']>;
  overallPickupStatus?: Maybe<ItemOverallPickupStatus>;
  isPriorityPickup?: Maybe<Scalars['Boolean']>;
};

export type PickupMyQueueResultSession = {
  __typename?: 'PickupMyQueueResultSession';
  id?: Maybe<Scalars['UUID']>;
  step?: Maybe<PickupSessionStep>;
};

export type PickupQueue = Node & {
  __typename?: 'PickupQueue';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  itemId: Scalars['Int'];
  locationId: Scalars['Int'];
  assignedUserId?: Maybe<Scalars['Int']>;
  inQueueAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<PickupQueueStatus>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  customerId: Scalars['Int'];
  completedAt?: Maybe<Scalars['Datetime']>;
  completedBy?: Maybe<Scalars['Int']>;
  createdBy?: Maybe<Scalars['Int']>;
  skipCount: Scalars['Int'];
  sessionId?: Maybe<Scalars['UUID']>;
  step?: Maybe<Scalars['String']>;
  /** Reads a single `Item` that is related to this `PickupQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Location` that is related to this `PickupQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `Bidder` that is related to this `PickupQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `PickupSession` that is related to this `PickupQueue`. */
  pickupSessionBySessionId?: Maybe<PickupSession>;
  elapsedSeconds?: Maybe<Scalars['Int']>;
};

export type PickupQueueAvailableCustomerInfo = {
  __typename?: 'PickupQueueAvailableCustomerInfo';
  customerId?: Maybe<Scalars['Int']>;
  customerName?: Maybe<Scalars['String']>;
  inQueueAt?: Maybe<Scalars['Datetime']>;
};

export type PickupQueueAvailableResult = {
  __typename?: 'PickupQueueAvailableResult';
  totalCount?: Maybe<Scalars['Int']>;
  customerInfo?: Maybe<Array<Maybe<PickupQueueAvailableCustomerInfo>>>;
};

/**
 * A condition to be used against `PickupQueue` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PickupQueueCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `inQueueAt` field. */
  inQueueAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<PickupQueueStatus>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `completedBy` field. */
  completedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `skipCount` field. */
  skipCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `sessionId` field. */
  sessionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `step` field. */
  step?: Maybe<Scalars['String']>;
};

/** A filter to be used against `PickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type PickupQueueFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<IntFilter>;
  /** Filter by the object’s `inQueueAt` field. */
  inQueueAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<PickupQueueStatusFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `completedBy` field. */
  completedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `skipCount` field. */
  skipCount?: Maybe<IntFilter>;
  /** Filter by the object’s `sessionId` field. */
  sessionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `step` field. */
  step?: Maybe<StringFilter>;
  /** Filter by the object’s `elapsedSeconds` field. */
  elapsedSeconds?: Maybe<IntFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `userByAssignedUserId` relation. */
  userByAssignedUserId?: Maybe<UserFilter>;
  /** A related `userByAssignedUserId` exists. */
  userByAssignedUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** Filter by the object’s `userByCompletedBy` relation. */
  userByCompletedBy?: Maybe<UserFilter>;
  /** A related `userByCompletedBy` exists. */
  userByCompletedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupSessionBySessionId` relation. */
  pickupSessionBySessionId?: Maybe<PickupSessionFilter>;
  /** A related `pickupSessionBySessionId` exists. */
  pickupSessionBySessionIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PickupQueueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PickupQueueFilter>>;
  /** Negates the expression. */
  not?: Maybe<PickupQueueFilter>;
};

export type PickupQueueItem = {
  __typename?: 'PickupQueueItem';
  customerId?: Maybe<Scalars['Int']>;
  customerName?: Maybe<Scalars['String']>;
  assignedUserId?: Maybe<Scalars['Int']>;
  assignedName?: Maybe<Scalars['String']>;
  isPriority?: Maybe<Scalars['Boolean']>;
  inQueueAt?: Maybe<Scalars['Datetime']>;
  skipCount?: Maybe<Scalars['Int']>;
  pickupNumber?: Maybe<Scalars['String']>;
};

/** A filter to be used against `PickupQueueItem` object types. All fields are combined with a logical ‘and.’ */
export type PickupQueueItemFilter = {
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<IntFilter>;
  /** Filter by the object’s `assignedName` field. */
  assignedName?: Maybe<StringFilter>;
  /** Filter by the object’s `isPriority` field. */
  isPriority?: Maybe<BooleanFilter>;
  /** Filter by the object’s `inQueueAt` field. */
  inQueueAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `skipCount` field. */
  skipCount?: Maybe<IntFilter>;
  /** Filter by the object’s `pickupNumber` field. */
  pickupNumber?: Maybe<StringFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PickupQueueItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PickupQueueItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<PickupQueueItemFilter>;
};

/** A connection to a list of `PickupQueueItem` values. */
export type PickupQueueItemsConnection = {
  __typename?: 'PickupQueueItemsConnection';
  /** A list of `PickupQueueItem` objects. */
  nodes: Array<PickupQueueItem>;
  /** A list of edges which contains the `PickupQueueItem` and cursor to aid in pagination. */
  edges: Array<PickupQueueItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupQueueItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupQueueItem` edge in the connection. */
export type PickupQueueItemsEdge = {
  __typename?: 'PickupQueueItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupQueueItem` at the end of the edge. */
  node: PickupQueueItem;
};

/** Represents an update to a `PickupQueue`. Fields that are set will be updated. */
export type PickupQueuePatch = {
  assignedUserId?: Maybe<Scalars['Int']>;
  inQueueAt?: Maybe<Scalars['Datetime']>;
  status?: Maybe<PickupQueueStatus>;
  customerId?: Maybe<Scalars['Int']>;
  skipCount?: Maybe<Scalars['Int']>;
  sessionId?: Maybe<Scalars['UUID']>;
  step?: Maybe<Scalars['String']>;
};

/** A connection to a list of `PickupQueuePriority` values. */
export type PickupQueuePrioritiesConnection = {
  __typename?: 'PickupQueuePrioritiesConnection';
  /** A list of `PickupQueuePriority` objects. */
  nodes: Array<PickupQueuePriority>;
  /** A list of edges which contains the `PickupQueuePriority` and cursor to aid in pagination. */
  edges: Array<PickupQueuePrioritiesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupQueuePriority` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupQueuePriority` edge in the connection. */
export type PickupQueuePrioritiesEdge = {
  __typename?: 'PickupQueuePrioritiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupQueuePriority` at the end of the edge. */
  node: PickupQueuePriority;
};

/** Methods to use when ordering `PickupQueuePriority`. */
export enum PickupQueuePrioritiesOrderBy {
  Natural = 'NATURAL',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  PriorityAtAsc = 'PRIORITY_AT_ASC',
  PriorityAtDesc = 'PRIORITY_AT_DESC'
}

export type PickupQueuePriority = {
  __typename?: 'PickupQueuePriority';
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
  priorityAt: Scalars['Datetime'];
  /** Reads a single `Location` that is related to this `PickupQueuePriority`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupQueuePriority`. */
  bidderByCustomerId?: Maybe<Bidder>;
};

/**
 * A condition to be used against `PickupQueuePriority` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PickupQueuePriorityCondition = {
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `priorityAt` field. */
  priorityAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `PickupQueuePriority` object types. All fields are combined with a logical ‘and.’ */
export type PickupQueuePriorityFilter = {
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `priorityAt` field. */
  priorityAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PickupQueuePriorityFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PickupQueuePriorityFilter>>;
  /** Negates the expression. */
  not?: Maybe<PickupQueuePriorityFilter>;
};

export enum PickupQueueStatus {
  Waiting = 'WAITING',
  WorkingOn = 'WORKING_ON',
  Completed = 'COMPLETED'
}

/** A filter to be used against PickupQueueStatus fields. All fields are combined with a logical ‘and.’ */
export type PickupQueueStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<PickupQueueStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<PickupQueueStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<PickupQueueStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<PickupQueueStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<PickupQueueStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<PickupQueueStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<PickupQueueStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<PickupQueueStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<PickupQueueStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<PickupQueueStatus>;
};

/** A connection to a list of `PickupQueue` values. */
export type PickupQueuesConnection = {
  __typename?: 'PickupQueuesConnection';
  /** A list of `PickupQueue` objects. */
  nodes: Array<PickupQueue>;
  /** A list of edges which contains the `PickupQueue` and cursor to aid in pagination. */
  edges: Array<PickupQueuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupQueue` edge in the connection. */
export type PickupQueuesEdge = {
  __typename?: 'PickupQueuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupQueue` at the end of the edge. */
  node: PickupQueue;
};

/** Methods to use when ordering `PickupQueue`. */
export enum PickupQueuesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  AssignedUserIdAsc = 'ASSIGNED_USER_ID_ASC',
  AssignedUserIdDesc = 'ASSIGNED_USER_ID_DESC',
  InQueueAtAsc = 'IN_QUEUE_AT_ASC',
  InQueueAtDesc = 'IN_QUEUE_AT_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CompletedByAsc = 'COMPLETED_BY_ASC',
  CompletedByDesc = 'COMPLETED_BY_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  SkipCountAsc = 'SKIP_COUNT_ASC',
  SkipCountDesc = 'SKIP_COUNT_DESC',
  SessionIdAsc = 'SESSION_ID_ASC',
  SessionIdDesc = 'SESSION_ID_DESC',
  StepAsc = 'STEP_ASC',
  StepDesc = 'STEP_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type PickupSchedule = Node & {
  __typename?: 'PickupSchedule';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  itemId: Scalars['Int'];
  merId?: Maybe<Scalars['String']>;
  startsAt: Scalars['Datetime'];
  endsAt: Scalars['Datetime'];
  createdAt: Scalars['Datetime'];
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Item` that is related to this `PickupSchedule`. */
  itemByItemId?: Maybe<Item>;
};

/**
 * A condition to be used against `PickupSchedule` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PickupScheduleCondition = {
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `merId` field. */
  merId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `startsAt` field. */
  startsAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `endsAt` field. */
  endsAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `PickupSchedule` object types. All fields are combined with a logical ‘and.’ */
export type PickupScheduleFilter = {
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `merId` field. */
  merId?: Maybe<StringFilter>;
  /** Filter by the object’s `startsAt` field. */
  startsAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `endsAt` field. */
  endsAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PickupScheduleFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PickupScheduleFilter>>;
  /** Negates the expression. */
  not?: Maybe<PickupScheduleFilter>;
};

/** A connection to a list of `PickupSchedule` values. */
export type PickupSchedulesConnection = {
  __typename?: 'PickupSchedulesConnection';
  /** A list of `PickupSchedule` objects. */
  nodes: Array<PickupSchedule>;
  /** A list of edges which contains the `PickupSchedule` and cursor to aid in pagination. */
  edges: Array<PickupSchedulesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupSchedule` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupSchedule` edge in the connection. */
export type PickupSchedulesEdge = {
  __typename?: 'PickupSchedulesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupSchedule` at the end of the edge. */
  node: PickupSchedule;
};

/** Methods to use when ordering `PickupSchedule`. */
export enum PickupSchedulesOrderBy {
  Natural = 'NATURAL',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  MerIdAsc = 'MER_ID_ASC',
  MerIdDesc = 'MER_ID_DESC',
  StartsAtAsc = 'STARTS_AT_ASC',
  StartsAtDesc = 'STARTS_AT_DESC',
  EndsAtAsc = 'ENDS_AT_ASC',
  EndsAtDesc = 'ENDS_AT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type PickupSession = Node & {
  __typename?: 'PickupSession';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  step: PickupSessionStep;
  isCustomerVerified?: Maybe<Scalars['Boolean']>;
  isDone: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  doneAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Location` that is related to this `PickupSession`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupSession`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupSession`. */
  userByUserId?: Maybe<User>;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesBySessionId: PickupQueuesConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPickupQueueSessionIdAndItemId: PickupSessionItemsByPickupQueueSessionIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupQueueSessionIdAndLocationId: PickupSessionLocationsByPickupQueueSessionIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueSessionIdAndAssignedUserId: PickupSessionUsersByPickupQueueSessionIdAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupQueueSessionIdAndCustomerId: PickupSessionBiddersByPickupQueueSessionIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueSessionIdAndCompletedBy: PickupSessionUsersByPickupQueueSessionIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueSessionIdAndCreatedBy: PickupSessionUsersByPickupQueueSessionIdAndCreatedByManyToManyConnection;
};


export type PickupSessionPickupQueuesBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};


export type PickupSessionItemsByPickupQueueSessionIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type PickupSessionLocationsByPickupQueueSessionIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type PickupSessionUsersByPickupQueueSessionIdAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type PickupSessionBiddersByPickupQueueSessionIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type PickupSessionUsersByPickupQueueSessionIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type PickupSessionUsersByPickupQueueSessionIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupQueue`. */
export type PickupSessionBiddersByPickupQueueSessionIdAndCustomerIdManyToManyConnection = {
  __typename?: 'PickupSessionBiddersByPickupQueueSessionIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<PickupSessionBiddersByPickupQueueSessionIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionBiddersByPickupQueueSessionIdAndCustomerIdManyToManyEdge = {
  __typename?: 'PickupSessionBiddersByPickupQueueSessionIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCustomerId: PickupQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionBiddersByPickupQueueSessionIdAndCustomerIdManyToManyEdgePickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/**
 * A condition to be used against `PickupSession` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type PickupSessionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `step` field. */
  step?: Maybe<PickupSessionStep>;
  /** Checks for equality with the object’s `isCustomerVerified` field. */
  isCustomerVerified?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isDone` field. */
  isDone?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `doneAt` field. */
  doneAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `PickupSession` object types. All fields are combined with a logical ‘and.’ */
export type PickupSessionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<IntFilter>;
  /** Filter by the object’s `step` field. */
  step?: Maybe<PickupSessionStepFilter>;
  /** Filter by the object’s `isCustomerVerified` field. */
  isCustomerVerified?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isDone` field. */
  isDone?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `doneAt` field. */
  doneAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `pickupQueuesBySessionId` relation. */
  pickupQueuesBySessionId?: Maybe<PickupSessionToManyPickupQueueFilter>;
  /** Some related `pickupQueuesBySessionId` exist. */
  pickupQueuesBySessionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** A related `userByUserId` exists. */
  userByUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PickupSessionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PickupSessionFilter>>;
  /** Negates the expression. */
  not?: Maybe<PickupSessionFilter>;
};

/** An input for mutations affecting `PickupSession` */
export type PickupSessionInput = {
  id?: Maybe<Scalars['UUID']>;
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  step?: Maybe<PickupSessionStep>;
  isCustomerVerified?: Maybe<Scalars['Boolean']>;
  isDone?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  doneAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Item` values, with data from `PickupQueue`. */
export type PickupSessionItemsByPickupQueueSessionIdAndItemIdManyToManyConnection = {
  __typename?: 'PickupSessionItemsByPickupQueueSessionIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<PickupSessionItemsByPickupQueueSessionIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionItemsByPickupQueueSessionIdAndItemIdManyToManyEdge = {
  __typename?: 'PickupSessionItemsByPickupQueueSessionIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByItemId: PickupQueuesConnection;
};


/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionItemsByPickupQueueSessionIdAndItemIdManyToManyEdgePickupQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupQueue`. */
export type PickupSessionLocationsByPickupQueueSessionIdAndLocationIdManyToManyConnection = {
  __typename?: 'PickupSessionLocationsByPickupQueueSessionIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<PickupSessionLocationsByPickupQueueSessionIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionLocationsByPickupQueueSessionIdAndLocationIdManyToManyEdge = {
  __typename?: 'PickupSessionLocationsByPickupQueueSessionIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByLocationId: PickupQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionLocationsByPickupQueueSessionIdAndLocationIdManyToManyEdgePickupQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** Represents an update to a `PickupSession`. Fields that are set will be updated. */
export type PickupSessionPatch = {
  id?: Maybe<Scalars['UUID']>;
  locationId?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
  step?: Maybe<PickupSessionStep>;
  isCustomerVerified?: Maybe<Scalars['Boolean']>;
  isDone?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  doneAt?: Maybe<Scalars['Datetime']>;
};

export enum PickupSessionStep {
  Start = 'START',
  CustVerify = 'CUST_VERIFY',
  CustNotAvailable = 'CUST_NOT_AVAILABLE',
  VerifyGood = 'VERIFY_GOOD'
}

/** A filter to be used against PickupSessionStep fields. All fields are combined with a logical ‘and.’ */
export type PickupSessionStepFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<PickupSessionStep>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<PickupSessionStep>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<PickupSessionStep>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<PickupSessionStep>;
  /** Included in the specified list. */
  in?: Maybe<Array<PickupSessionStep>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<PickupSessionStep>>;
  /** Less than the specified value. */
  lessThan?: Maybe<PickupSessionStep>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<PickupSessionStep>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<PickupSessionStep>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<PickupSessionStep>;
};

/** A filter to be used against many `PickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type PickupSessionToManyPickupQueueFilter = {
  /** Every related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupQueueFilter>;
  /** Some related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupQueueFilter>;
  /** No related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndAssignedUserIdManyToManyConnection = {
  __typename?: 'PickupSessionUsersByPickupQueueSessionIdAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<PickupSessionUsersByPickupQueueSessionIdAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndAssignedUserIdManyToManyEdge = {
  __typename?: 'PickupSessionUsersByPickupQueueSessionIdAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByAssignedUserId: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndAssignedUserIdManyToManyEdgePickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndCompletedByManyToManyConnection = {
  __typename?: 'PickupSessionUsersByPickupQueueSessionIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<PickupSessionUsersByPickupQueueSessionIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndCompletedByManyToManyEdge = {
  __typename?: 'PickupSessionUsersByPickupQueueSessionIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCompletedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndCompletedByManyToManyEdgePickupQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndCreatedByManyToManyConnection = {
  __typename?: 'PickupSessionUsersByPickupQueueSessionIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<PickupSessionUsersByPickupQueueSessionIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndCreatedByManyToManyEdge = {
  __typename?: 'PickupSessionUsersByPickupQueueSessionIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCreatedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type PickupSessionUsersByPickupQueueSessionIdAndCreatedByManyToManyEdgePickupQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `PickupSession` values. */
export type PickupSessionsConnection = {
  __typename?: 'PickupSessionsConnection';
  /** A list of `PickupSession` objects. */
  nodes: Array<PickupSession>;
  /** A list of edges which contains the `PickupSession` and cursor to aid in pagination. */
  edges: Array<PickupSessionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupSession` edge in the connection. */
export type PickupSessionsEdge = {
  __typename?: 'PickupSessionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupSession` at the end of the edge. */
  node: PickupSession;
};

/** Methods to use when ordering `PickupSession`. */
export enum PickupSessionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  StepAsc = 'STEP_ASC',
  StepDesc = 'STEP_DESC',
  IsCustomerVerifiedAsc = 'IS_CUSTOMER_VERIFIED_ASC',
  IsCustomerVerifiedDesc = 'IS_CUSTOMER_VERIFIED_DESC',
  IsDoneAsc = 'IS_DONE_ASC',
  IsDoneDesc = 'IS_DONE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  DoneAtAsc = 'DONE_AT_ASC',
  DoneAtDesc = 'DONE_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `placeItemOnHold` mutation. */
export type PlaceItemOnHoldInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  rack: Scalars['String'];
};

/** The output of our `placeItemOnHold` mutation. */
export type PlaceItemOnHoldPayload = {
  __typename?: 'PlaceItemOnHoldPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<PlaceItemOnHoldRecord>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The return type of our `placeItemOnHold` mutation. */
export type PlaceItemOnHoldRecord = {
  __typename?: 'PlaceItemOnHoldRecord';
  item?: Maybe<Item>;
  itemRackLocation?: Maybe<ItemRackLocation>;
};

/** All input for the `printInboundTruckloadLabels` mutation. */
export type PrintInboundTruckloadLabelsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  printerId: Scalars['Int'];
  truckloadNumber: Scalars['Int'];
  details: Scalars['String'];
  numberOfLabels: Scalars['Int'];
  date: Scalars['String'];
};

/** The output of our `printInboundTruckloadLabels` mutation. */
export type PrintInboundTruckloadLabelsPayload = {
  __typename?: 'PrintInboundTruckloadLabelsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `printInvoiceLabel` mutation. */
export type PrintInvoiceLabelInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  printerId: Scalars['Int'];
  invoiceId: Scalars['Int'];
};

/** The output of our `printInvoiceLabel` mutation. */
export type PrintInvoiceLabelPayload = {
  __typename?: 'PrintInvoiceLabelPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `printNotPickedUpLabels` mutation. */
export type PrintNotPickedUpLabelsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  printerId: Scalars['Int'];
  auctionId: Scalars['Int'];
  quantity: Scalars['Int'];
};

/** The output of our `printNotPickedUpLabels` mutation. */
export type PrintNotPickedUpLabelsPayload = {
  __typename?: 'PrintNotPickedUpLabelsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type Printer = Node & {
  __typename?: 'Printer';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  name: Scalars['String'];
  locationId: Scalars['Int'];
  ipAddress: Scalars['String'];
  port: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Location` that is related to this `Printer`. */
  locationByLocationId?: Maybe<Location>;
};

/** A condition to be used against `Printer` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type PrinterCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `ipAddress` field. */
  ipAddress?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `port` field. */
  port?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `Printer` object types. All fields are combined with a logical ‘and.’ */
export type PrinterFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `ipAddress` field. */
  ipAddress?: Maybe<StringFilter>;
  /** Filter by the object’s `port` field. */
  port?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<PrinterFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<PrinterFilter>>;
  /** Negates the expression. */
  not?: Maybe<PrinterFilter>;
};

/** An input for mutations affecting `Printer` */
export type PrinterInput = {
  name: Scalars['String'];
  locationId: Scalars['Int'];
  ipAddress: Scalars['String'];
  port: Scalars['Int'];
};

/** Represents an update to a `Printer`. Fields that are set will be updated. */
export type PrinterPatch = {
  name?: Maybe<Scalars['String']>;
  locationId?: Maybe<Scalars['Int']>;
  ipAddress?: Maybe<Scalars['String']>;
  port?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `Printer` values. */
export type PrintersConnection = {
  __typename?: 'PrintersConnection';
  /** A list of `Printer` objects. */
  nodes: Array<Printer>;
  /** A list of edges which contains the `Printer` and cursor to aid in pagination. */
  edges: Array<PrintersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Printer` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Printer` edge in the connection. */
export type PrintersEdge = {
  __typename?: 'PrintersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Printer` at the end of the edge. */
  node: Printer;
};

/** Methods to use when ordering `Printer`. */
export enum PrintersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  IpAddressAsc = 'IP_ADDRESS_ASC',
  IpAddressDesc = 'IP_ADDRESS_DESC',
  PortAsc = 'PORT_ASC',
  PortDesc = 'PORT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `processAuctionInvoicePayments` mutation. */
export type ProcessAuctionInvoicePaymentsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  auctionId: Scalars['Int'];
};

/** The output of our `processAuctionInvoicePayments` mutation. */
export type ProcessAuctionInvoicePaymentsPayload = {
  __typename?: 'ProcessAuctionInvoicePaymentsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  /** Reads and enables pagination through a set of `AuctionChangesView`. */
  allAuctionChangesViews?: Maybe<AuctionChangesViewsConnection>;
  /** Reads and enables pagination through a set of `AuctionInvoicesView`. */
  allAuctionInvoicesViews?: Maybe<AuctionInvoicesViewsConnection>;
  /** Reads and enables pagination through a set of `AuctionPush`. */
  allAuctionPushes?: Maybe<AuctionPushesConnection>;
  /** Reads and enables pagination through a set of `Auction`. */
  allAuctions?: Maybe<AuctionsConnection>;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  allBidderCreditHistories?: Maybe<BidderCreditHistoriesConnection>;
  /** Reads and enables pagination through a set of `Bidder`. */
  allBidders?: Maybe<BiddersConnection>;
  /** Reads and enables pagination through a set of `CustomerActionsView`. */
  allCustomerActionsViews?: Maybe<CustomerActionsViewsConnection>;
  /** Reads and enables pagination through a set of `CustomerAlertsView`. */
  allCustomerAlertsViews?: Maybe<CustomerAlertsViewsConnection>;
  /** Reads and enables pagination through a set of `Invoice`. */
  allCustomerInvoicesByCustomerId?: Maybe<InvoicesConnection>;
  /** Reads and enables pagination through a set of `CustomerNote`. */
  allCustomerNotes?: Maybe<CustomerNotesConnection>;
  /** Reads and enables pagination through a set of `EmployeeStat`. */
  allEmployeeStats?: Maybe<EmployeeStatsConnection>;
  /** Reads and enables pagination through a set of `InvoiceAdjustment`. */
  allInvoiceAdjustments?: Maybe<InvoiceAdjustmentsConnection>;
  /** Reads and enables pagination through a set of `InvoiceItemReturnHistory`. */
  allInvoiceItemReturnHistories?: Maybe<InvoiceItemReturnHistoriesConnection>;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  allInvoiceItemReturns?: Maybe<InvoiceItemReturnsConnection>;
  /** Reads and enables pagination through a set of `InvoiceNote`. */
  allInvoiceNotes?: Maybe<InvoiceNotesConnection>;
  /** Reads and enables pagination through a set of `Invoice`. */
  allInvoices?: Maybe<InvoicesConnection>;
  /** Reads and enables pagination through a set of `InvoicesSearch`. */
  allInvoicesSearches?: Maybe<InvoicesSearchesConnection>;
  /** Reads and enables pagination through a set of `ItemActionHistory`. */
  allItemActionHistories?: Maybe<ItemActionHistoriesConnection>;
  /** Reads and enables pagination through a set of `ItemImage`. */
  allItemImages?: Maybe<ItemImagesConnection>;
  /** Reads and enables pagination through a set of `ItemRackLocation`. */
  allItemRackLocations?: Maybe<ItemRackLocationsConnection>;
  /** Reads and enables pagination through a set of `Item`. */
  allItems?: Maybe<ItemsConnection>;
  /** Reads and enables pagination through a set of `JobQueueItem`. */
  allJobQueueItems?: Maybe<JobQueueItemsConnection>;
  /** Reads and enables pagination through a set of `JobQueue`. */
  allJobQueues?: Maybe<JobQueuesConnection>;
  /** Reads and enables pagination through a set of `LivePickupQueue`. */
  allLivePickupQueues?: Maybe<LivePickupQueuesConnection>;
  /** Reads and enables pagination through a set of `Location`. */
  allLocations?: Maybe<LocationsConnection>;
  /** Reads and enables pagination through a set of `MyEmployeeStat`. */
  allMyEmployeeStats?: Maybe<MyEmployeeStatsConnection>;
  /** Reads and enables pagination through a set of `Payment`. */
  allPayments?: Maybe<PaymentsConnection>;
  /** Reads and enables pagination through a set of `PaymentsLog`. */
  allPaymentsLogs?: Maybe<PaymentsLogsConnection>;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  allPicklistQueues?: Maybe<PicklistQueuesConnection>;
  /** Reads and enables pagination through a set of `PickupCustomer`. */
  allPickupCustomers?: Maybe<PickupCustomersConnection>;
  /** Reads and enables pagination through a set of `PickupQueuePriority`. */
  allPickupQueuePriorities?: Maybe<PickupQueuePrioritiesConnection>;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  allPickupQueues?: Maybe<PickupQueuesConnection>;
  /** Reads and enables pagination through a set of `PickupSchedule`. */
  allPickupSchedules?: Maybe<PickupSchedulesConnection>;
  /** Reads and enables pagination through a set of `PickupSession`. */
  allPickupSessions?: Maybe<PickupSessionsConnection>;
  /** Reads and enables pagination through a set of `Printer`. */
  allPrinters?: Maybe<PrintersConnection>;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  allReturnOptions?: Maybe<ReturnOptionsConnection>;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  allReturnQueues?: Maybe<ReturnQueuesConnection>;
  /** Reads and enables pagination through a set of `Setting`. */
  allSettings?: Maybe<SettingsConnection>;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  allShippingQueues?: Maybe<ShippingQueuesConnection>;
  /** Reads and enables pagination through a set of `Transaction`. */
  allTransactions?: Maybe<TransactionsConnection>;
  /** Reads and enables pagination through a set of `TruckloadDatum`. */
  allTruckloadData?: Maybe<TruckloadDataConnection>;
  /** Reads and enables pagination through a set of `Truckload`. */
  allTruckloads?: Maybe<TruckloadsConnection>;
  /** Reads and enables pagination through a set of `UserLocation`. */
  allUserLocations?: Maybe<UserLocationsConnection>;
  /** Reads and enables pagination through a set of `UserNotification`. */
  allUserNotifications?: Maybe<UserNotificationsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  allUsers?: Maybe<UsersConnection>;
  /** Reads a single `Auction` using its globally unique `ID`. */
  auction?: Maybe<Auction>;
  auctionByBwId?: Maybe<Auction>;
  auctionById?: Maybe<Auction>;
  auctionByInvId?: Maybe<Auction>;
  /** Reads a single `AuctionPush` using its globally unique `ID`. */
  auctionPush?: Maybe<AuctionPush>;
  auctionPushById?: Maybe<AuctionPush>;
  auth?: Maybe<Auth>;
  /** Reads a single `Bidder` using its globally unique `ID`. */
  bidder?: Maybe<Bidder>;
  bidderByBwId?: Maybe<Bidder>;
  bidderById?: Maybe<Bidder>;
  /** Reads a single `BidderCreditHistory` using its globally unique `ID`. */
  bidderCreditHistory?: Maybe<BidderCreditHistory>;
  bidderCreditHistoryById?: Maybe<BidderCreditHistory>;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  bidwranglerReturnQueue?: Maybe<ReturnQueuesConnection>;
  checkAppNewUpdate?: Maybe<CheckAppNewUpdatePayload>;
  checkItemForRelist?: Maybe<CheckItemForRelistRecord>;
  /** The currently logged in user (or null if not logged in). */
  currentUser?: Maybe<User>;
  /** Reads a single `CustomerNote` using its globally unique `ID`. */
  customerNote?: Maybe<CustomerNote>;
  customerNoteById?: Maybe<CustomerNote>;
  dashboardAnalytics?: Maybe<DashboardAnalyticsType>;
  getAllowedUserPermissions?: Maybe<GetAllowedUserPermissionsConnection>;
  getCustomerByInvoiceId?: Maybe<Bidder>;
  getCustomerInvoice?: Maybe<GetCustomerInvoiceResult>;
  getDataByInvoiceUid?: Maybe<GetDataByInvoiceUidRecord>;
  getDataBySku?: Maybe<GetDataBySkuRecord>;
  /** Reads and enables pagination through a set of `Invoice`. */
  getEligibleBatchEmailInvoices?: Maybe<InvoicesConnection>;
  /** Reads and enables pagination through a set of `Invoice`. */
  getEligibleBatchProcessInvoicePayments?: Maybe<InvoicesConnection>;
  getInvoiceByAuctionNameAndItemNumber?: Maybe<GetInvoiceByAuctionNameAndItemNumberRecord>;
  getInvoiceFromMyPickupByAuctionNameAndItemNumber?: Maybe<GetInvoiceFromMyPickupByAuctionNameAndItemNumberRecord>;
  getItemHistory?: Maybe<GetItemHistoryConnection>;
  getItemReturnDetails?: Maybe<GetItemReturnDetailsRecord>;
  getLastPickupDateConfigForAuction?: Maybe<Scalars['Int']>;
  getTruckloadData?: Maybe<TruckloadDatum>;
  getUserLocation?: Maybe<GetUserLocationRecord>;
  /** Reads a single `Invoice` using its globally unique `ID`. */
  invoice?: Maybe<Invoice>;
  /** Reads a single `InvoiceAdjustment` using its globally unique `ID`. */
  invoiceAdjustment?: Maybe<InvoiceAdjustment>;
  invoiceAdjustmentById?: Maybe<InvoiceAdjustment>;
  invoiceByAuctionIdAndBidderIdAndLocationId?: Maybe<Invoice>;
  invoiceById?: Maybe<Invoice>;
  invoiceByPublicToken?: Maybe<Invoice>;
  invoiceByUid?: Maybe<Invoice>;
  /** Reads a single `InvoiceItemReturn` using its globally unique `ID`. */
  invoiceItemReturn?: Maybe<InvoiceItemReturn>;
  invoiceItemReturnById?: Maybe<InvoiceItemReturn>;
  /** Reads a single `InvoiceItemReturnHistory` using its globally unique `ID`. */
  invoiceItemReturnHistory?: Maybe<InvoiceItemReturnHistory>;
  invoiceItemReturnHistoryById?: Maybe<InvoiceItemReturnHistory>;
  /** Reads a single `InvoiceNote` using its globally unique `ID`. */
  invoiceNote?: Maybe<InvoiceNote>;
  invoiceNoteById?: Maybe<InvoiceNote>;
  /** Reads a single `InvoicesSearch` using its globally unique `ID`. */
  invoicesSearch?: Maybe<InvoicesSearch>;
  invoicesSearchByInvoiceIdAndBidderIdAndAuctionId?: Maybe<InvoicesSearch>;
  /** Reads a single `Item` using its globally unique `ID`. */
  item?: Maybe<Item>;
  /** Reads a single `ItemActionHistory` using its globally unique `ID`. */
  itemActionHistory?: Maybe<ItemActionHistory>;
  itemActionHistoryById?: Maybe<ItemActionHistory>;
  itemByBwId?: Maybe<Item>;
  itemById?: Maybe<Item>;
  /** Reads a single `ItemImage` using its globally unique `ID`. */
  itemImage?: Maybe<ItemImage>;
  itemImageById?: Maybe<ItemImage>;
  /** Reads a single `ItemRackLocation` using its globally unique `ID`. */
  itemRackLocation?: Maybe<ItemRackLocation>;
  itemRackLocationByItemId?: Maybe<ItemRackLocation>;
  /** Reads a single `JobQueue` using its globally unique `ID`. */
  jobQueue?: Maybe<JobQueue>;
  jobQueueById?: Maybe<JobQueue>;
  /** Reads a single `JobQueueItem` using its globally unique `ID`. */
  jobQueueItem?: Maybe<JobQueueItem>;
  jobQueueItemById?: Maybe<JobQueueItem>;
  /** Reads a single `Location` using its globally unique `ID`. */
  location?: Maybe<Location>;
  locationById?: Maybe<Location>;
  locationByInvoicePrefix?: Maybe<Location>;
  locationByName?: Maybe<Location>;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  mercuryReturnQueue?: Maybe<ReturnQueuesConnection>;
  /** Reads and enables pagination through a set of `MissingRackLocationListResult`. */
  missingRackLocationList?: Maybe<MissingRackLocationListResultsConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID'];
  /** Reads a single `Payment` using its globally unique `ID`. */
  payment?: Maybe<Payment>;
  paymentById?: Maybe<Payment>;
  /** Reads a single `PaymentsLog` using its globally unique `ID`. */
  paymentsLog?: Maybe<PaymentsLog>;
  paymentsLogById?: Maybe<PaymentsLog>;
  /** Reads and enables pagination through a set of `PicklistQueueItem`. */
  picklistAssignedItems?: Maybe<PicklistQueueItemsConnection>;
  /** Reads and enables pagination through a set of `PicklistAvailableQueueType`. */
  picklistAvailableQueue?: Maybe<PicklistAvailableQueueTypesConnection>;
  picklistAvailableQueueCount?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `PicklistMyQueueType`. */
  picklistMyQueue?: Maybe<PicklistMyQueueTypesConnection>;
  /** Reads a single `PicklistQueue` using its globally unique `ID`. */
  picklistQueue?: Maybe<PicklistQueue>;
  picklistQueueById?: Maybe<PicklistQueue>;
  picklistQueueByItemId?: Maybe<PicklistQueue>;
  picklistQueueCounts?: Maybe<PicklistQueueCountsRecord>;
  /** Reads and enables pagination through a set of `PicklistQueueItem`. */
  picklistUnassignedItems?: Maybe<PicklistQueueItemsConnection>;
  /** Reads and enables pagination through a set of `PickupQueueItem`. */
  pickupAssignedItems?: Maybe<PickupQueueItemsConnection>;
  /** Reads a single `PickupCustomer` using its globally unique `ID`. */
  pickupCustomer?: Maybe<PickupCustomer>;
  pickupCustomerById?: Maybe<PickupCustomer>;
  pickupCustomerByLocationIdAndCustomerId?: Maybe<PickupCustomer>;
  pickupCustomerByLocationIdAndNumber?: Maybe<PickupCustomer>;
  pickupMyQueue?: Maybe<PickupMyQueueResult>;
  /** Reads a single `PickupQueue` using its globally unique `ID`. */
  pickupQueue?: Maybe<PickupQueue>;
  pickupQueueAvailable?: Maybe<PickupQueueAvailableResult>;
  pickupQueueById?: Maybe<PickupQueue>;
  pickupQueuePriorityByLocationIdAndCustomerId?: Maybe<PickupQueuePriority>;
  /** Reads a single `PickupSchedule` using its globally unique `ID`. */
  pickupSchedule?: Maybe<PickupSchedule>;
  pickupScheduleByItemId?: Maybe<PickupSchedule>;
  /** Reads a single `PickupSession` using its globally unique `ID`. */
  pickupSession?: Maybe<PickupSession>;
  pickupSessionById?: Maybe<PickupSession>;
  /** Reads and enables pagination through a set of `PickupQueueItem`. */
  pickupUnassignedItems?: Maybe<PickupQueueItemsConnection>;
  /** Reads a single `Printer` using its globally unique `ID`. */
  printer?: Maybe<Printer>;
  printerById?: Maybe<Printer>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads and enables pagination through a set of `RackLocationListResult`. */
  rackLocationList?: Maybe<RackLocationListResultsConnection>;
  /** Reads a single `ReturnOption` using its globally unique `ID`. */
  returnOption?: Maybe<ReturnOption>;
  returnOptionById?: Maybe<ReturnOption>;
  /** Reads a single `ReturnQueue` using its globally unique `ID`. */
  returnQueue?: Maybe<ReturnQueue>;
  returnQueueById?: Maybe<ReturnQueue>;
  runAuctionSummaryReport?: Maybe<RunAuctionSummaryReportConnection>;
  runCompanyReturnRateReport?: Maybe<RunCompanyReturnRateReportConnection>;
  runItemSoldReport?: Maybe<RunItemSoldReportConnection>;
  runReturnReportEmployeeReturnRates?: Maybe<RunReturnReportEmployeeReturnRatesConnection>;
  runReturnReportPie?: Maybe<RunReturnReportPieConnection>;
  runTaxReport?: Maybe<RunTaxReportConnection>;
  /** Reads and enables pagination through a set of `QueueItemsByBidder`. */
  runnerQueue?: Maybe<QueueItemsByBiddersConnection>;
  /** Reads a single `Setting` using its globally unique `ID`. */
  setting?: Maybe<Setting>;
  settingByName?: Maybe<Setting>;
  /** Reads and enables pagination through a set of `ShippingQueueItem`. */
  shippingAssignedItems?: Maybe<ShippingQueueItemsConnection>;
  /** Reads and enables pagination through a set of `ShippingAvailableQueueType`. */
  shippingAvailableQueue?: Maybe<ShippingAvailableQueueTypesConnection>;
  shippingAvailableQueueCount?: Maybe<Scalars['Int']>;
  /** Reads and enables pagination through a set of `ShippingMyQueueType`. */
  shippingMyQueue?: Maybe<ShippingMyQueueTypesConnection>;
  /** Reads a single `ShippingQueue` using its globally unique `ID`. */
  shippingQueue?: Maybe<ShippingQueue>;
  shippingQueueById?: Maybe<ShippingQueue>;
  shippingQueueByItemId?: Maybe<ShippingQueue>;
  /** Reads and enables pagination through a set of `ShippingQueueItem`. */
  shippingUnassignedItems?: Maybe<ShippingQueueItemsConnection>;
  totalActiveReturnQueue?: Maybe<Scalars['Int']>;
  totalAuctionChanges?: Maybe<Scalars['Int']>;
  totalAuctionUnpaidInvoices?: Maybe<Scalars['Int']>;
  totalSalesLastMonth?: Maybe<TotalSalesLastMonthConnection>;
  /** Reads a single `Transaction` using its globally unique `ID`. */
  transaction?: Maybe<Transaction>;
  transactionById?: Maybe<Transaction>;
  /** Reads a single `Truckload` using its globally unique `ID`. */
  truckload?: Maybe<Truckload>;
  truckloadById?: Maybe<Truckload>;
  /** Reads a single `TruckloadDatum` using its globally unique `ID`. */
  truckloadDatum?: Maybe<TruckloadDatum>;
  truckloadDatumById?: Maybe<TruckloadDatum>;
  /** Reads a single `User` using its globally unique `ID`. */
  user?: Maybe<User>;
  userById?: Maybe<User>;
  userByUsername?: Maybe<User>;
  /** Reads a single `UserLocation` using its globally unique `ID`. */
  userLocation?: Maybe<UserLocation>;
  userLocationByUserId?: Maybe<UserLocation>;
  /** Reads a single `UserNotification` using its globally unique `ID`. */
  userNotification?: Maybe<UserNotification>;
  userNotificationById?: Maybe<UserNotification>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAuctionChangesViewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionChangesViewsOrderBy>>;
  condition?: Maybe<AuctionChangesViewCondition>;
  filter?: Maybe<AuctionChangesViewFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAuctionInvoicesViewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy>>;
  condition?: Maybe<AuctionInvoicesViewCondition>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAuctionPushesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionPushesOrderBy>>;
  condition?: Maybe<AuctionPushCondition>;
  filter?: Maybe<AuctionPushFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllAuctionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllBidderCreditHistoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllBiddersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCustomerActionsViewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerActionsViewsOrderBy>>;
  condition?: Maybe<CustomerActionsViewCondition>;
  filter?: Maybe<CustomerActionsViewFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCustomerAlertsViewsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerAlertsViewsOrderBy>>;
  condition?: Maybe<CustomerAlertsViewCondition>;
  filter?: Maybe<CustomerAlertsViewFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCustomerInvoicesByCustomerIdArgs = {
  customerId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<InvoiceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllCustomerNotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerNotesOrderBy>>;
  condition?: Maybe<CustomerNoteCondition>;
  filter?: Maybe<CustomerNoteFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllEmployeeStatsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<EmployeeStatsOrderBy>>;
  condition?: Maybe<EmployeeStatCondition>;
  filter?: Maybe<EmployeeStatFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllInvoiceAdjustmentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceAdjustmentsOrderBy>>;
  condition?: Maybe<InvoiceAdjustmentCondition>;
  filter?: Maybe<InvoiceAdjustmentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllInvoiceItemReturnHistoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnHistoriesOrderBy>>;
  condition?: Maybe<InvoiceItemReturnHistoryCondition>;
  filter?: Maybe<InvoiceItemReturnHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllInvoiceItemReturnsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllInvoiceNotesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceNotesOrderBy>>;
  condition?: Maybe<InvoiceNoteCondition>;
  filter?: Maybe<InvoiceNoteFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllInvoicesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllInvoicesSearchesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesSearchesOrderBy>>;
  condition?: Maybe<InvoicesSearchCondition>;
  filter?: Maybe<InvoicesSearchFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllItemActionHistoriesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemActionHistoriesOrderBy>>;
  condition?: Maybe<ItemActionHistoryCondition>;
  filter?: Maybe<ItemActionHistoryFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllItemImagesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemImagesOrderBy>>;
  condition?: Maybe<ItemImageCondition>;
  filter?: Maybe<ItemImageFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllItemRackLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemRackLocationsOrderBy>>;
  condition?: Maybe<ItemRackLocationCondition>;
  filter?: Maybe<ItemRackLocationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllJobQueueItemsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<JobQueueItemsOrderBy>>;
  condition?: Maybe<JobQueueItemCondition>;
  filter?: Maybe<JobQueueItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllJobQueuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<JobQueuesOrderBy>>;
  condition?: Maybe<JobQueueCondition>;
  filter?: Maybe<JobQueueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLivePickupQueuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LivePickupQueuesOrderBy>>;
  condition?: Maybe<LivePickupQueueCondition>;
  filter?: Maybe<LivePickupQueueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllMyEmployeeStatsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<MyEmployeeStatsOrderBy>>;
  condition?: Maybe<MyEmployeeStatCondition>;
  filter?: Maybe<MyEmployeeStatFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy>>;
  condition?: Maybe<PaymentCondition>;
  filter?: Maybe<PaymentFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPaymentsLogsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsLogsOrderBy>>;
  condition?: Maybe<PaymentsLogCondition>;
  filter?: Maybe<PaymentsLogFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPicklistQueuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPickupCustomersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
  condition?: Maybe<PickupCustomerCondition>;
  filter?: Maybe<PickupCustomerFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPickupQueuePrioritiesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuePrioritiesOrderBy>>;
  condition?: Maybe<PickupQueuePriorityCondition>;
  filter?: Maybe<PickupQueuePriorityFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPickupQueuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPickupSchedulesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSchedulesOrderBy>>;
  condition?: Maybe<PickupScheduleCondition>;
  filter?: Maybe<PickupScheduleFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPickupSessionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllPrintersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PrintersOrderBy>>;
  condition?: Maybe<PrinterCondition>;
  filter?: Maybe<PrinterFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReturnOptionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllReturnQueuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllSettingsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<SettingsOrderBy>>;
  condition?: Maybe<SettingCondition>;
  filter?: Maybe<SettingFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllShippingQueuesArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTransactionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TransactionsOrderBy>>;
  condition?: Maybe<TransactionCondition>;
  filter?: Maybe<TransactionFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTruckloadDataArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TruckloadDataOrderBy>>;
  condition?: Maybe<TruckloadDatumCondition>;
  filter?: Maybe<TruckloadDatumFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllTruckloadsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TruckloadsOrderBy>>;
  condition?: Maybe<TruckloadCondition>;
  filter?: Maybe<TruckloadFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUserLocationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserLocationsOrderBy>>;
  condition?: Maybe<UserLocationCondition>;
  filter?: Maybe<UserLocationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUserNotificationsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserNotificationsOrderBy>>;
  condition?: Maybe<UserNotificationCondition>;
  filter?: Maybe<UserNotificationFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAllUsersArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryAuctionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuctionByBwIdArgs = {
  bwId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuctionByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuctionByInvIdArgs = {
  invId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuctionPushArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuctionPushByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryAuthArgs = {
  test?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryBidderArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBidderByBwIdArgs = {
  bwId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBidderByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBidderCreditHistoryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBidderCreditHistoryByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryBidwranglerReturnQueueArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  filter?: Maybe<ReturnQueueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckAppNewUpdateArgs = {
  input: CheckAppNewUpdateInput;
};


/** The root query type which gives access points into the data universe. */
export type QueryCheckItemForRelistArgs = {
  auctionName: Scalars['String'];
  itemNumber: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerNoteArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryCustomerNoteByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetAllowedUserPermissionsArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCustomerByInvoiceIdArgs = {
  invoiceId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetCustomerInvoiceArgs = {
  id: Scalars['String'];
  token: Scalars['String'];
  elevatedToken?: Maybe<Scalars['String']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetDataByInvoiceUidArgs = {
  uid: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetDataBySkuArgs = {
  sku: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetEligibleBatchEmailInvoicesArgs = {
  invoiceIds: Array<Maybe<Scalars['Int']>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<InvoiceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetEligibleBatchProcessInvoicePaymentsArgs = {
  invoiceIds: Array<Maybe<Scalars['Int']>>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<InvoiceFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetInvoiceByAuctionNameAndItemNumberArgs = {
  auctionName: Scalars['String'];
  itemNumber: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetInvoiceFromMyPickupByAuctionNameAndItemNumberArgs = {
  auctionName: Scalars['String'];
  itemNumber: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetItemHistoryArgs = {
  itemId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<GetItemHistoryRecordFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetItemReturnDetailsArgs = {
  itemId: Scalars['Int'];
  returnQueueId?: Maybe<Scalars['UUID']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryGetLastPickupDateConfigForAuctionArgs = {
  ts: Scalars['Datetime'];
  locationId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryGetTruckloadDataArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceAdjustmentArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceAdjustmentByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceByAuctionIdAndBidderIdAndLocationIdArgs = {
  auctionId: Scalars['Int'];
  bidderId: Scalars['Int'];
  locationId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceByPublicTokenArgs = {
  publicToken: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceByUidArgs = {
  uid: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceItemReturnArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceItemReturnByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceItemReturnHistoryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceItemReturnHistoryByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceNoteArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoiceNoteByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoicesSearchArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryInvoicesSearchByInvoiceIdAndBidderIdAndAuctionIdArgs = {
  invoiceId: Scalars['Int'];
  bidderId: Scalars['Int'];
  auctionId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemActionHistoryArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemActionHistoryByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemByBwIdArgs = {
  bwId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemImageArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemImageByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemRackLocationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryItemRackLocationByItemIdArgs = {
  itemId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJobQueueArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJobQueueByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJobQueueItemArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryJobQueueItemByIdArgs = {
  id: Scalars['BigInt'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationByInvoicePrefixArgs = {
  invoicePrefix: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryLocationByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryMercuryReturnQueueArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  filter?: Maybe<ReturnQueueFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryMissingRackLocationListArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<MissingRackLocationListResultFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentsLogArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPaymentsLogByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPicklistAssignedItemsArgs = {
  locationId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<PicklistQueueItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPicklistAvailableQueueArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<PicklistAvailableQueueTypeFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPicklistMyQueueArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPicklistQueueArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPicklistQueueByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPicklistQueueByItemIdArgs = {
  itemId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPicklistQueueCountsArgs = {
  locationId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPicklistUnassignedItemsArgs = {
  locationId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<PicklistQueueItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupAssignedItemsArgs = {
  locationId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<PickupQueueItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupCustomerArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupCustomerByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupCustomerByLocationIdAndCustomerIdArgs = {
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupCustomerByLocationIdAndNumberArgs = {
  locationId: Scalars['Int'];
  number: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupQueueArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupQueueByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupQueuePriorityByLocationIdAndCustomerIdArgs = {
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupScheduleArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupScheduleByItemIdArgs = {
  itemId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupSessionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupSessionByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPickupUnassignedItemsArgs = {
  locationId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<PickupQueueItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryPrinterArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryPrinterByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRackLocationListArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RackLocationListResultFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryReturnOptionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReturnOptionByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReturnQueueArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryReturnQueueByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryRunAuctionSummaryReportArgs = {
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RunAuctionSummaryReportRecordFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRunCompanyReturnRateReportArgs = {
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
  groupBy: ReportGroupBy;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RunCompanyReturnRateReportRecordFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRunItemSoldReportArgs = {
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
  groupBy: ReportGroupBy;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RunItemSoldReportRecordFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRunReturnReportEmployeeReturnRatesArgs = {
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RunReturnReportEmployeeReturnRatesRecordFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRunReturnReportPieArgs = {
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RunReturnReportPieRecordFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRunTaxReportArgs = {
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RunTaxReportRecordFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryRunnerQueueArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QuerySettingArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QuerySettingByNameArgs = {
  name: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryShippingAssignedItemsArgs = {
  locationId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<ShippingQueueItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryShippingAvailableQueueArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<ShippingAvailableQueueTypeFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryShippingMyQueueArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
};


/** The root query type which gives access points into the data universe. */
export type QueryShippingQueueArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryShippingQueueByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryShippingQueueByItemIdArgs = {
  itemId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryShippingUnassignedItemsArgs = {
  locationId: Scalars['Int'];
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<ShippingQueueItemFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTotalAuctionUnpaidInvoicesArgs = {
  auctionId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTotalSalesLastMonthArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<TotalSalesLastMonthRecordFilter>;
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTransactionByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTruckloadArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTruckloadByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTruckloadDatumArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryTruckloadDatumByIdArgs = {
  id: Scalars['UUID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByIdArgs = {
  id: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserByUsernameArgs = {
  username: Scalars['String'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserLocationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserLocationByUserIdArgs = {
  userId: Scalars['Int'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserNotificationArgs = {
  nodeId: Scalars['ID'];
};


/** The root query type which gives access points into the data universe. */
export type QueryUserNotificationByIdArgs = {
  id: Scalars['Int'];
};

export type QueueItemsByBidder = {
  __typename?: 'QueueItemsByBidder';
  bidder?: Maybe<Bidder>;
  auction?: Maybe<Array<Maybe<Auction>>>;
  pickupQueue?: Maybe<Array<Maybe<PickupQueue>>>;
};

/** A connection to a list of `QueueItemsByBidder` values. */
export type QueueItemsByBiddersConnection = {
  __typename?: 'QueueItemsByBiddersConnection';
  /** A list of `QueueItemsByBidder` objects. */
  nodes: Array<QueueItemsByBidder>;
  /** A list of edges which contains the `QueueItemsByBidder` and cursor to aid in pagination. */
  edges: Array<QueueItemsByBiddersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `QueueItemsByBidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `QueueItemsByBidder` edge in the connection. */
export type QueueItemsByBiddersEdge = {
  __typename?: 'QueueItemsByBiddersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `QueueItemsByBidder` at the end of the edge. */
  node: QueueItemsByBidder;
};

export enum QueuedProcessingType {
  Queued = 'QUEUED',
  Processing = 'PROCESSING',
  Processed = 'PROCESSED'
}

/** A filter to be used against QueuedProcessingType fields. All fields are combined with a logical ‘and.’ */
export type QueuedProcessingTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<QueuedProcessingType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<QueuedProcessingType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<QueuedProcessingType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<QueuedProcessingType>;
  /** Included in the specified list. */
  in?: Maybe<Array<QueuedProcessingType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<QueuedProcessingType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<QueuedProcessingType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<QueuedProcessingType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<QueuedProcessingType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<QueuedProcessingType>;
};

export type RackLocationListResult = {
  __typename?: 'RackLocationListResult';
  rackLocation?: Maybe<Scalars['String']>;
  itemCount?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `RackLocationListResult` object types. All fields are combined with a logical ‘and.’ */
export type RackLocationListResultFilter = {
  /** Filter by the object’s `rackLocation` field. */
  rackLocation?: Maybe<StringFilter>;
  /** Filter by the object’s `itemCount` field. */
  itemCount?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RackLocationListResultFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RackLocationListResultFilter>>;
  /** Negates the expression. */
  not?: Maybe<RackLocationListResultFilter>;
};

/** A connection to a list of `RackLocationListResult` values. */
export type RackLocationListResultsConnection = {
  __typename?: 'RackLocationListResultsConnection';
  /** A list of `RackLocationListResult` objects. */
  nodes: Array<RackLocationListResult>;
  /** A list of edges which contains the `RackLocationListResult` and cursor to aid in pagination. */
  edges: Array<RackLocationListResultsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `RackLocationListResult` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RackLocationListResult` edge in the connection. */
export type RackLocationListResultsEdge = {
  __typename?: 'RackLocationListResultsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RackLocationListResult` at the end of the edge. */
  node: RackLocationListResult;
};

/** All input for the `relistItem` mutation. */
export type RelistItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
};

/** The output of our `relistItem` mutation. */
export type RelistItemPayload = {
  __typename?: 'RelistItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  item?: Maybe<Item>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Item`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `Item`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Payment` that is related to this `Item`. */
  paymentByPaymentId?: Maybe<Payment>;
  /** Reads a single `Bidder` that is related to this `Item`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Item`. */
  userByDeletedFromInvoiceBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userBySetAsideBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userByIsDeletedBy?: Maybe<User>;
  /** An edge for our `Item`. May be used by Relay 1. */
  itemEdge?: Maybe<ItemsEdge>;
};


/** The output of our `relistItem` mutation. */
export type RelistItemPayloadItemEdgeArgs = {
  orderBy?: Maybe<Array<ItemsOrderBy>>;
};

/** All input for the `removeItemTax` mutation. */
export type RemoveItemTaxInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
};

/** The output of our `removeItemTax` mutation. */
export type RemoveItemTaxPayload = {
  __typename?: 'RemoveItemTaxPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  item?: Maybe<Item>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Item`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `Item`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Payment` that is related to this `Item`. */
  paymentByPaymentId?: Maybe<Payment>;
  /** Reads a single `Bidder` that is related to this `Item`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Item`. */
  userByDeletedFromInvoiceBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userBySetAsideBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userByIsDeletedBy?: Maybe<User>;
  /** An edge for our `Item`. May be used by Relay 1. */
  itemEdge?: Maybe<ItemsEdge>;
};


/** The output of our `removeItemTax` mutation. */
export type RemoveItemTaxPayloadItemEdgeArgs = {
  orderBy?: Maybe<Array<ItemsOrderBy>>;
};

/** All input for the `removePrimaryImageItem` mutation. */
export type RemovePrimaryImageItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  image: Scalars['String'];
};

/** The output of our `removePrimaryImageItem` mutation. */
export type RemovePrimaryImageItemPayload = {
  __typename?: 'RemovePrimaryImageItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  item?: Maybe<Item>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Item`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `Item`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Payment` that is related to this `Item`. */
  paymentByPaymentId?: Maybe<Payment>;
  /** Reads a single `Bidder` that is related to this `Item`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Item`. */
  userByDeletedFromInvoiceBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userBySetAsideBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userByIsDeletedBy?: Maybe<User>;
  /** An edge for our `Item`. May be used by Relay 1. */
  itemEdge?: Maybe<ItemsEdge>;
};


/** The output of our `removePrimaryImageItem` mutation. */
export type RemovePrimaryImageItemPayloadItemEdgeArgs = {
  orderBy?: Maybe<Array<ItemsOrderBy>>;
};

export enum ReportGroupBy {
  Day = 'DAY',
  Week = 'WEEK',
  Month = 'MONTH'
}

/** All input for the `reprintTruckload` mutation. */
export type ReprintTruckloadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  truckId: Scalars['UUID'];
  numOfLabels: Scalars['Int'];
  printerId: Scalars['Int'];
};

/** The output of our `reprintTruckload` mutation. */
export type ReprintTruckloadPayload = {
  __typename?: 'ReprintTruckloadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  boolean?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `resortItemImages` mutation. */
export type ResortItemImagesInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  images: Array<Maybe<ResortItemImagesTypeInput>>;
};

/** The output of our `resortItemImages` mutation. */
export type ResortItemImagesPayload = {
  __typename?: 'ResortItemImagesPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  resortResult?: Maybe<ResortResult>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** An input for mutations affecting `ResortItemImagesType` */
export type ResortItemImagesTypeInput = {
  itemImageId?: Maybe<Scalars['Int']>;
  sort?: Maybe<Scalars['Int']>;
};

export type ResortResult = {
  __typename?: 'ResortResult';
  success?: Maybe<Scalars['Boolean']>;
};

/** All input for the `resyncCustomerFromBidwrangler` mutation. */
export type ResyncCustomerFromBidwranglerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  customerBwId: Scalars['Int'];
};

/** The output of our `resyncCustomerFromBidwrangler` mutation. */
export type ResyncCustomerFromBidwranglerPayload = {
  __typename?: 'ResyncCustomerFromBidwranglerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type ReturnOption = Node & {
  __typename?: 'ReturnOption';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  name: Scalars['String'];
  description: Scalars['String'];
  requireNote: Scalars['Boolean'];
  accessSupport: Scalars['Boolean'];
  accessApp: Scalars['Boolean'];
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  countAgainstUser: Scalars['Boolean'];
  countAgainstCompany: Scalars['Boolean'];
  merMapReason: Scalars['String'];
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByOptionId: InvoiceItemReturnsConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByOptionId: ReturnQueuesConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceItemReturnOptionIdAndInvoiceItemId: ReturnOptionItemsByInvoiceItemReturnOptionIdAndInvoiceItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnOptionIdAndCreatedBy: ReturnOptionUsersByInvoiceItemReturnOptionIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnOptionIdAndUpdatedBy: ReturnOptionUsersByInvoiceItemReturnOptionIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByInvoiceItemReturnOptionIdAndQueueId: ReturnOptionReturnQueuesByInvoiceItemReturnOptionIdAndQueueIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByReturnQueueOptionIdAndItemId: ReturnOptionItemsByReturnQueueOptionIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueOptionIdAndCreatedBy: ReturnOptionUsersByReturnQueueOptionIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueOptionIdAndCompletedBy: ReturnOptionUsersByReturnQueueOptionIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueOptionIdAndUpdatedBy: ReturnOptionUsersByReturnQueueOptionIdAndUpdatedByManyToManyConnection;
};


export type ReturnOptionInvoiceItemReturnsByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};


export type ReturnOptionReturnQueuesByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type ReturnOptionItemsByInvoiceItemReturnOptionIdAndInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type ReturnOptionUsersByInvoiceItemReturnOptionIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ReturnOptionUsersByInvoiceItemReturnOptionIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ReturnOptionReturnQueuesByInvoiceItemReturnOptionIdAndQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type ReturnOptionItemsByReturnQueueOptionIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type ReturnOptionUsersByReturnQueueOptionIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ReturnOptionUsersByReturnQueueOptionIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ReturnOptionUsersByReturnQueueOptionIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

/**
 * A condition to be used against `ReturnOption` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ReturnOptionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `description` field. */
  description?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `requireNote` field. */
  requireNote?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `accessSupport` field. */
  accessSupport?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `accessApp` field. */
  accessApp?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `countAgainstUser` field. */
  countAgainstUser?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `countAgainstCompany` field. */
  countAgainstCompany?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `merMapReason` field. */
  merMapReason?: Maybe<Scalars['String']>;
};

/** A filter to be used against `ReturnOption` object types. All fields are combined with a logical ‘and.’ */
export type ReturnOptionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: Maybe<StringFilter>;
  /** Filter by the object’s `requireNote` field. */
  requireNote?: Maybe<BooleanFilter>;
  /** Filter by the object’s `accessSupport` field. */
  accessSupport?: Maybe<BooleanFilter>;
  /** Filter by the object’s `accessApp` field. */
  accessApp?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `countAgainstUser` field. */
  countAgainstUser?: Maybe<BooleanFilter>;
  /** Filter by the object’s `countAgainstCompany` field. */
  countAgainstCompany?: Maybe<BooleanFilter>;
  /** Filter by the object’s `merMapReason` field. */
  merMapReason?: Maybe<StringFilter>;
  /** Filter by the object’s `invoiceItemReturnsByOptionId` relation. */
  invoiceItemReturnsByOptionId?: Maybe<ReturnOptionToManyInvoiceItemReturnFilter>;
  /** Some related `invoiceItemReturnsByOptionId` exist. */
  invoiceItemReturnsByOptionIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `returnQueuesByOptionId` relation. */
  returnQueuesByOptionId?: Maybe<ReturnOptionToManyReturnQueueFilter>;
  /** Some related `returnQueuesByOptionId` exist. */
  returnQueuesByOptionIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReturnOptionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReturnOptionFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReturnOptionFilter>;
};

/** An input for mutations affecting `ReturnOption` */
export type ReturnOptionInput = {
  id?: Maybe<Scalars['UUID']>;
  name: Scalars['String'];
  description: Scalars['String'];
  requireNote?: Maybe<Scalars['Boolean']>;
  accessSupport?: Maybe<Scalars['Boolean']>;
  accessApp?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  countAgainstUser?: Maybe<Scalars['Boolean']>;
  countAgainstCompany?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `Item` values, with data from `InvoiceItemReturn`. */
export type ReturnOptionItemsByInvoiceItemReturnOptionIdAndInvoiceItemIdManyToManyConnection = {
  __typename?: 'ReturnOptionItemsByInvoiceItemReturnOptionIdAndInvoiceItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ReturnOptionItemsByInvoiceItemReturnOptionIdAndInvoiceItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnOptionItemsByInvoiceItemReturnOptionIdAndInvoiceItemIdManyToManyEdge = {
  __typename?: 'ReturnOptionItemsByInvoiceItemReturnOptionIdAndInvoiceItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByInvoiceItemId: InvoiceItemReturnsConnection;
};


/** A `Item` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnOptionItemsByInvoiceItemReturnOptionIdAndInvoiceItemIdManyToManyEdgeInvoiceItemReturnsByInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `Item` values, with data from `ReturnQueue`. */
export type ReturnOptionItemsByReturnQueueOptionIdAndItemIdManyToManyConnection = {
  __typename?: 'ReturnOptionItemsByReturnQueueOptionIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<ReturnOptionItemsByReturnQueueOptionIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `ReturnQueue`. */
export type ReturnOptionItemsByReturnQueueOptionIdAndItemIdManyToManyEdge = {
  __typename?: 'ReturnOptionItemsByReturnQueueOptionIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByItemId: ReturnQueuesConnection;
};


/** A `Item` edge in the connection, with data from `ReturnQueue`. */
export type ReturnOptionItemsByReturnQueueOptionIdAndItemIdManyToManyEdgeReturnQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** Represents an update to a `ReturnOption`. Fields that are set will be updated. */
export type ReturnOptionPatch = {
  id?: Maybe<Scalars['UUID']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  requireNote?: Maybe<Scalars['Boolean']>;
  accessSupport?: Maybe<Scalars['Boolean']>;
  accessApp?: Maybe<Scalars['Boolean']>;
  isActive?: Maybe<Scalars['Boolean']>;
  countAgainstUser?: Maybe<Scalars['Boolean']>;
  countAgainstCompany?: Maybe<Scalars['Boolean']>;
};

/** A connection to a list of `ReturnQueue` values, with data from `InvoiceItemReturn`. */
export type ReturnOptionReturnQueuesByInvoiceItemReturnOptionIdAndQueueIdManyToManyConnection = {
  __typename?: 'ReturnOptionReturnQueuesByInvoiceItemReturnOptionIdAndQueueIdManyToManyConnection';
  /** A list of `ReturnQueue` objects. */
  nodes: Array<ReturnQueue>;
  /** A list of edges which contains the `ReturnQueue`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ReturnOptionReturnQueuesByInvoiceItemReturnOptionIdAndQueueIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnQueue` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnOptionReturnQueuesByInvoiceItemReturnOptionIdAndQueueIdManyToManyEdge = {
  __typename?: 'ReturnOptionReturnQueuesByInvoiceItemReturnOptionIdAndQueueIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnQueue` at the end of the edge. */
  node: ReturnQueue;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByQueueId: InvoiceItemReturnsConnection;
};


/** A `ReturnQueue` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnOptionReturnQueuesByInvoiceItemReturnOptionIdAndQueueIdManyToManyEdgeInvoiceItemReturnsByQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A filter to be used against many `InvoiceItemReturn` object types. All fields are combined with a logical ‘and.’ */
export type ReturnOptionToManyInvoiceItemReturnFilter = {
  /** Every related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceItemReturnFilter>;
  /** Some related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceItemReturnFilter>;
  /** No related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceItemReturnFilter>;
};

/** A filter to be used against many `ReturnQueue` object types. All fields are combined with a logical ‘and.’ */
export type ReturnOptionToManyReturnQueueFilter = {
  /** Every related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ReturnQueueFilter>;
  /** Some related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ReturnQueueFilter>;
  /** No related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturn`. */
export type ReturnOptionUsersByInvoiceItemReturnOptionIdAndCreatedByManyToManyConnection = {
  __typename?: 'ReturnOptionUsersByInvoiceItemReturnOptionIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ReturnOptionUsersByInvoiceItemReturnOptionIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnOptionUsersByInvoiceItemReturnOptionIdAndCreatedByManyToManyEdge = {
  __typename?: 'ReturnOptionUsersByInvoiceItemReturnOptionIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByCreatedBy: InvoiceItemReturnsConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnOptionUsersByInvoiceItemReturnOptionIdAndCreatedByManyToManyEdgeInvoiceItemReturnsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturn`. */
export type ReturnOptionUsersByInvoiceItemReturnOptionIdAndUpdatedByManyToManyConnection = {
  __typename?: 'ReturnOptionUsersByInvoiceItemReturnOptionIdAndUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ReturnOptionUsersByInvoiceItemReturnOptionIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnOptionUsersByInvoiceItemReturnOptionIdAndUpdatedByManyToManyEdge = {
  __typename?: 'ReturnOptionUsersByInvoiceItemReturnOptionIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByUpdatedBy: InvoiceItemReturnsConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnOptionUsersByInvoiceItemReturnOptionIdAndUpdatedByManyToManyEdgeInvoiceItemReturnsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndCompletedByManyToManyConnection = {
  __typename?: 'ReturnOptionUsersByReturnQueueOptionIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<ReturnOptionUsersByReturnQueueOptionIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndCompletedByManyToManyEdge = {
  __typename?: 'ReturnOptionUsersByReturnQueueOptionIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCompletedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndCompletedByManyToManyEdgeReturnQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndCreatedByManyToManyConnection = {
  __typename?: 'ReturnOptionUsersByReturnQueueOptionIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<ReturnOptionUsersByReturnQueueOptionIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndCreatedByManyToManyEdge = {
  __typename?: 'ReturnOptionUsersByReturnQueueOptionIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCreatedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndCreatedByManyToManyEdgeReturnQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndUpdatedByManyToManyConnection = {
  __typename?: 'ReturnOptionUsersByReturnQueueOptionIdAndUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<ReturnOptionUsersByReturnQueueOptionIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndUpdatedByManyToManyEdge = {
  __typename?: 'ReturnOptionUsersByReturnQueueOptionIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByUpdatedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type ReturnOptionUsersByReturnQueueOptionIdAndUpdatedByManyToManyEdgeReturnQueuesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `ReturnOption` values. */
export type ReturnOptionsConnection = {
  __typename?: 'ReturnOptionsConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption` and cursor to aid in pagination. */
  edges: Array<ReturnOptionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection. */
export type ReturnOptionsEdge = {
  __typename?: 'ReturnOptionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
};

/** Methods to use when ordering `ReturnOption`. */
export enum ReturnOptionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  RequireNoteAsc = 'REQUIRE_NOTE_ASC',
  RequireNoteDesc = 'REQUIRE_NOTE_DESC',
  AccessSupportAsc = 'ACCESS_SUPPORT_ASC',
  AccessSupportDesc = 'ACCESS_SUPPORT_DESC',
  AccessAppAsc = 'ACCESS_APP_ASC',
  AccessAppDesc = 'ACCESS_APP_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  CountAgainstUserAsc = 'COUNT_AGAINST_USER_ASC',
  CountAgainstUserDesc = 'COUNT_AGAINST_USER_DESC',
  CountAgainstCompanyAsc = 'COUNT_AGAINST_COMPANY_ASC',
  CountAgainstCompanyDesc = 'COUNT_AGAINST_COMPANY_DESC',
  MerMapReasonAsc = 'MER_MAP_REASON_ASC',
  MerMapReasonDesc = 'MER_MAP_REASON_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ReturnQueue = Node & {
  __typename?: 'ReturnQueue';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  type: ReturnQueueType;
  optionId: Scalars['UUID'];
  itemId: Scalars['Int'];
  amount: Scalars['Int'];
  note?: Maybe<Scalars['String']>;
  status: ReturnQueueStatus;
  sendEmail: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  createdBy?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['Datetime']>;
  completedBy?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  updatedBy?: Maybe<Scalars['Int']>;
  isMer: Scalars['Boolean'];
  merReturnId?: Maybe<Scalars['String']>;
  origin: EventType;
  amountType: ItemTotalReturnStatus;
  /** Reads a single `ReturnOption` that is related to this `ReturnQueue`. */
  returnOptionByOptionId?: Maybe<ReturnOption>;
  /** Reads a single `Item` that is related to this `ReturnQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `ReturnQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `ReturnQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `ReturnQueue`. */
  userByUpdatedBy?: Maybe<User>;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByQueueId: InvoiceItemReturnsConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceItemReturnQueueIdAndInvoiceItemId: ReturnQueueItemsByInvoiceItemReturnQueueIdAndInvoiceItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnQueueIdAndCreatedBy: ReturnQueueUsersByInvoiceItemReturnQueueIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnQueueIdAndUpdatedBy: ReturnQueueUsersByInvoiceItemReturnQueueIdAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  returnOptionsByInvoiceItemReturnQueueIdAndOptionId: ReturnQueueReturnOptionsByInvoiceItemReturnQueueIdAndOptionIdManyToManyConnection;
};


export type ReturnQueueInvoiceItemReturnsByQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};


export type ReturnQueueItemsByInvoiceItemReturnQueueIdAndInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type ReturnQueueUsersByInvoiceItemReturnQueueIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ReturnQueueUsersByInvoiceItemReturnQueueIdAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type ReturnQueueReturnOptionsByInvoiceItemReturnQueueIdAndOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};

/**
 * A condition to be used against `ReturnQueue` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ReturnQueueCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<ReturnQueueType>;
  /** Checks for equality with the object’s `optionId` field. */
  optionId?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `note` field. */
  note?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<ReturnQueueStatus>;
  /** Checks for equality with the object’s `sendEmail` field. */
  sendEmail?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `completedBy` field. */
  completedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedBy` field. */
  updatedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `isMer` field. */
  isMer?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `merReturnId` field. */
  merReturnId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `origin` field. */
  origin?: Maybe<EventType>;
  /** Checks for equality with the object’s `amountType` field. */
  amountType?: Maybe<ItemTotalReturnStatus>;
};

/** A filter to be used against `ReturnQueue` object types. All fields are combined with a logical ‘and.’ */
export type ReturnQueueFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<ReturnQueueTypeFilter>;
  /** Filter by the object’s `optionId` field. */
  optionId?: Maybe<UuidFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `amount` field. */
  amount?: Maybe<IntFilter>;
  /** Filter by the object’s `note` field. */
  note?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<ReturnQueueStatusFilter>;
  /** Filter by the object’s `sendEmail` field. */
  sendEmail?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `completedBy` field. */
  completedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedBy` field. */
  updatedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `isMer` field. */
  isMer?: Maybe<BooleanFilter>;
  /** Filter by the object’s `merReturnId` field. */
  merReturnId?: Maybe<StringFilter>;
  /** Filter by the object’s `origin` field. */
  origin?: Maybe<EventTypeFilter>;
  /** Filter by the object’s `amountType` field. */
  amountType?: Maybe<ItemTotalReturnStatusFilter>;
  /** Filter by the object’s `invoiceItemReturnsByQueueId` relation. */
  invoiceItemReturnsByQueueId?: Maybe<ReturnQueueToManyInvoiceItemReturnFilter>;
  /** Some related `invoiceItemReturnsByQueueId` exist. */
  invoiceItemReturnsByQueueIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `returnOptionByOptionId` relation. */
  returnOptionByOptionId?: Maybe<ReturnOptionFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** A related `userByCreatedBy` exists. */
  userByCreatedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCompletedBy` relation. */
  userByCompletedBy?: Maybe<UserFilter>;
  /** A related `userByCompletedBy` exists. */
  userByCompletedByExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUpdatedBy` relation. */
  userByUpdatedBy?: Maybe<UserFilter>;
  /** A related `userByUpdatedBy` exists. */
  userByUpdatedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ReturnQueueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ReturnQueueFilter>>;
  /** Negates the expression. */
  not?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `Item` values, with data from `InvoiceItemReturn`. */
export type ReturnQueueItemsByInvoiceItemReturnQueueIdAndInvoiceItemIdManyToManyConnection = {
  __typename?: 'ReturnQueueItemsByInvoiceItemReturnQueueIdAndInvoiceItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ReturnQueueItemsByInvoiceItemReturnQueueIdAndInvoiceItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnQueueItemsByInvoiceItemReturnQueueIdAndInvoiceItemIdManyToManyEdge = {
  __typename?: 'ReturnQueueItemsByInvoiceItemReturnQueueIdAndInvoiceItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByInvoiceItemId: InvoiceItemReturnsConnection;
};


/** A `Item` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnQueueItemsByInvoiceItemReturnQueueIdAndInvoiceItemIdManyToManyEdgeInvoiceItemReturnsByInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** Represents an update to a `ReturnQueue`. Fields that are set will be updated. */
export type ReturnQueuePatch = {
  type?: Maybe<ReturnQueueType>;
  optionId?: Maybe<Scalars['UUID']>;
  itemId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
  note?: Maybe<Scalars['String']>;
  status?: Maybe<ReturnQueueStatus>;
  sendEmail?: Maybe<Scalars['Boolean']>;
  amountType?: Maybe<ItemTotalReturnStatus>;
};

/** A connection to a list of `ReturnOption` values, with data from `InvoiceItemReturn`. */
export type ReturnQueueReturnOptionsByInvoiceItemReturnQueueIdAndOptionIdManyToManyConnection = {
  __typename?: 'ReturnQueueReturnOptionsByInvoiceItemReturnQueueIdAndOptionIdManyToManyConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ReturnQueueReturnOptionsByInvoiceItemReturnQueueIdAndOptionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnQueueReturnOptionsByInvoiceItemReturnQueueIdAndOptionIdManyToManyEdge = {
  __typename?: 'ReturnQueueReturnOptionsByInvoiceItemReturnQueueIdAndOptionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByOptionId: InvoiceItemReturnsConnection;
};


/** A `ReturnOption` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnQueueReturnOptionsByInvoiceItemReturnQueueIdAndOptionIdManyToManyEdgeInvoiceItemReturnsByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

export enum ReturnQueueStatus {
  Open = 'OPEN',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Cancelled = 'CANCELLED'
}

/** A filter to be used against ReturnQueueStatus fields. All fields are combined with a logical ‘and.’ */
export type ReturnQueueStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ReturnQueueStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ReturnQueueStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ReturnQueueStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ReturnQueueStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ReturnQueueStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ReturnQueueStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ReturnQueueStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ReturnQueueStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ReturnQueueStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ReturnQueueStatus>;
};

/** A filter to be used against many `InvoiceItemReturn` object types. All fields are combined with a logical ‘and.’ */
export type ReturnQueueToManyInvoiceItemReturnFilter = {
  /** Every related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceItemReturnFilter>;
  /** Some related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceItemReturnFilter>;
  /** No related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceItemReturnFilter>;
};

export enum ReturnQueueType {
  Refund = 'REFUND',
  Credit = 'CREDIT'
}

/** A filter to be used against ReturnQueueType fields. All fields are combined with a logical ‘and.’ */
export type ReturnQueueTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ReturnQueueType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ReturnQueueType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ReturnQueueType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ReturnQueueType>;
  /** Included in the specified list. */
  in?: Maybe<Array<ReturnQueueType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ReturnQueueType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ReturnQueueType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ReturnQueueType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ReturnQueueType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ReturnQueueType>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturn`. */
export type ReturnQueueUsersByInvoiceItemReturnQueueIdAndCreatedByManyToManyConnection = {
  __typename?: 'ReturnQueueUsersByInvoiceItemReturnQueueIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ReturnQueueUsersByInvoiceItemReturnQueueIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnQueueUsersByInvoiceItemReturnQueueIdAndCreatedByManyToManyEdge = {
  __typename?: 'ReturnQueueUsersByInvoiceItemReturnQueueIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByCreatedBy: InvoiceItemReturnsConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnQueueUsersByInvoiceItemReturnQueueIdAndCreatedByManyToManyEdgeInvoiceItemReturnsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturn`. */
export type ReturnQueueUsersByInvoiceItemReturnQueueIdAndUpdatedByManyToManyConnection = {
  __typename?: 'ReturnQueueUsersByInvoiceItemReturnQueueIdAndUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<ReturnQueueUsersByInvoiceItemReturnQueueIdAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnQueueUsersByInvoiceItemReturnQueueIdAndUpdatedByManyToManyEdge = {
  __typename?: 'ReturnQueueUsersByInvoiceItemReturnQueueIdAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByUpdatedBy: InvoiceItemReturnsConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type ReturnQueueUsersByInvoiceItemReturnQueueIdAndUpdatedByManyToManyEdgeInvoiceItemReturnsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `ReturnQueue` values. */
export type ReturnQueuesConnection = {
  __typename?: 'ReturnQueuesConnection';
  /** A list of `ReturnQueue` objects. */
  nodes: Array<ReturnQueue>;
  /** A list of edges which contains the `ReturnQueue` and cursor to aid in pagination. */
  edges: Array<ReturnQueuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnQueue` edge in the connection. */
export type ReturnQueuesEdge = {
  __typename?: 'ReturnQueuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnQueue` at the end of the edge. */
  node: ReturnQueue;
};

/** Methods to use when ordering `ReturnQueue`. */
export enum ReturnQueuesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  OptionIdAsc = 'OPTION_ID_ASC',
  OptionIdDesc = 'OPTION_ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  NoteAsc = 'NOTE_ASC',
  NoteDesc = 'NOTE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  SendEmailAsc = 'SEND_EMAIL_ASC',
  SendEmailDesc = 'SEND_EMAIL_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  CompletedByAsc = 'COMPLETED_BY_ASC',
  CompletedByDesc = 'COMPLETED_BY_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpdatedByAsc = 'UPDATED_BY_ASC',
  UpdatedByDesc = 'UPDATED_BY_DESC',
  IsMerAsc = 'IS_MER_ASC',
  IsMerDesc = 'IS_MER_DESC',
  MerReturnIdAsc = 'MER_RETURN_ID_ASC',
  MerReturnIdDesc = 'MER_RETURN_ID_DESC',
  OriginAsc = 'ORIGIN_ASC',
  OriginDesc = 'ORIGIN_DESC',
  AmountTypeAsc = 'AMOUNT_TYPE_ASC',
  AmountTypeDesc = 'AMOUNT_TYPE_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type ReuploadItemsImageInput = {
  itemImageId: Scalars['Int'];
  image: Scalars['Upload'];
};

export type ReuploadItemsImagePayload = {
  __typename?: 'ReuploadItemsImagePayload';
  itemImage?: Maybe<ItemImage>;
  query?: Maybe<Query>;
};

/** A connection to a list of `RunAuctionSummaryReportRecord` values. */
export type RunAuctionSummaryReportConnection = {
  __typename?: 'RunAuctionSummaryReportConnection';
  /** A list of `RunAuctionSummaryReportRecord` objects. */
  nodes: Array<RunAuctionSummaryReportRecord>;
  /** A list of edges which contains the `RunAuctionSummaryReportRecord` and cursor to aid in pagination. */
  edges: Array<RunAuctionSummaryReportEdge>;
  /** The count of *all* `RunAuctionSummaryReportRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RunAuctionSummaryReportRecord` edge in the connection. */
export type RunAuctionSummaryReportEdge = {
  __typename?: 'RunAuctionSummaryReportEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RunAuctionSummaryReportRecord` at the end of the edge. */
  node: RunAuctionSummaryReportRecord;
};

/** The return type of our `runAuctionSummaryReport` query. */
export type RunAuctionSummaryReportRecord = {
  __typename?: 'RunAuctionSummaryReportRecord';
  auctionId?: Maybe<Scalars['Int']>;
  auctionName?: Maybe<Scalars['String']>;
  countItems?: Maybe<Scalars['Int']>;
  countItemsSold?: Maybe<Scalars['Int']>;
  countItemsNotSold?: Maybe<Scalars['Int']>;
  totalRetailPrice?: Maybe<Scalars['Int']>;
  totalSalePrice?: Maybe<Scalars['Int']>;
  avgPricePerItem?: Maybe<Scalars['Int']>;
  retailPercent?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `RunAuctionSummaryReportRecord` object types. All fields are combined with a logical ‘and.’ */
export type RunAuctionSummaryReportRecordFilter = {
  /** Filter by the object’s `auctionId` field. */
  auctionId?: Maybe<IntFilter>;
  /** Filter by the object’s `auctionName` field. */
  auctionName?: Maybe<StringFilter>;
  /** Filter by the object’s `countItems` field. */
  countItems?: Maybe<IntFilter>;
  /** Filter by the object’s `countItemsSold` field. */
  countItemsSold?: Maybe<IntFilter>;
  /** Filter by the object’s `countItemsNotSold` field. */
  countItemsNotSold?: Maybe<IntFilter>;
  /** Filter by the object’s `totalRetailPrice` field. */
  totalRetailPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `totalSalePrice` field. */
  totalSalePrice?: Maybe<IntFilter>;
  /** Filter by the object’s `avgPricePerItem` field. */
  avgPricePerItem?: Maybe<IntFilter>;
  /** Filter by the object’s `retailPercent` field. */
  retailPercent?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RunAuctionSummaryReportRecordFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RunAuctionSummaryReportRecordFilter>>;
  /** Negates the expression. */
  not?: Maybe<RunAuctionSummaryReportRecordFilter>;
};

/** A connection to a list of `RunCompanyReturnRateReportRecord` values. */
export type RunCompanyReturnRateReportConnection = {
  __typename?: 'RunCompanyReturnRateReportConnection';
  /** A list of `RunCompanyReturnRateReportRecord` objects. */
  nodes: Array<RunCompanyReturnRateReportRecord>;
  /** A list of edges which contains the `RunCompanyReturnRateReportRecord` and cursor to aid in pagination. */
  edges: Array<RunCompanyReturnRateReportEdge>;
  /** The count of *all* `RunCompanyReturnRateReportRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RunCompanyReturnRateReportRecord` edge in the connection. */
export type RunCompanyReturnRateReportEdge = {
  __typename?: 'RunCompanyReturnRateReportEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RunCompanyReturnRateReportRecord` at the end of the edge. */
  node: RunCompanyReturnRateReportRecord;
};

/** The return type of our `runCompanyReturnRateReport` query. */
export type RunCompanyReturnRateReportRecord = {
  __typename?: 'RunCompanyReturnRateReportRecord';
  date?: Maybe<Scalars['String']>;
  percent?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `RunCompanyReturnRateReportRecord` object types. All fields are combined with a logical ‘and.’ */
export type RunCompanyReturnRateReportRecordFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<StringFilter>;
  /** Filter by the object’s `percent` field. */
  percent?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RunCompanyReturnRateReportRecordFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RunCompanyReturnRateReportRecordFilter>>;
  /** Negates the expression. */
  not?: Maybe<RunCompanyReturnRateReportRecordFilter>;
};

/** A connection to a list of `RunItemSoldReportRecord` values. */
export type RunItemSoldReportConnection = {
  __typename?: 'RunItemSoldReportConnection';
  /** A list of `RunItemSoldReportRecord` objects. */
  nodes: Array<RunItemSoldReportRecord>;
  /** A list of edges which contains the `RunItemSoldReportRecord` and cursor to aid in pagination. */
  edges: Array<RunItemSoldReportEdge>;
  /** The count of *all* `RunItemSoldReportRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RunItemSoldReportRecord` edge in the connection. */
export type RunItemSoldReportEdge = {
  __typename?: 'RunItemSoldReportEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RunItemSoldReportRecord` at the end of the edge. */
  node: RunItemSoldReportRecord;
};

/** The return type of our `runItemSoldReport` query. */
export type RunItemSoldReportRecord = {
  __typename?: 'RunItemSoldReportRecord';
  date?: Maybe<Scalars['String']>;
  count?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `RunItemSoldReportRecord` object types. All fields are combined with a logical ‘and.’ */
export type RunItemSoldReportRecordFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<StringFilter>;
  /** Filter by the object’s `count` field. */
  count?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RunItemSoldReportRecordFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RunItemSoldReportRecordFilter>>;
  /** Negates the expression. */
  not?: Maybe<RunItemSoldReportRecordFilter>;
};

/** A `RunReturnReportEmployeeReturnRatesRecord` edge in the connection. */
export type RunReturnReportEmployeeReturnRateEdge = {
  __typename?: 'RunReturnReportEmployeeReturnRateEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RunReturnReportEmployeeReturnRatesRecord` at the end of the edge. */
  node: RunReturnReportEmployeeReturnRatesRecord;
};

/** A connection to a list of `RunReturnReportEmployeeReturnRatesRecord` values. */
export type RunReturnReportEmployeeReturnRatesConnection = {
  __typename?: 'RunReturnReportEmployeeReturnRatesConnection';
  /** A list of `RunReturnReportEmployeeReturnRatesRecord` objects. */
  nodes: Array<RunReturnReportEmployeeReturnRatesRecord>;
  /** A list of edges which contains the `RunReturnReportEmployeeReturnRatesRecord` and cursor to aid in pagination. */
  edges: Array<RunReturnReportEmployeeReturnRateEdge>;
  /** The count of *all* `RunReturnReportEmployeeReturnRatesRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** The return type of our `runReturnReportEmployeeReturnRates` query. */
export type RunReturnReportEmployeeReturnRatesRecord = {
  __typename?: 'RunReturnReportEmployeeReturnRatesRecord';
  employeeName?: Maybe<Scalars['String']>;
  totalPickedUp?: Maybe<Scalars['Int']>;
  totalReturns?: Maybe<Scalars['Int']>;
  percent?: Maybe<Scalars['BigFloat']>;
  avgPercent?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `RunReturnReportEmployeeReturnRatesRecord` object types. All fields are combined with a logical ‘and.’ */
export type RunReturnReportEmployeeReturnRatesRecordFilter = {
  /** Filter by the object’s `employeeName` field. */
  employeeName?: Maybe<StringFilter>;
  /** Filter by the object’s `totalPickedUp` field. */
  totalPickedUp?: Maybe<IntFilter>;
  /** Filter by the object’s `totalReturns` field. */
  totalReturns?: Maybe<IntFilter>;
  /** Filter by the object’s `percent` field. */
  percent?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `avgPercent` field. */
  avgPercent?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RunReturnReportEmployeeReturnRatesRecordFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RunReturnReportEmployeeReturnRatesRecordFilter>>;
  /** Negates the expression. */
  not?: Maybe<RunReturnReportEmployeeReturnRatesRecordFilter>;
};

/** A connection to a list of `RunReturnReportPieRecord` values. */
export type RunReturnReportPieConnection = {
  __typename?: 'RunReturnReportPieConnection';
  /** A list of `RunReturnReportPieRecord` objects. */
  nodes: Array<RunReturnReportPieRecord>;
  /** A list of edges which contains the `RunReturnReportPieRecord` and cursor to aid in pagination. */
  edges: Array<RunReturnReportPieEdge>;
  /** The count of *all* `RunReturnReportPieRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RunReturnReportPieRecord` edge in the connection. */
export type RunReturnReportPieEdge = {
  __typename?: 'RunReturnReportPieEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RunReturnReportPieRecord` at the end of the edge. */
  node: RunReturnReportPieRecord;
};

/** The return type of our `runReturnReportPie` query. */
export type RunReturnReportPieRecord = {
  __typename?: 'RunReturnReportPieRecord';
  reason?: Maybe<Scalars['String']>;
  num?: Maybe<Scalars['Int']>;
  percent?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `RunReturnReportPieRecord` object types. All fields are combined with a logical ‘and.’ */
export type RunReturnReportPieRecordFilter = {
  /** Filter by the object’s `reason` field. */
  reason?: Maybe<StringFilter>;
  /** Filter by the object’s `num` field. */
  num?: Maybe<IntFilter>;
  /** Filter by the object’s `percent` field. */
  percent?: Maybe<BigFloatFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RunReturnReportPieRecordFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RunReturnReportPieRecordFilter>>;
  /** Negates the expression. */
  not?: Maybe<RunReturnReportPieRecordFilter>;
};

/** A connection to a list of `RunTaxReportRecord` values. */
export type RunTaxReportConnection = {
  __typename?: 'RunTaxReportConnection';
  /** A list of `RunTaxReportRecord` objects. */
  nodes: Array<RunTaxReportRecord>;
  /** A list of edges which contains the `RunTaxReportRecord` and cursor to aid in pagination. */
  edges: Array<RunTaxReportEdge>;
  /** The count of *all* `RunTaxReportRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `RunTaxReportRecord` edge in the connection. */
export type RunTaxReportEdge = {
  __typename?: 'RunTaxReportEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `RunTaxReportRecord` at the end of the edge. */
  node: RunTaxReportRecord;
};

/** The return type of our `runTaxReport` query. */
export type RunTaxReportRecord = {
  __typename?: 'RunTaxReportRecord';
  locationName?: Maybe<Scalars['String']>;
  locationTax?: Maybe<Scalars['String']>;
  auctionSales?: Maybe<Scalars['Int']>;
  creditCardRefunds?: Maybe<Scalars['Int']>;
  reportedGrossSales?: Maybe<Scalars['Int']>;
  taxExemptSales?: Maybe<Scalars['Int']>;
  totalTaxableSales?: Maybe<Scalars['Int']>;
  salesTaxDue?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `RunTaxReportRecord` object types. All fields are combined with a logical ‘and.’ */
export type RunTaxReportRecordFilter = {
  /** Filter by the object’s `locationName` field. */
  locationName?: Maybe<StringFilter>;
  /** Filter by the object’s `locationTax` field. */
  locationTax?: Maybe<StringFilter>;
  /** Filter by the object’s `auctionSales` field. */
  auctionSales?: Maybe<IntFilter>;
  /** Filter by the object’s `creditCardRefunds` field. */
  creditCardRefunds?: Maybe<IntFilter>;
  /** Filter by the object’s `reportedGrossSales` field. */
  reportedGrossSales?: Maybe<IntFilter>;
  /** Filter by the object’s `taxExemptSales` field. */
  taxExemptSales?: Maybe<IntFilter>;
  /** Filter by the object’s `totalTaxableSales` field. */
  totalTaxableSales?: Maybe<IntFilter>;
  /** Filter by the object’s `salesTaxDue` field. */
  salesTaxDue?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<RunTaxReportRecordFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<RunTaxReportRecordFilter>>;
  /** Negates the expression. */
  not?: Maybe<RunTaxReportRecordFilter>;
};

/** All input for the `setAsideItem` mutation. */
export type SetAsideItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  note: Scalars['String'];
};

/** The output of our `setAsideItem` mutation. */
export type SetAsideItemPayload = {
  __typename?: 'SetAsideItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  item?: Maybe<Item>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Item`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `Item`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Payment` that is related to this `Item`. */
  paymentByPaymentId?: Maybe<Payment>;
  /** Reads a single `Bidder` that is related to this `Item`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Item`. */
  userByDeletedFromInvoiceBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userBySetAsideBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userByIsDeletedBy?: Maybe<User>;
  /** An edge for our `Item`. May be used by Relay 1. */
  itemEdge?: Maybe<ItemsEdge>;
};


/** The output of our `setAsideItem` mutation. */
export type SetAsideItemPayloadItemEdgeArgs = {
  orderBy?: Maybe<Array<ItemsOrderBy>>;
};

/** All input for the `setInvoiceLastPickupDate` mutation. */
export type SetInvoiceLastPickupDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
  lastPickupDate: Scalars['Date'];
};

/** The output of our `setInvoiceLastPickupDate` mutation. */
export type SetInvoiceLastPickupDatePayload = {
  __typename?: 'SetInvoiceLastPickupDatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setInvoiceSignaturesBasedOnItems` mutation. */
export type SetInvoiceSignaturesBasedOnItemsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemIds: Array<Maybe<Scalars['Int']>>;
  imageName: Scalars['String'];
};

/** The output of our `setInvoiceSignaturesBasedOnItems` mutation. */
export type SetInvoiceSignaturesBasedOnItemsPayload = {
  __typename?: 'SetInvoiceSignaturesBasedOnItemsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setInvoicesLastPickupDate` mutation. */
export type SetInvoicesLastPickupDateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceIds: Array<Maybe<Scalars['Int']>>;
  lastPickupDate: Scalars['Date'];
};

/** The output of our `setInvoicesLastPickupDate` mutation. */
export type SetInvoicesLastPickupDatePayload = {
  __typename?: 'SetInvoicesLastPickupDatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  setInvoicesLastPickupDateResultTypes?: Maybe<Array<SetInvoicesLastPickupDateResultType>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type SetInvoicesLastPickupDateResultType = {
  __typename?: 'SetInvoicesLastPickupDateResultType';
  id?: Maybe<Scalars['Int']>;
  lastPickupDate?: Maybe<Scalars['Date']>;
};

/** All input for the `setItemBinByItemIds` mutation. */
export type SetItemBinByItemIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  bin: Scalars['String'];
  itemIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `setItemBinByItemIds` mutation. */
export type SetItemBinByItemIdsPayload = {
  __typename?: 'SetItemBinByItemIdsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemRackLocations?: Maybe<Array<ItemRackLocation>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setItemRackLocationByItemIds` mutation. */
export type SetItemRackLocationByItemIdsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  rackLocation: Scalars['String'];
  itemIds: Array<Maybe<Scalars['Int']>>;
};

/** The output of our `setItemRackLocationByItemIds` mutation. */
export type SetItemRackLocationByItemIdsPayload = {
  __typename?: 'SetItemRackLocationByItemIdsPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemRackLocations?: Maybe<Array<ItemRackLocation>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setItemsRackLocation` mutation. */
export type SetItemsRackLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  items: Array<Maybe<SetItemsRackLocationItemsTypeInput>>;
  rackLocation: Scalars['String'];
};

/** An input for mutations affecting `SetItemsRackLocationItemsType` */
export type SetItemsRackLocationItemsTypeInput = {
  itemNumber?: Maybe<Scalars['Int']>;
  auctionName?: Maybe<Scalars['String']>;
};

/** The output of our `setItemsRackLocation` mutation. */
export type SetItemsRackLocationPayload = {
  __typename?: 'SetItemsRackLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `setProcessInvoicePayment` mutation. */
export type SetProcessInvoicePaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  bidderId: Scalars['Int'];
  isCollect: Scalars['Boolean'];
  reason: Scalars['String'];
};

/** The output of our `setProcessInvoicePayment` mutation. */
export type SetProcessInvoicePaymentPayload = {
  __typename?: 'SetProcessInvoicePaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  bidder?: Maybe<Bidder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Bidder`. */
  userByProcessInvoicePaymentChangedBy?: Maybe<User>;
  /** An edge for our `Bidder`. May be used by Relay 1. */
  bidderEdge?: Maybe<BiddersEdge>;
};


/** The output of our `setProcessInvoicePayment` mutation. */
export type SetProcessInvoicePaymentPayloadBidderEdgeArgs = {
  orderBy?: Maybe<Array<BiddersOrderBy>>;
};

/** All input for the `setProcessPayment` mutation. */
export type SetProcessPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
  isCollect: Scalars['Boolean'];
  reason: Scalars['String'];
};

/** The output of our `setProcessPayment` mutation. */
export type SetProcessPaymentPayload = {
  __typename?: 'SetProcessPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoice?: Maybe<Invoice>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Invoice`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Bidder` that is related to this `Invoice`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Invoice`. */
  userByProcessPaymentChangedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Invoice`. */
  userByAlternatePaymentCreatedBy?: Maybe<User>;
  /** Reads a single `Location` that is related to this `Invoice`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `Invoice`. May be used by Relay 1. */
  invoiceEdge?: Maybe<InvoicesEdge>;
};


/** The output of our `setProcessPayment` mutation. */
export type SetProcessPaymentPayloadInvoiceEdgeArgs = {
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
};

/** All input for the `setUserLocation` mutation. */
export type SetUserLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  locationId: Scalars['Int'];
};

/** The output of our `setUserLocation` mutation. */
export type SetUserLocationPayload = {
  __typename?: 'SetUserLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<SetUserLocationRecord>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The return type of our `setUserLocation` mutation. */
export type SetUserLocationRecord = {
  __typename?: 'SetUserLocationRecord';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

export type Setting = Node & {
  __typename?: 'Setting';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  name: Scalars['String'];
  props: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
};

/** A condition to be used against `Setting` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SettingCondition = {
  /** Checks for equality with the object’s `name` field. */
  name?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `props` field. */
  props?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `Setting` object types. All fields are combined with a logical ‘and.’ */
export type SettingFilter = {
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `props` field. */
  props?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<SettingFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<SettingFilter>>;
  /** Negates the expression. */
  not?: Maybe<SettingFilter>;
};

/** A connection to a list of `Setting` values. */
export type SettingsConnection = {
  __typename?: 'SettingsConnection';
  /** A list of `Setting` objects. */
  nodes: Array<Setting>;
  /** A list of edges which contains the `Setting` and cursor to aid in pagination. */
  edges: Array<SettingsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Setting` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Setting` edge in the connection. */
export type SettingsEdge = {
  __typename?: 'SettingsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Setting` at the end of the edge. */
  node: Setting;
};

/** Methods to use when ordering `Setting`. */
export enum SettingsOrderBy {
  Natural = 'NATURAL',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PropsAsc = 'PROPS_ASC',
  PropsDesc = 'PROPS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `shippingAddItemToCart` mutation. */
export type ShippingAddItemToCartInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
};

/** The output of our `shippingAddItemToCart` mutation. */
export type ShippingAddItemToCartPayload = {
  __typename?: 'ShippingAddItemToCartPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  shippingQueue?: Maybe<ShippingQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `ShippingQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `ShippingQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `ShippingQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** An edge for our `ShippingQueue`. May be used by Relay 1. */
  shippingQueueEdge?: Maybe<ShippingQueuesEdge>;
};


/** The output of our `shippingAddItemToCart` mutation. */
export type ShippingAddItemToCartPayloadShippingQueueEdgeArgs = {
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
};

/** All input for the `shippingAssignNextCustomer` mutation. */
export type ShippingAssignNextCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** The output of our `shippingAssignNextCustomer` mutation. */
export type ShippingAssignNextCustomerPayload = {
  __typename?: 'ShippingAssignNextCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** All input for the `shippingAssignRackToItem` mutation. */
export type ShippingAssignRackToItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
  rack: Scalars['String'];
};

/** The output of our `shippingAssignRackToItem` mutation. */
export type ShippingAssignRackToItemPayload = {
  __typename?: 'ShippingAssignRackToItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  shippingQueue?: Maybe<ShippingQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `ShippingQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `ShippingQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `ShippingQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** An edge for our `ShippingQueue`. May be used by Relay 1. */
  shippingQueueEdge?: Maybe<ShippingQueuesEdge>;
};


/** The output of our `shippingAssignRackToItem` mutation. */
export type ShippingAssignRackToItemPayloadShippingQueueEdgeArgs = {
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
};

export type ShippingAvailableQueueType = {
  __typename?: 'ShippingAvailableQueueType';
  auctionName?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `ShippingAvailableQueueType` object types. All fields are combined with a logical ‘and.’ */
export type ShippingAvailableQueueTypeFilter = {
  /** Filter by the object’s `auctionName` field. */
  auctionName?: Maybe<StringFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ShippingAvailableQueueTypeFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ShippingAvailableQueueTypeFilter>>;
  /** Negates the expression. */
  not?: Maybe<ShippingAvailableQueueTypeFilter>;
};

/** A connection to a list of `ShippingAvailableQueueType` values. */
export type ShippingAvailableQueueTypesConnection = {
  __typename?: 'ShippingAvailableQueueTypesConnection';
  /** A list of `ShippingAvailableQueueType` objects. */
  nodes: Array<ShippingAvailableQueueType>;
  /** A list of edges which contains the `ShippingAvailableQueueType` and cursor to aid in pagination. */
  edges: Array<ShippingAvailableQueueTypesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ShippingAvailableQueueType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ShippingAvailableQueueType` edge in the connection. */
export type ShippingAvailableQueueTypesEdge = {
  __typename?: 'ShippingAvailableQueueTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ShippingAvailableQueueType` at the end of the edge. */
  node: ShippingAvailableQueueType;
};

/** All input for the `shippingItemNotFound` mutation. */
export type ShippingItemNotFoundInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  itemId: Scalars['Int'];
};

/** The output of our `shippingItemNotFound` mutation. */
export type ShippingItemNotFoundPayload = {
  __typename?: 'ShippingItemNotFoundPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  shippingQueue?: Maybe<ShippingQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `ShippingQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `ShippingQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `ShippingQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** An edge for our `ShippingQueue`. May be used by Relay 1. */
  shippingQueueEdge?: Maybe<ShippingQueuesEdge>;
};


/** The output of our `shippingItemNotFound` mutation. */
export type ShippingItemNotFoundPayloadShippingQueueEdgeArgs = {
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
};

export type ShippingMyQueueType = {
  __typename?: 'ShippingMyQueueType';
  item?: Maybe<Item>;
  queue?: Maybe<ShippingQueue>;
};

/** A connection to a list of `ShippingMyQueueType` values. */
export type ShippingMyQueueTypesConnection = {
  __typename?: 'ShippingMyQueueTypesConnection';
  /** A list of `ShippingMyQueueType` objects. */
  nodes: Array<ShippingMyQueueType>;
  /** A list of edges which contains the `ShippingMyQueueType` and cursor to aid in pagination. */
  edges: Array<ShippingMyQueueTypesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ShippingMyQueueType` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ShippingMyQueueType` edge in the connection. */
export type ShippingMyQueueTypesEdge = {
  __typename?: 'ShippingMyQueueTypesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ShippingMyQueueType` at the end of the edge. */
  node: ShippingMyQueueType;
};

export type ShippingQueue = Node & {
  __typename?: 'ShippingQueue';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  itemId: Scalars['Int'];
  itemNum: Scalars['Int'];
  customerId: Scalars['Int'];
  locationId: Scalars['Int'];
  assignedUserId?: Maybe<Scalars['Int']>;
  step?: Maybe<ShippingQueueStep>;
  stepValue?: Maybe<Scalars['String']>;
  status: ShippingQueueStatus;
  createdAt: Scalars['Datetime'];
  completedBy?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `Item` that is related to this `ShippingQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `ShippingQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `ShippingQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByCompletedBy?: Maybe<User>;
};

/**
 * A condition to be used against `ShippingQueue` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ShippingQueueCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `itemId` field. */
  itemId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `itemNum` field. */
  itemNum?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `customerId` field. */
  customerId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `step` field. */
  step?: Maybe<ShippingQueueStep>;
  /** Checks for equality with the object’s `stepValue` field. */
  stepValue?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<ShippingQueueStatus>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `completedBy` field. */
  completedBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `completedAt` field. */
  completedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `ShippingQueue` object types. All fields are combined with a logical ‘and.’ */
export type ShippingQueueFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `itemId` field. */
  itemId?: Maybe<IntFilter>;
  /** Filter by the object’s `itemNum` field. */
  itemNum?: Maybe<IntFilter>;
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<IntFilter>;
  /** Filter by the object’s `step` field. */
  step?: Maybe<ShippingQueueStepFilter>;
  /** Filter by the object’s `stepValue` field. */
  stepValue?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<ShippingQueueStatusFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `completedBy` field. */
  completedBy?: Maybe<IntFilter>;
  /** Filter by the object’s `completedAt` field. */
  completedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `itemByItemId` relation. */
  itemByItemId?: Maybe<ItemFilter>;
  /** Filter by the object’s `bidderByCustomerId` relation. */
  bidderByCustomerId?: Maybe<BidderFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Filter by the object’s `userByAssignedUserId` relation. */
  userByAssignedUserId?: Maybe<UserFilter>;
  /** A related `userByAssignedUserId` exists. */
  userByAssignedUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByCompletedBy` relation. */
  userByCompletedBy?: Maybe<UserFilter>;
  /** A related `userByCompletedBy` exists. */
  userByCompletedByExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ShippingQueueFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ShippingQueueFilter>>;
  /** Negates the expression. */
  not?: Maybe<ShippingQueueFilter>;
};

export type ShippingQueueItem = {
  __typename?: 'ShippingQueueItem';
  customerId?: Maybe<Scalars['Int']>;
  customerName?: Maybe<Scalars['String']>;
  assignedUserId?: Maybe<Scalars['Int']>;
  assignedName?: Maybe<Scalars['String']>;
  inQueueAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `ShippingQueueItem` object types. All fields are combined with a logical ‘and.’ */
export type ShippingQueueItemFilter = {
  /** Filter by the object’s `customerId` field. */
  customerId?: Maybe<IntFilter>;
  /** Filter by the object’s `customerName` field. */
  customerName?: Maybe<StringFilter>;
  /** Filter by the object’s `assignedUserId` field. */
  assignedUserId?: Maybe<IntFilter>;
  /** Filter by the object’s `assignedName` field. */
  assignedName?: Maybe<StringFilter>;
  /** Filter by the object’s `inQueueAt` field. */
  inQueueAt?: Maybe<DatetimeFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<ShippingQueueItemFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<ShippingQueueItemFilter>>;
  /** Negates the expression. */
  not?: Maybe<ShippingQueueItemFilter>;
};

/** A connection to a list of `ShippingQueueItem` values. */
export type ShippingQueueItemsConnection = {
  __typename?: 'ShippingQueueItemsConnection';
  /** A list of `ShippingQueueItem` objects. */
  nodes: Array<ShippingQueueItem>;
  /** A list of edges which contains the `ShippingQueueItem` and cursor to aid in pagination. */
  edges: Array<ShippingQueueItemsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ShippingQueueItem` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ShippingQueueItem` edge in the connection. */
export type ShippingQueueItemsEdge = {
  __typename?: 'ShippingQueueItemsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ShippingQueueItem` at the end of the edge. */
  node: ShippingQueueItem;
};

/** Represents an update to a `ShippingQueue`. Fields that are set will be updated. */
export type ShippingQueuePatch = {
  id?: Maybe<Scalars['UUID']>;
  itemId?: Maybe<Scalars['Int']>;
  itemNum?: Maybe<Scalars['Int']>;
  customerId?: Maybe<Scalars['Int']>;
  locationId?: Maybe<Scalars['Int']>;
  assignedUserId?: Maybe<Scalars['Int']>;
  step?: Maybe<ShippingQueueStep>;
  stepValue?: Maybe<Scalars['String']>;
  status?: Maybe<ShippingQueueStatus>;
  createdAt?: Maybe<Scalars['Datetime']>;
  completedBy?: Maybe<Scalars['Int']>;
  completedAt?: Maybe<Scalars['Datetime']>;
};

export enum ShippingQueueStatus {
  Waiting = 'WAITING',
  WorkingOn = 'WORKING_ON',
  Completed = 'COMPLETED'
}

/** A filter to be used against ShippingQueueStatus fields. All fields are combined with a logical ‘and.’ */
export type ShippingQueueStatusFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ShippingQueueStatus>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ShippingQueueStatus>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ShippingQueueStatus>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ShippingQueueStatus>;
  /** Included in the specified list. */
  in?: Maybe<Array<ShippingQueueStatus>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ShippingQueueStatus>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ShippingQueueStatus>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ShippingQueueStatus>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ShippingQueueStatus>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ShippingQueueStatus>;
};

export enum ShippingQueueStep {
  Cart = 'CART',
  CartToRack = 'CART_TO_RACK',
  NotFound = 'NOT_FOUND'
}

/** A filter to be used against ShippingQueueStep fields. All fields are combined with a logical ‘and.’ */
export type ShippingQueueStepFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<ShippingQueueStep>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<ShippingQueueStep>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<ShippingQueueStep>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<ShippingQueueStep>;
  /** Included in the specified list. */
  in?: Maybe<Array<ShippingQueueStep>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<ShippingQueueStep>>;
  /** Less than the specified value. */
  lessThan?: Maybe<ShippingQueueStep>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<ShippingQueueStep>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<ShippingQueueStep>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<ShippingQueueStep>;
};

/** A connection to a list of `ShippingQueue` values. */
export type ShippingQueuesConnection = {
  __typename?: 'ShippingQueuesConnection';
  /** A list of `ShippingQueue` objects. */
  nodes: Array<ShippingQueue>;
  /** A list of edges which contains the `ShippingQueue` and cursor to aid in pagination. */
  edges: Array<ShippingQueuesEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ShippingQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ShippingQueue` edge in the connection. */
export type ShippingQueuesEdge = {
  __typename?: 'ShippingQueuesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ShippingQueue` at the end of the edge. */
  node: ShippingQueue;
};

/** Methods to use when ordering `ShippingQueue`. */
export enum ShippingQueuesOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ItemIdAsc = 'ITEM_ID_ASC',
  ItemIdDesc = 'ITEM_ID_DESC',
  ItemNumAsc = 'ITEM_NUM_ASC',
  ItemNumDesc = 'ITEM_NUM_DESC',
  CustomerIdAsc = 'CUSTOMER_ID_ASC',
  CustomerIdDesc = 'CUSTOMER_ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  AssignedUserIdAsc = 'ASSIGNED_USER_ID_ASC',
  AssignedUserIdDesc = 'ASSIGNED_USER_ID_DESC',
  StepAsc = 'STEP_ASC',
  StepDesc = 'STEP_DESC',
  StepValueAsc = 'STEP_VALUE_ASC',
  StepValueDesc = 'STEP_VALUE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CompletedByAsc = 'COMPLETED_BY_ASC',
  CompletedByDesc = 'COMPLETED_BY_DESC',
  CompletedAtAsc = 'COMPLETED_AT_ASC',
  CompletedAtDesc = 'COMPLETED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `startInvoicePayment` mutation. */
export type StartInvoicePaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  invoiceId: Scalars['Int'];
};

/** The output of our `startInvoicePayment` mutation. */
export type StartInvoicePaymentPayload = {
  __typename?: 'StartInvoicePaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export enum StatusType {
  Success = 'SUCCESS',
  Error = 'ERROR'
}

/** A filter to be used against StatusType fields. All fields are combined with a logical ‘and.’ */
export type StatusTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<StatusType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<StatusType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<StatusType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<StatusType>;
  /** Included in the specified list. */
  in?: Maybe<Array<StatusType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<StatusType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<StatusType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<StatusType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<StatusType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<StatusType>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['String']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-sensitive). */
  includes?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: Maybe<Scalars['String']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: Maybe<Scalars['String']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: Maybe<Scalars['String']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: Maybe<Scalars['String']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: Maybe<Scalars['String']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: Maybe<Scalars['String']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: Maybe<Scalars['String']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: Maybe<Scalars['String']>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: Maybe<Array<Scalars['String']>>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: Maybe<Scalars['String']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: Maybe<Scalars['String']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: Maybe<Scalars['String']>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contains the specified list of values. */
  contains?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Contained by the specified list of values. */
  containedBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Overlaps the specified list of values. */
  overlaps?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Any array item is equal to the specified value. */
  anyEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is less than the specified value. */
  anyLessThan?: Maybe<Scalars['String']>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: Maybe<Scalars['String']>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: Maybe<Scalars['String']>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: Maybe<Scalars['String']>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: 'Subscription';
  InvoiceItemEvent?: Maybe<InvoiceItemEventSubscriptionPayload>;
  UserNotificationEvent?: Maybe<UserNotificationEventSubscriptionPayload>;
  listen: ListenPayload;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionInvoiceItemEventArgs = {
  input?: Maybe<InvoiceItemEventInput>;
};


/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type SubscriptionListenArgs = {
  topic: Scalars['String'];
};

/** A connection to a list of `TotalSalesLastMonthRecord` values. */
export type TotalSalesLastMonthConnection = {
  __typename?: 'TotalSalesLastMonthConnection';
  /** A list of `TotalSalesLastMonthRecord` objects. */
  nodes: Array<TotalSalesLastMonthRecord>;
  /** A list of edges which contains the `TotalSalesLastMonthRecord` and cursor to aid in pagination. */
  edges: Array<TotalSalesLastMonthEdge>;
  /** The count of *all* `TotalSalesLastMonthRecord` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TotalSalesLastMonthRecord` edge in the connection. */
export type TotalSalesLastMonthEdge = {
  __typename?: 'TotalSalesLastMonthEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TotalSalesLastMonthRecord` at the end of the edge. */
  node: TotalSalesLastMonthRecord;
};

/** The return type of our `totalSalesLastMonth` query. */
export type TotalSalesLastMonthRecord = {
  __typename?: 'TotalSalesLastMonthRecord';
  date?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
};

/** A filter to be used against `TotalSalesLastMonthRecord` object types. All fields are combined with a logical ‘and.’ */
export type TotalSalesLastMonthRecordFilter = {
  /** Filter by the object’s `date` field. */
  date?: Maybe<StringFilter>;
  /** Filter by the object’s `total` field. */
  total?: Maybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TotalSalesLastMonthRecordFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TotalSalesLastMonthRecordFilter>>;
  /** Negates the expression. */
  not?: Maybe<TotalSalesLastMonthRecordFilter>;
};

export type Transaction = Node & {
  __typename?: 'Transaction';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  type: TransactionType;
  paymentId?: Maybe<Scalars['Int']>;
  paymentProcessorId: Scalars['String'];
  bidderPaymentId: Scalars['String'];
  status: Scalars['String'];
  amount: Scalars['Int'];
  response: Scalars['JSON'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Payment` that is related to this `Transaction`. */
  paymentByPaymentId?: Maybe<Payment>;
};

/**
 * A condition to be used against `Transaction` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TransactionCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `type` field. */
  type?: Maybe<TransactionType>;
  /** Checks for equality with the object’s `paymentId` field. */
  paymentId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `paymentProcessorId` field. */
  paymentProcessorId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bidderPaymentId` field. */
  bidderPaymentId?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `status` field. */
  status?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `amount` field. */
  amount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `response` field. */
  response?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `Transaction` object types. All fields are combined with a logical ‘and.’ */
export type TransactionFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: Maybe<TransactionTypeFilter>;
  /** Filter by the object’s `paymentId` field. */
  paymentId?: Maybe<IntFilter>;
  /** Filter by the object’s `paymentProcessorId` field. */
  paymentProcessorId?: Maybe<StringFilter>;
  /** Filter by the object’s `bidderPaymentId` field. */
  bidderPaymentId?: Maybe<StringFilter>;
  /** Filter by the object’s `status` field. */
  status?: Maybe<StringFilter>;
  /** Filter by the object’s `amount` field. */
  amount?: Maybe<IntFilter>;
  /** Filter by the object’s `response` field. */
  response?: Maybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `paymentByPaymentId` relation. */
  paymentByPaymentId?: Maybe<PaymentFilter>;
  /** A related `paymentByPaymentId` exists. */
  paymentByPaymentIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TransactionFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TransactionFilter>>;
  /** Negates the expression. */
  not?: Maybe<TransactionFilter>;
};

export enum TransactionType {
  Charge = 'CHARGE',
  Refund = 'REFUND',
  Credit = 'CREDIT',
  Void = 'VOID'
}

/** A filter to be used against TransactionType fields. All fields are combined with a logical ‘and.’ */
export type TransactionTypeFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<TransactionType>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<TransactionType>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<TransactionType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<TransactionType>;
  /** Included in the specified list. */
  in?: Maybe<Array<TransactionType>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<TransactionType>>;
  /** Less than the specified value. */
  lessThan?: Maybe<TransactionType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<TransactionType>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<TransactionType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<TransactionType>;
};

/** A connection to a list of `Transaction` values. */
export type TransactionsConnection = {
  __typename?: 'TransactionsConnection';
  /** A list of `Transaction` objects. */
  nodes: Array<Transaction>;
  /** A list of edges which contains the `Transaction` and cursor to aid in pagination. */
  edges: Array<TransactionsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Transaction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Transaction` edge in the connection. */
export type TransactionsEdge = {
  __typename?: 'TransactionsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Transaction` at the end of the edge. */
  node: Transaction;
};

/** Methods to use when ordering `Transaction`. */
export enum TransactionsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  PaymentIdAsc = 'PAYMENT_ID_ASC',
  PaymentIdDesc = 'PAYMENT_ID_DESC',
  PaymentProcessorIdAsc = 'PAYMENT_PROCESSOR_ID_ASC',
  PaymentProcessorIdDesc = 'PAYMENT_PROCESSOR_ID_DESC',
  BidderPaymentIdAsc = 'BIDDER_PAYMENT_ID_ASC',
  BidderPaymentIdDesc = 'BIDDER_PAYMENT_ID_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  AmountAsc = 'AMOUNT_ASC',
  AmountDesc = 'AMOUNT_DESC',
  ResponseAsc = 'RESPONSE_ASC',
  ResponseDesc = 'RESPONSE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type Truckload = Node & {
  __typename?: 'Truckload';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  num: Scalars['Int'];
  loadIdentifier: Scalars['String'];
  facilityName: Scalars['String'];
  receivingLocation: Scalars['Int'];
  dateReceived: Scalars['Datetime'];
  bolName: Scalars['String'];
  bolSignedUrl?: Maybe<Scalars['String']>;
  bolExpiredAt?: Maybe<Scalars['Datetime']>;
  itemsOnManifest: Scalars['Int'];
  inventoriedItems: Scalars['Int'];
  shouldRefreshInventoriedItems: Scalars['Boolean'];
  inventoriedItemsRefreshCount: Scalars['Int'];
  lastInventoriedItemsRefresh?: Maybe<Scalars['Datetime']>;
  createdBy: Scalars['Int'];
  createdAt: Scalars['Datetime'];
  /** Reads a single `Location` that is related to this `Truckload`. */
  locationByReceivingLocation?: Maybe<Location>;
  /** Reads a single `User` that is related to this `Truckload`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `TruckloadDatum` that is related to this `Truckload`. */
  truckloadDatumById?: Maybe<TruckloadDatum>;
  bolImageUrl?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `Truckload` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type TruckloadCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `num` field. */
  num?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `loadIdentifier` field. */
  loadIdentifier?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `facilityName` field. */
  facilityName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `receivingLocation` field. */
  receivingLocation?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `dateReceived` field. */
  dateReceived?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `bolName` field. */
  bolName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bolSignedUrl` field. */
  bolSignedUrl?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `bolExpiredAt` field. */
  bolExpiredAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `itemsOnManifest` field. */
  itemsOnManifest?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `inventoriedItems` field. */
  inventoriedItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `shouldRefreshInventoriedItems` field. */
  shouldRefreshInventoriedItems?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `inventoriedItemsRefreshCount` field. */
  inventoriedItemsRefreshCount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `lastInventoriedItemsRefresh` field. */
  lastInventoriedItemsRefresh?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `createdBy` field. */
  createdBy?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `TruckloadDatum` values. */
export type TruckloadDataConnection = {
  __typename?: 'TruckloadDataConnection';
  /** A list of `TruckloadDatum` objects. */
  nodes: Array<TruckloadDatum>;
  /** A list of edges which contains the `TruckloadDatum` and cursor to aid in pagination. */
  edges: Array<TruckloadDataEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `TruckloadDatum` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `TruckloadDatum` edge in the connection. */
export type TruckloadDataEdge = {
  __typename?: 'TruckloadDataEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `TruckloadDatum` at the end of the edge. */
  node: TruckloadDatum;
};

/** Methods to use when ordering `TruckloadDatum`. */
export enum TruckloadDataOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NumAsc = 'NUM_ASC',
  NumDesc = 'NUM_DESC',
  LoadIdentifierAsc = 'LOAD_IDENTIFIER_ASC',
  LoadIdentifierDesc = 'LOAD_IDENTIFIER_DESC',
  FacilityNameAsc = 'FACILITY_NAME_ASC',
  FacilityNameDesc = 'FACILITY_NAME_DESC',
  ReceivingLocationAsc = 'RECEIVING_LOCATION_ASC',
  ReceivingLocationDesc = 'RECEIVING_LOCATION_DESC',
  LocationNameAsc = 'LOCATION_NAME_ASC',
  LocationNameDesc = 'LOCATION_NAME_DESC',
  DateReceivedAsc = 'DATE_RECEIVED_ASC',
  DateReceivedDesc = 'DATE_RECEIVED_DESC',
  CountInventoriedItemsAsc = 'COUNT_INVENTORIED_ITEMS_ASC',
  CountInventoriedItemsDesc = 'COUNT_INVENTORIED_ITEMS_DESC',
  RetailPercentAsc = 'RETAIL_PERCENT_ASC',
  RetailPercentDesc = 'RETAIL_PERCENT_DESC',
  TotalSaleAmountAsc = 'TOTAL_SALE_AMOUNT_ASC',
  TotalSaleAmountDesc = 'TOTAL_SALE_AMOUNT_DESC',
  CountItemsSoldAsc = 'COUNT_ITEMS_SOLD_ASC',
  CountItemsSoldDesc = 'COUNT_ITEMS_SOLD_DESC',
  SoldTotalRetailPriceAsc = 'SOLD_TOTAL_RETAIL_PRICE_ASC',
  SoldTotalRetailPriceDesc = 'SOLD_TOTAL_RETAIL_PRICE_DESC',
  CountItemsUnsoldAsc = 'COUNT_ITEMS_UNSOLD_ASC',
  CountItemsUnsoldDesc = 'COUNT_ITEMS_UNSOLD_DESC',
  UnsoldTotalRetailPriceAsc = 'UNSOLD_TOTAL_RETAIL_PRICE_ASC',
  UnsoldTotalRetailPriceDesc = 'UNSOLD_TOTAL_RETAIL_PRICE_DESC',
  ItemsSoldPercentAsc = 'ITEMS_SOLD_PERCENT_ASC',
  ItemsSoldPercentDesc = 'ITEMS_SOLD_PERCENT_DESC',
  TotalReturnAmountAsc = 'TOTAL_RETURN_AMOUNT_ASC',
  TotalReturnAmountDesc = 'TOTAL_RETURN_AMOUNT_DESC',
  CountItemsReturnedAsc = 'COUNT_ITEMS_RETURNED_ASC',
  CountItemsReturnedDesc = 'COUNT_ITEMS_RETURNED_DESC',
  ReturnRatePercentAsc = 'RETURN_RATE_PERCENT_ASC',
  ReturnRatePercentDesc = 'RETURN_RATE_PERCENT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type TruckloadDatum = Node & {
  __typename?: 'TruckloadDatum';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['UUID'];
  num?: Maybe<Scalars['Int']>;
  loadIdentifier?: Maybe<Scalars['String']>;
  facilityName?: Maybe<Scalars['String']>;
  receivingLocation?: Maybe<Scalars['Int']>;
  locationName?: Maybe<Scalars['String']>;
  dateReceived?: Maybe<Scalars['Datetime']>;
  countInventoriedItems?: Maybe<Scalars['Int']>;
  retailPercent?: Maybe<Scalars['BigFloat']>;
  totalSaleAmount?: Maybe<Scalars['Int']>;
  countItemsSold?: Maybe<Scalars['Int']>;
  soldTotalRetailPrice?: Maybe<Scalars['Int']>;
  countItemsUnsold?: Maybe<Scalars['Int']>;
  unsoldTotalRetailPrice?: Maybe<Scalars['Int']>;
  itemsSoldPercent?: Maybe<Scalars['BigFloat']>;
  totalReturnAmount?: Maybe<Scalars['Int']>;
  countItemsReturned?: Maybe<Scalars['Int']>;
  returnRatePercent?: Maybe<Scalars['BigFloat']>;
  /** Reads a single `Truckload` that is related to this `TruckloadDatum`. */
  truckloadById?: Maybe<Truckload>;
};

/**
 * A condition to be used against `TruckloadDatum` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type TruckloadDatumCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['UUID']>;
  /** Checks for equality with the object’s `num` field. */
  num?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `loadIdentifier` field. */
  loadIdentifier?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `facilityName` field. */
  facilityName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `receivingLocation` field. */
  receivingLocation?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationName` field. */
  locationName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `dateReceived` field. */
  dateReceived?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `countInventoriedItems` field. */
  countInventoriedItems?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `retailPercent` field. */
  retailPercent?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalSaleAmount` field. */
  totalSaleAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `countItemsSold` field. */
  countItemsSold?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `soldTotalRetailPrice` field. */
  soldTotalRetailPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `countItemsUnsold` field. */
  countItemsUnsold?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `unsoldTotalRetailPrice` field. */
  unsoldTotalRetailPrice?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `itemsSoldPercent` field. */
  itemsSoldPercent?: Maybe<Scalars['BigFloat']>;
  /** Checks for equality with the object’s `totalReturnAmount` field. */
  totalReturnAmount?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `countItemsReturned` field. */
  countItemsReturned?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `returnRatePercent` field. */
  returnRatePercent?: Maybe<Scalars['BigFloat']>;
};

/** A filter to be used against `TruckloadDatum` object types. All fields are combined with a logical ‘and.’ */
export type TruckloadDatumFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `num` field. */
  num?: Maybe<IntFilter>;
  /** Filter by the object’s `loadIdentifier` field. */
  loadIdentifier?: Maybe<StringFilter>;
  /** Filter by the object’s `facilityName` field. */
  facilityName?: Maybe<StringFilter>;
  /** Filter by the object’s `receivingLocation` field. */
  receivingLocation?: Maybe<IntFilter>;
  /** Filter by the object’s `locationName` field. */
  locationName?: Maybe<StringFilter>;
  /** Filter by the object’s `dateReceived` field. */
  dateReceived?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `countInventoriedItems` field. */
  countInventoriedItems?: Maybe<IntFilter>;
  /** Filter by the object’s `retailPercent` field. */
  retailPercent?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalSaleAmount` field. */
  totalSaleAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `countItemsSold` field. */
  countItemsSold?: Maybe<IntFilter>;
  /** Filter by the object’s `soldTotalRetailPrice` field. */
  soldTotalRetailPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `countItemsUnsold` field. */
  countItemsUnsold?: Maybe<IntFilter>;
  /** Filter by the object’s `unsoldTotalRetailPrice` field. */
  unsoldTotalRetailPrice?: Maybe<IntFilter>;
  /** Filter by the object’s `itemsSoldPercent` field. */
  itemsSoldPercent?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `totalReturnAmount` field. */
  totalReturnAmount?: Maybe<IntFilter>;
  /** Filter by the object’s `countItemsReturned` field. */
  countItemsReturned?: Maybe<IntFilter>;
  /** Filter by the object’s `returnRatePercent` field. */
  returnRatePercent?: Maybe<BigFloatFilter>;
  /** Filter by the object’s `truckloadById` relation. */
  truckloadById?: Maybe<TruckloadFilter>;
  /** A related `truckloadById` exists. */
  truckloadByIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TruckloadDatumFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TruckloadDatumFilter>>;
  /** Negates the expression. */
  not?: Maybe<TruckloadDatumFilter>;
};

/** A filter to be used against `Truckload` object types. All fields are combined with a logical ‘and.’ */
export type TruckloadFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<UuidFilter>;
  /** Filter by the object’s `num` field. */
  num?: Maybe<IntFilter>;
  /** Filter by the object’s `loadIdentifier` field. */
  loadIdentifier?: Maybe<StringFilter>;
  /** Filter by the object’s `facilityName` field. */
  facilityName?: Maybe<StringFilter>;
  /** Filter by the object’s `receivingLocation` field. */
  receivingLocation?: Maybe<IntFilter>;
  /** Filter by the object’s `dateReceived` field. */
  dateReceived?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `bolName` field. */
  bolName?: Maybe<StringFilter>;
  /** Filter by the object’s `bolSignedUrl` field. */
  bolSignedUrl?: Maybe<StringFilter>;
  /** Filter by the object’s `bolExpiredAt` field. */
  bolExpiredAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `itemsOnManifest` field. */
  itemsOnManifest?: Maybe<IntFilter>;
  /** Filter by the object’s `inventoriedItems` field. */
  inventoriedItems?: Maybe<IntFilter>;
  /** Filter by the object’s `shouldRefreshInventoriedItems` field. */
  shouldRefreshInventoriedItems?: Maybe<BooleanFilter>;
  /** Filter by the object’s `inventoriedItemsRefreshCount` field. */
  inventoriedItemsRefreshCount?: Maybe<IntFilter>;
  /** Filter by the object’s `lastInventoriedItemsRefresh` field. */
  lastInventoriedItemsRefresh?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `createdBy` field. */
  createdBy?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `truckloadDatumById` relation. */
  truckloadDatumById?: Maybe<TruckloadDatumFilter>;
  /** A related `truckloadDatumById` exists. */
  truckloadDatumByIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `locationByReceivingLocation` relation. */
  locationByReceivingLocation?: Maybe<LocationFilter>;
  /** Filter by the object’s `userByCreatedBy` relation. */
  userByCreatedBy?: Maybe<UserFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<TruckloadFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<TruckloadFilter>>;
  /** Negates the expression. */
  not?: Maybe<TruckloadFilter>;
};

/** Represents an update to a `Truckload`. Fields that are set will be updated. */
export type TruckloadPatch = {
  id?: Maybe<Scalars['UUID']>;
  num?: Maybe<Scalars['Int']>;
  loadIdentifier?: Maybe<Scalars['String']>;
  facilityName?: Maybe<Scalars['String']>;
  receivingLocation?: Maybe<Scalars['Int']>;
  dateReceived?: Maybe<Scalars['Datetime']>;
  bolName?: Maybe<Scalars['String']>;
  bolSignedUrl?: Maybe<Scalars['String']>;
  bolExpiredAt?: Maybe<Scalars['Datetime']>;
  itemsOnManifest?: Maybe<Scalars['Int']>;
  inventoriedItems?: Maybe<Scalars['Int']>;
  shouldRefreshInventoriedItems?: Maybe<Scalars['Boolean']>;
  inventoriedItemsRefreshCount?: Maybe<Scalars['Int']>;
  lastInventoriedItemsRefresh?: Maybe<Scalars['Datetime']>;
  createdBy?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['Datetime']>;
};

/** A connection to a list of `Truckload` values. */
export type TruckloadsConnection = {
  __typename?: 'TruckloadsConnection';
  /** A list of `Truckload` objects. */
  nodes: Array<Truckload>;
  /** A list of edges which contains the `Truckload` and cursor to aid in pagination. */
  edges: Array<TruckloadsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Truckload` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Truckload` edge in the connection. */
export type TruckloadsEdge = {
  __typename?: 'TruckloadsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Truckload` at the end of the edge. */
  node: Truckload;
};

/** Methods to use when ordering `Truckload`. */
export enum TruckloadsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NumAsc = 'NUM_ASC',
  NumDesc = 'NUM_DESC',
  LoadIdentifierAsc = 'LOAD_IDENTIFIER_ASC',
  LoadIdentifierDesc = 'LOAD_IDENTIFIER_DESC',
  FacilityNameAsc = 'FACILITY_NAME_ASC',
  FacilityNameDesc = 'FACILITY_NAME_DESC',
  ReceivingLocationAsc = 'RECEIVING_LOCATION_ASC',
  ReceivingLocationDesc = 'RECEIVING_LOCATION_DESC',
  DateReceivedAsc = 'DATE_RECEIVED_ASC',
  DateReceivedDesc = 'DATE_RECEIVED_DESC',
  BolNameAsc = 'BOL_NAME_ASC',
  BolNameDesc = 'BOL_NAME_DESC',
  BolSignedUrlAsc = 'BOL_SIGNED_URL_ASC',
  BolSignedUrlDesc = 'BOL_SIGNED_URL_DESC',
  BolExpiredAtAsc = 'BOL_EXPIRED_AT_ASC',
  BolExpiredAtDesc = 'BOL_EXPIRED_AT_DESC',
  ItemsOnManifestAsc = 'ITEMS_ON_MANIFEST_ASC',
  ItemsOnManifestDesc = 'ITEMS_ON_MANIFEST_DESC',
  InventoriedItemsAsc = 'INVENTORIED_ITEMS_ASC',
  InventoriedItemsDesc = 'INVENTORIED_ITEMS_DESC',
  ShouldRefreshInventoriedItemsAsc = 'SHOULD_REFRESH_INVENTORIED_ITEMS_ASC',
  ShouldRefreshInventoriedItemsDesc = 'SHOULD_REFRESH_INVENTORIED_ITEMS_DESC',
  InventoriedItemsRefreshCountAsc = 'INVENTORIED_ITEMS_REFRESH_COUNT_ASC',
  InventoriedItemsRefreshCountDesc = 'INVENTORIED_ITEMS_REFRESH_COUNT_DESC',
  LastInventoriedItemsRefreshAsc = 'LAST_INVENTORIED_ITEMS_REFRESH_ASC',
  LastInventoriedItemsRefreshDesc = 'LAST_INVENTORIED_ITEMS_REFRESH_DESC',
  CreatedByAsc = 'CREATED_BY_ASC',
  CreatedByDesc = 'CREATED_BY_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}


/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<Scalars['UUID']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<Scalars['UUID']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<Scalars['UUID']>;
  /** Included in the specified list. */
  in?: Maybe<Array<Scalars['UUID']>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<Scalars['UUID']>>;
  /** Less than the specified value. */
  lessThan?: Maybe<Scalars['UUID']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<Scalars['UUID']>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<Scalars['UUID']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<Scalars['UUID']>;
};

/** All input for the `updateAuctionByBwId` mutation. */
export type UpdateAuctionByBwIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Auction` being updated. */
  auctionPatch: AuctionPatch;
  bwId: Scalars['Int'];
};

/** All input for the `updateAuctionById` mutation. */
export type UpdateAuctionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Auction` being updated. */
  auctionPatch: AuctionPatch;
  id: Scalars['Int'];
};

/** All input for the `updateAuctionByInvId` mutation. */
export type UpdateAuctionByInvIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Auction` being updated. */
  auctionPatch: AuctionPatch;
  invId: Scalars['Int'];
};

/** All input for the `updateAuction` mutation. */
export type UpdateAuctionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Auction` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Auction` being updated. */
  auctionPatch: AuctionPatch;
};

/** The output of our update `Auction` mutation. */
export type UpdateAuctionPayload = {
  __typename?: 'UpdateAuctionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Auction` that was updated by this mutation. */
  auction?: Maybe<Auction>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `Auction`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `Auction`. May be used by Relay 1. */
  auctionEdge?: Maybe<AuctionsEdge>;
};


/** The output of our update `Auction` mutation. */
export type UpdateAuctionPayloadAuctionEdgeArgs = {
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
};

/** All input for the `updateBidderByBwId` mutation. */
export type UpdateBidderByBwIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Bidder` being updated. */
  bidderPatch: BidderPatch;
  bwId: Scalars['Int'];
};

/** All input for the `updateBidderById` mutation. */
export type UpdateBidderByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Bidder` being updated. */
  bidderPatch: BidderPatch;
  id: Scalars['Int'];
};

/** All input for the `updateBidder` mutation. */
export type UpdateBidderInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Bidder` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Bidder` being updated. */
  bidderPatch: BidderPatch;
};

/** The output of our update `Bidder` mutation. */
export type UpdateBidderPayload = {
  __typename?: 'UpdateBidderPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Bidder` that was updated by this mutation. */
  bidder?: Maybe<Bidder>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Bidder`. */
  userByProcessInvoicePaymentChangedBy?: Maybe<User>;
  /** An edge for our `Bidder`. May be used by Relay 1. */
  bidderEdge?: Maybe<BiddersEdge>;
};


/** The output of our update `Bidder` mutation. */
export type UpdateBidderPayloadBidderEdgeArgs = {
  orderBy?: Maybe<Array<BiddersOrderBy>>;
};

/** All input for the `updateCustomerNoteById` mutation. */
export type UpdateCustomerNoteByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `CustomerNote` being updated. */
  customerNotePatch: CustomerNotePatch;
  id: Scalars['Int'];
};

/** All input for the `updateCustomerNote` mutation. */
export type UpdateCustomerNoteInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `CustomerNote` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `CustomerNote` being updated. */
  customerNotePatch: CustomerNotePatch;
};

/** The output of our update `CustomerNote` mutation. */
export type UpdateCustomerNotePayload = {
  __typename?: 'UpdateCustomerNotePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `CustomerNote` that was updated by this mutation. */
  customerNote?: Maybe<CustomerNote>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Bidder` that is related to this `CustomerNote`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `CustomerNote`. */
  userByCreatedBy?: Maybe<User>;
  /** An edge for our `CustomerNote`. May be used by Relay 1. */
  customerNoteEdge?: Maybe<CustomerNotesEdge>;
};


/** The output of our update `CustomerNote` mutation. */
export type UpdateCustomerNotePayloadCustomerNoteEdgeArgs = {
  orderBy?: Maybe<Array<CustomerNotesOrderBy>>;
};

/** All input for the `updateInvoiceAdjustmentById` mutation. */
export type UpdateInvoiceAdjustmentByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `InvoiceAdjustment` being updated. */
  invoiceAdjustmentPatch: InvoiceAdjustmentPatch;
  id: Scalars['Int'];
};

/** All input for the `updateInvoiceAdjustment` mutation. */
export type UpdateInvoiceAdjustmentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `InvoiceAdjustment` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `InvoiceAdjustment` being updated. */
  invoiceAdjustmentPatch: InvoiceAdjustmentPatch;
};

/** The output of our update `InvoiceAdjustment` mutation. */
export type UpdateInvoiceAdjustmentPayload = {
  __typename?: 'UpdateInvoiceAdjustmentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `InvoiceAdjustment` that was updated by this mutation. */
  invoiceAdjustment?: Maybe<InvoiceAdjustment>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Invoice` that is related to this `InvoiceAdjustment`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** An edge for our `InvoiceAdjustment`. May be used by Relay 1. */
  invoiceAdjustmentEdge?: Maybe<InvoiceAdjustmentsEdge>;
};


/** The output of our update `InvoiceAdjustment` mutation. */
export type UpdateInvoiceAdjustmentPayloadInvoiceAdjustmentEdgeArgs = {
  orderBy?: Maybe<Array<InvoiceAdjustmentsOrderBy>>;
};

/** All input for the `updateItemByBwId` mutation. */
export type UpdateItemByBwIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Item` being updated. */
  itemPatch: ItemPatch;
  bwId: Scalars['Int'];
};

/** All input for the `updateItemById` mutation. */
export type UpdateItemByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Item` being updated. */
  itemPatch: ItemPatch;
  id: Scalars['Int'];
};

/** All input for the `updateItemImageById` mutation. */
export type UpdateItemImageByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ItemImage` being updated. */
  itemImagePatch: ItemImagePatch;
  id: Scalars['Int'];
};

/** All input for the `updateItemImage` mutation. */
export type UpdateItemImageInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ItemImage` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ItemImage` being updated. */
  itemImagePatch: ItemImagePatch;
};

/** The output of our update `ItemImage` mutation. */
export type UpdateItemImagePayload = {
  __typename?: 'UpdateItemImagePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ItemImage` that was updated by this mutation. */
  itemImage?: Maybe<ItemImage>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `ItemImage`. */
  itemByItemId?: Maybe<Item>;
  /** An edge for our `ItemImage`. May be used by Relay 1. */
  itemImageEdge?: Maybe<ItemImagesEdge>;
};


/** The output of our update `ItemImage` mutation. */
export type UpdateItemImagePayloadItemImageEdgeArgs = {
  orderBy?: Maybe<Array<ItemImagesOrderBy>>;
};

/** All input for the `updateItem` mutation. */
export type UpdateItemInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Item` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Item` being updated. */
  itemPatch: ItemPatch;
};

/** The output of our update `Item` mutation. */
export type UpdateItemPayload = {
  __typename?: 'UpdateItemPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Item` that was updated by this mutation. */
  item?: Maybe<Item>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Auction` that is related to this `Item`. */
  auctionByAuctionId?: Maybe<Auction>;
  /** Reads a single `Invoice` that is related to this `Item`. */
  invoiceByInvoiceId?: Maybe<Invoice>;
  /** Reads a single `Payment` that is related to this `Item`. */
  paymentByPaymentId?: Maybe<Payment>;
  /** Reads a single `Bidder` that is related to this `Item`. */
  bidderByBidderId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `Item`. */
  userByDeletedFromInvoiceBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userBySetAsideBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `Item`. */
  userByIsDeletedBy?: Maybe<User>;
  /** An edge for our `Item`. May be used by Relay 1. */
  itemEdge?: Maybe<ItemsEdge>;
};


/** The output of our update `Item` mutation. */
export type UpdateItemPayloadItemEdgeArgs = {
  orderBy?: Maybe<Array<ItemsOrderBy>>;
};

/** All input for the `updateLocationById` mutation. */
export type UpdateLocationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Location` being updated. */
  locationPatch: LocationPatch;
  id: Scalars['Int'];
};

/** All input for the `updateLocationByInvoicePrefix` mutation. */
export type UpdateLocationByInvoicePrefixInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Location` being updated. */
  locationPatch: LocationPatch;
  invoicePrefix: Scalars['String'];
};

/** All input for the `updateLocationByName` mutation. */
export type UpdateLocationByNameInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Location` being updated. */
  locationPatch: LocationPatch;
  name: Scalars['String'];
};

/** All input for the `updateLocation` mutation. */
export type UpdateLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Location` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Location` being updated. */
  locationPatch: LocationPatch;
};

/** The output of our update `Location` mutation. */
export type UpdateLocationPayload = {
  __typename?: 'UpdateLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Location` that was updated by this mutation. */
  location?: Maybe<Location>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Location`. May be used by Relay 1. */
  locationEdge?: Maybe<LocationsEdge>;
};


/** The output of our update `Location` mutation. */
export type UpdateLocationPayloadLocationEdgeArgs = {
  orderBy?: Maybe<Array<LocationsOrderBy>>;
};

/** All input for the `updatePicklistQueueById` mutation. */
export type UpdatePicklistQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PicklistQueue` being updated. */
  picklistQueuePatch: PicklistQueuePatch;
  id: Scalars['UUID'];
};

/** All input for the `updatePicklistQueueByItemId` mutation. */
export type UpdatePicklistQueueByItemIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PicklistQueue` being updated. */
  picklistQueuePatch: PicklistQueuePatch;
  itemId: Scalars['Int'];
};

/** All input for the `updatePicklistQueue` mutation. */
export type UpdatePicklistQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PicklistQueue` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PicklistQueue` being updated. */
  picklistQueuePatch: PicklistQueuePatch;
};

/** The output of our update `PicklistQueue` mutation. */
export type UpdatePicklistQueuePayload = {
  __typename?: 'UpdatePicklistQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PicklistQueue` that was updated by this mutation. */
  picklistQueue?: Maybe<PicklistQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `PicklistQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `PicklistQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `PicklistQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PicklistQueue`. */
  userByStepUpdatedBy?: Maybe<User>;
  /** An edge for our `PicklistQueue`. May be used by Relay 1. */
  picklistQueueEdge?: Maybe<PicklistQueuesEdge>;
};


/** The output of our update `PicklistQueue` mutation. */
export type UpdatePicklistQueuePayloadPicklistQueueEdgeArgs = {
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
};

/** All input for the `updatePickupCustomerById` mutation. */
export type UpdatePickupCustomerByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PickupCustomer` being updated. */
  pickupCustomerPatch: PickupCustomerPatch;
  id: Scalars['UUID'];
};

/** All input for the `updatePickupCustomerByLocationIdAndCustomerId` mutation. */
export type UpdatePickupCustomerByLocationIdAndCustomerIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PickupCustomer` being updated. */
  pickupCustomerPatch: PickupCustomerPatch;
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
};

/** All input for the `updatePickupCustomerByLocationIdAndNumber` mutation. */
export type UpdatePickupCustomerByLocationIdAndNumberInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PickupCustomer` being updated. */
  pickupCustomerPatch: PickupCustomerPatch;
  locationId: Scalars['Int'];
  number: Scalars['Int'];
};

/** All input for the `updatePickupCustomer` mutation. */
export type UpdatePickupCustomerInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PickupCustomer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PickupCustomer` being updated. */
  pickupCustomerPatch: PickupCustomerPatch;
};

/** The output of our update `PickupCustomer` mutation. */
export type UpdatePickupCustomerPayload = {
  __typename?: 'UpdatePickupCustomerPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupCustomer` that was updated by this mutation. */
  pickupCustomer?: Maybe<PickupCustomer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `PickupCustomer`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupCustomer`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupCustomer`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `PickupCustomer`. May be used by Relay 1. */
  pickupCustomerEdge?: Maybe<PickupCustomersEdge>;
};


/** The output of our update `PickupCustomer` mutation. */
export type UpdatePickupCustomerPayloadPickupCustomerEdgeArgs = {
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
};

/** All input for the `updatePickupQueueById` mutation. */
export type UpdatePickupQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PickupQueue` being updated. */
  pickupQueuePatch: PickupQueuePatch;
  id: Scalars['Int'];
};

/** All input for the `updatePickupQueue` mutation. */
export type UpdatePickupQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PickupQueue` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PickupQueue` being updated. */
  pickupQueuePatch: PickupQueuePatch;
};

/** The output of our update `PickupQueue` mutation. */
export type UpdatePickupQueuePayload = {
  __typename?: 'UpdatePickupQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupQueue` that was updated by this mutation. */
  pickupQueue?: Maybe<PickupQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `PickupQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Location` that is related to this `PickupQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `Bidder` that is related to this `PickupQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `PickupQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `PickupSession` that is related to this `PickupQueue`. */
  pickupSessionBySessionId?: Maybe<PickupSession>;
  /** An edge for our `PickupQueue`. May be used by Relay 1. */
  pickupQueueEdge?: Maybe<PickupQueuesEdge>;
};


/** The output of our update `PickupQueue` mutation. */
export type UpdatePickupQueuePayloadPickupQueueEdgeArgs = {
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
};

/** All input for the `updatePickupSessionById` mutation. */
export type UpdatePickupSessionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `PickupSession` being updated. */
  pickupSessionPatch: PickupSessionPatch;
  id: Scalars['UUID'];
};

/** All input for the `updatePickupSession` mutation. */
export type UpdatePickupSessionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `PickupSession` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `PickupSession` being updated. */
  pickupSessionPatch: PickupSessionPatch;
};

/** The output of our update `PickupSession` mutation. */
export type UpdatePickupSessionPayload = {
  __typename?: 'UpdatePickupSessionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupSession` that was updated by this mutation. */
  pickupSession?: Maybe<PickupSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `PickupSession`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupSession`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupSession`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `PickupSession`. May be used by Relay 1. */
  pickupSessionEdge?: Maybe<PickupSessionsEdge>;
};


/** The output of our update `PickupSession` mutation. */
export type UpdatePickupSessionPayloadPickupSessionEdgeArgs = {
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
};

/** All input for the `updatePrinterById` mutation. */
export type UpdatePrinterByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Printer` being updated. */
  printerPatch: PrinterPatch;
  id: Scalars['Int'];
};

/** All input for the `updatePrinter` mutation. */
export type UpdatePrinterInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Printer` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Printer` being updated. */
  printerPatch: PrinterPatch;
};

/** The output of our update `Printer` mutation. */
export type UpdatePrinterPayload = {
  __typename?: 'UpdatePrinterPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Printer` that was updated by this mutation. */
  printer?: Maybe<Printer>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `Printer`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `Printer`. May be used by Relay 1. */
  printerEdge?: Maybe<PrintersEdge>;
};


/** The output of our update `Printer` mutation. */
export type UpdatePrinterPayloadPrinterEdgeArgs = {
  orderBy?: Maybe<Array<PrintersOrderBy>>;
};

/** All input for the `updateReturnOptionById` mutation. */
export type UpdateReturnOptionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ReturnOption` being updated. */
  returnOptionPatch: ReturnOptionPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateReturnOption` mutation. */
export type UpdateReturnOptionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReturnOption` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ReturnOption` being updated. */
  returnOptionPatch: ReturnOptionPatch;
};

/** The output of our update `ReturnOption` mutation. */
export type UpdateReturnOptionPayload = {
  __typename?: 'UpdateReturnOptionPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ReturnOption` that was updated by this mutation. */
  returnOption?: Maybe<ReturnOption>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `ReturnOption`. May be used by Relay 1. */
  returnOptionEdge?: Maybe<ReturnOptionsEdge>;
};


/** The output of our update `ReturnOption` mutation. */
export type UpdateReturnOptionPayloadReturnOptionEdgeArgs = {
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
};

/** All input for the `updateReturnQueueById` mutation. */
export type UpdateReturnQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ReturnQueue` being updated. */
  returnQueuePatch: ReturnQueuePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateReturnQueue` mutation. */
export type UpdateReturnQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ReturnQueue` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ReturnQueue` being updated. */
  returnQueuePatch: ReturnQueuePatch;
};

/** The output of our update `ReturnQueue` mutation. */
export type UpdateReturnQueuePayload = {
  __typename?: 'UpdateReturnQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ReturnQueue` that was updated by this mutation. */
  returnQueue?: Maybe<ReturnQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `ReturnOption` that is related to this `ReturnQueue`. */
  returnOptionByOptionId?: Maybe<ReturnOption>;
  /** Reads a single `Item` that is related to this `ReturnQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `User` that is related to this `ReturnQueue`. */
  userByCreatedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `ReturnQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** Reads a single `User` that is related to this `ReturnQueue`. */
  userByUpdatedBy?: Maybe<User>;
  /** An edge for our `ReturnQueue`. May be used by Relay 1. */
  returnQueueEdge?: Maybe<ReturnQueuesEdge>;
};


/** The output of our update `ReturnQueue` mutation. */
export type UpdateReturnQueuePayloadReturnQueueEdgeArgs = {
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
};

/** All input for the `updateShippingQueueById` mutation. */
export type UpdateShippingQueueByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ShippingQueue` being updated. */
  shippingQueuePatch: ShippingQueuePatch;
  id: Scalars['UUID'];
};

/** All input for the `updateShippingQueueByItemId` mutation. */
export type UpdateShippingQueueByItemIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `ShippingQueue` being updated. */
  shippingQueuePatch: ShippingQueuePatch;
  itemId: Scalars['Int'];
};

/** All input for the `updateShippingQueue` mutation. */
export type UpdateShippingQueueInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `ShippingQueue` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `ShippingQueue` being updated. */
  shippingQueuePatch: ShippingQueuePatch;
};

/** The output of our update `ShippingQueue` mutation. */
export type UpdateShippingQueuePayload = {
  __typename?: 'UpdateShippingQueuePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ShippingQueue` that was updated by this mutation. */
  shippingQueue?: Maybe<ShippingQueue>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Item` that is related to this `ShippingQueue`. */
  itemByItemId?: Maybe<Item>;
  /** Reads a single `Bidder` that is related to this `ShippingQueue`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `Location` that is related to this `ShippingQueue`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByAssignedUserId?: Maybe<User>;
  /** Reads a single `User` that is related to this `ShippingQueue`. */
  userByCompletedBy?: Maybe<User>;
  /** An edge for our `ShippingQueue`. May be used by Relay 1. */
  shippingQueueEdge?: Maybe<ShippingQueuesEdge>;
};


/** The output of our update `ShippingQueue` mutation. */
export type UpdateShippingQueuePayloadShippingQueueEdgeArgs = {
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
};

/** All input for the `updateTruckloadById` mutation. */
export type UpdateTruckloadByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `Truckload` being updated. */
  truckloadPatch: TruckloadPatch;
  id: Scalars['UUID'];
};

/** All input for the `updateTruckload` mutation. */
export type UpdateTruckloadInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `Truckload` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `Truckload` being updated. */
  truckloadPatch: TruckloadPatch;
};

/** The output of our update `Truckload` mutation. */
export type UpdateTruckloadPayload = {
  __typename?: 'UpdateTruckloadPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `Truckload` that was updated by this mutation. */
  truckload?: Maybe<Truckload>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `Truckload`. */
  locationByReceivingLocation?: Maybe<Location>;
  /** Reads a single `User` that is related to this `Truckload`. */
  userByCreatedBy?: Maybe<User>;
  /** An edge for our `Truckload`. May be used by Relay 1. */
  truckloadEdge?: Maybe<TruckloadsEdge>;
};


/** The output of our update `Truckload` mutation. */
export type UpdateTruckloadPayloadTruckloadEdgeArgs = {
  orderBy?: Maybe<Array<TruckloadsOrderBy>>;
};

/** All input for the `updateUserActiveState` mutation. */
export type UpdateUserActiveStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
  active: Scalars['Boolean'];
};

/** The output of our `updateUserActiveState` mutation. */
export type UpdateUserActiveStatePayload = {
  __typename?: 'UpdateUserActiveStatePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `updateUserActiveState` mutation. */
export type UpdateUserActiveStatePayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
  role: UserRoles;
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password?: Maybe<Scalars['String']>;
  pin?: Maybe<Scalars['String']>;
};

/** All input for the `updateUserLocationByUserId` mutation. */
export type UpdateUserLocationByUserIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserLocation` being updated. */
  userLocationPatch: UserLocationPatch;
  userId: Scalars['Int'];
};

/** All input for the `updateUserLocation` mutation. */
export type UpdateUserLocationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserLocation` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserLocation` being updated. */
  userLocationPatch: UserLocationPatch;
};

/** The output of our update `UserLocation` mutation. */
export type UpdateUserLocationPayload = {
  __typename?: 'UpdateUserLocationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserLocation` that was updated by this mutation. */
  userLocation?: Maybe<UserLocation>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserLocation`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Location` that is related to this `UserLocation`. */
  locationByLocationId?: Maybe<Location>;
  /** An edge for our `UserLocation`. May be used by Relay 1. */
  userLocationEdge?: Maybe<UserLocationsEdge>;
};


/** The output of our update `UserLocation` mutation. */
export type UpdateUserLocationPayloadUserLocationEdgeArgs = {
  orderBy?: Maybe<Array<UserLocationsOrderBy>>;
};

/** All input for the `updateUserNotificationById` mutation. */
export type UpdateUserNotificationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** An object where the defined keys will be set on the `UserNotification` being updated. */
  userNotificationPatch: UserNotificationPatch;
  id: Scalars['Int'];
};

/** All input for the `updateUserNotification` mutation. */
export type UpdateUserNotificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The globally unique `ID` which will identify a single `UserNotification` to be updated. */
  nodeId: Scalars['ID'];
  /** An object where the defined keys will be set on the `UserNotification` being updated. */
  userNotificationPatch: UserNotificationPatch;
};

/** The output of our update `UserNotification` mutation. */
export type UpdateUserNotificationPayload = {
  __typename?: 'UpdateUserNotificationPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `UserNotification` that was updated by this mutation. */
  userNotification?: Maybe<UserNotification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserNotification`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `UserNotification`. May be used by Relay 1. */
  userNotificationEdge?: Maybe<UserNotificationsEdge>;
};


/** The output of our update `UserNotification` mutation. */
export type UpdateUserNotificationPayloadUserNotificationEdgeArgs = {
  orderBy?: Maybe<Array<UserNotificationsOrderBy>>;
};

/** The output of our `updateUser` mutation. */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};


/** The output of our `updateUser` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: Maybe<Array<UsersOrderBy>>;
};


export type UploadItemsImageInput = {
  itemId: Scalars['Int'];
  image: Scalars['Upload'];
};

export type UploadItemsImagePayload = {
  __typename?: 'UploadItemsImagePayload';
  itemImage?: Maybe<ItemImage>;
  query?: Maybe<Query>;
};

export type UploadSignatureInput = {
  itemIds: Array<Maybe<Scalars['Int']>>;
  image: Scalars['Upload'];
};

export type UploadSignaturePayload = {
  __typename?: 'UploadSignaturePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export type UploadTruckloadBillOfLadingInput = {
  truckloadId: Scalars['String'];
  billOfLading: Scalars['Upload'];
};

export type UploadTruckloadInput = {
  facilityName: Scalars['String'];
  loadIdentifier: Scalars['String'];
  receivingLocationId: Scalars['Int'];
  numOfLabels?: Maybe<Scalars['Int']>;
  printerId?: Maybe<Scalars['Int']>;
  billOfLading: Scalars['Upload'];
};

export type UploadTruckloadPayload = {
  __typename?: 'UploadTruckloadPayload';
  success?: Maybe<Scalars['Boolean']>;
};

/** All input for the upsert `PickupSession` mutation. */
export type UpsertPickupSessionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupSession` to be upserted by this mutation. */
  pickupSession: PickupSessionInput;
};

/** The output of our upsert `PickupSession` mutation. */
export type UpsertPickupSessionPayload = {
  __typename?: 'UpsertPickupSessionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `PickupSession` that was upserted by this mutation. */
  pickupSession?: Maybe<PickupSession>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Location` that is related to this `PickupSession`. */
  locationByLocationId?: Maybe<Location>;
  /** Reads a single `Bidder` that is related to this `PickupSession`. */
  bidderByCustomerId?: Maybe<Bidder>;
  /** Reads a single `User` that is related to this `PickupSession`. */
  userByUserId?: Maybe<User>;
  /** An edge for our `PickupSession`. May be used by Relay 1. */
  pickupSessionEdge?: Maybe<PickupSessionsEdge>;
};


/** The output of our upsert `PickupSession` mutation. */
export type UpsertPickupSessionPayloadPickupSessionEdgeArgs = {
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
};

/** Where conditions for the upsert `PickupSession` mutation. */
export type UpsertPickupSessionWhere = {
  id?: Maybe<Scalars['UUID']>;
};

/** All input for the upsert `ReturnOption` mutation. */
export type UpsertReturnOptionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ReturnOption` to be upserted by this mutation. */
  returnOption: ReturnOptionInput;
};

/** The output of our upsert `ReturnOption` mutation. */
export type UpsertReturnOptionPayload = {
  __typename?: 'UpsertReturnOptionPayload';
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The `ReturnOption` that was upserted by this mutation. */
  returnOption?: Maybe<ReturnOption>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `ReturnOption`. May be used by Relay 1. */
  returnOptionEdge?: Maybe<ReturnOptionsEdge>;
};


/** The output of our upsert `ReturnOption` mutation. */
export type UpsertReturnOptionPayloadReturnOptionEdgeArgs = {
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
};

/** Where conditions for the upsert `ReturnOption` mutation. */
export type UpsertReturnOptionWhere = {
  id?: Maybe<Scalars['UUID']>;
};

export type User = Node & {
  __typename?: 'User';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  /** Unique identifier for the user. */
  id: Scalars['Int'];
  /** Public-facing username of the user. */
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  role: UserRoles;
  isActive: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByProcessInvoicePaymentChangedBy: BiddersConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProcessPaymentChangedBy: InvoicesConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAlternatePaymentCreatedBy: InvoicesConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByDeletedFromInvoiceBy: ItemsConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySetAsideBy: ItemsConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByIsDeletedBy: ItemsConnection;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByCreatedBy: BidderCreditHistoriesConnection;
  /** Reads and enables pagination through a set of `ItemRackLocation`. */
  itemRackLocationsByUpdatedBy: ItemRackLocationsConnection;
  /** Reads and enables pagination through a set of `InvoiceNote`. */
  invoiceNotesByCreatedBy: InvoiceNotesConnection;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByCreatedBy: InvoiceItemReturnsConnection;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByUpdatedBy: InvoiceItemReturnsConnection;
  /** Reads and enables pagination through a set of `UserNotification`. */
  userNotificationsByUserId: UserNotificationsConnection;
  /** Reads and enables pagination through a set of `JobQueue`. */
  jobQueuesByCreatedBy: JobQueuesConnection;
  /** Reads a single `UserLocation` that is related to this `User`. */
  userLocationByUserId?: Maybe<UserLocation>;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByAssignedUserId: PickupQueuesConnection;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCompletedBy: PickupQueuesConnection;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCreatedBy: PickupQueuesConnection;
  /** Reads and enables pagination through a set of `ItemActionHistory`. */
  itemActionHistoriesByCreatedBy: ItemActionHistoriesConnection;
  /** Reads and enables pagination through a set of `CustomerNote`. */
  customerNotesByCreatedBy: CustomerNotesConnection;
  /** Reads and enables pagination through a set of `InvoiceItemReturnHistory`. */
  invoiceItemReturnHistoriesByBy: InvoiceItemReturnHistoriesConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCreatedBy: ReturnQueuesConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCompletedBy: ReturnQueuesConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByUpdatedBy: ReturnQueuesConnection;
  /** Reads and enables pagination through a set of `Truckload`. */
  truckloadsByCreatedBy: TruckloadsConnection;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByAssignedUserId: PicklistQueuesConnection;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCreatedBy: PicklistQueuesConnection;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCompletedBy: PicklistQueuesConnection;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByStepUpdatedBy: PicklistQueuesConnection;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByAssignedUserId: ShippingQueuesConnection;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByCompletedBy: ShippingQueuesConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByUserId: PickupSessionsConnection;
  /** Reads and enables pagination through a set of `PickupCustomer`. */
  pickupCustomersByUserId: PickupCustomersConnection;
  /** Reads and enables pagination through a set of `CustomerActionsView`. */
  customerActionsViewsByCreatedBy: CustomerActionsViewsConnection;
  /** Reads and enables pagination through a set of `CustomerAlertsView`. */
  customerAlertsViewsByCreatedBy: CustomerAlertsViewsConnection;
  /** Reads and enables pagination through a set of `LivePickupQueue`. */
  livePickupQueuesByAssignedUserId: LivePickupQueuesConnection;
  name?: Maybe<Scalars['String']>;
  pincode?: Maybe<Scalars['String']>;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByInvoiceProcessPaymentChangedByAndAuctionId: UserAuctionsByInvoiceProcessPaymentChangedByAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByInvoiceProcessPaymentChangedByAndBidderId: UserBiddersByInvoiceProcessPaymentChangedByAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedBy: UserUsersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByInvoiceProcessPaymentChangedByAndLocationId: UserLocationsByInvoiceProcessPaymentChangedByAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByInvoiceAlternatePaymentCreatedByAndAuctionId: UserAuctionsByInvoiceAlternatePaymentCreatedByAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByInvoiceAlternatePaymentCreatedByAndBidderId: UserBiddersByInvoiceAlternatePaymentCreatedByAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedBy: UserUsersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByInvoiceAlternatePaymentCreatedByAndLocationId: UserLocationsByInvoiceAlternatePaymentCreatedByAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByItemDeletedFromInvoiceByAndAuctionId: UserAuctionsByItemDeletedFromInvoiceByAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByItemDeletedFromInvoiceByAndInvoiceId: UserInvoicesByItemDeletedFromInvoiceByAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByItemDeletedFromInvoiceByAndPaymentId: UserPaymentsByItemDeletedFromInvoiceByAndPaymentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByItemDeletedFromInvoiceByAndBidderId: UserBiddersByItemDeletedFromInvoiceByAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemDeletedFromInvoiceByAndSetAsideBy: UserUsersByItemDeletedFromInvoiceByAndSetAsideByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemDeletedFromInvoiceByAndIsDeletedBy: UserUsersByItemDeletedFromInvoiceByAndIsDeletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByItemSetAsideByAndAuctionId: UserAuctionsByItemSetAsideByAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByItemSetAsideByAndInvoiceId: UserInvoicesByItemSetAsideByAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByItemSetAsideByAndPaymentId: UserPaymentsByItemSetAsideByAndPaymentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByItemSetAsideByAndBidderId: UserBiddersByItemSetAsideByAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemSetAsideByAndDeletedFromInvoiceBy: UserUsersByItemSetAsideByAndDeletedFromInvoiceByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemSetAsideByAndIsDeletedBy: UserUsersByItemSetAsideByAndIsDeletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByItemIsDeletedByAndAuctionId: UserAuctionsByItemIsDeletedByAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByItemIsDeletedByAndInvoiceId: UserInvoicesByItemIsDeletedByAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Payment`. */
  paymentsByItemIsDeletedByAndPaymentId: UserPaymentsByItemIsDeletedByAndPaymentIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByItemIsDeletedByAndBidderId: UserBiddersByItemIsDeletedByAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemIsDeletedByAndDeletedFromInvoiceBy: UserUsersByItemIsDeletedByAndDeletedFromInvoiceByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByItemIsDeletedByAndSetAsideBy: UserUsersByItemIsDeletedByAndSetAsideByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByBidderCreditHistoryCreatedByAndBidderId: UserBiddersByBidderCreditHistoryCreatedByAndBidderIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderCreditHistoryCreatedByAndInvoiceId: UserInvoicesByBidderCreditHistoryCreatedByAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Auction`. */
  auctionsByBidderCreditHistoryCreatedByAndAuctionId: UserAuctionsByBidderCreditHistoryCreatedByAndAuctionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderCreditHistoryCreatedByAndItemId: UserItemsByBidderCreditHistoryCreatedByAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByInvoiceNoteCreatedByAndInvoiceId: UserInvoicesByInvoiceNoteCreatedByAndInvoiceIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceItemReturnCreatedByAndInvoiceItemId: UserItemsByInvoiceItemReturnCreatedByAndInvoiceItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnCreatedByAndUpdatedBy: UserUsersByInvoiceItemReturnCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  returnOptionsByInvoiceItemReturnCreatedByAndOptionId: UserReturnOptionsByInvoiceItemReturnCreatedByAndOptionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByInvoiceItemReturnCreatedByAndQueueId: UserReturnQueuesByInvoiceItemReturnCreatedByAndQueueIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceItemReturnUpdatedByAndInvoiceItemId: UserItemsByInvoiceItemReturnUpdatedByAndInvoiceItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByInvoiceItemReturnUpdatedByAndCreatedBy: UserUsersByInvoiceItemReturnUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  returnOptionsByInvoiceItemReturnUpdatedByAndOptionId: UserReturnOptionsByInvoiceItemReturnUpdatedByAndOptionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByInvoiceItemReturnUpdatedByAndQueueId: UserReturnQueuesByInvoiceItemReturnUpdatedByAndQueueIdManyToManyConnection;
  /** Reads and enables pagination through a set of `UserNotification`. */
  userNotificationsByJobQueueCreatedByAndUserNotificationId: UserUserNotificationsByJobQueueCreatedByAndUserNotificationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPickupQueueAssignedUserIdAndItemId: UserItemsByPickupQueueAssignedUserIdAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupQueueAssignedUserIdAndLocationId: UserLocationsByPickupQueueAssignedUserIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupQueueAssignedUserIdAndCustomerId: UserBiddersByPickupQueueAssignedUserIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueAssignedUserIdAndCompletedBy: UserUsersByPickupQueueAssignedUserIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueAssignedUserIdAndCreatedBy: UserUsersByPickupQueueAssignedUserIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByPickupQueueAssignedUserIdAndSessionId: UserPickupSessionsByPickupQueueAssignedUserIdAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPickupQueueCompletedByAndItemId: UserItemsByPickupQueueCompletedByAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupQueueCompletedByAndLocationId: UserLocationsByPickupQueueCompletedByAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueCompletedByAndAssignedUserId: UserUsersByPickupQueueCompletedByAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupQueueCompletedByAndCustomerId: UserBiddersByPickupQueueCompletedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueCompletedByAndCreatedBy: UserUsersByPickupQueueCompletedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByPickupQueueCompletedByAndSessionId: UserPickupSessionsByPickupQueueCompletedByAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPickupQueueCreatedByAndItemId: UserItemsByPickupQueueCreatedByAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupQueueCreatedByAndLocationId: UserLocationsByPickupQueueCreatedByAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueCreatedByAndAssignedUserId: UserUsersByPickupQueueCreatedByAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupQueueCreatedByAndCustomerId: UserBiddersByPickupQueueCreatedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPickupQueueCreatedByAndCompletedBy: UserUsersByPickupQueueCreatedByAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByPickupQueueCreatedByAndSessionId: UserPickupSessionsByPickupQueueCreatedByAndSessionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByItemActionHistoryCreatedByAndItemId: UserItemsByItemActionHistoryCreatedByAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByCustomerNoteCreatedByAndCustomerId: UserBiddersByCustomerNoteCreatedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnId: UserInvoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnIdManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  returnOptionsByReturnQueueCreatedByAndOptionId: UserReturnOptionsByReturnQueueCreatedByAndOptionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByReturnQueueCreatedByAndItemId: UserItemsByReturnQueueCreatedByAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueCreatedByAndCompletedBy: UserUsersByReturnQueueCreatedByAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueCreatedByAndUpdatedBy: UserUsersByReturnQueueCreatedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  returnOptionsByReturnQueueCompletedByAndOptionId: UserReturnOptionsByReturnQueueCompletedByAndOptionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByReturnQueueCompletedByAndItemId: UserItemsByReturnQueueCompletedByAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueCompletedByAndCreatedBy: UserUsersByReturnQueueCompletedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueCompletedByAndUpdatedBy: UserUsersByReturnQueueCompletedByAndUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `ReturnOption`. */
  returnOptionsByReturnQueueUpdatedByAndOptionId: UserReturnOptionsByReturnQueueUpdatedByAndOptionIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByReturnQueueUpdatedByAndItemId: UserItemsByReturnQueueUpdatedByAndItemIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueUpdatedByAndCreatedBy: UserUsersByReturnQueueUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByReturnQueueUpdatedByAndCompletedBy: UserUsersByReturnQueueUpdatedByAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByTruckloadCreatedByAndReceivingLocation: UserLocationsByTruckloadCreatedByAndReceivingLocationManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPicklistQueueAssignedUserIdAndCustomerId: UserBiddersByPicklistQueueAssignedUserIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPicklistQueueAssignedUserIdAndLocationId: UserLocationsByPicklistQueueAssignedUserIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueAssignedUserIdAndCreatedBy: UserUsersByPicklistQueueAssignedUserIdAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueAssignedUserIdAndCompletedBy: UserUsersByPicklistQueueAssignedUserIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueAssignedUserIdAndStepUpdatedBy: UserUsersByPicklistQueueAssignedUserIdAndStepUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPicklistQueueCreatedByAndCustomerId: UserBiddersByPicklistQueueCreatedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPicklistQueueCreatedByAndLocationId: UserLocationsByPicklistQueueCreatedByAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCreatedByAndAssignedUserId: UserUsersByPicklistQueueCreatedByAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCreatedByAndCompletedBy: UserUsersByPicklistQueueCreatedByAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCreatedByAndStepUpdatedBy: UserUsersByPicklistQueueCreatedByAndStepUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPicklistQueueCompletedByAndCustomerId: UserBiddersByPicklistQueueCompletedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPicklistQueueCompletedByAndLocationId: UserLocationsByPicklistQueueCompletedByAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCompletedByAndAssignedUserId: UserUsersByPicklistQueueCompletedByAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCompletedByAndCreatedBy: UserUsersByPicklistQueueCompletedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueCompletedByAndStepUpdatedBy: UserUsersByPicklistQueueCompletedByAndStepUpdatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPicklistQueueStepUpdatedByAndCustomerId: UserBiddersByPicklistQueueStepUpdatedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPicklistQueueStepUpdatedByAndLocationId: UserLocationsByPicklistQueueStepUpdatedByAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueStepUpdatedByAndAssignedUserId: UserUsersByPicklistQueueStepUpdatedByAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueStepUpdatedByAndCreatedBy: UserUsersByPicklistQueueStepUpdatedByAndCreatedByManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByPicklistQueueStepUpdatedByAndCompletedBy: UserUsersByPicklistQueueStepUpdatedByAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByShippingQueueAssignedUserIdAndCustomerId: UserBiddersByShippingQueueAssignedUserIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByShippingQueueAssignedUserIdAndLocationId: UserLocationsByShippingQueueAssignedUserIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByShippingQueueAssignedUserIdAndCompletedBy: UserUsersByShippingQueueAssignedUserIdAndCompletedByManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByShippingQueueCompletedByAndCustomerId: UserBiddersByShippingQueueCompletedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByShippingQueueCompletedByAndLocationId: UserLocationsByShippingQueueCompletedByAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByShippingQueueCompletedByAndAssignedUserId: UserUsersByShippingQueueCompletedByAndAssignedUserIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupSessionUserIdAndLocationId: UserLocationsByPickupSessionUserIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupSessionUserIdAndCustomerId: UserBiddersByPickupSessionUserIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Location`. */
  locationsByPickupCustomerUserIdAndLocationId: UserLocationsByPickupCustomerUserIdAndLocationIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByPickupCustomerUserIdAndCustomerId: UserBiddersByPickupCustomerUserIdAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByCustomerActionsViewCreatedByAndCustomerId: UserBiddersByCustomerActionsViewCreatedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByCustomerAlertsViewCreatedByAndCustomerId: UserBiddersByCustomerAlertsViewCreatedByAndCustomerIdManyToManyConnection;
  /** Reads and enables pagination through a set of `Bidder`. */
  biddersByLivePickupQueueAssignedUserIdAndCustomerId: UserBiddersByLivePickupQueueAssignedUserIdAndCustomerIdManyToManyConnection;
};


export type UserBiddersByProcessInvoicePaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserInvoicesByProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type UserInvoicesByAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type UserItemsByDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserItemsBySetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserItemsByIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserBidderCreditHistoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};


export type UserItemRackLocationsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemRackLocationsOrderBy>>;
  condition?: Maybe<ItemRackLocationCondition>;
  filter?: Maybe<ItemRackLocationFilter>;
};


export type UserInvoiceNotesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceNotesOrderBy>>;
  condition?: Maybe<InvoiceNoteCondition>;
  filter?: Maybe<InvoiceNoteFilter>;
};


export type UserInvoiceItemReturnsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};


export type UserInvoiceItemReturnsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};


export type UserUserNotificationsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserNotificationsOrderBy>>;
  condition?: Maybe<UserNotificationCondition>;
  filter?: Maybe<UserNotificationFilter>;
};


export type UserJobQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<JobQueuesOrderBy>>;
  condition?: Maybe<JobQueueCondition>;
  filter?: Maybe<JobQueueFilter>;
};


export type UserPickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};


export type UserPickupQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};


export type UserPickupQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};


export type UserItemActionHistoriesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemActionHistoriesOrderBy>>;
  condition?: Maybe<ItemActionHistoryCondition>;
  filter?: Maybe<ItemActionHistoryFilter>;
};


export type UserCustomerNotesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerNotesOrderBy>>;
  condition?: Maybe<CustomerNoteCondition>;
  filter?: Maybe<CustomerNoteFilter>;
};


export type UserInvoiceItemReturnHistoriesByByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnHistoriesOrderBy>>;
  condition?: Maybe<InvoiceItemReturnHistoryCondition>;
  filter?: Maybe<InvoiceItemReturnHistoryFilter>;
};


export type UserReturnQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type UserReturnQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type UserReturnQueuesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type UserTruckloadsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TruckloadsOrderBy>>;
  condition?: Maybe<TruckloadCondition>;
  filter?: Maybe<TruckloadFilter>;
};


export type UserPicklistQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};


export type UserPicklistQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};


export type UserPicklistQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};


export type UserPicklistQueuesByStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};


export type UserShippingQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};


export type UserShippingQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};


export type UserPickupSessionsByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type UserPickupCustomersByUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
  condition?: Maybe<PickupCustomerCondition>;
  filter?: Maybe<PickupCustomerFilter>;
};


export type UserCustomerActionsViewsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerActionsViewsOrderBy>>;
  condition?: Maybe<CustomerActionsViewCondition>;
  filter?: Maybe<CustomerActionsViewFilter>;
};


export type UserCustomerAlertsViewsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerAlertsViewsOrderBy>>;
  condition?: Maybe<CustomerAlertsViewCondition>;
  filter?: Maybe<CustomerAlertsViewFilter>;
};


export type UserLivePickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LivePickupQueuesOrderBy>>;
  condition?: Maybe<LivePickupQueueCondition>;
  filter?: Maybe<LivePickupQueueFilter>;
};


export type UserAuctionsByInvoiceProcessPaymentChangedByAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type UserBiddersByInvoiceProcessPaymentChangedByAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserUsersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserLocationsByInvoiceProcessPaymentChangedByAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserAuctionsByInvoiceAlternatePaymentCreatedByAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type UserBiddersByInvoiceAlternatePaymentCreatedByAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserUsersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserLocationsByInvoiceAlternatePaymentCreatedByAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserAuctionsByItemDeletedFromInvoiceByAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type UserInvoicesByItemDeletedFromInvoiceByAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type UserPaymentsByItemDeletedFromInvoiceByAndPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy>>;
  condition?: Maybe<PaymentCondition>;
  filter?: Maybe<PaymentFilter>;
};


export type UserBiddersByItemDeletedFromInvoiceByAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserUsersByItemDeletedFromInvoiceByAndSetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByItemDeletedFromInvoiceByAndIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserAuctionsByItemSetAsideByAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type UserInvoicesByItemSetAsideByAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type UserPaymentsByItemSetAsideByAndPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy>>;
  condition?: Maybe<PaymentCondition>;
  filter?: Maybe<PaymentFilter>;
};


export type UserBiddersByItemSetAsideByAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserUsersByItemSetAsideByAndDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByItemSetAsideByAndIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserAuctionsByItemIsDeletedByAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type UserInvoicesByItemIsDeletedByAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type UserPaymentsByItemIsDeletedByAndPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy>>;
  condition?: Maybe<PaymentCondition>;
  filter?: Maybe<PaymentFilter>;
};


export type UserBiddersByItemIsDeletedByAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserUsersByItemIsDeletedByAndDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByItemIsDeletedByAndSetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserBiddersByBidderCreditHistoryCreatedByAndBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserInvoicesByBidderCreditHistoryCreatedByAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type UserAuctionsByBidderCreditHistoryCreatedByAndAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy>>;
  condition?: Maybe<AuctionCondition>;
  filter?: Maybe<AuctionFilter>;
};


export type UserItemsByBidderCreditHistoryCreatedByAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserInvoicesByInvoiceNoteCreatedByAndInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};


export type UserItemsByInvoiceItemReturnCreatedByAndInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserUsersByInvoiceItemReturnCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserReturnOptionsByInvoiceItemReturnCreatedByAndOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};


export type UserReturnQueuesByInvoiceItemReturnCreatedByAndQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type UserItemsByInvoiceItemReturnUpdatedByAndInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserUsersByInvoiceItemReturnUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserReturnOptionsByInvoiceItemReturnUpdatedByAndOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};


export type UserReturnQueuesByInvoiceItemReturnUpdatedByAndQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};


export type UserUserNotificationsByJobQueueCreatedByAndUserNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UserNotificationsOrderBy>>;
  condition?: Maybe<UserNotificationCondition>;
  filter?: Maybe<UserNotificationFilter>;
};


export type UserItemsByPickupQueueAssignedUserIdAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserLocationsByPickupQueueAssignedUserIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserBiddersByPickupQueueAssignedUserIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserUsersByPickupQueueAssignedUserIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPickupQueueAssignedUserIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserPickupSessionsByPickupQueueAssignedUserIdAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type UserItemsByPickupQueueCompletedByAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserLocationsByPickupQueueCompletedByAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserUsersByPickupQueueCompletedByAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserBiddersByPickupQueueCompletedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserUsersByPickupQueueCompletedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserPickupSessionsByPickupQueueCompletedByAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type UserItemsByPickupQueueCreatedByAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserLocationsByPickupQueueCreatedByAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserUsersByPickupQueueCreatedByAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserBiddersByPickupQueueCreatedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserUsersByPickupQueueCreatedByAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserPickupSessionsByPickupQueueCreatedByAndSessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};


export type UserItemsByItemActionHistoryCreatedByAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserBiddersByCustomerNoteCreatedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserInvoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};


export type UserReturnOptionsByReturnQueueCreatedByAndOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};


export type UserItemsByReturnQueueCreatedByAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserUsersByReturnQueueCreatedByAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByReturnQueueCreatedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserReturnOptionsByReturnQueueCompletedByAndOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};


export type UserItemsByReturnQueueCompletedByAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserUsersByReturnQueueCompletedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByReturnQueueCompletedByAndUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserReturnOptionsByReturnQueueUpdatedByAndOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy>>;
  condition?: Maybe<ReturnOptionCondition>;
  filter?: Maybe<ReturnOptionFilter>;
};


export type UserItemsByReturnQueueUpdatedByAndItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};


export type UserUsersByReturnQueueUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByReturnQueueUpdatedByAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserLocationsByTruckloadCreatedByAndReceivingLocationArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserBiddersByPicklistQueueAssignedUserIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserLocationsByPicklistQueueAssignedUserIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserUsersByPicklistQueueAssignedUserIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPicklistQueueAssignedUserIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPicklistQueueAssignedUserIdAndStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserBiddersByPicklistQueueCreatedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserLocationsByPicklistQueueCreatedByAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserUsersByPicklistQueueCreatedByAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPicklistQueueCreatedByAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPicklistQueueCreatedByAndStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserBiddersByPicklistQueueCompletedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserLocationsByPicklistQueueCompletedByAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserUsersByPicklistQueueCompletedByAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPicklistQueueCompletedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPicklistQueueCompletedByAndStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserBiddersByPicklistQueueStepUpdatedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserLocationsByPicklistQueueStepUpdatedByAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserUsersByPicklistQueueStepUpdatedByAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPicklistQueueStepUpdatedByAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserUsersByPicklistQueueStepUpdatedByAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserBiddersByShippingQueueAssignedUserIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserLocationsByShippingQueueAssignedUserIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserUsersByShippingQueueAssignedUserIdAndCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserBiddersByShippingQueueCompletedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserLocationsByShippingQueueCompletedByAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserUsersByShippingQueueCompletedByAndAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};


export type UserLocationsByPickupSessionUserIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserBiddersByPickupSessionUserIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserLocationsByPickupCustomerUserIdAndLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy>>;
  condition?: Maybe<LocationCondition>;
  filter?: Maybe<LocationFilter>;
};


export type UserBiddersByPickupCustomerUserIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserBiddersByCustomerActionsViewCreatedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserBiddersByCustomerAlertsViewCreatedByAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};


export type UserBiddersByLivePickupQueueAssignedUserIdAndCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy>>;
  condition?: Maybe<BidderCondition>;
  filter?: Maybe<BidderFilter>;
};

/** A connection to a list of `Auction` values, with data from `BidderCreditHistory`. */
export type UserAuctionsByBidderCreditHistoryCreatedByAndAuctionIdManyToManyConnection = {
  __typename?: 'UserAuctionsByBidderCreditHistoryCreatedByAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<UserAuctionsByBidderCreditHistoryCreatedByAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `BidderCreditHistory`. */
export type UserAuctionsByBidderCreditHistoryCreatedByAndAuctionIdManyToManyEdge = {
  __typename?: 'UserAuctionsByBidderCreditHistoryCreatedByAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByAuctionId: BidderCreditHistoriesConnection;
};


/** A `Auction` edge in the connection, with data from `BidderCreditHistory`. */
export type UserAuctionsByBidderCreditHistoryCreatedByAndAuctionIdManyToManyEdgeBidderCreditHistoriesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Auction` values, with data from `Invoice`. */
export type UserAuctionsByInvoiceAlternatePaymentCreatedByAndAuctionIdManyToManyConnection = {
  __typename?: 'UserAuctionsByInvoiceAlternatePaymentCreatedByAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<UserAuctionsByInvoiceAlternatePaymentCreatedByAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Invoice`. */
export type UserAuctionsByInvoiceAlternatePaymentCreatedByAndAuctionIdManyToManyEdge = {
  __typename?: 'UserAuctionsByInvoiceAlternatePaymentCreatedByAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAuctionId: InvoicesConnection;
};


/** A `Auction` edge in the connection, with data from `Invoice`. */
export type UserAuctionsByInvoiceAlternatePaymentCreatedByAndAuctionIdManyToManyEdgeInvoicesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Auction` values, with data from `Invoice`. */
export type UserAuctionsByInvoiceProcessPaymentChangedByAndAuctionIdManyToManyConnection = {
  __typename?: 'UserAuctionsByInvoiceProcessPaymentChangedByAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<UserAuctionsByInvoiceProcessPaymentChangedByAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Invoice`. */
export type UserAuctionsByInvoiceProcessPaymentChangedByAndAuctionIdManyToManyEdge = {
  __typename?: 'UserAuctionsByInvoiceProcessPaymentChangedByAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAuctionId: InvoicesConnection;
};


/** A `Auction` edge in the connection, with data from `Invoice`. */
export type UserAuctionsByInvoiceProcessPaymentChangedByAndAuctionIdManyToManyEdgeInvoicesByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Auction` values, with data from `Item`. */
export type UserAuctionsByItemDeletedFromInvoiceByAndAuctionIdManyToManyConnection = {
  __typename?: 'UserAuctionsByItemDeletedFromInvoiceByAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserAuctionsByItemDeletedFromInvoiceByAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Item`. */
export type UserAuctionsByItemDeletedFromInvoiceByAndAuctionIdManyToManyEdge = {
  __typename?: 'UserAuctionsByItemDeletedFromInvoiceByAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByAuctionId: ItemsConnection;
};


/** A `Auction` edge in the connection, with data from `Item`. */
export type UserAuctionsByItemDeletedFromInvoiceByAndAuctionIdManyToManyEdgeItemsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Auction` values, with data from `Item`. */
export type UserAuctionsByItemIsDeletedByAndAuctionIdManyToManyConnection = {
  __typename?: 'UserAuctionsByItemIsDeletedByAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserAuctionsByItemIsDeletedByAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Item`. */
export type UserAuctionsByItemIsDeletedByAndAuctionIdManyToManyEdge = {
  __typename?: 'UserAuctionsByItemIsDeletedByAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByAuctionId: ItemsConnection;
};


/** A `Auction` edge in the connection, with data from `Item`. */
export type UserAuctionsByItemIsDeletedByAndAuctionIdManyToManyEdgeItemsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Auction` values, with data from `Item`. */
export type UserAuctionsByItemSetAsideByAndAuctionIdManyToManyConnection = {
  __typename?: 'UserAuctionsByItemSetAsideByAndAuctionIdManyToManyConnection';
  /** A list of `Auction` objects. */
  nodes: Array<Auction>;
  /** A list of edges which contains the `Auction`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserAuctionsByItemSetAsideByAndAuctionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Auction` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Auction` edge in the connection, with data from `Item`. */
export type UserAuctionsByItemSetAsideByAndAuctionIdManyToManyEdge = {
  __typename?: 'UserAuctionsByItemSetAsideByAndAuctionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Auction` at the end of the edge. */
  node: Auction;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByAuctionId: ItemsConnection;
};


/** A `Auction` edge in the connection, with data from `Item`. */
export type UserAuctionsByItemSetAsideByAndAuctionIdManyToManyEdgeItemsByAuctionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Bidder` values, with data from `BidderCreditHistory`. */
export type UserBiddersByBidderCreditHistoryCreatedByAndBidderIdManyToManyConnection = {
  __typename?: 'UserBiddersByBidderCreditHistoryCreatedByAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByBidderCreditHistoryCreatedByAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `BidderCreditHistory`. */
export type UserBiddersByBidderCreditHistoryCreatedByAndBidderIdManyToManyEdge = {
  __typename?: 'UserBiddersByBidderCreditHistoryCreatedByAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByBidderId: BidderCreditHistoriesConnection;
};


/** A `Bidder` edge in the connection, with data from `BidderCreditHistory`. */
export type UserBiddersByBidderCreditHistoryCreatedByAndBidderIdManyToManyEdgeBidderCreditHistoriesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Bidder` values, with data from `CustomerActionsView`. */
export type UserBiddersByCustomerActionsViewCreatedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByCustomerActionsViewCreatedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `CustomerActionsView`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByCustomerActionsViewCreatedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `CustomerActionsView`. */
export type UserBiddersByCustomerActionsViewCreatedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByCustomerActionsViewCreatedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `CustomerActionsView`. */
  customerActionsViewsByCustomerId: CustomerActionsViewsConnection;
};


/** A `Bidder` edge in the connection, with data from `CustomerActionsView`. */
export type UserBiddersByCustomerActionsViewCreatedByAndCustomerIdManyToManyEdgeCustomerActionsViewsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerActionsViewsOrderBy>>;
  condition?: Maybe<CustomerActionsViewCondition>;
  filter?: Maybe<CustomerActionsViewFilter>;
};

/** A connection to a list of `Bidder` values, with data from `CustomerAlertsView`. */
export type UserBiddersByCustomerAlertsViewCreatedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByCustomerAlertsViewCreatedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `CustomerAlertsView`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByCustomerAlertsViewCreatedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `CustomerAlertsView`. */
export type UserBiddersByCustomerAlertsViewCreatedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByCustomerAlertsViewCreatedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `CustomerAlertsView`. */
  customerAlertsViewsByCustomerId: CustomerAlertsViewsConnection;
};


/** A `Bidder` edge in the connection, with data from `CustomerAlertsView`. */
export type UserBiddersByCustomerAlertsViewCreatedByAndCustomerIdManyToManyEdgeCustomerAlertsViewsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerAlertsViewsOrderBy>>;
  condition?: Maybe<CustomerAlertsViewCondition>;
  filter?: Maybe<CustomerAlertsViewFilter>;
};

/** A connection to a list of `Bidder` values, with data from `CustomerNote`. */
export type UserBiddersByCustomerNoteCreatedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByCustomerNoteCreatedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `CustomerNote`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByCustomerNoteCreatedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `CustomerNote`. */
export type UserBiddersByCustomerNoteCreatedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByCustomerNoteCreatedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `CustomerNote`. */
  customerNotesByCustomerId: CustomerNotesConnection;
};


/** A `Bidder` edge in the connection, with data from `CustomerNote`. */
export type UserBiddersByCustomerNoteCreatedByAndCustomerIdManyToManyEdgeCustomerNotesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerNotesOrderBy>>;
  condition?: Maybe<CustomerNoteCondition>;
  filter?: Maybe<CustomerNoteFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Invoice`. */
export type UserBiddersByInvoiceAlternatePaymentCreatedByAndBidderIdManyToManyConnection = {
  __typename?: 'UserBiddersByInvoiceAlternatePaymentCreatedByAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByInvoiceAlternatePaymentCreatedByAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Invoice`. */
export type UserBiddersByInvoiceAlternatePaymentCreatedByAndBidderIdManyToManyEdge = {
  __typename?: 'UserBiddersByInvoiceAlternatePaymentCreatedByAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderId: InvoicesConnection;
};


/** A `Bidder` edge in the connection, with data from `Invoice`. */
export type UserBiddersByInvoiceAlternatePaymentCreatedByAndBidderIdManyToManyEdgeInvoicesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Invoice`. */
export type UserBiddersByInvoiceProcessPaymentChangedByAndBidderIdManyToManyConnection = {
  __typename?: 'UserBiddersByInvoiceProcessPaymentChangedByAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByInvoiceProcessPaymentChangedByAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Invoice`. */
export type UserBiddersByInvoiceProcessPaymentChangedByAndBidderIdManyToManyEdge = {
  __typename?: 'UserBiddersByInvoiceProcessPaymentChangedByAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByBidderId: InvoicesConnection;
};


/** A `Bidder` edge in the connection, with data from `Invoice`. */
export type UserBiddersByInvoiceProcessPaymentChangedByAndBidderIdManyToManyEdgeInvoicesByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Item`. */
export type UserBiddersByItemDeletedFromInvoiceByAndBidderIdManyToManyConnection = {
  __typename?: 'UserBiddersByItemDeletedFromInvoiceByAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByItemDeletedFromInvoiceByAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Item`. */
export type UserBiddersByItemDeletedFromInvoiceByAndBidderIdManyToManyEdge = {
  __typename?: 'UserBiddersByItemDeletedFromInvoiceByAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderId: ItemsConnection;
};


/** A `Bidder` edge in the connection, with data from `Item`. */
export type UserBiddersByItemDeletedFromInvoiceByAndBidderIdManyToManyEdgeItemsByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Item`. */
export type UserBiddersByItemIsDeletedByAndBidderIdManyToManyConnection = {
  __typename?: 'UserBiddersByItemIsDeletedByAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByItemIsDeletedByAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Item`. */
export type UserBiddersByItemIsDeletedByAndBidderIdManyToManyEdge = {
  __typename?: 'UserBiddersByItemIsDeletedByAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderId: ItemsConnection;
};


/** A `Bidder` edge in the connection, with data from `Item`. */
export type UserBiddersByItemIsDeletedByAndBidderIdManyToManyEdgeItemsByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Bidder` values, with data from `Item`. */
export type UserBiddersByItemSetAsideByAndBidderIdManyToManyConnection = {
  __typename?: 'UserBiddersByItemSetAsideByAndBidderIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByItemSetAsideByAndBidderIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `Item`. */
export type UserBiddersByItemSetAsideByAndBidderIdManyToManyEdge = {
  __typename?: 'UserBiddersByItemSetAsideByAndBidderIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByBidderId: ItemsConnection;
};


/** A `Bidder` edge in the connection, with data from `Item`. */
export type UserBiddersByItemSetAsideByAndBidderIdManyToManyEdgeItemsByBidderIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Bidder` values, with data from `LivePickupQueue`. */
export type UserBiddersByLivePickupQueueAssignedUserIdAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByLivePickupQueueAssignedUserIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `LivePickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByLivePickupQueueAssignedUserIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `LivePickupQueue`. */
export type UserBiddersByLivePickupQueueAssignedUserIdAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByLivePickupQueueAssignedUserIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `LivePickupQueue`. */
  livePickupQueuesByCustomerId: LivePickupQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `LivePickupQueue`. */
export type UserBiddersByLivePickupQueueAssignedUserIdAndCustomerIdManyToManyEdgeLivePickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LivePickupQueuesOrderBy>>;
  condition?: Maybe<LivePickupQueueCondition>;
  filter?: Maybe<LivePickupQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueAssignedUserIdAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPicklistQueueAssignedUserIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPicklistQueueAssignedUserIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueAssignedUserIdAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPicklistQueueAssignedUserIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCustomerId: PicklistQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueAssignedUserIdAndCustomerIdManyToManyEdgePicklistQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueCompletedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPicklistQueueCompletedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPicklistQueueCompletedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueCompletedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPicklistQueueCompletedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCustomerId: PicklistQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueCompletedByAndCustomerIdManyToManyEdgePicklistQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueCreatedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPicklistQueueCreatedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPicklistQueueCreatedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueCreatedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPicklistQueueCreatedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCustomerId: PicklistQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueCreatedByAndCustomerIdManyToManyEdgePicklistQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueStepUpdatedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPicklistQueueStepUpdatedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPicklistQueueStepUpdatedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueStepUpdatedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPicklistQueueStepUpdatedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCustomerId: PicklistQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PicklistQueue`. */
export type UserBiddersByPicklistQueueStepUpdatedByAndCustomerIdManyToManyEdgePicklistQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupCustomer`. */
export type UserBiddersByPickupCustomerUserIdAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPickupCustomerUserIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupCustomer`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPickupCustomerUserIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupCustomer`. */
export type UserBiddersByPickupCustomerUserIdAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPickupCustomerUserIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupCustomer`. */
  pickupCustomersByCustomerId: PickupCustomersConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupCustomer`. */
export type UserBiddersByPickupCustomerUserIdAndCustomerIdManyToManyEdgePickupCustomersByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
  condition?: Maybe<PickupCustomerCondition>;
  filter?: Maybe<PickupCustomerFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueAssignedUserIdAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPickupQueueAssignedUserIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPickupQueueAssignedUserIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueAssignedUserIdAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPickupQueueAssignedUserIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCustomerId: PickupQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueAssignedUserIdAndCustomerIdManyToManyEdgePickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueCompletedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPickupQueueCompletedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPickupQueueCompletedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueCompletedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPickupQueueCompletedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCustomerId: PickupQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueCompletedByAndCustomerIdManyToManyEdgePickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueCreatedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPickupQueueCreatedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPickupQueueCreatedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueCreatedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPickupQueueCreatedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCustomerId: PickupQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupQueue`. */
export type UserBiddersByPickupQueueCreatedByAndCustomerIdManyToManyEdgePickupQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `PickupSession`. */
export type UserBiddersByPickupSessionUserIdAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByPickupSessionUserIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `PickupSession`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByPickupSessionUserIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `PickupSession`. */
export type UserBiddersByPickupSessionUserIdAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByPickupSessionUserIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByCustomerId: PickupSessionsConnection;
};


/** A `Bidder` edge in the connection, with data from `PickupSession`. */
export type UserBiddersByPickupSessionUserIdAndCustomerIdManyToManyEdgePickupSessionsByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};

/** A connection to a list of `Bidder` values, with data from `ShippingQueue`. */
export type UserBiddersByShippingQueueAssignedUserIdAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByShippingQueueAssignedUserIdAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByShippingQueueAssignedUserIdAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `ShippingQueue`. */
export type UserBiddersByShippingQueueAssignedUserIdAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByShippingQueueAssignedUserIdAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByCustomerId: ShippingQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `ShippingQueue`. */
export type UserBiddersByShippingQueueAssignedUserIdAndCustomerIdManyToManyEdgeShippingQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `Bidder` values, with data from `ShippingQueue`. */
export type UserBiddersByShippingQueueCompletedByAndCustomerIdManyToManyConnection = {
  __typename?: 'UserBiddersByShippingQueueCompletedByAndCustomerIdManyToManyConnection';
  /** A list of `Bidder` objects. */
  nodes: Array<Bidder>;
  /** A list of edges which contains the `Bidder`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<UserBiddersByShippingQueueCompletedByAndCustomerIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Bidder` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Bidder` edge in the connection, with data from `ShippingQueue`. */
export type UserBiddersByShippingQueueCompletedByAndCustomerIdManyToManyEdge = {
  __typename?: 'UserBiddersByShippingQueueCompletedByAndCustomerIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Bidder` at the end of the edge. */
  node: Bidder;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByCustomerId: ShippingQueuesConnection;
};


/** A `Bidder` edge in the connection, with data from `ShippingQueue`. */
export type UserBiddersByShippingQueueCompletedByAndCustomerIdManyToManyEdgeShippingQueuesByCustomerIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `username` field. */
  username?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `firstName` field. */
  firstName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `lastName` field. */
  lastName?: Maybe<Scalars['String']>;
  /** Checks for equality with the object’s `role` field. */
  role?: Maybe<UserRoles>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `username` field. */
  username?: Maybe<StringFilter>;
  /** Filter by the object’s `firstName` field. */
  firstName?: Maybe<StringFilter>;
  /** Filter by the object’s `lastName` field. */
  lastName?: Maybe<StringFilter>;
  /** Filter by the object’s `role` field. */
  role?: Maybe<UserRolesFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `name` field. */
  name?: Maybe<StringFilter>;
  /** Filter by the object’s `pincode` field. */
  pincode?: Maybe<StringFilter>;
  /** Filter by the object’s `biddersByProcessInvoicePaymentChangedBy` relation. */
  biddersByProcessInvoicePaymentChangedBy?: Maybe<UserToManyBidderFilter>;
  /** Some related `biddersByProcessInvoicePaymentChangedBy` exist. */
  biddersByProcessInvoicePaymentChangedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoicesByProcessPaymentChangedBy` relation. */
  invoicesByProcessPaymentChangedBy?: Maybe<UserToManyInvoiceFilter>;
  /** Some related `invoicesByProcessPaymentChangedBy` exist. */
  invoicesByProcessPaymentChangedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoicesByAlternatePaymentCreatedBy` relation. */
  invoicesByAlternatePaymentCreatedBy?: Maybe<UserToManyInvoiceFilter>;
  /** Some related `invoicesByAlternatePaymentCreatedBy` exist. */
  invoicesByAlternatePaymentCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemsByDeletedFromInvoiceBy` relation. */
  itemsByDeletedFromInvoiceBy?: Maybe<UserToManyItemFilter>;
  /** Some related `itemsByDeletedFromInvoiceBy` exist. */
  itemsByDeletedFromInvoiceByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemsBySetAsideBy` relation. */
  itemsBySetAsideBy?: Maybe<UserToManyItemFilter>;
  /** Some related `itemsBySetAsideBy` exist. */
  itemsBySetAsideByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemsByIsDeletedBy` relation. */
  itemsByIsDeletedBy?: Maybe<UserToManyItemFilter>;
  /** Some related `itemsByIsDeletedBy` exist. */
  itemsByIsDeletedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `bidderCreditHistoriesByCreatedBy` relation. */
  bidderCreditHistoriesByCreatedBy?: Maybe<UserToManyBidderCreditHistoryFilter>;
  /** Some related `bidderCreditHistoriesByCreatedBy` exist. */
  bidderCreditHistoriesByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemRackLocationsByUpdatedBy` relation. */
  itemRackLocationsByUpdatedBy?: Maybe<UserToManyItemRackLocationFilter>;
  /** Some related `itemRackLocationsByUpdatedBy` exist. */
  itemRackLocationsByUpdatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceNotesByCreatedBy` relation. */
  invoiceNotesByCreatedBy?: Maybe<UserToManyInvoiceNoteFilter>;
  /** Some related `invoiceNotesByCreatedBy` exist. */
  invoiceNotesByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceItemReturnsByCreatedBy` relation. */
  invoiceItemReturnsByCreatedBy?: Maybe<UserToManyInvoiceItemReturnFilter>;
  /** Some related `invoiceItemReturnsByCreatedBy` exist. */
  invoiceItemReturnsByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceItemReturnsByUpdatedBy` relation. */
  invoiceItemReturnsByUpdatedBy?: Maybe<UserToManyInvoiceItemReturnFilter>;
  /** Some related `invoiceItemReturnsByUpdatedBy` exist. */
  invoiceItemReturnsByUpdatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userNotificationsByUserId` relation. */
  userNotificationsByUserId?: Maybe<UserToManyUserNotificationFilter>;
  /** Some related `userNotificationsByUserId` exist. */
  userNotificationsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `jobQueuesByCreatedBy` relation. */
  jobQueuesByCreatedBy?: Maybe<UserToManyJobQueueFilter>;
  /** Some related `jobQueuesByCreatedBy` exist. */
  jobQueuesByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userLocationByUserId` relation. */
  userLocationByUserId?: Maybe<UserLocationFilter>;
  /** A related `userLocationByUserId` exists. */
  userLocationByUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupQueuesByAssignedUserId` relation. */
  pickupQueuesByAssignedUserId?: Maybe<UserToManyPickupQueueFilter>;
  /** Some related `pickupQueuesByAssignedUserId` exist. */
  pickupQueuesByAssignedUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupQueuesByCompletedBy` relation. */
  pickupQueuesByCompletedBy?: Maybe<UserToManyPickupQueueFilter>;
  /** Some related `pickupQueuesByCompletedBy` exist. */
  pickupQueuesByCompletedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupQueuesByCreatedBy` relation. */
  pickupQueuesByCreatedBy?: Maybe<UserToManyPickupQueueFilter>;
  /** Some related `pickupQueuesByCreatedBy` exist. */
  pickupQueuesByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `itemActionHistoriesByCreatedBy` relation. */
  itemActionHistoriesByCreatedBy?: Maybe<UserToManyItemActionHistoryFilter>;
  /** Some related `itemActionHistoriesByCreatedBy` exist. */
  itemActionHistoriesByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerNotesByCreatedBy` relation. */
  customerNotesByCreatedBy?: Maybe<UserToManyCustomerNoteFilter>;
  /** Some related `customerNotesByCreatedBy` exist. */
  customerNotesByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `invoiceItemReturnHistoriesByBy` relation. */
  invoiceItemReturnHistoriesByBy?: Maybe<UserToManyInvoiceItemReturnHistoryFilter>;
  /** Some related `invoiceItemReturnHistoriesByBy` exist. */
  invoiceItemReturnHistoriesByByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `returnQueuesByCreatedBy` relation. */
  returnQueuesByCreatedBy?: Maybe<UserToManyReturnQueueFilter>;
  /** Some related `returnQueuesByCreatedBy` exist. */
  returnQueuesByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `returnQueuesByCompletedBy` relation. */
  returnQueuesByCompletedBy?: Maybe<UserToManyReturnQueueFilter>;
  /** Some related `returnQueuesByCompletedBy` exist. */
  returnQueuesByCompletedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `returnQueuesByUpdatedBy` relation. */
  returnQueuesByUpdatedBy?: Maybe<UserToManyReturnQueueFilter>;
  /** Some related `returnQueuesByUpdatedBy` exist. */
  returnQueuesByUpdatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `truckloadsByCreatedBy` relation. */
  truckloadsByCreatedBy?: Maybe<UserToManyTruckloadFilter>;
  /** Some related `truckloadsByCreatedBy` exist. */
  truckloadsByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `picklistQueuesByAssignedUserId` relation. */
  picklistQueuesByAssignedUserId?: Maybe<UserToManyPicklistQueueFilter>;
  /** Some related `picklistQueuesByAssignedUserId` exist. */
  picklistQueuesByAssignedUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `picklistQueuesByCreatedBy` relation. */
  picklistQueuesByCreatedBy?: Maybe<UserToManyPicklistQueueFilter>;
  /** Some related `picklistQueuesByCreatedBy` exist. */
  picklistQueuesByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `picklistQueuesByCompletedBy` relation. */
  picklistQueuesByCompletedBy?: Maybe<UserToManyPicklistQueueFilter>;
  /** Some related `picklistQueuesByCompletedBy` exist. */
  picklistQueuesByCompletedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `picklistQueuesByStepUpdatedBy` relation. */
  picklistQueuesByStepUpdatedBy?: Maybe<UserToManyPicklistQueueFilter>;
  /** Some related `picklistQueuesByStepUpdatedBy` exist. */
  picklistQueuesByStepUpdatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `shippingQueuesByAssignedUserId` relation. */
  shippingQueuesByAssignedUserId?: Maybe<UserToManyShippingQueueFilter>;
  /** Some related `shippingQueuesByAssignedUserId` exist. */
  shippingQueuesByAssignedUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `shippingQueuesByCompletedBy` relation. */
  shippingQueuesByCompletedBy?: Maybe<UserToManyShippingQueueFilter>;
  /** Some related `shippingQueuesByCompletedBy` exist. */
  shippingQueuesByCompletedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupSessionsByUserId` relation. */
  pickupSessionsByUserId?: Maybe<UserToManyPickupSessionFilter>;
  /** Some related `pickupSessionsByUserId` exist. */
  pickupSessionsByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `pickupCustomersByUserId` relation. */
  pickupCustomersByUserId?: Maybe<UserToManyPickupCustomerFilter>;
  /** Some related `pickupCustomersByUserId` exist. */
  pickupCustomersByUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerActionsViewsByCreatedBy` relation. */
  customerActionsViewsByCreatedBy?: Maybe<UserToManyCustomerActionsViewFilter>;
  /** Some related `customerActionsViewsByCreatedBy` exist. */
  customerActionsViewsByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `customerAlertsViewsByCreatedBy` relation. */
  customerAlertsViewsByCreatedBy?: Maybe<UserToManyCustomerAlertsViewFilter>;
  /** Some related `customerAlertsViewsByCreatedBy` exist. */
  customerAlertsViewsByCreatedByExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `livePickupQueuesByAssignedUserId` relation. */
  livePickupQueuesByAssignedUserId?: Maybe<UserToManyLivePickupQueueFilter>;
  /** Some related `livePickupQueuesByAssignedUserId` exist. */
  livePickupQueuesByAssignedUserIdExist?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserFilter>;
};

/** A connection to a list of `InvoiceItemReturn` values, with data from `InvoiceItemReturnHistory`. */
export type UserInvoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnIdManyToManyConnection = {
  __typename?: 'UserInvoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnIdManyToManyConnection';
  /** A list of `InvoiceItemReturn` objects. */
  nodes: Array<InvoiceItemReturn>;
  /** A list of edges which contains the `InvoiceItemReturn`, info from the `InvoiceItemReturnHistory`, and the cursor to aid in pagination. */
  edges: Array<UserInvoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `InvoiceItemReturn` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `InvoiceItemReturn` edge in the connection, with data from `InvoiceItemReturnHistory`. */
export type UserInvoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnIdManyToManyEdge = {
  __typename?: 'UserInvoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `InvoiceItemReturn` at the end of the edge. */
  node: InvoiceItemReturn;
  /** Reads and enables pagination through a set of `InvoiceItemReturnHistory`. */
  invoiceItemReturnHistoriesByReturnId: InvoiceItemReturnHistoriesConnection;
};


/** A `InvoiceItemReturn` edge in the connection, with data from `InvoiceItemReturnHistory`. */
export type UserInvoiceItemReturnsByInvoiceItemReturnHistoryByAndReturnIdManyToManyEdgeInvoiceItemReturnHistoriesByReturnIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnHistoriesOrderBy>>;
  condition?: Maybe<InvoiceItemReturnHistoryCondition>;
  filter?: Maybe<InvoiceItemReturnHistoryFilter>;
};

/** A connection to a list of `Invoice` values, with data from `BidderCreditHistory`. */
export type UserInvoicesByBidderCreditHistoryCreatedByAndInvoiceIdManyToManyConnection = {
  __typename?: 'UserInvoicesByBidderCreditHistoryCreatedByAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<UserInvoicesByBidderCreditHistoryCreatedByAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `BidderCreditHistory`. */
export type UserInvoicesByBidderCreditHistoryCreatedByAndInvoiceIdManyToManyEdge = {
  __typename?: 'UserInvoicesByBidderCreditHistoryCreatedByAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByInvoiceId: BidderCreditHistoriesConnection;
};


/** A `Invoice` edge in the connection, with data from `BidderCreditHistory`. */
export type UserInvoicesByBidderCreditHistoryCreatedByAndInvoiceIdManyToManyEdgeBidderCreditHistoriesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Invoice` values, with data from `InvoiceNote`. */
export type UserInvoicesByInvoiceNoteCreatedByAndInvoiceIdManyToManyConnection = {
  __typename?: 'UserInvoicesByInvoiceNoteCreatedByAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `InvoiceNote`, and the cursor to aid in pagination. */
  edges: Array<UserInvoicesByInvoiceNoteCreatedByAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `InvoiceNote`. */
export type UserInvoicesByInvoiceNoteCreatedByAndInvoiceIdManyToManyEdge = {
  __typename?: 'UserInvoicesByInvoiceNoteCreatedByAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `InvoiceNote`. */
  invoiceNotesByInvoiceId: InvoiceNotesConnection;
};


/** A `Invoice` edge in the connection, with data from `InvoiceNote`. */
export type UserInvoicesByInvoiceNoteCreatedByAndInvoiceIdManyToManyEdgeInvoiceNotesByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceNotesOrderBy>>;
  condition?: Maybe<InvoiceNoteCondition>;
  filter?: Maybe<InvoiceNoteFilter>;
};

/** A connection to a list of `Invoice` values, with data from `Item`. */
export type UserInvoicesByItemDeletedFromInvoiceByAndInvoiceIdManyToManyConnection = {
  __typename?: 'UserInvoicesByItemDeletedFromInvoiceByAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserInvoicesByItemDeletedFromInvoiceByAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `Item`. */
export type UserInvoicesByItemDeletedFromInvoiceByAndInvoiceIdManyToManyEdge = {
  __typename?: 'UserInvoicesByItemDeletedFromInvoiceByAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceId: ItemsConnection;
};


/** A `Invoice` edge in the connection, with data from `Item`. */
export type UserInvoicesByItemDeletedFromInvoiceByAndInvoiceIdManyToManyEdgeItemsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Invoice` values, with data from `Item`. */
export type UserInvoicesByItemIsDeletedByAndInvoiceIdManyToManyConnection = {
  __typename?: 'UserInvoicesByItemIsDeletedByAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserInvoicesByItemIsDeletedByAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `Item`. */
export type UserInvoicesByItemIsDeletedByAndInvoiceIdManyToManyEdge = {
  __typename?: 'UserInvoicesByItemIsDeletedByAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceId: ItemsConnection;
};


/** A `Invoice` edge in the connection, with data from `Item`. */
export type UserInvoicesByItemIsDeletedByAndInvoiceIdManyToManyEdgeItemsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Invoice` values, with data from `Item`. */
export type UserInvoicesByItemSetAsideByAndInvoiceIdManyToManyConnection = {
  __typename?: 'UserInvoicesByItemSetAsideByAndInvoiceIdManyToManyConnection';
  /** A list of `Invoice` objects. */
  nodes: Array<Invoice>;
  /** A list of edges which contains the `Invoice`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserInvoicesByItemSetAsideByAndInvoiceIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Invoice` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Invoice` edge in the connection, with data from `Item`. */
export type UserInvoicesByItemSetAsideByAndInvoiceIdManyToManyEdge = {
  __typename?: 'UserInvoicesByItemSetAsideByAndInvoiceIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Invoice` at the end of the edge. */
  node: Invoice;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByInvoiceId: ItemsConnection;
};


/** A `Invoice` edge in the connection, with data from `Item`. */
export type UserInvoicesByItemSetAsideByAndInvoiceIdManyToManyEdgeItemsByInvoiceIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Item` values, with data from `BidderCreditHistory`. */
export type UserItemsByBidderCreditHistoryCreatedByAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsByBidderCreditHistoryCreatedByAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `BidderCreditHistory`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByBidderCreditHistoryCreatedByAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `BidderCreditHistory`. */
export type UserItemsByBidderCreditHistoryCreatedByAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsByBidderCreditHistoryCreatedByAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `BidderCreditHistory`. */
  bidderCreditHistoriesByItemId: BidderCreditHistoriesConnection;
};


/** A `Item` edge in the connection, with data from `BidderCreditHistory`. */
export type UserItemsByBidderCreditHistoryCreatedByAndItemIdManyToManyEdgeBidderCreditHistoriesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy>>;
  condition?: Maybe<BidderCreditHistoryCondition>;
  filter?: Maybe<BidderCreditHistoryFilter>;
};

/** A connection to a list of `Item` values, with data from `InvoiceItemReturn`. */
export type UserItemsByInvoiceItemReturnCreatedByAndInvoiceItemIdManyToManyConnection = {
  __typename?: 'UserItemsByInvoiceItemReturnCreatedByAndInvoiceItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByInvoiceItemReturnCreatedByAndInvoiceItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserItemsByInvoiceItemReturnCreatedByAndInvoiceItemIdManyToManyEdge = {
  __typename?: 'UserItemsByInvoiceItemReturnCreatedByAndInvoiceItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByInvoiceItemId: InvoiceItemReturnsConnection;
};


/** A `Item` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserItemsByInvoiceItemReturnCreatedByAndInvoiceItemIdManyToManyEdgeInvoiceItemReturnsByInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `Item` values, with data from `InvoiceItemReturn`. */
export type UserItemsByInvoiceItemReturnUpdatedByAndInvoiceItemIdManyToManyConnection = {
  __typename?: 'UserItemsByInvoiceItemReturnUpdatedByAndInvoiceItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByInvoiceItemReturnUpdatedByAndInvoiceItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserItemsByInvoiceItemReturnUpdatedByAndInvoiceItemIdManyToManyEdge = {
  __typename?: 'UserItemsByInvoiceItemReturnUpdatedByAndInvoiceItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByInvoiceItemId: InvoiceItemReturnsConnection;
};


/** A `Item` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserItemsByInvoiceItemReturnUpdatedByAndInvoiceItemIdManyToManyEdgeInvoiceItemReturnsByInvoiceItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `Item` values, with data from `ItemActionHistory`. */
export type UserItemsByItemActionHistoryCreatedByAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsByItemActionHistoryCreatedByAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `ItemActionHistory`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByItemActionHistoryCreatedByAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `ItemActionHistory`. */
export type UserItemsByItemActionHistoryCreatedByAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsByItemActionHistoryCreatedByAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `ItemActionHistory`. */
  itemActionHistoriesByItemId: ItemActionHistoriesConnection;
};


/** A `Item` edge in the connection, with data from `ItemActionHistory`. */
export type UserItemsByItemActionHistoryCreatedByAndItemIdManyToManyEdgeItemActionHistoriesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemActionHistoriesOrderBy>>;
  condition?: Maybe<ItemActionHistoryCondition>;
  filter?: Maybe<ItemActionHistoryFilter>;
};

/** A connection to a list of `Item` values, with data from `PickupQueue`. */
export type UserItemsByPickupQueueAssignedUserIdAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsByPickupQueueAssignedUserIdAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByPickupQueueAssignedUserIdAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type UserItemsByPickupQueueAssignedUserIdAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsByPickupQueueAssignedUserIdAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByItemId: PickupQueuesConnection;
};


/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type UserItemsByPickupQueueAssignedUserIdAndItemIdManyToManyEdgePickupQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Item` values, with data from `PickupQueue`. */
export type UserItemsByPickupQueueCompletedByAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsByPickupQueueCompletedByAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByPickupQueueCompletedByAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type UserItemsByPickupQueueCompletedByAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsByPickupQueueCompletedByAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByItemId: PickupQueuesConnection;
};


/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type UserItemsByPickupQueueCompletedByAndItemIdManyToManyEdgePickupQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Item` values, with data from `PickupQueue`. */
export type UserItemsByPickupQueueCreatedByAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsByPickupQueueCreatedByAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByPickupQueueCreatedByAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type UserItemsByPickupQueueCreatedByAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsByPickupQueueCreatedByAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByItemId: PickupQueuesConnection;
};


/** A `Item` edge in the connection, with data from `PickupQueue`. */
export type UserItemsByPickupQueueCreatedByAndItemIdManyToManyEdgePickupQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Item` values, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueCompletedByAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsByReturnQueueCompletedByAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByReturnQueueCompletedByAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueCompletedByAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsByReturnQueueCompletedByAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByItemId: ReturnQueuesConnection;
};


/** A `Item` edge in the connection, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueCompletedByAndItemIdManyToManyEdgeReturnQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `Item` values, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueCreatedByAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsByReturnQueueCreatedByAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByReturnQueueCreatedByAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueCreatedByAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsByReturnQueueCreatedByAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByItemId: ReturnQueuesConnection;
};


/** A `Item` edge in the connection, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueCreatedByAndItemIdManyToManyEdgeReturnQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `Item` values, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueUpdatedByAndItemIdManyToManyConnection = {
  __typename?: 'UserItemsByReturnQueueUpdatedByAndItemIdManyToManyConnection';
  /** A list of `Item` objects. */
  nodes: Array<Item>;
  /** A list of edges which contains the `Item`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserItemsByReturnQueueUpdatedByAndItemIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Item` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Item` edge in the connection, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueUpdatedByAndItemIdManyToManyEdge = {
  __typename?: 'UserItemsByReturnQueueUpdatedByAndItemIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Item` at the end of the edge. */
  node: Item;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByItemId: ReturnQueuesConnection;
};


/** A `Item` edge in the connection, with data from `ReturnQueue`. */
export type UserItemsByReturnQueueUpdatedByAndItemIdManyToManyEdgeReturnQueuesByItemIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

export type UserLocation = Node & {
  __typename?: 'UserLocation';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  userId: Scalars['Int'];
  locationId: Scalars['Int'];
  createdAt?: Maybe<Scalars['Datetime']>;
  updatedAt?: Maybe<Scalars['Datetime']>;
  /** Reads a single `User` that is related to this `UserLocation`. */
  userByUserId?: Maybe<User>;
  /** Reads a single `Location` that is related to this `UserLocation`. */
  locationByLocationId?: Maybe<Location>;
};

/**
 * A condition to be used against `UserLocation` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserLocationCondition = {
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `locationId` field. */
  locationId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

/** A filter to be used against `UserLocation` object types. All fields are combined with a logical ‘and.’ */
export type UserLocationFilter = {
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<IntFilter>;
  /** Filter by the object’s `locationId` field. */
  locationId?: Maybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** Filter by the object’s `locationByLocationId` relation. */
  locationByLocationId?: Maybe<LocationFilter>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserLocationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserLocationFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserLocationFilter>;
};

/** An input for mutations affecting `UserLocation` */
export type UserLocationInput = {
  userId: Scalars['Int'];
  locationId: Scalars['Int'];
};

/** Represents an update to a `UserLocation`. Fields that are set will be updated. */
export type UserLocationPatch = {
  userId?: Maybe<Scalars['Int']>;
  locationId?: Maybe<Scalars['Int']>;
};

/** A connection to a list of `Location` values, with data from `Invoice`. */
export type UserLocationsByInvoiceAlternatePaymentCreatedByAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByInvoiceAlternatePaymentCreatedByAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByInvoiceAlternatePaymentCreatedByAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `Invoice`. */
export type UserLocationsByInvoiceAlternatePaymentCreatedByAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByInvoiceAlternatePaymentCreatedByAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByLocationId: InvoicesConnection;
};


/** A `Location` edge in the connection, with data from `Invoice`. */
export type UserLocationsByInvoiceAlternatePaymentCreatedByAndLocationIdManyToManyEdgeInvoicesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Location` values, with data from `Invoice`. */
export type UserLocationsByInvoiceProcessPaymentChangedByAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByInvoiceProcessPaymentChangedByAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByInvoiceProcessPaymentChangedByAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `Invoice`. */
export type UserLocationsByInvoiceProcessPaymentChangedByAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByInvoiceProcessPaymentChangedByAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByLocationId: InvoicesConnection;
};


/** A `Location` edge in the connection, with data from `Invoice`. */
export type UserLocationsByInvoiceProcessPaymentChangedByAndLocationIdManyToManyEdgeInvoicesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `Location` values, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueAssignedUserIdAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPicklistQueueAssignedUserIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPicklistQueueAssignedUserIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueAssignedUserIdAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPicklistQueueAssignedUserIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByLocationId: PicklistQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueAssignedUserIdAndLocationIdManyToManyEdgePicklistQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueCompletedByAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPicklistQueueCompletedByAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPicklistQueueCompletedByAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueCompletedByAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPicklistQueueCompletedByAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByLocationId: PicklistQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueCompletedByAndLocationIdManyToManyEdgePicklistQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueCreatedByAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPicklistQueueCreatedByAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPicklistQueueCreatedByAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueCreatedByAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPicklistQueueCreatedByAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByLocationId: PicklistQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueCreatedByAndLocationIdManyToManyEdgePicklistQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueStepUpdatedByAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPicklistQueueStepUpdatedByAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPicklistQueueStepUpdatedByAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueStepUpdatedByAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPicklistQueueStepUpdatedByAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByLocationId: PicklistQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PicklistQueue`. */
export type UserLocationsByPicklistQueueStepUpdatedByAndLocationIdManyToManyEdgePicklistQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupCustomer`. */
export type UserLocationsByPickupCustomerUserIdAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPickupCustomerUserIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupCustomer`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPickupCustomerUserIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupCustomer`. */
export type UserLocationsByPickupCustomerUserIdAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPickupCustomerUserIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupCustomer`. */
  pickupCustomersByLocationId: PickupCustomersConnection;
};


/** A `Location` edge in the connection, with data from `PickupCustomer`. */
export type UserLocationsByPickupCustomerUserIdAndLocationIdManyToManyEdgePickupCustomersByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupCustomersOrderBy>>;
  condition?: Maybe<PickupCustomerCondition>;
  filter?: Maybe<PickupCustomerFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueAssignedUserIdAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPickupQueueAssignedUserIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPickupQueueAssignedUserIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueAssignedUserIdAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPickupQueueAssignedUserIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByLocationId: PickupQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueAssignedUserIdAndLocationIdManyToManyEdgePickupQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueCompletedByAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPickupQueueCompletedByAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPickupQueueCompletedByAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueCompletedByAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPickupQueueCompletedByAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByLocationId: PickupQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueCompletedByAndLocationIdManyToManyEdgePickupQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueCreatedByAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPickupQueueCreatedByAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPickupQueueCreatedByAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueCreatedByAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPickupQueueCreatedByAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByLocationId: PickupQueuesConnection;
};


/** A `Location` edge in the connection, with data from `PickupQueue`. */
export type UserLocationsByPickupQueueCreatedByAndLocationIdManyToManyEdgePickupQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `PickupSession`. */
export type UserLocationsByPickupSessionUserIdAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByPickupSessionUserIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `PickupSession`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByPickupSessionUserIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `PickupSession`. */
export type UserLocationsByPickupSessionUserIdAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByPickupSessionUserIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `PickupSession`. */
  pickupSessionsByLocationId: PickupSessionsConnection;
};


/** A `Location` edge in the connection, with data from `PickupSession`. */
export type UserLocationsByPickupSessionUserIdAndLocationIdManyToManyEdgePickupSessionsByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupSessionsOrderBy>>;
  condition?: Maybe<PickupSessionCondition>;
  filter?: Maybe<PickupSessionFilter>;
};

/** A connection to a list of `Location` values, with data from `ShippingQueue`. */
export type UserLocationsByShippingQueueAssignedUserIdAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByShippingQueueAssignedUserIdAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByShippingQueueAssignedUserIdAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `ShippingQueue`. */
export type UserLocationsByShippingQueueAssignedUserIdAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByShippingQueueAssignedUserIdAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByLocationId: ShippingQueuesConnection;
};


/** A `Location` edge in the connection, with data from `ShippingQueue`. */
export type UserLocationsByShippingQueueAssignedUserIdAndLocationIdManyToManyEdgeShippingQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `ShippingQueue`. */
export type UserLocationsByShippingQueueCompletedByAndLocationIdManyToManyConnection = {
  __typename?: 'UserLocationsByShippingQueueCompletedByAndLocationIdManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByShippingQueueCompletedByAndLocationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `ShippingQueue`. */
export type UserLocationsByShippingQueueCompletedByAndLocationIdManyToManyEdge = {
  __typename?: 'UserLocationsByShippingQueueCompletedByAndLocationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByLocationId: ShippingQueuesConnection;
};


/** A `Location` edge in the connection, with data from `ShippingQueue`. */
export type UserLocationsByShippingQueueCompletedByAndLocationIdManyToManyEdgeShippingQueuesByLocationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `Location` values, with data from `Truckload`. */
export type UserLocationsByTruckloadCreatedByAndReceivingLocationManyToManyConnection = {
  __typename?: 'UserLocationsByTruckloadCreatedByAndReceivingLocationManyToManyConnection';
  /** A list of `Location` objects. */
  nodes: Array<Location>;
  /** A list of edges which contains the `Location`, info from the `Truckload`, and the cursor to aid in pagination. */
  edges: Array<UserLocationsByTruckloadCreatedByAndReceivingLocationManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Location` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Location` edge in the connection, with data from `Truckload`. */
export type UserLocationsByTruckloadCreatedByAndReceivingLocationManyToManyEdge = {
  __typename?: 'UserLocationsByTruckloadCreatedByAndReceivingLocationManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Location` at the end of the edge. */
  node: Location;
  /** Reads and enables pagination through a set of `Truckload`. */
  truckloadsByReceivingLocation: TruckloadsConnection;
};


/** A `Location` edge in the connection, with data from `Truckload`. */
export type UserLocationsByTruckloadCreatedByAndReceivingLocationManyToManyEdgeTruckloadsByReceivingLocationArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TruckloadsOrderBy>>;
  condition?: Maybe<TruckloadCondition>;
  filter?: Maybe<TruckloadFilter>;
};

/** A connection to a list of `UserLocation` values. */
export type UserLocationsConnection = {
  __typename?: 'UserLocationsConnection';
  /** A list of `UserLocation` objects. */
  nodes: Array<UserLocation>;
  /** A list of edges which contains the `UserLocation` and cursor to aid in pagination. */
  edges: Array<UserLocationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserLocation` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserLocation` edge in the connection. */
export type UserLocationsEdge = {
  __typename?: 'UserLocationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserLocation` at the end of the edge. */
  node: UserLocation;
};

/** Methods to use when ordering `UserLocation`. */
export enum UserLocationsOrderBy {
  Natural = 'NATURAL',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  LocationIdAsc = 'LOCATION_ID_ASC',
  LocationIdDesc = 'LOCATION_ID_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

export type UserNotification = Node & {
  __typename?: 'UserNotification';
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID'];
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  meta?: Maybe<UserNotificationMetaType>;
  before?: Maybe<UserNotificationBeforeType>;
  after?: Maybe<UserNotificationAfterType>;
  result?: Maybe<UserNotificationResponse>;
  isCompleted: Scalars['Boolean'];
  isCleared: Scalars['Boolean'];
  createdAt: Scalars['Datetime'];
  updatedAt: Scalars['Datetime'];
  /** Reads a single `User` that is related to this `UserNotification`. */
  userByUserId?: Maybe<User>;
  /** Reads and enables pagination through a set of `JobQueue`. */
  jobQueuesByUserNotificationId: JobQueuesConnection;
  /** Reads and enables pagination through a set of `User`. */
  usersByJobQueueUserNotificationIdAndCreatedBy: UserNotificationUsersByJobQueueUserNotificationIdAndCreatedByManyToManyConnection;
};


export type UserNotificationJobQueuesByUserNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<JobQueuesOrderBy>>;
  condition?: Maybe<JobQueueCondition>;
  filter?: Maybe<JobQueueFilter>;
};


export type UserNotificationUsersByJobQueueUserNotificationIdAndCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy>>;
  condition?: Maybe<UserCondition>;
  filter?: Maybe<UserFilter>;
};

export type UserNotificationAfterType = {
  __typename?: 'UserNotificationAfterType';
  message?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type UserNotificationBeforeType = {
  __typename?: 'UserNotificationBeforeType';
  message?: Maybe<Scalars['String']>;
};

/**
 * A condition to be used against `UserNotification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserNotificationCondition = {
  /** Checks for equality with the object’s `id` field. */
  id?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: Maybe<Scalars['Int']>;
  /** Checks for equality with the object’s `meta` field. */
  meta?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `before` field. */
  before?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `after` field. */
  after?: Maybe<Scalars['JSON']>;
  /** Checks for equality with the object’s `result` field. */
  result?: Maybe<UserNotificationResponse>;
  /** Checks for equality with the object’s `isCompleted` field. */
  isCompleted?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `isCleared` field. */
  isCleared?: Maybe<Scalars['Boolean']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: Maybe<Scalars['Datetime']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: Maybe<Scalars['Datetime']>;
};

export type UserNotificationEventSubscriptionPayload = {
  __typename?: 'UserNotificationEventSubscriptionPayload';
  notification?: Maybe<UserNotification>;
  event: UserNotificationEventType;
};

export enum UserNotificationEventType {
  Updated = 'updated',
  Deleted = 'deleted',
  Added = 'added'
}

/** A filter to be used against `UserNotification` object types. All fields are combined with a logical ‘and.’ */
export type UserNotificationFilter = {
  /** Filter by the object’s `id` field. */
  id?: Maybe<IntFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: Maybe<IntFilter>;
  /** Filter by the object’s `meta` field. */
  meta?: Maybe<JsonFilter>;
  /** Filter by the object’s `before` field. */
  before?: Maybe<JsonFilter>;
  /** Filter by the object’s `after` field. */
  after?: Maybe<JsonFilter>;
  /** Filter by the object’s `result` field. */
  result?: Maybe<UserNotificationResponseFilter>;
  /** Filter by the object’s `isCompleted` field. */
  isCompleted?: Maybe<BooleanFilter>;
  /** Filter by the object’s `isCleared` field. */
  isCleared?: Maybe<BooleanFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: Maybe<DatetimeFilter>;
  /** Filter by the object’s `jobQueuesByUserNotificationId` relation. */
  jobQueuesByUserNotificationId?: Maybe<UserNotificationToManyJobQueueFilter>;
  /** Some related `jobQueuesByUserNotificationId` exist. */
  jobQueuesByUserNotificationIdExist?: Maybe<Scalars['Boolean']>;
  /** Filter by the object’s `userByUserId` relation. */
  userByUserId?: Maybe<UserFilter>;
  /** A related `userByUserId` exists. */
  userByUserIdExists?: Maybe<Scalars['Boolean']>;
  /** Checks for all expressions in this list. */
  and?: Maybe<Array<UserNotificationFilter>>;
  /** Checks for any expressions in this list. */
  or?: Maybe<Array<UserNotificationFilter>>;
  /** Negates the expression. */
  not?: Maybe<UserNotificationFilter>;
};

export type UserNotificationMetaType = {
  __typename?: 'UserNotificationMetaType';
  text?: Maybe<Scalars['String']>;
  type?: Maybe<UserNotificationMetaTypeEnum>;
  id?: Maybe<Scalars['String']>;
};

export enum UserNotificationMetaTypeEnum {
  Invoice = 'INVOICE',
  Auction = 'AUCTION',
  Customer = 'CUSTOMER',
  Payment = 'PAYMENT',
  ReturnFromQueue = 'RETURN_FROM_QUEUE',
  ReturnFromInvoice = 'RETURN_FROM_INVOICE',
  Download = 'DOWNLOAD'
}

/** Represents an update to a `UserNotification`. Fields that are set will be updated. */
export type UserNotificationPatch = {
  isCleared?: Maybe<Scalars['Boolean']>;
};

export enum UserNotificationResponse {
  Ok = 'OK',
  Error = 'ERROR'
}

/** A filter to be used against UserNotificationResponse fields. All fields are combined with a logical ‘and.’ */
export type UserNotificationResponseFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<UserNotificationResponse>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<UserNotificationResponse>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<UserNotificationResponse>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<UserNotificationResponse>;
  /** Included in the specified list. */
  in?: Maybe<Array<UserNotificationResponse>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<UserNotificationResponse>>;
  /** Less than the specified value. */
  lessThan?: Maybe<UserNotificationResponse>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<UserNotificationResponse>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<UserNotificationResponse>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<UserNotificationResponse>;
};

/** A filter to be used against many `JobQueue` object types. All fields are combined with a logical ‘and.’ */
export type UserNotificationToManyJobQueueFilter = {
  /** Every related `JobQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<JobQueueFilter>;
  /** Some related `JobQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<JobQueueFilter>;
  /** No related `JobQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<JobQueueFilter>;
};

/** A connection to a list of `User` values, with data from `JobQueue`. */
export type UserNotificationUsersByJobQueueUserNotificationIdAndCreatedByManyToManyConnection = {
  __typename?: 'UserNotificationUsersByJobQueueUserNotificationIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `JobQueue`, and the cursor to aid in pagination. */
  edges: Array<UserNotificationUsersByJobQueueUserNotificationIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `JobQueue`. */
export type UserNotificationUsersByJobQueueUserNotificationIdAndCreatedByManyToManyEdge = {
  __typename?: 'UserNotificationUsersByJobQueueUserNotificationIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `JobQueue`. */
  jobQueuesByCreatedBy: JobQueuesConnection;
};


/** A `User` edge in the connection, with data from `JobQueue`. */
export type UserNotificationUsersByJobQueueUserNotificationIdAndCreatedByManyToManyEdgeJobQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<JobQueuesOrderBy>>;
  condition?: Maybe<JobQueueCondition>;
  filter?: Maybe<JobQueueFilter>;
};

/** A connection to a list of `UserNotification` values. */
export type UserNotificationsConnection = {
  __typename?: 'UserNotificationsConnection';
  /** A list of `UserNotification` objects. */
  nodes: Array<UserNotification>;
  /** A list of edges which contains the `UserNotification` and cursor to aid in pagination. */
  edges: Array<UserNotificationsEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserNotification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserNotification` edge in the connection. */
export type UserNotificationsEdge = {
  __typename?: 'UserNotificationsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserNotification` at the end of the edge. */
  node: UserNotification;
};

/** Methods to use when ordering `UserNotification`. */
export enum UserNotificationsOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UserIdAsc = 'USER_ID_ASC',
  UserIdDesc = 'USER_ID_DESC',
  MetaAsc = 'META_ASC',
  MetaDesc = 'META_DESC',
  BeforeAsc = 'BEFORE_ASC',
  BeforeDesc = 'BEFORE_DESC',
  AfterAsc = 'AFTER_ASC',
  AfterDesc = 'AFTER_DESC',
  ResultAsc = 'RESULT_ASC',
  ResultDesc = 'RESULT_DESC',
  IsCompletedAsc = 'IS_COMPLETED_ASC',
  IsCompletedDesc = 'IS_COMPLETED_DESC',
  IsClearedAsc = 'IS_CLEARED_ASC',
  IsClearedDesc = 'IS_CLEARED_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** A connection to a list of `Payment` values, with data from `Item`. */
export type UserPaymentsByItemDeletedFromInvoiceByAndPaymentIdManyToManyConnection = {
  __typename?: 'UserPaymentsByItemDeletedFromInvoiceByAndPaymentIdManyToManyConnection';
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** A list of edges which contains the `Payment`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserPaymentsByItemDeletedFromInvoiceByAndPaymentIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection, with data from `Item`. */
export type UserPaymentsByItemDeletedFromInvoiceByAndPaymentIdManyToManyEdge = {
  __typename?: 'UserPaymentsByItemDeletedFromInvoiceByAndPaymentIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPaymentId: ItemsConnection;
};


/** A `Payment` edge in the connection, with data from `Item`. */
export type UserPaymentsByItemDeletedFromInvoiceByAndPaymentIdManyToManyEdgeItemsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Payment` values, with data from `Item`. */
export type UserPaymentsByItemIsDeletedByAndPaymentIdManyToManyConnection = {
  __typename?: 'UserPaymentsByItemIsDeletedByAndPaymentIdManyToManyConnection';
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** A list of edges which contains the `Payment`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserPaymentsByItemIsDeletedByAndPaymentIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection, with data from `Item`. */
export type UserPaymentsByItemIsDeletedByAndPaymentIdManyToManyEdge = {
  __typename?: 'UserPaymentsByItemIsDeletedByAndPaymentIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPaymentId: ItemsConnection;
};


/** A `Payment` edge in the connection, with data from `Item`. */
export type UserPaymentsByItemIsDeletedByAndPaymentIdManyToManyEdgeItemsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `Payment` values, with data from `Item`. */
export type UserPaymentsByItemSetAsideByAndPaymentIdManyToManyConnection = {
  __typename?: 'UserPaymentsByItemSetAsideByAndPaymentIdManyToManyConnection';
  /** A list of `Payment` objects. */
  nodes: Array<Payment>;
  /** A list of edges which contains the `Payment`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserPaymentsByItemSetAsideByAndPaymentIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Payment` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `Payment` edge in the connection, with data from `Item`. */
export type UserPaymentsByItemSetAsideByAndPaymentIdManyToManyEdge = {
  __typename?: 'UserPaymentsByItemSetAsideByAndPaymentIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `Payment` at the end of the edge. */
  node: Payment;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByPaymentId: ItemsConnection;
};


/** A `Payment` edge in the connection, with data from `Item`. */
export type UserPaymentsByItemSetAsideByAndPaymentIdManyToManyEdgeItemsByPaymentIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `PickupSession` values, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueAssignedUserIdAndSessionIdManyToManyConnection = {
  __typename?: 'UserPickupSessionsByPickupQueueAssignedUserIdAndSessionIdManyToManyConnection';
  /** A list of `PickupSession` objects. */
  nodes: Array<PickupSession>;
  /** A list of edges which contains the `PickupSession`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserPickupSessionsByPickupQueueAssignedUserIdAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueAssignedUserIdAndSessionIdManyToManyEdge = {
  __typename?: 'UserPickupSessionsByPickupQueueAssignedUserIdAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupSession` at the end of the edge. */
  node: PickupSession;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesBySessionId: PickupQueuesConnection;
};


/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueAssignedUserIdAndSessionIdManyToManyEdgePickupQueuesBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `PickupSession` values, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueCompletedByAndSessionIdManyToManyConnection = {
  __typename?: 'UserPickupSessionsByPickupQueueCompletedByAndSessionIdManyToManyConnection';
  /** A list of `PickupSession` objects. */
  nodes: Array<PickupSession>;
  /** A list of edges which contains the `PickupSession`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserPickupSessionsByPickupQueueCompletedByAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueCompletedByAndSessionIdManyToManyEdge = {
  __typename?: 'UserPickupSessionsByPickupQueueCompletedByAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupSession` at the end of the edge. */
  node: PickupSession;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesBySessionId: PickupQueuesConnection;
};


/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueCompletedByAndSessionIdManyToManyEdgePickupQueuesBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `PickupSession` values, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueCreatedByAndSessionIdManyToManyConnection = {
  __typename?: 'UserPickupSessionsByPickupQueueCreatedByAndSessionIdManyToManyConnection';
  /** A list of `PickupSession` objects. */
  nodes: Array<PickupSession>;
  /** A list of edges which contains the `PickupSession`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserPickupSessionsByPickupQueueCreatedByAndSessionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `PickupSession` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueCreatedByAndSessionIdManyToManyEdge = {
  __typename?: 'UserPickupSessionsByPickupQueueCreatedByAndSessionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `PickupSession` at the end of the edge. */
  node: PickupSession;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesBySessionId: PickupQueuesConnection;
};


/** A `PickupSession` edge in the connection, with data from `PickupQueue`. */
export type UserPickupSessionsByPickupQueueCreatedByAndSessionIdManyToManyEdgePickupQueuesBySessionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `ReturnOption` values, with data from `InvoiceItemReturn`. */
export type UserReturnOptionsByInvoiceItemReturnCreatedByAndOptionIdManyToManyConnection = {
  __typename?: 'UserReturnOptionsByInvoiceItemReturnCreatedByAndOptionIdManyToManyConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<UserReturnOptionsByInvoiceItemReturnCreatedByAndOptionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserReturnOptionsByInvoiceItemReturnCreatedByAndOptionIdManyToManyEdge = {
  __typename?: 'UserReturnOptionsByInvoiceItemReturnCreatedByAndOptionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByOptionId: InvoiceItemReturnsConnection;
};


/** A `ReturnOption` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserReturnOptionsByInvoiceItemReturnCreatedByAndOptionIdManyToManyEdgeInvoiceItemReturnsByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `ReturnOption` values, with data from `InvoiceItemReturn`. */
export type UserReturnOptionsByInvoiceItemReturnUpdatedByAndOptionIdManyToManyConnection = {
  __typename?: 'UserReturnOptionsByInvoiceItemReturnUpdatedByAndOptionIdManyToManyConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<UserReturnOptionsByInvoiceItemReturnUpdatedByAndOptionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserReturnOptionsByInvoiceItemReturnUpdatedByAndOptionIdManyToManyEdge = {
  __typename?: 'UserReturnOptionsByInvoiceItemReturnUpdatedByAndOptionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByOptionId: InvoiceItemReturnsConnection;
};


/** A `ReturnOption` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserReturnOptionsByInvoiceItemReturnUpdatedByAndOptionIdManyToManyEdgeInvoiceItemReturnsByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `ReturnOption` values, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueCompletedByAndOptionIdManyToManyConnection = {
  __typename?: 'UserReturnOptionsByReturnQueueCompletedByAndOptionIdManyToManyConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserReturnOptionsByReturnQueueCompletedByAndOptionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueCompletedByAndOptionIdManyToManyEdge = {
  __typename?: 'UserReturnOptionsByReturnQueueCompletedByAndOptionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByOptionId: ReturnQueuesConnection;
};


/** A `ReturnOption` edge in the connection, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueCompletedByAndOptionIdManyToManyEdgeReturnQueuesByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `ReturnOption` values, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueCreatedByAndOptionIdManyToManyConnection = {
  __typename?: 'UserReturnOptionsByReturnQueueCreatedByAndOptionIdManyToManyConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserReturnOptionsByReturnQueueCreatedByAndOptionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueCreatedByAndOptionIdManyToManyEdge = {
  __typename?: 'UserReturnOptionsByReturnQueueCreatedByAndOptionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByOptionId: ReturnQueuesConnection;
};


/** A `ReturnOption` edge in the connection, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueCreatedByAndOptionIdManyToManyEdgeReturnQueuesByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `ReturnOption` values, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueUpdatedByAndOptionIdManyToManyConnection = {
  __typename?: 'UserReturnOptionsByReturnQueueUpdatedByAndOptionIdManyToManyConnection';
  /** A list of `ReturnOption` objects. */
  nodes: Array<ReturnOption>;
  /** A list of edges which contains the `ReturnOption`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserReturnOptionsByReturnQueueUpdatedByAndOptionIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnOption` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnOption` edge in the connection, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueUpdatedByAndOptionIdManyToManyEdge = {
  __typename?: 'UserReturnOptionsByReturnQueueUpdatedByAndOptionIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnOption` at the end of the edge. */
  node: ReturnOption;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByOptionId: ReturnQueuesConnection;
};


/** A `ReturnOption` edge in the connection, with data from `ReturnQueue`. */
export type UserReturnOptionsByReturnQueueUpdatedByAndOptionIdManyToManyEdgeReturnQueuesByOptionIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `ReturnQueue` values, with data from `InvoiceItemReturn`. */
export type UserReturnQueuesByInvoiceItemReturnCreatedByAndQueueIdManyToManyConnection = {
  __typename?: 'UserReturnQueuesByInvoiceItemReturnCreatedByAndQueueIdManyToManyConnection';
  /** A list of `ReturnQueue` objects. */
  nodes: Array<ReturnQueue>;
  /** A list of edges which contains the `ReturnQueue`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<UserReturnQueuesByInvoiceItemReturnCreatedByAndQueueIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnQueue` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserReturnQueuesByInvoiceItemReturnCreatedByAndQueueIdManyToManyEdge = {
  __typename?: 'UserReturnQueuesByInvoiceItemReturnCreatedByAndQueueIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnQueue` at the end of the edge. */
  node: ReturnQueue;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByQueueId: InvoiceItemReturnsConnection;
};


/** A `ReturnQueue` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserReturnQueuesByInvoiceItemReturnCreatedByAndQueueIdManyToManyEdgeInvoiceItemReturnsByQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `ReturnQueue` values, with data from `InvoiceItemReturn`. */
export type UserReturnQueuesByInvoiceItemReturnUpdatedByAndQueueIdManyToManyConnection = {
  __typename?: 'UserReturnQueuesByInvoiceItemReturnUpdatedByAndQueueIdManyToManyConnection';
  /** A list of `ReturnQueue` objects. */
  nodes: Array<ReturnQueue>;
  /** A list of edges which contains the `ReturnQueue`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<UserReturnQueuesByInvoiceItemReturnUpdatedByAndQueueIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ReturnQueue` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `ReturnQueue` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserReturnQueuesByInvoiceItemReturnUpdatedByAndQueueIdManyToManyEdge = {
  __typename?: 'UserReturnQueuesByInvoiceItemReturnUpdatedByAndQueueIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `ReturnQueue` at the end of the edge. */
  node: ReturnQueue;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByQueueId: InvoiceItemReturnsConnection;
};


/** A `ReturnQueue` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserReturnQueuesByInvoiceItemReturnUpdatedByAndQueueIdManyToManyEdgeInvoiceItemReturnsByQueueIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

export enum UserRoles {
  Customer = 'CUSTOMER',
  Employee = 'EMPLOYEE',
  Secretary = 'SECRETARY',
  Administrator = 'ADMINISTRATOR'
}

/** A filter to be used against UserRoles fields. All fields are combined with a logical ‘and.’ */
export type UserRolesFilter = {
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: Maybe<Scalars['Boolean']>;
  /** Equal to the specified value. */
  equalTo?: Maybe<UserRoles>;
  /** Not equal to the specified value. */
  notEqualTo?: Maybe<UserRoles>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: Maybe<UserRoles>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: Maybe<UserRoles>;
  /** Included in the specified list. */
  in?: Maybe<Array<UserRoles>>;
  /** Not included in the specified list. */
  notIn?: Maybe<Array<UserRoles>>;
  /** Less than the specified value. */
  lessThan?: Maybe<UserRoles>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: Maybe<UserRoles>;
  /** Greater than the specified value. */
  greaterThan?: Maybe<UserRoles>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: Maybe<UserRoles>;
};

/** A filter to be used against many `BidderCreditHistory` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyBidderCreditHistoryFilter = {
  /** Every related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<BidderCreditHistoryFilter>;
  /** Some related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<BidderCreditHistoryFilter>;
  /** No related `BidderCreditHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<BidderCreditHistoryFilter>;
};

/** A filter to be used against many `Bidder` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyBidderFilter = {
  /** Every related `Bidder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<BidderFilter>;
  /** Some related `Bidder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<BidderFilter>;
  /** No related `Bidder` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<BidderFilter>;
};

/** A filter to be used against many `CustomerActionsView` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyCustomerActionsViewFilter = {
  /** Every related `CustomerActionsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CustomerActionsViewFilter>;
  /** Some related `CustomerActionsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CustomerActionsViewFilter>;
  /** No related `CustomerActionsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CustomerActionsViewFilter>;
};

/** A filter to be used against many `CustomerAlertsView` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyCustomerAlertsViewFilter = {
  /** Every related `CustomerAlertsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CustomerAlertsViewFilter>;
  /** Some related `CustomerAlertsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CustomerAlertsViewFilter>;
  /** No related `CustomerAlertsView` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CustomerAlertsViewFilter>;
};

/** A filter to be used against many `CustomerNote` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyCustomerNoteFilter = {
  /** Every related `CustomerNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<CustomerNoteFilter>;
  /** Some related `CustomerNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<CustomerNoteFilter>;
  /** No related `CustomerNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<CustomerNoteFilter>;
};

/** A filter to be used against many `Invoice` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyInvoiceFilter = {
  /** Every related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceFilter>;
  /** Some related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceFilter>;
  /** No related `Invoice` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceFilter>;
};

/** A filter to be used against many `InvoiceItemReturn` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyInvoiceItemReturnFilter = {
  /** Every related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceItemReturnFilter>;
  /** Some related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceItemReturnFilter>;
  /** No related `InvoiceItemReturn` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceItemReturnFilter>;
};

/** A filter to be used against many `InvoiceItemReturnHistory` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyInvoiceItemReturnHistoryFilter = {
  /** Every related `InvoiceItemReturnHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceItemReturnHistoryFilter>;
  /** Some related `InvoiceItemReturnHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceItemReturnHistoryFilter>;
  /** No related `InvoiceItemReturnHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceItemReturnHistoryFilter>;
};

/** A filter to be used against many `InvoiceNote` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyInvoiceNoteFilter = {
  /** Every related `InvoiceNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<InvoiceNoteFilter>;
  /** Some related `InvoiceNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<InvoiceNoteFilter>;
  /** No related `InvoiceNote` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<InvoiceNoteFilter>;
};

/** A filter to be used against many `ItemActionHistory` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyItemActionHistoryFilter = {
  /** Every related `ItemActionHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemActionHistoryFilter>;
  /** Some related `ItemActionHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemActionHistoryFilter>;
  /** No related `ItemActionHistory` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemActionHistoryFilter>;
};

/** A filter to be used against many `Item` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyItemFilter = {
  /** Every related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemFilter>;
  /** Some related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemFilter>;
  /** No related `Item` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemFilter>;
};

/** A filter to be used against many `ItemRackLocation` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyItemRackLocationFilter = {
  /** Every related `ItemRackLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ItemRackLocationFilter>;
  /** Some related `ItemRackLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ItemRackLocationFilter>;
  /** No related `ItemRackLocation` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ItemRackLocationFilter>;
};

/** A filter to be used against many `JobQueue` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyJobQueueFilter = {
  /** Every related `JobQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<JobQueueFilter>;
  /** Some related `JobQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<JobQueueFilter>;
  /** No related `JobQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<JobQueueFilter>;
};

/** A filter to be used against many `LivePickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyLivePickupQueueFilter = {
  /** Every related `LivePickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<LivePickupQueueFilter>;
  /** Some related `LivePickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<LivePickupQueueFilter>;
  /** No related `LivePickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<LivePickupQueueFilter>;
};

/** A filter to be used against many `PicklistQueue` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyPicklistQueueFilter = {
  /** Every related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PicklistQueueFilter>;
  /** Some related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PicklistQueueFilter>;
  /** No related `PicklistQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PicklistQueueFilter>;
};

/** A filter to be used against many `PickupCustomer` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyPickupCustomerFilter = {
  /** Every related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupCustomerFilter>;
  /** Some related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupCustomerFilter>;
  /** No related `PickupCustomer` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupCustomerFilter>;
};

/** A filter to be used against many `PickupQueue` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyPickupQueueFilter = {
  /** Every related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupQueueFilter>;
  /** Some related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupQueueFilter>;
  /** No related `PickupQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupQueueFilter>;
};

/** A filter to be used against many `PickupSession` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyPickupSessionFilter = {
  /** Every related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<PickupSessionFilter>;
  /** Some related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<PickupSessionFilter>;
  /** No related `PickupSession` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<PickupSessionFilter>;
};

/** A filter to be used against many `ReturnQueue` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyReturnQueueFilter = {
  /** Every related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ReturnQueueFilter>;
  /** Some related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ReturnQueueFilter>;
  /** No related `ReturnQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ReturnQueueFilter>;
};

/** A filter to be used against many `ShippingQueue` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyShippingQueueFilter = {
  /** Every related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<ShippingQueueFilter>;
  /** Some related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<ShippingQueueFilter>;
  /** No related `ShippingQueue` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<ShippingQueueFilter>;
};

/** A filter to be used against many `Truckload` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyTruckloadFilter = {
  /** Every related `Truckload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<TruckloadFilter>;
  /** Some related `Truckload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<TruckloadFilter>;
  /** No related `Truckload` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<TruckloadFilter>;
};

/** A filter to be used against many `UserNotification` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyUserNotificationFilter = {
  /** Every related `UserNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: Maybe<UserNotificationFilter>;
  /** Some related `UserNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: Maybe<UserNotificationFilter>;
  /** No related `UserNotification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: Maybe<UserNotificationFilter>;
};

/** A connection to a list of `UserNotification` values, with data from `JobQueue`. */
export type UserUserNotificationsByJobQueueCreatedByAndUserNotificationIdManyToManyConnection = {
  __typename?: 'UserUserNotificationsByJobQueueCreatedByAndUserNotificationIdManyToManyConnection';
  /** A list of `UserNotification` objects. */
  nodes: Array<UserNotification>;
  /** A list of edges which contains the `UserNotification`, info from the `JobQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUserNotificationsByJobQueueCreatedByAndUserNotificationIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserNotification` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `UserNotification` edge in the connection, with data from `JobQueue`. */
export type UserUserNotificationsByJobQueueCreatedByAndUserNotificationIdManyToManyEdge = {
  __typename?: 'UserUserNotificationsByJobQueueCreatedByAndUserNotificationIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `UserNotification` at the end of the edge. */
  node: UserNotification;
  /** Reads and enables pagination through a set of `JobQueue`. */
  jobQueuesByUserNotificationId: JobQueuesConnection;
};


/** A `UserNotification` edge in the connection, with data from `JobQueue`. */
export type UserUserNotificationsByJobQueueCreatedByAndUserNotificationIdManyToManyEdgeJobQueuesByUserNotificationIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<JobQueuesOrderBy>>;
  condition?: Maybe<JobQueueCondition>;
  filter?: Maybe<JobQueueFilter>;
};

/** A connection to a list of `User` values, with data from `Invoice`. */
export type UserUsersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedByManyToManyConnection = {
  __typename?: 'UserUsersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invoice`. */
export type UserUsersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedByManyToManyEdge = {
  __typename?: 'UserUsersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByProcessPaymentChangedBy: InvoicesConnection;
};


/** A `User` edge in the connection, with data from `Invoice`. */
export type UserUsersByInvoiceAlternatePaymentCreatedByAndProcessPaymentChangedByManyToManyEdgeInvoicesByProcessPaymentChangedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturn`. */
export type UserUsersByInvoiceItemReturnCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'UserUsersByInvoiceItemReturnCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByInvoiceItemReturnCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserUsersByInvoiceItemReturnCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'UserUsersByInvoiceItemReturnCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByUpdatedBy: InvoiceItemReturnsConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserUsersByInvoiceItemReturnCreatedByAndUpdatedByManyToManyEdgeInvoiceItemReturnsByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `User` values, with data from `InvoiceItemReturn`. */
export type UserUsersByInvoiceItemReturnUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByInvoiceItemReturnUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `InvoiceItemReturn`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByInvoiceItemReturnUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserUsersByInvoiceItemReturnUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByInvoiceItemReturnUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `InvoiceItemReturn`. */
  invoiceItemReturnsByCreatedBy: InvoiceItemReturnsConnection;
};


/** A `User` edge in the connection, with data from `InvoiceItemReturn`. */
export type UserUsersByInvoiceItemReturnUpdatedByAndCreatedByManyToManyEdgeInvoiceItemReturnsByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoiceItemReturnsOrderBy>>;
  condition?: Maybe<InvoiceItemReturnCondition>;
  filter?: Maybe<InvoiceItemReturnFilter>;
};

/** A connection to a list of `User` values, with data from `Invoice`. */
export type UserUsersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Invoice`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Invoice`. */
export type UserUsersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Invoice`. */
  invoicesByAlternatePaymentCreatedBy: InvoicesConnection;
};


/** A `User` edge in the connection, with data from `Invoice`. */
export type UserUsersByInvoiceProcessPaymentChangedByAndAlternatePaymentCreatedByManyToManyEdgeInvoicesByAlternatePaymentCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy>>;
  condition?: Maybe<InvoiceCondition>;
  filter?: Maybe<InvoiceFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type UserUsersByItemDeletedFromInvoiceByAndIsDeletedByManyToManyConnection = {
  __typename?: 'UserUsersByItemDeletedFromInvoiceByAndIsDeletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByItemDeletedFromInvoiceByAndIsDeletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemDeletedFromInvoiceByAndIsDeletedByManyToManyEdge = {
  __typename?: 'UserUsersByItemDeletedFromInvoiceByAndIsDeletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByIsDeletedBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemDeletedFromInvoiceByAndIsDeletedByManyToManyEdgeItemsByIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type UserUsersByItemDeletedFromInvoiceByAndSetAsideByManyToManyConnection = {
  __typename?: 'UserUsersByItemDeletedFromInvoiceByAndSetAsideByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByItemDeletedFromInvoiceByAndSetAsideByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemDeletedFromInvoiceByAndSetAsideByManyToManyEdge = {
  __typename?: 'UserUsersByItemDeletedFromInvoiceByAndSetAsideByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySetAsideBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemDeletedFromInvoiceByAndSetAsideByManyToManyEdgeItemsBySetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type UserUsersByItemIsDeletedByAndDeletedFromInvoiceByManyToManyConnection = {
  __typename?: 'UserUsersByItemIsDeletedByAndDeletedFromInvoiceByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByItemIsDeletedByAndDeletedFromInvoiceByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemIsDeletedByAndDeletedFromInvoiceByManyToManyEdge = {
  __typename?: 'UserUsersByItemIsDeletedByAndDeletedFromInvoiceByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByDeletedFromInvoiceBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemIsDeletedByAndDeletedFromInvoiceByManyToManyEdgeItemsByDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type UserUsersByItemIsDeletedByAndSetAsideByManyToManyConnection = {
  __typename?: 'UserUsersByItemIsDeletedByAndSetAsideByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByItemIsDeletedByAndSetAsideByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemIsDeletedByAndSetAsideByManyToManyEdge = {
  __typename?: 'UserUsersByItemIsDeletedByAndSetAsideByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsBySetAsideBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemIsDeletedByAndSetAsideByManyToManyEdgeItemsBySetAsideByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type UserUsersByItemSetAsideByAndDeletedFromInvoiceByManyToManyConnection = {
  __typename?: 'UserUsersByItemSetAsideByAndDeletedFromInvoiceByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByItemSetAsideByAndDeletedFromInvoiceByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemSetAsideByAndDeletedFromInvoiceByManyToManyEdge = {
  __typename?: 'UserUsersByItemSetAsideByAndDeletedFromInvoiceByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByDeletedFromInvoiceBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemSetAsideByAndDeletedFromInvoiceByManyToManyEdgeItemsByDeletedFromInvoiceByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `Item`. */
export type UserUsersByItemSetAsideByAndIsDeletedByManyToManyConnection = {
  __typename?: 'UserUsersByItemSetAsideByAndIsDeletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `Item`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByItemSetAsideByAndIsDeletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemSetAsideByAndIsDeletedByManyToManyEdge = {
  __typename?: 'UserUsersByItemSetAsideByAndIsDeletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `Item`. */
  itemsByIsDeletedBy: ItemsConnection;
};


/** A `User` edge in the connection, with data from `Item`. */
export type UserUsersByItemSetAsideByAndIsDeletedByManyToManyEdgeItemsByIsDeletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy>>;
  condition?: Maybe<ItemCondition>;
  filter?: Maybe<ItemFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndCompletedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueAssignedUserIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueAssignedUserIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndCompletedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueAssignedUserIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCompletedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndCompletedByManyToManyEdgePicklistQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueAssignedUserIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueAssignedUserIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueAssignedUserIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCreatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndCreatedByManyToManyEdgePicklistQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndStepUpdatedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueAssignedUserIdAndStepUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueAssignedUserIdAndStepUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndStepUpdatedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueAssignedUserIdAndStepUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByStepUpdatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueAssignedUserIdAndStepUpdatedByManyToManyEdgePicklistQueuesByStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndAssignedUserIdManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueCompletedByAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueCompletedByAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndAssignedUserIdManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueCompletedByAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByAssignedUserId: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndAssignedUserIdManyToManyEdgePicklistQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueCompletedByAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueCompletedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueCompletedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCreatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndCreatedByManyToManyEdgePicklistQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndStepUpdatedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueCompletedByAndStepUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueCompletedByAndStepUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndStepUpdatedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueCompletedByAndStepUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByStepUpdatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCompletedByAndStepUpdatedByManyToManyEdgePicklistQueuesByStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndAssignedUserIdManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueCreatedByAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueCreatedByAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndAssignedUserIdManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueCreatedByAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByAssignedUserId: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndAssignedUserIdManyToManyEdgePicklistQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndCompletedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueCreatedByAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueCreatedByAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndCompletedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueCreatedByAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCompletedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndCompletedByManyToManyEdgePicklistQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndStepUpdatedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueCreatedByAndStepUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueCreatedByAndStepUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndStepUpdatedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueCreatedByAndStepUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByStepUpdatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueCreatedByAndStepUpdatedByManyToManyEdgePicklistQueuesByStepUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndAssignedUserIdManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueStepUpdatedByAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueStepUpdatedByAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndAssignedUserIdManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueStepUpdatedByAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByAssignedUserId: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndAssignedUserIdManyToManyEdgePicklistQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndCompletedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueStepUpdatedByAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueStepUpdatedByAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndCompletedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueStepUpdatedByAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCompletedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndCompletedByManyToManyEdgePicklistQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByPicklistQueueStepUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PicklistQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPicklistQueueStepUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByPicklistQueueStepUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PicklistQueue`. */
  picklistQueuesByCreatedBy: PicklistQueuesConnection;
};


/** A `User` edge in the connection, with data from `PicklistQueue`. */
export type UserUsersByPicklistQueueStepUpdatedByAndCreatedByManyToManyEdgePicklistQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PicklistQueuesOrderBy>>;
  condition?: Maybe<PicklistQueueCondition>;
  filter?: Maybe<PicklistQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type UserUsersByPickupQueueAssignedUserIdAndCompletedByManyToManyConnection = {
  __typename?: 'UserUsersByPickupQueueAssignedUserIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPickupQueueAssignedUserIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueAssignedUserIdAndCompletedByManyToManyEdge = {
  __typename?: 'UserUsersByPickupQueueAssignedUserIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCompletedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueAssignedUserIdAndCompletedByManyToManyEdgePickupQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type UserUsersByPickupQueueAssignedUserIdAndCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByPickupQueueAssignedUserIdAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPickupQueueAssignedUserIdAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueAssignedUserIdAndCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByPickupQueueAssignedUserIdAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCreatedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueAssignedUserIdAndCreatedByManyToManyEdgePickupQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCompletedByAndAssignedUserIdManyToManyConnection = {
  __typename?: 'UserUsersByPickupQueueCompletedByAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPickupQueueCompletedByAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCompletedByAndAssignedUserIdManyToManyEdge = {
  __typename?: 'UserUsersByPickupQueueCompletedByAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByAssignedUserId: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCompletedByAndAssignedUserIdManyToManyEdgePickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCompletedByAndCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByPickupQueueCompletedByAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPickupQueueCompletedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCompletedByAndCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByPickupQueueCompletedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCreatedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCompletedByAndCreatedByManyToManyEdgePickupQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCreatedByAndAssignedUserIdManyToManyConnection = {
  __typename?: 'UserUsersByPickupQueueCreatedByAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPickupQueueCreatedByAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCreatedByAndAssignedUserIdManyToManyEdge = {
  __typename?: 'UserUsersByPickupQueueCreatedByAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByAssignedUserId: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCreatedByAndAssignedUserIdManyToManyEdgePickupQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCreatedByAndCompletedByManyToManyConnection = {
  __typename?: 'UserUsersByPickupQueueCreatedByAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `PickupQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByPickupQueueCreatedByAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCreatedByAndCompletedByManyToManyEdge = {
  __typename?: 'UserUsersByPickupQueueCreatedByAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `PickupQueue`. */
  pickupQueuesByCompletedBy: PickupQueuesConnection;
};


/** A `User` edge in the connection, with data from `PickupQueue`. */
export type UserUsersByPickupQueueCreatedByAndCompletedByManyToManyEdgePickupQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PickupQueuesOrderBy>>;
  condition?: Maybe<PickupQueueCondition>;
  filter?: Maybe<PickupQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCompletedByAndCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByReturnQueueCompletedByAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByReturnQueueCompletedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCompletedByAndCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByReturnQueueCompletedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCreatedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCompletedByAndCreatedByManyToManyEdgeReturnQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCompletedByAndUpdatedByManyToManyConnection = {
  __typename?: 'UserUsersByReturnQueueCompletedByAndUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByReturnQueueCompletedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCompletedByAndUpdatedByManyToManyEdge = {
  __typename?: 'UserUsersByReturnQueueCompletedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByUpdatedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCompletedByAndUpdatedByManyToManyEdgeReturnQueuesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCreatedByAndCompletedByManyToManyConnection = {
  __typename?: 'UserUsersByReturnQueueCreatedByAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByReturnQueueCreatedByAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCreatedByAndCompletedByManyToManyEdge = {
  __typename?: 'UserUsersByReturnQueueCreatedByAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCompletedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCreatedByAndCompletedByManyToManyEdgeReturnQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCreatedByAndUpdatedByManyToManyConnection = {
  __typename?: 'UserUsersByReturnQueueCreatedByAndUpdatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByReturnQueueCreatedByAndUpdatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCreatedByAndUpdatedByManyToManyEdge = {
  __typename?: 'UserUsersByReturnQueueCreatedByAndUpdatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByUpdatedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueCreatedByAndUpdatedByManyToManyEdgeReturnQueuesByUpdatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueUpdatedByAndCompletedByManyToManyConnection = {
  __typename?: 'UserUsersByReturnQueueUpdatedByAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByReturnQueueUpdatedByAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueUpdatedByAndCompletedByManyToManyEdge = {
  __typename?: 'UserUsersByReturnQueueUpdatedByAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCompletedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueUpdatedByAndCompletedByManyToManyEdgeReturnQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueUpdatedByAndCreatedByManyToManyConnection = {
  __typename?: 'UserUsersByReturnQueueUpdatedByAndCreatedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ReturnQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByReturnQueueUpdatedByAndCreatedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueUpdatedByAndCreatedByManyToManyEdge = {
  __typename?: 'UserUsersByReturnQueueUpdatedByAndCreatedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ReturnQueue`. */
  returnQueuesByCreatedBy: ReturnQueuesConnection;
};


/** A `User` edge in the connection, with data from `ReturnQueue`. */
export type UserUsersByReturnQueueUpdatedByAndCreatedByManyToManyEdgeReturnQueuesByCreatedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy>>;
  condition?: Maybe<ReturnQueueCondition>;
  filter?: Maybe<ReturnQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ShippingQueue`. */
export type UserUsersByShippingQueueAssignedUserIdAndCompletedByManyToManyConnection = {
  __typename?: 'UserUsersByShippingQueueAssignedUserIdAndCompletedByManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByShippingQueueAssignedUserIdAndCompletedByManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type UserUsersByShippingQueueAssignedUserIdAndCompletedByManyToManyEdge = {
  __typename?: 'UserUsersByShippingQueueAssignedUserIdAndCompletedByManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByCompletedBy: ShippingQueuesConnection;
};


/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type UserUsersByShippingQueueAssignedUserIdAndCompletedByManyToManyEdgeShippingQueuesByCompletedByArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `User` values, with data from `ShippingQueue`. */
export type UserUsersByShippingQueueCompletedByAndAssignedUserIdManyToManyConnection = {
  __typename?: 'UserUsersByShippingQueueCompletedByAndAssignedUserIdManyToManyConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User`, info from the `ShippingQueue`, and the cursor to aid in pagination. */
  edges: Array<UserUsersByShippingQueueCompletedByAndAssignedUserIdManyToManyEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type UserUsersByShippingQueueCompletedByAndAssignedUserIdManyToManyEdge = {
  __typename?: 'UserUsersByShippingQueueCompletedByAndAssignedUserIdManyToManyEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
  /** Reads and enables pagination through a set of `ShippingQueue`. */
  shippingQueuesByAssignedUserId: ShippingQueuesConnection;
};


/** A `User` edge in the connection, with data from `ShippingQueue`. */
export type UserUsersByShippingQueueCompletedByAndAssignedUserIdManyToManyEdgeShippingQueuesByAssignedUserIdArgs = {
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Cursor']>;
  after?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ShippingQueuesOrderBy>>;
  condition?: Maybe<ShippingQueueCondition>;
  filter?: Maybe<ShippingQueueFilter>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  Natural = 'NATURAL',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  UsernameAsc = 'USERNAME_ASC',
  UsernameDesc = 'USERNAME_DESC',
  FirstNameAsc = 'FIRST_NAME_ASC',
  FirstNameDesc = 'FIRST_NAME_DESC',
  LastNameAsc = 'LAST_NAME_ASC',
  LastNameDesc = 'LAST_NAME_DESC',
  RoleAsc = 'ROLE_ASC',
  RoleDesc = 'ROLE_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PrimaryKeyAsc = 'PRIMARY_KEY_ASC',
  PrimaryKeyDesc = 'PRIMARY_KEY_DESC'
}

/** All input for the `voidPayment` mutation. */
export type VoidPaymentInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  paymentId: Scalars['Int'];
};

/** The output of our `voidPayment` mutation. */
export type VoidPaymentPayload = {
  __typename?: 'VoidPaymentPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']>;
  willProcess?: Maybe<Scalars['Boolean']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

export type DashboardAnalyticsQueryVariables = Exact<{
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
  groupBy: ReportGroupBy;
}>;


export type DashboardAnalyticsQuery = (
  { __typename?: 'Query' }
  & { dashboardAnalytics?: Maybe<(
    { __typename?: 'DashboardAnalyticsType' }
    & Pick<DashboardAnalyticsType, 'numItemsReturned' | 'numItemsSold'>
  )>, runItemSoldReport?: Maybe<(
    { __typename?: 'RunItemSoldReportConnection' }
    & { nodes: Array<(
      { __typename?: 'RunItemSoldReportRecord' }
      & Pick<RunItemSoldReportRecord, 'date' | 'count'>
    )> }
  )> }
);

export type TotalAuctionChangesQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalAuctionChangesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalAuctionChanges'>
);

export type TotalActiveReturnQueueQueryVariables = Exact<{ [key: string]: never; }>;


export type TotalActiveReturnQueueQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalActiveReturnQueue'>
);

export type AuctionEntryFragment = (
  { __typename?: 'Auction' }
  & Pick<Auction, 'id' | 'bwId' | 'invId' | 'number' | 'locationName' | 'locationId' | 'name' | 'descriptionShort' | 'descriptionFull' | 'tagline' | 'premium' | 'tax' | 'timezone' | 'startAt' | 'endAt' | 'pickupStartAt' | 'pickupEndAt' | 'isPushed' | 'isPushing' | 'isDeleted' | 'isPulled' | 'isPulling' | 'lastPullAt' | 'lastPullError' | 'lastPushAt' | 'lastPushError' | 'isProcessingInvoicePayments' | 'processInvoicePaymentsExecCount' | 'isEmailingInvoices' | 'emailInvoicesExecCount' | 'countTotalInvoices' | 'countPaidInvoices' | 'countUnpaidInvoices' | 'countUncompletedInvoices' | 'countCompletedInvoices' | 'percentPaidInvoices' | 'percentCompletedInvoices' | 'percentSoldItems' | 'countSoldItems' | 'countTotalItems' | 'countQueuedItemsForPush' | 'lpudNumDays' | 'extType' | 'merId'>
  & { locationByLocationId?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name' | 'fullAddress' | 'shortDescription' | 'fullDescription'>
  )>, images: (
    { __typename?: 'ItemImagesConnection' }
    & { nodes: Array<(
      { __typename?: 'ItemImage' }
      & ItemImageEntryFragment
    )> }
  ) }
);

export type GetAuctionByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetAuctionByIdQuery = (
  { __typename?: 'Query' }
  & { auctionById?: Maybe<(
    { __typename?: 'Auction' }
    & AuctionEntryFragment
  )> }
);

export type AllAuctionsEntryFragment = (
  { __typename?: 'Auction' }
  & Pick<Auction, 'percentSoldItems' | 'countSoldItems' | 'countTotalItems' | 'countTotalInvoices' | 'countPaidInvoices' | 'countUnpaidInvoices' | 'countUncompletedInvoices' | 'countCompletedInvoices' | 'percentPaidInvoices' | 'percentCompletedInvoices'>
  & AuctionEntryFragment
);

export type AllAuctionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionsOrderBy> | AuctionsOrderBy>;
  filter?: Maybe<AuctionFilter>;
}>;


export type AllAuctionsQuery = (
  { __typename?: 'Query' }
  & { allAuctions?: Maybe<(
    { __typename?: 'AuctionsConnection' }
    & Pick<AuctionsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Auction' }
      & AllAuctionsEntryFragment
    )> }
  )> }
);

export type CommitAuctionMutationVariables = Exact<{
  input: CommitAuctionInput;
}>;


export type CommitAuctionMutation = (
  { __typename?: 'Mutation' }
  & { commitAuction?: Maybe<(
    { __typename?: 'CommitAuctionPayload' }
    & Pick<CommitAuctionPayload, 'willCommit'>
  )> }
);

export type ProcessAuctionInvoicePaymentsMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ProcessAuctionInvoicePaymentsMutation = (
  { __typename?: 'Mutation' }
  & { processAuctionInvoicePayments?: Maybe<(
    { __typename?: 'ProcessAuctionInvoicePaymentsPayload' }
    & Pick<ProcessAuctionInvoicePaymentsPayload, 'clientMutationId'>
  )> }
);

export type RemovePrimaryImageMutationVariables = Exact<{
  image: Scalars['String'];
}>;


export type RemovePrimaryImageMutation = (
  { __typename?: 'Mutation' }
  & { removePrimaryImageItem?: Maybe<(
    { __typename?: 'RemovePrimaryImageItemPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'primaryImages'>
    )> }
  )> }
);

export type UpdateAuctionMutationVariables = Exact<{
  id: Scalars['Int'];
  values: AuctionPatch;
}>;


export type UpdateAuctionMutation = (
  { __typename?: 'Mutation' }
  & { updateAuctionById?: Maybe<(
    { __typename?: 'UpdateAuctionPayload' }
    & { auction?: Maybe<(
      { __typename?: 'Auction' }
      & AuctionEntryFragment
    )> }
  )> }
);

export type EmailAuctionInvoicesMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EmailAuctionInvoicesMutation = (
  { __typename?: 'Mutation' }
  & { emailAuctionInvoices?: Maybe<(
    { __typename?: 'EmailAuctionInvoicesPayload' }
    & Pick<EmailAuctionInvoicesPayload, 'clientMutationId'>
  )> }
);

export type TotalAuctionUnpaidInvoicesQueryVariables = Exact<{
  auctionId: Scalars['Int'];
}>;


export type TotalAuctionUnpaidInvoicesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'totalAuctionUnpaidInvoices'>
);

export type AuctionItemImageEntryFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'id' | 'number' | 'description'>
);

export type AuctionItemImagesQueryVariables = Exact<{
  auctionId: Scalars['Int'];
}>;


export type AuctionItemImagesQuery = (
  { __typename?: 'Query' }
  & { allItems?: Maybe<(
    { __typename?: 'ItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'Item' }
      & AuctionItemImageEntryFragment
    )> }
  )>, auctionById?: Maybe<(
    { __typename?: 'Auction' }
    & AuctionEntryFragment
  )> }
);

export type AuctionPendingChangesEntryFragment = (
  { __typename?: 'AuctionChangesView' }
  & Pick<AuctionChangesView, 'id' | 'name' | 'countQueuedItemsForPush' | 'countProcessingItemsForPush'>
);

export type AuctionsPendingChangesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionChangesViewsOrderBy> | AuctionChangesViewsOrderBy>;
  filter?: Maybe<AuctionChangesViewFilter>;
}>;


export type AuctionsPendingChangesQuery = (
  { __typename?: 'Query' }
  & { allAuctionChangesViews?: Maybe<(
    { __typename?: 'AuctionChangesViewsConnection' }
    & Pick<AuctionChangesViewsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'AuctionChangesView' }
      & AuctionPendingChangesEntryFragment
    )> }
  )> }
);

export type DeleteAuctionPushByAuctionIdMutationVariables = Exact<{
  input: DeleteAuctionPushByAuctionIdInput;
}>;


export type DeleteAuctionPushByAuctionIdMutation = (
  { __typename?: 'Mutation' }
  & { deleteAuctionPushByAuctionId?: Maybe<(
    { __typename?: 'DeleteAuctionPushByAuctionIdPayload' }
    & Pick<DeleteAuctionPushByAuctionIdPayload, 'clientMutationId'>
  )> }
);

export type GetLastPickupDateConfigForAuctionQueryVariables = Exact<{
  timestamp: Scalars['Datetime'];
  locationId: Scalars['Int'];
}>;


export type GetLastPickupDateConfigForAuctionQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getLastPickupDateConfigForAuction'>
);

export type GetAuthQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAuthQuery = (
  { __typename?: 'Query' }
  & { auth?: Maybe<(
    { __typename?: 'Auth' }
    & Pick<Auth, 'isInitialized' | 'refreshToken' | 'accessToken'>
  )> }
);

export type CredentialsFragment = (
  { __typename?: 'Credentials' }
  & Pick<Credentials, 'accessToken' | 'refreshToken'>
);

export type LoginByUsernameAndPasswordMutationVariables = Exact<{
  username: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginByUsernameAndPasswordMutation = (
  { __typename?: 'Mutation' }
  & { loginByUsernameAndPassword?: Maybe<(
    { __typename?: 'LoginByUsernameAndPasswordPayload' }
    & { credentials: (
      { __typename?: 'Credentials' }
      & CredentialsFragment
    ) }
  )> }
);

export type LoginByRefreshTokenMutationVariables = Exact<{
  refreshToken: Scalars['String'];
}>;


export type LoginByRefreshTokenMutation = (
  { __typename?: 'Mutation' }
  & { loginByRefreshToken?: Maybe<(
    { __typename?: 'LoginByRefreshTokenPayload' }
    & { credentials: (
      { __typename?: 'Credentials' }
      & CredentialsFragment
    ) }
  )> }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout?: Maybe<(
    { __typename?: 'LogoutPayload' }
    & Pick<LogoutPayload, 'success'>
  )> }
);

export type BidderEntryFragment = (
  { __typename?: 'Bidder' }
  & Pick<Bidder, 'id' | 'bwId' | 'name' | 'firstName' | 'lastName' | 'email' | 'mobileNumber' | 'phoneNumber' | 'location' | 'creditBalance' | 'processInvoicePayment' | 'processInvoicePaymentReason' | 'processInvoicePaymentChangedAt' | 'isTaxExempt' | 'paymentId' | 'merId'>
  & { userByProcessInvoicePaymentChangedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )> }
);

export type AllBiddersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BiddersOrderBy> | BiddersOrderBy>;
  filter?: Maybe<BidderFilter>;
}>;


export type AllBiddersQuery = (
  { __typename?: 'Query' }
  & { allBidders?: Maybe<(
    { __typename?: 'BiddersConnection' }
    & Pick<BiddersConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Bidder' }
      & BidderEntryFragment
    )> }
  )> }
);

export type GetBidderByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetBidderByIdQuery = (
  { __typename?: 'Query' }
  & { bidderById?: Maybe<(
    { __typename?: 'Bidder' }
    & { customerNotesByCustomerId: (
      { __typename?: 'CustomerNotesConnection' }
      & { nodes: Array<(
        { __typename?: 'CustomerNote' }
        & CustomerNoteEntryFragment
      )> }
    ) }
    & BidderEntryFragment
  )> }
);

export type UpdateBidderMutationVariables = Exact<{
  id: Scalars['Int'];
  values: BidderPatch;
}>;


export type UpdateBidderMutation = (
  { __typename?: 'Mutation' }
  & { updateBidderById?: Maybe<(
    { __typename?: 'UpdateBidderPayload' }
    & Pick<UpdateBidderPayload, 'clientMutationId'>
  )> }
);

export type SetCollectPaymentBiddersMutationVariables = Exact<{
  bidderId: Scalars['Int'];
  processInvoicePayment: Scalars['Boolean'];
  reason: Scalars['String'];
}>;


export type SetCollectPaymentBiddersMutation = (
  { __typename?: 'Mutation' }
  & { setProcessInvoicePayment?: Maybe<(
    { __typename?: 'SetProcessInvoicePaymentPayload' }
    & { bidder?: Maybe<(
      { __typename?: 'Bidder' }
      & BidderEntryFragment
    )> }
  )> }
);

export type CustomerNoteEntryFragment = (
  { __typename?: 'CustomerNote' }
  & Pick<CustomerNote, 'id' | 'note' | 'createdBy' | 'createdAt' | 'type' | 'expiresAt'>
  & { userByCreatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )> }
);

export type CreateCustomerNoteMutationVariables = Exact<{
  input: CreateCustomerNoteInput;
}>;


export type CreateCustomerNoteMutation = (
  { __typename?: 'Mutation' }
  & { createCustomerNote?: Maybe<(
    { __typename?: 'CreateCustomerNotePayload' }
    & { customerNote?: Maybe<(
      { __typename?: 'CustomerNote' }
      & CustomerNoteEntryFragment
    )> }
  )> }
);

export type DeleteCustomerNoteMutationVariables = Exact<{
  input: DeleteCustomerNoteByIdInput;
}>;


export type DeleteCustomerNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteCustomerNoteById?: Maybe<(
    { __typename?: 'DeleteCustomerNotePayload' }
    & Pick<DeleteCustomerNotePayload, 'clientMutationId'>
  )> }
);

export type GetCustomerNotesQueryVariables = Exact<{
  customerId: Scalars['Int'];
}>;


export type GetCustomerNotesQuery = (
  { __typename?: 'Query' }
  & { allCustomerNotes?: Maybe<(
    { __typename?: 'CustomerNotesConnection' }
    & { nodes: Array<(
      { __typename?: 'CustomerNote' }
      & CustomerNoteEntryFragment
    )> }
  )> }
);

export type CustomerActionViewFragment = (
  { __typename?: 'CustomerActionsView' }
  & Pick<CustomerActionsView, 'id' | 'note' | 'createdBy' | 'createdAt' | 'type' | 'expiresAt'>
  & { userByCreatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )> }
);

export type AllCustomerActionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerActionsViewsOrderBy> | CustomerActionsViewsOrderBy>;
  filter?: Maybe<CustomerActionsViewFilter>;
}>;


export type AllCustomerActionsQuery = (
  { __typename?: 'Query' }
  & { allCustomerActionsViews?: Maybe<(
    { __typename?: 'CustomerActionsViewsConnection' }
    & Pick<CustomerActionsViewsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'CustomerActionsView' }
      & CustomerActionViewFragment
    )> }
  )> }
);

export type CustomerAlertViewFragment = (
  { __typename?: 'CustomerAlertsView' }
  & Pick<CustomerAlertsView, 'id' | 'note' | 'createdBy' | 'createdAt' | 'type' | 'expiresAt'>
  & { userByCreatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )> }
);

export type AllCustomerAlertsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<CustomerAlertsViewsOrderBy> | CustomerAlertsViewsOrderBy>;
  filter?: Maybe<CustomerAlertsViewFilter>;
}>;


export type AllCustomerAlertsQuery = (
  { __typename?: 'Query' }
  & { allCustomerAlertsViews?: Maybe<(
    { __typename?: 'CustomerAlertsViewsConnection' }
    & Pick<CustomerAlertsViewsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'CustomerAlertsView' }
      & CustomerAlertViewFragment
    )> }
  )> }
);

export type ResyncCustomerFromBidwranglerMutationVariables = Exact<{
  input: ResyncCustomerFromBidwranglerInput;
}>;


export type ResyncCustomerFromBidwranglerMutation = (
  { __typename?: 'Mutation' }
  & { resyncCustomerFromBidwrangler?: Maybe<(
    { __typename?: 'ResyncCustomerFromBidwranglerPayload' }
    & Pick<ResyncCustomerFromBidwranglerPayload, 'clientMutationId'>
  )> }
);

export type BidderCreditHistoryEntryFragment = (
  { __typename?: 'BidderCreditHistory' }
  & Pick<BidderCreditHistory, 'id' | 'txType' | 'credit' | 'note' | 'createdAt'>
  & { auctionByAuctionId?: Maybe<(
    { __typename?: 'Auction' }
    & Pick<Auction, 'id' | 'name'>
  )>, invoiceByInvoiceId?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'uid'>
  )> }
);

export type BidderCreditHistoryQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<BidderCreditHistoriesOrderBy> | BidderCreditHistoriesOrderBy>;
  filter?: Maybe<BidderCreditHistoryFilter>;
}>;


export type BidderCreditHistoryQuery = (
  { __typename?: 'Query' }
  & { allBidderCreditHistories?: Maybe<(
    { __typename?: 'BidderCreditHistoriesConnection' }
    & Pick<BidderCreditHistoriesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'BidderCreditHistory' }
      & BidderCreditHistoryEntryFragment
    )> }
  )> }
);

export type AdjustBidderCreditsMutationVariables = Exact<{
  values: AdjustBidderCreditsInput;
}>;


export type AdjustBidderCreditsMutation = (
  { __typename?: 'Mutation' }
  & { adjustBidderCredits?: Maybe<(
    { __typename?: 'AdjustBidderCreditsPayload' }
    & { bidderByBidderId?: Maybe<(
      { __typename?: 'Bidder' }
      & Pick<Bidder, 'creditBalance'>
    )>, bidderCreditHistory?: Maybe<(
      { __typename?: 'BidderCreditHistory' }
      & BidderCreditHistoryEntryFragment
    )> }
  )> }
);

export type EmployeeStatFragmentFragment = (
  { __typename?: 'EmployeeStat' }
  & Pick<EmployeeStat, 'employeeId' | 'employeeName' | 'locationId' | 'locationName' | 'dailyTotalItems' | 'dailyPicklistItems' | 'dailyPickupItems' | 'last60TotalItems' | 'last60PicklistItems' | 'last60PickupItems'>
);

export type EmployeeStatsQueryVariables = Exact<{
  locationId: Scalars['Int'];
}>;


export type EmployeeStatsQuery = (
  { __typename?: 'Query' }
  & { allEmployeeStats?: Maybe<(
    { __typename?: 'EmployeeStatsConnection' }
    & { nodes: Array<(
      { __typename?: 'EmployeeStat' }
      & EmployeeStatFragmentFragment
    )> }
  )> }
);

export type InvoiceEntryFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'uid' | 'processPayment' | 'processPaymentReason' | 'processPaymentChangedAt' | 'bidderCreditsUsed' | 'paymentCreditsUsed' | 'alternateCreditsUsed' | 'isProcessingPayment' | 'isInitialPaymentProcessed' | 'canProceedPayment' | 'lastPickupDate' | 'isPaid' | 'merId'>
  & { userByProcessPaymentChangedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )> }
);

export type AllInvoiceEntryFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'uid' | 'total' | 'amountDue' | 'itemsCountAll' | 'itemsCountActive' | 'isPaid' | 'isCompleted' | 'lastPickupDate' | 'countTotalReturns'>
  & { auctionByAuctionId?: Maybe<(
    { __typename?: 'Auction' }
    & Pick<Auction, 'id' | 'name'>
  )>, bidderByBidderId?: Maybe<(
    { __typename?: 'Bidder' }
    & Pick<Bidder, 'id' | 'name' | 'email'>
    & { customerNotesByCustomerId: (
      { __typename?: 'CustomerNotesConnection' }
      & Pick<CustomerNotesConnection, 'totalCount'>
    ) }
  )> }
);

export type InvoiceAdjustmentEntryFragment = (
  { __typename?: 'InvoiceAdjustment' }
  & Pick<InvoiceAdjustment, 'id' | 'description' | 'type' | 'amount' | 'isDeleted' | 'createdAt'>
);

export type GetInvoiceByIdEntryFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'itemsSubtotal' | 'feesSubtotal' | 'total' | 'amountDue' | 'publicToken' | 'elevatedToken'>
  & { auctionByAuctionId?: Maybe<(
    { __typename?: 'Auction' }
    & Pick<Auction, 'id' | 'name' | 'pickupEndAt'>
  )>, bidderByBidderId?: Maybe<(
    { __typename?: 'Bidder' }
    & Pick<Bidder, 'id' | 'name' | 'activeAllNotesCount'>
  )>, invoiceAdjustmentsByInvoiceId: (
    { __typename?: 'InvoiceAdjustmentsConnection' }
    & { nodes: Array<(
      { __typename?: 'InvoiceAdjustment' }
      & InvoiceAdjustmentEntryFragment
    )> }
  ) }
  & InvoiceEntryFragment
);

export type GetInvoiceByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInvoiceByIdQuery = (
  { __typename?: 'Query' }
  & { invoiceById?: Maybe<(
    { __typename?: 'Invoice' }
    & GetInvoiceByIdEntryFragment
  )> }
);

export type AllInvoicesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<InvoicesOrderBy> | InvoicesOrderBy>;
  filter?: Maybe<InvoiceFilter>;
}>;


export type AllInvoicesQuery = (
  { __typename?: 'Query' }
  & { allInvoices?: Maybe<(
    { __typename?: 'InvoicesConnection' }
    & Pick<InvoicesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Invoice' }
      & AllInvoiceEntryFragment
    )> }
  )> }
);

export type ProcessInvoicePaymentMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
  processPayment: Scalars['Boolean'];
  reason: Scalars['String'];
}>;


export type ProcessInvoicePaymentMutation = (
  { __typename?: 'Mutation' }
  & { setProcessPayment?: Maybe<(
    { __typename?: 'SetProcessPaymentPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & InvoiceEntryFragment
    )> }
  )> }
);

export type AllAuctionInvoicesEntryFragment = (
  { __typename?: 'AuctionInvoicesView' }
  & Pick<AuctionInvoicesView, 'invoiceId' | 'invoiceUid' | 'amountDue' | 'total' | 'itemCount' | 'auctionName' | 'customerId' | 'customerName' | 'customerActiveActionNotesCount' | 'customerActiveAlertNotesCount'>
);

export type AllAuctionInvoicesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<AuctionInvoicesViewsOrderBy> | AuctionInvoicesViewsOrderBy>;
  filter?: Maybe<AuctionInvoicesViewFilter>;
}>;


export type AllAuctionInvoicesQuery = (
  { __typename?: 'Query' }
  & { allAuctionInvoicesViews?: Maybe<(
    { __typename?: 'AuctionInvoicesViewsConnection' }
    & Pick<AuctionInvoicesViewsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'AuctionInvoicesView' }
      & AllAuctionInvoicesEntryFragment
    )> }
  )> }
);

export type EmailInvoiceMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type EmailInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { emailInvoice?: Maybe<(
    { __typename?: 'EmailInvoicePayload' }
    & Pick<EmailInvoicePayload, 'clientMutationId'>
  )> }
);

export type SetInvoiceLastPickupDateMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
  lastPickupDate: Scalars['Date'];
}>;


export type SetInvoiceLastPickupDateMutation = (
  { __typename?: 'Mutation' }
  & { setInvoiceLastPickupDate?: Maybe<(
    { __typename?: 'SetInvoiceLastPickupDatePayload' }
    & Pick<SetInvoiceLastPickupDatePayload, 'clientMutationId'>
  )> }
);

export type GetEligibleBatchProcessInvoicePaymentsEntryFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'amountDue' | 'uid'>
  & { bidderByBidderId?: Maybe<(
    { __typename?: 'Bidder' }
    & Pick<Bidder, 'name'>
  )> }
);

export type GetEligibleBatchProcessInvoicePaymentsQueryVariables = Exact<{
  invoiceIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetEligibleBatchProcessInvoicePaymentsQuery = (
  { __typename?: 'Query' }
  & { getEligibleBatchProcessInvoicePayments?: Maybe<(
    { __typename?: 'InvoicesConnection' }
    & { nodes: Array<(
      { __typename?: 'Invoice' }
      & GetEligibleBatchProcessInvoicePaymentsEntryFragment
    )> }
  )> }
);

export type BatchProcessInvoicePaymentsMutationVariables = Exact<{
  invoiceIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type BatchProcessInvoicePaymentsMutation = (
  { __typename?: 'Mutation' }
  & { batchProcessInvoicePayments?: Maybe<(
    { __typename?: 'BatchProcessInvoicePaymentsPayload' }
    & Pick<BatchProcessInvoicePaymentsPayload, 'clientMutationId'>
  )> }
);

export type GetEligibleBatchEmailInvoicesEntryFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'id' | 'amountDue' | 'uid'>
  & { bidderByBidderId?: Maybe<(
    { __typename?: 'Bidder' }
    & Pick<Bidder, 'name'>
  )> }
);

export type GetEligibleBatchEmailInvoicesQueryVariables = Exact<{
  invoiceIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type GetEligibleBatchEmailInvoicesQuery = (
  { __typename?: 'Query' }
  & { getEligibleBatchEmailInvoices?: Maybe<(
    { __typename?: 'InvoicesConnection' }
    & { nodes: Array<(
      { __typename?: 'Invoice' }
      & GetEligibleBatchEmailInvoicesEntryFragment
    )> }
  )> }
);

export type BatchEmailInvoicesMutationVariables = Exact<{
  invoiceIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
}>;


export type BatchEmailInvoicesMutation = (
  { __typename?: 'Mutation' }
  & { batchEmailInvoices?: Maybe<(
    { __typename?: 'BatchEmailInvoicesPayload' }
    & Pick<BatchEmailInvoicesPayload, 'clientMutationId'>
  )> }
);

export type MarkInvoicePaidMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
  note: Scalars['String'];
}>;


export type MarkInvoicePaidMutation = (
  { __typename?: 'Mutation' }
  & { markInvoiceAsPaid?: Maybe<(
    { __typename?: 'MarkInvoiceAsPaidPayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & GetInvoiceByIdEntryFragment
    )> }
  )> }
);

export type SetInvoicesLastPickupDateMutationVariables = Exact<{
  invoiceIds: Array<Maybe<Scalars['Int']>> | Maybe<Scalars['Int']>;
  lastPickupDate: Scalars['Date'];
}>;


export type SetInvoicesLastPickupDateMutation = (
  { __typename?: 'Mutation' }
  & { setInvoicesLastPickupDate?: Maybe<(
    { __typename?: 'SetInvoicesLastPickupDatePayload' }
    & { setInvoicesLastPickupDateResultTypes?: Maybe<Array<(
      { __typename?: 'SetInvoicesLastPickupDateResultType' }
      & Pick<SetInvoicesLastPickupDateResultType, 'id' | 'lastPickupDate'>
    )>> }
  )> }
);

export type GetInvoiceCustomerDataForApplyCreditsToInvoiceQueryVariables = Exact<{
  invoiceId: Scalars['Int'];
}>;


export type GetInvoiceCustomerDataForApplyCreditsToInvoiceQuery = (
  { __typename?: 'Query' }
  & { invoiceById?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'amountDue'>
    & { bidderByBidderId?: Maybe<(
      { __typename?: 'Bidder' }
      & Pick<Bidder, 'creditBalance'>
    )> }
  )> }
);

export type ApplyCreditToInvoiceMutationVariables = Exact<{
  input: ApplyCreditToInvoiceInput;
}>;


export type ApplyCreditToInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { applyCreditToInvoice?: Maybe<(
    { __typename?: 'ApplyCreditToInvoicePayload' }
    & { invoice?: Maybe<(
      { __typename?: 'Invoice' }
      & GetInvoiceByIdEntryFragment
    )> }
  )> }
);

export type CreateInvoiceAdjustmentMutationVariables = Exact<{
  values: InvoiceAdjustmentInput;
}>;


export type CreateInvoiceAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & { createInvoiceAdjustment?: Maybe<(
    { __typename?: 'CreateInvoiceAdjustmentPayload' }
    & { invoiceAdjustment?: Maybe<(
      { __typename?: 'InvoiceAdjustment' }
      & InvoiceAdjustmentEntryFragment
    )> }
  )> }
);

export type UpdateInvoiceAdjustmentMutationVariables = Exact<{
  id: Scalars['Int'];
  values: InvoiceAdjustmentPatch;
}>;


export type UpdateInvoiceAdjustmentMutation = (
  { __typename?: 'Mutation' }
  & { updateInvoiceAdjustmentById?: Maybe<(
    { __typename?: 'UpdateInvoiceAdjustmentPayload' }
    & { invoiceAdjustment?: Maybe<(
      { __typename?: 'InvoiceAdjustment' }
      & InvoiceAdjustmentEntryFragment
    )> }
  )> }
);

export type InvoiceNoteEntryFragment = (
  { __typename?: 'InvoiceNote' }
  & Pick<InvoiceNote, 'id' | 'invoiceId' | 'note' | 'createdBy' | 'createdAt'>
  & { userByCreatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )> }
);

export type GetInvoiceNotesByInvoiceIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetInvoiceNotesByInvoiceIdQuery = (
  { __typename?: 'Query' }
  & { allInvoiceNotes?: Maybe<(
    { __typename?: 'InvoiceNotesConnection' }
    & { nodes: Array<(
      { __typename?: 'InvoiceNote' }
      & InvoiceNoteEntryFragment
    )> }
  )> }
);

export type CreateInvoiceNoteMutationVariables = Exact<{
  values: InvoiceNoteInput;
}>;


export type CreateInvoiceNoteMutation = (
  { __typename?: 'Mutation' }
  & { createInvoiceNote?: Maybe<(
    { __typename?: 'CreateInvoiceNotePayload' }
    & Pick<CreateInvoiceNotePayload, 'clientMutationId'>
  )> }
);

export type DeleteInvoiceNoteMutationVariables = Exact<{
  invoiceNoteId: Scalars['Int'];
}>;


export type DeleteInvoiceNoteMutation = (
  { __typename?: 'Mutation' }
  & { deleteInvoiceNoteById?: Maybe<(
    { __typename?: 'DeleteInvoiceNotePayload' }
    & Pick<DeleteInvoiceNotePayload, 'clientMutationId' | 'deletedInvoiceNoteId'>
  )> }
);

export type SimpleItemRackFragment = (
  { __typename?: 'ItemRackLocation' }
  & Pick<ItemRackLocation, 'rackLocation' | 'bin'>
);

export type ItemEntryFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'id' | 'nodeId' | 'bwId' | 'number' | 'invId' | 'auctionId' | 'invoiceId' | 'truckId' | 'invDateCreated' | 'invDateScanned' | 'invCreatedByUserName' | 'description' | 'bidAmount' | 'premiumAmount' | 'taxAmount' | 'totalAmount' | 'retailPrice' | 'condition' | 'category' | 'olasku' | 'createdAt' | 'updatedAt' | 'bwSoldState' | 'bwWonBidderId' | 'bwWonAmount' | 'images' | 'primaryImages' | 'primaryImagesCount' | 'isTaxExempt' | 'overallPickupStatus' | 'paidStatus' | 'setAsideNote' | 'setAsideAt' | 'externalHyperlink' | 'merId'>
  & { userBySetAsideBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )>, itemRackLocationByItemId?: Maybe<(
    { __typename?: 'ItemRackLocation' }
    & Pick<ItemRackLocation, 'rackLocation' | 'bin' | 'updatedAt' | 'updatedBy'>
    & { userByUpdatedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'name'>
    )> }
  )> }
);

export type AllItemsEntryFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'id' | 'number' | 'description' | 'bidAmount' | 'totalAmount' | 'overallPickupStatus'>
  & { auctionByAuctionId?: Maybe<(
    { __typename?: 'Auction' }
    & Pick<Auction, 'name' | 'id'>
  )>, itemRackLocationByItemId?: Maybe<(
    { __typename?: 'ItemRackLocation' }
    & SimpleItemRackFragment
  )> }
);

export type ItemImageEntryFragment = (
  { __typename?: 'ItemImage' }
  & Pick<ItemImage, 'id' | 'sort' | 'isPrimary' | 'publicUrl'>
);

export type GetItemQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetItemQuery = (
  { __typename?: 'Query' }
  & { itemById?: Maybe<(
    { __typename?: 'Item' }
    & { itemImagesByItemId: (
      { __typename?: 'ItemImagesConnection' }
      & { nodes: Array<(
        { __typename?: 'ItemImage' }
        & ItemImageEntryFragment
      )> }
    ), auctionByAuctionId?: Maybe<(
      { __typename?: 'Auction' }
      & Pick<Auction, 'name' | 'premium' | 'tax'>
    )>, invoiceByInvoiceId?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'uid' | 'isInitialPaymentProcessed'>
      & { bidderByBidderId?: Maybe<(
        { __typename?: 'Bidder' }
        & Pick<Bidder, 'id' | 'name'>
      )> }
    )> }
    & ItemEntryFragment
  )> }
);

export type AllInvoiceItemsEntryFragment = (
  { __typename?: 'Item' }
  & { invoiceItemReturnsByInvoiceItemId: (
    { __typename?: 'InvoiceItemReturnsConnection' }
    & Pick<InvoiceItemReturnsConnection, 'totalCount'>
  ) }
  & ItemEntryFragment
);

export type AllItemsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy> | ItemsOrderBy>;
  filter?: Maybe<ItemFilter>;
}>;


export type AllItemsQuery = (
  { __typename?: 'Query' }
  & { allItems?: Maybe<(
    { __typename?: 'ItemsConnection' }
    & Pick<ItemsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Item' }
      & AllItemsEntryFragment
    )> }
  )> }
);

export type AllAuctionItemEntryFragment = (
  { __typename?: 'Item' }
  & Pick<Item, 'id' | 'number' | 'description' | 'retailPrice' | 'condition' | 'category' | 'isTaxExempt' | 'isDeleted' | 'isPushed' | 'primaryImages' | 'primaryImagesCount' | 'isProcessingPushChange' | 'isQueuedPushChange'>
);

export type AllAuctionItemsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemsOrderBy> | ItemsOrderBy>;
  filter?: Maybe<ItemFilter>;
}>;


export type AllAuctionItemsQuery = (
  { __typename?: 'Query' }
  & { allItems?: Maybe<(
    { __typename?: 'ItemsConnection' }
    & Pick<ItemsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Item' }
      & AllAuctionItemEntryFragment
    )> }
  )> }
);

export type UpdateItemByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  values: ItemPatch;
}>;


export type UpdateItemByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateItemById?: Maybe<(
    { __typename?: 'UpdateItemPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & AllAuctionItemEntryFragment
    )> }
  )> }
);

export type AllInvoiceItemsQueryVariables = Exact<{
  filter?: Maybe<ItemFilter>;
}>;


export type AllInvoiceItemsQuery = (
  { __typename?: 'Query' }
  & { allItems?: Maybe<(
    { __typename?: 'ItemsConnection' }
    & Pick<ItemsConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'Item' }
      & AllInvoiceItemsEntryFragment
    )> }
  )> }
);

export type UpdateInvoiceItemByIdMutationVariables = Exact<{
  id: Scalars['Int'];
  values: ItemPatch;
}>;


export type UpdateInvoiceItemByIdMutation = (
  { __typename?: 'Mutation' }
  & { updateItemById?: Maybe<(
    { __typename?: 'UpdateItemPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & ItemEntryFragment
    )> }
  )> }
);

export type ChargeInvoicePaymentMutationVariables = Exact<{
  invoiceId: Scalars['Int'];
}>;


export type ChargeInvoicePaymentMutation = (
  { __typename?: 'Mutation' }
  & { chargeInvoicePayment?: Maybe<(
    { __typename?: 'ChargeInvoicePaymentPayload' }
    & Pick<ChargeInvoicePaymentPayload, 'clientMutationId'>
  )> }
);

export type SearchAuctionItemsQueryVariables = Exact<{
  value: Scalars['String'];
  auctionId: Scalars['Int'];
}>;


export type SearchAuctionItemsQuery = (
  { __typename?: 'Query' }
  & { allItems?: Maybe<(
    { __typename?: 'ItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'description' | 'number'>
    )> }
  )> }
);

export type GetItemsQueryVariables = Exact<{
  value: Scalars['String'];
}>;


export type GetItemsQuery = (
  { __typename?: 'Query' }
  & { allItems?: Maybe<(
    { __typename?: 'ItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'description' | 'number'>
    )> }
  )> }
);

export type AddImageItemMutationVariables = Exact<{
  itemId: Scalars['Int'];
  image: Scalars['String'];
}>;


export type AddImageItemMutation = (
  { __typename?: 'Mutation' }
  & { addImageItem?: Maybe<(
    { __typename?: 'AddImageItemPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'images'>
    )> }
  )> }
);

export type UpdateItemMutationVariables = Exact<{
  id: Scalars['Int'];
  values: ItemPatch;
}>;


export type UpdateItemMutation = (
  { __typename?: 'Mutation' }
  & { updateItemById?: Maybe<(
    { __typename?: 'UpdateItemPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'images' | 'primaryImages'>
    )> }
  )> }
);

export type UploadItemImageMutationVariables = Exact<{
  itemId: Scalars['Int'];
  image: Scalars['Upload'];
}>;


export type UploadItemImageMutation = (
  { __typename?: 'Mutation' }
  & { uploadItemsImage?: Maybe<(
    { __typename?: 'UploadItemsImagePayload' }
    & { itemImage?: Maybe<(
      { __typename?: 'ItemImage' }
      & ItemImageEntryFragment
    )> }
  )> }
);

export type ResortItemImagesMutationVariables = Exact<{
  itemId: Scalars['Int'];
  images: Array<Maybe<ResortItemImagesTypeInput>> | Maybe<ResortItemImagesTypeInput>;
}>;


export type ResortItemImagesMutation = (
  { __typename?: 'Mutation' }
  & { resortItemImages?: Maybe<(
    { __typename?: 'ResortItemImagesPayload' }
    & { resortResult?: Maybe<(
      { __typename?: 'ResortResult' }
      & Pick<ResortResult, 'success'>
    )> }
  )> }
);

export type SetPrimaryItemImageMutationVariables = Exact<{
  itemImageId: Scalars['Int'];
  isPrimary: Scalars['Boolean'];
}>;


export type SetPrimaryItemImageMutation = (
  { __typename?: 'Mutation' }
  & { updateItemImageById?: Maybe<(
    { __typename?: 'UpdateItemImagePayload' }
    & { itemImage?: Maybe<(
      { __typename?: 'ItemImage' }
      & ItemImageEntryFragment
    )> }
  )> }
);

export type DeleteItemImageMutationVariables = Exact<{
  itemImageId: Scalars['Int'];
}>;


export type DeleteItemImageMutation = (
  { __typename?: 'Mutation' }
  & { updateItemImageById?: Maybe<(
    { __typename?: 'UpdateItemImagePayload' }
    & { itemImage?: Maybe<(
      { __typename?: 'ItemImage' }
      & ItemImageEntryFragment
    )> }
  )> }
);

export type ReuploadItemImageMutationVariables = Exact<{
  itemImageId: Scalars['Int'];
  image: Scalars['Upload'];
}>;


export type ReuploadItemImageMutation = (
  { __typename?: 'Mutation' }
  & { reuploadItemsImage?: Maybe<(
    { __typename?: 'ReuploadItemsImagePayload' }
    & { itemImage?: Maybe<(
      { __typename?: 'ItemImage' }
      & ItemImageEntryFragment
    )> }
  )> }
);

export type AllItemImagesQueryVariables = Exact<{
  itemId: Scalars['Int'];
}>;


export type AllItemImagesQuery = (
  { __typename?: 'Query' }
  & { allItemImages?: Maybe<(
    { __typename?: 'ItemImagesConnection' }
    & { nodes: Array<(
      { __typename?: 'ItemImage' }
      & ItemImageEntryFragment
    )> }
  )> }
);

export type CopyItemImageMutationVariables = Exact<{
  itemImageId: Scalars['Int'];
  toItemId: Scalars['Int'];
}>;


export type CopyItemImageMutation = (
  { __typename?: 'Mutation' }
  & { copyItemsImage?: Maybe<(
    { __typename?: 'CopyItemsImagePayload' }
    & { itemImage?: Maybe<(
      { __typename?: 'ItemImage' }
      & ItemImageEntryFragment
    )> }
  )> }
);

export type GetItemReturnDetailsQueryVariables = Exact<{
  itemId: Scalars['Int'];
  returnQueueId?: Maybe<Scalars['UUID']>;
}>;


export type GetItemReturnDetailsQuery = (
  { __typename?: 'Query' }
  & { getItemReturnDetails?: Maybe<(
    { __typename?: 'GetItemReturnDetailsRecord' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & Pick<Item, 'id' | 'number' | 'paymentCreditsAvailableForRefund' | 'alternatePaymentCreditsAvailableForRefund' | 'hasPaymentTransactionSettled'>
    )>, returnQueue?: Maybe<(
      { __typename?: 'ReturnQueue' }
      & Pick<ReturnQueue, 'amount' | 'note' | 'sendEmail' | 'type'>
      & { returnOptionByOptionId?: Maybe<(
        { __typename?: 'ReturnOption' }
        & Pick<ReturnOption, 'name' | 'id' | 'requireNote'>
      )> }
    )> }
  )> }
);

export type DeleteItemFromInvoiceMutationVariables = Exact<{
  input: DeleteItemFromInvoiceInput;
}>;


export type DeleteItemFromInvoiceMutation = (
  { __typename?: 'Mutation' }
  & { deleteItemFromInvoice?: Maybe<(
    { __typename?: 'DeleteItemFromInvoicePayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & ItemEntryFragment
    )> }
  )> }
);

export type RelistItemMutationVariables = Exact<{
  input: RelistItemInput;
}>;


export type RelistItemMutation = (
  { __typename?: 'Mutation' }
  & { relistItem?: Maybe<(
    { __typename?: 'RelistItemPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & ItemEntryFragment
    )> }
  )> }
);

export type SetAsideItemMutationVariables = Exact<{
  input: SetAsideItemInput;
}>;


export type SetAsideItemMutation = (
  { __typename?: 'Mutation' }
  & { setAsideItem?: Maybe<(
    { __typename?: 'SetAsideItemPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & ItemEntryFragment
    )> }
  )> }
);

export type RemoveTaxFromItemMutationVariables = Exact<{
  input: RemoveItemTaxInput;
}>;


export type RemoveTaxFromItemMutation = (
  { __typename?: 'Mutation' }
  & { removeItemTax?: Maybe<(
    { __typename?: 'RemoveItemTaxPayload' }
    & { item?: Maybe<(
      { __typename?: 'Item' }
      & ItemEntryFragment
    )> }
  )> }
);

export type ItemHistoryEntryFragment = (
  { __typename?: 'GetItemHistoryRecord' }
  & Pick<GetItemHistoryRecord, 'id' | 'type' | 'oldValue' | 'newValue' | 'createdAt' | 'createdByUser'>
);

export type ItemHistoryQueryVariables = Exact<{
  itemId: Scalars['Int'];
}>;


export type ItemHistoryQuery = (
  { __typename?: 'Query' }
  & { getItemHistory?: Maybe<(
    { __typename?: 'GetItemHistoryConnection' }
    & { nodes: Array<(
      { __typename?: 'GetItemHistoryRecord' }
      & ItemHistoryEntryFragment
    )> }
  )> }
);

export type RackLocationListEntryFragment = (
  { __typename?: 'RackLocationListResult' }
  & Pick<RackLocationListResult, 'rackLocation' | 'itemCount'>
);

export type RackLocationListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<RackLocationListResultFilter>;
}>;


export type RackLocationListQuery = (
  { __typename?: 'Query' }
  & { rackLocationList?: Maybe<(
    { __typename?: 'RackLocationListResultsConnection' }
    & Pick<RackLocationListResultsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'RackLocationListResult' }
      & RackLocationListEntryFragment
    )> }
  )> }
);

export type ItemRackLocationEntryFragment = (
  { __typename?: 'ItemRackLocation' }
  & Pick<ItemRackLocation, 'updatedAt'>
  & { userByUpdatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )>, itemByItemId?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'number' | 'invoiceId'>
    & { auctionByAuctionId?: Maybe<(
      { __typename?: 'Auction' }
      & Pick<Auction, 'name'>
    )>, invoiceByInvoiceId?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'uid'>
    )>, bidderByBidderId?: Maybe<(
      { __typename?: 'Bidder' }
      & Pick<Bidder, 'name'>
    )> }
  )> }
);

export type ItemRackLocationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ItemRackLocationsOrderBy> | ItemRackLocationsOrderBy>;
  filter?: Maybe<ItemRackLocationFilter>;
}>;


export type ItemRackLocationsQuery = (
  { __typename?: 'Query' }
  & { allItemRackLocations?: Maybe<(
    { __typename?: 'ItemRackLocationsConnection' }
    & Pick<ItemRackLocationsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'ItemRackLocation' }
      & ItemRackLocationEntryFragment
    )> }
  )> }
);

export type MissingRackLocationListEntryFragment = (
  { __typename?: 'MissingRackLocationListResult' }
  & Pick<MissingRackLocationListResult, 'auction' | 'customer' | 'invoiceId' | 'invoiceUid' | 'itemId' | 'itemNumber' | 'location'>
);

export type MissingRackLocationListQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  filter?: Maybe<MissingRackLocationListResultFilter>;
}>;


export type MissingRackLocationListQuery = (
  { __typename?: 'Query' }
  & { missingRackLocationList?: Maybe<(
    { __typename?: 'MissingRackLocationListResultsConnection' }
    & Pick<MissingRackLocationListResultsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'MissingRackLocationListResult' }
      & MissingRackLocationListEntryFragment
    )> }
  )> }
);

export type InvoiceItemReturnEntryFragment = (
  { __typename?: 'InvoiceItemReturn' }
  & Pick<InvoiceItemReturn, 'id' | 'queueId' | 'creditAmount' | 'errorMessage' | 'invoiceItemId' | 'paymentAmount' | 'alternatePaymentAmount' | 'note' | 'status' | 'wasSuccessful' | 'createdAt' | 'updatedAt' | 'canDeleteCredit'>
  & { returnOptionByOptionId?: Maybe<(
    { __typename?: 'ReturnOption' }
    & Pick<ReturnOption, 'name'>
  )>, userByCreatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )>, invoiceItemReturnHistoriesByReturnId: (
    { __typename?: 'InvoiceItemReturnHistoriesConnection' }
    & { nodes: Array<(
      { __typename?: 'InvoiceItemReturnHistory' }
      & Pick<InvoiceItemReturnHistory, 'id' | 'reason' | 'action' | 'data' | 'ts'>
      & { userByBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name'>
      )> }
    )> }
  ) }
);

export type GetReturnsByItemIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetReturnsByItemIdQuery = (
  { __typename?: 'Query' }
  & { allInvoiceItemReturns?: Maybe<(
    { __typename?: 'InvoiceItemReturnsConnection' }
    & { nodes: Array<(
      { __typename?: 'InvoiceItemReturn' }
      & InvoiceItemReturnEntryFragment
    )> }
  )> }
);

export type ReturnItemMutationVariables = Exact<{
  input: IssueReturnForItemInput;
}>;


export type ReturnItemMutation = (
  { __typename?: 'Mutation' }
  & { issueReturnForItem?: Maybe<(
    { __typename?: 'IssueReturnForItemPayload' }
    & { invoiceItemReturn?: Maybe<(
      { __typename?: 'InvoiceItemReturn' }
      & InvoiceItemReturnEntryFragment
    )> }
  )> }
);

export type DeleteCreditFromReturnMutationVariables = Exact<{
  input: DeleteCreditFromReturnInput;
}>;


export type DeleteCreditFromReturnMutation = (
  { __typename?: 'Mutation' }
  & { deleteCreditFromReturn?: Maybe<(
    { __typename?: 'DeleteCreditFromReturnPayload' }
    & { invoiceItemReturn?: Maybe<(
      { __typename?: 'InvoiceItemReturn' }
      & InvoiceItemReturnEntryFragment
    )> }
  )> }
);

export type JobsByAuctionIdEntryFragment = (
  { __typename?: 'JobQueue' }
  & Pick<JobQueue, 'id' | 'failCount' | 'job' | 'isCompleted' | 'successCount' | 'jobCount' | 'createdAt' | 'updatedAt'>
);

export type JobsByAuctionIdQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<JobQueuesOrderBy> | JobQueuesOrderBy>;
  filter?: Maybe<JobQueueFilter>;
}>;


export type JobsByAuctionIdQuery = (
  { __typename?: 'Query' }
  & { allJobQueues?: Maybe<(
    { __typename?: 'JobQueuesConnection' }
    & Pick<JobQueuesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'JobQueue' }
      & JobsByAuctionIdEntryFragment
    )> }
  )> }
);

export type JobQueueItemEntryFragment = (
  { __typename?: 'JobQueueItem' }
  & Pick<JobQueueItem, 'id' | 'payload' | 'completedAt'>
);

export type JobQueueItemsQueryVariables = Exact<{
  jobQueueId: Scalars['Int'];
}>;


export type JobQueueItemsQuery = (
  { __typename?: 'Query' }
  & { allJobQueueItems?: Maybe<(
    { __typename?: 'JobQueueItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'JobQueueItem' }
      & JobQueueItemEntryFragment
    )> }
  )> }
);

export type LocationEntryFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'invoicePrefix' | 'name' | 'fullAddress' | 'addressMain' | 'addressSecondary' | 'city' | 'state' | 'zipCode' | 'tax' | 'premium' | 'timezone' | 'shortDescription' | 'fullDescription' | 'lpudMon' | 'lpudTue' | 'lpudWed' | 'lpudThu' | 'lpudFri' | 'lpudSat' | 'lpudSun'>
);

export type LocationListEntryFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'premium' | 'tax' | 'addressMain' | 'addressSecondary' | 'city' | 'timezone' | 'state' | 'zipCode' | 'isDeleted'>
);

export type GetLocationQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetLocationQuery = (
  { __typename?: 'Query' }
  & { locationById?: Maybe<(
    { __typename?: 'Location' }
    & LocationEntryFragment
  )> }
);

export type AllLocationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<LocationsOrderBy> | LocationsOrderBy>;
  filter?: Maybe<LocationFilter>;
}>;


export type AllLocationsQuery = (
  { __typename?: 'Query' }
  & { allLocations?: Maybe<(
    { __typename?: 'LocationsConnection' }
    & Pick<LocationsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Location' }
      & LocationListEntryFragment
    )> }
  )> }
);

export type GetLocationsEntryFragment = (
  { __typename?: 'Location' }
  & Pick<Location, 'id' | 'name' | 'fullAddress'>
);

export type GetLocationsQueryVariables = Exact<{
  value: Scalars['String'];
}>;


export type GetLocationsQuery = (
  { __typename?: 'Query' }
  & { allLocations?: Maybe<(
    { __typename?: 'LocationsConnection' }
    & { nodes: Array<(
      { __typename?: 'Location' }
      & GetLocationsEntryFragment
    )> }
  )> }
);

export type GetAllLocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllLocationsQuery = (
  { __typename?: 'Query' }
  & { allLocations?: Maybe<(
    { __typename?: 'LocationsConnection' }
    & { nodes: Array<(
      { __typename?: 'Location' }
      & GetLocationsEntryFragment
    )> }
  )> }
);

export type CreateLocationMutationVariables = Exact<{
  values: LocationInput;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation?: Maybe<(
    { __typename?: 'CreateLocationPayload' }
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & LocationEntryFragment
    )> }
  )> }
);

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['Int'];
  values: LocationPatch;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocationById?: Maybe<(
    { __typename?: 'UpdateLocationPayload' }
    & { location?: Maybe<(
      { __typename?: 'Location' }
      & LocationEntryFragment
    )> }
  )> }
);

export type PaymentEntryFragment = (
  { __typename?: 'Payment' }
  & Pick<Payment, 'id' | 'status' | 'chargeStatus' | 'chargeStatusErrorMessage' | 'processingVoidStatus' | 'isVoided' | 'isCharged' | 'amount' | 'createdAt' | 'paymentAmount' | 'creditAmount' | 'refundedPaymentAmount' | 'refundedCreditAmount' | 'cardNumber'>
  & { invoiceByInvoiceId?: Maybe<(
    { __typename?: 'Invoice' }
    & Pick<Invoice, 'id' | 'uid'>
    & { bidderByBidderId?: Maybe<(
      { __typename?: 'Bidder' }
      & Pick<Bidder, 'id' | 'name'>
    )> }
  )> }
);

export type AllPaymentsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PaymentsOrderBy> | PaymentsOrderBy>;
  filter?: Maybe<PaymentFilter>;
}>;


export type AllPaymentsQuery = (
  { __typename?: 'Query' }
  & { allPayments?: Maybe<(
    { __typename?: 'PaymentsConnection' }
    & Pick<PaymentsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Payment' }
      & PaymentEntryFragment
    )> }
  )> }
);

export type GetPaymentQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPaymentQuery = (
  { __typename?: 'Query' }
  & { paymentById?: Maybe<(
    { __typename?: 'Payment' }
    & PaymentEntryFragment
  )> }
);

export type GetPaymentsByInvoiceIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPaymentsByInvoiceIdQuery = (
  { __typename?: 'Query' }
  & { allPayments?: Maybe<(
    { __typename?: 'PaymentsConnection' }
    & { nodes: Array<(
      { __typename?: 'Payment' }
      & PaymentEntryFragment
    )> }
  )> }
);

export type VoidPaymentMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type VoidPaymentMutation = (
  { __typename?: 'Mutation' }
  & { voidPayment?: Maybe<(
    { __typename?: 'VoidPaymentPayload' }
    & Pick<VoidPaymentPayload, 'willProcess'>
  )> }
);

export type InvoiceForSendToPickListQueueEntryFragment = (
  { __typename?: 'Invoice' }
  & Pick<Invoice, 'uid'>
  & { customer?: Maybe<(
    { __typename?: 'Bidder' }
    & Pick<Bidder, 'name'>
  )>, items: (
    { __typename?: 'ItemsConnection' }
    & Pick<ItemsConnection, 'totalCount'>
  ) }
);

export type GetInvoicesForSendToPickListQueueQueryVariables = Exact<{
  invoiceIds: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type GetInvoicesForSendToPickListQueueQuery = (
  { __typename?: 'Query' }
  & { allInvoices?: Maybe<(
    { __typename?: 'InvoicesConnection' }
    & { nodes: Array<(
      { __typename?: 'Invoice' }
      & InvoiceForSendToPickListQueueEntryFragment
    )> }
  )> }
);

export type BatchSendToPickListQueueMutationVariables = Exact<{
  input: BatchSendToPicklistQueueInput;
}>;


export type BatchSendToPickListQueueMutation = (
  { __typename?: 'Mutation' }
  & { batchSendToPicklistQueue?: Maybe<(
    { __typename?: 'BatchSendToPicklistQueuePayload' }
    & Pick<BatchSendToPicklistQueuePayload, 'clientMutationId'>
  )> }
);

export type PicklistQueueItemEntryFragment = (
  { __typename?: 'PicklistQueueItem' }
  & Pick<PicklistQueueItem, 'customerId' | 'customerName' | 'assignedUserId' | 'assignedName' | 'inQueueAt' | 'scheduleTime'>
);

export type PicklistQueueByLocationQueryVariables = Exact<{
  locationId: Scalars['Int'];
}>;


export type PicklistQueueByLocationQuery = (
  { __typename?: 'Query' }
  & { picklistQueueCounts?: Maybe<(
    { __typename?: 'PicklistQueueCountsRecord' }
    & Pick<PicklistQueueCountsRecord, 'totalCount' | 'unassignedCount' | 'assignedCount'>
  )>, picklistUnassignedItems?: Maybe<(
    { __typename?: 'PicklistQueueItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'PicklistQueueItem' }
      & PicklistQueueItemEntryFragment
    )> }
  )>, picklistAssignedItems?: Maybe<(
    { __typename?: 'PicklistQueueItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'PicklistQueueItem' }
      & PicklistQueueItemEntryFragment
    )> }
  )> }
);

export type PicklistQueueEntryFragment = (
  { __typename?: 'PicklistQueue' }
  & Pick<PicklistQueue, 'id' | 'status' | 'createdAt'>
  & { userByAssignedUserId?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )>, locationByLocationId?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, itemByItemId?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'number' | 'description'>
    & { invoiceByInvoiceId?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'uid'>
      & { auctionByAuctionId?: Maybe<(
        { __typename?: 'Auction' }
        & Pick<Auction, 'id' | 'name'>
      )>, bidderByBidderId?: Maybe<(
        { __typename?: 'Bidder' }
        & Pick<Bidder, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type PicklistQueueItemQueryVariables = Exact<{
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
  isAssignedNull: Scalars['Boolean'];
  status: PicklistQueueStatusFilter;
}>;


export type PicklistQueueItemQuery = (
  { __typename?: 'Query' }
  & { allPicklistQueues?: Maybe<(
    { __typename?: 'PicklistQueuesConnection' }
    & { nodes: Array<(
      { __typename?: 'PicklistQueue' }
      & PicklistQueueEntryFragment
    )> }
  )> }
);

export type DeletePicklistQueueMutationVariables = Exact<{
  input: DeletePicklistQueueByIdInput;
}>;


export type DeletePicklistQueueMutation = (
  { __typename?: 'Mutation' }
  & { deletePicklistQueueById?: Maybe<(
    { __typename?: 'DeletePicklistQueuePayload' }
    & Pick<DeletePicklistQueuePayload, 'deletedPicklistQueueId'>
  )> }
);

export type UpdatePicklistQueueMutationVariables = Exact<{
  input: UpdatePicklistQueueByIdInput;
}>;


export type UpdatePicklistQueueMutation = (
  { __typename?: 'Mutation' }
  & { updatePicklistQueueById?: Maybe<(
    { __typename?: 'UpdatePicklistQueuePayload' }
    & { picklistQueue?: Maybe<(
      { __typename?: 'PicklistQueue' }
      & PicklistQueueEntryFragment
    )> }
  )> }
);

export type PickupQueueEntryFragment = (
  { __typename?: 'PickupQueue' }
  & Pick<PickupQueue, 'id' | 'elapsedSeconds' | 'status' | 'createdAt'>
  & { userByAssignedUserId?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )>, locationByLocationId?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, itemByItemId?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'number' | 'isPriorityPickup' | 'description'>
    & { invoiceByInvoiceId?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'uid'>
      & { auctionByAuctionId?: Maybe<(
        { __typename?: 'Auction' }
        & Pick<Auction, 'id' | 'name'>
      )>, bidderByBidderId?: Maybe<(
        { __typename?: 'Bidder' }
        & Pick<Bidder, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type PickupQueueItemEntryFragment = (
  { __typename?: 'PickupQueueItem' }
  & Pick<PickupQueueItem, 'assignedUserId' | 'customerId' | 'assignedName' | 'customerName' | 'isPriority' | 'inQueueAt' | 'skipCount' | 'pickupNumber'>
);

export type NotCompletedPickupQueueByLocationQueryVariables = Exact<{
  locationId: Scalars['Int'];
}>;


export type NotCompletedPickupQueueByLocationQuery = (
  { __typename?: 'Query' }
  & { pickupUnassignedItems?: Maybe<(
    { __typename?: 'PickupQueueItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'PickupQueueItem' }
      & PickupQueueItemEntryFragment
    )> }
  )>, pickupAssignedItems?: Maybe<(
    { __typename?: 'PickupQueueItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'PickupQueueItem' }
      & PickupQueueItemEntryFragment
    )> }
  )> }
);

export type PickupQueueItemQueryVariables = Exact<{
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
  isAssignedNull: Scalars['Boolean'];
  status: PickupQueueStatusFilter;
}>;


export type PickupQueueItemQuery = (
  { __typename?: 'Query' }
  & { allPickupQueues?: Maybe<(
    { __typename?: 'PickupQueuesConnection' }
    & { nodes: Array<(
      { __typename?: 'PickupQueue' }
      & PickupQueueEntryFragment
    )> }
  )> }
);

export type NotCompletedPickupQueueQueryVariables = Exact<{ [key: string]: never; }>;


export type NotCompletedPickupQueueQuery = (
  { __typename?: 'Query' }
  & { allPickupQueues?: Maybe<(
    { __typename?: 'PickupQueuesConnection' }
    & { nodes: Array<(
      { __typename?: 'PickupQueue' }
      & PickupQueueEntryFragment
    )> }
  )> }
);

export type ClearPickupQueueItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type ClearPickupQueueItemMutation = (
  { __typename?: 'Mutation' }
  & { clearPickupQueueItem?: Maybe<(
    { __typename?: 'ClearPickupQueueItemPayload' }
    & { pickupQueue?: Maybe<(
      { __typename?: 'PickupQueue' }
      & PickupQueueEntryFragment
    )> }
  )> }
);

export type DeletePickupQueueItemMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeletePickupQueueItemMutation = (
  { __typename?: 'Mutation' }
  & { deletePickupQueueItem?: Maybe<(
    { __typename?: 'DeletePickupQueueItemPayload' }
    & Pick<DeletePickupQueueItemPayload, 'clientMutationId'>
  )> }
);

export type LivePickupQueueEntryFragment = (
  { __typename?: 'LivePickupQueue' }
  & Pick<LivePickupQueue, 'customerId' | 'assignedUserId' | 'isPriority'>
  & { bidderByCustomerId?: Maybe<(
    { __typename?: 'Bidder' }
    & Pick<Bidder, 'name'>
  )>, userByAssignedUserId?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )> }
);

export type LivePickupQueueQueryVariables = Exact<{
  locationId: Scalars['Int'];
}>;


export type LivePickupQueueQuery = (
  { __typename?: 'Query' }
  & { allLivePickupQueues?: Maybe<(
    { __typename?: 'LivePickupQueuesConnection' }
    & { nodes: Array<(
      { __typename?: 'LivePickupQueue' }
      & LivePickupQueueEntryFragment
    )> }
  )> }
);

export type DownloadPaperlessAppMutationVariables = Exact<{
  input: DownloadPaperlessAppInput;
}>;


export type DownloadPaperlessAppMutation = (
  { __typename?: 'Mutation' }
  & { downloadPaperlessApp?: Maybe<(
    { __typename?: 'DownloadPaperlessAppPayload' }
    & Pick<DownloadPaperlessAppPayload, 'downloadUrl' | 'version'>
  )> }
);

export type AllPrintersEntryFragment = (
  { __typename?: 'Printer' }
  & Pick<Printer, 'id' | 'name' | 'ipAddress' | 'port'>
  & { locationByLocationId?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'name'>
  )> }
);

export type AllPrintersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<PrintersOrderBy> | PrintersOrderBy>;
  filter?: Maybe<PrinterFilter>;
}>;


export type AllPrintersQuery = (
  { __typename?: 'Query' }
  & { allPrinters?: Maybe<(
    { __typename?: 'PrintersConnection' }
    & Pick<PrintersConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Printer' }
      & AllPrintersEntryFragment
    )> }
  )> }
);

export type PrinterEntryFragment = (
  { __typename?: 'Printer' }
  & Pick<Printer, 'id' | 'name' | 'locationId' | 'ipAddress' | 'port' | 'createdAt' | 'updatedAt'>
  & { locationByLocationId?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )> }
);

export type GetPrinterQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetPrinterQuery = (
  { __typename?: 'Query' }
  & { printerById?: Maybe<(
    { __typename?: 'Printer' }
    & PrinterEntryFragment
  )> }
);

export type CreatePrinterMutationVariables = Exact<{
  values: PrinterInput;
}>;


export type CreatePrinterMutation = (
  { __typename?: 'Mutation' }
  & { createPrinter?: Maybe<(
    { __typename?: 'CreatePrinterPayload' }
    & { printer?: Maybe<(
      { __typename?: 'Printer' }
      & PrinterEntryFragment
    )> }
  )> }
);

export type UpdatePrinterMutationVariables = Exact<{
  id: Scalars['Int'];
  values: PrinterPatch;
}>;


export type UpdatePrinterMutation = (
  { __typename?: 'Mutation' }
  & { updatePrinterById?: Maybe<(
    { __typename?: 'UpdatePrinterPayload' }
    & { printer?: Maybe<(
      { __typename?: 'Printer' }
      & PrinterEntryFragment
    )> }
  )> }
);

export type RunTaxReportQueryVariables = Exact<{
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
}>;


export type RunTaxReportQuery = (
  { __typename?: 'Query' }
  & { runTaxReport?: Maybe<(
    { __typename?: 'RunTaxReportConnection' }
    & Pick<RunTaxReportConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'RunTaxReportRecord' }
      & Pick<RunTaxReportRecord, 'auctionSales' | 'creditCardRefunds' | 'locationName' | 'locationTax' | 'reportedGrossSales' | 'salesTaxDue' | 'taxExemptSales' | 'totalTaxableSales'>
    )> }
  )> }
);

export type RunAuctionSummaryReportQueryVariables = Exact<{
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
}>;


export type RunAuctionSummaryReportQuery = (
  { __typename?: 'Query' }
  & { runAuctionSummaryReport?: Maybe<(
    { __typename?: 'RunAuctionSummaryReportConnection' }
    & Pick<RunAuctionSummaryReportConnection, 'totalCount'>
    & { nodes: Array<(
      { __typename?: 'RunAuctionSummaryReportRecord' }
      & Pick<RunAuctionSummaryReportRecord, 'auctionId' | 'auctionName' | 'avgPricePerItem' | 'countItems' | 'totalRetailPrice' | 'totalSalePrice' | 'countItemsNotSold' | 'countItemsSold' | 'retailPercent'>
    )> }
  )> }
);

export type RunReturnsReportQueryVariables = Exact<{
  startDate: Scalars['Datetime'];
  endDate: Scalars['Datetime'];
  groupBy: ReportGroupBy;
}>;


export type RunReturnsReportQuery = (
  { __typename?: 'Query' }
  & { runItemSoldReport?: Maybe<(
    { __typename?: 'RunItemSoldReportConnection' }
    & { nodes: Array<(
      { __typename?: 'RunItemSoldReportRecord' }
      & Pick<RunItemSoldReportRecord, 'date' | 'count'>
    )> }
  )>, runCompanyReturnRateReport?: Maybe<(
    { __typename?: 'RunCompanyReturnRateReportConnection' }
    & { nodes: Array<(
      { __typename?: 'RunCompanyReturnRateReportRecord' }
      & Pick<RunCompanyReturnRateReportRecord, 'date' | 'percent'>
    )> }
  )>, runReturnReportEmployeeReturnRates?: Maybe<(
    { __typename?: 'RunReturnReportEmployeeReturnRatesConnection' }
    & { nodes: Array<(
      { __typename?: 'RunReturnReportEmployeeReturnRatesRecord' }
      & Pick<RunReturnReportEmployeeReturnRatesRecord, 'employeeName' | 'totalPickedUp' | 'totalReturns' | 'percent' | 'avgPercent'>
    )> }
  )>, runReturnReportPie?: Maybe<(
    { __typename?: 'RunReturnReportPieConnection' }
    & { nodes: Array<(
      { __typename?: 'RunReturnReportPieRecord' }
      & Pick<RunReturnReportPieRecord, 'percent' | 'reason' | 'num'>
    )> }
  )> }
);

export type DownloadReturnReportMutationVariables = Exact<{
  input: DownloadReturnReportInput;
}>;


export type DownloadReturnReportMutation = (
  { __typename?: 'Mutation' }
  & { downloadReturnReport?: Maybe<(
    { __typename?: 'DownloadReturnReportPayload' }
    & Pick<DownloadReturnReportPayload, 'clientMutationId'>
  )> }
);

export type ReturnOptionEntryFragment = (
  { __typename?: 'ReturnOption' }
  & Pick<ReturnOption, 'id' | 'name' | 'description' | 'requireNote' | 'accessSupport' | 'accessApp' | 'isActive' | 'countAgainstUser' | 'countAgainstCompany'>
);

export type AllReturnOptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnOptionsOrderBy> | ReturnOptionsOrderBy>;
  filter?: Maybe<ReturnOptionFilter>;
}>;


export type AllReturnOptionsQuery = (
  { __typename?: 'Query' }
  & { allReturnOptions?: Maybe<(
    { __typename?: 'ReturnOptionsConnection' }
    & Pick<ReturnOptionsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'ReturnOption' }
      & ReturnOptionEntryFragment
    )> }
  )> }
);

export type ReturnOptionByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type ReturnOptionByIdQuery = (
  { __typename?: 'Query' }
  & { returnOptionById?: Maybe<(
    { __typename?: 'ReturnOption' }
    & ReturnOptionEntryFragment
  )> }
);

export type UpsertReturnOptionMutationVariables = Exact<{
  input: UpsertReturnOptionInput;
  where?: Maybe<UpsertReturnOptionWhere>;
}>;


export type UpsertReturnOptionMutation = (
  { __typename?: 'Mutation' }
  & { upsertReturnOption?: Maybe<(
    { __typename?: 'UpsertReturnOptionPayload' }
    & { returnOption?: Maybe<(
      { __typename?: 'ReturnOption' }
      & ReturnOptionEntryFragment
    )> }
  )> }
);

export type GetReturnOptionsQueryVariables = Exact<{
  value: Scalars['String'];
}>;


export type GetReturnOptionsQuery = (
  { __typename?: 'Query' }
  & { allReturnOptions?: Maybe<(
    { __typename?: 'ReturnOptionsConnection' }
    & { nodes: Array<(
      { __typename?: 'ReturnOption' }
      & ReturnOptionEntryFragment
    )> }
  )> }
);

export type ReturnQueueEntryFragment = (
  { __typename?: 'ReturnQueue' }
  & Pick<ReturnQueue, 'id' | 'completedAt' | 'completedBy' | 'createdAt' | 'createdBy' | 'status' | 'type' | 'updatedBy' | 'updatedAt' | 'amount' | 'note' | 'sendEmail' | 'isMer'>
  & { returnOptionByOptionId?: Maybe<(
    { __typename?: 'ReturnOption' }
    & Pick<ReturnOption, 'id' | 'name'>
  )>, userByCreatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )>, userByCompletedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )>, itemByItemId?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'number' | 'description'>
    & { invoiceByInvoiceId?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'uid'>
    )>, bidderByBidderId?: Maybe<(
      { __typename?: 'Bidder' }
      & Pick<Bidder, 'id' | 'name' | 'activeAlertNotesCount'>
    )> }
  )> }
);

export type ReturnQueueListEntryFragment = (
  { __typename?: 'ReturnQueue' }
  & Pick<ReturnQueue, 'id' | 'type' | 'status' | 'createdAt' | 'isMer'>
  & { returnOptionByOptionId?: Maybe<(
    { __typename?: 'ReturnOption' }
    & Pick<ReturnOption, 'name'>
  )>, userByCreatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name'>
  )>, itemByItemId?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'number'>
    & { bidderByBidderId?: Maybe<(
      { __typename?: 'Bidder' }
      & Pick<Bidder, 'name'>
    )>, invoiceByInvoiceId?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'uid'>
    )> }
  )> }
);

export type AllReturnQueueQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy> | ReturnQueuesOrderBy>;
  filter?: Maybe<ReturnQueueFilter>;
}>;


export type AllReturnQueueQuery = (
  { __typename?: 'Query' }
  & { allReturnQueues?: Maybe<(
    { __typename?: 'ReturnQueuesConnection' }
    & Pick<ReturnQueuesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'ReturnQueue' }
      & ReturnQueueListEntryFragment
    )> }
  )> }
);

export type BidWranglerReturnQueueQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy> | ReturnQueuesOrderBy>;
  filter?: Maybe<ReturnQueueFilter>;
}>;


export type BidWranglerReturnQueueQuery = (
  { __typename?: 'Query' }
  & { bidwranglerReturnQueue?: Maybe<(
    { __typename?: 'ReturnQueuesConnection' }
    & Pick<ReturnQueuesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'ReturnQueue' }
      & ReturnQueueListEntryFragment
    )> }
  )> }
);

export type MercuryReturnQueueQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<ReturnQueuesOrderBy> | ReturnQueuesOrderBy>;
  filter?: Maybe<ReturnQueueFilter>;
}>;


export type MercuryReturnQueueQuery = (
  { __typename?: 'Query' }
  & { mercuryReturnQueue?: Maybe<(
    { __typename?: 'ReturnQueuesConnection' }
    & Pick<ReturnQueuesConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'ReturnQueue' }
      & ReturnQueueListEntryFragment
    )> }
  )> }
);

export type GetReturnQueueByIdQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetReturnQueueByIdQuery = (
  { __typename?: 'Query' }
  & { returnQueueById?: Maybe<(
    { __typename?: 'ReturnQueue' }
    & ReturnQueueEntryFragment
  )> }
);

export type UpdateReturnQueueStatusMutationVariables = Exact<{
  id: Scalars['UUID'];
  status: ReturnQueueStatus;
}>;


export type UpdateReturnQueueStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateReturnQueueById?: Maybe<(
    { __typename?: 'UpdateReturnQueuePayload' }
    & { returnQueue?: Maybe<(
      { __typename?: 'ReturnQueue' }
      & ReturnQueueEntryFragment
    )> }
  )> }
);

export type ShippingQueueItemEntryFragment = (
  { __typename?: 'ShippingQueueItem' }
  & Pick<ShippingQueueItem, 'customerId' | 'customerName' | 'assignedUserId' | 'assignedName' | 'inQueueAt'>
);

export type ShippingQueueByLocationQueryVariables = Exact<{
  locationId: Scalars['Int'];
}>;


export type ShippingQueueByLocationQuery = (
  { __typename?: 'Query' }
  & { shippingUnassignedItems?: Maybe<(
    { __typename?: 'ShippingQueueItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'ShippingQueueItem' }
      & ShippingQueueItemEntryFragment
    )> }
  )>, shippingAssignedItems?: Maybe<(
    { __typename?: 'ShippingQueueItemsConnection' }
    & { nodes: Array<(
      { __typename?: 'ShippingQueueItem' }
      & ShippingQueueItemEntryFragment
    )> }
  )> }
);

export type ShippingQueueEntryFragment = (
  { __typename?: 'ShippingQueue' }
  & Pick<ShippingQueue, 'id' | 'status' | 'createdAt'>
  & { userByAssignedUserId?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )>, locationByLocationId?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, itemByItemId?: Maybe<(
    { __typename?: 'Item' }
    & Pick<Item, 'id' | 'number' | 'description'>
    & { invoiceByInvoiceId?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'uid'>
      & { auctionByAuctionId?: Maybe<(
        { __typename?: 'Auction' }
        & Pick<Auction, 'id' | 'name'>
      )>, bidderByBidderId?: Maybe<(
        { __typename?: 'Bidder' }
        & Pick<Bidder, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ShippingQueueItemQueryVariables = Exact<{
  locationId: Scalars['Int'];
  customerId: Scalars['Int'];
  isAssignedNull: Scalars['Boolean'];
  status: ShippingQueueStatusFilter;
}>;


export type ShippingQueueItemQuery = (
  { __typename?: 'Query' }
  & { allShippingQueues?: Maybe<(
    { __typename?: 'ShippingQueuesConnection' }
    & { nodes: Array<(
      { __typename?: 'ShippingQueue' }
      & ShippingQueueEntryFragment
    )> }
  )> }
);

export type DeleteShippingQueueMutationVariables = Exact<{
  input: DeleteShippingQueueByIdInput;
}>;


export type DeleteShippingQueueMutation = (
  { __typename?: 'Mutation' }
  & { deleteShippingQueueById?: Maybe<(
    { __typename?: 'DeleteShippingQueuePayload' }
    & Pick<DeleteShippingQueuePayload, 'deletedShippingQueueId'>
  )> }
);

export type UpdateShippingQueueMutationVariables = Exact<{
  input: UpdateShippingQueueByIdInput;
}>;


export type UpdateShippingQueueMutation = (
  { __typename?: 'Mutation' }
  & { updateShippingQueueById?: Maybe<(
    { __typename?: 'UpdateShippingQueuePayload' }
    & { shippingQueue?: Maybe<(
      { __typename?: 'ShippingQueue' }
      & ShippingQueueEntryFragment
    )> }
  )> }
);

export type TransactionEntryFragment = (
  { __typename?: 'Transaction' }
  & Pick<Transaction, 'id' | 'type' | 'paymentId' | 'paymentProcessorId' | 'bidderPaymentId' | 'status' | 'amount' | 'response' | 'createdAt'>
  & { paymentByPaymentId?: Maybe<(
    { __typename?: 'Payment' }
    & Pick<Payment, 'id'>
    & { invoiceByInvoiceId?: Maybe<(
      { __typename?: 'Invoice' }
      & Pick<Invoice, 'id' | 'uid'>
      & { bidderByBidderId?: Maybe<(
        { __typename?: 'Bidder' }
        & Pick<Bidder, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type AllTransactionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TransactionsOrderBy> | TransactionsOrderBy>;
  filter?: Maybe<TransactionFilter>;
}>;


export type AllTransactionsQuery = (
  { __typename?: 'Query' }
  & { allTransactions?: Maybe<(
    { __typename?: 'TransactionsConnection' }
    & Pick<TransactionsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Transaction' }
      & TransactionEntryFragment
    )> }
  )> }
);

export type GetTransactionQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTransactionQuery = (
  { __typename?: 'Query' }
  & { transactionById?: Maybe<(
    { __typename?: 'Transaction' }
    & TransactionEntryFragment
  )> }
);

export type GetTransactionsByPaymentIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetTransactionsByPaymentIdQuery = (
  { __typename?: 'Query' }
  & { allTransactions?: Maybe<(
    { __typename?: 'TransactionsConnection' }
    & { nodes: Array<(
      { __typename?: 'Transaction' }
      & TransactionEntryFragment
    )> }
  )> }
);

export type TruckloadEntryFragment = (
  { __typename?: 'Truckload' }
  & Pick<Truckload, 'id' | 'bolImageUrl' | 'createdAt' | 'createdBy' | 'dateReceived' | 'facilityName' | 'itemsOnManifest' | 'lastInventoriedItemsRefresh' | 'loadIdentifier' | 'num' | 'shouldRefreshInventoriedItems'>
  & { locationByReceivingLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'id' | 'name'>
  )>, userByCreatedBy?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'name'>
  )> }
);

export type TruckloadDataEntryFragment = (
  { __typename?: 'TruckloadDatum' }
  & Pick<TruckloadDatum, 'id' | 'num' | 'countItemsSold' | 'dateReceived' | 'facilityName' | 'itemsSoldPercent' | 'loadIdentifier' | 'locationName' | 'receivingLocation' | 'retailPercent' | 'returnRatePercent' | 'totalSaleAmount' | 'countInventoriedItems' | 'totalReturnAmount' | 'countItemsReturned' | 'countItemsUnsold' | 'unsoldTotalRetailPrice' | 'soldTotalRetailPrice'>
);

export type AllTruckloadsEntryFragment = (
  { __typename?: 'Truckload' }
  & Pick<Truckload, 'id' | 'num' | 'loadIdentifier' | 'facilityName' | 'dateReceived'>
  & { truckloadDatumById?: Maybe<(
    { __typename?: 'TruckloadDatum' }
    & Pick<TruckloadDatum, 'retailPercent' | 'returnRatePercent' | 'totalSaleAmount' | 'itemsSoldPercent' | 'countItemsSold' | 'countInventoriedItems'>
  )> }
);

export type AllTruckloadsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<TruckloadsOrderBy> | TruckloadsOrderBy>;
  filter?: Maybe<TruckloadFilter>;
}>;


export type AllTruckloadsQuery = (
  { __typename?: 'Query' }
  & { allTruckloads?: Maybe<(
    { __typename?: 'TruckloadsConnection' }
    & Pick<TruckloadsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'Truckload' }
      & AllTruckloadsEntryFragment
    )> }
  )> }
);

export type GetTruckloadQueryVariables = Exact<{
  id: Scalars['UUID'];
}>;


export type GetTruckloadQuery = (
  { __typename?: 'Query' }
  & { truckloadById?: Maybe<(
    { __typename?: 'Truckload' }
    & TruckloadEntryFragment
  )>, getTruckloadData?: Maybe<(
    { __typename?: 'TruckloadDatum' }
    & TruckloadDataEntryFragment
  )> }
);

export type UploadTruckloadBillOfLadingMutationVariables = Exact<{
  input: UploadTruckloadBillOfLadingInput;
}>;


export type UploadTruckloadBillOfLadingMutation = (
  { __typename?: 'Mutation' }
  & { uploadTruckloadBillOfLading?: Maybe<(
    { __typename?: 'Truckload' }
    & TruckloadEntryFragment
  )> }
);

export type UpdateTruckloadMutationVariables = Exact<{
  input: UpdateTruckloadByIdInput;
}>;


export type UpdateTruckloadMutation = (
  { __typename?: 'Mutation' }
  & { updateTruckloadById?: Maybe<(
    { __typename?: 'UpdateTruckloadPayload' }
    & { truckload?: Maybe<(
      { __typename?: 'Truckload' }
      & TruckloadEntryFragment
    )> }
  )> }
);

export type CurrentUserEntryFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'firstName' | 'lastName' | 'role'>
);

export type CurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type CurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & CurrentUserEntryFragment
  )> }
);

export type AllUsersEntryFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'role' | 'isActive'>
);

export type AllUsersQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
  orderBy?: Maybe<Array<UsersOrderBy> | UsersOrderBy>;
  filter?: Maybe<UserFilter>;
}>;


export type AllUsersQuery = (
  { __typename?: 'Query' }
  & { allUsers?: Maybe<(
    { __typename?: 'UsersConnection' }
    & Pick<UsersConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'User' }
      & AllUsersEntryFragment
    )> }
  )> }
);

export type UserEntryFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'firstName' | 'lastName' | 'username' | 'role' | 'updatedAt' | 'createdAt' | 'isActive' | 'pincode'>
);

export type GetUserQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetUserQuery = (
  { __typename?: 'Query' }
  & { userById?: Maybe<(
    { __typename?: 'User' }
    & UserEntryFragment
  )> }
);

export type CreateAdministratorMutationVariables = Exact<{
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  pinCode?: Maybe<Scalars['String']>;
}>;


export type CreateAdministratorMutation = (
  { __typename?: 'Mutation' }
  & { createAdministrator?: Maybe<(
    { __typename?: 'CreateAdministratorPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserEntryFragment
    )> }
  )> }
);

export type CreateSecretaryMutationVariables = Exact<{
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  password: Scalars['String'];
  pinCode?: Maybe<Scalars['String']>;
}>;


export type CreateSecretaryMutation = (
  { __typename?: 'Mutation' }
  & { createSecretary?: Maybe<(
    { __typename?: 'CreateSecretaryPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserEntryFragment
    )> }
  )> }
);

export type CreateEmployeeMutationVariables = Exact<{
  username: Scalars['String'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  pinCode: Scalars['String'];
}>;


export type CreateEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { createEmployee?: Maybe<(
    { __typename?: 'CreateEmployeePayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserEntryFragment
    )> }
  )> }
);

export type UpdateUserActiveStateMutationVariables = Exact<{
  id: Scalars['Int'];
  isActive: Scalars['Boolean'];
}>;


export type UpdateUserActiveStateMutation = (
  { __typename?: 'Mutation' }
  & { updateUserActiveState?: Maybe<(
    { __typename?: 'UpdateUserActiveStatePayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserEntryFragment
    )> }
  )> }
);

export type ChangeUserPasswordMutationVariables = Exact<{
  input: ChangeUserPasswordInput;
}>;


export type ChangeUserPasswordMutation = (
  { __typename?: 'Mutation' }
  & { changeUserPassword?: Maybe<(
    { __typename?: 'ChangeUserPasswordPayload' }
    & Pick<ChangeUserPasswordPayload, 'boolean'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & UserEntryFragment
    )> }
  )> }
);

export type GetUsersQueryVariables = Exact<{
  value: Scalars['String'];
}>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { allUsers?: Maybe<(
    { __typename?: 'UsersConnection' }
    & { nodes: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )> }
  )> }
);

export type UserNotificationEntryFragment = (
  { __typename?: 'UserNotification' }
  & Pick<UserNotification, 'id' | 'result' | 'isCleared' | 'isCompleted' | 'createdAt' | 'updatedAt'>
  & { meta?: Maybe<(
    { __typename?: 'UserNotificationMetaType' }
    & Pick<UserNotificationMetaType, 'id' | 'text' | 'type'>
  )>, before?: Maybe<(
    { __typename?: 'UserNotificationBeforeType' }
    & Pick<UserNotificationBeforeType, 'message'>
  )>, after?: Maybe<(
    { __typename?: 'UserNotificationAfterType' }
    & Pick<UserNotificationAfterType, 'message' | 'url'>
  )> }
);

export type GetMyNotificationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['Cursor']>;
  before?: Maybe<Scalars['Cursor']>;
}>;


export type GetMyNotificationsQuery = (
  { __typename?: 'Query' }
  & { allUserNotifications?: Maybe<(
    { __typename?: 'UserNotificationsConnection' }
    & Pick<UserNotificationsConnection, 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'hasNextPage' | 'endCursor'>
    ), nodes: Array<(
      { __typename?: 'UserNotification' }
      & UserNotificationEntryFragment
    )> }
  )> }
);

export type ClearAllUserNotificationsMutationVariables = Exact<{ [key: string]: never; }>;


export type ClearAllUserNotificationsMutation = (
  { __typename?: 'Mutation' }
  & { clearAllUserNotifications?: Maybe<(
    { __typename?: 'ClearAllUserNotificationsPayload' }
    & Pick<ClearAllUserNotificationsPayload, 'clientMutationId'>
  )> }
);

export type ClearUserNotificationMutationVariables = Exact<{
  notificationId: Scalars['Int'];
}>;


export type ClearUserNotificationMutation = (
  { __typename?: 'Mutation' }
  & { clearUserNotification?: Maybe<(
    { __typename?: 'ClearUserNotificationPayload' }
    & Pick<ClearUserNotificationPayload, 'clientMutationId'>
  )> }
);

export type UserNotificationChangedSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type UserNotificationChangedSubscription = (
  { __typename?: 'Subscription' }
  & { UserNotificationEvent?: Maybe<(
    { __typename?: 'UserNotificationEventSubscriptionPayload' }
    & Pick<UserNotificationEventSubscriptionPayload, 'event'>
    & { notification?: Maybe<(
      { __typename?: 'UserNotification' }
      & UserNotificationEntryFragment
    )> }
  )> }
);

export const ItemImageEntryFragmentDoc = gql`
    fragment ItemImageEntry on ItemImage {
  id
  sort
  isPrimary
  publicUrl
}
    `;
export const AuctionEntryFragmentDoc = gql`
    fragment AuctionEntry on Auction {
  id
  bwId
  invId
  number
  locationByLocationId {
    id
    name
    fullAddress
    shortDescription
    fullDescription
  }
  locationName
  locationId
  name
  descriptionShort
  descriptionFull
  tagline
  images {
    nodes {
      ...ItemImageEntry
    }
  }
  premium
  tax
  timezone
  startAt
  endAt
  pickupStartAt
  pickupEndAt
  isPushed
  isPushing
  isDeleted
  isPulled
  isPulling
  lastPullAt
  lastPullError
  lastPushAt
  lastPushError
  isProcessingInvoicePayments
  processInvoicePaymentsExecCount
  isEmailingInvoices
  emailInvoicesExecCount
  countTotalInvoices
  countPaidInvoices
  countUnpaidInvoices
  countUncompletedInvoices
  countCompletedInvoices
  percentPaidInvoices
  percentCompletedInvoices
  percentSoldItems
  countSoldItems
  countTotalItems
  countQueuedItemsForPush
  lpudNumDays
  extType
  merId
}
    ${ItemImageEntryFragmentDoc}`;
export const AllAuctionsEntryFragmentDoc = gql`
    fragment AllAuctionsEntry on Auction {
  ...AuctionEntry
  percentSoldItems
  countSoldItems
  countTotalItems
  countTotalInvoices
  countPaidInvoices
  countUnpaidInvoices
  countUncompletedInvoices
  countCompletedInvoices
  percentPaidInvoices
  percentCompletedInvoices
}
    ${AuctionEntryFragmentDoc}`;
export const AuctionItemImageEntryFragmentDoc = gql`
    fragment AuctionItemImageEntry on Item {
  id
  number
  description
}
    `;
export const AuctionPendingChangesEntryFragmentDoc = gql`
    fragment AuctionPendingChangesEntry on AuctionChangesView {
  id
  name
  countQueuedItemsForPush
  countProcessingItemsForPush
}
    `;
export const CredentialsFragmentDoc = gql`
    fragment Credentials on Credentials {
  accessToken
  refreshToken
}
    `;
export const BidderEntryFragmentDoc = gql`
    fragment BidderEntry on Bidder {
  id
  bwId
  name
  firstName
  lastName
  email
  mobileNumber
  phoneNumber
  location
  creditBalance
  processInvoicePayment
  processInvoicePaymentReason
  userByProcessInvoicePaymentChangedBy {
    name
  }
  processInvoicePaymentChangedAt
  isTaxExempt
  paymentId
  merId
}
    `;
export const CustomerNoteEntryFragmentDoc = gql`
    fragment CustomerNoteEntry on CustomerNote {
  id
  note
  createdBy
  createdAt
  type
  expiresAt
  userByCreatedBy {
    name
  }
}
    `;
export const CustomerActionViewFragmentDoc = gql`
    fragment CustomerActionView on CustomerActionsView {
  id
  note
  createdBy
  createdAt
  type
  expiresAt
  userByCreatedBy {
    name
  }
}
    `;
export const CustomerAlertViewFragmentDoc = gql`
    fragment CustomerAlertView on CustomerAlertsView {
  id
  note
  createdBy
  createdAt
  type
  expiresAt
  userByCreatedBy {
    name
  }
}
    `;
export const BidderCreditHistoryEntryFragmentDoc = gql`
    fragment BidderCreditHistoryEntry on BidderCreditHistory {
  id
  txType
  credit
  note
  createdAt
  auctionByAuctionId {
    id
    name
  }
  invoiceByInvoiceId {
    id
    uid
  }
}
    `;
export const EmployeeStatFragmentFragmentDoc = gql`
    fragment EmployeeStatFragment on EmployeeStat {
  employeeId
  employeeName
  locationId
  locationName
  dailyTotalItems
  dailyPicklistItems
  dailyPickupItems
  last60TotalItems
  last60PicklistItems
  last60PickupItems
}
    `;
export const AllInvoiceEntryFragmentDoc = gql`
    fragment AllInvoiceEntry on Invoice {
  id
  uid
  total
  amountDue
  itemsCountAll
  itemsCountActive
  isPaid
  isCompleted
  lastPickupDate
  countTotalReturns
  auctionByAuctionId {
    id
    name
  }
  bidderByBidderId {
    id
    name
    email
    customerNotesByCustomerId {
      totalCount
    }
  }
}
    `;
export const InvoiceEntryFragmentDoc = gql`
    fragment InvoiceEntry on Invoice {
  id
  uid
  processPayment
  processPaymentReason
  userByProcessPaymentChangedBy {
    name
  }
  processPaymentChangedAt
  bidderCreditsUsed
  paymentCreditsUsed
  alternateCreditsUsed
  isProcessingPayment
  isInitialPaymentProcessed
  canProceedPayment
  lastPickupDate
  isPaid
  merId
}
    `;
export const InvoiceAdjustmentEntryFragmentDoc = gql`
    fragment InvoiceAdjustmentEntry on InvoiceAdjustment {
  id
  description
  type
  amount
  isDeleted
  createdAt
}
    `;
export const GetInvoiceByIdEntryFragmentDoc = gql`
    fragment GetInvoiceByIdEntry on Invoice {
  ...InvoiceEntry
  itemsSubtotal
  feesSubtotal
  total
  amountDue
  publicToken
  elevatedToken
  auctionByAuctionId {
    id
    name
    pickupEndAt
  }
  bidderByBidderId {
    id
    name
    activeAllNotesCount
  }
  invoiceAdjustmentsByInvoiceId(condition: {isDeleted: false}) {
    nodes {
      ...InvoiceAdjustmentEntry
    }
  }
}
    ${InvoiceEntryFragmentDoc}
${InvoiceAdjustmentEntryFragmentDoc}`;
export const AllAuctionInvoicesEntryFragmentDoc = gql`
    fragment AllAuctionInvoicesEntry on AuctionInvoicesView {
  invoiceId
  invoiceUid
  amountDue
  total
  itemCount
  auctionName
  customerId
  customerName
  customerActiveActionNotesCount
  customerActiveAlertNotesCount
}
    `;
export const GetEligibleBatchProcessInvoicePaymentsEntryFragmentDoc = gql`
    fragment GetEligibleBatchProcessInvoicePaymentsEntry on Invoice {
  id
  amountDue
  uid
  bidderByBidderId {
    name
  }
}
    `;
export const GetEligibleBatchEmailInvoicesEntryFragmentDoc = gql`
    fragment GetEligibleBatchEmailInvoicesEntry on Invoice {
  id
  amountDue
  uid
  bidderByBidderId {
    name
  }
}
    `;
export const InvoiceNoteEntryFragmentDoc = gql`
    fragment InvoiceNoteEntry on InvoiceNote {
  id
  invoiceId
  note
  createdBy
  createdAt
  userByCreatedBy {
    name
  }
}
    `;
export const SimpleItemRackFragmentDoc = gql`
    fragment SimpleItemRack on ItemRackLocation {
  rackLocation
  bin
}
    `;
export const AllItemsEntryFragmentDoc = gql`
    fragment AllItemsEntry on Item {
  id
  number
  description
  bidAmount
  totalAmount
  overallPickupStatus
  auctionByAuctionId {
    name
    id
  }
  itemRackLocationByItemId {
    ...SimpleItemRack
  }
}
    ${SimpleItemRackFragmentDoc}`;
export const ItemEntryFragmentDoc = gql`
    fragment ItemEntry on Item {
  id
  nodeId
  bwId
  number
  invId
  auctionId
  invoiceId
  truckId
  invDateCreated
  invDateScanned
  invCreatedByUserName
  description
  bidAmount
  premiumAmount
  taxAmount
  totalAmount
  retailPrice
  condition
  category
  olasku
  createdAt
  updatedAt
  bwSoldState
  bwWonBidderId
  bwWonAmount
  images
  primaryImages
  primaryImagesCount
  isTaxExempt
  overallPickupStatus
  paidStatus
  setAsideNote
  setAsideAt
  externalHyperlink
  userBySetAsideBy {
    name
  }
  itemRackLocationByItemId {
    rackLocation
    bin
    updatedAt
    updatedBy
    userByUpdatedBy {
      name
    }
  }
  merId
}
    `;
export const AllInvoiceItemsEntryFragmentDoc = gql`
    fragment AllInvoiceItemsEntry on Item {
  ...ItemEntry
  invoiceItemReturnsByInvoiceItemId(condition: {wasSuccessful: true}) {
    totalCount
  }
}
    ${ItemEntryFragmentDoc}`;
export const AllAuctionItemEntryFragmentDoc = gql`
    fragment AllAuctionItemEntry on Item {
  id
  number
  description
  retailPrice
  condition
  category
  isTaxExempt
  isDeleted
  isPushed
  primaryImages
  primaryImagesCount
  isProcessingPushChange
  isQueuedPushChange
}
    `;
export const ItemHistoryEntryFragmentDoc = gql`
    fragment ItemHistoryEntry on GetItemHistoryRecord {
  id
  type
  oldValue
  newValue
  createdAt
  createdByUser
}
    `;
export const RackLocationListEntryFragmentDoc = gql`
    fragment RackLocationListEntry on RackLocationListResult {
  rackLocation
  itemCount
}
    `;
export const ItemRackLocationEntryFragmentDoc = gql`
    fragment ItemRackLocationEntry on ItemRackLocation {
  updatedAt
  userByUpdatedBy {
    name
  }
  itemByItemId {
    number
    invoiceId
    auctionByAuctionId {
      name
    }
    invoiceByInvoiceId {
      uid
    }
    bidderByBidderId {
      name
    }
  }
}
    `;
export const MissingRackLocationListEntryFragmentDoc = gql`
    fragment MissingRackLocationListEntry on MissingRackLocationListResult {
  auction
  customer
  invoiceId
  invoiceUid
  itemId
  itemNumber
  location
}
    `;
export const InvoiceItemReturnEntryFragmentDoc = gql`
    fragment InvoiceItemReturnEntry on InvoiceItemReturn {
  id
  queueId
  creditAmount
  errorMessage
  invoiceItemId
  paymentAmount
  alternatePaymentAmount
  returnOptionByOptionId {
    name
  }
  note
  status
  wasSuccessful
  createdAt
  userByCreatedBy {
    id
    name
  }
  updatedAt
  canDeleteCredit
  invoiceItemReturnHistoriesByReturnId(orderBy: TS_DESC) {
    nodes {
      id
      reason
      action
      data
      ts
      userByBy {
        name
      }
    }
  }
}
    `;
export const JobsByAuctionIdEntryFragmentDoc = gql`
    fragment JobsByAuctionIdEntry on JobQueue {
  id
  failCount
  job
  isCompleted
  successCount
  jobCount
  createdAt
  updatedAt
}
    `;
export const JobQueueItemEntryFragmentDoc = gql`
    fragment JobQueueItemEntry on JobQueueItem {
  id
  payload
  completedAt
}
    `;
export const LocationEntryFragmentDoc = gql`
    fragment LocationEntry on Location {
  id
  invoicePrefix
  name
  fullAddress
  addressMain
  addressSecondary
  city
  state
  zipCode
  tax
  premium
  timezone
  shortDescription
  fullDescription
  lpudMon
  lpudTue
  lpudWed
  lpudThu
  lpudFri
  lpudSat
  lpudSun
}
    `;
export const LocationListEntryFragmentDoc = gql`
    fragment LocationListEntry on Location {
  id
  name
  premium
  tax
  addressMain
  addressSecondary
  city
  timezone
  state
  zipCode
  isDeleted
}
    `;
export const GetLocationsEntryFragmentDoc = gql`
    fragment GetLocationsEntry on Location {
  id
  name
  fullAddress
}
    `;
export const PaymentEntryFragmentDoc = gql`
    fragment PaymentEntry on Payment {
  id
  status
  chargeStatus
  chargeStatusErrorMessage
  processingVoidStatus
  isVoided
  isCharged
  amount
  createdAt
  paymentAmount
  creditAmount
  refundedPaymentAmount
  refundedCreditAmount
  cardNumber
  invoiceByInvoiceId {
    id
    uid
    bidderByBidderId {
      id
      name
    }
  }
}
    `;
export const InvoiceForSendToPickListQueueEntryFragmentDoc = gql`
    fragment InvoiceForSendToPickListQueueEntry on Invoice {
  uid
  customer: bidderByBidderId {
    name
  }
  items: itemsByInvoiceId(
    filter: {isPriorityPickup: {equalTo: false}, overallPickupStatus: {in: [PAID, SET_ASIDE]}}
  ) {
    totalCount
  }
}
    `;
export const PicklistQueueItemEntryFragmentDoc = gql`
    fragment PicklistQueueItemEntry on PicklistQueueItem {
  customerId
  customerName
  assignedUserId
  assignedName
  inQueueAt
  scheduleTime
}
    `;
export const PicklistQueueEntryFragmentDoc = gql`
    fragment PicklistQueueEntry on PicklistQueue {
  id
  status
  createdAt
  userByAssignedUserId {
    id
    name
  }
  locationByLocationId {
    id
    name
  }
  itemByItemId {
    id
    number
    description
    invoiceByInvoiceId {
      id
      uid
      auctionByAuctionId {
        id
        name
      }
      bidderByBidderId {
        id
        name
      }
    }
  }
}
    `;
export const PickupQueueEntryFragmentDoc = gql`
    fragment PickupQueueEntry on PickupQueue {
  id
  elapsedSeconds
  status
  createdAt
  userByAssignedUserId {
    id
    name
  }
  locationByLocationId {
    id
    name
  }
  itemByItemId {
    id
    number
    isPriorityPickup
    description
    invoiceByInvoiceId {
      id
      uid
      auctionByAuctionId {
        id
        name
      }
      bidderByBidderId {
        id
        name
      }
    }
  }
}
    `;
export const PickupQueueItemEntryFragmentDoc = gql`
    fragment PickupQueueItemEntry on PickupQueueItem {
  assignedUserId
  customerId
  assignedName
  customerName
  isPriority
  inQueueAt
  skipCount
  pickupNumber
}
    `;
export const LivePickupQueueEntryFragmentDoc = gql`
    fragment LivePickupQueueEntry on LivePickupQueue {
  customerId
  assignedUserId
  isPriority
  bidderByCustomerId {
    name
  }
  userByAssignedUserId {
    name
  }
}
    `;
export const AllPrintersEntryFragmentDoc = gql`
    fragment AllPrintersEntry on Printer {
  id
  name
  locationByLocationId {
    name
  }
  ipAddress
  port
}
    `;
export const PrinterEntryFragmentDoc = gql`
    fragment PrinterEntry on Printer {
  id
  name
  locationId
  locationByLocationId {
    id
    name
  }
  ipAddress
  port
  createdAt
  updatedAt
}
    `;
export const ReturnOptionEntryFragmentDoc = gql`
    fragment ReturnOptionEntry on ReturnOption {
  id
  name
  description
  requireNote
  accessSupport
  accessApp
  isActive
  countAgainstUser
  countAgainstCompany
}
    `;
export const ReturnQueueEntryFragmentDoc = gql`
    fragment ReturnQueueEntry on ReturnQueue {
  id
  completedAt
  completedBy
  createdAt
  createdBy
  status
  type
  updatedBy
  updatedAt
  amount
  note
  sendEmail
  isMer
  returnOptionByOptionId {
    id
    name
  }
  userByCreatedBy {
    name
  }
  userByCompletedBy {
    name
  }
  itemByItemId {
    id
    number
    description
    invoiceByInvoiceId {
      id
      uid
    }
    bidderByBidderId {
      id
      name
      activeAlertNotesCount
    }
  }
}
    `;
export const ReturnQueueListEntryFragmentDoc = gql`
    fragment ReturnQueueListEntry on ReturnQueue {
  id
  type
  status
  createdAt
  isMer
  returnOptionByOptionId {
    name
  }
  userByCreatedBy {
    name
  }
  itemByItemId {
    id
    number
    bidderByBidderId {
      name
    }
    invoiceByInvoiceId {
      id
      uid
    }
  }
}
    `;
export const ShippingQueueItemEntryFragmentDoc = gql`
    fragment ShippingQueueItemEntry on ShippingQueueItem {
  customerId
  customerName
  assignedUserId
  assignedName
  inQueueAt
}
    `;
export const ShippingQueueEntryFragmentDoc = gql`
    fragment ShippingQueueEntry on ShippingQueue {
  id
  status
  createdAt
  userByAssignedUserId {
    id
    name
  }
  locationByLocationId {
    id
    name
  }
  itemByItemId {
    id
    number
    description
    invoiceByInvoiceId {
      id
      uid
      auctionByAuctionId {
        id
        name
      }
      bidderByBidderId {
        id
        name
      }
    }
  }
}
    `;
export const TransactionEntryFragmentDoc = gql`
    fragment TransactionEntry on Transaction {
  id
  type
  paymentId
  paymentProcessorId
  bidderPaymentId
  status
  amount
  response
  createdAt
  paymentByPaymentId {
    id
    invoiceByInvoiceId {
      id
      uid
      bidderByBidderId {
        id
        name
      }
    }
  }
}
    `;
export const TruckloadEntryFragmentDoc = gql`
    fragment TruckloadEntry on Truckload {
  id
  bolImageUrl
  createdAt
  createdBy
  dateReceived
  facilityName
  itemsOnManifest
  lastInventoriedItemsRefresh
  loadIdentifier
  locationByReceivingLocation {
    id
    name
  }
  num
  shouldRefreshInventoriedItems
  userByCreatedBy {
    id
    name
  }
}
    `;
export const TruckloadDataEntryFragmentDoc = gql`
    fragment TruckloadDataEntry on TruckloadDatum {
  id
  num
  countItemsSold
  dateReceived
  facilityName
  itemsSoldPercent
  loadIdentifier
  locationName
  receivingLocation
  retailPercent
  returnRatePercent
  totalSaleAmount
  countInventoriedItems
  totalReturnAmount
  countItemsReturned
  countItemsUnsold
  unsoldTotalRetailPrice
  soldTotalRetailPrice
}
    `;
export const AllTruckloadsEntryFragmentDoc = gql`
    fragment AllTruckloadsEntry on Truckload {
  id
  num
  loadIdentifier
  facilityName
  dateReceived
  truckloadDatumById {
    retailPercent
    returnRatePercent
    totalSaleAmount
    itemsSoldPercent
    countItemsSold
    countInventoriedItems
  }
}
    `;
export const CurrentUserEntryFragmentDoc = gql`
    fragment CurrentUserEntry on User {
  id
  username
  firstName
  lastName
  role
}
    `;
export const AllUsersEntryFragmentDoc = gql`
    fragment AllUsersEntry on User {
  id
  firstName
  lastName
  username
  role
  isActive
}
    `;
export const UserEntryFragmentDoc = gql`
    fragment UserEntry on User {
  id
  firstName
  lastName
  username
  role
  updatedAt
  createdAt
  isActive
  pincode
}
    `;
export const UserNotificationEntryFragmentDoc = gql`
    fragment UserNotificationEntry on UserNotification {
  id
  result
  meta {
    id
    text
    type
  }
  before {
    message
  }
  after {
    message
    url
  }
  isCleared
  isCompleted
  createdAt
  updatedAt
}
    `;
export const DashboardAnalyticsDocument = gql`
    query DashboardAnalytics($startDate: Datetime!, $endDate: Datetime!, $groupBy: ReportGroupBy!) {
  dashboardAnalytics {
    numItemsReturned
    numItemsSold
  }
  runItemSoldReport(startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
    nodes {
      date
      count
    }
  }
}
    `;

/**
 * __useDashboardAnalyticsQuery__
 *
 * To run a query within a React component, call `useDashboardAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDashboardAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDashboardAnalyticsQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useDashboardAnalyticsQuery(baseOptions: Apollo.QueryHookOptions<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>(DashboardAnalyticsDocument, options);
      }
export function useDashboardAnalyticsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>(DashboardAnalyticsDocument, options);
        }
export type DashboardAnalyticsQueryHookResult = ReturnType<typeof useDashboardAnalyticsQuery>;
export type DashboardAnalyticsLazyQueryHookResult = ReturnType<typeof useDashboardAnalyticsLazyQuery>;
export type DashboardAnalyticsQueryResult = Apollo.QueryResult<DashboardAnalyticsQuery, DashboardAnalyticsQueryVariables>;
export const TotalAuctionChangesDocument = gql`
    query TotalAuctionChanges {
  totalAuctionChanges
}
    `;

/**
 * __useTotalAuctionChangesQuery__
 *
 * To run a query within a React component, call `useTotalAuctionChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalAuctionChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalAuctionChangesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalAuctionChangesQuery(baseOptions?: Apollo.QueryHookOptions<TotalAuctionChangesQuery, TotalAuctionChangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalAuctionChangesQuery, TotalAuctionChangesQueryVariables>(TotalAuctionChangesDocument, options);
      }
export function useTotalAuctionChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalAuctionChangesQuery, TotalAuctionChangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalAuctionChangesQuery, TotalAuctionChangesQueryVariables>(TotalAuctionChangesDocument, options);
        }
export type TotalAuctionChangesQueryHookResult = ReturnType<typeof useTotalAuctionChangesQuery>;
export type TotalAuctionChangesLazyQueryHookResult = ReturnType<typeof useTotalAuctionChangesLazyQuery>;
export type TotalAuctionChangesQueryResult = Apollo.QueryResult<TotalAuctionChangesQuery, TotalAuctionChangesQueryVariables>;
export const TotalActiveReturnQueueDocument = gql`
    query TotalActiveReturnQueue {
  totalActiveReturnQueue
}
    `;

/**
 * __useTotalActiveReturnQueueQuery__
 *
 * To run a query within a React component, call `useTotalActiveReturnQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalActiveReturnQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalActiveReturnQueueQuery({
 *   variables: {
 *   },
 * });
 */
export function useTotalActiveReturnQueueQuery(baseOptions?: Apollo.QueryHookOptions<TotalActiveReturnQueueQuery, TotalActiveReturnQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalActiveReturnQueueQuery, TotalActiveReturnQueueQueryVariables>(TotalActiveReturnQueueDocument, options);
      }
export function useTotalActiveReturnQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalActiveReturnQueueQuery, TotalActiveReturnQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalActiveReturnQueueQuery, TotalActiveReturnQueueQueryVariables>(TotalActiveReturnQueueDocument, options);
        }
export type TotalActiveReturnQueueQueryHookResult = ReturnType<typeof useTotalActiveReturnQueueQuery>;
export type TotalActiveReturnQueueLazyQueryHookResult = ReturnType<typeof useTotalActiveReturnQueueLazyQuery>;
export type TotalActiveReturnQueueQueryResult = Apollo.QueryResult<TotalActiveReturnQueueQuery, TotalActiveReturnQueueQueryVariables>;
export const GetAuctionByIdDocument = gql`
    query GetAuctionById($id: Int!) {
  auctionById(id: $id) {
    ...AuctionEntry
  }
}
    ${AuctionEntryFragmentDoc}`;

/**
 * __useGetAuctionByIdQuery__
 *
 * To run a query within a React component, call `useGetAuctionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuctionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuctionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuctionByIdQuery(baseOptions: Apollo.QueryHookOptions<GetAuctionByIdQuery, GetAuctionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuctionByIdQuery, GetAuctionByIdQueryVariables>(GetAuctionByIdDocument, options);
      }
export function useGetAuctionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuctionByIdQuery, GetAuctionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuctionByIdQuery, GetAuctionByIdQueryVariables>(GetAuctionByIdDocument, options);
        }
export type GetAuctionByIdQueryHookResult = ReturnType<typeof useGetAuctionByIdQuery>;
export type GetAuctionByIdLazyQueryHookResult = ReturnType<typeof useGetAuctionByIdLazyQuery>;
export type GetAuctionByIdQueryResult = Apollo.QueryResult<GetAuctionByIdQuery, GetAuctionByIdQueryVariables>;
export const AllAuctionsDocument = gql`
    query AllAuctions($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [AuctionsOrderBy!], $filter: AuctionFilter) {
  allAuctions(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllAuctionsEntry
    }
  }
}
    ${AllAuctionsEntryFragmentDoc}`;

/**
 * __useAllAuctionsQuery__
 *
 * To run a query within a React component, call `useAllAuctionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAuctionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAuctionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllAuctionsQuery(baseOptions?: Apollo.QueryHookOptions<AllAuctionsQuery, AllAuctionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAuctionsQuery, AllAuctionsQueryVariables>(AllAuctionsDocument, options);
      }
export function useAllAuctionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAuctionsQuery, AllAuctionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAuctionsQuery, AllAuctionsQueryVariables>(AllAuctionsDocument, options);
        }
export type AllAuctionsQueryHookResult = ReturnType<typeof useAllAuctionsQuery>;
export type AllAuctionsLazyQueryHookResult = ReturnType<typeof useAllAuctionsLazyQuery>;
export type AllAuctionsQueryResult = Apollo.QueryResult<AllAuctionsQuery, AllAuctionsQueryVariables>;
export const CommitAuctionDocument = gql`
    mutation CommitAuction($input: CommitAuctionInput!) {
  commitAuction(input: $input) {
    willCommit
  }
}
    `;
export type CommitAuctionMutationFn = Apollo.MutationFunction<CommitAuctionMutation, CommitAuctionMutationVariables>;

/**
 * __useCommitAuctionMutation__
 *
 * To run a mutation, you first call `useCommitAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCommitAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [commitAuctionMutation, { data, loading, error }] = useCommitAuctionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCommitAuctionMutation(baseOptions?: Apollo.MutationHookOptions<CommitAuctionMutation, CommitAuctionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CommitAuctionMutation, CommitAuctionMutationVariables>(CommitAuctionDocument, options);
      }
export type CommitAuctionMutationHookResult = ReturnType<typeof useCommitAuctionMutation>;
export type CommitAuctionMutationResult = Apollo.MutationResult<CommitAuctionMutation>;
export type CommitAuctionMutationOptions = Apollo.BaseMutationOptions<CommitAuctionMutation, CommitAuctionMutationVariables>;
export const ProcessAuctionInvoicePaymentsDocument = gql`
    mutation ProcessAuctionInvoicePayments($id: Int!) {
  processAuctionInvoicePayments(input: {auctionId: $id}) {
    clientMutationId
  }
}
    `;
export type ProcessAuctionInvoicePaymentsMutationFn = Apollo.MutationFunction<ProcessAuctionInvoicePaymentsMutation, ProcessAuctionInvoicePaymentsMutationVariables>;

/**
 * __useProcessAuctionInvoicePaymentsMutation__
 *
 * To run a mutation, you first call `useProcessAuctionInvoicePaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessAuctionInvoicePaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processAuctionInvoicePaymentsMutation, { data, loading, error }] = useProcessAuctionInvoicePaymentsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useProcessAuctionInvoicePaymentsMutation(baseOptions?: Apollo.MutationHookOptions<ProcessAuctionInvoicePaymentsMutation, ProcessAuctionInvoicePaymentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessAuctionInvoicePaymentsMutation, ProcessAuctionInvoicePaymentsMutationVariables>(ProcessAuctionInvoicePaymentsDocument, options);
      }
export type ProcessAuctionInvoicePaymentsMutationHookResult = ReturnType<typeof useProcessAuctionInvoicePaymentsMutation>;
export type ProcessAuctionInvoicePaymentsMutationResult = Apollo.MutationResult<ProcessAuctionInvoicePaymentsMutation>;
export type ProcessAuctionInvoicePaymentsMutationOptions = Apollo.BaseMutationOptions<ProcessAuctionInvoicePaymentsMutation, ProcessAuctionInvoicePaymentsMutationVariables>;
export const RemovePrimaryImageDocument = gql`
    mutation RemovePrimaryImage($image: String!) {
  removePrimaryImageItem(input: {image: $image}) {
    item {
      primaryImages
    }
  }
}
    `;
export type RemovePrimaryImageMutationFn = Apollo.MutationFunction<RemovePrimaryImageMutation, RemovePrimaryImageMutationVariables>;

/**
 * __useRemovePrimaryImageMutation__
 *
 * To run a mutation, you first call `useRemovePrimaryImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePrimaryImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePrimaryImageMutation, { data, loading, error }] = useRemovePrimaryImageMutation({
 *   variables: {
 *      image: // value for 'image'
 *   },
 * });
 */
export function useRemovePrimaryImageMutation(baseOptions?: Apollo.MutationHookOptions<RemovePrimaryImageMutation, RemovePrimaryImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePrimaryImageMutation, RemovePrimaryImageMutationVariables>(RemovePrimaryImageDocument, options);
      }
export type RemovePrimaryImageMutationHookResult = ReturnType<typeof useRemovePrimaryImageMutation>;
export type RemovePrimaryImageMutationResult = Apollo.MutationResult<RemovePrimaryImageMutation>;
export type RemovePrimaryImageMutationOptions = Apollo.BaseMutationOptions<RemovePrimaryImageMutation, RemovePrimaryImageMutationVariables>;
export const UpdateAuctionDocument = gql`
    mutation UpdateAuction($id: Int!, $values: AuctionPatch!) {
  updateAuctionById(input: {id: $id, auctionPatch: $values}) {
    auction {
      ...AuctionEntry
    }
  }
}
    ${AuctionEntryFragmentDoc}`;
export type UpdateAuctionMutationFn = Apollo.MutationFunction<UpdateAuctionMutation, UpdateAuctionMutationVariables>;

/**
 * __useUpdateAuctionMutation__
 *
 * To run a mutation, you first call `useUpdateAuctionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAuctionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAuctionMutation, { data, loading, error }] = useUpdateAuctionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateAuctionMutation(baseOptions?: Apollo.MutationHookOptions<UpdateAuctionMutation, UpdateAuctionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateAuctionMutation, UpdateAuctionMutationVariables>(UpdateAuctionDocument, options);
      }
export type UpdateAuctionMutationHookResult = ReturnType<typeof useUpdateAuctionMutation>;
export type UpdateAuctionMutationResult = Apollo.MutationResult<UpdateAuctionMutation>;
export type UpdateAuctionMutationOptions = Apollo.BaseMutationOptions<UpdateAuctionMutation, UpdateAuctionMutationVariables>;
export const EmailAuctionInvoicesDocument = gql`
    mutation EmailAuctionInvoices($id: Int!) {
  emailAuctionInvoices(input: {auctionId: $id}) {
    clientMutationId
  }
}
    `;
export type EmailAuctionInvoicesMutationFn = Apollo.MutationFunction<EmailAuctionInvoicesMutation, EmailAuctionInvoicesMutationVariables>;

/**
 * __useEmailAuctionInvoicesMutation__
 *
 * To run a mutation, you first call `useEmailAuctionInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailAuctionInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailAuctionInvoicesMutation, { data, loading, error }] = useEmailAuctionInvoicesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailAuctionInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<EmailAuctionInvoicesMutation, EmailAuctionInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailAuctionInvoicesMutation, EmailAuctionInvoicesMutationVariables>(EmailAuctionInvoicesDocument, options);
      }
export type EmailAuctionInvoicesMutationHookResult = ReturnType<typeof useEmailAuctionInvoicesMutation>;
export type EmailAuctionInvoicesMutationResult = Apollo.MutationResult<EmailAuctionInvoicesMutation>;
export type EmailAuctionInvoicesMutationOptions = Apollo.BaseMutationOptions<EmailAuctionInvoicesMutation, EmailAuctionInvoicesMutationVariables>;
export const TotalAuctionUnpaidInvoicesDocument = gql`
    query totalAuctionUnpaidInvoices($auctionId: Int!) {
  totalAuctionUnpaidInvoices(auctionId: $auctionId)
}
    `;

/**
 * __useTotalAuctionUnpaidInvoicesQuery__
 *
 * To run a query within a React component, call `useTotalAuctionUnpaidInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalAuctionUnpaidInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalAuctionUnpaidInvoicesQuery({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useTotalAuctionUnpaidInvoicesQuery(baseOptions: Apollo.QueryHookOptions<TotalAuctionUnpaidInvoicesQuery, TotalAuctionUnpaidInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TotalAuctionUnpaidInvoicesQuery, TotalAuctionUnpaidInvoicesQueryVariables>(TotalAuctionUnpaidInvoicesDocument, options);
      }
export function useTotalAuctionUnpaidInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TotalAuctionUnpaidInvoicesQuery, TotalAuctionUnpaidInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TotalAuctionUnpaidInvoicesQuery, TotalAuctionUnpaidInvoicesQueryVariables>(TotalAuctionUnpaidInvoicesDocument, options);
        }
export type TotalAuctionUnpaidInvoicesQueryHookResult = ReturnType<typeof useTotalAuctionUnpaidInvoicesQuery>;
export type TotalAuctionUnpaidInvoicesLazyQueryHookResult = ReturnType<typeof useTotalAuctionUnpaidInvoicesLazyQuery>;
export type TotalAuctionUnpaidInvoicesQueryResult = Apollo.QueryResult<TotalAuctionUnpaidInvoicesQuery, TotalAuctionUnpaidInvoicesQueryVariables>;
export const AuctionItemImagesDocument = gql`
    query AuctionItemImages($auctionId: Int!) {
  allItems(condition: {auctionId: $auctionId}) {
    nodes {
      ...AuctionItemImageEntry
    }
  }
  auctionById(id: $auctionId) {
    ...AuctionEntry
  }
}
    ${AuctionItemImageEntryFragmentDoc}
${AuctionEntryFragmentDoc}`;

/**
 * __useAuctionItemImagesQuery__
 *
 * To run a query within a React component, call `useAuctionItemImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionItemImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionItemImagesQuery({
 *   variables: {
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useAuctionItemImagesQuery(baseOptions: Apollo.QueryHookOptions<AuctionItemImagesQuery, AuctionItemImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuctionItemImagesQuery, AuctionItemImagesQueryVariables>(AuctionItemImagesDocument, options);
      }
export function useAuctionItemImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuctionItemImagesQuery, AuctionItemImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuctionItemImagesQuery, AuctionItemImagesQueryVariables>(AuctionItemImagesDocument, options);
        }
export type AuctionItemImagesQueryHookResult = ReturnType<typeof useAuctionItemImagesQuery>;
export type AuctionItemImagesLazyQueryHookResult = ReturnType<typeof useAuctionItemImagesLazyQuery>;
export type AuctionItemImagesQueryResult = Apollo.QueryResult<AuctionItemImagesQuery, AuctionItemImagesQueryVariables>;
export const AuctionsPendingChangesDocument = gql`
    query AuctionsPendingChanges($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [AuctionChangesViewsOrderBy!], $filter: AuctionChangesViewFilter) {
  allAuctionChangesViews(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AuctionPendingChangesEntry
    }
  }
}
    ${AuctionPendingChangesEntryFragmentDoc}`;

/**
 * __useAuctionsPendingChangesQuery__
 *
 * To run a query within a React component, call `useAuctionsPendingChangesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAuctionsPendingChangesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAuctionsPendingChangesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAuctionsPendingChangesQuery(baseOptions?: Apollo.QueryHookOptions<AuctionsPendingChangesQuery, AuctionsPendingChangesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AuctionsPendingChangesQuery, AuctionsPendingChangesQueryVariables>(AuctionsPendingChangesDocument, options);
      }
export function useAuctionsPendingChangesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AuctionsPendingChangesQuery, AuctionsPendingChangesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AuctionsPendingChangesQuery, AuctionsPendingChangesQueryVariables>(AuctionsPendingChangesDocument, options);
        }
export type AuctionsPendingChangesQueryHookResult = ReturnType<typeof useAuctionsPendingChangesQuery>;
export type AuctionsPendingChangesLazyQueryHookResult = ReturnType<typeof useAuctionsPendingChangesLazyQuery>;
export type AuctionsPendingChangesQueryResult = Apollo.QueryResult<AuctionsPendingChangesQuery, AuctionsPendingChangesQueryVariables>;
export const DeleteAuctionPushByAuctionIdDocument = gql`
    mutation DeleteAuctionPushByAuctionId($input: DeleteAuctionPushByAuctionIdInput!) {
  deleteAuctionPushByAuctionId(input: $input) {
    clientMutationId
  }
}
    `;
export type DeleteAuctionPushByAuctionIdMutationFn = Apollo.MutationFunction<DeleteAuctionPushByAuctionIdMutation, DeleteAuctionPushByAuctionIdMutationVariables>;

/**
 * __useDeleteAuctionPushByAuctionIdMutation__
 *
 * To run a mutation, you first call `useDeleteAuctionPushByAuctionIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAuctionPushByAuctionIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAuctionPushByAuctionIdMutation, { data, loading, error }] = useDeleteAuctionPushByAuctionIdMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAuctionPushByAuctionIdMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAuctionPushByAuctionIdMutation, DeleteAuctionPushByAuctionIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAuctionPushByAuctionIdMutation, DeleteAuctionPushByAuctionIdMutationVariables>(DeleteAuctionPushByAuctionIdDocument, options);
      }
export type DeleteAuctionPushByAuctionIdMutationHookResult = ReturnType<typeof useDeleteAuctionPushByAuctionIdMutation>;
export type DeleteAuctionPushByAuctionIdMutationResult = Apollo.MutationResult<DeleteAuctionPushByAuctionIdMutation>;
export type DeleteAuctionPushByAuctionIdMutationOptions = Apollo.BaseMutationOptions<DeleteAuctionPushByAuctionIdMutation, DeleteAuctionPushByAuctionIdMutationVariables>;
export const GetLastPickupDateConfigForAuctionDocument = gql`
    query GetLastPickupDateConfigForAuction($timestamp: Datetime!, $locationId: Int!) {
  getLastPickupDateConfigForAuction(ts: $timestamp, locationId: $locationId)
}
    `;

/**
 * __useGetLastPickupDateConfigForAuctionQuery__
 *
 * To run a query within a React component, call `useGetLastPickupDateConfigForAuctionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLastPickupDateConfigForAuctionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLastPickupDateConfigForAuctionQuery({
 *   variables: {
 *      timestamp: // value for 'timestamp'
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLastPickupDateConfigForAuctionQuery(baseOptions: Apollo.QueryHookOptions<GetLastPickupDateConfigForAuctionQuery, GetLastPickupDateConfigForAuctionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLastPickupDateConfigForAuctionQuery, GetLastPickupDateConfigForAuctionQueryVariables>(GetLastPickupDateConfigForAuctionDocument, options);
      }
export function useGetLastPickupDateConfigForAuctionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLastPickupDateConfigForAuctionQuery, GetLastPickupDateConfigForAuctionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLastPickupDateConfigForAuctionQuery, GetLastPickupDateConfigForAuctionQueryVariables>(GetLastPickupDateConfigForAuctionDocument, options);
        }
export type GetLastPickupDateConfigForAuctionQueryHookResult = ReturnType<typeof useGetLastPickupDateConfigForAuctionQuery>;
export type GetLastPickupDateConfigForAuctionLazyQueryHookResult = ReturnType<typeof useGetLastPickupDateConfigForAuctionLazyQuery>;
export type GetLastPickupDateConfigForAuctionQueryResult = Apollo.QueryResult<GetLastPickupDateConfigForAuctionQuery, GetLastPickupDateConfigForAuctionQueryVariables>;
export const GetAuthDocument = gql`
    query GetAuth {
  auth @client {
    isInitialized
    refreshToken
    accessToken
  }
}
    `;

/**
 * __useGetAuthQuery__
 *
 * To run a query within a React component, call `useGetAuthQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAuthQuery(baseOptions?: Apollo.QueryHookOptions<GetAuthQuery, GetAuthQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthQuery, GetAuthQueryVariables>(GetAuthDocument, options);
      }
export function useGetAuthLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthQuery, GetAuthQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthQuery, GetAuthQueryVariables>(GetAuthDocument, options);
        }
export type GetAuthQueryHookResult = ReturnType<typeof useGetAuthQuery>;
export type GetAuthLazyQueryHookResult = ReturnType<typeof useGetAuthLazyQuery>;
export type GetAuthQueryResult = Apollo.QueryResult<GetAuthQuery, GetAuthQueryVariables>;
export const LoginByUsernameAndPasswordDocument = gql`
    mutation LoginByUsernameAndPassword($username: String!, $password: String!) {
  loginByUsernameAndPassword(input: {username: $username, password: $password}) {
    credentials {
      ...Credentials
    }
  }
}
    ${CredentialsFragmentDoc}`;
export type LoginByUsernameAndPasswordMutationFn = Apollo.MutationFunction<LoginByUsernameAndPasswordMutation, LoginByUsernameAndPasswordMutationVariables>;

/**
 * __useLoginByUsernameAndPasswordMutation__
 *
 * To run a mutation, you first call `useLoginByUsernameAndPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByUsernameAndPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByUsernameAndPasswordMutation, { data, loading, error }] = useLoginByUsernameAndPasswordMutation({
 *   variables: {
 *      username: // value for 'username'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginByUsernameAndPasswordMutation(baseOptions?: Apollo.MutationHookOptions<LoginByUsernameAndPasswordMutation, LoginByUsernameAndPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByUsernameAndPasswordMutation, LoginByUsernameAndPasswordMutationVariables>(LoginByUsernameAndPasswordDocument, options);
      }
export type LoginByUsernameAndPasswordMutationHookResult = ReturnType<typeof useLoginByUsernameAndPasswordMutation>;
export type LoginByUsernameAndPasswordMutationResult = Apollo.MutationResult<LoginByUsernameAndPasswordMutation>;
export type LoginByUsernameAndPasswordMutationOptions = Apollo.BaseMutationOptions<LoginByUsernameAndPasswordMutation, LoginByUsernameAndPasswordMutationVariables>;
export const LoginByRefreshTokenDocument = gql`
    mutation LoginByRefreshToken($refreshToken: String!) {
  loginByRefreshToken(input: {refreshToken: $refreshToken}) {
    credentials {
      ...Credentials
    }
  }
}
    ${CredentialsFragmentDoc}`;
export type LoginByRefreshTokenMutationFn = Apollo.MutationFunction<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>;

/**
 * __useLoginByRefreshTokenMutation__
 *
 * To run a mutation, you first call `useLoginByRefreshTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginByRefreshTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginByRefreshTokenMutation, { data, loading, error }] = useLoginByRefreshTokenMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *   },
 * });
 */
export function useLoginByRefreshTokenMutation(baseOptions?: Apollo.MutationHookOptions<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>(LoginByRefreshTokenDocument, options);
      }
export type LoginByRefreshTokenMutationHookResult = ReturnType<typeof useLoginByRefreshTokenMutation>;
export type LoginByRefreshTokenMutationResult = Apollo.MutationResult<LoginByRefreshTokenMutation>;
export type LoginByRefreshTokenMutationOptions = Apollo.BaseMutationOptions<LoginByRefreshTokenMutation, LoginByRefreshTokenMutationVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const AllBiddersDocument = gql`
    query AllBidders($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [BiddersOrderBy!], $filter: BidderFilter) {
  allBidders(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...BidderEntry
    }
  }
}
    ${BidderEntryFragmentDoc}`;

/**
 * __useAllBiddersQuery__
 *
 * To run a query within a React component, call `useAllBiddersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllBiddersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllBiddersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllBiddersQuery(baseOptions?: Apollo.QueryHookOptions<AllBiddersQuery, AllBiddersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllBiddersQuery, AllBiddersQueryVariables>(AllBiddersDocument, options);
      }
export function useAllBiddersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllBiddersQuery, AllBiddersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllBiddersQuery, AllBiddersQueryVariables>(AllBiddersDocument, options);
        }
export type AllBiddersQueryHookResult = ReturnType<typeof useAllBiddersQuery>;
export type AllBiddersLazyQueryHookResult = ReturnType<typeof useAllBiddersLazyQuery>;
export type AllBiddersQueryResult = Apollo.QueryResult<AllBiddersQuery, AllBiddersQueryVariables>;
export const GetBidderByIdDocument = gql`
    query GetBidderById($id: Int!) {
  bidderById(id: $id) {
    ...BidderEntry
    customerNotesByCustomerId(orderBy: CREATED_AT_DESC) {
      nodes {
        ...CustomerNoteEntry
      }
    }
  }
}
    ${BidderEntryFragmentDoc}
${CustomerNoteEntryFragmentDoc}`;

/**
 * __useGetBidderByIdQuery__
 *
 * To run a query within a React component, call `useGetBidderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBidderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBidderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetBidderByIdQuery(baseOptions: Apollo.QueryHookOptions<GetBidderByIdQuery, GetBidderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBidderByIdQuery, GetBidderByIdQueryVariables>(GetBidderByIdDocument, options);
      }
export function useGetBidderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBidderByIdQuery, GetBidderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBidderByIdQuery, GetBidderByIdQueryVariables>(GetBidderByIdDocument, options);
        }
export type GetBidderByIdQueryHookResult = ReturnType<typeof useGetBidderByIdQuery>;
export type GetBidderByIdLazyQueryHookResult = ReturnType<typeof useGetBidderByIdLazyQuery>;
export type GetBidderByIdQueryResult = Apollo.QueryResult<GetBidderByIdQuery, GetBidderByIdQueryVariables>;
export const UpdateBidderDocument = gql`
    mutation UpdateBidder($id: Int!, $values: BidderPatch!) {
  updateBidderById(input: {id: $id, bidderPatch: $values}) {
    clientMutationId
  }
}
    `;
export type UpdateBidderMutationFn = Apollo.MutationFunction<UpdateBidderMutation, UpdateBidderMutationVariables>;

/**
 * __useUpdateBidderMutation__
 *
 * To run a mutation, you first call `useUpdateBidderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBidderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBidderMutation, { data, loading, error }] = useUpdateBidderMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateBidderMutation(baseOptions?: Apollo.MutationHookOptions<UpdateBidderMutation, UpdateBidderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateBidderMutation, UpdateBidderMutationVariables>(UpdateBidderDocument, options);
      }
export type UpdateBidderMutationHookResult = ReturnType<typeof useUpdateBidderMutation>;
export type UpdateBidderMutationResult = Apollo.MutationResult<UpdateBidderMutation>;
export type UpdateBidderMutationOptions = Apollo.BaseMutationOptions<UpdateBidderMutation, UpdateBidderMutationVariables>;
export const SetCollectPaymentBiddersDocument = gql`
    mutation SetCollectPaymentBidders($bidderId: Int!, $processInvoicePayment: Boolean!, $reason: String!) {
  setProcessInvoicePayment(
    input: {bidderId: $bidderId, isCollect: $processInvoicePayment, reason: $reason}
  ) {
    bidder {
      ...BidderEntry
    }
  }
}
    ${BidderEntryFragmentDoc}`;
export type SetCollectPaymentBiddersMutationFn = Apollo.MutationFunction<SetCollectPaymentBiddersMutation, SetCollectPaymentBiddersMutationVariables>;

/**
 * __useSetCollectPaymentBiddersMutation__
 *
 * To run a mutation, you first call `useSetCollectPaymentBiddersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCollectPaymentBiddersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCollectPaymentBiddersMutation, { data, loading, error }] = useSetCollectPaymentBiddersMutation({
 *   variables: {
 *      bidderId: // value for 'bidderId'
 *      processInvoicePayment: // value for 'processInvoicePayment'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useSetCollectPaymentBiddersMutation(baseOptions?: Apollo.MutationHookOptions<SetCollectPaymentBiddersMutation, SetCollectPaymentBiddersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetCollectPaymentBiddersMutation, SetCollectPaymentBiddersMutationVariables>(SetCollectPaymentBiddersDocument, options);
      }
export type SetCollectPaymentBiddersMutationHookResult = ReturnType<typeof useSetCollectPaymentBiddersMutation>;
export type SetCollectPaymentBiddersMutationResult = Apollo.MutationResult<SetCollectPaymentBiddersMutation>;
export type SetCollectPaymentBiddersMutationOptions = Apollo.BaseMutationOptions<SetCollectPaymentBiddersMutation, SetCollectPaymentBiddersMutationVariables>;
export const CreateCustomerNoteDocument = gql`
    mutation CreateCustomerNote($input: CreateCustomerNoteInput!) {
  createCustomerNote(input: $input) {
    customerNote {
      ...CustomerNoteEntry
    }
  }
}
    ${CustomerNoteEntryFragmentDoc}`;
export type CreateCustomerNoteMutationFn = Apollo.MutationFunction<CreateCustomerNoteMutation, CreateCustomerNoteMutationVariables>;

/**
 * __useCreateCustomerNoteMutation__
 *
 * To run a mutation, you first call `useCreateCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerNoteMutation, { data, loading, error }] = useCreateCustomerNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCustomerNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateCustomerNoteMutation, CreateCustomerNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCustomerNoteMutation, CreateCustomerNoteMutationVariables>(CreateCustomerNoteDocument, options);
      }
export type CreateCustomerNoteMutationHookResult = ReturnType<typeof useCreateCustomerNoteMutation>;
export type CreateCustomerNoteMutationResult = Apollo.MutationResult<CreateCustomerNoteMutation>;
export type CreateCustomerNoteMutationOptions = Apollo.BaseMutationOptions<CreateCustomerNoteMutation, CreateCustomerNoteMutationVariables>;
export const DeleteCustomerNoteDocument = gql`
    mutation DeleteCustomerNote($input: DeleteCustomerNoteByIdInput!) {
  deleteCustomerNoteById(input: $input) {
    clientMutationId
  }
}
    `;
export type DeleteCustomerNoteMutationFn = Apollo.MutationFunction<DeleteCustomerNoteMutation, DeleteCustomerNoteMutationVariables>;

/**
 * __useDeleteCustomerNoteMutation__
 *
 * To run a mutation, you first call `useDeleteCustomerNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomerNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomerNoteMutation, { data, loading, error }] = useDeleteCustomerNoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCustomerNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCustomerNoteMutation, DeleteCustomerNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCustomerNoteMutation, DeleteCustomerNoteMutationVariables>(DeleteCustomerNoteDocument, options);
      }
export type DeleteCustomerNoteMutationHookResult = ReturnType<typeof useDeleteCustomerNoteMutation>;
export type DeleteCustomerNoteMutationResult = Apollo.MutationResult<DeleteCustomerNoteMutation>;
export type DeleteCustomerNoteMutationOptions = Apollo.BaseMutationOptions<DeleteCustomerNoteMutation, DeleteCustomerNoteMutationVariables>;
export const GetCustomerNotesDocument = gql`
    query GetCustomerNotes($customerId: Int!) {
  allCustomerNotes(condition: {customerId: $customerId}, orderBy: CREATED_AT_DESC) {
    nodes {
      ...CustomerNoteEntry
    }
  }
}
    ${CustomerNoteEntryFragmentDoc}`;

/**
 * __useGetCustomerNotesQuery__
 *
 * To run a query within a React component, call `useGetCustomerNotesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomerNotesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomerNotesQuery({
 *   variables: {
 *      customerId: // value for 'customerId'
 *   },
 * });
 */
export function useGetCustomerNotesQuery(baseOptions: Apollo.QueryHookOptions<GetCustomerNotesQuery, GetCustomerNotesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCustomerNotesQuery, GetCustomerNotesQueryVariables>(GetCustomerNotesDocument, options);
      }
export function useGetCustomerNotesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCustomerNotesQuery, GetCustomerNotesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCustomerNotesQuery, GetCustomerNotesQueryVariables>(GetCustomerNotesDocument, options);
        }
export type GetCustomerNotesQueryHookResult = ReturnType<typeof useGetCustomerNotesQuery>;
export type GetCustomerNotesLazyQueryHookResult = ReturnType<typeof useGetCustomerNotesLazyQuery>;
export type GetCustomerNotesQueryResult = Apollo.QueryResult<GetCustomerNotesQuery, GetCustomerNotesQueryVariables>;
export const AllCustomerActionsDocument = gql`
    query AllCustomerActions($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [CustomerActionsViewsOrderBy!], $filter: CustomerActionsViewFilter) {
  allCustomerActionsViews(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...CustomerActionView
    }
  }
}
    ${CustomerActionViewFragmentDoc}`;

/**
 * __useAllCustomerActionsQuery__
 *
 * To run a query within a React component, call `useAllCustomerActionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomerActionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomerActionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllCustomerActionsQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomerActionsQuery, AllCustomerActionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomerActionsQuery, AllCustomerActionsQueryVariables>(AllCustomerActionsDocument, options);
      }
export function useAllCustomerActionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomerActionsQuery, AllCustomerActionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomerActionsQuery, AllCustomerActionsQueryVariables>(AllCustomerActionsDocument, options);
        }
export type AllCustomerActionsQueryHookResult = ReturnType<typeof useAllCustomerActionsQuery>;
export type AllCustomerActionsLazyQueryHookResult = ReturnType<typeof useAllCustomerActionsLazyQuery>;
export type AllCustomerActionsQueryResult = Apollo.QueryResult<AllCustomerActionsQuery, AllCustomerActionsQueryVariables>;
export const AllCustomerAlertsDocument = gql`
    query AllCustomerAlerts($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [CustomerAlertsViewsOrderBy!], $filter: CustomerAlertsViewFilter) {
  allCustomerAlertsViews(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...CustomerAlertView
    }
  }
}
    ${CustomerAlertViewFragmentDoc}`;

/**
 * __useAllCustomerAlertsQuery__
 *
 * To run a query within a React component, call `useAllCustomerAlertsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllCustomerAlertsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllCustomerAlertsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllCustomerAlertsQuery(baseOptions?: Apollo.QueryHookOptions<AllCustomerAlertsQuery, AllCustomerAlertsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllCustomerAlertsQuery, AllCustomerAlertsQueryVariables>(AllCustomerAlertsDocument, options);
      }
export function useAllCustomerAlertsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllCustomerAlertsQuery, AllCustomerAlertsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllCustomerAlertsQuery, AllCustomerAlertsQueryVariables>(AllCustomerAlertsDocument, options);
        }
export type AllCustomerAlertsQueryHookResult = ReturnType<typeof useAllCustomerAlertsQuery>;
export type AllCustomerAlertsLazyQueryHookResult = ReturnType<typeof useAllCustomerAlertsLazyQuery>;
export type AllCustomerAlertsQueryResult = Apollo.QueryResult<AllCustomerAlertsQuery, AllCustomerAlertsQueryVariables>;
export const ResyncCustomerFromBidwranglerDocument = gql`
    mutation ResyncCustomerFromBidwrangler($input: ResyncCustomerFromBidwranglerInput!) {
  resyncCustomerFromBidwrangler(input: $input) {
    clientMutationId
  }
}
    `;
export type ResyncCustomerFromBidwranglerMutationFn = Apollo.MutationFunction<ResyncCustomerFromBidwranglerMutation, ResyncCustomerFromBidwranglerMutationVariables>;

/**
 * __useResyncCustomerFromBidwranglerMutation__
 *
 * To run a mutation, you first call `useResyncCustomerFromBidwranglerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResyncCustomerFromBidwranglerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resyncCustomerFromBidwranglerMutation, { data, loading, error }] = useResyncCustomerFromBidwranglerMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResyncCustomerFromBidwranglerMutation(baseOptions?: Apollo.MutationHookOptions<ResyncCustomerFromBidwranglerMutation, ResyncCustomerFromBidwranglerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResyncCustomerFromBidwranglerMutation, ResyncCustomerFromBidwranglerMutationVariables>(ResyncCustomerFromBidwranglerDocument, options);
      }
export type ResyncCustomerFromBidwranglerMutationHookResult = ReturnType<typeof useResyncCustomerFromBidwranglerMutation>;
export type ResyncCustomerFromBidwranglerMutationResult = Apollo.MutationResult<ResyncCustomerFromBidwranglerMutation>;
export type ResyncCustomerFromBidwranglerMutationOptions = Apollo.BaseMutationOptions<ResyncCustomerFromBidwranglerMutation, ResyncCustomerFromBidwranglerMutationVariables>;
export const BidderCreditHistoryDocument = gql`
    query BidderCreditHistory($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [BidderCreditHistoriesOrderBy!], $filter: BidderCreditHistoryFilter) {
  allBidderCreditHistories(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...BidderCreditHistoryEntry
    }
  }
}
    ${BidderCreditHistoryEntryFragmentDoc}`;

/**
 * __useBidderCreditHistoryQuery__
 *
 * To run a query within a React component, call `useBidderCreditHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidderCreditHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidderCreditHistoryQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBidderCreditHistoryQuery(baseOptions?: Apollo.QueryHookOptions<BidderCreditHistoryQuery, BidderCreditHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BidderCreditHistoryQuery, BidderCreditHistoryQueryVariables>(BidderCreditHistoryDocument, options);
      }
export function useBidderCreditHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BidderCreditHistoryQuery, BidderCreditHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BidderCreditHistoryQuery, BidderCreditHistoryQueryVariables>(BidderCreditHistoryDocument, options);
        }
export type BidderCreditHistoryQueryHookResult = ReturnType<typeof useBidderCreditHistoryQuery>;
export type BidderCreditHistoryLazyQueryHookResult = ReturnType<typeof useBidderCreditHistoryLazyQuery>;
export type BidderCreditHistoryQueryResult = Apollo.QueryResult<BidderCreditHistoryQuery, BidderCreditHistoryQueryVariables>;
export const AdjustBidderCreditsDocument = gql`
    mutation AdjustBidderCredits($values: AdjustBidderCreditsInput!) {
  adjustBidderCredits(input: $values) {
    bidderByBidderId {
      creditBalance
    }
    bidderCreditHistory {
      ...BidderCreditHistoryEntry
    }
  }
}
    ${BidderCreditHistoryEntryFragmentDoc}`;
export type AdjustBidderCreditsMutationFn = Apollo.MutationFunction<AdjustBidderCreditsMutation, AdjustBidderCreditsMutationVariables>;

/**
 * __useAdjustBidderCreditsMutation__
 *
 * To run a mutation, you first call `useAdjustBidderCreditsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAdjustBidderCreditsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [adjustBidderCreditsMutation, { data, loading, error }] = useAdjustBidderCreditsMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useAdjustBidderCreditsMutation(baseOptions?: Apollo.MutationHookOptions<AdjustBidderCreditsMutation, AdjustBidderCreditsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AdjustBidderCreditsMutation, AdjustBidderCreditsMutationVariables>(AdjustBidderCreditsDocument, options);
      }
export type AdjustBidderCreditsMutationHookResult = ReturnType<typeof useAdjustBidderCreditsMutation>;
export type AdjustBidderCreditsMutationResult = Apollo.MutationResult<AdjustBidderCreditsMutation>;
export type AdjustBidderCreditsMutationOptions = Apollo.BaseMutationOptions<AdjustBidderCreditsMutation, AdjustBidderCreditsMutationVariables>;
export const EmployeeStatsDocument = gql`
    query EmployeeStats($locationId: Int!) {
  allEmployeeStats(
    filter: {locationId: {equalTo: $locationId}}
    orderBy: LAST_60_TOTAL_ITEMS_DESC
  ) {
    nodes {
      ...EmployeeStatFragment
    }
  }
}
    ${EmployeeStatFragmentFragmentDoc}`;

/**
 * __useEmployeeStatsQuery__
 *
 * To run a query within a React component, call `useEmployeeStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmployeeStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmployeeStatsQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useEmployeeStatsQuery(baseOptions: Apollo.QueryHookOptions<EmployeeStatsQuery, EmployeeStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmployeeStatsQuery, EmployeeStatsQueryVariables>(EmployeeStatsDocument, options);
      }
export function useEmployeeStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmployeeStatsQuery, EmployeeStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmployeeStatsQuery, EmployeeStatsQueryVariables>(EmployeeStatsDocument, options);
        }
export type EmployeeStatsQueryHookResult = ReturnType<typeof useEmployeeStatsQuery>;
export type EmployeeStatsLazyQueryHookResult = ReturnType<typeof useEmployeeStatsLazyQuery>;
export type EmployeeStatsQueryResult = Apollo.QueryResult<EmployeeStatsQuery, EmployeeStatsQueryVariables>;
export const GetInvoiceByIdDocument = gql`
    query GetInvoiceById($id: Int!) {
  invoiceById(id: $id) {
    ...GetInvoiceByIdEntry
  }
}
    ${GetInvoiceByIdEntryFragmentDoc}`;

/**
 * __useGetInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useGetInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceByIdQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(GetInvoiceByIdDocument, options);
      }
export function useGetInvoiceByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>(GetInvoiceByIdDocument, options);
        }
export type GetInvoiceByIdQueryHookResult = ReturnType<typeof useGetInvoiceByIdQuery>;
export type GetInvoiceByIdLazyQueryHookResult = ReturnType<typeof useGetInvoiceByIdLazyQuery>;
export type GetInvoiceByIdQueryResult = Apollo.QueryResult<GetInvoiceByIdQuery, GetInvoiceByIdQueryVariables>;
export const AllInvoicesDocument = gql`
    query AllInvoices($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [InvoicesOrderBy!], $filter: InvoiceFilter) {
  allInvoices(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllInvoiceEntry
    }
  }
}
    ${AllInvoiceEntryFragmentDoc}`;

/**
 * __useAllInvoicesQuery__
 *
 * To run a query within a React component, call `useAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvoicesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<AllInvoicesQuery, AllInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllInvoicesQuery, AllInvoicesQueryVariables>(AllInvoicesDocument, options);
      }
export function useAllInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllInvoicesQuery, AllInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllInvoicesQuery, AllInvoicesQueryVariables>(AllInvoicesDocument, options);
        }
export type AllInvoicesQueryHookResult = ReturnType<typeof useAllInvoicesQuery>;
export type AllInvoicesLazyQueryHookResult = ReturnType<typeof useAllInvoicesLazyQuery>;
export type AllInvoicesQueryResult = Apollo.QueryResult<AllInvoicesQuery, AllInvoicesQueryVariables>;
export const ProcessInvoicePaymentDocument = gql`
    mutation processInvoicePayment($invoiceId: Int!, $processPayment: Boolean!, $reason: String!) {
  setProcessPayment(
    input: {invoiceId: $invoiceId, isCollect: $processPayment, reason: $reason}
  ) {
    invoice {
      ...InvoiceEntry
    }
  }
}
    ${InvoiceEntryFragmentDoc}`;
export type ProcessInvoicePaymentMutationFn = Apollo.MutationFunction<ProcessInvoicePaymentMutation, ProcessInvoicePaymentMutationVariables>;

/**
 * __useProcessInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useProcessInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useProcessInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [processInvoicePaymentMutation, { data, loading, error }] = useProcessInvoicePaymentMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      processPayment: // value for 'processPayment'
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useProcessInvoicePaymentMutation(baseOptions?: Apollo.MutationHookOptions<ProcessInvoicePaymentMutation, ProcessInvoicePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ProcessInvoicePaymentMutation, ProcessInvoicePaymentMutationVariables>(ProcessInvoicePaymentDocument, options);
      }
export type ProcessInvoicePaymentMutationHookResult = ReturnType<typeof useProcessInvoicePaymentMutation>;
export type ProcessInvoicePaymentMutationResult = Apollo.MutationResult<ProcessInvoicePaymentMutation>;
export type ProcessInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<ProcessInvoicePaymentMutation, ProcessInvoicePaymentMutationVariables>;
export const AllAuctionInvoicesDocument = gql`
    query AllAuctionInvoices($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [AuctionInvoicesViewsOrderBy!], $filter: AuctionInvoicesViewFilter) {
  allAuctionInvoicesViews(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllAuctionInvoicesEntry
    }
  }
}
    ${AllAuctionInvoicesEntryFragmentDoc}`;

/**
 * __useAllAuctionInvoicesQuery__
 *
 * To run a query within a React component, call `useAllAuctionInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAuctionInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAuctionInvoicesQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllAuctionInvoicesQuery(baseOptions?: Apollo.QueryHookOptions<AllAuctionInvoicesQuery, AllAuctionInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAuctionInvoicesQuery, AllAuctionInvoicesQueryVariables>(AllAuctionInvoicesDocument, options);
      }
export function useAllAuctionInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAuctionInvoicesQuery, AllAuctionInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAuctionInvoicesQuery, AllAuctionInvoicesQueryVariables>(AllAuctionInvoicesDocument, options);
        }
export type AllAuctionInvoicesQueryHookResult = ReturnType<typeof useAllAuctionInvoicesQuery>;
export type AllAuctionInvoicesLazyQueryHookResult = ReturnType<typeof useAllAuctionInvoicesLazyQuery>;
export type AllAuctionInvoicesQueryResult = Apollo.QueryResult<AllAuctionInvoicesQuery, AllAuctionInvoicesQueryVariables>;
export const EmailInvoiceDocument = gql`
    mutation EmailInvoice($id: Int!) {
  emailInvoice(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type EmailInvoiceMutationFn = Apollo.MutationFunction<EmailInvoiceMutation, EmailInvoiceMutationVariables>;

/**
 * __useEmailInvoiceMutation__
 *
 * To run a mutation, you first call `useEmailInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEmailInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [emailInvoiceMutation, { data, loading, error }] = useEmailInvoiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEmailInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<EmailInvoiceMutation, EmailInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EmailInvoiceMutation, EmailInvoiceMutationVariables>(EmailInvoiceDocument, options);
      }
export type EmailInvoiceMutationHookResult = ReturnType<typeof useEmailInvoiceMutation>;
export type EmailInvoiceMutationResult = Apollo.MutationResult<EmailInvoiceMutation>;
export type EmailInvoiceMutationOptions = Apollo.BaseMutationOptions<EmailInvoiceMutation, EmailInvoiceMutationVariables>;
export const SetInvoiceLastPickupDateDocument = gql`
    mutation SetInvoiceLastPickupDate($invoiceId: Int!, $lastPickupDate: Date!) {
  setInvoiceLastPickupDate(
    input: {invoiceId: $invoiceId, lastPickupDate: $lastPickupDate}
  ) {
    clientMutationId
  }
}
    `;
export type SetInvoiceLastPickupDateMutationFn = Apollo.MutationFunction<SetInvoiceLastPickupDateMutation, SetInvoiceLastPickupDateMutationVariables>;

/**
 * __useSetInvoiceLastPickupDateMutation__
 *
 * To run a mutation, you first call `useSetInvoiceLastPickupDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInvoiceLastPickupDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInvoiceLastPickupDateMutation, { data, loading, error }] = useSetInvoiceLastPickupDateMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      lastPickupDate: // value for 'lastPickupDate'
 *   },
 * });
 */
export function useSetInvoiceLastPickupDateMutation(baseOptions?: Apollo.MutationHookOptions<SetInvoiceLastPickupDateMutation, SetInvoiceLastPickupDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetInvoiceLastPickupDateMutation, SetInvoiceLastPickupDateMutationVariables>(SetInvoiceLastPickupDateDocument, options);
      }
export type SetInvoiceLastPickupDateMutationHookResult = ReturnType<typeof useSetInvoiceLastPickupDateMutation>;
export type SetInvoiceLastPickupDateMutationResult = Apollo.MutationResult<SetInvoiceLastPickupDateMutation>;
export type SetInvoiceLastPickupDateMutationOptions = Apollo.BaseMutationOptions<SetInvoiceLastPickupDateMutation, SetInvoiceLastPickupDateMutationVariables>;
export const GetEligibleBatchProcessInvoicePaymentsDocument = gql`
    query GetEligibleBatchProcessInvoicePayments($invoiceIds: [Int]!) {
  getEligibleBatchProcessInvoicePayments(invoiceIds: $invoiceIds) {
    nodes {
      ...GetEligibleBatchProcessInvoicePaymentsEntry
    }
  }
}
    ${GetEligibleBatchProcessInvoicePaymentsEntryFragmentDoc}`;

/**
 * __useGetEligibleBatchProcessInvoicePaymentsQuery__
 *
 * To run a query within a React component, call `useGetEligibleBatchProcessInvoicePaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibleBatchProcessInvoicePaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibleBatchProcessInvoicePaymentsQuery({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *   },
 * });
 */
export function useGetEligibleBatchProcessInvoicePaymentsQuery(baseOptions: Apollo.QueryHookOptions<GetEligibleBatchProcessInvoicePaymentsQuery, GetEligibleBatchProcessInvoicePaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEligibleBatchProcessInvoicePaymentsQuery, GetEligibleBatchProcessInvoicePaymentsQueryVariables>(GetEligibleBatchProcessInvoicePaymentsDocument, options);
      }
export function useGetEligibleBatchProcessInvoicePaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibleBatchProcessInvoicePaymentsQuery, GetEligibleBatchProcessInvoicePaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEligibleBatchProcessInvoicePaymentsQuery, GetEligibleBatchProcessInvoicePaymentsQueryVariables>(GetEligibleBatchProcessInvoicePaymentsDocument, options);
        }
export type GetEligibleBatchProcessInvoicePaymentsQueryHookResult = ReturnType<typeof useGetEligibleBatchProcessInvoicePaymentsQuery>;
export type GetEligibleBatchProcessInvoicePaymentsLazyQueryHookResult = ReturnType<typeof useGetEligibleBatchProcessInvoicePaymentsLazyQuery>;
export type GetEligibleBatchProcessInvoicePaymentsQueryResult = Apollo.QueryResult<GetEligibleBatchProcessInvoicePaymentsQuery, GetEligibleBatchProcessInvoicePaymentsQueryVariables>;
export const BatchProcessInvoicePaymentsDocument = gql`
    mutation BatchProcessInvoicePayments($invoiceIds: [Int]!) {
  batchProcessInvoicePayments(input: {invoiceIds: $invoiceIds}) {
    clientMutationId
  }
}
    `;
export type BatchProcessInvoicePaymentsMutationFn = Apollo.MutationFunction<BatchProcessInvoicePaymentsMutation, BatchProcessInvoicePaymentsMutationVariables>;

/**
 * __useBatchProcessInvoicePaymentsMutation__
 *
 * To run a mutation, you first call `useBatchProcessInvoicePaymentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchProcessInvoicePaymentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchProcessInvoicePaymentsMutation, { data, loading, error }] = useBatchProcessInvoicePaymentsMutation({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *   },
 * });
 */
export function useBatchProcessInvoicePaymentsMutation(baseOptions?: Apollo.MutationHookOptions<BatchProcessInvoicePaymentsMutation, BatchProcessInvoicePaymentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchProcessInvoicePaymentsMutation, BatchProcessInvoicePaymentsMutationVariables>(BatchProcessInvoicePaymentsDocument, options);
      }
export type BatchProcessInvoicePaymentsMutationHookResult = ReturnType<typeof useBatchProcessInvoicePaymentsMutation>;
export type BatchProcessInvoicePaymentsMutationResult = Apollo.MutationResult<BatchProcessInvoicePaymentsMutation>;
export type BatchProcessInvoicePaymentsMutationOptions = Apollo.BaseMutationOptions<BatchProcessInvoicePaymentsMutation, BatchProcessInvoicePaymentsMutationVariables>;
export const GetEligibleBatchEmailInvoicesDocument = gql`
    query GetEligibleBatchEmailInvoices($invoiceIds: [Int]!) {
  getEligibleBatchEmailInvoices(invoiceIds: $invoiceIds) {
    nodes {
      ...GetEligibleBatchEmailInvoicesEntry
    }
  }
}
    ${GetEligibleBatchEmailInvoicesEntryFragmentDoc}`;

/**
 * __useGetEligibleBatchEmailInvoicesQuery__
 *
 * To run a query within a React component, call `useGetEligibleBatchEmailInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEligibleBatchEmailInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEligibleBatchEmailInvoicesQuery({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *   },
 * });
 */
export function useGetEligibleBatchEmailInvoicesQuery(baseOptions: Apollo.QueryHookOptions<GetEligibleBatchEmailInvoicesQuery, GetEligibleBatchEmailInvoicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEligibleBatchEmailInvoicesQuery, GetEligibleBatchEmailInvoicesQueryVariables>(GetEligibleBatchEmailInvoicesDocument, options);
      }
export function useGetEligibleBatchEmailInvoicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEligibleBatchEmailInvoicesQuery, GetEligibleBatchEmailInvoicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEligibleBatchEmailInvoicesQuery, GetEligibleBatchEmailInvoicesQueryVariables>(GetEligibleBatchEmailInvoicesDocument, options);
        }
export type GetEligibleBatchEmailInvoicesQueryHookResult = ReturnType<typeof useGetEligibleBatchEmailInvoicesQuery>;
export type GetEligibleBatchEmailInvoicesLazyQueryHookResult = ReturnType<typeof useGetEligibleBatchEmailInvoicesLazyQuery>;
export type GetEligibleBatchEmailInvoicesQueryResult = Apollo.QueryResult<GetEligibleBatchEmailInvoicesQuery, GetEligibleBatchEmailInvoicesQueryVariables>;
export const BatchEmailInvoicesDocument = gql`
    mutation BatchEmailInvoices($invoiceIds: [Int]!) {
  batchEmailInvoices(input: {invoiceIds: $invoiceIds}) {
    clientMutationId
  }
}
    `;
export type BatchEmailInvoicesMutationFn = Apollo.MutationFunction<BatchEmailInvoicesMutation, BatchEmailInvoicesMutationVariables>;

/**
 * __useBatchEmailInvoicesMutation__
 *
 * To run a mutation, you first call `useBatchEmailInvoicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchEmailInvoicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchEmailInvoicesMutation, { data, loading, error }] = useBatchEmailInvoicesMutation({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *   },
 * });
 */
export function useBatchEmailInvoicesMutation(baseOptions?: Apollo.MutationHookOptions<BatchEmailInvoicesMutation, BatchEmailInvoicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchEmailInvoicesMutation, BatchEmailInvoicesMutationVariables>(BatchEmailInvoicesDocument, options);
      }
export type BatchEmailInvoicesMutationHookResult = ReturnType<typeof useBatchEmailInvoicesMutation>;
export type BatchEmailInvoicesMutationResult = Apollo.MutationResult<BatchEmailInvoicesMutation>;
export type BatchEmailInvoicesMutationOptions = Apollo.BaseMutationOptions<BatchEmailInvoicesMutation, BatchEmailInvoicesMutationVariables>;
export const MarkInvoicePaidDocument = gql`
    mutation MarkInvoicePaid($invoiceId: Int!, $note: String!) {
  markInvoiceAsPaid(input: {invoiceId: $invoiceId, note: $note}) {
    invoice {
      ...GetInvoiceByIdEntry
    }
  }
}
    ${GetInvoiceByIdEntryFragmentDoc}`;
export type MarkInvoicePaidMutationFn = Apollo.MutationFunction<MarkInvoicePaidMutation, MarkInvoicePaidMutationVariables>;

/**
 * __useMarkInvoicePaidMutation__
 *
 * To run a mutation, you first call `useMarkInvoicePaidMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkInvoicePaidMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markInvoicePaidMutation, { data, loading, error }] = useMarkInvoicePaidMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *      note: // value for 'note'
 *   },
 * });
 */
export function useMarkInvoicePaidMutation(baseOptions?: Apollo.MutationHookOptions<MarkInvoicePaidMutation, MarkInvoicePaidMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MarkInvoicePaidMutation, MarkInvoicePaidMutationVariables>(MarkInvoicePaidDocument, options);
      }
export type MarkInvoicePaidMutationHookResult = ReturnType<typeof useMarkInvoicePaidMutation>;
export type MarkInvoicePaidMutationResult = Apollo.MutationResult<MarkInvoicePaidMutation>;
export type MarkInvoicePaidMutationOptions = Apollo.BaseMutationOptions<MarkInvoicePaidMutation, MarkInvoicePaidMutationVariables>;
export const SetInvoicesLastPickupDateDocument = gql`
    mutation SetInvoicesLastPickupDate($invoiceIds: [Int]!, $lastPickupDate: Date!) {
  setInvoicesLastPickupDate(
    input: {invoiceIds: $invoiceIds, lastPickupDate: $lastPickupDate}
  ) {
    setInvoicesLastPickupDateResultTypes {
      id
      lastPickupDate
    }
  }
}
    `;
export type SetInvoicesLastPickupDateMutationFn = Apollo.MutationFunction<SetInvoicesLastPickupDateMutation, SetInvoicesLastPickupDateMutationVariables>;

/**
 * __useSetInvoicesLastPickupDateMutation__
 *
 * To run a mutation, you first call `useSetInvoicesLastPickupDateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInvoicesLastPickupDateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInvoicesLastPickupDateMutation, { data, loading, error }] = useSetInvoicesLastPickupDateMutation({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *      lastPickupDate: // value for 'lastPickupDate'
 *   },
 * });
 */
export function useSetInvoicesLastPickupDateMutation(baseOptions?: Apollo.MutationHookOptions<SetInvoicesLastPickupDateMutation, SetInvoicesLastPickupDateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetInvoicesLastPickupDateMutation, SetInvoicesLastPickupDateMutationVariables>(SetInvoicesLastPickupDateDocument, options);
      }
export type SetInvoicesLastPickupDateMutationHookResult = ReturnType<typeof useSetInvoicesLastPickupDateMutation>;
export type SetInvoicesLastPickupDateMutationResult = Apollo.MutationResult<SetInvoicesLastPickupDateMutation>;
export type SetInvoicesLastPickupDateMutationOptions = Apollo.BaseMutationOptions<SetInvoicesLastPickupDateMutation, SetInvoicesLastPickupDateMutationVariables>;
export const GetInvoiceCustomerDataForApplyCreditsToInvoiceDocument = gql`
    query GetInvoiceCustomerDataForApplyCreditsToInvoice($invoiceId: Int!) {
  invoiceById(id: $invoiceId) {
    amountDue
    bidderByBidderId {
      creditBalance
    }
  }
}
    `;

/**
 * __useGetInvoiceCustomerDataForApplyCreditsToInvoiceQuery__
 *
 * To run a query within a React component, call `useGetInvoiceCustomerDataForApplyCreditsToInvoiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceCustomerDataForApplyCreditsToInvoiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceCustomerDataForApplyCreditsToInvoiceQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useGetInvoiceCustomerDataForApplyCreditsToInvoiceQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceCustomerDataForApplyCreditsToInvoiceQuery, GetInvoiceCustomerDataForApplyCreditsToInvoiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceCustomerDataForApplyCreditsToInvoiceQuery, GetInvoiceCustomerDataForApplyCreditsToInvoiceQueryVariables>(GetInvoiceCustomerDataForApplyCreditsToInvoiceDocument, options);
      }
export function useGetInvoiceCustomerDataForApplyCreditsToInvoiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceCustomerDataForApplyCreditsToInvoiceQuery, GetInvoiceCustomerDataForApplyCreditsToInvoiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceCustomerDataForApplyCreditsToInvoiceQuery, GetInvoiceCustomerDataForApplyCreditsToInvoiceQueryVariables>(GetInvoiceCustomerDataForApplyCreditsToInvoiceDocument, options);
        }
export type GetInvoiceCustomerDataForApplyCreditsToInvoiceQueryHookResult = ReturnType<typeof useGetInvoiceCustomerDataForApplyCreditsToInvoiceQuery>;
export type GetInvoiceCustomerDataForApplyCreditsToInvoiceLazyQueryHookResult = ReturnType<typeof useGetInvoiceCustomerDataForApplyCreditsToInvoiceLazyQuery>;
export type GetInvoiceCustomerDataForApplyCreditsToInvoiceQueryResult = Apollo.QueryResult<GetInvoiceCustomerDataForApplyCreditsToInvoiceQuery, GetInvoiceCustomerDataForApplyCreditsToInvoiceQueryVariables>;
export const ApplyCreditToInvoiceDocument = gql`
    mutation ApplyCreditToInvoice($input: ApplyCreditToInvoiceInput!) {
  applyCreditToInvoice(input: $input) {
    invoice {
      ...GetInvoiceByIdEntry
    }
  }
}
    ${GetInvoiceByIdEntryFragmentDoc}`;
export type ApplyCreditToInvoiceMutationFn = Apollo.MutationFunction<ApplyCreditToInvoiceMutation, ApplyCreditToInvoiceMutationVariables>;

/**
 * __useApplyCreditToInvoiceMutation__
 *
 * To run a mutation, you first call `useApplyCreditToInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApplyCreditToInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [applyCreditToInvoiceMutation, { data, loading, error }] = useApplyCreditToInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useApplyCreditToInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<ApplyCreditToInvoiceMutation, ApplyCreditToInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApplyCreditToInvoiceMutation, ApplyCreditToInvoiceMutationVariables>(ApplyCreditToInvoiceDocument, options);
      }
export type ApplyCreditToInvoiceMutationHookResult = ReturnType<typeof useApplyCreditToInvoiceMutation>;
export type ApplyCreditToInvoiceMutationResult = Apollo.MutationResult<ApplyCreditToInvoiceMutation>;
export type ApplyCreditToInvoiceMutationOptions = Apollo.BaseMutationOptions<ApplyCreditToInvoiceMutation, ApplyCreditToInvoiceMutationVariables>;
export const CreateInvoiceAdjustmentDocument = gql`
    mutation CreateInvoiceAdjustment($values: InvoiceAdjustmentInput!) {
  createInvoiceAdjustment(input: {invoiceAdjustment: $values}) {
    invoiceAdjustment {
      ...InvoiceAdjustmentEntry
    }
  }
}
    ${InvoiceAdjustmentEntryFragmentDoc}`;
export type CreateInvoiceAdjustmentMutationFn = Apollo.MutationFunction<CreateInvoiceAdjustmentMutation, CreateInvoiceAdjustmentMutationVariables>;

/**
 * __useCreateInvoiceAdjustmentMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceAdjustmentMutation, { data, loading, error }] = useCreateInvoiceAdjustmentMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useCreateInvoiceAdjustmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceAdjustmentMutation, CreateInvoiceAdjustmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceAdjustmentMutation, CreateInvoiceAdjustmentMutationVariables>(CreateInvoiceAdjustmentDocument, options);
      }
export type CreateInvoiceAdjustmentMutationHookResult = ReturnType<typeof useCreateInvoiceAdjustmentMutation>;
export type CreateInvoiceAdjustmentMutationResult = Apollo.MutationResult<CreateInvoiceAdjustmentMutation>;
export type CreateInvoiceAdjustmentMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceAdjustmentMutation, CreateInvoiceAdjustmentMutationVariables>;
export const UpdateInvoiceAdjustmentDocument = gql`
    mutation UpdateInvoiceAdjustment($id: Int!, $values: InvoiceAdjustmentPatch!) {
  updateInvoiceAdjustmentById(input: {id: $id, invoiceAdjustmentPatch: $values}) {
    invoiceAdjustment {
      ...InvoiceAdjustmentEntry
    }
  }
}
    ${InvoiceAdjustmentEntryFragmentDoc}`;
export type UpdateInvoiceAdjustmentMutationFn = Apollo.MutationFunction<UpdateInvoiceAdjustmentMutation, UpdateInvoiceAdjustmentMutationVariables>;

/**
 * __useUpdateInvoiceAdjustmentMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceAdjustmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceAdjustmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceAdjustmentMutation, { data, loading, error }] = useUpdateInvoiceAdjustmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateInvoiceAdjustmentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceAdjustmentMutation, UpdateInvoiceAdjustmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceAdjustmentMutation, UpdateInvoiceAdjustmentMutationVariables>(UpdateInvoiceAdjustmentDocument, options);
      }
export type UpdateInvoiceAdjustmentMutationHookResult = ReturnType<typeof useUpdateInvoiceAdjustmentMutation>;
export type UpdateInvoiceAdjustmentMutationResult = Apollo.MutationResult<UpdateInvoiceAdjustmentMutation>;
export type UpdateInvoiceAdjustmentMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceAdjustmentMutation, UpdateInvoiceAdjustmentMutationVariables>;
export const GetInvoiceNotesByInvoiceIdDocument = gql`
    query GetInvoiceNotesByInvoiceId($id: Int!) {
  allInvoiceNotes(condition: {invoiceId: $id}, orderBy: CREATED_AT_DESC) {
    nodes {
      ...InvoiceNoteEntry
    }
  }
}
    ${InvoiceNoteEntryFragmentDoc}`;

/**
 * __useGetInvoiceNotesByInvoiceIdQuery__
 *
 * To run a query within a React component, call `useGetInvoiceNotesByInvoiceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoiceNotesByInvoiceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoiceNotesByInvoiceIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetInvoiceNotesByInvoiceIdQuery(baseOptions: Apollo.QueryHookOptions<GetInvoiceNotesByInvoiceIdQuery, GetInvoiceNotesByInvoiceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoiceNotesByInvoiceIdQuery, GetInvoiceNotesByInvoiceIdQueryVariables>(GetInvoiceNotesByInvoiceIdDocument, options);
      }
export function useGetInvoiceNotesByInvoiceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoiceNotesByInvoiceIdQuery, GetInvoiceNotesByInvoiceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoiceNotesByInvoiceIdQuery, GetInvoiceNotesByInvoiceIdQueryVariables>(GetInvoiceNotesByInvoiceIdDocument, options);
        }
export type GetInvoiceNotesByInvoiceIdQueryHookResult = ReturnType<typeof useGetInvoiceNotesByInvoiceIdQuery>;
export type GetInvoiceNotesByInvoiceIdLazyQueryHookResult = ReturnType<typeof useGetInvoiceNotesByInvoiceIdLazyQuery>;
export type GetInvoiceNotesByInvoiceIdQueryResult = Apollo.QueryResult<GetInvoiceNotesByInvoiceIdQuery, GetInvoiceNotesByInvoiceIdQueryVariables>;
export const CreateInvoiceNoteDocument = gql`
    mutation CreateInvoiceNote($values: InvoiceNoteInput!) {
  createInvoiceNote(input: {invoiceNote: $values}) {
    clientMutationId
  }
}
    `;
export type CreateInvoiceNoteMutationFn = Apollo.MutationFunction<CreateInvoiceNoteMutation, CreateInvoiceNoteMutationVariables>;

/**
 * __useCreateInvoiceNoteMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceNoteMutation, { data, loading, error }] = useCreateInvoiceNoteMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useCreateInvoiceNoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateInvoiceNoteMutation, CreateInvoiceNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateInvoiceNoteMutation, CreateInvoiceNoteMutationVariables>(CreateInvoiceNoteDocument, options);
      }
export type CreateInvoiceNoteMutationHookResult = ReturnType<typeof useCreateInvoiceNoteMutation>;
export type CreateInvoiceNoteMutationResult = Apollo.MutationResult<CreateInvoiceNoteMutation>;
export type CreateInvoiceNoteMutationOptions = Apollo.BaseMutationOptions<CreateInvoiceNoteMutation, CreateInvoiceNoteMutationVariables>;
export const DeleteInvoiceNoteDocument = gql`
    mutation DeleteInvoiceNote($invoiceNoteId: Int!) {
  deleteInvoiceNoteById(input: {id: $invoiceNoteId}) {
    clientMutationId
    deletedInvoiceNoteId
  }
}
    `;
export type DeleteInvoiceNoteMutationFn = Apollo.MutationFunction<DeleteInvoiceNoteMutation, DeleteInvoiceNoteMutationVariables>;

/**
 * __useDeleteInvoiceNoteMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceNoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceNoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceNoteMutation, { data, loading, error }] = useDeleteInvoiceNoteMutation({
 *   variables: {
 *      invoiceNoteId: // value for 'invoiceNoteId'
 *   },
 * });
 */
export function useDeleteInvoiceNoteMutation(baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceNoteMutation, DeleteInvoiceNoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteInvoiceNoteMutation, DeleteInvoiceNoteMutationVariables>(DeleteInvoiceNoteDocument, options);
      }
export type DeleteInvoiceNoteMutationHookResult = ReturnType<typeof useDeleteInvoiceNoteMutation>;
export type DeleteInvoiceNoteMutationResult = Apollo.MutationResult<DeleteInvoiceNoteMutation>;
export type DeleteInvoiceNoteMutationOptions = Apollo.BaseMutationOptions<DeleteInvoiceNoteMutation, DeleteInvoiceNoteMutationVariables>;
export const GetItemDocument = gql`
    query GetItem($id: Int!) {
  itemById(id: $id) {
    ...ItemEntry
    itemImagesByItemId(orderBy: SORT_ASC, condition: {isDeleted: false}) {
      nodes {
        ...ItemImageEntry
      }
    }
    auctionByAuctionId {
      name
      premium
      tax
    }
    invoiceByInvoiceId {
      id
      uid
      isInitialPaymentProcessed
      bidderByBidderId {
        id
        name
      }
    }
  }
}
    ${ItemEntryFragmentDoc}
${ItemImageEntryFragmentDoc}`;

/**
 * __useGetItemQuery__
 *
 * To run a query within a React component, call `useGetItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetItemQuery(baseOptions: Apollo.QueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
      }
export function useGetItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemQuery, GetItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemQuery, GetItemQueryVariables>(GetItemDocument, options);
        }
export type GetItemQueryHookResult = ReturnType<typeof useGetItemQuery>;
export type GetItemLazyQueryHookResult = ReturnType<typeof useGetItemLazyQuery>;
export type GetItemQueryResult = Apollo.QueryResult<GetItemQuery, GetItemQueryVariables>;
export const AllItemsDocument = gql`
    query AllItems($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [ItemsOrderBy!], $filter: ItemFilter) {
  allItems(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllItemsEntry
    }
  }
}
    ${AllItemsEntryFragmentDoc}`;

/**
 * __useAllItemsQuery__
 *
 * To run a query within a React component, call `useAllItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllItemsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllItemsQuery(baseOptions?: Apollo.QueryHookOptions<AllItemsQuery, AllItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllItemsQuery, AllItemsQueryVariables>(AllItemsDocument, options);
      }
export function useAllItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllItemsQuery, AllItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllItemsQuery, AllItemsQueryVariables>(AllItemsDocument, options);
        }
export type AllItemsQueryHookResult = ReturnType<typeof useAllItemsQuery>;
export type AllItemsLazyQueryHookResult = ReturnType<typeof useAllItemsLazyQuery>;
export type AllItemsQueryResult = Apollo.QueryResult<AllItemsQuery, AllItemsQueryVariables>;
export const AllAuctionItemsDocument = gql`
    query AllAuctionItems($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [ItemsOrderBy!], $filter: ItemFilter) {
  allItems(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllAuctionItemEntry
    }
  }
}
    ${AllAuctionItemEntryFragmentDoc}`;

/**
 * __useAllAuctionItemsQuery__
 *
 * To run a query within a React component, call `useAllAuctionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllAuctionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllAuctionItemsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllAuctionItemsQuery(baseOptions?: Apollo.QueryHookOptions<AllAuctionItemsQuery, AllAuctionItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllAuctionItemsQuery, AllAuctionItemsQueryVariables>(AllAuctionItemsDocument, options);
      }
export function useAllAuctionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllAuctionItemsQuery, AllAuctionItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllAuctionItemsQuery, AllAuctionItemsQueryVariables>(AllAuctionItemsDocument, options);
        }
export type AllAuctionItemsQueryHookResult = ReturnType<typeof useAllAuctionItemsQuery>;
export type AllAuctionItemsLazyQueryHookResult = ReturnType<typeof useAllAuctionItemsLazyQuery>;
export type AllAuctionItemsQueryResult = Apollo.QueryResult<AllAuctionItemsQuery, AllAuctionItemsQueryVariables>;
export const UpdateItemByIdDocument = gql`
    mutation UpdateItemById($id: Int!, $values: ItemPatch!) {
  updateItemById(input: {id: $id, itemPatch: $values}) {
    item {
      ...AllAuctionItemEntry
    }
  }
}
    ${AllAuctionItemEntryFragmentDoc}`;
export type UpdateItemByIdMutationFn = Apollo.MutationFunction<UpdateItemByIdMutation, UpdateItemByIdMutationVariables>;

/**
 * __useUpdateItemByIdMutation__
 *
 * To run a mutation, you first call `useUpdateItemByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemByIdMutation, { data, loading, error }] = useUpdateItemByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateItemByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemByIdMutation, UpdateItemByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemByIdMutation, UpdateItemByIdMutationVariables>(UpdateItemByIdDocument, options);
      }
export type UpdateItemByIdMutationHookResult = ReturnType<typeof useUpdateItemByIdMutation>;
export type UpdateItemByIdMutationResult = Apollo.MutationResult<UpdateItemByIdMutation>;
export type UpdateItemByIdMutationOptions = Apollo.BaseMutationOptions<UpdateItemByIdMutation, UpdateItemByIdMutationVariables>;
export const AllInvoiceItemsDocument = gql`
    query AllInvoiceItems($filter: ItemFilter) {
  allItems(filter: $filter) {
    totalCount
    nodes {
      ...AllInvoiceItemsEntry
    }
  }
}
    ${AllInvoiceItemsEntryFragmentDoc}`;

/**
 * __useAllInvoiceItemsQuery__
 *
 * To run a query within a React component, call `useAllInvoiceItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllInvoiceItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllInvoiceItemsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllInvoiceItemsQuery(baseOptions?: Apollo.QueryHookOptions<AllInvoiceItemsQuery, AllInvoiceItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllInvoiceItemsQuery, AllInvoiceItemsQueryVariables>(AllInvoiceItemsDocument, options);
      }
export function useAllInvoiceItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllInvoiceItemsQuery, AllInvoiceItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllInvoiceItemsQuery, AllInvoiceItemsQueryVariables>(AllInvoiceItemsDocument, options);
        }
export type AllInvoiceItemsQueryHookResult = ReturnType<typeof useAllInvoiceItemsQuery>;
export type AllInvoiceItemsLazyQueryHookResult = ReturnType<typeof useAllInvoiceItemsLazyQuery>;
export type AllInvoiceItemsQueryResult = Apollo.QueryResult<AllInvoiceItemsQuery, AllInvoiceItemsQueryVariables>;
export const UpdateInvoiceItemByIdDocument = gql`
    mutation UpdateInvoiceItemById($id: Int!, $values: ItemPatch!) {
  updateItemById(input: {id: $id, itemPatch: $values}) {
    item {
      ...ItemEntry
    }
  }
}
    ${ItemEntryFragmentDoc}`;
export type UpdateInvoiceItemByIdMutationFn = Apollo.MutationFunction<UpdateInvoiceItemByIdMutation, UpdateInvoiceItemByIdMutationVariables>;

/**
 * __useUpdateInvoiceItemByIdMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceItemByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceItemByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceItemByIdMutation, { data, loading, error }] = useUpdateInvoiceItemByIdMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateInvoiceItemByIdMutation(baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceItemByIdMutation, UpdateInvoiceItemByIdMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateInvoiceItemByIdMutation, UpdateInvoiceItemByIdMutationVariables>(UpdateInvoiceItemByIdDocument, options);
      }
export type UpdateInvoiceItemByIdMutationHookResult = ReturnType<typeof useUpdateInvoiceItemByIdMutation>;
export type UpdateInvoiceItemByIdMutationResult = Apollo.MutationResult<UpdateInvoiceItemByIdMutation>;
export type UpdateInvoiceItemByIdMutationOptions = Apollo.BaseMutationOptions<UpdateInvoiceItemByIdMutation, UpdateInvoiceItemByIdMutationVariables>;
export const ChargeInvoicePaymentDocument = gql`
    mutation ChargeInvoicePayment($invoiceId: Int!) {
  chargeInvoicePayment(input: {invoiceId: $invoiceId}) {
    clientMutationId
  }
}
    `;
export type ChargeInvoicePaymentMutationFn = Apollo.MutationFunction<ChargeInvoicePaymentMutation, ChargeInvoicePaymentMutationVariables>;

/**
 * __useChargeInvoicePaymentMutation__
 *
 * To run a mutation, you first call `useChargeInvoicePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChargeInvoicePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [chargeInvoicePaymentMutation, { data, loading, error }] = useChargeInvoicePaymentMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useChargeInvoicePaymentMutation(baseOptions?: Apollo.MutationHookOptions<ChargeInvoicePaymentMutation, ChargeInvoicePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChargeInvoicePaymentMutation, ChargeInvoicePaymentMutationVariables>(ChargeInvoicePaymentDocument, options);
      }
export type ChargeInvoicePaymentMutationHookResult = ReturnType<typeof useChargeInvoicePaymentMutation>;
export type ChargeInvoicePaymentMutationResult = Apollo.MutationResult<ChargeInvoicePaymentMutation>;
export type ChargeInvoicePaymentMutationOptions = Apollo.BaseMutationOptions<ChargeInvoicePaymentMutation, ChargeInvoicePaymentMutationVariables>;
export const SearchAuctionItemsDocument = gql`
    query SearchAuctionItems($value: String!, $auctionId: Int!) {
  allItems(
    condition: {auctionId: $auctionId}
    orderBy: NUMBER_ASC
    filter: {or: {description: {includesInsensitive: $value}}}
  ) {
    nodes {
      id
      description
      number
    }
  }
}
    `;

/**
 * __useSearchAuctionItemsQuery__
 *
 * To run a query within a React component, call `useSearchAuctionItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchAuctionItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchAuctionItemsQuery({
 *   variables: {
 *      value: // value for 'value'
 *      auctionId: // value for 'auctionId'
 *   },
 * });
 */
export function useSearchAuctionItemsQuery(baseOptions: Apollo.QueryHookOptions<SearchAuctionItemsQuery, SearchAuctionItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchAuctionItemsQuery, SearchAuctionItemsQueryVariables>(SearchAuctionItemsDocument, options);
      }
export function useSearchAuctionItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchAuctionItemsQuery, SearchAuctionItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchAuctionItemsQuery, SearchAuctionItemsQueryVariables>(SearchAuctionItemsDocument, options);
        }
export type SearchAuctionItemsQueryHookResult = ReturnType<typeof useSearchAuctionItemsQuery>;
export type SearchAuctionItemsLazyQueryHookResult = ReturnType<typeof useSearchAuctionItemsLazyQuery>;
export type SearchAuctionItemsQueryResult = Apollo.QueryResult<SearchAuctionItemsQuery, SearchAuctionItemsQueryVariables>;
export const GetItemsDocument = gql`
    query GetItems($value: String!) {
  allItems(
    first: 10
    orderBy: DESCRIPTION_ASC
    filter: {description: {includesInsensitive: $value}}
  ) {
    nodes {
      id
      description
      number
    }
  }
}
    `;

/**
 * __useGetItemsQuery__
 *
 * To run a query within a React component, call `useGetItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemsQuery({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetItemsQuery(baseOptions: Apollo.QueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
      }
export function useGetItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemsQuery, GetItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemsQuery, GetItemsQueryVariables>(GetItemsDocument, options);
        }
export type GetItemsQueryHookResult = ReturnType<typeof useGetItemsQuery>;
export type GetItemsLazyQueryHookResult = ReturnType<typeof useGetItemsLazyQuery>;
export type GetItemsQueryResult = Apollo.QueryResult<GetItemsQuery, GetItemsQueryVariables>;
export const AddImageItemDocument = gql`
    mutation AddImageItem($itemId: Int!, $image: String!) {
  addImageItem(input: {itemId: $itemId, image: $image}) {
    item {
      id
      images
    }
  }
}
    `;
export type AddImageItemMutationFn = Apollo.MutationFunction<AddImageItemMutation, AddImageItemMutationVariables>;

/**
 * __useAddImageItemMutation__
 *
 * To run a mutation, you first call `useAddImageItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddImageItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addImageItemMutation, { data, loading, error }] = useAddImageItemMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useAddImageItemMutation(baseOptions?: Apollo.MutationHookOptions<AddImageItemMutation, AddImageItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddImageItemMutation, AddImageItemMutationVariables>(AddImageItemDocument, options);
      }
export type AddImageItemMutationHookResult = ReturnType<typeof useAddImageItemMutation>;
export type AddImageItemMutationResult = Apollo.MutationResult<AddImageItemMutation>;
export type AddImageItemMutationOptions = Apollo.BaseMutationOptions<AddImageItemMutation, AddImageItemMutationVariables>;
export const UpdateItemDocument = gql`
    mutation UpdateItem($id: Int!, $values: ItemPatch!) {
  updateItemById(input: {id: $id, itemPatch: $values}) {
    item {
      id
      images
      primaryImages
    }
  }
}
    `;
export type UpdateItemMutationFn = Apollo.MutationFunction<UpdateItemMutation, UpdateItemMutationVariables>;

/**
 * __useUpdateItemMutation__
 *
 * To run a mutation, you first call `useUpdateItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateItemMutation, { data, loading, error }] = useUpdateItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateItemMutation(baseOptions?: Apollo.MutationHookOptions<UpdateItemMutation, UpdateItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateItemMutation, UpdateItemMutationVariables>(UpdateItemDocument, options);
      }
export type UpdateItemMutationHookResult = ReturnType<typeof useUpdateItemMutation>;
export type UpdateItemMutationResult = Apollo.MutationResult<UpdateItemMutation>;
export type UpdateItemMutationOptions = Apollo.BaseMutationOptions<UpdateItemMutation, UpdateItemMutationVariables>;
export const UploadItemImageDocument = gql`
    mutation UploadItemImage($itemId: Int!, $image: Upload!) {
  uploadItemsImage(input: {itemId: $itemId, image: $image}) {
    itemImage {
      ...ItemImageEntry
    }
  }
}
    ${ItemImageEntryFragmentDoc}`;
export type UploadItemImageMutationFn = Apollo.MutationFunction<UploadItemImageMutation, UploadItemImageMutationVariables>;

/**
 * __useUploadItemImageMutation__
 *
 * To run a mutation, you first call `useUploadItemImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadItemImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadItemImageMutation, { data, loading, error }] = useUploadItemImageMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUploadItemImageMutation(baseOptions?: Apollo.MutationHookOptions<UploadItemImageMutation, UploadItemImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadItemImageMutation, UploadItemImageMutationVariables>(UploadItemImageDocument, options);
      }
export type UploadItemImageMutationHookResult = ReturnType<typeof useUploadItemImageMutation>;
export type UploadItemImageMutationResult = Apollo.MutationResult<UploadItemImageMutation>;
export type UploadItemImageMutationOptions = Apollo.BaseMutationOptions<UploadItemImageMutation, UploadItemImageMutationVariables>;
export const ResortItemImagesDocument = gql`
    mutation ResortItemImages($itemId: Int!, $images: [ResortItemImagesTypeInput]!) {
  resortItemImages(input: {itemId: $itemId, images: $images}) {
    resortResult {
      success
    }
  }
}
    `;
export type ResortItemImagesMutationFn = Apollo.MutationFunction<ResortItemImagesMutation, ResortItemImagesMutationVariables>;

/**
 * __useResortItemImagesMutation__
 *
 * To run a mutation, you first call `useResortItemImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResortItemImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resortItemImagesMutation, { data, loading, error }] = useResortItemImagesMutation({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      images: // value for 'images'
 *   },
 * });
 */
export function useResortItemImagesMutation(baseOptions?: Apollo.MutationHookOptions<ResortItemImagesMutation, ResortItemImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResortItemImagesMutation, ResortItemImagesMutationVariables>(ResortItemImagesDocument, options);
      }
export type ResortItemImagesMutationHookResult = ReturnType<typeof useResortItemImagesMutation>;
export type ResortItemImagesMutationResult = Apollo.MutationResult<ResortItemImagesMutation>;
export type ResortItemImagesMutationOptions = Apollo.BaseMutationOptions<ResortItemImagesMutation, ResortItemImagesMutationVariables>;
export const SetPrimaryItemImageDocument = gql`
    mutation SetPrimaryItemImage($itemImageId: Int!, $isPrimary: Boolean!) {
  updateItemImageById(
    input: {id: $itemImageId, itemImagePatch: {isPrimary: $isPrimary}}
  ) {
    itemImage {
      ...ItemImageEntry
    }
  }
}
    ${ItemImageEntryFragmentDoc}`;
export type SetPrimaryItemImageMutationFn = Apollo.MutationFunction<SetPrimaryItemImageMutation, SetPrimaryItemImageMutationVariables>;

/**
 * __useSetPrimaryItemImageMutation__
 *
 * To run a mutation, you first call `useSetPrimaryItemImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetPrimaryItemImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setPrimaryItemImageMutation, { data, loading, error }] = useSetPrimaryItemImageMutation({
 *   variables: {
 *      itemImageId: // value for 'itemImageId'
 *      isPrimary: // value for 'isPrimary'
 *   },
 * });
 */
export function useSetPrimaryItemImageMutation(baseOptions?: Apollo.MutationHookOptions<SetPrimaryItemImageMutation, SetPrimaryItemImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetPrimaryItemImageMutation, SetPrimaryItemImageMutationVariables>(SetPrimaryItemImageDocument, options);
      }
export type SetPrimaryItemImageMutationHookResult = ReturnType<typeof useSetPrimaryItemImageMutation>;
export type SetPrimaryItemImageMutationResult = Apollo.MutationResult<SetPrimaryItemImageMutation>;
export type SetPrimaryItemImageMutationOptions = Apollo.BaseMutationOptions<SetPrimaryItemImageMutation, SetPrimaryItemImageMutationVariables>;
export const DeleteItemImageDocument = gql`
    mutation deleteItemImage($itemImageId: Int!) {
  updateItemImageById(
    input: {id: $itemImageId, itemImagePatch: {isDeleted: true}}
  ) {
    itemImage {
      ...ItemImageEntry
    }
  }
}
    ${ItemImageEntryFragmentDoc}`;
export type DeleteItemImageMutationFn = Apollo.MutationFunction<DeleteItemImageMutation, DeleteItemImageMutationVariables>;

/**
 * __useDeleteItemImageMutation__
 *
 * To run a mutation, you first call `useDeleteItemImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemImageMutation, { data, loading, error }] = useDeleteItemImageMutation({
 *   variables: {
 *      itemImageId: // value for 'itemImageId'
 *   },
 * });
 */
export function useDeleteItemImageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemImageMutation, DeleteItemImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemImageMutation, DeleteItemImageMutationVariables>(DeleteItemImageDocument, options);
      }
export type DeleteItemImageMutationHookResult = ReturnType<typeof useDeleteItemImageMutation>;
export type DeleteItemImageMutationResult = Apollo.MutationResult<DeleteItemImageMutation>;
export type DeleteItemImageMutationOptions = Apollo.BaseMutationOptions<DeleteItemImageMutation, DeleteItemImageMutationVariables>;
export const ReuploadItemImageDocument = gql`
    mutation ReuploadItemImage($itemImageId: Int!, $image: Upload!) {
  reuploadItemsImage(input: {itemImageId: $itemImageId, image: $image}) {
    itemImage {
      ...ItemImageEntry
    }
  }
}
    ${ItemImageEntryFragmentDoc}`;
export type ReuploadItemImageMutationFn = Apollo.MutationFunction<ReuploadItemImageMutation, ReuploadItemImageMutationVariables>;

/**
 * __useReuploadItemImageMutation__
 *
 * To run a mutation, you first call `useReuploadItemImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReuploadItemImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reuploadItemImageMutation, { data, loading, error }] = useReuploadItemImageMutation({
 *   variables: {
 *      itemImageId: // value for 'itemImageId'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useReuploadItemImageMutation(baseOptions?: Apollo.MutationHookOptions<ReuploadItemImageMutation, ReuploadItemImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReuploadItemImageMutation, ReuploadItemImageMutationVariables>(ReuploadItemImageDocument, options);
      }
export type ReuploadItemImageMutationHookResult = ReturnType<typeof useReuploadItemImageMutation>;
export type ReuploadItemImageMutationResult = Apollo.MutationResult<ReuploadItemImageMutation>;
export type ReuploadItemImageMutationOptions = Apollo.BaseMutationOptions<ReuploadItemImageMutation, ReuploadItemImageMutationVariables>;
export const AllItemImagesDocument = gql`
    query allItemImages($itemId: Int!) {
  allItemImages(
    condition: {itemId: $itemId, isDeleted: false}
    orderBy: [SORT_ASC]
  ) {
    nodes {
      ...ItemImageEntry
    }
  }
}
    ${ItemImageEntryFragmentDoc}`;

/**
 * __useAllItemImagesQuery__
 *
 * To run a query within a React component, call `useAllItemImagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllItemImagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllItemImagesQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useAllItemImagesQuery(baseOptions: Apollo.QueryHookOptions<AllItemImagesQuery, AllItemImagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllItemImagesQuery, AllItemImagesQueryVariables>(AllItemImagesDocument, options);
      }
export function useAllItemImagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllItemImagesQuery, AllItemImagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllItemImagesQuery, AllItemImagesQueryVariables>(AllItemImagesDocument, options);
        }
export type AllItemImagesQueryHookResult = ReturnType<typeof useAllItemImagesQuery>;
export type AllItemImagesLazyQueryHookResult = ReturnType<typeof useAllItemImagesLazyQuery>;
export type AllItemImagesQueryResult = Apollo.QueryResult<AllItemImagesQuery, AllItemImagesQueryVariables>;
export const CopyItemImageDocument = gql`
    mutation copyItemImage($itemImageId: Int!, $toItemId: Int!) {
  copyItemsImage(input: {itemImageId: $itemImageId, toItemId: $toItemId}) {
    itemImage {
      ...ItemImageEntry
    }
  }
}
    ${ItemImageEntryFragmentDoc}`;
export type CopyItemImageMutationFn = Apollo.MutationFunction<CopyItemImageMutation, CopyItemImageMutationVariables>;

/**
 * __useCopyItemImageMutation__
 *
 * To run a mutation, you first call `useCopyItemImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCopyItemImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [copyItemImageMutation, { data, loading, error }] = useCopyItemImageMutation({
 *   variables: {
 *      itemImageId: // value for 'itemImageId'
 *      toItemId: // value for 'toItemId'
 *   },
 * });
 */
export function useCopyItemImageMutation(baseOptions?: Apollo.MutationHookOptions<CopyItemImageMutation, CopyItemImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CopyItemImageMutation, CopyItemImageMutationVariables>(CopyItemImageDocument, options);
      }
export type CopyItemImageMutationHookResult = ReturnType<typeof useCopyItemImageMutation>;
export type CopyItemImageMutationResult = Apollo.MutationResult<CopyItemImageMutation>;
export type CopyItemImageMutationOptions = Apollo.BaseMutationOptions<CopyItemImageMutation, CopyItemImageMutationVariables>;
export const GetItemReturnDetailsDocument = gql`
    query GetItemReturnDetails($itemId: Int!, $returnQueueId: UUID) {
  getItemReturnDetails(itemId: $itemId, returnQueueId: $returnQueueId) {
    item {
      id
      number
      paymentCreditsAvailableForRefund
      alternatePaymentCreditsAvailableForRefund
      hasPaymentTransactionSettled
    }
    returnQueue {
      amount
      note
      returnOptionByOptionId {
        name
        id
        requireNote
      }
      sendEmail
      type
    }
  }
}
    `;

/**
 * __useGetItemReturnDetailsQuery__
 *
 * To run a query within a React component, call `useGetItemReturnDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetItemReturnDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetItemReturnDetailsQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *      returnQueueId: // value for 'returnQueueId'
 *   },
 * });
 */
export function useGetItemReturnDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetItemReturnDetailsQuery, GetItemReturnDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetItemReturnDetailsQuery, GetItemReturnDetailsQueryVariables>(GetItemReturnDetailsDocument, options);
      }
export function useGetItemReturnDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetItemReturnDetailsQuery, GetItemReturnDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetItemReturnDetailsQuery, GetItemReturnDetailsQueryVariables>(GetItemReturnDetailsDocument, options);
        }
export type GetItemReturnDetailsQueryHookResult = ReturnType<typeof useGetItemReturnDetailsQuery>;
export type GetItemReturnDetailsLazyQueryHookResult = ReturnType<typeof useGetItemReturnDetailsLazyQuery>;
export type GetItemReturnDetailsQueryResult = Apollo.QueryResult<GetItemReturnDetailsQuery, GetItemReturnDetailsQueryVariables>;
export const DeleteItemFromInvoiceDocument = gql`
    mutation DeleteItemFromInvoice($input: DeleteItemFromInvoiceInput!) {
  deleteItemFromInvoice(input: $input) {
    item {
      ...ItemEntry
    }
  }
}
    ${ItemEntryFragmentDoc}`;
export type DeleteItemFromInvoiceMutationFn = Apollo.MutationFunction<DeleteItemFromInvoiceMutation, DeleteItemFromInvoiceMutationVariables>;

/**
 * __useDeleteItemFromInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteItemFromInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteItemFromInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteItemFromInvoiceMutation, { data, loading, error }] = useDeleteItemFromInvoiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteItemFromInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteItemFromInvoiceMutation, DeleteItemFromInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteItemFromInvoiceMutation, DeleteItemFromInvoiceMutationVariables>(DeleteItemFromInvoiceDocument, options);
      }
export type DeleteItemFromInvoiceMutationHookResult = ReturnType<typeof useDeleteItemFromInvoiceMutation>;
export type DeleteItemFromInvoiceMutationResult = Apollo.MutationResult<DeleteItemFromInvoiceMutation>;
export type DeleteItemFromInvoiceMutationOptions = Apollo.BaseMutationOptions<DeleteItemFromInvoiceMutation, DeleteItemFromInvoiceMutationVariables>;
export const RelistItemDocument = gql`
    mutation RelistItem($input: RelistItemInput!) {
  relistItem(input: $input) {
    item {
      ...ItemEntry
    }
  }
}
    ${ItemEntryFragmentDoc}`;
export type RelistItemMutationFn = Apollo.MutationFunction<RelistItemMutation, RelistItemMutationVariables>;

/**
 * __useRelistItemMutation__
 *
 * To run a mutation, you first call `useRelistItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRelistItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [relistItemMutation, { data, loading, error }] = useRelistItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRelistItemMutation(baseOptions?: Apollo.MutationHookOptions<RelistItemMutation, RelistItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RelistItemMutation, RelistItemMutationVariables>(RelistItemDocument, options);
      }
export type RelistItemMutationHookResult = ReturnType<typeof useRelistItemMutation>;
export type RelistItemMutationResult = Apollo.MutationResult<RelistItemMutation>;
export type RelistItemMutationOptions = Apollo.BaseMutationOptions<RelistItemMutation, RelistItemMutationVariables>;
export const SetAsideItemDocument = gql`
    mutation SetAsideItem($input: SetAsideItemInput!) {
  setAsideItem(input: $input) {
    item {
      ...ItemEntry
    }
  }
}
    ${ItemEntryFragmentDoc}`;
export type SetAsideItemMutationFn = Apollo.MutationFunction<SetAsideItemMutation, SetAsideItemMutationVariables>;

/**
 * __useSetAsideItemMutation__
 *
 * To run a mutation, you first call `useSetAsideItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetAsideItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setAsideItemMutation, { data, loading, error }] = useSetAsideItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSetAsideItemMutation(baseOptions?: Apollo.MutationHookOptions<SetAsideItemMutation, SetAsideItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetAsideItemMutation, SetAsideItemMutationVariables>(SetAsideItemDocument, options);
      }
export type SetAsideItemMutationHookResult = ReturnType<typeof useSetAsideItemMutation>;
export type SetAsideItemMutationResult = Apollo.MutationResult<SetAsideItemMutation>;
export type SetAsideItemMutationOptions = Apollo.BaseMutationOptions<SetAsideItemMutation, SetAsideItemMutationVariables>;
export const RemoveTaxFromItemDocument = gql`
    mutation RemoveTaxFromItem($input: RemoveItemTaxInput!) {
  removeItemTax(input: $input) {
    item {
      ...ItemEntry
    }
  }
}
    ${ItemEntryFragmentDoc}`;
export type RemoveTaxFromItemMutationFn = Apollo.MutationFunction<RemoveTaxFromItemMutation, RemoveTaxFromItemMutationVariables>;

/**
 * __useRemoveTaxFromItemMutation__
 *
 * To run a mutation, you first call `useRemoveTaxFromItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTaxFromItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTaxFromItemMutation, { data, loading, error }] = useRemoveTaxFromItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveTaxFromItemMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTaxFromItemMutation, RemoveTaxFromItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTaxFromItemMutation, RemoveTaxFromItemMutationVariables>(RemoveTaxFromItemDocument, options);
      }
export type RemoveTaxFromItemMutationHookResult = ReturnType<typeof useRemoveTaxFromItemMutation>;
export type RemoveTaxFromItemMutationResult = Apollo.MutationResult<RemoveTaxFromItemMutation>;
export type RemoveTaxFromItemMutationOptions = Apollo.BaseMutationOptions<RemoveTaxFromItemMutation, RemoveTaxFromItemMutationVariables>;
export const ItemHistoryDocument = gql`
    query ItemHistory($itemId: Int!) {
  getItemHistory(itemId: $itemId) {
    nodes {
      ...ItemHistoryEntry
    }
  }
}
    ${ItemHistoryEntryFragmentDoc}`;

/**
 * __useItemHistoryQuery__
 *
 * To run a query within a React component, call `useItemHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemHistoryQuery({
 *   variables: {
 *      itemId: // value for 'itemId'
 *   },
 * });
 */
export function useItemHistoryQuery(baseOptions: Apollo.QueryHookOptions<ItemHistoryQuery, ItemHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemHistoryQuery, ItemHistoryQueryVariables>(ItemHistoryDocument, options);
      }
export function useItemHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemHistoryQuery, ItemHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemHistoryQuery, ItemHistoryQueryVariables>(ItemHistoryDocument, options);
        }
export type ItemHistoryQueryHookResult = ReturnType<typeof useItemHistoryQuery>;
export type ItemHistoryLazyQueryHookResult = ReturnType<typeof useItemHistoryLazyQuery>;
export type ItemHistoryQueryResult = Apollo.QueryResult<ItemHistoryQuery, ItemHistoryQueryVariables>;
export const RackLocationListDocument = gql`
    query RackLocationList($first: Int, $last: Int, $after: Cursor, $before: Cursor, $filter: RackLocationListResultFilter) {
  rackLocationList(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...RackLocationListEntry
    }
  }
}
    ${RackLocationListEntryFragmentDoc}`;

/**
 * __useRackLocationListQuery__
 *
 * To run a query within a React component, call `useRackLocationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useRackLocationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRackLocationListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useRackLocationListQuery(baseOptions?: Apollo.QueryHookOptions<RackLocationListQuery, RackLocationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RackLocationListQuery, RackLocationListQueryVariables>(RackLocationListDocument, options);
      }
export function useRackLocationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RackLocationListQuery, RackLocationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RackLocationListQuery, RackLocationListQueryVariables>(RackLocationListDocument, options);
        }
export type RackLocationListQueryHookResult = ReturnType<typeof useRackLocationListQuery>;
export type RackLocationListLazyQueryHookResult = ReturnType<typeof useRackLocationListLazyQuery>;
export type RackLocationListQueryResult = Apollo.QueryResult<RackLocationListQuery, RackLocationListQueryVariables>;
export const ItemRackLocationsDocument = gql`
    query ItemRackLocations($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [ItemRackLocationsOrderBy!], $filter: ItemRackLocationFilter) {
  allItemRackLocations(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...ItemRackLocationEntry
    }
  }
}
    ${ItemRackLocationEntryFragmentDoc}`;

/**
 * __useItemRackLocationsQuery__
 *
 * To run a query within a React component, call `useItemRackLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useItemRackLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useItemRackLocationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useItemRackLocationsQuery(baseOptions?: Apollo.QueryHookOptions<ItemRackLocationsQuery, ItemRackLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ItemRackLocationsQuery, ItemRackLocationsQueryVariables>(ItemRackLocationsDocument, options);
      }
export function useItemRackLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ItemRackLocationsQuery, ItemRackLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ItemRackLocationsQuery, ItemRackLocationsQueryVariables>(ItemRackLocationsDocument, options);
        }
export type ItemRackLocationsQueryHookResult = ReturnType<typeof useItemRackLocationsQuery>;
export type ItemRackLocationsLazyQueryHookResult = ReturnType<typeof useItemRackLocationsLazyQuery>;
export type ItemRackLocationsQueryResult = Apollo.QueryResult<ItemRackLocationsQuery, ItemRackLocationsQueryVariables>;
export const MissingRackLocationListDocument = gql`
    query MissingRackLocationList($first: Int, $last: Int, $after: Cursor, $before: Cursor, $filter: MissingRackLocationListResultFilter) {
  missingRackLocationList(
    first: $first
    last: $last
    before: $before
    after: $after
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...MissingRackLocationListEntry
    }
  }
}
    ${MissingRackLocationListEntryFragmentDoc}`;

/**
 * __useMissingRackLocationListQuery__
 *
 * To run a query within a React component, call `useMissingRackLocationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useMissingRackLocationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMissingRackLocationListQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMissingRackLocationListQuery(baseOptions?: Apollo.QueryHookOptions<MissingRackLocationListQuery, MissingRackLocationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MissingRackLocationListQuery, MissingRackLocationListQueryVariables>(MissingRackLocationListDocument, options);
      }
export function useMissingRackLocationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MissingRackLocationListQuery, MissingRackLocationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MissingRackLocationListQuery, MissingRackLocationListQueryVariables>(MissingRackLocationListDocument, options);
        }
export type MissingRackLocationListQueryHookResult = ReturnType<typeof useMissingRackLocationListQuery>;
export type MissingRackLocationListLazyQueryHookResult = ReturnType<typeof useMissingRackLocationListLazyQuery>;
export type MissingRackLocationListQueryResult = Apollo.QueryResult<MissingRackLocationListQuery, MissingRackLocationListQueryVariables>;
export const GetReturnsByItemIdDocument = gql`
    query GetReturnsByItemId($id: Int!) {
  allInvoiceItemReturns(condition: {invoiceItemId: $id}, orderBy: CREATED_AT_DESC) {
    nodes {
      ...InvoiceItemReturnEntry
    }
  }
}
    ${InvoiceItemReturnEntryFragmentDoc}`;

/**
 * __useGetReturnsByItemIdQuery__
 *
 * To run a query within a React component, call `useGetReturnsByItemIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnsByItemIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnsByItemIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReturnsByItemIdQuery(baseOptions: Apollo.QueryHookOptions<GetReturnsByItemIdQuery, GetReturnsByItemIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnsByItemIdQuery, GetReturnsByItemIdQueryVariables>(GetReturnsByItemIdDocument, options);
      }
export function useGetReturnsByItemIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnsByItemIdQuery, GetReturnsByItemIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnsByItemIdQuery, GetReturnsByItemIdQueryVariables>(GetReturnsByItemIdDocument, options);
        }
export type GetReturnsByItemIdQueryHookResult = ReturnType<typeof useGetReturnsByItemIdQuery>;
export type GetReturnsByItemIdLazyQueryHookResult = ReturnType<typeof useGetReturnsByItemIdLazyQuery>;
export type GetReturnsByItemIdQueryResult = Apollo.QueryResult<GetReturnsByItemIdQuery, GetReturnsByItemIdQueryVariables>;
export const ReturnItemDocument = gql`
    mutation ReturnItem($input: IssueReturnForItemInput!) {
  issueReturnForItem(input: $input) {
    invoiceItemReturn {
      ...InvoiceItemReturnEntry
    }
  }
}
    ${InvoiceItemReturnEntryFragmentDoc}`;
export type ReturnItemMutationFn = Apollo.MutationFunction<ReturnItemMutation, ReturnItemMutationVariables>;

/**
 * __useReturnItemMutation__
 *
 * To run a mutation, you first call `useReturnItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReturnItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [returnItemMutation, { data, loading, error }] = useReturnItemMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReturnItemMutation(baseOptions?: Apollo.MutationHookOptions<ReturnItemMutation, ReturnItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReturnItemMutation, ReturnItemMutationVariables>(ReturnItemDocument, options);
      }
export type ReturnItemMutationHookResult = ReturnType<typeof useReturnItemMutation>;
export type ReturnItemMutationResult = Apollo.MutationResult<ReturnItemMutation>;
export type ReturnItemMutationOptions = Apollo.BaseMutationOptions<ReturnItemMutation, ReturnItemMutationVariables>;
export const DeleteCreditFromReturnDocument = gql`
    mutation DeleteCreditFromReturn($input: DeleteCreditFromReturnInput!) {
  deleteCreditFromReturn(input: $input) {
    invoiceItemReturn {
      ...InvoiceItemReturnEntry
    }
  }
}
    ${InvoiceItemReturnEntryFragmentDoc}`;
export type DeleteCreditFromReturnMutationFn = Apollo.MutationFunction<DeleteCreditFromReturnMutation, DeleteCreditFromReturnMutationVariables>;

/**
 * __useDeleteCreditFromReturnMutation__
 *
 * To run a mutation, you first call `useDeleteCreditFromReturnMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCreditFromReturnMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCreditFromReturnMutation, { data, loading, error }] = useDeleteCreditFromReturnMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCreditFromReturnMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCreditFromReturnMutation, DeleteCreditFromReturnMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCreditFromReturnMutation, DeleteCreditFromReturnMutationVariables>(DeleteCreditFromReturnDocument, options);
      }
export type DeleteCreditFromReturnMutationHookResult = ReturnType<typeof useDeleteCreditFromReturnMutation>;
export type DeleteCreditFromReturnMutationResult = Apollo.MutationResult<DeleteCreditFromReturnMutation>;
export type DeleteCreditFromReturnMutationOptions = Apollo.BaseMutationOptions<DeleteCreditFromReturnMutation, DeleteCreditFromReturnMutationVariables>;
export const JobsByAuctionIdDocument = gql`
    query JobsByAuctionId($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [JobQueuesOrderBy!], $filter: JobQueueFilter) {
  allJobQueues(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...JobsByAuctionIdEntry
    }
  }
}
    ${JobsByAuctionIdEntryFragmentDoc}`;

/**
 * __useJobsByAuctionIdQuery__
 *
 * To run a query within a React component, call `useJobsByAuctionIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobsByAuctionIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobsByAuctionIdQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useJobsByAuctionIdQuery(baseOptions?: Apollo.QueryHookOptions<JobsByAuctionIdQuery, JobsByAuctionIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobsByAuctionIdQuery, JobsByAuctionIdQueryVariables>(JobsByAuctionIdDocument, options);
      }
export function useJobsByAuctionIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobsByAuctionIdQuery, JobsByAuctionIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobsByAuctionIdQuery, JobsByAuctionIdQueryVariables>(JobsByAuctionIdDocument, options);
        }
export type JobsByAuctionIdQueryHookResult = ReturnType<typeof useJobsByAuctionIdQuery>;
export type JobsByAuctionIdLazyQueryHookResult = ReturnType<typeof useJobsByAuctionIdLazyQuery>;
export type JobsByAuctionIdQueryResult = Apollo.QueryResult<JobsByAuctionIdQuery, JobsByAuctionIdQueryVariables>;
export const JobQueueItemsDocument = gql`
    query JobQueueItems($jobQueueId: Int!) {
  allJobQueueItems(
    condition: {isSuccessful: false, jobQueueId: $jobQueueId}
    orderBy: COMPLETED_AT_DESC
  ) {
    nodes {
      ...JobQueueItemEntry
    }
  }
}
    ${JobQueueItemEntryFragmentDoc}`;

/**
 * __useJobQueueItemsQuery__
 *
 * To run a query within a React component, call `useJobQueueItemsQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobQueueItemsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobQueueItemsQuery({
 *   variables: {
 *      jobQueueId: // value for 'jobQueueId'
 *   },
 * });
 */
export function useJobQueueItemsQuery(baseOptions: Apollo.QueryHookOptions<JobQueueItemsQuery, JobQueueItemsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobQueueItemsQuery, JobQueueItemsQueryVariables>(JobQueueItemsDocument, options);
      }
export function useJobQueueItemsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobQueueItemsQuery, JobQueueItemsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobQueueItemsQuery, JobQueueItemsQueryVariables>(JobQueueItemsDocument, options);
        }
export type JobQueueItemsQueryHookResult = ReturnType<typeof useJobQueueItemsQuery>;
export type JobQueueItemsLazyQueryHookResult = ReturnType<typeof useJobQueueItemsLazyQuery>;
export type JobQueueItemsQueryResult = Apollo.QueryResult<JobQueueItemsQuery, JobQueueItemsQueryVariables>;
export const GetLocationDocument = gql`
    query GetLocation($id: Int!) {
  locationById(id: $id) {
    ...LocationEntry
  }
}
    ${LocationEntryFragmentDoc}`;

/**
 * __useGetLocationQuery__
 *
 * To run a query within a React component, call `useGetLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetLocationQuery(baseOptions: Apollo.QueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
      }
export function useGetLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationQuery, GetLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationQuery, GetLocationQueryVariables>(GetLocationDocument, options);
        }
export type GetLocationQueryHookResult = ReturnType<typeof useGetLocationQuery>;
export type GetLocationLazyQueryHookResult = ReturnType<typeof useGetLocationLazyQuery>;
export type GetLocationQueryResult = Apollo.QueryResult<GetLocationQuery, GetLocationQueryVariables>;
export const AllLocationsDocument = gql`
    query AllLocations($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [LocationsOrderBy!], $filter: LocationFilter) {
  allLocations(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...LocationListEntry
    }
  }
}
    ${LocationListEntryFragmentDoc}`;

/**
 * __useAllLocationsQuery__
 *
 * To run a query within a React component, call `useAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllLocationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllLocationsQuery(baseOptions?: Apollo.QueryHookOptions<AllLocationsQuery, AllLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllLocationsQuery, AllLocationsQueryVariables>(AllLocationsDocument, options);
      }
export function useAllLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllLocationsQuery, AllLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllLocationsQuery, AllLocationsQueryVariables>(AllLocationsDocument, options);
        }
export type AllLocationsQueryHookResult = ReturnType<typeof useAllLocationsQuery>;
export type AllLocationsLazyQueryHookResult = ReturnType<typeof useAllLocationsLazyQuery>;
export type AllLocationsQueryResult = Apollo.QueryResult<AllLocationsQuery, AllLocationsQueryVariables>;
export const GetLocationsDocument = gql`
    query GetLocations($value: String!) {
  allLocations(
    first: 10
    orderBy: NAME_ASC
    filter: {name: {includesInsensitive: $value}}
  ) {
    nodes {
      ...GetLocationsEntry
    }
  }
}
    ${GetLocationsEntryFragmentDoc}`;

/**
 * __useGetLocationsQuery__
 *
 * To run a query within a React component, call `useGetLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsQuery({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetLocationsQuery(baseOptions: Apollo.QueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
      }
export function useGetLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLocationsQuery, GetLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLocationsQuery, GetLocationsQueryVariables>(GetLocationsDocument, options);
        }
export type GetLocationsQueryHookResult = ReturnType<typeof useGetLocationsQuery>;
export type GetLocationsLazyQueryHookResult = ReturnType<typeof useGetLocationsLazyQuery>;
export type GetLocationsQueryResult = Apollo.QueryResult<GetLocationsQuery, GetLocationsQueryVariables>;
export const GetAllLocationsDocument = gql`
    query GetAllLocations {
  allLocations(orderBy: NAME_ASC) {
    nodes {
      ...GetLocationsEntry
    }
  }
}
    ${GetLocationsEntryFragmentDoc}`;

/**
 * __useGetAllLocationsQuery__
 *
 * To run a query within a React component, call `useGetAllLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLocationsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
      }
export function useGetAllLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllLocationsQuery, GetAllLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllLocationsQuery, GetAllLocationsQueryVariables>(GetAllLocationsDocument, options);
        }
export type GetAllLocationsQueryHookResult = ReturnType<typeof useGetAllLocationsQuery>;
export type GetAllLocationsLazyQueryHookResult = ReturnType<typeof useGetAllLocationsLazyQuery>;
export type GetAllLocationsQueryResult = Apollo.QueryResult<GetAllLocationsQuery, GetAllLocationsQueryVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($values: LocationInput!) {
  createLocation(input: {location: $values}) {
    location {
      ...LocationEntry
    }
  }
}
    ${LocationEntryFragmentDoc}`;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($id: Int!, $values: LocationPatch!) {
  updateLocationById(input: {id: $id, locationPatch: $values}) {
    location {
      ...LocationEntry
    }
  }
}
    ${LocationEntryFragmentDoc}`;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const AllPaymentsDocument = gql`
    query AllPayments($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [PaymentsOrderBy!], $filter: PaymentFilter) {
  allPayments(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...PaymentEntry
    }
  }
}
    ${PaymentEntryFragmentDoc}`;

/**
 * __useAllPaymentsQuery__
 *
 * To run a query within a React component, call `useAllPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPaymentsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllPaymentsQuery(baseOptions?: Apollo.QueryHookOptions<AllPaymentsQuery, AllPaymentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPaymentsQuery, AllPaymentsQueryVariables>(AllPaymentsDocument, options);
      }
export function useAllPaymentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPaymentsQuery, AllPaymentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPaymentsQuery, AllPaymentsQueryVariables>(AllPaymentsDocument, options);
        }
export type AllPaymentsQueryHookResult = ReturnType<typeof useAllPaymentsQuery>;
export type AllPaymentsLazyQueryHookResult = ReturnType<typeof useAllPaymentsLazyQuery>;
export type AllPaymentsQueryResult = Apollo.QueryResult<AllPaymentsQuery, AllPaymentsQueryVariables>;
export const GetPaymentDocument = gql`
    query GetPayment($id: Int!) {
  paymentById(id: $id) {
    ...PaymentEntry
  }
}
    ${PaymentEntryFragmentDoc}`;

/**
 * __useGetPaymentQuery__
 *
 * To run a query within a React component, call `useGetPaymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
      }
export function useGetPaymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentQuery, GetPaymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentQuery, GetPaymentQueryVariables>(GetPaymentDocument, options);
        }
export type GetPaymentQueryHookResult = ReturnType<typeof useGetPaymentQuery>;
export type GetPaymentLazyQueryHookResult = ReturnType<typeof useGetPaymentLazyQuery>;
export type GetPaymentQueryResult = Apollo.QueryResult<GetPaymentQuery, GetPaymentQueryVariables>;
export const GetPaymentsByInvoiceIdDocument = gql`
    query GetPaymentsByInvoiceId($id: Int!) {
  allPayments(condition: {invoiceId: $id}, orderBy: CREATED_AT_DESC) {
    nodes {
      ...PaymentEntry
    }
  }
}
    ${PaymentEntryFragmentDoc}`;

/**
 * __useGetPaymentsByInvoiceIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentsByInvoiceIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentsByInvoiceIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentsByInvoiceIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPaymentsByInvoiceIdQuery(baseOptions: Apollo.QueryHookOptions<GetPaymentsByInvoiceIdQuery, GetPaymentsByInvoiceIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentsByInvoiceIdQuery, GetPaymentsByInvoiceIdQueryVariables>(GetPaymentsByInvoiceIdDocument, options);
      }
export function useGetPaymentsByInvoiceIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentsByInvoiceIdQuery, GetPaymentsByInvoiceIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentsByInvoiceIdQuery, GetPaymentsByInvoiceIdQueryVariables>(GetPaymentsByInvoiceIdDocument, options);
        }
export type GetPaymentsByInvoiceIdQueryHookResult = ReturnType<typeof useGetPaymentsByInvoiceIdQuery>;
export type GetPaymentsByInvoiceIdLazyQueryHookResult = ReturnType<typeof useGetPaymentsByInvoiceIdLazyQuery>;
export type GetPaymentsByInvoiceIdQueryResult = Apollo.QueryResult<GetPaymentsByInvoiceIdQuery, GetPaymentsByInvoiceIdQueryVariables>;
export const VoidPaymentDocument = gql`
    mutation VoidPayment($id: Int!) {
  voidPayment(input: {paymentId: $id}) {
    willProcess
  }
}
    `;
export type VoidPaymentMutationFn = Apollo.MutationFunction<VoidPaymentMutation, VoidPaymentMutationVariables>;

/**
 * __useVoidPaymentMutation__
 *
 * To run a mutation, you first call `useVoidPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVoidPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [voidPaymentMutation, { data, loading, error }] = useVoidPaymentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVoidPaymentMutation(baseOptions?: Apollo.MutationHookOptions<VoidPaymentMutation, VoidPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VoidPaymentMutation, VoidPaymentMutationVariables>(VoidPaymentDocument, options);
      }
export type VoidPaymentMutationHookResult = ReturnType<typeof useVoidPaymentMutation>;
export type VoidPaymentMutationResult = Apollo.MutationResult<VoidPaymentMutation>;
export type VoidPaymentMutationOptions = Apollo.BaseMutationOptions<VoidPaymentMutation, VoidPaymentMutationVariables>;
export const GetInvoicesForSendToPickListQueueDocument = gql`
    query GetInvoicesForSendToPickListQueue($invoiceIds: [Int!]!) {
  allInvoices(filter: {id: {in: $invoiceIds}}) {
    nodes {
      ...InvoiceForSendToPickListQueueEntry
    }
  }
}
    ${InvoiceForSendToPickListQueueEntryFragmentDoc}`;

/**
 * __useGetInvoicesForSendToPickListQueueQuery__
 *
 * To run a query within a React component, call `useGetInvoicesForSendToPickListQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInvoicesForSendToPickListQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInvoicesForSendToPickListQueueQuery({
 *   variables: {
 *      invoiceIds: // value for 'invoiceIds'
 *   },
 * });
 */
export function useGetInvoicesForSendToPickListQueueQuery(baseOptions: Apollo.QueryHookOptions<GetInvoicesForSendToPickListQueueQuery, GetInvoicesForSendToPickListQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInvoicesForSendToPickListQueueQuery, GetInvoicesForSendToPickListQueueQueryVariables>(GetInvoicesForSendToPickListQueueDocument, options);
      }
export function useGetInvoicesForSendToPickListQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInvoicesForSendToPickListQueueQuery, GetInvoicesForSendToPickListQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInvoicesForSendToPickListQueueQuery, GetInvoicesForSendToPickListQueueQueryVariables>(GetInvoicesForSendToPickListQueueDocument, options);
        }
export type GetInvoicesForSendToPickListQueueQueryHookResult = ReturnType<typeof useGetInvoicesForSendToPickListQueueQuery>;
export type GetInvoicesForSendToPickListQueueLazyQueryHookResult = ReturnType<typeof useGetInvoicesForSendToPickListQueueLazyQuery>;
export type GetInvoicesForSendToPickListQueueQueryResult = Apollo.QueryResult<GetInvoicesForSendToPickListQueueQuery, GetInvoicesForSendToPickListQueueQueryVariables>;
export const BatchSendToPickListQueueDocument = gql`
    mutation BatchSendToPickListQueue($input: BatchSendToPicklistQueueInput!) {
  batchSendToPicklistQueue(input: $input) {
    clientMutationId
  }
}
    `;
export type BatchSendToPickListQueueMutationFn = Apollo.MutationFunction<BatchSendToPickListQueueMutation, BatchSendToPickListQueueMutationVariables>;

/**
 * __useBatchSendToPickListQueueMutation__
 *
 * To run a mutation, you first call `useBatchSendToPickListQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBatchSendToPickListQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [batchSendToPickListQueueMutation, { data, loading, error }] = useBatchSendToPickListQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useBatchSendToPickListQueueMutation(baseOptions?: Apollo.MutationHookOptions<BatchSendToPickListQueueMutation, BatchSendToPickListQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BatchSendToPickListQueueMutation, BatchSendToPickListQueueMutationVariables>(BatchSendToPickListQueueDocument, options);
      }
export type BatchSendToPickListQueueMutationHookResult = ReturnType<typeof useBatchSendToPickListQueueMutation>;
export type BatchSendToPickListQueueMutationResult = Apollo.MutationResult<BatchSendToPickListQueueMutation>;
export type BatchSendToPickListQueueMutationOptions = Apollo.BaseMutationOptions<BatchSendToPickListQueueMutation, BatchSendToPickListQueueMutationVariables>;
export const PicklistQueueByLocationDocument = gql`
    query PicklistQueueByLocation($locationId: Int!) {
  picklistQueueCounts(locationId: $locationId) {
    totalCount
    unassignedCount
    assignedCount
  }
  picklistUnassignedItems(locationId: $locationId) {
    nodes {
      ...PicklistQueueItemEntry
    }
  }
  picklistAssignedItems(locationId: $locationId) {
    nodes {
      ...PicklistQueueItemEntry
    }
  }
}
    ${PicklistQueueItemEntryFragmentDoc}`;

/**
 * __usePicklistQueueByLocationQuery__
 *
 * To run a query within a React component, call `usePicklistQueueByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `usePicklistQueueByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePicklistQueueByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function usePicklistQueueByLocationQuery(baseOptions: Apollo.QueryHookOptions<PicklistQueueByLocationQuery, PicklistQueueByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PicklistQueueByLocationQuery, PicklistQueueByLocationQueryVariables>(PicklistQueueByLocationDocument, options);
      }
export function usePicklistQueueByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PicklistQueueByLocationQuery, PicklistQueueByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PicklistQueueByLocationQuery, PicklistQueueByLocationQueryVariables>(PicklistQueueByLocationDocument, options);
        }
export type PicklistQueueByLocationQueryHookResult = ReturnType<typeof usePicklistQueueByLocationQuery>;
export type PicklistQueueByLocationLazyQueryHookResult = ReturnType<typeof usePicklistQueueByLocationLazyQuery>;
export type PicklistQueueByLocationQueryResult = Apollo.QueryResult<PicklistQueueByLocationQuery, PicklistQueueByLocationQueryVariables>;
export const PicklistQueueItemDocument = gql`
    query PicklistQueueItem($locationId: Int!, $customerId: Int!, $isAssignedNull: Boolean!, $status: PicklistQueueStatusFilter!) {
  allPicklistQueues(
    filter: {locationId: {equalTo: $locationId}, customerId: {equalTo: $customerId}, assignedUserId: {isNull: $isAssignedNull}, status: $status}
    orderBy: ITEM_NUM_ASC
  ) {
    nodes {
      ...PicklistQueueEntry
    }
  }
}
    ${PicklistQueueEntryFragmentDoc}`;

/**
 * __usePicklistQueueItemQuery__
 *
 * To run a query within a React component, call `usePicklistQueueItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePicklistQueueItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePicklistQueueItemQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      customerId: // value for 'customerId'
 *      isAssignedNull: // value for 'isAssignedNull'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePicklistQueueItemQuery(baseOptions: Apollo.QueryHookOptions<PicklistQueueItemQuery, PicklistQueueItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PicklistQueueItemQuery, PicklistQueueItemQueryVariables>(PicklistQueueItemDocument, options);
      }
export function usePicklistQueueItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PicklistQueueItemQuery, PicklistQueueItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PicklistQueueItemQuery, PicklistQueueItemQueryVariables>(PicklistQueueItemDocument, options);
        }
export type PicklistQueueItemQueryHookResult = ReturnType<typeof usePicklistQueueItemQuery>;
export type PicklistQueueItemLazyQueryHookResult = ReturnType<typeof usePicklistQueueItemLazyQuery>;
export type PicklistQueueItemQueryResult = Apollo.QueryResult<PicklistQueueItemQuery, PicklistQueueItemQueryVariables>;
export const DeletePicklistQueueDocument = gql`
    mutation DeletePicklistQueue($input: DeletePicklistQueueByIdInput!) {
  deletePicklistQueueById(input: $input) {
    deletedPicklistQueueId
  }
}
    `;
export type DeletePicklistQueueMutationFn = Apollo.MutationFunction<DeletePicklistQueueMutation, DeletePicklistQueueMutationVariables>;

/**
 * __useDeletePicklistQueueMutation__
 *
 * To run a mutation, you first call `useDeletePicklistQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePicklistQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePicklistQueueMutation, { data, loading, error }] = useDeletePicklistQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeletePicklistQueueMutation(baseOptions?: Apollo.MutationHookOptions<DeletePicklistQueueMutation, DeletePicklistQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePicklistQueueMutation, DeletePicklistQueueMutationVariables>(DeletePicklistQueueDocument, options);
      }
export type DeletePicklistQueueMutationHookResult = ReturnType<typeof useDeletePicklistQueueMutation>;
export type DeletePicklistQueueMutationResult = Apollo.MutationResult<DeletePicklistQueueMutation>;
export type DeletePicklistQueueMutationOptions = Apollo.BaseMutationOptions<DeletePicklistQueueMutation, DeletePicklistQueueMutationVariables>;
export const UpdatePicklistQueueDocument = gql`
    mutation UpdatePicklistQueue($input: UpdatePicklistQueueByIdInput!) {
  updatePicklistQueueById(input: $input) {
    picklistQueue {
      ...PicklistQueueEntry
    }
  }
}
    ${PicklistQueueEntryFragmentDoc}`;
export type UpdatePicklistQueueMutationFn = Apollo.MutationFunction<UpdatePicklistQueueMutation, UpdatePicklistQueueMutationVariables>;

/**
 * __useUpdatePicklistQueueMutation__
 *
 * To run a mutation, you first call `useUpdatePicklistQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePicklistQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePicklistQueueMutation, { data, loading, error }] = useUpdatePicklistQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePicklistQueueMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePicklistQueueMutation, UpdatePicklistQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePicklistQueueMutation, UpdatePicklistQueueMutationVariables>(UpdatePicklistQueueDocument, options);
      }
export type UpdatePicklistQueueMutationHookResult = ReturnType<typeof useUpdatePicklistQueueMutation>;
export type UpdatePicklistQueueMutationResult = Apollo.MutationResult<UpdatePicklistQueueMutation>;
export type UpdatePicklistQueueMutationOptions = Apollo.BaseMutationOptions<UpdatePicklistQueueMutation, UpdatePicklistQueueMutationVariables>;
export const NotCompletedPickupQueueByLocationDocument = gql`
    query NotCompletedPickupQueueByLocation($locationId: Int!) {
  pickupUnassignedItems(locationId: $locationId) {
    nodes {
      ...PickupQueueItemEntry
    }
  }
  pickupAssignedItems(locationId: $locationId) {
    nodes {
      ...PickupQueueItemEntry
    }
  }
}
    ${PickupQueueItemEntryFragmentDoc}`;

/**
 * __useNotCompletedPickupQueueByLocationQuery__
 *
 * To run a query within a React component, call `useNotCompletedPickupQueueByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotCompletedPickupQueueByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotCompletedPickupQueueByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useNotCompletedPickupQueueByLocationQuery(baseOptions: Apollo.QueryHookOptions<NotCompletedPickupQueueByLocationQuery, NotCompletedPickupQueueByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotCompletedPickupQueueByLocationQuery, NotCompletedPickupQueueByLocationQueryVariables>(NotCompletedPickupQueueByLocationDocument, options);
      }
export function useNotCompletedPickupQueueByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotCompletedPickupQueueByLocationQuery, NotCompletedPickupQueueByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotCompletedPickupQueueByLocationQuery, NotCompletedPickupQueueByLocationQueryVariables>(NotCompletedPickupQueueByLocationDocument, options);
        }
export type NotCompletedPickupQueueByLocationQueryHookResult = ReturnType<typeof useNotCompletedPickupQueueByLocationQuery>;
export type NotCompletedPickupQueueByLocationLazyQueryHookResult = ReturnType<typeof useNotCompletedPickupQueueByLocationLazyQuery>;
export type NotCompletedPickupQueueByLocationQueryResult = Apollo.QueryResult<NotCompletedPickupQueueByLocationQuery, NotCompletedPickupQueueByLocationQueryVariables>;
export const PickupQueueItemDocument = gql`
    query PickupQueueItem($locationId: Int!, $customerId: Int!, $isAssignedNull: Boolean!, $status: PickupQueueStatusFilter!) {
  allPickupQueues(
    filter: {locationId: {equalTo: $locationId}, customerId: {equalTo: $customerId}, assignedUserId: {isNull: $isAssignedNull}, status: $status}
  ) {
    nodes {
      ...PickupQueueEntry
    }
  }
}
    ${PickupQueueEntryFragmentDoc}`;

/**
 * __usePickupQueueItemQuery__
 *
 * To run a query within a React component, call `usePickupQueueItemQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickupQueueItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickupQueueItemQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      customerId: // value for 'customerId'
 *      isAssignedNull: // value for 'isAssignedNull'
 *      status: // value for 'status'
 *   },
 * });
 */
export function usePickupQueueItemQuery(baseOptions: Apollo.QueryHookOptions<PickupQueueItemQuery, PickupQueueItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickupQueueItemQuery, PickupQueueItemQueryVariables>(PickupQueueItemDocument, options);
      }
export function usePickupQueueItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickupQueueItemQuery, PickupQueueItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickupQueueItemQuery, PickupQueueItemQueryVariables>(PickupQueueItemDocument, options);
        }
export type PickupQueueItemQueryHookResult = ReturnType<typeof usePickupQueueItemQuery>;
export type PickupQueueItemLazyQueryHookResult = ReturnType<typeof usePickupQueueItemLazyQuery>;
export type PickupQueueItemQueryResult = Apollo.QueryResult<PickupQueueItemQuery, PickupQueueItemQueryVariables>;
export const NotCompletedPickupQueueDocument = gql`
    query NotCompletedPickupQueue {
  allPickupQueues(filter: {status: {notIn: COMPLETED}}) {
    nodes {
      ...PickupQueueEntry
    }
  }
}
    ${PickupQueueEntryFragmentDoc}`;

/**
 * __useNotCompletedPickupQueueQuery__
 *
 * To run a query within a React component, call `useNotCompletedPickupQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotCompletedPickupQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotCompletedPickupQueueQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotCompletedPickupQueueQuery(baseOptions?: Apollo.QueryHookOptions<NotCompletedPickupQueueQuery, NotCompletedPickupQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotCompletedPickupQueueQuery, NotCompletedPickupQueueQueryVariables>(NotCompletedPickupQueueDocument, options);
      }
export function useNotCompletedPickupQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotCompletedPickupQueueQuery, NotCompletedPickupQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotCompletedPickupQueueQuery, NotCompletedPickupQueueQueryVariables>(NotCompletedPickupQueueDocument, options);
        }
export type NotCompletedPickupQueueQueryHookResult = ReturnType<typeof useNotCompletedPickupQueueQuery>;
export type NotCompletedPickupQueueLazyQueryHookResult = ReturnType<typeof useNotCompletedPickupQueueLazyQuery>;
export type NotCompletedPickupQueueQueryResult = Apollo.QueryResult<NotCompletedPickupQueueQuery, NotCompletedPickupQueueQueryVariables>;
export const ClearPickupQueueItemDocument = gql`
    mutation ClearPickupQueueItem($id: Int!) {
  clearPickupQueueItem(input: {pickupQueueId: $id}) {
    pickupQueue {
      ...PickupQueueEntry
    }
  }
}
    ${PickupQueueEntryFragmentDoc}`;
export type ClearPickupQueueItemMutationFn = Apollo.MutationFunction<ClearPickupQueueItemMutation, ClearPickupQueueItemMutationVariables>;

/**
 * __useClearPickupQueueItemMutation__
 *
 * To run a mutation, you first call `useClearPickupQueueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearPickupQueueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearPickupQueueItemMutation, { data, loading, error }] = useClearPickupQueueItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useClearPickupQueueItemMutation(baseOptions?: Apollo.MutationHookOptions<ClearPickupQueueItemMutation, ClearPickupQueueItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearPickupQueueItemMutation, ClearPickupQueueItemMutationVariables>(ClearPickupQueueItemDocument, options);
      }
export type ClearPickupQueueItemMutationHookResult = ReturnType<typeof useClearPickupQueueItemMutation>;
export type ClearPickupQueueItemMutationResult = Apollo.MutationResult<ClearPickupQueueItemMutation>;
export type ClearPickupQueueItemMutationOptions = Apollo.BaseMutationOptions<ClearPickupQueueItemMutation, ClearPickupQueueItemMutationVariables>;
export const DeletePickupQueueItemDocument = gql`
    mutation DeletePickupQueueItem($id: Int!) {
  deletePickupQueueItem(input: {pickupQueueId: $id}) {
    clientMutationId
  }
}
    `;
export type DeletePickupQueueItemMutationFn = Apollo.MutationFunction<DeletePickupQueueItemMutation, DeletePickupQueueItemMutationVariables>;

/**
 * __useDeletePickupQueueItemMutation__
 *
 * To run a mutation, you first call `useDeletePickupQueueItemMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePickupQueueItemMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePickupQueueItemMutation, { data, loading, error }] = useDeletePickupQueueItemMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePickupQueueItemMutation(baseOptions?: Apollo.MutationHookOptions<DeletePickupQueueItemMutation, DeletePickupQueueItemMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePickupQueueItemMutation, DeletePickupQueueItemMutationVariables>(DeletePickupQueueItemDocument, options);
      }
export type DeletePickupQueueItemMutationHookResult = ReturnType<typeof useDeletePickupQueueItemMutation>;
export type DeletePickupQueueItemMutationResult = Apollo.MutationResult<DeletePickupQueueItemMutation>;
export type DeletePickupQueueItemMutationOptions = Apollo.BaseMutationOptions<DeletePickupQueueItemMutation, DeletePickupQueueItemMutationVariables>;
export const LivePickupQueueDocument = gql`
    query LivePickupQueue($locationId: Int!) {
  allLivePickupQueues(condition: {locationId: $locationId}) {
    nodes {
      ...LivePickupQueueEntry
    }
  }
}
    ${LivePickupQueueEntryFragmentDoc}`;

/**
 * __useLivePickupQueueQuery__
 *
 * To run a query within a React component, call `useLivePickupQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useLivePickupQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLivePickupQueueQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLivePickupQueueQuery(baseOptions: Apollo.QueryHookOptions<LivePickupQueueQuery, LivePickupQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LivePickupQueueQuery, LivePickupQueueQueryVariables>(LivePickupQueueDocument, options);
      }
export function useLivePickupQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LivePickupQueueQuery, LivePickupQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LivePickupQueueQuery, LivePickupQueueQueryVariables>(LivePickupQueueDocument, options);
        }
export type LivePickupQueueQueryHookResult = ReturnType<typeof useLivePickupQueueQuery>;
export type LivePickupQueueLazyQueryHookResult = ReturnType<typeof useLivePickupQueueLazyQuery>;
export type LivePickupQueueQueryResult = Apollo.QueryResult<LivePickupQueueQuery, LivePickupQueueQueryVariables>;
export const DownloadPaperlessAppDocument = gql`
    mutation DownloadPaperlessApp($input: DownloadPaperlessAppInput!) {
  downloadPaperlessApp(input: $input) {
    downloadUrl
    version
  }
}
    `;
export type DownloadPaperlessAppMutationFn = Apollo.MutationFunction<DownloadPaperlessAppMutation, DownloadPaperlessAppMutationVariables>;

/**
 * __useDownloadPaperlessAppMutation__
 *
 * To run a mutation, you first call `useDownloadPaperlessAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadPaperlessAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadPaperlessAppMutation, { data, loading, error }] = useDownloadPaperlessAppMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadPaperlessAppMutation(baseOptions?: Apollo.MutationHookOptions<DownloadPaperlessAppMutation, DownloadPaperlessAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadPaperlessAppMutation, DownloadPaperlessAppMutationVariables>(DownloadPaperlessAppDocument, options);
      }
export type DownloadPaperlessAppMutationHookResult = ReturnType<typeof useDownloadPaperlessAppMutation>;
export type DownloadPaperlessAppMutationResult = Apollo.MutationResult<DownloadPaperlessAppMutation>;
export type DownloadPaperlessAppMutationOptions = Apollo.BaseMutationOptions<DownloadPaperlessAppMutation, DownloadPaperlessAppMutationVariables>;
export const AllPrintersDocument = gql`
    query AllPrinters($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [PrintersOrderBy!], $filter: PrinterFilter) {
  allPrinters(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllPrintersEntry
    }
  }
}
    ${AllPrintersEntryFragmentDoc}`;

/**
 * __useAllPrintersQuery__
 *
 * To run a query within a React component, call `useAllPrintersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllPrintersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllPrintersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllPrintersQuery(baseOptions?: Apollo.QueryHookOptions<AllPrintersQuery, AllPrintersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllPrintersQuery, AllPrintersQueryVariables>(AllPrintersDocument, options);
      }
export function useAllPrintersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllPrintersQuery, AllPrintersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllPrintersQuery, AllPrintersQueryVariables>(AllPrintersDocument, options);
        }
export type AllPrintersQueryHookResult = ReturnType<typeof useAllPrintersQuery>;
export type AllPrintersLazyQueryHookResult = ReturnType<typeof useAllPrintersLazyQuery>;
export type AllPrintersQueryResult = Apollo.QueryResult<AllPrintersQuery, AllPrintersQueryVariables>;
export const GetPrinterDocument = gql`
    query GetPrinter($id: Int!) {
  printerById(id: $id) {
    ...PrinterEntry
  }
}
    ${PrinterEntryFragmentDoc}`;

/**
 * __useGetPrinterQuery__
 *
 * To run a query within a React component, call `useGetPrinterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPrinterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPrinterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPrinterQuery(baseOptions: Apollo.QueryHookOptions<GetPrinterQuery, GetPrinterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPrinterQuery, GetPrinterQueryVariables>(GetPrinterDocument, options);
      }
export function useGetPrinterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPrinterQuery, GetPrinterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPrinterQuery, GetPrinterQueryVariables>(GetPrinterDocument, options);
        }
export type GetPrinterQueryHookResult = ReturnType<typeof useGetPrinterQuery>;
export type GetPrinterLazyQueryHookResult = ReturnType<typeof useGetPrinterLazyQuery>;
export type GetPrinterQueryResult = Apollo.QueryResult<GetPrinterQuery, GetPrinterQueryVariables>;
export const CreatePrinterDocument = gql`
    mutation CreatePrinter($values: PrinterInput!) {
  createPrinter(input: {printer: $values}) {
    printer {
      ...PrinterEntry
    }
  }
}
    ${PrinterEntryFragmentDoc}`;
export type CreatePrinterMutationFn = Apollo.MutationFunction<CreatePrinterMutation, CreatePrinterMutationVariables>;

/**
 * __useCreatePrinterMutation__
 *
 * To run a mutation, you first call `useCreatePrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPrinterMutation, { data, loading, error }] = useCreatePrinterMutation({
 *   variables: {
 *      values: // value for 'values'
 *   },
 * });
 */
export function useCreatePrinterMutation(baseOptions?: Apollo.MutationHookOptions<CreatePrinterMutation, CreatePrinterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePrinterMutation, CreatePrinterMutationVariables>(CreatePrinterDocument, options);
      }
export type CreatePrinterMutationHookResult = ReturnType<typeof useCreatePrinterMutation>;
export type CreatePrinterMutationResult = Apollo.MutationResult<CreatePrinterMutation>;
export type CreatePrinterMutationOptions = Apollo.BaseMutationOptions<CreatePrinterMutation, CreatePrinterMutationVariables>;
export const UpdatePrinterDocument = gql`
    mutation UpdatePrinter($id: Int!, $values: PrinterPatch!) {
  updatePrinterById(input: {id: $id, printerPatch: $values}) {
    printer {
      ...PrinterEntry
    }
  }
}
    ${PrinterEntryFragmentDoc}`;
export type UpdatePrinterMutationFn = Apollo.MutationFunction<UpdatePrinterMutation, UpdatePrinterMutationVariables>;

/**
 * __useUpdatePrinterMutation__
 *
 * To run a mutation, you first call `useUpdatePrinterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePrinterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePrinterMutation, { data, loading, error }] = useUpdatePrinterMutation({
 *   variables: {
 *      id: // value for 'id'
 *      values: // value for 'values'
 *   },
 * });
 */
export function useUpdatePrinterMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePrinterMutation, UpdatePrinterMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePrinterMutation, UpdatePrinterMutationVariables>(UpdatePrinterDocument, options);
      }
export type UpdatePrinterMutationHookResult = ReturnType<typeof useUpdatePrinterMutation>;
export type UpdatePrinterMutationResult = Apollo.MutationResult<UpdatePrinterMutation>;
export type UpdatePrinterMutationOptions = Apollo.BaseMutationOptions<UpdatePrinterMutation, UpdatePrinterMutationVariables>;
export const RunTaxReportDocument = gql`
    query RunTaxReport($startDate: Datetime!, $endDate: Datetime!) {
  runTaxReport(startDate: $startDate, endDate: $endDate) {
    totalCount
    nodes {
      auctionSales
      creditCardRefunds
      locationName
      locationTax
      reportedGrossSales
      salesTaxDue
      taxExemptSales
      totalTaxableSales
    }
  }
}
    `;

/**
 * __useRunTaxReportQuery__
 *
 * To run a query within a React component, call `useRunTaxReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunTaxReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunTaxReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRunTaxReportQuery(baseOptions: Apollo.QueryHookOptions<RunTaxReportQuery, RunTaxReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunTaxReportQuery, RunTaxReportQueryVariables>(RunTaxReportDocument, options);
      }
export function useRunTaxReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunTaxReportQuery, RunTaxReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunTaxReportQuery, RunTaxReportQueryVariables>(RunTaxReportDocument, options);
        }
export type RunTaxReportQueryHookResult = ReturnType<typeof useRunTaxReportQuery>;
export type RunTaxReportLazyQueryHookResult = ReturnType<typeof useRunTaxReportLazyQuery>;
export type RunTaxReportQueryResult = Apollo.QueryResult<RunTaxReportQuery, RunTaxReportQueryVariables>;
export const RunAuctionSummaryReportDocument = gql`
    query RunAuctionSummaryReport($startDate: Datetime!, $endDate: Datetime!) {
  runAuctionSummaryReport(startDate: $startDate, endDate: $endDate) {
    totalCount
    nodes {
      auctionId
      auctionName
      avgPricePerItem
      countItems
      totalRetailPrice
      totalSalePrice
      countItemsNotSold
      countItemsSold
      retailPercent
    }
  }
}
    `;

/**
 * __useRunAuctionSummaryReportQuery__
 *
 * To run a query within a React component, call `useRunAuctionSummaryReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunAuctionSummaryReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunAuctionSummaryReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useRunAuctionSummaryReportQuery(baseOptions: Apollo.QueryHookOptions<RunAuctionSummaryReportQuery, RunAuctionSummaryReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunAuctionSummaryReportQuery, RunAuctionSummaryReportQueryVariables>(RunAuctionSummaryReportDocument, options);
      }
export function useRunAuctionSummaryReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunAuctionSummaryReportQuery, RunAuctionSummaryReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunAuctionSummaryReportQuery, RunAuctionSummaryReportQueryVariables>(RunAuctionSummaryReportDocument, options);
        }
export type RunAuctionSummaryReportQueryHookResult = ReturnType<typeof useRunAuctionSummaryReportQuery>;
export type RunAuctionSummaryReportLazyQueryHookResult = ReturnType<typeof useRunAuctionSummaryReportLazyQuery>;
export type RunAuctionSummaryReportQueryResult = Apollo.QueryResult<RunAuctionSummaryReportQuery, RunAuctionSummaryReportQueryVariables>;
export const RunReturnsReportDocument = gql`
    query RunReturnsReport($startDate: Datetime!, $endDate: Datetime!, $groupBy: ReportGroupBy!) {
  runItemSoldReport(startDate: $startDate, endDate: $endDate, groupBy: $groupBy) {
    nodes {
      date
      count
    }
  }
  runCompanyReturnRateReport(
    startDate: $startDate
    endDate: $endDate
    groupBy: $groupBy
  ) {
    nodes {
      date
      percent
    }
  }
  runReturnReportEmployeeReturnRates(startDate: $startDate, endDate: $endDate) {
    nodes {
      employeeName
      totalPickedUp
      totalReturns
      percent
      avgPercent
    }
  }
  runReturnReportPie(startDate: $startDate, endDate: $endDate) {
    nodes {
      percent
      reason
      num
    }
  }
}
    `;

/**
 * __useRunReturnsReportQuery__
 *
 * To run a query within a React component, call `useRunReturnsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunReturnsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunReturnsReportQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *      groupBy: // value for 'groupBy'
 *   },
 * });
 */
export function useRunReturnsReportQuery(baseOptions: Apollo.QueryHookOptions<RunReturnsReportQuery, RunReturnsReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RunReturnsReportQuery, RunReturnsReportQueryVariables>(RunReturnsReportDocument, options);
      }
export function useRunReturnsReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RunReturnsReportQuery, RunReturnsReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RunReturnsReportQuery, RunReturnsReportQueryVariables>(RunReturnsReportDocument, options);
        }
export type RunReturnsReportQueryHookResult = ReturnType<typeof useRunReturnsReportQuery>;
export type RunReturnsReportLazyQueryHookResult = ReturnType<typeof useRunReturnsReportLazyQuery>;
export type RunReturnsReportQueryResult = Apollo.QueryResult<RunReturnsReportQuery, RunReturnsReportQueryVariables>;
export const DownloadReturnReportDocument = gql`
    mutation DownloadReturnReport($input: DownloadReturnReportInput!) {
  downloadReturnReport(input: $input) {
    clientMutationId
  }
}
    `;
export type DownloadReturnReportMutationFn = Apollo.MutationFunction<DownloadReturnReportMutation, DownloadReturnReportMutationVariables>;

/**
 * __useDownloadReturnReportMutation__
 *
 * To run a mutation, you first call `useDownloadReturnReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDownloadReturnReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [downloadReturnReportMutation, { data, loading, error }] = useDownloadReturnReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadReturnReportMutation(baseOptions?: Apollo.MutationHookOptions<DownloadReturnReportMutation, DownloadReturnReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DownloadReturnReportMutation, DownloadReturnReportMutationVariables>(DownloadReturnReportDocument, options);
      }
export type DownloadReturnReportMutationHookResult = ReturnType<typeof useDownloadReturnReportMutation>;
export type DownloadReturnReportMutationResult = Apollo.MutationResult<DownloadReturnReportMutation>;
export type DownloadReturnReportMutationOptions = Apollo.BaseMutationOptions<DownloadReturnReportMutation, DownloadReturnReportMutationVariables>;
export const AllReturnOptionsDocument = gql`
    query AllReturnOptions($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [ReturnOptionsOrderBy!], $filter: ReturnOptionFilter) {
  allReturnOptions(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...ReturnOptionEntry
    }
  }
}
    ${ReturnOptionEntryFragmentDoc}`;

/**
 * __useAllReturnOptionsQuery__
 *
 * To run a query within a React component, call `useAllReturnOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReturnOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReturnOptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllReturnOptionsQuery(baseOptions?: Apollo.QueryHookOptions<AllReturnOptionsQuery, AllReturnOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllReturnOptionsQuery, AllReturnOptionsQueryVariables>(AllReturnOptionsDocument, options);
      }
export function useAllReturnOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllReturnOptionsQuery, AllReturnOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllReturnOptionsQuery, AllReturnOptionsQueryVariables>(AllReturnOptionsDocument, options);
        }
export type AllReturnOptionsQueryHookResult = ReturnType<typeof useAllReturnOptionsQuery>;
export type AllReturnOptionsLazyQueryHookResult = ReturnType<typeof useAllReturnOptionsLazyQuery>;
export type AllReturnOptionsQueryResult = Apollo.QueryResult<AllReturnOptionsQuery, AllReturnOptionsQueryVariables>;
export const ReturnOptionByIdDocument = gql`
    query ReturnOptionById($id: UUID!) {
  returnOptionById(id: $id) {
    ...ReturnOptionEntry
  }
}
    ${ReturnOptionEntryFragmentDoc}`;

/**
 * __useReturnOptionByIdQuery__
 *
 * To run a query within a React component, call `useReturnOptionByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useReturnOptionByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReturnOptionByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReturnOptionByIdQuery(baseOptions: Apollo.QueryHookOptions<ReturnOptionByIdQuery, ReturnOptionByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReturnOptionByIdQuery, ReturnOptionByIdQueryVariables>(ReturnOptionByIdDocument, options);
      }
export function useReturnOptionByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReturnOptionByIdQuery, ReturnOptionByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReturnOptionByIdQuery, ReturnOptionByIdQueryVariables>(ReturnOptionByIdDocument, options);
        }
export type ReturnOptionByIdQueryHookResult = ReturnType<typeof useReturnOptionByIdQuery>;
export type ReturnOptionByIdLazyQueryHookResult = ReturnType<typeof useReturnOptionByIdLazyQuery>;
export type ReturnOptionByIdQueryResult = Apollo.QueryResult<ReturnOptionByIdQuery, ReturnOptionByIdQueryVariables>;
export const UpsertReturnOptionDocument = gql`
    mutation UpsertReturnOption($input: UpsertReturnOptionInput!, $where: UpsertReturnOptionWhere) {
  upsertReturnOption(input: $input, where: $where) {
    returnOption {
      ...ReturnOptionEntry
    }
  }
}
    ${ReturnOptionEntryFragmentDoc}`;
export type UpsertReturnOptionMutationFn = Apollo.MutationFunction<UpsertReturnOptionMutation, UpsertReturnOptionMutationVariables>;

/**
 * __useUpsertReturnOptionMutation__
 *
 * To run a mutation, you first call `useUpsertReturnOptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertReturnOptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertReturnOptionMutation, { data, loading, error }] = useUpsertReturnOptionMutation({
 *   variables: {
 *      input: // value for 'input'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useUpsertReturnOptionMutation(baseOptions?: Apollo.MutationHookOptions<UpsertReturnOptionMutation, UpsertReturnOptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertReturnOptionMutation, UpsertReturnOptionMutationVariables>(UpsertReturnOptionDocument, options);
      }
export type UpsertReturnOptionMutationHookResult = ReturnType<typeof useUpsertReturnOptionMutation>;
export type UpsertReturnOptionMutationResult = Apollo.MutationResult<UpsertReturnOptionMutation>;
export type UpsertReturnOptionMutationOptions = Apollo.BaseMutationOptions<UpsertReturnOptionMutation, UpsertReturnOptionMutationVariables>;
export const GetReturnOptionsDocument = gql`
    query GetReturnOptions($value: String!) {
  allReturnOptions(
    orderBy: NAME_ASC
    filter: {name: {includesInsensitive: $value}}
  ) {
    nodes {
      ...ReturnOptionEntry
    }
  }
}
    ${ReturnOptionEntryFragmentDoc}`;

/**
 * __useGetReturnOptionsQuery__
 *
 * To run a query within a React component, call `useGetReturnOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnOptionsQuery({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetReturnOptionsQuery(baseOptions: Apollo.QueryHookOptions<GetReturnOptionsQuery, GetReturnOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnOptionsQuery, GetReturnOptionsQueryVariables>(GetReturnOptionsDocument, options);
      }
export function useGetReturnOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnOptionsQuery, GetReturnOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnOptionsQuery, GetReturnOptionsQueryVariables>(GetReturnOptionsDocument, options);
        }
export type GetReturnOptionsQueryHookResult = ReturnType<typeof useGetReturnOptionsQuery>;
export type GetReturnOptionsLazyQueryHookResult = ReturnType<typeof useGetReturnOptionsLazyQuery>;
export type GetReturnOptionsQueryResult = Apollo.QueryResult<GetReturnOptionsQuery, GetReturnOptionsQueryVariables>;
export const AllReturnQueueDocument = gql`
    query AllReturnQueue($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [ReturnQueuesOrderBy!], $filter: ReturnQueueFilter) {
  allReturnQueues(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...ReturnQueueListEntry
    }
  }
}
    ${ReturnQueueListEntryFragmentDoc}`;

/**
 * __useAllReturnQueueQuery__
 *
 * To run a query within a React component, call `useAllReturnQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllReturnQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllReturnQueueQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllReturnQueueQuery(baseOptions?: Apollo.QueryHookOptions<AllReturnQueueQuery, AllReturnQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllReturnQueueQuery, AllReturnQueueQueryVariables>(AllReturnQueueDocument, options);
      }
export function useAllReturnQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllReturnQueueQuery, AllReturnQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllReturnQueueQuery, AllReturnQueueQueryVariables>(AllReturnQueueDocument, options);
        }
export type AllReturnQueueQueryHookResult = ReturnType<typeof useAllReturnQueueQuery>;
export type AllReturnQueueLazyQueryHookResult = ReturnType<typeof useAllReturnQueueLazyQuery>;
export type AllReturnQueueQueryResult = Apollo.QueryResult<AllReturnQueueQuery, AllReturnQueueQueryVariables>;
export const BidWranglerReturnQueueDocument = gql`
    query BidWranglerReturnQueue($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [ReturnQueuesOrderBy!], $filter: ReturnQueueFilter) {
  bidwranglerReturnQueue(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...ReturnQueueListEntry
    }
  }
}
    ${ReturnQueueListEntryFragmentDoc}`;

/**
 * __useBidWranglerReturnQueueQuery__
 *
 * To run a query within a React component, call `useBidWranglerReturnQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useBidWranglerReturnQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBidWranglerReturnQueueQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useBidWranglerReturnQueueQuery(baseOptions?: Apollo.QueryHookOptions<BidWranglerReturnQueueQuery, BidWranglerReturnQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BidWranglerReturnQueueQuery, BidWranglerReturnQueueQueryVariables>(BidWranglerReturnQueueDocument, options);
      }
export function useBidWranglerReturnQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BidWranglerReturnQueueQuery, BidWranglerReturnQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BidWranglerReturnQueueQuery, BidWranglerReturnQueueQueryVariables>(BidWranglerReturnQueueDocument, options);
        }
export type BidWranglerReturnQueueQueryHookResult = ReturnType<typeof useBidWranglerReturnQueueQuery>;
export type BidWranglerReturnQueueLazyQueryHookResult = ReturnType<typeof useBidWranglerReturnQueueLazyQuery>;
export type BidWranglerReturnQueueQueryResult = Apollo.QueryResult<BidWranglerReturnQueueQuery, BidWranglerReturnQueueQueryVariables>;
export const MercuryReturnQueueDocument = gql`
    query MercuryReturnQueue($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [ReturnQueuesOrderBy!], $filter: ReturnQueueFilter) {
  mercuryReturnQueue(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...ReturnQueueListEntry
    }
  }
}
    ${ReturnQueueListEntryFragmentDoc}`;

/**
 * __useMercuryReturnQueueQuery__
 *
 * To run a query within a React component, call `useMercuryReturnQueueQuery` and pass it any options that fit your needs.
 * When your component renders, `useMercuryReturnQueueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMercuryReturnQueueQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMercuryReturnQueueQuery(baseOptions?: Apollo.QueryHookOptions<MercuryReturnQueueQuery, MercuryReturnQueueQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MercuryReturnQueueQuery, MercuryReturnQueueQueryVariables>(MercuryReturnQueueDocument, options);
      }
export function useMercuryReturnQueueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MercuryReturnQueueQuery, MercuryReturnQueueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MercuryReturnQueueQuery, MercuryReturnQueueQueryVariables>(MercuryReturnQueueDocument, options);
        }
export type MercuryReturnQueueQueryHookResult = ReturnType<typeof useMercuryReturnQueueQuery>;
export type MercuryReturnQueueLazyQueryHookResult = ReturnType<typeof useMercuryReturnQueueLazyQuery>;
export type MercuryReturnQueueQueryResult = Apollo.QueryResult<MercuryReturnQueueQuery, MercuryReturnQueueQueryVariables>;
export const GetReturnQueueByIdDocument = gql`
    query GetReturnQueueById($id: UUID!) {
  returnQueueById(id: $id) {
    ...ReturnQueueEntry
  }
}
    ${ReturnQueueEntryFragmentDoc}`;

/**
 * __useGetReturnQueueByIdQuery__
 *
 * To run a query within a React component, call `useGetReturnQueueByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReturnQueueByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReturnQueueByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetReturnQueueByIdQuery(baseOptions: Apollo.QueryHookOptions<GetReturnQueueByIdQuery, GetReturnQueueByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReturnQueueByIdQuery, GetReturnQueueByIdQueryVariables>(GetReturnQueueByIdDocument, options);
      }
export function useGetReturnQueueByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReturnQueueByIdQuery, GetReturnQueueByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReturnQueueByIdQuery, GetReturnQueueByIdQueryVariables>(GetReturnQueueByIdDocument, options);
        }
export type GetReturnQueueByIdQueryHookResult = ReturnType<typeof useGetReturnQueueByIdQuery>;
export type GetReturnQueueByIdLazyQueryHookResult = ReturnType<typeof useGetReturnQueueByIdLazyQuery>;
export type GetReturnQueueByIdQueryResult = Apollo.QueryResult<GetReturnQueueByIdQuery, GetReturnQueueByIdQueryVariables>;
export const UpdateReturnQueueStatusDocument = gql`
    mutation UpdateReturnQueueStatus($id: UUID!, $status: ReturnQueueStatus!) {
  updateReturnQueueById(input: {id: $id, returnQueuePatch: {status: $status}}) {
    returnQueue {
      ...ReturnQueueEntry
    }
  }
}
    ${ReturnQueueEntryFragmentDoc}`;
export type UpdateReturnQueueStatusMutationFn = Apollo.MutationFunction<UpdateReturnQueueStatusMutation, UpdateReturnQueueStatusMutationVariables>;

/**
 * __useUpdateReturnQueueStatusMutation__
 *
 * To run a mutation, you first call `useUpdateReturnQueueStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReturnQueueStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReturnQueueStatusMutation, { data, loading, error }] = useUpdateReturnQueueStatusMutation({
 *   variables: {
 *      id: // value for 'id'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateReturnQueueStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReturnQueueStatusMutation, UpdateReturnQueueStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReturnQueueStatusMutation, UpdateReturnQueueStatusMutationVariables>(UpdateReturnQueueStatusDocument, options);
      }
export type UpdateReturnQueueStatusMutationHookResult = ReturnType<typeof useUpdateReturnQueueStatusMutation>;
export type UpdateReturnQueueStatusMutationResult = Apollo.MutationResult<UpdateReturnQueueStatusMutation>;
export type UpdateReturnQueueStatusMutationOptions = Apollo.BaseMutationOptions<UpdateReturnQueueStatusMutation, UpdateReturnQueueStatusMutationVariables>;
export const ShippingQueueByLocationDocument = gql`
    query ShippingQueueByLocation($locationId: Int!) {
  shippingUnassignedItems(locationId: $locationId) {
    nodes {
      ...ShippingQueueItemEntry
    }
  }
  shippingAssignedItems(locationId: $locationId) {
    nodes {
      ...ShippingQueueItemEntry
    }
  }
}
    ${ShippingQueueItemEntryFragmentDoc}`;

/**
 * __useShippingQueueByLocationQuery__
 *
 * To run a query within a React component, call `useShippingQueueByLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingQueueByLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingQueueByLocationQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useShippingQueueByLocationQuery(baseOptions: Apollo.QueryHookOptions<ShippingQueueByLocationQuery, ShippingQueueByLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShippingQueueByLocationQuery, ShippingQueueByLocationQueryVariables>(ShippingQueueByLocationDocument, options);
      }
export function useShippingQueueByLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShippingQueueByLocationQuery, ShippingQueueByLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShippingQueueByLocationQuery, ShippingQueueByLocationQueryVariables>(ShippingQueueByLocationDocument, options);
        }
export type ShippingQueueByLocationQueryHookResult = ReturnType<typeof useShippingQueueByLocationQuery>;
export type ShippingQueueByLocationLazyQueryHookResult = ReturnType<typeof useShippingQueueByLocationLazyQuery>;
export type ShippingQueueByLocationQueryResult = Apollo.QueryResult<ShippingQueueByLocationQuery, ShippingQueueByLocationQueryVariables>;
export const ShippingQueueItemDocument = gql`
    query ShippingQueueItem($locationId: Int!, $customerId: Int!, $isAssignedNull: Boolean!, $status: ShippingQueueStatusFilter!) {
  allShippingQueues(
    filter: {locationId: {equalTo: $locationId}, customerId: {equalTo: $customerId}, assignedUserId: {isNull: $isAssignedNull}, status: $status}
    orderBy: ITEM_NUM_ASC
  ) {
    nodes {
      ...ShippingQueueEntry
    }
  }
}
    ${ShippingQueueEntryFragmentDoc}`;

/**
 * __useShippingQueueItemQuery__
 *
 * To run a query within a React component, call `useShippingQueueItemQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingQueueItemQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingQueueItemQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *      customerId: // value for 'customerId'
 *      isAssignedNull: // value for 'isAssignedNull'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useShippingQueueItemQuery(baseOptions: Apollo.QueryHookOptions<ShippingQueueItemQuery, ShippingQueueItemQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShippingQueueItemQuery, ShippingQueueItemQueryVariables>(ShippingQueueItemDocument, options);
      }
export function useShippingQueueItemLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShippingQueueItemQuery, ShippingQueueItemQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShippingQueueItemQuery, ShippingQueueItemQueryVariables>(ShippingQueueItemDocument, options);
        }
export type ShippingQueueItemQueryHookResult = ReturnType<typeof useShippingQueueItemQuery>;
export type ShippingQueueItemLazyQueryHookResult = ReturnType<typeof useShippingQueueItemLazyQuery>;
export type ShippingQueueItemQueryResult = Apollo.QueryResult<ShippingQueueItemQuery, ShippingQueueItemQueryVariables>;
export const DeleteShippingQueueDocument = gql`
    mutation DeleteShippingQueue($input: DeleteShippingQueueByIdInput!) {
  deleteShippingQueueById(input: $input) {
    deletedShippingQueueId
  }
}
    `;
export type DeleteShippingQueueMutationFn = Apollo.MutationFunction<DeleteShippingQueueMutation, DeleteShippingQueueMutationVariables>;

/**
 * __useDeleteShippingQueueMutation__
 *
 * To run a mutation, you first call `useDeleteShippingQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteShippingQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteShippingQueueMutation, { data, loading, error }] = useDeleteShippingQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteShippingQueueMutation(baseOptions?: Apollo.MutationHookOptions<DeleteShippingQueueMutation, DeleteShippingQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteShippingQueueMutation, DeleteShippingQueueMutationVariables>(DeleteShippingQueueDocument, options);
      }
export type DeleteShippingQueueMutationHookResult = ReturnType<typeof useDeleteShippingQueueMutation>;
export type DeleteShippingQueueMutationResult = Apollo.MutationResult<DeleteShippingQueueMutation>;
export type DeleteShippingQueueMutationOptions = Apollo.BaseMutationOptions<DeleteShippingQueueMutation, DeleteShippingQueueMutationVariables>;
export const UpdateShippingQueueDocument = gql`
    mutation UpdateShippingQueue($input: UpdateShippingQueueByIdInput!) {
  updateShippingQueueById(input: $input) {
    shippingQueue {
      ...ShippingQueueEntry
    }
  }
}
    ${ShippingQueueEntryFragmentDoc}`;
export type UpdateShippingQueueMutationFn = Apollo.MutationFunction<UpdateShippingQueueMutation, UpdateShippingQueueMutationVariables>;

/**
 * __useUpdateShippingQueueMutation__
 *
 * To run a mutation, you first call `useUpdateShippingQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateShippingQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateShippingQueueMutation, { data, loading, error }] = useUpdateShippingQueueMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateShippingQueueMutation(baseOptions?: Apollo.MutationHookOptions<UpdateShippingQueueMutation, UpdateShippingQueueMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateShippingQueueMutation, UpdateShippingQueueMutationVariables>(UpdateShippingQueueDocument, options);
      }
export type UpdateShippingQueueMutationHookResult = ReturnType<typeof useUpdateShippingQueueMutation>;
export type UpdateShippingQueueMutationResult = Apollo.MutationResult<UpdateShippingQueueMutation>;
export type UpdateShippingQueueMutationOptions = Apollo.BaseMutationOptions<UpdateShippingQueueMutation, UpdateShippingQueueMutationVariables>;
export const AllTransactionsDocument = gql`
    query AllTransactions($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [TransactionsOrderBy!], $filter: TransactionFilter) {
  allTransactions(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...TransactionEntry
    }
  }
}
    ${TransactionEntryFragmentDoc}`;

/**
 * __useAllTransactionsQuery__
 *
 * To run a query within a React component, call `useAllTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTransactionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllTransactionsQuery(baseOptions?: Apollo.QueryHookOptions<AllTransactionsQuery, AllTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTransactionsQuery, AllTransactionsQueryVariables>(AllTransactionsDocument, options);
      }
export function useAllTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTransactionsQuery, AllTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTransactionsQuery, AllTransactionsQueryVariables>(AllTransactionsDocument, options);
        }
export type AllTransactionsQueryHookResult = ReturnType<typeof useAllTransactionsQuery>;
export type AllTransactionsLazyQueryHookResult = ReturnType<typeof useAllTransactionsLazyQuery>;
export type AllTransactionsQueryResult = Apollo.QueryResult<AllTransactionsQuery, AllTransactionsQueryVariables>;
export const GetTransactionDocument = gql`
    query GetTransaction($id: Int!) {
  transactionById(id: $id) {
    ...TransactionEntry
  }
}
    ${TransactionEntryFragmentDoc}`;

/**
 * __useGetTransactionQuery__
 *
 * To run a query within a React component, call `useGetTransactionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransactionQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, options);
      }
export function useGetTransactionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionQuery, GetTransactionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionQuery, GetTransactionQueryVariables>(GetTransactionDocument, options);
        }
export type GetTransactionQueryHookResult = ReturnType<typeof useGetTransactionQuery>;
export type GetTransactionLazyQueryHookResult = ReturnType<typeof useGetTransactionLazyQuery>;
export type GetTransactionQueryResult = Apollo.QueryResult<GetTransactionQuery, GetTransactionQueryVariables>;
export const GetTransactionsByPaymentIdDocument = gql`
    query GetTransactionsByPaymentId($id: Int!) {
  allTransactions(condition: {paymentId: $id}, orderBy: CREATED_AT_DESC) {
    nodes {
      ...TransactionEntry
    }
  }
}
    ${TransactionEntryFragmentDoc}`;

/**
 * __useGetTransactionsByPaymentIdQuery__
 *
 * To run a query within a React component, call `useGetTransactionsByPaymentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsByPaymentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsByPaymentIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTransactionsByPaymentIdQuery(baseOptions: Apollo.QueryHookOptions<GetTransactionsByPaymentIdQuery, GetTransactionsByPaymentIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTransactionsByPaymentIdQuery, GetTransactionsByPaymentIdQueryVariables>(GetTransactionsByPaymentIdDocument, options);
      }
export function useGetTransactionsByPaymentIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsByPaymentIdQuery, GetTransactionsByPaymentIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTransactionsByPaymentIdQuery, GetTransactionsByPaymentIdQueryVariables>(GetTransactionsByPaymentIdDocument, options);
        }
export type GetTransactionsByPaymentIdQueryHookResult = ReturnType<typeof useGetTransactionsByPaymentIdQuery>;
export type GetTransactionsByPaymentIdLazyQueryHookResult = ReturnType<typeof useGetTransactionsByPaymentIdLazyQuery>;
export type GetTransactionsByPaymentIdQueryResult = Apollo.QueryResult<GetTransactionsByPaymentIdQuery, GetTransactionsByPaymentIdQueryVariables>;
export const AllTruckloadsDocument = gql`
    query AllTruckloads($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [TruckloadsOrderBy!], $filter: TruckloadFilter) {
  allTruckloads(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllTruckloadsEntry
    }
  }
}
    ${AllTruckloadsEntryFragmentDoc}`;

/**
 * __useAllTruckloadsQuery__
 *
 * To run a query within a React component, call `useAllTruckloadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTruckloadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTruckloadsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllTruckloadsQuery(baseOptions?: Apollo.QueryHookOptions<AllTruckloadsQuery, AllTruckloadsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTruckloadsQuery, AllTruckloadsQueryVariables>(AllTruckloadsDocument, options);
      }
export function useAllTruckloadsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTruckloadsQuery, AllTruckloadsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTruckloadsQuery, AllTruckloadsQueryVariables>(AllTruckloadsDocument, options);
        }
export type AllTruckloadsQueryHookResult = ReturnType<typeof useAllTruckloadsQuery>;
export type AllTruckloadsLazyQueryHookResult = ReturnType<typeof useAllTruckloadsLazyQuery>;
export type AllTruckloadsQueryResult = Apollo.QueryResult<AllTruckloadsQuery, AllTruckloadsQueryVariables>;
export const GetTruckloadDocument = gql`
    query GetTruckload($id: UUID!) {
  truckloadById(id: $id) {
    ...TruckloadEntry
  }
  getTruckloadData(id: $id) {
    ...TruckloadDataEntry
  }
}
    ${TruckloadEntryFragmentDoc}
${TruckloadDataEntryFragmentDoc}`;

/**
 * __useGetTruckloadQuery__
 *
 * To run a query within a React component, call `useGetTruckloadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTruckloadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTruckloadQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTruckloadQuery(baseOptions: Apollo.QueryHookOptions<GetTruckloadQuery, GetTruckloadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTruckloadQuery, GetTruckloadQueryVariables>(GetTruckloadDocument, options);
      }
export function useGetTruckloadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTruckloadQuery, GetTruckloadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTruckloadQuery, GetTruckloadQueryVariables>(GetTruckloadDocument, options);
        }
export type GetTruckloadQueryHookResult = ReturnType<typeof useGetTruckloadQuery>;
export type GetTruckloadLazyQueryHookResult = ReturnType<typeof useGetTruckloadLazyQuery>;
export type GetTruckloadQueryResult = Apollo.QueryResult<GetTruckloadQuery, GetTruckloadQueryVariables>;
export const UploadTruckloadBillOfLadingDocument = gql`
    mutation UploadTruckloadBillOfLading($input: UploadTruckloadBillOfLadingInput!) {
  uploadTruckloadBillOfLading(input: $input) {
    ...TruckloadEntry
  }
}
    ${TruckloadEntryFragmentDoc}`;
export type UploadTruckloadBillOfLadingMutationFn = Apollo.MutationFunction<UploadTruckloadBillOfLadingMutation, UploadTruckloadBillOfLadingMutationVariables>;

/**
 * __useUploadTruckloadBillOfLadingMutation__
 *
 * To run a mutation, you first call `useUploadTruckloadBillOfLadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadTruckloadBillOfLadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadTruckloadBillOfLadingMutation, { data, loading, error }] = useUploadTruckloadBillOfLadingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUploadTruckloadBillOfLadingMutation(baseOptions?: Apollo.MutationHookOptions<UploadTruckloadBillOfLadingMutation, UploadTruckloadBillOfLadingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadTruckloadBillOfLadingMutation, UploadTruckloadBillOfLadingMutationVariables>(UploadTruckloadBillOfLadingDocument, options);
      }
export type UploadTruckloadBillOfLadingMutationHookResult = ReturnType<typeof useUploadTruckloadBillOfLadingMutation>;
export type UploadTruckloadBillOfLadingMutationResult = Apollo.MutationResult<UploadTruckloadBillOfLadingMutation>;
export type UploadTruckloadBillOfLadingMutationOptions = Apollo.BaseMutationOptions<UploadTruckloadBillOfLadingMutation, UploadTruckloadBillOfLadingMutationVariables>;
export const UpdateTruckloadDocument = gql`
    mutation UpdateTruckload($input: UpdateTruckloadByIdInput!) {
  updateTruckloadById(input: $input) {
    truckload {
      ...TruckloadEntry
    }
  }
}
    ${TruckloadEntryFragmentDoc}`;
export type UpdateTruckloadMutationFn = Apollo.MutationFunction<UpdateTruckloadMutation, UpdateTruckloadMutationVariables>;

/**
 * __useUpdateTruckloadMutation__
 *
 * To run a mutation, you first call `useUpdateTruckloadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTruckloadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTruckloadMutation, { data, loading, error }] = useUpdateTruckloadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTruckloadMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTruckloadMutation, UpdateTruckloadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTruckloadMutation, UpdateTruckloadMutationVariables>(UpdateTruckloadDocument, options);
      }
export type UpdateTruckloadMutationHookResult = ReturnType<typeof useUpdateTruckloadMutation>;
export type UpdateTruckloadMutationResult = Apollo.MutationResult<UpdateTruckloadMutation>;
export type UpdateTruckloadMutationOptions = Apollo.BaseMutationOptions<UpdateTruckloadMutation, UpdateTruckloadMutationVariables>;
export const CurrentUserDocument = gql`
    query CurrentUser {
  currentUser {
    ...CurrentUserEntry
  }
}
    ${CurrentUserEntryFragmentDoc}`;

/**
 * __useCurrentUserQuery__
 *
 * To run a query within a React component, call `useCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
      }
export function useCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CurrentUserQuery, CurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CurrentUserQuery, CurrentUserQueryVariables>(CurrentUserDocument, options);
        }
export type CurrentUserQueryHookResult = ReturnType<typeof useCurrentUserQuery>;
export type CurrentUserLazyQueryHookResult = ReturnType<typeof useCurrentUserLazyQuery>;
export type CurrentUserQueryResult = Apollo.QueryResult<CurrentUserQuery, CurrentUserQueryVariables>;
export const AllUsersDocument = gql`
    query AllUsers($first: Int, $last: Int, $after: Cursor, $before: Cursor, $orderBy: [UsersOrderBy!], $filter: UserFilter) {
  allUsers(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: $orderBy
    filter: $filter
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...AllUsersEntry
    }
  }
}
    ${AllUsersEntryFragmentDoc}`;

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      orderBy: // value for 'orderBy'
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: Int!) {
  userById(id: $id) {
    ...UserEntry
  }
}
    ${UserEntryFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const CreateAdministratorDocument = gql`
    mutation CreateAdministrator($username: String!, $firstName: String!, $lastName: String!, $password: String!, $pinCode: String) {
  createAdministrator(
    input: {username: $username, firstName: $firstName, lastName: $lastName, password: $password, pinCode: $pinCode}
  ) {
    user {
      ...UserEntry
    }
  }
}
    ${UserEntryFragmentDoc}`;
export type CreateAdministratorMutationFn = Apollo.MutationFunction<CreateAdministratorMutation, CreateAdministratorMutationVariables>;

/**
 * __useCreateAdministratorMutation__
 *
 * To run a mutation, you first call `useCreateAdministratorMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAdministratorMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAdministratorMutation, { data, loading, error }] = useCreateAdministratorMutation({
 *   variables: {
 *      username: // value for 'username'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      pinCode: // value for 'pinCode'
 *   },
 * });
 */
export function useCreateAdministratorMutation(baseOptions?: Apollo.MutationHookOptions<CreateAdministratorMutation, CreateAdministratorMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateAdministratorMutation, CreateAdministratorMutationVariables>(CreateAdministratorDocument, options);
      }
export type CreateAdministratorMutationHookResult = ReturnType<typeof useCreateAdministratorMutation>;
export type CreateAdministratorMutationResult = Apollo.MutationResult<CreateAdministratorMutation>;
export type CreateAdministratorMutationOptions = Apollo.BaseMutationOptions<CreateAdministratorMutation, CreateAdministratorMutationVariables>;
export const CreateSecretaryDocument = gql`
    mutation CreateSecretary($username: String!, $firstName: String!, $lastName: String!, $password: String!, $pinCode: String) {
  createSecretary(
    input: {username: $username, firstName: $firstName, lastName: $lastName, password: $password, pinCode: $pinCode}
  ) {
    user {
      ...UserEntry
    }
  }
}
    ${UserEntryFragmentDoc}`;
export type CreateSecretaryMutationFn = Apollo.MutationFunction<CreateSecretaryMutation, CreateSecretaryMutationVariables>;

/**
 * __useCreateSecretaryMutation__
 *
 * To run a mutation, you first call `useCreateSecretaryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSecretaryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSecretaryMutation, { data, loading, error }] = useCreateSecretaryMutation({
 *   variables: {
 *      username: // value for 'username'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      password: // value for 'password'
 *      pinCode: // value for 'pinCode'
 *   },
 * });
 */
export function useCreateSecretaryMutation(baseOptions?: Apollo.MutationHookOptions<CreateSecretaryMutation, CreateSecretaryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSecretaryMutation, CreateSecretaryMutationVariables>(CreateSecretaryDocument, options);
      }
export type CreateSecretaryMutationHookResult = ReturnType<typeof useCreateSecretaryMutation>;
export type CreateSecretaryMutationResult = Apollo.MutationResult<CreateSecretaryMutation>;
export type CreateSecretaryMutationOptions = Apollo.BaseMutationOptions<CreateSecretaryMutation, CreateSecretaryMutationVariables>;
export const CreateEmployeeDocument = gql`
    mutation CreateEmployee($username: String!, $firstName: String!, $lastName: String!, $pinCode: String!) {
  createEmployee(
    input: {username: $username, firstName: $firstName, lastName: $lastName, pinCode: $pinCode}
  ) {
    user {
      ...UserEntry
    }
  }
}
    ${UserEntryFragmentDoc}`;
export type CreateEmployeeMutationFn = Apollo.MutationFunction<CreateEmployeeMutation, CreateEmployeeMutationVariables>;

/**
 * __useCreateEmployeeMutation__
 *
 * To run a mutation, you first call `useCreateEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmployeeMutation, { data, loading, error }] = useCreateEmployeeMutation({
 *   variables: {
 *      username: // value for 'username'
 *      firstName: // value for 'firstName'
 *      lastName: // value for 'lastName'
 *      pinCode: // value for 'pinCode'
 *   },
 * });
 */
export function useCreateEmployeeMutation(baseOptions?: Apollo.MutationHookOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateEmployeeMutation, CreateEmployeeMutationVariables>(CreateEmployeeDocument, options);
      }
export type CreateEmployeeMutationHookResult = ReturnType<typeof useCreateEmployeeMutation>;
export type CreateEmployeeMutationResult = Apollo.MutationResult<CreateEmployeeMutation>;
export type CreateEmployeeMutationOptions = Apollo.BaseMutationOptions<CreateEmployeeMutation, CreateEmployeeMutationVariables>;
export const UpdateUserActiveStateDocument = gql`
    mutation UpdateUserActiveState($id: Int!, $isActive: Boolean!) {
  updateUserActiveState(input: {userId: $id, active: $isActive}) {
    user {
      ...UserEntry
    }
  }
}
    ${UserEntryFragmentDoc}`;
export type UpdateUserActiveStateMutationFn = Apollo.MutationFunction<UpdateUserActiveStateMutation, UpdateUserActiveStateMutationVariables>;

/**
 * __useUpdateUserActiveStateMutation__
 *
 * To run a mutation, you first call `useUpdateUserActiveStateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserActiveStateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserActiveStateMutation, { data, loading, error }] = useUpdateUserActiveStateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useUpdateUserActiveStateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserActiveStateMutation, UpdateUserActiveStateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserActiveStateMutation, UpdateUserActiveStateMutationVariables>(UpdateUserActiveStateDocument, options);
      }
export type UpdateUserActiveStateMutationHookResult = ReturnType<typeof useUpdateUserActiveStateMutation>;
export type UpdateUserActiveStateMutationResult = Apollo.MutationResult<UpdateUserActiveStateMutation>;
export type UpdateUserActiveStateMutationOptions = Apollo.BaseMutationOptions<UpdateUserActiveStateMutation, UpdateUserActiveStateMutationVariables>;
export const ChangeUserPasswordDocument = gql`
    mutation ChangeUserPassword($input: ChangeUserPasswordInput!) {
  changeUserPassword(input: $input) {
    boolean
  }
}
    `;
export type ChangeUserPasswordMutationFn = Apollo.MutationFunction<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;

/**
 * __useChangeUserPasswordMutation__
 *
 * To run a mutation, you first call `useChangeUserPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeUserPasswordMutation, { data, loading, error }] = useChangeUserPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangeUserPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>(ChangeUserPasswordDocument, options);
      }
export type ChangeUserPasswordMutationHookResult = ReturnType<typeof useChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationResult = Apollo.MutationResult<ChangeUserPasswordMutation>;
export type ChangeUserPasswordMutationOptions = Apollo.BaseMutationOptions<ChangeUserPasswordMutation, ChangeUserPasswordMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($input: UpdateUserInput!) {
  updateUser(input: $input) {
    user {
      ...UserEntry
    }
  }
}
    ${UserEntryFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers($value: String!) {
  allUsers(orderBy: NAME_ASC, filter: {name: {includesInsensitive: $value}}) {
    nodes {
      id
      name
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetMyNotificationsDocument = gql`
    query GetMyNotifications($first: Int, $last: Int, $after: Cursor, $before: Cursor) {
  allUserNotifications(
    first: $first
    last: $last
    before: $before
    after: $after
    orderBy: UPDATED_AT_DESC
    condition: {isCleared: false}
  ) {
    totalCount
    pageInfo {
      startCursor
      hasPreviousPage
      hasNextPage
      endCursor
    }
    nodes {
      ...UserNotificationEntry
    }
  }
}
    ${UserNotificationEntryFragmentDoc}`;

/**
 * __useGetMyNotificationsQuery__
 *
 * To run a query within a React component, call `useGetMyNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyNotificationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      last: // value for 'last'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *   },
 * });
 */
export function useGetMyNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyNotificationsQuery, GetMyNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyNotificationsQuery, GetMyNotificationsQueryVariables>(GetMyNotificationsDocument, options);
      }
export function useGetMyNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyNotificationsQuery, GetMyNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyNotificationsQuery, GetMyNotificationsQueryVariables>(GetMyNotificationsDocument, options);
        }
export type GetMyNotificationsQueryHookResult = ReturnType<typeof useGetMyNotificationsQuery>;
export type GetMyNotificationsLazyQueryHookResult = ReturnType<typeof useGetMyNotificationsLazyQuery>;
export type GetMyNotificationsQueryResult = Apollo.QueryResult<GetMyNotificationsQuery, GetMyNotificationsQueryVariables>;
export const ClearAllUserNotificationsDocument = gql`
    mutation ClearAllUserNotifications {
  clearAllUserNotifications(input: {}) {
    clientMutationId
  }
}
    `;
export type ClearAllUserNotificationsMutationFn = Apollo.MutationFunction<ClearAllUserNotificationsMutation, ClearAllUserNotificationsMutationVariables>;

/**
 * __useClearAllUserNotificationsMutation__
 *
 * To run a mutation, you first call `useClearAllUserNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearAllUserNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearAllUserNotificationsMutation, { data, loading, error }] = useClearAllUserNotificationsMutation({
 *   variables: {
 *   },
 * });
 */
export function useClearAllUserNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<ClearAllUserNotificationsMutation, ClearAllUserNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearAllUserNotificationsMutation, ClearAllUserNotificationsMutationVariables>(ClearAllUserNotificationsDocument, options);
      }
export type ClearAllUserNotificationsMutationHookResult = ReturnType<typeof useClearAllUserNotificationsMutation>;
export type ClearAllUserNotificationsMutationResult = Apollo.MutationResult<ClearAllUserNotificationsMutation>;
export type ClearAllUserNotificationsMutationOptions = Apollo.BaseMutationOptions<ClearAllUserNotificationsMutation, ClearAllUserNotificationsMutationVariables>;
export const ClearUserNotificationDocument = gql`
    mutation ClearUserNotification($notificationId: Int!) {
  clearUserNotification(input: {notificationId: $notificationId}) {
    clientMutationId
  }
}
    `;
export type ClearUserNotificationMutationFn = Apollo.MutationFunction<ClearUserNotificationMutation, ClearUserNotificationMutationVariables>;

/**
 * __useClearUserNotificationMutation__
 *
 * To run a mutation, you first call `useClearUserNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearUserNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearUserNotificationMutation, { data, loading, error }] = useClearUserNotificationMutation({
 *   variables: {
 *      notificationId: // value for 'notificationId'
 *   },
 * });
 */
export function useClearUserNotificationMutation(baseOptions?: Apollo.MutationHookOptions<ClearUserNotificationMutation, ClearUserNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearUserNotificationMutation, ClearUserNotificationMutationVariables>(ClearUserNotificationDocument, options);
      }
export type ClearUserNotificationMutationHookResult = ReturnType<typeof useClearUserNotificationMutation>;
export type ClearUserNotificationMutationResult = Apollo.MutationResult<ClearUserNotificationMutation>;
export type ClearUserNotificationMutationOptions = Apollo.BaseMutationOptions<ClearUserNotificationMutation, ClearUserNotificationMutationVariables>;
export const UserNotificationChangedDocument = gql`
    subscription UserNotificationChanged {
  UserNotificationEvent {
    event
    notification {
      ...UserNotificationEntry
    }
  }
}
    ${UserNotificationEntryFragmentDoc}`;

/**
 * __useUserNotificationChangedSubscription__
 *
 * To run a query within a React component, call `useUserNotificationChangedSubscription` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationChangedSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationChangedSubscription({
 *   variables: {
 *   },
 * });
 */
export function useUserNotificationChangedSubscription(baseOptions?: Apollo.SubscriptionHookOptions<UserNotificationChangedSubscription, UserNotificationChangedSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<UserNotificationChangedSubscription, UserNotificationChangedSubscriptionVariables>(UserNotificationChangedDocument, options);
      }
export type UserNotificationChangedSubscriptionHookResult = ReturnType<typeof useUserNotificationChangedSubscription>;
export type UserNotificationChangedSubscriptionResult = Apollo.SubscriptionResult<UserNotificationChangedSubscription>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "Node": [
      "Auction",
      "AuctionPush",
      "Bidder",
      "BidderCreditHistory",
      "CustomerNote",
      "Invoice",
      "InvoiceAdjustment",
      "InvoiceItemReturn",
      "InvoiceItemReturnHistory",
      "InvoiceNote",
      "InvoicesSearch",
      "Item",
      "ItemActionHistory",
      "ItemImage",
      "ItemRackLocation",
      "JobQueue",
      "JobQueueItem",
      "Location",
      "Payment",
      "PaymentsLog",
      "PicklistQueue",
      "PickupCustomer",
      "PickupQueue",
      "PickupSchedule",
      "PickupSession",
      "Printer",
      "Query",
      "ReturnOption",
      "ReturnQueue",
      "Setting",
      "ShippingQueue",
      "Transaction",
      "Truckload",
      "TruckloadDatum",
      "User",
      "UserLocation",
      "UserNotification"
    ]
  }
};
      export default result;
    