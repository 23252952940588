import { Box, Card, Stack, Typography } from '@mui/material';
import LocationSelect, {
  Option as LocationOption,
} from 'components/LocationSelect';
import { NotPriorityIcon, PriorityIcon } from 'components/PickupIcons';
import { SimpleTable } from 'components/Table';
import { ViewScreen } from 'components/ViewScreen';
import {
  PickupQueueItemEntryFragment,
  PickupQueueStatus,
} from 'graphql/graphql-types';
import { useNotCompletedPickupQueueByLocation } from 'hooks/pickupQueue';
import React, { useEffect, useState } from 'react';
import { displayElapsedTimeFromTimestamp } from 'utils/time';
import PickupItemExpanded from './components/PickupItemExpanded';

const columns = [
  {
    label: '',
    name: 'priority',
    render: ({ row }: Row) =>
      row?.isPriority ? <PriorityIcon /> : <NotPriorityIcon />,
    width: '1%',
  },
  {
    label: 'Number',
    name: 'pickupNumber',
    render: ({ row }: Row) => row?.pickupNumber ?? '',
    align: 'right' as const,
    width: '1%',
  },
  {
    label: 'Customer',
    name: 'customer',
    render: ({ row }: Row) => row?.customerName ?? '',
    width: '30%',
  },
  {
    label: 'Assigned',
    name: 'assigned',
    render: ({ row }: Row) => row?.assignedName ?? '',
    width: '25%',
  },
  {
    label: 'Elapsed',
    name: 'elapsed',
    render: ({ row }: Row) => displayElapsedTimeFromTimestamp(row.inQueueAt),
    width: '20%',
  },
  {
    label: 'Skip',
    name: 'skip',
    render: ({ row }: Row) => row.skipCount ?? 0,
    align: 'right' as const,
    width: '1%',
  },
];

type Row = {
  row: PickupQueueItemEntryFragment;
};

const PickupQueueListScreen: React.FC = () => {
  const { handleStart, assigned, unassigned } =
    useNotCompletedPickupQueueByLocation();
  const [location, setLocation] = useState<LocationOption | null>(null);
  useEffect(() => {
    const id = location?.id;
    if (id) {
      handleStart(id);
    }
  }, [location]);
  const onLocationChange = (option: LocationOption) => setLocation(option);
  const locationId = location?.id;

  return (
    <ViewScreen
      loading={false}
      header={{
        leftProps: {
          title: 'Pickup Queue',
        },
        bottomProps: {
          component: (
            <Stack direction="row" spacing={1}>
              <LocationSelect onChange={onLocationChange} />
            </Stack>
          ),
        },
        dividerProps: {
          hide: true,
        },
      }}
    >
      <Box style={{ marginBottom: '2rem' }}>
        <Card variant="outlined" style={{ marginBottom: '1rem' }}>
          <Typography style={{ padding: '1rem 1rem 1rem' }}>
            Unassigned
          </Typography>
          <SimpleTable
            columns={columns}
            rows={unassigned}
            emptyMessage="Unassigned queue is empty"
            expandRow={(data: Row['row']) => (
              <PickupItemExpanded
                locationId={locationId}
                row={data}
                isAssigned={false}
                status={{
                  equalTo: PickupQueueStatus.Waiting,
                }}
              />
            )}
          />
          <Box
            style={{
              backgroundColor: '#f7fafc',
              borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
              paddingTop: '1rem',
            }}
          />
          <Typography style={{ padding: '1rem 1rem 1rem' }}>
            Assigned
          </Typography>
          <SimpleTable
            columns={columns}
            rows={assigned}
            emptyMessage="Assigned queue is empty"
            expandRow={(data: Row['row']) => (
              <PickupItemExpanded
                locationId={locationId}
                row={data}
                isAssigned={Boolean(true)}
                status={{
                  equalTo: PickupQueueStatus.WorkingOn,
                }}
              />
            )}
          />
        </Card>
      </Box>
    </ViewScreen>
  );
};

export default PickupQueueListScreen;
